import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Link from '../../atoms/Link';
import useUserForm from '../../../hooks/useUserForm';
import Flex from '../../atoms/Flex';
import FieldComponent from '../../molecules/FieldComponent';
import { validations } from '../../../utils/form';
import inputOptions from '../../../utils/form/inputOptions';
import ErrorAlert from '../../molecules/ErrorAlert';
import useLessonData from '../../../hooks/useLessonData';

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 45vw;
  min-width: 45vw;
  padding: 20px;
  max-height: 98vh;

  > form {
    height: calc(100% - 103px);
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const BuyBackButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 5px;
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 17px 15px 22px 0px;
  width: 200px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledButton = styled(Button)`
  min-width: 180px;
  margin: 20px 0px 20px;
  border-radius: 0px;
  width: 100%;
`;

const CreditValueWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const CreditValueText = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
`;

const CreditValueField = styled(StyledField)`
  input {
    padding-right: 40px;
  }
`;

const ErrorAlertContainer = styled.div`
  > div {
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: 75vw;
      width: fit-content;
    }
  }
`;

const PopUpBuyBack = ({ user, selectedItem, optionsData }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [studId, setStudId] = useState(null);

  const { onSubmit } = useUserForm({
    endpoint: 'inventoryItems/buyBack',
    setErrorMessage,
    type: 'inventoryItem',
    action: 'create',
  });

  const { data: studentPackage } = useLessonData({
    endpoint: 'sale/studentPackage',
    query: { studentId: studId },
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const initialValues = {
    buyerName: selectedItem.ownerName,
    studentId: selectedItem.ownerId,
  };

  const sortedChildren = optionsData.children.map((c) => {
    c.label = `${c.firstName} ${c.lastName}`;
    c.value = c.id;
    return c;
  }).sort((a, b) => (a.label > b.label ? 1 : -1));

  return (
    <StyledPopup
      trigger={<BuyBackButton disabled={!selectedItem.soldTo} style={{ color: !selectedItem.soldTo && '#C1C1C1' }}> Buy Back </BuyBackButton>}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          <PopUpButton className="close" onClick={close}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper> Buy Back </PopUpTextWrapper>
          <Form
            name="BuyBackForm"
            onSubmit={onSubmit}
            initialValues={initialValues}
            initialValuesEqual={() => true}
            render={({ handleSubmit, submitting, values }) => {
              values.itemId = selectedItem.id;

              const activePackages = (studentPackage && studentPackage.length > 0) ? studentPackage.filter((ap) => ap.children.map((ch) => Number(ch.id)).includes(Number(values.studentId))) : [];

              if (values.paymentMethod !== 'Package Credit') values.package = null;
              if (values.paymentMethod !== 'Credit Card') values.creditCardCharge = null;

              if (Number(studId) !== Number(values.studentId)) setStudId(Number(values.studentId));

              return (
                <StyledForm onSubmit={handleSubmit}>
                  <div style={{ paddingRight: '20px', maxHeight: '100%', overflow: 'auto' }}>
                    <ErrorAlertContainer>
                      <ErrorAlert
                        errorMessage={errorMessage}
                        show={errorMessage !== null}
                      />
                    </ErrorAlertContainer>

                    {values.studentId ? (
                      <FieldFlex>
                        <StyledText>Buy Back From: </StyledText>
                        <StyledField
                          name="studentId"
                          type="select"
                          component={FieldComponent}
                          options={sortedChildren}
                          disabled
                          validate={validations.required}
                        />
                      </FieldFlex>
                    ) : (
                      <FieldFlex>
                        <StyledText>Buy Back From: </StyledText>
                        <StyledField
                          name="buyerName"
                          type="text"
                          component={FieldComponent}
                          disabled
                          validate={validations.required}
                        />
                      </FieldFlex>
                    )}

                    <FieldFlex>
                      <StyledText>Buy Back Type: </StyledText>
                      <StyledField
                        name="buyBackType"
                        type="select"
                        component={FieldComponent}
                        options={inputOptions.buyBackTypeOptions}
                        validate={validations.required}
                        menuPortalTarget={document.getElementById('popup-root')}
                        menuPlacement="auto"
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Buy Back Price: </StyledText>
                      <CreditValueWrapper>
                        <CreditValueField
                          name="buyBackPrice"
                          type="number"
                          min={0}
                          step="any"
                          component={FieldComponent}
                          validate={validations.required}
                        />
                        <CreditValueText> HKD </CreditValueText>
                      </CreditValueWrapper>
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Payment Date: </StyledText>
                      <StyledField
                        name="paymentDate"
                        type="date"
                        isClearable
                        component={FieldComponent}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Payment Method: </StyledText>
                      <StyledField
                        name="paymentMethod"
                        type="select"
                        component={FieldComponent}
                        options={inputOptions.salesPaymentOptions}
                        isClearable
                        menuPortalTarget={document.getElementById('popup-root')}
                        menuPlacement="auto"
                      />
                    </FieldFlex>

                    { values.paymentMethod === 'Package Credit' ? (
                      <FieldFlex>
                        <StyledText>Package: </StyledText>
                        <StyledField
                          name="package"
                          type="select"
                          component={FieldComponent}
                          options={(activePackages && activePackages.length > 0) ? activePackages : []}
                          validate={validations.required}
                          menuPortalTarget={document.getElementById('popup-root')}
                          menuPlacement="auto"
                        />
                      </FieldFlex>
                    ) : null}

                    { values.paymentMethod === 'Credit Card' ? (
                      <FieldFlex>
                        <StyledText>Credit Card Charge </StyledText>
                        <CreditValueWrapper>
                          <CreditValueField
                            name="creditCardCharge"
                            type="number"
                            component={FieldComponent}
                            min={0}
                            step="any"
                            validate={validations.required}
                          />
                          <CreditValueText> HKD </CreditValueText>
                        </CreditValueWrapper>
                      </FieldFlex>
                    ) : null}
                  </div>

                  <div style={{ paddingRight: 20 }}>
                    <StyledButton type="submit" disabled={submitting}> Save </StyledButton>
                  </div>
                </StyledForm>
              )
            }}
          />
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpBuyBack.propTypes = {
  user: PropTypes.object.isRequired,
};

export default PopUpBuyBack;
