import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import { palette, size } from 'styled-theme';
import { validations } from '../../../utils/form';
import Button from '../../atoms/Button';
import FieldComponent from '../../molecules/FieldComponent';
import Form from '../../molecules/Form';
import Heading from '../../atoms/Heading';
import P from '../../atoms/P';
import Link from '../../atoms/Link';

const StyledHeading = styled(Heading)`
  margin: 0;
  text-align: center;
`;

const StyledP = styled(P)`
  color: #3998B5;
  margin: 0 0 ${size('margin.large')} 0;
  padding: ${size('padding.default')} 0;
  text-align: center;
  // border-bottom: 1px solid #FF9997;
`;

const StyledForm = styled.form`
  div[role="alert"] {
    min-height: 16px;
  }
`;

const StyledButton = styled(Button)`
  min-width: 160px;
  width: 100%;
  margin: ${size('margin.default')} auto ${size('margin.default')};
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const LinkWrapper = styled.div`
  text-align: right;
`;

const StyledLink = styled(Link)`
  color: #66C0DB;
`;

const FieldFlexWrapper = styled.div`
  display: flex;
  margin: 0;

  > div{
    width: 100%;
    margin: 0;
  };
`;

const LoginForm = ({ onSubmit }) => (
  <React.Fragment>
    <StyledHeading level={4}>Create an Account</StyledHeading>
    <StyledP>Please Sign Up</StyledP>
    <Form
      name="SignUpForm"
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <StyledForm onSubmit={handleSubmit}>
          <FieldFlexWrapper>
            <StyledField
              name="firstName"
              placeholder="First Name"
              type="text"
              component={FieldComponent}
              validate={validations.required}
            />

            <StyledField
              name="lastName"
              placeholder="Last Name"
              type="text"
              component={FieldComponent}
              validate={validations.required}
            />
          </FieldFlexWrapper>

          <StyledField
            name="email"
            placeholder="Email"
            type="text"
            component={FieldComponent}
            validate={validations.required}
          />

          <StyledField
            name="password"
            placeholder="Password"
            type="password"
            component={FieldComponent}
            validate={validations.required}
          />

          <StyledField
            name="confirmPassword"
            placeholder="Re-type Password"
            type="password"
            component={FieldComponent}
            validate={validations.required}
          />

          <StyledButton
            type="submit"
            disabled={submitting}
          >
            Sign Up
          </StyledButton>

          <LinkWrapper>
            <StyledLink to="/">
              Already have an account? Sign In
            </StyledLink>
          </LinkWrapper>

        </StyledForm>
      )}
    />
  </React.Fragment>
);

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
