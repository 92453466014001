import { useHistory, useLocation } from 'react-router-dom';
import get from 'lodash/get';
import merge from 'lodash/merge';
import qs from 'qs';
import { useEffect, useCallback } from 'react';

const parseQueryFromUrl = (search) => qs.parse(search, { ignoreQueryPrefix: true });

export default (options = {}) => {
  const {
    initialQueryParams = {},
  } = options;
  const history = useHistory();
  const { location } = history;
  const { search } = location;

  const initialQueryParamsString = qs.stringify(
    initialQueryParams,
    {
      skipNulls: true,
    },
  );
  const queryParams = parseQueryFromUrl(search);
  const queryString = qs.stringify(
    queryParams,
    {
      skipNulls: true,
    },
  );

  const setQueryParams = useCallback((mapper, opt) => {
    const shouldReplace = get(opt, 'replace', true);
    const newQueryParams = mapper(qs.parse(queryString));
    const queryStr = qs.stringify(
      newQueryParams,
      {
        skipNulls: true,
      },
    );
    if (shouldReplace) {
      history.replace(`?${queryStr}`);
    } else {
      history.push(`?${queryStr}`);
    }
  }, [
    history,
    queryString,
  ]);

  useEffect(() => {
    if (!initialQueryParamsString) return;
    if (queryString) return;
    history.replace(`?${initialQueryParamsString}`);
  }, [/* only runs once on initial render */]);

  return {
    queryParams,
    setQueryParams,
  };
}
