import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { prop } from 'styled-tools';
import Icon from '../Icon';

const rotation = keyframes`
  from {transform: rotate(0deg);}
  to   {transform: rotate(360deg);}
`;
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinningIcon = styled(Icon)`
  -webkit-animation: ${prop('animation')} 1800ms infinite linear;
  -moz-animation: ${prop('animation')} 1800ms infinite linear;
  -o-animation: ${prop('animation')} 1800ms infinite linear;
  animation: ${prop('animation')} 1800ms infinite linear;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
`;

const Spinner = ({ loading, size }) => {
  if (!loading) return null;
  return (
    <Wrapper>
      <Icon icon="loader" height={size} width={size} animation={rotation} />
    </Wrapper>
  );
};

Spinner.propTypes = {
  size: PropTypes.number,
  loading: PropTypes.bool,
  color: PropTypes.string,
};

Spinner.defaultProps = {
  size: 36,
  loading: true,
};

export default Spinner;
