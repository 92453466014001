import { useState, useCallback, useEffect } from 'react';
import { compose, lifecycle, withHandlers, withState } from 'recompose';

import get from 'lodash/get';

import sequelizeSpecToFormSpec from '../utils/form/sequelizeSpecToFormSpec';

import withLoadingState from '../hocs/withLoadingState';
import withErrorState from '../hocs/withErrorState';

export default (options = {}) => {
  const {
    idPath,
    id,
    match: { params },
    endpoint,
    query = {},
  } = options;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [spec, setSpec] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { api } = global;

  const pathToId = idPath || 'id';
  const userId = id || get(params, pathToId);

  const fetchData = useCallback(async () => {
    // data should be null when rendering a form for createing a new entry
    setLoading(true);
    return Promise.all([
      userId ? api.get(`/${endpoint}/${userId}`, { params: query }) : null,
      api.get(`/${endpoint}/spec`),
    ])
      .then(([response, spec]) => {
        const formSpec = sequelizeSpecToFormSpec(spec);
        setSpec(formSpec);
        setData(get(response, 'data', null));
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
      });
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, []);

  return {
    loading,
    data,
    spec,
    errorMessage,
  };
  // : ({
  //   userIdPath,
  //   match: { params },
  //   setLoading,
  //   setData, setSpec, setErrorMessage, config: { endpoint, query = {} } = {},
  // }) => () => {

}
