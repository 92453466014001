import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import styled from 'styled-components';
import { font, palette } from 'styled-theme';

const Wrapper = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  font-family: ${font('primary')};
  margin: 0;
  padding: 0px;
`;

const Cell = styled.li`
  min-width: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;

  padding: 16px; /* 1rem */
  max-height: 95px;
  overflow: hidden;
  margin: 0px;
  color: ${palette('grayscale', 1)};
  text-align: center;
`;

const Row = ({ cells, styles, ...others }) => (
  <Wrapper {...others}>
    {map(cells, (cell, i) => (
      <Cell key={i} style={styles[i]}>
        {cell}
      </Cell>
    ))}
  </Wrapper>
);

Row.defaultProps = {
  cells: [],
  styles: [],
};

Row.propTypes = {
  cells: PropTypes.arrayOf(PropTypes.node),
  styles: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(Row);
