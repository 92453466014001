import React from 'react';
import styled from 'styled-components';
import { Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';

import UserPage from '../../User';
// import SurveyPage from '../../Survey';
import MyProfilePage from '../../MyProfile';
// import Report from '../../Report';
import LeftMenu from '../../../containers/LeftMenu';
import Route from './Route';
import Calendar from '../../Calendar';
import Dashboard from '../../Dashboard/ListView';
import StudentPage from '../../Student';
import StaffPage from '../../Staff';
import InstructorPage from '../../Instructor';
import SettingsPage from '../../Settings';
import PackagePage from '../../Package';
import LessonPage from '../../Lesson';
import LibraryPage from '../../Library';
import InventoryPage from '../../Inventory';
import SalePage from '../../Sale';
import PurchaseRequestPage from '../../PurchaseRequest';
import InstructorPerformancePage from '../../InstructorPerformance';
import FinancialPage from '../../Financial';
import MyPerformancePage from '../../MyPerformance';
import ClassAttendancePage from '../../ClassAttendance';
import MyStudentLessonPage from '../../MyStudentLessonPage';
import BroadcastPage from '../../Broadcast';
import EventPage from '../../Event';

import { selectUser } from '../../../store/authentication/selectors';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
`;

const Admin = (props) => {
  const { user } = props;
  const role = get(user, 'role');
  const superAdminRoutes = [
    { label: 'Dashboard', href: '/dashboard', component: Dashboard },
    { label: 'Calendar', href: '/calendar', component: Calendar },
    { label: 'Lesson Management', href: '/lesson', component: LessonPage },
    { label: 'Class Attendance', href: '/class-attendance', component: ClassAttendancePage },
    { label: 'Event Management', href: '/event', component: EventPage },
    { label: 'Package Management', href: '/package', component: PackagePage },
    { label: 'Student Management', href: '/student', component: StudentPage },
    { label: 'Parent Management', href: '/user', component: UserPage },
    { label: 'Instructor Management', href: '/instructor', component: InstructorPage },
    { label: 'Staff Management', href: '/staff', component: StaffPage },
    { label: 'Purchase Request', href: '/purchase-request', component: PurchaseRequestPage },
    { label: 'Library', href: '/library', component: LibraryPage },
    { label: 'Inventory Management', href: '/inventory', component: InventoryPage },
    { label: 'Sale Management', href: '/sale', component: SalePage },
    { label: 'Broadcast', href: '/broadcast', component: BroadcastPage },
    { label: 'Performance', href: '/performance', component: InstructorPerformancePage },
    { label: 'Financial Management', href: '/financial', component: FinancialPage },
    { label: 'Settings', href: '/settings', component: SettingsPage },
  ];
  const adminRoutes = [
    { label: 'Dashboard', href: '/dashboard', component: Dashboard },
    { label: 'Calendar', href: '/calendar', component: Calendar },
    { label: 'Lesson Management', href: '/lesson', component: LessonPage },
    { label: 'Class Attendance', href: '/class-attendance', component: ClassAttendancePage },
    { label: 'Event Management', href: '/event', component: EventPage },
    { label: 'Package Management', href: '/package', component: PackagePage },
    { label: 'Student Management', href: '/student', component: StudentPage },
    { label: 'Parent Management', href: '/user', component: UserPage },
    { label: 'Instructor Management', href: '/instructor', component: InstructorPage },
    { label: 'Purchase Request', href: '/purchase-request', component: PurchaseRequestPage },
    { label: 'Library', href: '/library', component: LibraryPage },
    { label: 'Inventory Management', href: '/inventory', component: InventoryPage },
    { label: 'Sale Management', href: '/sale', component: SalePage },
    { label: 'Broadcast', href: '/broadcast', component: BroadcastPage },
    { label: 'Settings', href: '/settings', component: SettingsPage },
  ];
  const parentRoutes = [
    { label: 'Calendar', href: '/calendar', component: Calendar },
    { label: 'Lesson Management', href: '/lesson', component: LessonPage },
    { label: 'Class Attendance', href: '/class-attendance', component: ClassAttendancePage },
    { label: 'Package Management', href: '/package', component: PackagePage },
    { label: 'Student Management', href: '/student', component: StudentPage },
    { label: 'Settings', href: '/settings', component: SettingsPage },
  ];
  const instructorRoutes = [
    { label: 'Calendar', href: '/calendar', component: Calendar },
    { label: 'My Performance', href: '/performance', component: MyPerformancePage },
    { label: 'Lesson Management', href: '/lesson', component: LessonPage },
    { label: 'Class Attendance', href: '/class-attendance', component: ClassAttendancePage },
    { label: 'Event Management', href: '/event', component: EventPage },
    
    { label: 'Student Management', href: '/student', component: StudentPage },
    { label: 'Student Lessons', href: '/student-lesson', component: MyStudentLessonPage },
    { label: 'Purchase Request', href: '/purchase-request', component: PurchaseRequestPage },
    { label: 'Library', href: '/library', component: LibraryPage },
    { label: 'Inventory Management', href: '/inventory', component: InventoryPage },
    { label: 'Settings', href: '/settings', component: SettingsPage },
  ];
  const libraryHelperRoutes = [
    { label: 'Library', href: '/library', component: LibraryPage },
  ];

  const routes = role === 'superAdmin' ? superAdminRoutes :
                 role === 'admin' ? adminRoutes :
                 role === 'parent' ? parentRoutes :
                 role === 'instructor' ? instructorRoutes :
                 role === 'libraryHelper' ? libraryHelperRoutes : [];
  const finalRoutes = (role === 'admin' && Number(user.id) === 409) ? routes.filter((r) => r.label === 'Class Attendance') : routes;
  return (
    <Wrapper>
      <LeftMenu links={role === 'parent' ? finalRoutes.filter((r) => r.label !== 'Lesson Management') : finalRoutes} />

      <Switch>
        {finalRoutes.map((route) => {
          const isInternal = route.href[0] === '/';
          if (!isInternal) return null;
          return (
            <Route key={route.href} path={route.href} component={route.component} />
          );
        })}
        <Redirect to="/" />
      </Switch>
    </Wrapper>
  )
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state.authentication),
  }
}
export default connect(mapStateToProps)(Admin);
