import React from 'react';
import Alert from 'react-bootstrap/Alert';
import styled from 'styled-components';
import { font, palette } from 'styled-theme';
import Flex from '../../atoms/Flex';

const StyledAlert = styled(Alert)`
  position: fixed;
  z-index: 20;
  font-family: ${font('primary')};
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  color: ${palette('black', 0)};
  max-width: 35vw;
`;

const TextWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ErrorAlert = (props) => {
  const { errorMessage, show, dismissible, style } = props;
  return (
    <StyledAlert show={show} variant="danger" dismissible={dismissible} style={style}>
      <TextWrapper>{errorMessage}</TextWrapper>
    </StyledAlert>
  );
};

export default ErrorAlert;
