import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { size, palette, font } from 'styled-theme';
import moment from 'moment';
import { ifProp } from 'styled-tools';
import { Field, Form } from 'react-final-form';
import { Redirect, useHistory } from 'react-router-dom';
import { Divider } from 'antd';
import get from 'lodash/get';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import { validations } from '../../../utils/form';
import FieldComponent from '../../../components/molecules/FieldComponent';
import Button from '../../../components/atoms/Button';
import useUserForm from '../../../hooks/useUserForm';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import inputOptions from '../../../utils/form/inputOptions';
import Link from '../../../components/atoms/Link';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 100%;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
  }
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const FormWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 66px);
  height: calc(100% - 66px);
  overflow-y: auto;
  padding: 5px 25px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 5px 12px;
    max-height: calc(100% - 56px);
    height: calc(100% - 56px);
  }
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C7C7C7;
  margin-bottom: 12px;
`;

const TopText = styled.div`
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 150px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledForm = styled.form`
  >div {
    >div {
      min-height: 16px;
    }
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const StyledButton = styled(Button)`
  width: 50%;
  min-width: 180px;
  margin: 20px 0px 20px;
  border-radius: 0px;

  @media (max-width: ${size('mobileBreakpoint')}){
    width: 100%;
  }
`;

const MultiSelectText = styled(StyledText)`
  padding-top: 17px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const DescriptionText = styled(StyledText)`
  padding-top: 12px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const MainWrapper = styled(Flex)`
  flex-direction: column;
  width: 50%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    width: 100%;
  }
`;

const DividerContainer = styled.div`
  min-width: auto;
  width: 100%;

  .ant-divider-horizontal.ant-divider-with-text {
    margin-top: 0px;
    margin-bottom: 12px;
    border-top-color: ${palette('grayscale', 0)};
  }
`;

const EditView = (props) => {
  const { user, match, location } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const history = useHistory();

  const { data, loading } = useGeneralData({
    endpoint: match.path === '/broadcast/create' ? null : 'broadcast/edit',
    query: { id: match.params.id },
  });

  const { onSubmit } = useUserForm({
    endpoint: match.path === '/broadcast/create' ? 'broadcast/' : `broadcast/edit/${match.params.id}`,
    setErrorMessage,
    type: 'broadcast',
    action: match.path === '/broadcast/create' ? 'create' : 'edit',
  });

  let type;

  if (match.path === '/broadcast/create') type = 'create';
  else if (match.path === '/broadcast/edit/:id') type = 'edit';
  else return <Redirect to="broadcast" />

  if (loading) return <Loading />

  if (type === 'edit' && !get(data, 'broadcast', null)) return <Redirect to="/broadcast" />;

  let initialValues = null;

  if (type === 'edit') {
    initialValues = {
      ...data.broadcast,
      startDate: get(data, 'broadcast.startDate', null) ? moment(data.broadcast.startDate).format('YYYY-MM-DD') : null,
      endDate: get(data, 'broadcast.endDate', null) ? moment(data.broadcast.endDate).format('YYYY-MM-DD') : null,
      userType: get(data, 'broadcast.sendTo.users', null),
      groupId: get(data, 'broadcast.sendTo.groups', null),
      instructorId: get(data, 'broadcast.sendTo.instructors', null),
    };
  }

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> {type === 'create' ? 'Create New' : 'Edit'} </HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <ErrorAlert
            errorMessage={errorMessage}
            show={errorMessage !== null}
            style={{ position: 'absolute', maxWidth: 'none', marginTop: 8 }}
          />
        </Flex>
      </Flex>
      <FormWrapper>
        <StyledFlex>
          <Icon icon="broadcast-page" height={20} style={{ marginRight: 5 }} />
          <TopText> Broadcast </TopText>
        </StyledFlex>
        <Form
          name="BroadcastForm"
          onSubmit={onSubmit}
          initialValues={initialValues}
          initialValuesEqual={() => true}
          render={({ handleSubmit, submitting, values }) => {
            if (type === 'edit') {
              values.id = match.params.id;
              values.oldUserIds = get(data, 'broadcast.users', []).map((u) => Number(u.id));
            }

            return (
              <StyledForm onSubmit={handleSubmit}>
                <MainWrapper style={{ paddingRight: 10 }}>
                  <FieldFlex>
                    <StyledText>Start Date:</StyledText>
                    <StyledField
                      name="startDate"
                      type="date"
                      component={FieldComponent}
                      validate={validations.required}
                    />
                  </FieldFlex>

                  <FieldFlex>
                    <StyledText>End Date:</StyledText>
                    <StyledField
                      name="endDate"
                      type="date"
                      component={FieldComponent}
                      validate={validations.required}
                    />
                  </FieldFlex>

                  <FieldFlex style={{ alignItems: 'normal' }}>
                    <DescriptionText> Description: </DescriptionText>
                    <StyledField
                      name="description"
                      type="textarea"
                      component={FieldComponent}
                      validate={validations.required}
                    />
                  </FieldFlex>

                  <div style={{ width: '100%' }}>
                    <FieldFlex style={{ alignItems: 'normal' }}>
                      <MultiSelectText>Send to: </MultiSelectText>
                      <StyledField
                        name="userType"
                        type="multi-select"
                        component={FieldComponent}
                        options={inputOptions.broadcastUserOptions}
                        placeholder="Select users"
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText />
                      <DividerContainer>
                        <Divider dashed>OR</Divider>
                      </DividerContainer>
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText />
                      <StyledField
                        name="instructorId"
                        type="spec"
                        component={FieldComponent}
                        shouldChose
                        isMulti
                        labelBy={(v) => `${v.lastName ? `${v.firstName} ${v.lastName}` : v.firstName}'s students`}
                        reference={{ endpoint: 'user', query: { where: type === 'edit' ? { role: 'instructor' } : { role: 'instructor', status: 'Current' }, order: { sortKey: ['firstName', 'lastName'], sortOrder: 'asc' } } }}
                        associationType="own"
                        placeholder="Select students"
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText />
                      <DividerContainer>
                        <Divider dashed>OR</Divider>
                      </DividerContainer>
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText />
                      <StyledField
                        name="groupId"
                        type="spec"
                        component={FieldComponent}
                        shouldChose
                        isMulti
                        labelBy={(v) => v.name}
                        reference={{ endpoint: 'group/name', query: { order: { sortKey: 'group.name', sortOrder: 'asc' } } }}
                        associationType="own"
                        placeholder="Select groups"
                      />
                    </FieldFlex>
                  </div>

                  <FieldFlex>
                    <StyledText />
                    <Flex style={{ width: '100%' }}>
                      <StyledButton type="submit" disabled={submitting}>
                        Save
                      </StyledButton>
                    </Flex>
                  </FieldFlex>
                </MainWrapper>
              </StyledForm>
            )
          }}
        />
      </FormWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state.authentication),
})

export default connect(mapStateToProps)(EditView);
