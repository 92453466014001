import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import { size, palette, font } from 'styled-theme';
import { ConfigProvider, Empty, Tag } from 'antd';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import moment from 'moment'
import { ifProp } from 'styled-tools';
import { Redirect } from 'react-router-dom';
import Popup from 'reactjs-popup';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import PopUpAttendance from '../../../components/organisms/PopUpAttendance';
import Flex from '../../../components/atoms/Flex';
import Button from '../../../components/atoms/Button';
import useCalendarForm from '../../../hooks/useCalendarForm';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import Card from '../../../components/atoms/Card';

const Wrapper = styled.div`
  padding-bottom: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
    height: 100%;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const StyledTag = styled(Tag)`
  min-width: 70px;
  text-align: center;
  font-size: 14px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const ModelContainer = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
  > div {
    @media (min-width: calc(${size('mobileBreakpoint')} + 1px)){
      padding: 24px 24px 8px 24px;

      .ant-table-pagination-right {
        flex-wrap: wrap;
      }

      .ant-pagination-options {
        margin-bottom: 16px;
      }

      .ant-pagination-prev {
        margin-bottom: 16px;
      }
    }

    @media (max-width: ${size('mobileBreakpoint')}) {
      height: 100%;
    }
    > div: first-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        max-height: ${(props) => (props.divHeight ? 'none' : '56px')};
      }
    }
    > div: last-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        height: calc(100% - 56px);
        height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')}
      }
      > div {
        @media (max-width: ${size('mobileBreakpoint')}) {
          height: 100%;
        }
        > div {
          @media (max-width: ${size('mobileBreakpoint')}) {
            height: 100%;
          }
          > div: last-child {
            @media (max-width: ${size('mobileBreakpoint')}) {
              height: 100%;
            }
            > div {
              @media (max-width: ${size('mobileBreakpoint')}) {
                height: calc(100% - 40px);
              }
              > div {
                @media (max-width: ${size('mobileBreakpoint')}) {
                  height: 100%;
                }
                > div: last-child {
                  max-height: 100% !important;
                  @media (max-width: ${size('mobileBreakpoint')}) {
                    max-height: calc(100% - 55px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const StudentLink = styled(Link)`
  color: ${({ red, orange }) => (red ? '#FF0000' : (orange ? '#FFA500' : '#3E90F7'))} !important;

  &:hover,
  &:focus,
  &:active {
    color: ${({ red, orange }) => (red ? '#FF0000' : (orange ? '#FFA500' : '#3E90F7'))} !important;
  }
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 30vw;
  min-width: 30vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledText = styled.div`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  padding: 20px 0px 30px 0px;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  display: flex;
  flex-direction: row;
  border-radius: 0px;
  margin-bottom: 0px;
  width: 100%;
`;

const PopUpErrorAlertContainer = styled.div`
  > div {
    max-width: 28vw;
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: none;
    }
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 12px;
  }
`;

const TeachingNotes = styled.div`
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ListView = (props) => {
  const { user } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [divHeight, setDivHeight] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [confirmAll, setConfirmAll] = useState(null);
  const [popupErrorMessage, setPopupErrorMessage] = useState(null);

  const { onSubmit: onConfirm } = useCalendarForm({
    endpoint: 'lesson/confirm',
    setErrorMessage,
    user,
    type: 'attendance',
  });

  const { onSubmit: onConfirmAll } = useCalendarForm({
    endpoint: 'lesson/confirmAll',
    setErrorMessage: setPopupErrorMessage,
    user,
    type: 'cancelAll',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
        setIsSubmitting(false);
      }, 3000);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (popupErrorMessage !== null) {
      setTimeout(() => {
        setPopupErrorMessage(null);
      }, 3000);
    }
  }, [popupErrorMessage]);

  if (user.role !== 'admin' && user.role !== 'superAdmin' && user.role !== 'parent' && user.role !== 'instructor') return <Redirect to="/calendar" />;

  let attendanceCells = [
    {
      title: 'Class',
      width: 90,
      render: (key, row) => <Cell>{(Number(user.id) === 409 || user.role === 'parent') ? row.title : <Link to={`/lesson/show/${row.recurrenceId}`}>{row.title}</Link>}</Cell>,
    },
    {
      title: 'Type',
      dataIndex: 'lessonType',
      key: 'lessonType',
      width: 90,
      render: (content) => <Cell>{(content || '').split(' ')[0]}</Cell>,
    },
    {
      title: 'Date',
      dataIndex: 'formattedDate',
      key: 'formattedDate',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Time',
      dataIndex: 'formattedTime',
      key: 'formattedTime',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
      width: 100,
      render: (student, data) => (
        <Cell>
          {((user.role === 'parent' && (Number(student.parentId) !== Number(user.id))))
            ? student.name
            : (
              <StudentLink
                to={`/student/show/${student.id}`}
                disabled={Number(user.id) === 409}
                red={
                  (['parent', 'admin', 'superAdmin'].includes(user.role) || Number(user.id) === 409)
                  && !student.hasCredits
                }
                orange={
                  (['parent', 'admin', 'superAdmin'].includes(user.role) || Number(user.id) === 409)
                  && !get(data, 'package.paymentDate')
                }
              >
                {student.hasCredits}
                {student.name}
              </StudentLink>
            )
          }
        </Cell>
      ),
    },
    {
      title: 'Package Code',
      dataIndex: 'packageInfo',
      key: 'packageInfo',
      width: 140,
      render: (packageInfo) => <Cell>{((packageInfo && packageInfo.id) ? <Link to={`/package/show/${packageInfo.id}`}>{`${packageInfo.prefix}${packageInfo.id}`}</Link> : 'N/A')}</Cell>,
    },
    {
      title: 'Instructor',
      dataIndex: 'instructor',
      key: 'instructor',
      width: 120,
      render: (instructor) => <Cell>{(user.role === 'superAdmin' ? <Link to={`/staff/show/${instructor.id}`}>{instructor.name}</Link> : instructor.name)}</Cell>,
    },
    {
      title: 'Room',
      dataIndex: 'roomName',
      key: 'roomName',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Notes',
      dataIndex: 'externalNote',
      key: 'externalNote',
      width: 140,
      render: (notes) => <Cell><TeachingNotes> { notes || 'N/A' } </TeachingNotes></Cell>,
    },
    {
      title: 'Teaching Notes',
      dataIndex: 'teachingNotes',
      key: 'teachingNotes',
      width: 140,
      render: (notes) => <Cell><TeachingNotes> { notes || 'N/A' } </TeachingNotes></Cell>,
    },
    {
      title: 'Status',
      dataIndex: 'cancelledById',
      key: 'cancelledById',
      width: 90,
      render: (id) => <Cell><StyledTag color={id ? 'red' : 'green'} key={id}>{id ? 'Cancelled' : 'Confirmed'}</StyledTag></Cell>,
    },
    // {
    //   title: 'Final Price',
    //   dataIndex: 'creditsDeduct',
    //   key: 'creditsDeduct',
    //   width: 130,
    //   render: (credits) => <Cell>{credits}</Cell>,
    // },
    ...((user.role !== 'parent' && user.id !== 409) ? [
      {
        title: 'Class Price',
        dataIndex: 'classPrice',
        key: 'classPrice',
        width: 130,
        render: (credits) => <Cell>{`$${(+Number(credits).toFixed(2)).toLocaleString()}`}</Cell>,
      },
    ] : []),
    ...((user.role !== 'parent') ? [
      {
        title: 'Signature',
        dataIndex: 'hasSignature',
        key: 'hasSignature',
        render: (content) => <Cell>{content}</Cell>,
        width: 120,
      },
    ] : []),
    {
      title: 'Confirmed By',
      dataIndex: 'confirmedByName',
      key: 'confirmedByName',
      width: 140,
      render: (confirmedBy) => <Cell>{(confirmedBy.id ? (user.role === 'superAdmin' ? <Link to={`/staff/show/${confirmedBy.id}`}>{confirmedBy.name}</Link> : confirmedBy.name) : 'N/A')}</Cell>,
    },
    {
      title: 'Cancelled By',
      dataIndex: 'cancelledByName',
      key: 'cancelledByName',
      width: 140,
      render: (cancelledBy) => <Cell>{(cancelledBy.id ? (cancelledBy.role === 'parent' ? <Link to={`/user/show/${cancelledBy.id}`}>{cancelledBy.name}</Link> : (user.role === 'superAdmin' ? <Link to={`/staff/show/${cancelledBy.id}`}>{cancelledBy.name}</Link> : cancelledBy.name)) : 'N/A')}</Cell>,
    },
    ...((user.role !== 'parent') ? [
      {
        title: 'Action',
        key: 'action',
        width: 90,
        render: (text, record) => (
          <Cell>
            {(user.role === 'admin' || user.role === 'superAdmin') && (
              <Flex>
                {(!record.cancelledById && !record.isUpcoming && !(Number(user.id) === 409)) ? (
                  <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                    <StyledButton disabled={record.isConfirmed || (isSubmitting && !errorMessage)} style={{ color: record.isConfirmed && '#C1C1C1' }} onClick={() => { setIsSubmitting(true); onConfirm(record); }}> Confirm </StyledButton>
                  </div>
                ) : null}
                <div style={{ paddingLeft: (!record.cancelledById && !record.isUpcoming && !(Number(user.id) === 409)) && 5 }}>
                  <PopUpAttendance
                    user={user}
                    selectedEvent={record}
                    id={record.id}
                    type={record.cancelledById ? 'Cancelled' : 'Completed'}
                    action={record.isConfirmed ? 'View' : record.isUpcoming ? 'Upcoming' : 'Edit'}
                  />
                </div>
              </Flex>
            )}
            {(user.role === 'instructor' || user.role === 'parent') && (
              <PopUpAttendance
                user={user}
                selectedEvent={record}
                id={record.id}
                type={(record.cancelledById || record.isUpcoming) ? 'Cancelled' : 'Completed'}
                action={(user.role === 'parent' || (user.role === 'instructor' && user.id !== record.instructorId)) ? 'View' : null}
              />
            )}
          </Cell>
        ),
      },
    ] : []),
  ];

  if (user.role === 'parent' || Number(user.id) === 409) attendanceCells = attendanceCells.filter((c) => c.title !== 'Package Code' && c.title !== 'Final Price' && c.title !== 'Class Price' && c.title !== 'Teaching Notes' && c.title !== 'Cancelled By' && c.title !== 'Confirmed By');
  else if (user.role === 'instructor') attendanceCells = attendanceCells.filter((c) => c.title !== 'Package Code' && c.title !== 'Final Price' && c.title !== 'Class Price' && c.title !== 'Signature' && c.title !== 'Cancelled By' && c.title !== 'Confirmed By');
  else if (user.role === 'admin' || user.role === 'superAdmin') attendanceCells = attendanceCells.filter((c) => c.title !== 'Teaching Notes');

  const filterConfig = [
    {
      type: 'radioGroup',
      name: 'status',
      label: 'Status',
      options: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'Confirmed',
          value: 'Confirmed',
        },
        {
          label: 'Cancelled',
          value: 'Cancelled',
        },
      ],
    },
    {
      type: 'radioGroup',
      name: 'lessonType',
      label: 'Lesson Type',
      options: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Private Lesson',
          value: 'Private Lesson',
        },
        {
          label: 'Semiprivate Lesson',
          value: 'Semiprivate Lesson',
        },
        {
          label: 'Group Lesson',
          value: 'Group Lesson',
        },
      ],
    },
    ...((user.role !== 'parent') ? [
      {
        type: 'radioGroup',
        name: 'signature',
        label: 'Signature',
        options: [
          {
            label: 'All',
            value: 'All',
          },
          {
            label: 'Yes',
            value: 'Yes',
          },
          {
            label: 'No',
            value: 'No',
          },
        ],
      },
    ] : []),
    {
      type: 'dateRange',
      name: 'date',
      label: 'Date',
      showDate: true,
      showTime: false,
    },
    {
      type: 'multi-select',
      name: 'title',
      label: 'Class',
      endpoint: 'lesson/lessonOptions',
      valueBy: (v) => v.lessonName,
      labelBy: (v) => v.lessonName,
    },
    {
      type: 'spec',
      name: 'childId',
      label: 'Student',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: {
        endpoint: 'student',
        query: {
          where: user.role === 'parent' ? { parentId: user.id } : user.role === 'instructor' ? { 'lessons.instructorId': user.id } : {},
          order: {
            sortKey: [
              'child.firstName',
              'child.lastName',
            ],
            sortOrder: 'asc',
          },
        },
      },
      associationType: 'own',
    },
    {
      type: 'spec',
      name: 'instructorId',
      label: 'Instructor',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: {
        endpoint: 'user',
        query: {
          where: {
            role: 'instructor',
            status: 'Current',
          },
          order: {
            sortKey: [
              'firstName',
              'lastName',
            ],
            sortOrder: 'asc',
          },
        },
      },
      associationType: 'own',
    },
    {
      type: 'spec',
      name: 'roomId',
      label: 'Room',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => v.name,
      reference: {
        endpoint: 'room',
      },
      associationType: 'own',
    },
  ];

  return (
    <Wrapper>
      {confirmAll ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpWrapper>
              <PopUpErrorAlertContainer>
                <ErrorAlert
                  errorMessage={popupErrorMessage}
                  show={popupErrorMessage !== null}
                />
              </PopUpErrorAlertContainer>
              <PopUpButton className="close" onClick={() => { close(); setConfirmAll(false); }}>
                &times;
              </PopUpButton>
              <PopUpTextWrapper>
                Confirm All
              </PopUpTextWrapper>
              <StyledText>
                {`Are you sure you want to confirm the ${selectedRowKeys.length} selected ${selectedRowKeys.length > 1 ? 'attendances' : 'attendance'}?`}
              </StyledText>
              <Form
                name="ConfirmedClassesFrom"
                onSubmit={onConfirmAll}
                initialValues={null}
                initialValuesEqual={() => true}
                render={({ handleSubmit, values, submitting }) => {
                  values.ids = selectedRowKeys;
                  return (
                    <StyledForm onSubmit={handleSubmit}>
                      <SubmitButton type="submit" disabled={submitting}> Confirm </SubmitButton>
                    </StyledForm>
                  )
                }}
              />
            </PopUpWrapper>
          )}
        </StyledPopup>
      ) : null}
      <Flex style={{ position: 'relative' }}>
        <HeaderText> Class Attendance </HeaderText>
        <Flex>
          <ErrorAlert
            errorMessage={errorMessage}
            show={errorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw / 2)' }}
          />
        </Flex>
      </Flex>
      <ModelContainer divHeight={divHeight} showMargin={((user.role === 'admin' || user.role === 'superAdmin') && Number(user.id) !== 409)}>
        <ModelPage
          {...props}
          showHeader={false}
          apiRoute="lesson/attendance"
          itemsPerPage={25}
          filterConfig={user.role === 'instructor' ? filterConfig.filter((c) => c.label !== 'Signature' && c.label !== 'Instructor') : filterConfig}
          allowSearch
          query={{ where: { type: 'Upcoming', page: 'classAttendance' } }}
          initialQuery={{
            currentPage: 1,
            where: {
              type: 'Upcoming',
            },
          }}
          radioGroup={(user.role === 'admin' || user.role === 'superAdmin') ? ['All', 'Upcoming', 'Unconfirmed', 'Confirmed'] : ['All', 'Upcoming', 'Past']}
          radioGroupKey="type"
          showCreateButton={false}
          actions={((user.role === 'admin' || user.role === 'superAdmin') && Number(user.id) !== 409) ? [
            { action: () => setConfirmAll(true), label: 'Confirm All', disabled: !(selectedRowKeys && selectedRowKeys.length > 0) },
          ] : []}
          render={(renderProps) => {
            const { height, loading, pageSize } = renderProps;
            if (divHeight !== height) setDivHeight(height);
            if (selectedRowKeys && selectedRowKeys.length > 0 && loading) setSelectedRowKeys([]);
            return (
              <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Attendance" />}>
                <AntDTable
                  cellRenderers={attendanceCells}
                  pagination={{ pageSize, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: !!((user.role === 'admin' || user.role === 'superAdmin')), pageSizeOptions: [10, 20, 25, 50, 100] }}
                  rowKey="id"
                  rowSelection={((user.role === 'admin' || user.role === 'superAdmin') && Number(user.id) !== 409) ? {
                    selectedRowKeys,
                    onChange: (keys) => setSelectedRowKeys(keys),
                    getCheckboxProps: (record) => ({
                      disabled: !(!record.cancelledById && !record.isUpcoming && !(Number(user.id) === 409) && !record.isConfirmed),
                    }),
                  } : false}
                  scroll={{ x: 'max-content', y: '65vh' }}
                  {...renderProps}
                />
              </ConfigProvider>
            )
          }}
        />
      </ModelContainer>
    </Wrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
