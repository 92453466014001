import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import { ConfigProvider, Empty } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import get from 'lodash/get';
import P from '../../../components/atoms/P';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Flex from '../../../components/atoms/Flex';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import Button from '../../../components/atoms/Button';
import PopUpDelete from '../../../components/organisms/PopUpDelete';
import Image from '../../../components/atoms/Image';
import placeholder from '../../../asset/image/placeholder.jpeg';

const Wrapper = styled.div`
  padding-bottom: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
    height: 100%;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const ModelContainer = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
  > div {
    @media (max-width: ${size('mobileBreakpoint')}) {
      height: 100%;
    }
    > div: first-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        max-height: ${(props) => (props.divHeight ? 'none' : '56px')};
      }
    }
    > div: last-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        height: calc(100% - 56px);
        height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')}
      }
      > div {
        @media (max-width: ${size('mobileBreakpoint')}) {
          height: 100%;
        }
        > div {
          @media (max-width: ${size('mobileBreakpoint')}) {
            height: 100%;
          }
          > div: last-child {
            @media (max-width: ${size('mobileBreakpoint')}) {
              height: 100%;
            }
            > div {
              @media (max-width: ${size('mobileBreakpoint')}) {
                height: calc(100% - 40px);
              }
              > div {
                @media (max-width: ${size('mobileBreakpoint')}) {
                  height: 100%;
                }
                > div: last-child {
                  max-height: 100% !important;
                  @media (max-width: ${size('mobileBreakpoint')}) {
                    max-height: calc(100% - 55px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const modelName = 'inventory';

const itemsPerPage = 25;

const ListView = (props) => {
  const { user, location } = props;
  const [divHeight, setDivHeight] = useState(0);

  const cellRenderers = [
    {
      title: 'Product ID',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Cell>{(user.role === 'instructor' ? `INV-PR${id}` : <Link to={{ pathname: `/inventory/show/${id}`, state: { prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` } }}>{`INV-PR${id}`}</Link>)}</Cell>,
      width: 120,
    },
    {
      title: 'Photo',
      dataIndex: 'photo',
      key: 'photo',
      render: (content) => (
        <Cell style={{ maxWidth: 80 }}>
          <Image width={80} height={80} src={content || placeholder} alt="Image not found" />
        </Cell>
      ),
      width: 80,
    },
    {
      title: 'Section',
      dataIndex: 'section',
      key: 'section',
      render: (content) => <Cell>{content}</Cell>,
      width: 100,
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (content) => <Cell style={{ maxWidth: 300 }}>{content}</Cell>,
      width: 100,
    },
    {
      title: 'Publisher / Maker',
      dataIndex: 'publisherOrMaker',
      key: 'publisherOrMaker',
      render: (publisherOrMaker) => <Cell style={{ maxWidth: 300 }}>{publisherOrMaker || 'N/A'}</Cell>,
      width: 150,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record) => <Cell>{((record.inventoryItems.length > 0 && record.inventoryItems[0].id) ? Number(record.inventoryItems.length) : 0)}</Cell>,
      width: 100,
    },
    {
      title: 'Available',
      dataIndex: 'availableQuantity',
      key: 'availableQuantity',
      render: (content) => <Cell>{content}</Cell>,
      width: 100,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Cell>
          <PopUpDelete user={user} endpoint="inventory" id={record.id} type="Inventory" />
        </Cell>
      ),
      width: 90,
    },
  ];

  const filterConfig = [
    {
      type: 'multi-select',
      name: 'section',
      label: 'Section',
      endpoint: 'inventory/inventorySections',
      valueBy: (v) => v.value,
      labelBy: (v) => v.label,
    },
    {
      type: 'multi-select',
      name: 'publisherOrMaker',
      label: 'Publisher / Maker',
      endpoint: 'inventory/publisherOrMakerOptions',
      valueBy: (v) => v.publisherOrMaker,
      labelBy: (v) => v.publisherOrMaker,
    },
    {
      type: 'text',
      name: 'itemId',
      label: 'Item ID',
    },
  ];

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderText> Inventory Management </HeaderText>
      </Flex>
      <ModelContainer divHeight={divHeight} role={user.role}>
        <ModelPage
          showHeader={false}
          modelName={modelName}
          itemsPerPage={itemsPerPage}
          filterConfig={filterConfig}
          allowSearch
          showCreateButton={user.role === 'admin' || user.role === 'superAdmin'}
          showExportButton={user.role === 'admin' || user.role === 'superAdmin'}
          createState={{ prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` }}
          query={{ where: { status: 'Current', type: 'All', typeArray: user.role === 'instructor' ? ['All', 'Accessory', 'Book', 'Instrument'] : ['All', 'Accessory', 'Book', 'Fee', 'Instrument', 'Ticket', 'Others'] } }}
          initialQuery={{
            currentPage: 1,
            where: {
              type: 'All',
            },
          }}
          radioGroup={user.role === 'instructor' ? ['All', 'Accessory', 'Book', 'Instrument'] : ['All', 'Accessory', 'Book', 'Fee', 'Instrument', 'Ticket', 'Others']}
          radioGroupKey="type"
          {...props}
          render={(renderProps) => {
            const { height } = renderProps;
            if (divHeight !== height) setDivHeight(height);
            return (
              <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Inventory" />}>
                <AntDTable
                  modelName={modelName}
                  cellRenderers={user.role === 'instructor' ? cellRenderers.filter((c) => c.title !== 'Action') : cellRenderers}
                  scroll={{ x: 'max-content', y: '65vh' }}
                  pagination={{ pageSize: itemsPerPage, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                  rowKey="id"
                  {...renderProps}
                />
              </ConfigProvider>
            )
          }}
        />
      </ModelContainer>
    </Wrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
