import styled from 'styled-components';
import { palette } from 'styled-theme';

const Card = styled.div`
  padding: 24px 48px; /* 1.5rem 3rem */
  background: ${palette('white', 0)};
  box-shadow: -2px 2px 6px 2px rgba(0,0,0,0.1);
  border-radius: 4px; /* 0.25rem */
  /* border: 0.0625rem solid ${palette('grayscale', 4)}; */
`;

export default Card;
