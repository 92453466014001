import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import styled from 'styled-components';
import { palette, size, font } from 'styled-theme';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash/get';
import Heading from '../../atoms/Heading';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/P';
import { selectUser } from '../../../store/authentication/selectors';
import NotificationContainer from '../../../containers/Notification';
import action from '../../../store/left-menu/actions';
import notificationAction from '../../../store/notification-menu/actions';
import RightMenuContainerComp from '../../molecules/RightMenuContainer';
import Flex from '../../atoms/Flex';

const { open } = action;
const { open: notificationOpen } = notificationAction;

const HeaderContainer = styled.div`
  padding: 10px 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: ${palette('white', 0)};
  border-bottom: 1px solid #ECECEC;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 10px;
    padding-left: 7px;
    padding-right: 12px;
    background-color: '${props => (props.isPageHeader ? palette('primary', 0) : 'initial')}';
    align-items: unset;
    z-index: 1;
    position: ${props => (props.isPageHeader ? 'sticky' : 'initial')};
    top: 0px;
    left: 0px;
    border-bottom: ${props => (props.isPageHeader ? 'unset' : `1px solid ${palette('primary', 0)}`)};
  }
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-bottom: -5px;
`;

const StyledText = styled(Text)`
  && {
    margin-bottom: 0;
    color: ${palette('black', 0)};

    @media (max-width: ${size('mobileBreakpoint')}){
      color: ${palette('black', 0)};
      margin-left: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const LeftMenuButtonContainer = styled.div`
  display: none;

  @media (max-width: ${size('mobileBreakpoint')}){
    display: block;
    color: white;
    width: 20%;
    height: 40px;
  }
`;

const LeftMenuButton = styled.div`
  width: 40px;
  height: 40px;
`;

const NotificationButtonContainer = styled.div`
  display: block;
  height: 40px;
`;

const NotificationButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

const StyledHeading = styled(Heading)`
  @media (max-width: ${size('breakpoint')}){
    color: ${props => (props.isPageHeader ? 'white' : palette('primary', 0))};
    line-height: 40px;
    font-size: 20px;
    width: 60%;
    text-align: ${props => (props.isPageHeader ? 'center' : 'unset')};
  }

  @media (max-width: 320px){
    font-size: 18px;
  }
`;

const Header = ({
  title,
  level,
  renderRight,
  icon,
  user,
  isPageHeader,
  fontFamily,
  menuOpen,
  onClose,
  notifications,
}) => {

  return (
    <HeaderContainer isPageHeader={isPageHeader}>
      {
        isPageHeader &&
        <LeftMenuButtonContainer>
          <LeftMenuButton onClick={() => open()} tabIndex="0">
            <Icon icon="burger-menu" height={40} />
          </LeftMenuButton>
        </LeftMenuButtonContainer>
      }
      <StyledHeading level={level} fontFamily={fontFamily} isPageHeader={isPageHeader}>
        { icon && <StyledIcon icon={icon} />}
        {startCase(title)}
      </StyledHeading>
      <RightContainer>
        <NotificationButtonContainer>
          <NotificationButton onClick={() => notificationOpen()}>
            <Icon icon="notification" height={20} />
          </NotificationButton>
        </NotificationButtonContainer>
        <RightMenuContainerComp onClose={onClose} open={menuOpen} top={60}>
          <NotificationContainer />
        </RightMenuContainerComp>
        {
          isPageHeader &&
          <StyledText level={2} palette="primary">{`Hi, ${startCase(user && user.name)}`}</StyledText>
        }
        {renderRight && renderRight()}
      </RightContainer>
    </HeaderContainer>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  level: PropTypes.number,
  renderRight: PropTypes.func,
  icon: PropTypes.string,
  user: PropTypes.object,
  isPageHeader: PropTypes.bool,
};

Header.defaultProps = {
  level: 3,
  isPageHeader: true,
};

const mapStateToProps = (state) => {
  const menuState = state.notificationMenu;
  return {
    user: selectUser(state.authentication),
    notifications: state.notifications,
    menuOpen: menuState.open,
  };
};

const mapDispatchToProps = () => ({
  onClose: () => notificationAction.close(),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
