/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import { ConfigProvider, Empty, Tag } from 'antd';
import moment from 'moment-timezone'
import { connect } from 'react-redux';
import get from 'lodash/get';
import P from '../../../components/atoms/P';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Flex from '../../../components/atoms/Flex';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import Button from '../../../components/atoms/Button';
import PopUpDelete from '../../../components/organisms/PopUpDelete';
import inputOptions from '../../../utils/form/inputOptions';

const Wrapper = styled.div`
  padding-bottom: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
    height: 100%;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const ModelContainer = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
  > div {
    @media (max-width: ${size('mobileBreakpoint')}) {
      height: 100%;
    }
    > div: first-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        max-height: ${(props) => (props.divHeight ? 'none' : '56px')};
      }
    }
    > div: last-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        height: calc(100% - 56px);
        height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')}
      }
      > div {
        @media (max-width: ${size('mobileBreakpoint')}) {
          height: 100%;
        }
        > div {
          @media (max-width: ${size('mobileBreakpoint')}) {
            height: 100%;
          }
          > div: last-child {
            @media (max-width: ${size('mobileBreakpoint')}) {
              height: 100%;
            }
            > div {
              @media (max-width: ${size('mobileBreakpoint')}) {
                height: calc(100% - 40px);
              }
              > div {
                @media (max-width: ${size('mobileBreakpoint')}) {
                  height: 100%;
                }
                > div: last-child {
                  max-height: 100% !important;
                  @media (max-width: ${size('mobileBreakpoint')}) {
                    max-height: calc(100% - 55px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const StyledLink = styled(Link)`
  color: #3E90F7;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-family: ${font('secondary')};

  &:hover,
  &:focus,
  &:active {
    color: #3E90F7;
  }
`;

const StyledTag = styled(Tag)`
  text-align: center;
  font-size: 14px;
  width: 100%;
`;

const itemsPerPage = 25;

const ListView = (props) => {
  const { user, location } = props;
  const [divHeight, setDivHeight] = useState(0);

  const cellRenderers = [
    {
      title: 'Instructor',
      dataIndex: 'user',
      key: 'user',
      render: (instructor) => <Cell>{user.role === 'superAdmin' ? <Link to={`/staff/show/${instructor.id}`}>{`${instructor.firstName || ''} ${instructor.lastName || ''}`}</Link> : `${instructor.firstName || ''} ${instructor.lastName || ''}`}</Cell>,
      width: 100,
    },
    {
      title: 'Student',
      dataIndex: 'child',
      key: 'child',
      render: (child) => <Cell><Link to={`/student/show/${child.id}`}>{`${child.firstName || ''} ${child.lastName || ''}`}</Link></Cell>,
      width: 90,
    },
    {
      title: 'Source',
      dataIndex: 'itemSource',
      key: 'itemSource',
      render: (content) => <Cell>{content || 'N/A'}</Cell>,
      width: 80,
    },
    {
      title: 'Type',
      dataIndex: 'itemType',
      key: 'itemType',
      render: (content) => <Cell>{content || 'N/A'}</Cell>,
      width: 80,
    },
    {
      title: 'Product',
      dataIndex: 'itemName',
      key: 'itemName',
      render: (content, record) => <Cell>{content ? ((user.role === 'instructor' || !record.inventoryId) ? content : <Link to={`/inventory/show/${record.inventoryId}`}>{content}</Link>) : 'N/A'}</Cell>,
      width: 90,
    },
    {
      title: 'Publisher / Maker',
      dataIndex: 'publisher',
      key: 'publisher',
      render: (publisher) => <Cell>{publisher || 'N/A'}</Cell>,
      width: 150,
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (content) => <Cell>{content || 'N/A'}</Cell>,
      width: 90,
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      render: (content) => <Cell style={{ maxWidth: 300 }}>{content || 'N/A'}</Cell>,
      width: 80,
    },
    {
      title: 'Request Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (content) => <Cell style={{ maxWidth: 300 }}>{moment.tz(content, 'Asia/Singapore').format('YYYY-MM-DD HH:mm') || 'N/A'}</Cell>,
      width: 80,
    },

    {
      title: 'Status',
      dataIndex: 'completedAt',
      key: 'completedAt',
      width: 90,
      render: (completedAt, record) => (
        <Cell style={{ width: '100%' }}>
          <StyledTag color={completedAt ? 'green' : record.declinedAt ? 'red' : 'orange'} key={completedAt}>{completedAt ? 'Completed' : record.declinedAt ? 'Decilned' : 'Pending'}</StyledTag>
        </Cell>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Cell>
          {(record.completedAt || record.declinedAt) ? ('N/A') : (
            <Flex>
              <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                <StyledLink to={{ pathname: `purchase-request/edit/${record.id}`, state: { prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` } }}>Edit</StyledLink>
              </div>
              {(user.role === 'instructor') ? (
                <div style={{ paddingLeft: 5 }}>
                  <PopUpDelete user={user} endpoint="request" id={record.id} type="Request" />
                </div>
              ) : null}
              {(user.role === 'admin' || user.role === 'superAdmin') ? (
                <div style={{ paddingLeft: 5 }}>
                  <PopUpDelete user={user} endpoint="request/complete" id={record.id} type="Request" action="Complete" />
                </div>
              ) : null}
              {(user.role === 'admin' || user.role === 'superAdmin') ? (
                <div style={{ paddingLeft: 5 }}>
                  <PopUpDelete user={user} endpoint="request/decline" id={record.id} type="Request" action="Decline" />
                </div>
              ) : null}
            </Flex>
          )}
        </Cell>
      ),
      width: 90,
    },
  ];

  const filterConfig = [
    {
      type: 'spec',
      name: 'instructorId',
      label: 'Instructor',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName || ''} ${v.lastName || ''}`,
      reference: {
        endpoint: 'user',
        query: {
          where: { role: 'instructor', status: 'Current' },
          order: { sortKey: ['firstName', 'lastName'], sortOrder: 'asc' },
        },
      },
      associationType: 'own',
    },
    {
      type: 'spec',
      name: 'childId',
      label: 'Student',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: {
        endpoint: 'student',
        query: {
          where: { 'lessons.instructorId': user.id },
          order: { sortKey: ['child.firstName', 'child.lastName'], sortOrder: 'asc' },
        },
      },
      associationType: 'own',
    },
    {
      type: 'radioGroup',
      name: 'itemSource',
      label: 'Source',
      options: [{ label: 'All', value: 'All' }, { label: 'Inventory', value: 'Inventory' }, { label: 'Library', value: 'Library' }],
    },
    {
      type: 'radioGroup',
      name: 'itemType',
      label: 'Type',
      options: [{ label: 'All', value: 'All' }, { label: 'Accessory', value: 'Accessory' }, { label: 'Book', value: 'Book' }, { label: 'Instrument', value: 'Instrument' }, { label: 'Others', value: 'Others' }],
    },
    {
      type: 'multi-select',
      name: 'itemName',
      label: 'Product',
      endpoint: 'inventory/productOptions',
      query: { type: ['Accessory', 'Book', 'Instrument'] },
      valueBy: (v) => v.product,
      labelBy: (v) => v.product,
    },
    {
      type: 'multi-select',
      name: 'publisher',
      label: 'Publisher / Maker',
      endpoint: 'inventory/publisherOrMakerOptions',
      valueBy: (v) => v.publisherOrMaker,
      labelBy: (v) => v.publisherOrMaker,
    },
  ];
  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderText> Purchase Request </HeaderText>
      </Flex>
      <ModelContainer divHeight={divHeight}>
        <ModelPage
          showHeader={false}
          modelName="request/purchase"
          itemsPerPage={itemsPerPage}
          filterConfig={user.role === 'instructor' ? filterConfig.filter((f) => f.label !== 'Instructor') : filterConfig}
          allowSearch
          apiRoute="purchase-request"
          showCreateButton={['superAdmin', 'admin', 'instructor'].includes(user.role)}
          createState={{ prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` }}
          query={{ where: { status: 'Pending' } }}
          initialQuery={{
            currentPage: 1,
            where: {
              status: 'Pending',
            },
          }}
          radioGroup={['All', 'Pending', 'Completed', 'Declined']}
          radioGroupKey="status"
          {...props}
          render={(renderProps) => {
            const { height } = renderProps;
            if (divHeight !== height) setDivHeight(height);
            return (
              <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Requests" />}>
                <AntDTable
                  cellRenderers={user.role === 'instructor' ? cellRenderers.filter((c) => c.title !== 'Instructor') : cellRenderers}
                  scroll={{ x: 'max-content', y: '65vh' }}
                  pagination={{
                    currentPage: renderProps.currentPage,
                    pageSize: renderProps.pageSize,
                    showQuickJumper: true,
                    hideOnSinglePage: true,
                    showSizeChanger: !!((user.role === 'admin' || user.role === 'superAdmin')),
                    pageSizeOptions: [10, 20, 25, 50, 100],
                  }}
                  rowKey="id"
                  {...renderProps}
                />
              </ConfigProvider>
            )
          }}
        />
      </ModelContainer>
    </Wrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
