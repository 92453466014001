import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import NotFound from '../NotFound';
import ShowView from './ShowView';

const MyStudentLessonPage = ({ match: { path } }) => (
  <Switch>
    <Route exact path={path} component={ShowView} />
    <Route component={NotFound} />
  </Switch>
);

MyStudentLessonPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default MyStudentLessonPage;
