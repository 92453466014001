/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Tabs, ConfigProvider, Empty, Tag } from 'antd';
import { size, palette, font } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import get from 'lodash/get';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Button from '../../../components/atoms/Button';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import useCalendarForm from '../../../hooks/useCalendarForm';
import PopUpCredit from '../../../components/organisms/PopUpCredit';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import useQueryParams from '../../../hooks/useQueryParams';
import useUserForm from '../../../hooks/useUserForm';
import PopUpDelete from '../../../components/organisms/PopUpDelete';

const Wrapper = styled.div`
  height: 100%;
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100% - 66px);
  padding-bottom: 10px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
    min-height: calc(100% - 56px);
    padding-bottom: 0px;
  }
`;

const LeftWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 30%;
  margin-right: 15px;
  overflow: auto;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 12px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
    max-height: calc(50vh - 50px);
  }
`;

const RightWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  width: 70%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 0px;
    width: 100%;
    flex: 1;
  }
`;

const DetailWrapper = styled(Flex)`
  flex-direction: column;
`;

const StyledFlex = styled(Flex)`
  padding: 10px 0px;
  align-items: center;
`;

const LabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const DetailLabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  width: 110px;
  min-width: 110px;
  margin-right: 20px;
  font-family: ${font('tertiary')};
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledLink = styled(Link)`
  color: #3E90F7;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    color: #3E90F7;
  }
`;

const ModelContainer = styled.div`
  > div {
    padding: 24px 0px;
  }

  .ant-table-body {
    max-height: 100% !important;
    @media (max-width: ${size('mobileBreakpoint')}) {
      max-height: ${(props) => (props.divHeight ? `calc(100vh - ${props.divHeight}px - 260px)` : 'calc(100vh - 56px - 260px)')} !important;
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const ShowView = (props) => {
  const { user, match, location } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [addCredit, setAddCredit] = useState(null);
  const [deductCredit, setDeductCredit] = useState(null);
  const [divHeight, setDivHeight] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  const { data, loading } = useGeneralData({
    endpoint: 'package/packageData',
    query: { packageId: match.params.id },
  });

  const { onSubmit } = useCalendarForm({
    endpoint: 'package/confirmPending',
    setErrorMessage,
    user,
    type: 'attendance',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
        setIsSubmitting(false);
      }, 3000);
    }
  }, [errorMessage]);

  const history = useHistory();

  const { queryParams, setQueryParams } = useQueryParams();

  if (loading) return <Loading />

  if (!data) return <Redirect to="/package" />;
  if (!data.packageInfo) return <Redirect to="/package" />;

  const { TabPane } = Tabs;

  const creditCells = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 75,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 110,
      render: (content, row) => {
        return (
          <Cell>
            {((
              user.role === 'superAdmin' || user.role === 'admin')
              && user.id !== 409
              && get(row, 'event') != null
            ) ? (
              <Link
                to={row.event.recurrenceId
                  ? `/lesson/show/${row.event.recurrenceId}`
                  : row.inventoryItem ? `/inventory/show/${row.inventoryItem.inventoryId}`
                  : null}
              >
                {content}
              </Link>
            ) : content}
          </Cell>
        )
      },
    },
    {
      title: 'Date',
      dataIndex: 'formattedDate',
      key: 'formattedDate',
      width: 75,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Time',
      dataIndex: 'formattedTime',
      key: 'formattedTime',
      width: 75,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
      render: (student) => <Cell>{(student ? <Link to={`/student/show/${student.id}`}>{student.name}</Link> : 'N/A')}</Cell>,
      width: 90,
    },
    {
      title: 'Instructor',
      dataIndex: 'instructor',
      key: 'instructor',
      width: 100,
      render: (instructor) => <Cell>{(instructor ? (user.role === 'superAdmin' ? <Link to={`/staff/show/${instructor.id}`}>{instructor.name}</Link> : instructor.name) : 'N/A')}</Cell>,
    },
    {
      title: 'Transaction Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 140,
      render: (createdAt) => moment.tz(createdAt, 'Asia/Singapore').format('DD/MM/YYYY'),
    },
    {
      title: user.role === 'superAdmin' ? 'External Credit' : 'Credit Used',
      dataIndex: 'formattedCreditUsed',
      key: 'formattedCreditUsed',
      width: user.role === 'superAdmin' ? 140 : 120,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: user.role === 'superAdmin' ? 'External Balance' : 'Credit Remaining',
      dataIndex: 'formattedCreditRemaining',
      key: 'formattedCreditRemaining',
      width: 150,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Internal Credit',
      dataIndex: 'formattedInternalCredit',
      key: 'formattedInternalCredit',
      width: 140,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Internal Balance',
      dataIndex: 'formattedInternalBalance',
      key: 'formattedInternalBalance',
      width: 140,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (text, record) => (
        <Cell>
          { get(queryParams, ['where', 'status'], null) === 'Pending' ? (
            <div>
              {(user.role === 'admin' || user.role === 'superAdmin') && (
                <Flex>
                  <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                    <StyledButton disabled={isSubmitting && !errorMessage} onClick={() => { setIsSubmitting(true); onSubmit(record); }}> Confirm </StyledButton>
                  </div>
                  <div style={{ borderRight: '1px solid #ADADAD' }}>
                    <PopUpCredit
                      user={user}
                      child={data.children}
                      instructor={data.instructors}
                      currPackage={data.packageInfo[0].package}
                      selectedCredit={record}
                      type="edit"
                    />
                  </div>
                  <div style={{ paddingLeft: 5 }}>
                    <PopUpDelete user={user} endpoint="package/deletePending" id={record.id} type="Credit" />
                  </div>
                </Flex>
              )}
            </div>
          ) : (
            <div>
              {(user.role === 'admin' || user.role === 'superAdmin') && (
                <div>
                  { record.eventId ? <PopUpDelete user={user} endpoint="package/undoCredit" id={record.id} type="Credit" action="Undo" /> : 'N/A'}
                </div>
              )}
            </div>
          )}
        </Cell>
      ),
    },
  ];

  return (
    <Wrapper>
      {addCredit ? (
        <PopUpCredit
          open
          user={user}
          child={data.children.filter((c) => get(data, 'packageInfo[0].child', []).map((cp) => cp.id).includes(c.id))}
          instructor={data.instructors}
          currPackage={data.packageInfo[0].package}
          selectedCredit={null}
          type="add"
          onClose={() => setAddCredit(null)}
        />
      ) : null}
      {deductCredit ? (
        <PopUpCredit
          open
          user={user}
          child={data.children.filter((c) => get(data, 'packageInfo[0].child', []).map((cp) => cp.id).includes(c.id))}
          instructor={data.instructors}
          currPackage={data.packageInfo[0].package}
          selectedCredit={null}
          type="deduct"
          onClose={() => setDeductCredit(null)}
        />
      ) : null}
      <Flex>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> Package Management </HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <ErrorAlert
            errorMessage={errorMessage}
            show={errorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw / 2)' }}
          />
        </Flex>
      </Flex>
      <BottomWrapper>
        <LeftWrapper>
          <DetailWrapper>
            <StyledFlex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Flex style={{ alignItems: 'center' }}>
                <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
                <LabelText style={{ marginRight: 0 }}> Package Information </LabelText>
              </Flex>
              { (user.role === 'admin' || user.role === 'superAdmin') ? (
                <StyledLink to={`/package/edit/${match.params.id}`}>
                  <Icon icon="edit-icon" height={21} style={{ minWidth: 21 }} />
                  <div>Edit</div>
                </StyledLink>
              ) : null}
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Package Code </DetailLabelText>
              <div>{`${data.packageInfo[0].package.prefix}${data.packageInfo[0].package.id}`}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Package Type  </DetailLabelText>
              <div>{data.packageInfo[0].package.type}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Value Type  </DetailLabelText>
              <div>{data.packageInfo[0].package.valueType}</div>
            </StyledFlex>
            { data.packageInfo[0].package.valueType === 'Hourly' ? (
              <div>
                <StyledFlex>
                  <DetailLabelText> Duration </DetailLabelText>
                  <div>{data.packageInfo[0].package.hours ? `${data.packageInfo[0].package.hours} ${data.packageInfo[0].package.hours > 1 ? 'hours' : 'hour'}` : `${data.packageInfo[0].package.minutes} ${data.packageInfo[0].package.minutes > 1 ? 'mins' : 'min'}`}</div>
                </StyledFlex>
                <StyledFlex>
                  <DetailLabelText> Remaining Minutes </DetailLabelText>
                  <div>{data.packageInfo[0].package.remainingMinutes ? `${+(Number(data.packageInfo[0].package.remainingMinutes).toFixed(2))} mins` : 'N/A'}</div>
                </StyledFlex>
              </div>
            ) : null}
            { data.packageInfo[0].package.totalLessons ? (
              <StyledFlex>
                <DetailLabelText> Total Classes </DetailLabelText>
                <div>{data.packageInfo[0].package.totalLessons}</div>
              </StyledFlex>
            ) : null}
            <StyledFlex>
              <DetailLabelText> Description </DetailLabelText>
              <div>{data.packageInfo[0].package.description || 'N/A'}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Lesson Discount </DetailLabelText>
              <div>{data.packageInfo[0].package.defaultDiscount ? `${data.packageInfo[0].package.defaultDiscount * 100}%` : 'N/A'}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Other Discount </DetailLabelText>
              <div>{data.packageInfo[0].package.otherDiscount ? `${data.packageInfo[0].package.otherDiscount * 100}%` : 'N/A'}</div>
            </StyledFlex>
            { (user.role === 'admin' || user.role === 'superAdmin') ? (
              <StyledFlex style={{ alignItems: 'normal' }}>
                <DetailLabelText> Discount % </DetailLabelText>
                <div>{data.packageInfo[0].package.discountPercent || 'N/A'}</div>
              </StyledFlex>
            ) : null}
            <StyledFlex>
              <DetailLabelText> Remaining Free Trial </DetailLabelText>
              <div>{data.packageInfo[0].package.freeTrial ? `${data.packageInfo[0].package.freeTrial} mins` : 'N/A'}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Sale To </DetailLabelText>
              <div>{`${data.packageInfo[0].package.parent.firstName} ${data.packageInfo[0].package.parent.lastName}`}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Student </DetailLabelText>
              <div>{data.packageInfo[0].child.map((p) => `${p.firstName} ${p.lastName}`.trim()).join(', ')}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Activation Date </DetailLabelText>
              <div>{data.packageInfo[0].package.activationDate ? moment.tz(data.packageInfo[0].package.activationDate, 'Asia/Singapore').format('DD/MM/YYYY') : 'N/A'}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Expiry Date </DetailLabelText>
              <div>{data.packageInfo[0].package.expiryDate ? moment.tz(data.packageInfo[0].package.expiryDate, 'Asia/Singapore').format('DD/MM/YYYY') : 'N/A'}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Invoice Date </DetailLabelText>
              <div>{data.packageInfo[0].package.invoiceDate ? moment.tz(data.packageInfo[0].package.invoiceDate, 'Asia/Singapore').format('DD/MM/YYYY') : 'N/A'}</div>
            </StyledFlex>
            { (user.role === 'admin' || user.role === 'superAdmin') ? (
              <StyledFlex style={{ alignItems: 'normal' }}>
                <DetailLabelText> Internal Remark </DetailLabelText>
                <div style={{ whiteSpace: 'pre' }}>{data.packageInfo[0].package.internalRemark || 'N/A'}</div>
              </StyledFlex>
            ) : null}
            <StyledFlex style={{ alignItems: 'normal' }}>
              <DetailLabelText> {user.role === 'parent' ? '' : 'External'} Remark </DetailLabelText>
              <div style={{ whiteSpace: 'pre' }}>{data.packageInfo[0].package.externalRemark || 'N/A'}</div>
            </StyledFlex>
          </DetailWrapper>
        </LeftWrapper>
        <RightWrapper>
          <Tabs defaultActiveKey="Credit History">
            <TabPane tab="Credit History" key="Credit History">
              <ModelContainer divHeight={divHeight}>
                <ModelPage
                  showHeader={false}
                  modelName="package/creditHistory"
                  itemsPerPage={25}
                  query={{ where: { packageId: match.params.id, status: 'Confirmed' } }}
                  initialQuery={{
                    currentPage: 1,
                    where: (user.role === 'admin' || user.role === 'superAdmin') ? { status: 'Confirmed' } : {},
                  }}
                  radioGroup={(user.role === 'admin' || user.role === 'superAdmin') ? ['Pending', 'Confirmed'] : false}
                  radioGroupKey="status"
                  radioGroupSize="middle"
                  allowSearch={false}
                  actions={((user.role === 'admin' || user.role === 'superAdmin') && get(queryParams, ['where', 'status'], null) === 'Confirmed') ? [
                    { action: () => setAddCredit(true), label: 'Add Credit' },
                    { action: () => setDeductCredit(true), label: 'Deduct Credit' }
                  ] : []}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (divHeight !== height) setDivHeight(height);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Credits" />}>
                        <AntDTable
                          modelName="package/creditHistory"
                          cellRenderers={get(queryParams, ['where', 'status'], null) === 'Pending' ? creditCells.filter((c) => c.title !== 'Credit Remaining' && c.title !== 'External Balance' && c.title !== 'Internal Credit' && c.title !== 'Internal Balance' && c.title !== 'Transaction Date') : (user.role === 'parent' ? creditCells.filter((c) => c.title !== 'Action' && c.title !== 'Internal Credit' && c.title !== 'Internal Balance' && c.title !== 'Transaction Date') : (user.role === 'admin' ? creditCells.filter((c) => c.title !== 'Internal Credit' && c.title !== 'Internal Balance') : creditCells))}
                          pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          scroll={{ x: 'max-content', y: '40vh' }}
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
          </Tabs>
        </RightWrapper>
      </BottomWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state.authentication),
  }
}

export default connect(mapStateToProps)(ShowView);
