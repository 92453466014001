import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Upload, message } from 'antd';
import kebabCase from 'lodash/kebabCase';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const UploadWrapper = styled.div`
  width: 100%;
  height: 100%;
  border: 1px dashed #d9d9d9;
  background-color: #fafafa;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const request = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

const ImageUpload = ({ image, setImage, existingImage, endpoint, disabled }) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [existingRemoved, setExistingRemoved] = useState(false);

  if (existingImage != null && existingRemoved === false) {
    setFileList([{ uid: '1', name: 'image.png', status: 'done', url: existingImage }]);
    setImage(existingImage);
    setExistingRemoved(true);
  }

  const handleChange = async (info) => {
    if (info.fileList.length !== 0) {
      if (info.fileList[0].size / 1024 / 1024 > 2) {
        setFileList([]);
        setImage(null);
        return
      }

      if (info.file.status === 'uploading') {
        const { name: fileName, type: fileType } = info.file.originFileObj;
        const pathPrefix = `images/${kebabCase(endpoint)}`;

        const { data: signedUrl } = await global.api.get('/s3/getSignedUrl', {
          params: { fileName, fileType, pathPrefix },
        });

        let imgUrl;
        setLoading(true);

        try {
          const { url } = await fetch(signedUrl, {
            method: 'PUT',
            body: info.file.originFileObj,
            headers: { 'Content-Type': fileType },
          });
          [imgUrl] = url.split('?');
          setImage(imgUrl);
        } catch (err) {
          console.log(err);
        }

        setLoading(false);
      }
    }

    setFileList(info.fileList);

    if (info.fileList.length === 0) {
      setImage(null);
      setExistingRemoved(true);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Upload
      disabled={disabled}
      listType="picture-card"
      showUploadList={{ showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: true }}
      className="avatar-uploader"
      accept="image/png, image/jpeg"
      fileList={fileList}
      customRequest={request}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      itemRender={(originNode, file, currFileList) => (
        <div style={{ width: '100%', height: '100%' }}>
          { loading ? (
            <UploadWrapper>
              <Wrapper>
                <div style={{ textAlign: 'center' }}>
                  <div> <LoadingOutlined /> </div>
                  <div style={{ marginTop: 8 }}>Uploading</div>
                </div>
              </Wrapper>
            </UploadWrapper>
          ) : originNode }
        </div>
      )}
    >
      {fileList.length >= 1 ? null : uploadButton}
    </Upload>
  );
}

export default ImageUpload;
