import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import Popup from 'reactjs-popup';
import get from 'lodash/get';
import Card from '../../../components/atoms/Card';
import Button from '../../../components/atoms/Button';
import Link from '../../../components/atoms/Link';
import { selectUser } from '../../../store/authentication/selectors';
import useCalendarForm from '../../../hooks/useCalendarForm';
import useGeneralData from '../../../hooks/useGeneralData';
import Icon from '../../../components/atoms/Icon';
import Loading from '../../../components/atoms/Loading';
import Flex from '../../../components/atoms/Flex';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import FieldComponent from '../../../components/molecules/FieldComponent';
import { validations } from '../../../utils/form';
import inputOptions from '../../../utils/form/inputOptions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 100%;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
  }
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const FormWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 66px);
  height: calc(100% - 66px);
  overflow-y: auto;
  padding: 5px 25px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 5px 12px;
    max-height: calc(100% - 56px);
    height: calc(100% - 56px);
  }
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C7C7C7;
  margin-bottom: 12px;
`;

const TopText = styled.div`
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const StyledForm = styled.form`
  >div {
    >div {
      min-height: 16px;
    }
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  min-width: 120px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledButton = styled(Button)`
  min-width: 180px;
  margin: 20px 0px 20px;
  border-radius: 0px;
  width: 50%;

  @media (max-width: ${size('mobileBreakpoint')}){
    width: 100%;
  }
`;

const StyledText3 = styled.div`
  font-family: ${font('primary')};
  font-size: 14px;
  padding: 20px 0px 25px 0px;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 40vw;
  min-width: 40vw;
  padding: 20px;
  max-height: 95vh;

  > div {
    height: calc(100% - 47px);
    > form {
      height: calc(100% - 56px);
    }
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CompletedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

const MainWrapper = styled(Flex)`
  flex-direction: column;
  width: 50%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    width: 100%;
  }
`;

const MultiSelectText = styled(StyledText)`
  padding-top: 17px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const ErrorAlertContainer = styled.div`
  > div {
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: 75vw;
      width: fit-content;
    }
  }
`;

const ExtendedLeaveView = (props) => {
  const { user, match, location } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [completeMessage, setCompleteMessage] = useState(null);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  const action = match.path === '/calendar/extendedleave' ? 'create' : 'reschedule';

  const { data, loading } = useGeneralData({
    endpoint: 'event/getParentExtendedLeave',
    query: {
      id: match.params.id,
    },
  });

  const { onSubmit } = useCalendarForm({
    endpoint: 'event/extendedleave',
    setErrorMessage,
    user,
    type: 'extendedleave',
    setCompleteMessage,
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const history = useHistory();

  if (loading) return <Loading />

  if (!data) return <Redirect to="/calendar" />;
  if (user.role !== 'parent') return <Redirect to="/calendar" />;

  if (data.thisEvent.length === 0 && action === 'reschedule') return <Redirect to="/calendar" />;
  if (action === 'reschedule' && (Number(data.thisEvent[0].userId) !== Number(user.id) || data.thisEvent[0].type !== 'Personal Block')) return <Redirect to="/calendar" />;

  const reason = action === 'create' ? null : JSON.parse(data.thisEvent[0].note).reason;

  const initialValues = action === 'create' ? null : {
    student: data.sameRecurringEvents.map((c) => c.childId),
    startDate: get(data, 'thisEvent.0.startTime', null) ? moment(data.thisEvent[0].startTime).format('YYYY-MM-DD') : null,
    endDate: get(data, 'thisEvent.0.endTime', null) ? moment(data.thisEvent[0].endTime).subtract(1, 'days').format('YYYY-MM-DD') : null,
    reason,
  };

  const sortedChild = data.myChildren.map((c) => {
    c.label = `${c.firstName} ${c.lastName}`;
    c.value = c.id;
    return c;
  }).sort((a, b) => (a.label > b.label ? 1 : -1));

  return (
    <Wrapper>
      <Flex>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> {action === 'create' ? 'Create' : 'Edit'} Extended Leave Request </HeaderText>
        </HeaderTextWrapper>
      </Flex>
      <FormWrapper style={{ paddingRight: '10px', maxHeight: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
        <StyledFlex>
          <Icon icon="basic-info" height={20} style={{ marginRight: 10 }} />
          <TopText> Extended Leave Information </TopText>
        </StyledFlex>
        <StyledText3 style={{ padding: '10px 0px 20px 0px' }}> If you would like to cancel classes for 3 weeks or more, please fill out this form</StyledText3>
        <Form
          name="ExtendedLeaveForm"
          onSubmit={onSubmit}
          initialValues={initialValues}
          initialValuesEqual={() => true}
          render={({ invalid, values }) => (
            <MainWrapper style={{ paddingRight: 10 }}>
              <FieldFlex style={{ alignItems: 'normal' }}>
                <MultiSelectText>Student: </MultiSelectText>
                <StyledField
                  name="student"
                  type="multi-select"
                  component={FieldComponent}
                  options={action === 'create' ? sortedChild.filter((c) => c.status === 'Current' && !c.deletedAt) : sortedChild}
                  disabled={action === 'reschedule'}
                  validate={validations.required}
                />
              </FieldFlex>

              <FieldFlex>
                <StyledText>Start Date: </StyledText>
                <StyledField
                  name="startDate"
                  type="date"
                  component={FieldComponent}
                  validate={validations.required}
                />
              </FieldFlex>

              <FieldFlex>
                <StyledText>End Date: </StyledText>
                <StyledField
                  name="endDate"
                  type="date"
                  component={FieldComponent}
                  validate={validations.required}
                />
              </FieldFlex>

              <FieldFlex>
                <StyledText>Reason: </StyledText>
                <StyledField
                  name="reason"
                  type="select"
                  component={FieldComponent}
                  options={inputOptions.extendedLeaveReason}
                  validate={validations.required}
                />
              </FieldFlex>

              <FieldFlex style={{ marginTop: 20 }}>
                <StyledText />
                { invalid === false ? (
                  <StyledPopup
                    trigger={<div style={{ width: '100%' }}><StyledButton disabled={invalid} style={{ margin: 0 }}> Save </StyledButton></div>}
                    modal
                    closeOnDocumentClick={false}
                    closeOnEscape={false}
                  >
                    {(close) => (
                      <PopUpWrapper>
                        <PopUpButton className="close" onClick={close}>
                          &times;
                        </PopUpButton>
                        <PopUpTextWrapper>
                          Extended Leave Request
                        </PopUpTextWrapper>
                        { completeMessage == null ? (
                          <div>
                            <ErrorAlertContainer>
                              <ErrorAlert
                                errorMessage={errorMessage}
                                show={errorMessage !== null}
                              />
                            </ErrorAlertContainer>
                            <Form
                              name="ExtendedLeaveForm"
                              onSubmit={onSubmit}
                              initialValues={{ student: values.student, startDate: values.startDate, endDate: values.endDate, reason: values.reason }}
                              initialValuesEqual={() => true}
                              render={({ handleSubmit, values, submitting }) => {
                                values.action = action;
                                if (action === 'reschedule') {
                                  values.recurId = data.thisEvent[0].recurrenceId;
                                  values.oldNote = get(data, 'thisEvent.0.note', null);
                                }
                                values.calendars = get(location, 'state.calendars', []);
                                return (
                                  <StyledForm onSubmit={handleSubmit}>
                                    <div style={{ paddingRight: '20px', maxHeight: '100%', overflow: 'auto' }}>
                                      <TopWrapper>
                                        <Icon icon="lesson-cancel-more" height="85" style={{ width: '95px' }} />
                                        <StyledText3 style={{ textAlign: 'center' }}>
                                          Your classes within the requested period will be cancelled. A kind reminder that students have to keep regular attendance to maintain the weekly slot. M.int Academy may give away your slot if regular attendance is not met. The admin team will be in touch if such a situation arises. Also, please note that if there are classes scheduled within 48 hours, there will be a late cancellation fee. Thank you!
                                        </StyledText3>
                                      </TopWrapper>
                                      <Flex direction="column" style={{ width: '100%', paddingRight: 10 }}>
                                        <FieldFlex style={{ alignItems: 'normal' }}>
                                          <MultiSelectText>Student: </MultiSelectText>
                                          <StyledField
                                            name="student"
                                            type="multi-select"
                                            component={FieldComponent}
                                            options={sortedChild}
                                            disabled
                                            validate={validations.required}
                                          />
                                        </FieldFlex>

                                        <FieldFlex>
                                          <StyledText>Start Date: </StyledText>
                                          <StyledField
                                            name="startDate"
                                            type="date"
                                            component={FieldComponent}
                                            disabled
                                            isClearable={false}
                                            validate={validations.required}
                                          />
                                        </FieldFlex>

                                        <FieldFlex>
                                          <StyledText>End Date: </StyledText>
                                          <StyledField
                                            name="endDate"
                                            type="date"
                                            component={FieldComponent}
                                            disabled
                                            isClearable={false}
                                            validate={validations.required}
                                          />
                                        </FieldFlex>

                                        <FieldFlex>
                                          <StyledText>Reason: </StyledText>
                                          <StyledField
                                            name="reason"
                                            type="select"
                                            component={FieldComponent}
                                            options={inputOptions.extendedLeaveReason}
                                            disabled
                                            validate={validations.required}
                                          />
                                        </FieldFlex>
                                      </Flex>
                                    </div>

                                    <div style={{ paddingRight: 30 }}>
                                      <StyledButton type="submit" disabled={submitting} style={{ width: '100%' }}> Confirm </StyledButton>
                                    </div>
                                  </StyledForm>
                                )
                              }}
                            />
                          </div>
                        ) : (
                          <CompletedWrapper>
                            <Icon icon="request-sent" height="60" />
                            <StyledText3>Extended leave request sent</StyledText3>
                          </CompletedWrapper>
                        )}
                      </PopUpWrapper>
                    )}
                  </StyledPopup>
                ) : <StyledButton disabled={invalid} style={{ margin: 0 }}> Save </StyledButton> }
              </FieldFlex>
            </MainWrapper>
          )}
        />
      </FormWrapper>
    </Wrapper>
  );
}

ExtendedLeaveView.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: selectUser(state.authentication),
})

export default connect(mapStateToProps)(ExtendedLeaveView);
