import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Tabs, ConfigProvider, Empty } from 'antd';
import { size, palette, font } from 'styled-theme';
import get from 'lodash/get';
import Popup from 'reactjs-popup';
import { Field, Form } from 'react-final-form';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import Image from '../../../components/atoms/Image';
import defaultProfile from '../../../asset/image/defaultprofile.png'
import useQueryParams from '../../../hooks/useQueryParams';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import useUserForm from '../../../hooks/useUserForm';
import Button from '../../../components/atoms/Button';
import FieldComponent from '../../../components/molecules/FieldComponent';
import { validations } from '../../../utils/form';
import inputOptions from '../../../utils/form/inputOptions';

const Wrapper = styled.div`
  height: 100%;
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100% - 66px);
  padding-bottom: 10px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
    min-height: calc(100% - 56px);
    padding-bottom: 0px;
  }
`;

const LeftWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 30%;
  margin-right: 15px;
  overflow: auto;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 12px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
    max-height: calc(50vh - 50px);
  }
`;

const RightWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  width: 70%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 0px;
    width: 100%;
    flex: 1;
  }
`;

const DetailWrapper = styled(Flex)`
  flex-direction: column;
`;

const ImageWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding: 10px 0px;
`;

const LabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const DetailLabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  width: 120px;
  min-width: 120px;
  margin-right: 20px;
  font-family: ${font('tertiary')};
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 35vw;
  padding: 20px;

  > form {
    height: calc(100% - 87px);
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 5px;
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 17px 15px 22px 0px;
  width: 120px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const SaveButton = styled(Button)`
  min-width: 180px;
  margin: 20px 0px 20px;
  margin-bottom: 0px;
  border-radius: 0px;
  width: 100%;
`;

const ModelContainer = styled.div`
  > div {
    padding: 24px 0px;
    > div {
      > div {
        .selector {
          width: 225px;
          min-width: 225px;
        }
      }
    }
  }

  .ant-table-body {
    max-height: 100% !important;
    @media (max-width: ${size('mobileBreakpoint')}) {
      max-height: ${(props) => (props.divHeight ? `calc(100vh - ${props.divHeight}px - 260px)` : 'calc(100vh - 56px - 260px)')} !important;
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const ErrorAlertContainer = styled.div`
  > div {
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: 75vw;
      width: fit-content;
    }
  }
`;

const ShowView = (props) => {
  const { user, match } = props;
  const [tab, setTab] = useState('Active');
  const [errorMessage, setErrorMessage] = useState(null);
  const [divHeight, setDivHeight] = useState(0);
  const [inactiveDivHeight, setInactiveDivHeight] = useState(0);
  const [exDivHeight, setExDivHeight] = useState(0);
  const [ottDivHeight, setOttDivHeight] = useState(0);
  const [subInDivHeight, setSubInDivHeight] = useState(0);

  const { data, loading } = useGeneralData({
    endpoint: 'user/instructorData',
    query: { userId: user.id },
  });

  const { onSubmit: onEditStatus } = useUserForm({
    endpoint: 'user/instructorStudentStatus',
    setErrorMessage,
    action: 'update',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const { queryParams, setQueryParams } = useQueryParams();

  if (loading) return <Loading />

  const { TabPane } = Tabs;

  const myLessons = data.formattedUser.myLessons === 'N/A' ? 'N/A' : data.formattedUser.myLessons.join(', ');
  const lessonTab = myLessons === 'N/A' ? [] : ['All', ...data.formattedUser.myLessons];

  const cellRenderers = [
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
      render: (content) => <Cell>{content}</Cell>,
      width: 110,
    },
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
      render: (student) => <Cell><Link to={`/student/show/${student.id}`}>{student.name}</Link></Cell>,
      width: 90,
    },
    {
      title: 'Lesson',
      dataIndex: 'lessonName',
      key: 'lessonName',
      render: (content) => <Cell>{content}</Cell>,
      width: 90,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Cell>
          <StyledPopup
            trigger={<StyledButton style={{ minWidth: 'auto' }}> Edit </StyledButton>}
            modal
            closeOnDocumentClick={false}
            closeOnEscape={false}
          >
            {(close) => (
              <PopUpWrapper>
                <PopUpButton className="close" onClick={close}>
                  &times;
                </PopUpButton>
                <PopUpTextWrapper> Edit Status </PopUpTextWrapper>
                <div>
                  <Form
                    name="EditStatusForm"
                    onSubmit={onEditStatus}
                    initialValues={{ status: record.status }}
                    initialValuesEqual={() => true}
                    render={({ handleSubmit, submitting, values }) => {
                      values.id = record.id;
                      return (
                        <StyledForm onSubmit={handleSubmit}>
                          <ErrorAlertContainer>
                            <ErrorAlert
                              errorMessage={errorMessage}
                              show={errorMessage !== null}
                            />
                          </ErrorAlertContainer>

                          <div style={{ maxHeight: '100%', overflow: 'auto' }}>
                            <FieldFlex>
                              <StyledText>Status: </StyledText>
                              <StyledField
                                name="status"
                                type="select"
                                component={FieldComponent}
                                options={inputOptions.lessonStatus}
                                validate={validations.required}
                                menuPortalTarget={document.getElementById('popup-root')}
                                menuPlacement="auto"
                              />
                            </FieldFlex>
                          </div>

                          <SaveButton type="submit" disabled={submitting}> Save </SaveButton>
                        </StyledForm>
                      )
                    }}
                  />
                </div>
              </PopUpWrapper>
            )}
          </StyledPopup>
        </Cell>
      ),
      width: 90,
    },
  ];

  const onTabChange = (tabValue) => {
    setQueryParams((old) => ({
      ...old,
      currentPage: 1,
      where: {
        ...old.where,
        status: tabValue,
        name: 'All',
      },
    }), { replace: true });
  }

  const profileImg = data.formattedUser.profileImage === null ? defaultProfile : data.formattedUser.profileImage;

  return (
    <Wrapper>
      <Flex>
        <HeaderText> Student Lessons </HeaderText>
      </Flex>
      <BottomWrapper>
        <LeftWrapper>
          <DetailWrapper>
            <StyledFlex>
              <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
              <LabelText style={{ marginRight: 0 }}> Basic Information </LabelText>
            </StyledFlex>
            <StyledFlex style={{ alignItems: 'normal' }}>
              <DetailLabelText> Lesson(s) </DetailLabelText>
              <div>{data.formattedUser.myLessons === 'N/A' ? 'N/A' : data.formattedUser.myLessons.map((l) => <div style={{ paddingBottom: 3 }}>{l}</div>)}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> No. of Active Students </DetailLabelText>
              <div>{data.formattedUser.activeStudents}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> No. of Inactive Students </DetailLabelText>
              <div>{data.formattedUser.inactiveStudents}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> No. of Sub-in Students </DetailLabelText>
              <div>{data.formattedUser.subInStudents}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> No. of One-time Trial Students </DetailLabelText>
              <div>{data.formattedUser.oneTimeTrialStudents}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> No. of Ex Students </DetailLabelText>
              <div>{data.formattedUser.exStudents}</div>
            </StyledFlex>
          </DetailWrapper>
        </LeftWrapper>
        <RightWrapper>
          <Tabs activeKey={get(queryParams, ['where', 'status'])} onChange={(tabValue) => onTabChange(tabValue)}>
            <TabPane tab="Active Students" key="Active">
              <ModelContainer divHeight={divHeight}>
                <ModelPage
                  showHeader={false}
                  modelName="user/instructorStudent"
                  itemsPerPage={25}
                  query={{ where: { instructorId: user.id, status: 'Active', name: 'All' } }}
                  initialQuery={{
                    currentPage: 1,
                    where: {
                      status: 'Active',
                      name: 'All',
                    },
                  }}
                  radioGroup={lessonTab}
                  radioGroupKey="name"
                  radioGroupSize="middle"
                  allowSearch={false}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (divHeight !== height) setDivHeight(height);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Students" />}>
                        <AntDTable
                          modelName="user/instructorStudent"
                          cellRenderers={cellRenderers}
                          pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          scroll={{ x: 'max-content', y: '40vh' }}
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
            <TabPane tab="Inactive Students" key="Inactive">
              <ModelContainer divHeight={inactiveDivHeight}>
                <ModelPage
                  showHeader={false}
                  modelName="user/instructorStudent"
                  itemsPerPage={25}
                  query={{ where: { instructorId: user.id, status: 'Inactive', name: 'All' } }}
                  initialQuery={{
                    currentPage: 1,
                    where: {
                      status: 'Inactive',
                      name: 'All',
                    },
                  }}
                  radioGroup={lessonTab}
                  radioGroupKey="name"
                  radioGroupSize="middle"
                  allowSearch={false}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (inactiveDivHeight !== height) setInactiveDivHeight(height);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Students" />}>
                        <AntDTable
                          modelName="user/instructorStudent"
                          cellRenderers={cellRenderers}
                          pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          scroll={{ x: 'max-content', y: '40vh' }}
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
            <TabPane tab="Sub-in Students" key="Sub-in">
              <ModelContainer divHeight={subInDivHeight}>
                <ModelPage
                  showHeader={false}
                  modelName="user/instructorStudent"
                  itemsPerPage={25}
                  query={{ where: { instructorId: user.id, status: 'Sub-in', name: 'All' } }}
                  initialQuery={{
                    currentPage: 1,
                    where: {
                      status: 'Sub-in',
                      name: 'All',
                    },
                  }}
                  radioGroup={lessonTab}
                  radioGroupKey="name"
                  radioGroupSize="middle"
                  allowSearch={false}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (subInDivHeight !== height) setSubInDivHeight(height);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Students" />}>
                        <AntDTable
                          modelName="user/instructorStudent"
                          cellRenderers={cellRenderers}
                          pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          scroll={{ x: 'max-content', y: '40vh' }}
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
            <TabPane tab="One-time Trial Students" key="One-time Trial">
              <ModelContainer divHeight={ottDivHeight}>
                <ModelPage
                  showHeader={false}
                  modelName="user/instructorStudent"
                  itemsPerPage={25}
                  query={{ where: { instructorId: user.id, status: 'One-time Trial', name: 'All' } }}
                  initialQuery={{
                    currentPage: 1,
                    where: {
                      status: 'One-time Trial',
                      name: 'All',
                    },
                  }}
                  radioGroup={lessonTab}
                  radioGroupKey="name"
                  radioGroupSize="middle"
                  allowSearch={false}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (ottDivHeight !== height) setOttDivHeight(height);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Students" />}>
                        <AntDTable
                          modelName="user/instructorStudent"
                          cellRenderers={cellRenderers}
                          pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          scroll={{ x: 'max-content', y: '40vh' }}
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
            <TabPane tab="Ex Students" key="Ex">
              <ModelContainer divHeight={exDivHeight}>
                <ModelPage
                  showHeader={false}
                  modelName="user/instructorStudent"
                  itemsPerPage={25}
                  query={{ where: { instructorId: user.id, status: 'Ex', name: 'All' } }}
                  initialQuery={{
                    currentPage: 1,
                    where: {
                      status: 'Ex',
                      name: 'All',
                    },
                  }}
                  radioGroup={lessonTab}
                  radioGroupKey="name"
                  radioGroupSize="middle"
                  allowSearch={false}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (exDivHeight !== height) setExDivHeight(height);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Students" />}>
                        <AntDTable
                          modelName="user/instructorStudent"
                          cellRenderers={cellRenderers}
                          pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          scroll={{ x: 'max-content', y: '40vh' }}
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
          </Tabs>
        </RightWrapper>
      </BottomWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state.authentication),
  }
}

export default connect(mapStateToProps)(ShowView);
