import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import NotFound from '../NotFound';
import ListView from './ListView';
import ShowView from './ShowView';
import EditView from './EditView';

const LessonPage = ({ match: { path } }) => (
  <Switch>
    <Route exact path={path} component={ListView} />
    <Route path={`${path}/:recurrenceId/editSingle/:id`} component={EditView} />
    <Route path={`${path}/:recurrenceId/edit`} component={EditView} />
    <Route path={`${path}/show/:id`} component={ShowView} />
    <Route component={NotFound} />
  </Switch>
);

LessonPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default LessonPage;
