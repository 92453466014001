import { connect } from 'react-redux';

import SignUpForm from '../../../components/organisms/SignUpForm';
import { signUpRequest } from '../../../store/authentication/actions';

const mapDispatchToProps = dispatch => ({
  onSubmit: values => dispatch(signUpRequest(values)),
});

export default connect(null, mapDispatchToProps)(SignUpForm);
