import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex: 1;
  padding: 15px 15px 15px 265px;
  max-width: 100%;
`;

Wrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
};

const Page = ({ children, ...props }) => <Wrapper {...props}>{children}</Wrapper>;

Page.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Page;
