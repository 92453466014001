import React, { useState } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import moment from 'moment';
import { connect } from 'react-redux';
import { ConfigProvider, Empty } from 'antd';
import { FixedSizeList as List } from 'react-window';
import ReactSelect, { createFilter } from 'react-select';
import { PieChart, Pie, Legend, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import get from 'lodash/get';
import P from '../../../components/atoms/P';
import useGeneralData from '../../../hooks/useGeneralData';
import Card from '../../../components/atoms/Card';
import Flex from '../../../components/atoms/Flex';
import Icon from '../../../components/atoms/Icon';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import Button from '../../../components/atoms/Button';
import Link from '../../../components/atoms/Link';
import ModelPage from '../../../containers/ModelPage';
import customStyle from '../../../components/atoms/Select/styles';
import useQueryParams from '../../../hooks/useQueryParams';
import Loading from '../../../components/atoms/Loading';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 12px 12px 0px 12px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
`;

const LeftWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 15px 24px;
  margin-right: 50px;
`;

const RightWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  width: calc(50% - 50px);
  padding: 15px 24px;
  overflow: auto;
`;

const BottomWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: center;
`;

const MainHeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
  }
`;

const HeaderText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding: 10px 5px;
  font-family: ${font('tertiary')};
  padding-left: 0px;
`;

const OverViewText = styled.div`
  font-size: 14px;
`;

const ModelContainer = styled.div`
  > div {
    > div {
      > div: nth-child(3) {
        margin-left: auto;
      }
    }
  }
`;

const MenuContainer = styled.div`
  > div {
    > div {
      div {
        div {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = 35;
  const [value] = getValue();
  const listHeight = Math.min(maxHeight, height * children.length) || 200;
  let initialOffset = options.indexOf(value) * height;
  initialOffset = initialOffset > listHeight ? initialOffset : 0;
  return (
    <MenuContainer>
      { children.length ? (
        <List
          height={listHeight}
          itemCount={children.length}
          itemSize={height}
          initialScrollOffset={initialOffset}
        >
          {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
      ) : <div style={{ padding: '10px 8px' }}> No options available </div>}
    </MenuContainer>
  );
};

const StyledPieChart = styled(PieChart)`
  display: flex;
  align-items: center;
  li {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex !important;
    align-items: center;
  }
  > svg {
    min-width: 200px;
  }
  > div {
    min-width: 300px;
  }
`;

const getAllMonthYears = (startDate) => {
  const dateToday = moment().format('YYYY-MM-DD');
  let newDate = moment(startDate).format('YYYY-MM-DD');
  const monthYears = [];
  while (moment(newDate).isBefore(dateToday)) {
    const currentDay = moment(newDate).get('date');
    const monthStartDate = currentDay < 28 ? moment(newDate).set('date', 28).subtract(1, 'months').startOf('day') : moment(newDate).set('date', 28).startOf('day');
    const monthEndDate = currentDay < 28 ? moment(newDate).set('date', 27).endOf('day') : moment(newDate).set('date', 27).add(1, 'months').endOf('day');

    monthYears.push({ label: `${monthStartDate.format('DD MMM YYYY')} - ${monthEndDate.format('DD MMM YYYY')}`, value: JSON.stringify({ startDate: monthStartDate.format('YYYY-MM-DD'), endDate: monthEndDate.format('YYYY-MM-DD') }), endDate: moment(monthEndDate.format('YYYY-MM-DD')).tz('Asia/Singapore').startOf('day') });
    newDate = moment(newDate).add(1, 'months').format('YYYY-MM-DD');
  }
  return [...monthYears].reverse();
}

const ShowView = (props) => {
  const { user } = props;

  let monthYears = getAllMonthYears('2021-07-01');
  const filteredMonthYears = monthYears.filter((month) => moment(user.createdAt).tz('Asia/Singapore').startOf('day').isSameOrBefore(moment(month.endDate)));
  monthYears = (filteredMonthYears && filteredMonthYears.length > 0) ? filteredMonthYears : monthYears;

  const { queryParams, setQueryParams } = useQueryParams({ initialQueryParams: { range: JSON.parse(monthYears[0].value) } });

  const currentRange = get(queryParams, 'range', JSON.parse(monthYears[0].value));

  const customSelect = (
    <div style={{ width: 250, minWidth: 250 }}>
      <ReactSelect
        className="select"
        autoBlur
        components={{ MenuList }}
        filterOption={createFilter({ ignoreAccents: false })}
        options={monthYears}
        onChange={(value) => setQueryParams((old) => ({
          ...old,
          range: JSON.parse(value.value),
        }), { replace: true })}
        closeMenuOnSelect
        isClearable={false}
        onBlurResetsInput={false}
        onCloseResetsInput={false}
        hideSelectedOptions={false}
        styles={customStyle({ invalid: false, disabled: false })}
        value={monthYears.filter((option) => option.value === JSON.stringify(currentRange))[0]}
        defaultValue={monthYears[0]}
        isDisabled={false}
      />
    </div>
  )

  return (
    <MainWrapper>
      <MainHeaderText> My Performance </MainHeaderText>
      <ModelContainer>
        <ModelPage
          showHeader={false}
          apiRoute="performance/instructor"
          allowSearch={false}
          query={{ where: { date: currentRange } }}
          showCreateButton={false}
          customSelect={customSelect}
          {...props}
          render={(renderProps) => {
            const { data, loading } = renderProps;
            if (loading) return <Loading />
            const { myPerformance = [], totalTeachingHours = 0 } = data;
            const myTeachingHours = myPerformance.length > 0 ? +(myPerformance[0]['Teaching Hours']).toFixed(2) : 0;
            const weeklyClasses = (myPerformance.length > 0 && myPerformance[0].classesByWeek) ? myPerformance[0].classesByWeek : [];
            const monthlyRevenue = [
              { name: 'Your Teaching Hours', value: myTeachingHours, color: '#5BA1F8' },
              { name: 'Others Teaching Hours', value: totalTeachingHours - myTeachingHours, color: '#F6D564' },
            ];
            return (
              <Wrapper>
                <TopWrapper>
                  <LeftWrapper>
                    <Flex direction="column" style={{ paddingBottom: 75 }}>
                      <HeaderText style={{ fontSize: 20 }}> {`${myTeachingHours} Hours`} </HeaderText>
                      <OverViewText> This Month Teaching Hours </OverViewText>
                    </Flex>
                    <ResponsiveContainer height={myPerformance.length * 90}>
                      <BarChart data={myPerformance} layout="vertical">
                        <XAxis
                          type="number"
                          dataKey="Teaching Hours"
                          domain={[0, 150]}
                          tick={myPerformance.length > 0}
                          ticks={[myPerformance.length > 0 ? myPerformance[0].targetHours : 0]}
                          axisLine={false}
                          tickFormatter={(value) => `${value} Hours`}
                        />
                        <YAxis hide dataKey="0" type="category" />
                        <Bar dataKey="Teaching Hours" stackId="a" fill="#F6D564" />
                        <Bar dataKey="greyHours" stackId="a" fill="#EFEFEF" />
                        <Tooltip
                          cursor={{ fill: 'transparent' }}
                          content={({ payload }) => {
                            const info = payload.length > 0 ? payload[0].payload : null;
                            let text = '';
                            if (info && info.remainingHours) {
                              if (info.remainingHours <= 0) text = 'Congratulations! You have achieved the target hours to get extra bonus.'
                              else text = `Keep going! Only ${info.remainingHours} more hours to get extra bonus!`
                            } else text = 'There is no target hours set for extra bonus.';
                            return <Card style={{ padding: 15, width: 250, textAlign: 'center' }}> {text} </Card>;
                          }}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </LeftWrapper>
                  {user.role !== 'instructor' ? (
                    <RightWrapper>
                      <Flex direction="column" style={{ paddingBottom: 10 }}>
                        <HeaderText style={{ fontSize: 20 }}> {`${myPerformance.length > 0 ? (totalTeachingHours === 0 ? 0 : +(Number((myPerformance[0]['Teaching Hours'] / totalTeachingHours) * 100).toFixed(2))) : 0}%`} </HeaderText>
                        <OverViewText> {'of M.int\'s total teaching hours'} </OverViewText>
                      </Flex>
                      <StyledPieChart width={200} height={200}>
                        <Pie data={monthlyRevenue} cx={80} cy={100} innerRadius={40} outerRadius={80}>
                          {monthlyRevenue.map((type) => (
                            <Cell fill={type.color} />
                          ))}
                        </Pie>
                        <Legend
                          layout="vertical"
                          iconType="circle"
                          formatter={(value) => <span style={{ marginLeft: 5, color: '#000', minWidth: 80, display: 'inline-block' }}> {value} </span>}
                          wrapperStyle={{ position: 'relative', left: 0, top: 4 }}
                        />
                      </StyledPieChart>
                    </RightWrapper>
                  ) : null}
                </TopWrapper>
                <BottomWrapper>
                  <HeaderText> This Month Teaching Hours </HeaderText>
                  <ResponsiveContainer height={weeklyClasses.length > 0 ? weeklyClasses.length * 80 : 0}>
                    <BarChart data={weeklyClasses} layout="vertical">
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="Weekly Teaching Hours" type="number" domain={[0, 150]} ticks={[0, 30, 60, 90, 120, 150]} label="Teaching Hours" />
                      <YAxis dataKey="weekRange" type="category" width={100} />
                      <Bar dataKey="Weekly Teaching Hours" fill="#84CDBD" />
                      <Tooltip cursor={{ fill: 'transparent' }} labelStyle={{ textAlign: 'center' }} itemStyle={{ textAlign: 'center' }} />
                    </BarChart>
                  </ResponsiveContainer>
                </BottomWrapper>
              </Wrapper>
            )
          }}
        />
      </ModelContainer>
    </MainWrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowView);
