import React from 'react';
import { compose, branch, withProps, renderComponent } from 'recompose';
import styled from 'styled-components';
import omitProps from '../../utils/omitProps';
import Spinner from '../../components/atoms/Spinner';

const Flex = styled.div`
  display: flex;
  flex-direction:  ${({ flexDirection }) => flexDirection};
`;
const LoadingContainer = styled(Flex)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

// const LoaderContainer = styled(Flex)`
//   position: relative;
//   min-height: 150px;
// `;

const withLoadingState = (replace = true) => {
  if (replace) {
    return compose(
      branch(
        ({ loading }) => loading,
        renderComponent(Spinner),
      ),
    );
  }
  return (Original) => {
    const WithLoadingState = ({ loadingContainerProps, loading, ...props }) => {
      return (
        <React.Fragment >
          {loading ? <LoadingContainer {...loadingContainerProps}><Spinner /></LoadingContainer> : null}
          <Original {...props} />
        </React.Fragment>
      );
    };

    return WithLoadingState;
  };
};

export default withLoadingState;
