import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import startCase from 'lodash/startCase';
import { palette, size } from 'styled-theme';
import { ifProp } from 'styled-tools';

import Link from '../../atoms/Link';
import Logo from '../../atoms/Logo';

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: ${props => (`${props.width}px`)};
  background-color: ${palette('white', 0)};
  /* background-color: #37C5B9; */
  align-items: center;
  overflow-y: auto;
  border-right: 1px solid #ECECEC;
  z-index: 2;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  height: 100px;
  width: 100%;
  color: #FFFFFF;
  text-align: center;
`;

const LogoWrapper = styled.div`
  padding: 15px;
  background-color: ${palette('white', 0)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 48px;
  width: 100%;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0;
  }
`;

const MenuLink = styled(Link)`
  display: inline-block;
  width: 100%;
  padding: 16px 0;
  color: ${palette('black', 0)};;
  /* font-family: 'Montserrat', sans-serif; */
  padding: 25px 35px;
  border-left: 4px solid transparent;

  &.current-link,
  &:hover,
  &:focus {
    color: ${palette('black', 0)};;
    background-color: #E9F4F5;
    /* border-left: 4px solid ${palette('secondary', 0)}; */
    border-left: 4px solid ${palette('primary', 0)};
    text-decoration: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    display: ${ifProp((props) => ['Dashboard', 'Financial Management', 'My Performance'].includes(props.label), 'none')};
  }
`;

const LeftMenu = ({ width, links, onClickSignOut, onClose }) => (
  <Wrapper width={width}>
    <LogoWrapper>
      <Logo opposite />
    </LogoWrapper>
    <MenuWrapper>
      <ul onClick={() => { onClose() }}>
        {links.map((link, index) => (
          <li key={index}>
            <MenuLink exact={link.href === '/'} to={link.href} className="fill" activeClassName="current-link" label={link.label}>
              {startCase(link.label)}
            </MenuLink>
          </li>
        ))}
      </ul>
      <MenuLink to="/" style={{ height: 'auto', alignSelf: 'flex-end' }} onClick={() => { onClose(); onClickSignOut(); }}>Log Out</MenuLink>
    </MenuWrapper>
  </Wrapper>
);

LeftMenu.defaultProps = {
  width: '250',
  links: [
    { label: 'Home', href: '/' },
  ],
};

LeftMenu.propTypes = {
  width: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  })),
};

export default LeftMenu;
