import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ifProp, switchProp } from 'styled-tools';
// import lowerCase from 'lodash/lowerCase';

import { styles } from '../../../utils/table';

import Block from '../../atoms/Block';
import Icon from '../../atoms/Icon';

const Cell = styled(Block)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const SortIcon = styled(Icon)`

`;

const StyledLabel = styled.div`
  overflow-wrap: normal;
  width: ${ifProp({ allowSort: true }, '85%', '100%')};
  text-overflow: ellipsis;
  overflow: hidden;
  color: white;
`;

const TableCellSortable = ({
  allowSort,
  sortKey,
  sortOrder,
  onSort,
  name,
  label,
}) => {
  if (!allowSort) {
    return (
      <Cell
        style={styles.defaultCell}
      >
        {/* {lowerCase(label)} */}
        <StyledLabel allowSort={allowSort}>
          {label}
        </StyledLabel>
        <SortIcon
          icon="sort-arrows"
          width={0}
          height={0}
          sorting={sortKey === name}
          sortOrder={sortOrder}
        />
      </Cell>
    )
  }
  const selected = sortKey === name;
  return (
    <Cell
      style={styles.defaultCell}
      onClick={() => {
        if (!selected) {
          return onSort({
            sortKey: name,
            sortOrder: 'desc',
          });
        }
        return onSort({
          sortKey: name,
          sortOrder: sortOrder === 'asc' ? null : 'asc',
        });
      }}
    >
      {/* {lowerCase(label)} */}
      <StyledLabel allowSort={allowSort}>
        {label}
      </StyledLabel>
      <SortIcon
        icon={selected ? (sortOrder === 'asc' ? 'sort-arrow-up' : 'sort-arrow-down') : 'sort-arrows'}
        width={16}
        height={16}
        sorting={sortKey === name}
        sortOrder={sortOrder}
      />
    </Cell>
  );
};

TableCellSortable.propTypes = {
  allowSort: PropTypes.bool,
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
  onSort: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
};

TableCellSortable.defaultProps = {
  allowSort: true,
};

export default TableCellSortable;
