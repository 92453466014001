import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styled, { css, createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';

import Button from '../../atoms/Button';
import IconButton from '../IconButton';
import Logo from '../../atoms/Logo';

createGlobalStyle`
  body.ReactModal__Body--open {
    overflow: hidden;
  }
`;

const overlayStyles = css`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  &[class*='after-open'] {
    opacity: 1;
  }
  &[class*='before-close'] {
    opacity: 0;
  }
`;

const ModalBox = styled(props => {
  useEffect(() => {
    ReactModal.setAppElement('body');
  }, []);
  return <ReactModal {...props} />;
})`
  position: absolute;
  display: flex;
  flex-direction: column;
  font-family: ${font('primary')};
  font-size: 14px;
  background-color: ${palette('grayscale', 0, true)};
  border-radius: 20px;
  color: ${palette('grayscale', 1)};
  top: calc(50%);
  left: calc(50%);
  right: auto;
  bottom: auto;
  margin: 0px calc(-50%) 0px 0px;
  transform: translate(-50%, 100%);
  transition: transform 250ms ease-in-out;
  outline: none;
  box-sizing: border-box;
  min-width: 320px;
  max-width: calc(640px);
  max-height: calc(100%);
  padding-top: ${({ hasHeader }) => (hasHeader ? 0 : '16px')};
  @media screen and (max-width: 640px) {
    width: calc(100%);
    min-width: 0;
  }
  &[class*='after-open'] {
    transform: translate(-50%, -50%);
  }
  &[class*='before-close'] {
    transform: translate(-50%, 100%);
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
`;

const StyledHeading = styled.h2`
  margin: 0 16px 0 0; /* 0 16px 0 0 */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Content = styled.div`
  overflow: auto;
  padding: 0 16px; /* 0 16px */
  margin-bottom: 16px; /* 16px */
`;

const StyledIconButton = styled(IconButton)`
  margin: 0px;
`;

const StyledReactModal = styled(({ className, ...props }) => (
  <ModalBox overlayClassName={className} closeTimeoutMS={250} {...props} />
))`
  ${overlayStyles}
`;

const Modal = ({ children, title, closeable, onClose, ...props }) => {
  const hasHeader = title || closeable;

  return (
    <StyledReactModal
      contentLabel={title || 'Modal'}
      onRequestClose={onClose}
      hasHeader={hasHeader}
      {...props}
    >
      {hasHeader && (
        <Header>
          {closeable && <span style={{ width: 40 }} />}
          <StyledHeading><Logo /></StyledHeading>
          {closeable &&
            <StyledIconButton
              transparent
              borderColor="transparent"
              icon="close"
              iconSize={24}
              onClick={onClose}
            />
          }
        </Header>
      )}
      <Content>{children}</Content>
    </StyledReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  closeable: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
