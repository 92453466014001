import React from 'react';
import { ifProp } from 'styled-tools';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { font, palette, size } from 'styled-theme';
import { Field } from 'react-final-form';
import Link from '../../atoms/Link';
import Block from '../../atoms/Block';
import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import Divider from '../../atoms/Divider';
import Form from '../../molecules/Form';
import FieldComponent from '../../molecules/FieldComponent';
import { validations } from '../../../utils/form';

const StyledHeading = styled(Heading)`
  text-align: center;
  margin-bottom: 24px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin: ${size('margin.default')} auto;
`;

const StyledLink = styled(Link)`
  color: ${palette('tertiary', 3)};
  font-family: ${font('primary')};
`;

const LinkWrapper = styled.div`
  text-align: right;
  margin: 6px 0;
`;

const Message = styled(Block)`
  font-size: 13px;
  min-height: 24px;
  color: ${ifProp('isError', palette('secondary', 0), palette('success', 4))};
`;

const SetPasswordForm = ({ onSubmit, error, success, ...props }) => {
  return (
    <div>
      <StyledHeading level={4}>Set Password</StyledHeading>
      <Form
        name="setPasswordForm"
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="newPassword"
              placeholder="New Password"
              type="password"
              component={FieldComponent}
              validate={validations.password}
              disabled={success}
            />
            <Field
              name="confirmPassword"
              placeholder="Confirm Password"
              type="password"
              component={FieldComponent}
              validate={validations.required}
              disabled={success}
            />
            {error ? <Message isError>{error}</Message> : null}
            {success && !error ? <Message>Your password has been successfully updated</Message> : null}
            <StyledButton
              type="submit"
              disabled={success || submitting || pristine}
            >
              Confirm
            </StyledButton>
            <LinkWrapper>
              <StyledLink to="/">Go Back</StyledLink>
            </LinkWrapper>
          </form>
        )}
        {...props}
      />
    </div>
  );
};

SetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  success: PropTypes.bool,
};

SetPasswordForm.defaultProps = {
  onSubmit: () => {},
  error: null,
  success: false,
};

export default SetPasswordForm;
