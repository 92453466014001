import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { size, palette } from 'styled-theme';
import Card from '../../components/atoms/Card';
import P from '../../components/atoms/P';
import Logo from '../../components/atoms/Logo';
import { signUpRequest } from '../../store/authentication/actions';
import { selectSignUpError, selectSignUpSuccessful } from '../../store/authentication/selectors';
import SignUpForm from './SignUpForm';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLogo = styled(Logo)`
  display: flex;
  margin: 0 auto;
`;

const ContentWrapper = styled(Card)`
  display: flex;
  width: 800px;
  margin: 24px;
  padding: 0;
  flex-direction: row;
`;

const ImageContainer = styled.div`
  background-image: url(${null});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 400px;
`;

const FormConatiner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${size('padding.large')};

  &>form {
    [role="alert"] {
      span {
        font-size: 14px;
      }
    }
  }
`;

const ErrorWrapper = styled.div`
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 6px;
`;

const StyledP = styled(P)`
  margin: 0;
  font-size: 14px;
  color: ${props => (props.signUpSuccess ? palette('success', 4) : palette('error', 4))};
`;

const Home = ({ authenticated, role, signUpError, signUpSuccess, ...props }) => {
  const { from } = props.location.state || { from: { pathname: '/batch' } };

  if (authenticated && ['admin', 'superAdmin'].includes(role)) return <Redirect to={from} />; // todo

  return (
    <Wrapper>
      <ContentWrapper>
        <ImageContainer />
        <FormConatiner>
          <StyledLogo colored={true} />
          <SignUpForm />
          <ErrorWrapper>
            {signUpError && <StyledP>{signUpError.message}</StyledP>}
            {signUpSuccess && <StyledP signUpSuccess={signUpSuccess}>Your Account has been created successfully, Please check your inbox to verify your email</StyledP>}
          </ErrorWrapper>
        </FormConatiner>
      </ContentWrapper>
    </Wrapper>
  );
};

Home.propTypes = {
  location: PropTypes.object,
  signUpError: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  authenticated: PropTypes.bool,
  role: PropTypes.string,
  signUpSuccess: PropTypes.bool,
};

const mapStateToProps = state => ({
  authenticated: state.authentication.authenticated,
  // role: selectUserRole(state.authentication),
  signUpError: selectSignUpError(state.authentication),
  signUpSuccess: selectSignUpSuccessful(state.authentication),
});

const mapDispatchToProps = dispatch => ({
  handleSignUp: params => dispatch(signUpRequest(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
