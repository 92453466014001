import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Tabs, ConfigProvider, Empty, Tag } from 'antd';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Button from '../../../components/atoms/Button';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import PopUpAddChild from '../../../components/organisms/PopUpAddChild';
import Image from '../../../components/atoms/Image';
import defaultProfile from '../../../asset/image/defaultprofile.png';
import PopUpDelete from '../../../components/organisms/PopUpDelete';

const Wrapper = styled.div`
  height: 100%;
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100% - 66px);
  padding-bottom: 10px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
    min-height: calc(100% - 56px);
    padding-bottom: 0px;
  }
`;

const LeftWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 30%;
  margin-right: 15px;
  max-height: 100%;
  overflow: auto;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 12px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
    max-height: calc(50vh - 50px);
  }
`;

const RightWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  width: 70%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 0px;
    width: 100%;
    flex: 1;
  }
`;

const DetailWrapper = styled(Flex)`
  flex-direction: column;
`;

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  padding: 0px 0px 15px 0px;
  border-bottom: 1px dashed #E7E7E7;
`;

const LeftTopWrapper = styled(Flex)`
  justify-content: center;
  flex-direction: column;
`;

const ImageWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
  justify-content: center;
  margin-top: 0px;
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding: 10px 0px;
`;

const LabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const DetailLabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  min-width: 100px;
  margin-right: 20px;
  font-family: ${font('tertiary')};
`;

const EditWrapper = styled(Flex)`
  justify-content: flex-end;
`;

const StyledLink = styled(Link)`
  color: #3E90F7;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    color: #3E90F7;
  }
`;

const StyledTag = styled(Tag)`
  min-width: 70px;
  text-align: center;
  font-size: 14px;
`;

const ModelContainer = styled.div`
  > div {
    padding: 24px 0px;
    > div {
      display: flex;
      justify-content: flex-end;
    }
  }

  .ant-table-body {
    max-height: 100% !important;
    @media (max-width: ${size('mobileBreakpoint')}) {
      max-height: ${(props) => (props.divHeight ? `calc(100vh - ${props.divHeight}px - 260px)` : 'calc(100vh - 56px - 260px)')} !important;
    }
  }
`;

const StyledSpan = styled.span`
  display: inline-block;
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const ShowView = (props) => {
  const { user, match , location} = props;
  const [addStudent, setAddStudent] = useState(null);
  const [divHeight, setDivHeight] = useState(0);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  const { data, loading } = useGeneralData({
    endpoint: 'user/userData',
    query: { userId: match.params.id, allStudent: true },
  });

  const history = useHistory();

  if (loading) return <Loading />

  if (!data || !data.formattedUser) return <Redirect to="/user" />;

  const { TabPane } = Tabs;

  const cellRenderers = [
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
      render: (content) => <Cell>{content}</Cell>,
      width: 110,
    },
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
      render: (student) => <Cell><Link to={`/student/show/${student.id}`}>{student.name}</Link></Cell>,
      width: 90,
    },
    {
      title: 'Lesson(s)',
      dataIndex: 'currentActiveLessons',
      key: 'currentActiveLessons',
      width: 90,
      render: (lessons) => (
        <Cell style={{ maxWidth: 350 }}>
          {(lessons ? uniqBy(lessons, 'lessonName')
            .map((l, index) =>
              <span>
                {(index ? ', ' : '')}
                <StyledSpan>{l.lessonName}</StyledSpan>
              </span>
            )
          : 'N/A')}
        </Cell>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 90,
      render: (status) => <Cell><StyledTag color={status === 'Current' ? 'green' : 'orange'} key={status}>{status}</StyledTag></Cell>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Cell><PopUpDelete user={user} endpoint="student/removeStudent" id={record.id} type="Student" action="Remove" /></Cell>
      ),
      width: 90,
    },
  ];

  const profileImg = data.formattedUser.profileImage === null ? defaultProfile : data.formattedUser.profileImage;
  const { otherContacts } = data.formattedUser;

  const checkHasMultipleContacts = () => {
    if (otherContacts.name2 != null && otherContacts.relationship2 != null && otherContacts.phoneNumber2 != null && otherContacts.email2 != null) return true;
    return false;
  }

  return (
    <Wrapper>
      {addStudent ? (
        <PopUpAddChild
          open
          userId={match.params.id}
          students={data.students}
          onClose={() => setAddStudent(null)}
        />
      ) : null}
      <Flex>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> Parent Management </HeaderText>
        </HeaderTextWrapper>
      </Flex>
      <BottomWrapper>
        <LeftWrapper>
          <LeftTopWrapper>
            <EditWrapper>
              <StyledLink to={`/user/edit/${match.params.id}`}>
                <Icon icon="edit-icon" height={21} style={{ minWidth: 21 }} />
                <div>Edit</div>
              </StyledLink>
            </EditWrapper>
            <ImageWrapper>
              <Image width={128} height={128} src={profileImg} alt="Image not found" style={{ minWidth: 128 }} />
              <LabelText style={{ fontSize: 16, paddingTop: 10 }}>{data.formattedUser.name}</LabelText>
            </ImageWrapper>
          </LeftTopWrapper>
          <DetailWrapper>
            <SectionWrapper>
              <StyledFlex>
                <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
                <LabelText style={{ marginRight: 0 }}> Basic Information </LabelText>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Account </DetailLabelText>
                <div>{data.formattedUser.email}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Created at </DetailLabelText>
                <div>{moment(data.formattedUser.createdAt).format('DD/MM/YYYY')}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Title </DetailLabelText>
                <div>{data.formattedUser.parentTitle || 'N/A'}</div>
              </StyledFlex>
            </SectionWrapper>
            <SectionWrapper style={{ paddingTop: 10, borderBottomStyle: 'dashed' }}>
              <StyledFlex>
                <Icon icon="contact-info" height={20} style={{ marginRight: 5 }} />
                <LabelText style={{ marginRight: 0 }}> Contact Information </LabelText>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Main Contact </DetailLabelText>
                <div>{(data.formattedUser.mainContact && data.formattedUser.mainContact.name) ? data.formattedUser.mainContact.name : 'N/A'}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Relationship </DetailLabelText>
                <div>{(data.formattedUser.mainContact && data.formattedUser.mainContact.relationship) ? data.formattedUser.mainContact.relationship : 'N/A'}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Phone Number </DetailLabelText>
                <div>{(data.formattedUser.mainContact && data.formattedUser.mainContact.phoneNumber) ? data.formattedUser.mainContact.phoneNumber : 'N/A'}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Email </DetailLabelText>
                <div>{(data.formattedUser.mainContact && data.formattedUser.mainContact.email) ? data.formattedUser.mainContact.email : 'N/A'}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Address </DetailLabelText>
                <div>{data.formattedUser.address || 'N/A'}</div>
              </StyledFlex>
            </SectionWrapper>
            { otherContacts != null ? (
              <div>
                <SectionWrapper style={{ paddingTop: 10, borderBottomStyle: 'dashed' }}>
                  <StyledFlex>
                    <DetailLabelText> Contact </DetailLabelText>
                    <div>{otherContacts.name1 || 'N/A'}</div>
                  </StyledFlex>
                  <StyledFlex>
                    <DetailLabelText> Relationship </DetailLabelText>
                    <div>{otherContacts.relationship1 || 'N/A'}</div>
                  </StyledFlex>
                  <StyledFlex>
                    <DetailLabelText> Phone Number </DetailLabelText>
                    <div>{otherContacts.phoneNumber1 || 'N/A'}</div>
                  </StyledFlex>
                  <StyledFlex>
                    <DetailLabelText> Email </DetailLabelText>
                    <div>{otherContacts.email1 || 'N/A'}</div>
                  </StyledFlex>
                </SectionWrapper>
                { checkHasMultipleContacts() === true && (
                  <SectionWrapper style={{ paddingTop: 10, borderBottomStyle: 'none', paddingBottom: 0 }}>
                    <StyledFlex>
                      <DetailLabelText> Contact </DetailLabelText>
                      <div>{otherContacts.name2 || 'N/A'}</div>
                    </StyledFlex>
                    <StyledFlex>
                      <DetailLabelText> Relationship </DetailLabelText>
                      <div>{otherContacts.relationship2 || 'N/A'}</div>
                    </StyledFlex>
                    <StyledFlex>
                      <DetailLabelText> Phone Number </DetailLabelText>
                      <div>{otherContacts.phoneNumber2 || 'N/A'}</div>
                    </StyledFlex>
                    <StyledFlex>
                      <DetailLabelText> Email </DetailLabelText>
                      <div>{otherContacts.email2 || 'N/A'}</div>
                    </StyledFlex>
                  </SectionWrapper>
                )}
              </div>
            ) : null}
          </DetailWrapper>
        </LeftWrapper>
        <RightWrapper>
          <Tabs defaultActiveKey="Student">
            <TabPane tab="Student" key="Student">
              <ModelContainer divHeight={divHeight}>
                <ModelPage
                  showHeader={false}
                  modelName="user/parentChild"
                  itemsPerPage={25}
                  query={{ where: { parentId: match.params.id } }}
                  initialQuery={{ currentPage: 1 }}
                  actions={[{
                    action: () => setAddStudent(true),
                    label: 'Add Student',
                  }]}
                  allowSearch={false}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (divHeight !== height) setDivHeight(height);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Students" />}>
                        <AntDTable
                          modelName="user/parentChild"
                          cellRenderers={cellRenderers}
                          pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          scroll={{ x: 'max-content', y: '40vh' }}
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
          </Tabs>
        </RightWrapper>
      </BottomWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state.authentication),
  }
}

export default connect(mapStateToProps)(ShowView);
