import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import map from 'lodash/map';
import get from 'lodash/get';
import omit from 'lodash/omit';
import assign from 'lodash/assign';
import { palette, size } from 'styled-theme';
import { styles } from '../../../utils/table';
import Card from '../../atoms/Card';
import TableRow from '../../atoms/TableRow';
import Pagination from '../../atoms/Pagination';
import TableCellSortable from '../../molecules/TableCellSortable';
import DefaultCellComponent from '../../molecules/DefaultCellComponent';

const {
  defaultCell: defaultCellStyles,
  tableHeaderCell,
} = styles;

const StyledPagination = styled(Pagination)`
  display: table;
  margin: 24px auto 0;
`;

const Flex = styled.div`
  display: flex;
  flex-direction:  ${({ flexDirection }) => flexDirection};
`;

const CardContent = styled(Flex)`
  width: fit-content;
  min-width: 100%;
`;

const formatCellRenderers = (cellRenderers) => {
  return map(cellRenderers, (renderer) => {
    if (typeof renderer === 'string') {
      return {
        data: renderer,
        label: renderer,
        component: DefaultCellComponent,
        allowSort: false,
      };
    }

    if (typeof renderer === 'object') {
      const {
        data,
        label = data,
        component = DefaultCellComponent,
        componentProps,
        displayValue,
        style,
        allowSort = false,
      } = renderer;

      return {
        data,
        label,
        displayValue,
        component,
        componentProps,
        style,
        allowSort,
      };
    }

    console.warn('Unsupported cellRenderer, ', renderer);
  });
};

const TableCard = styled(Card)`
  padding: 0px;
  overflow-x: auto;
  display: flex;
`;

const RowDiv = styled.div`
  &:nth-child(even) {
    background: ${palette('grayscale', 5)};
  }
`;

const RowsContainer = styled.div`
  @media (max-width: ${size('breakpoint')}) {
    width: fit-content;
  }
`;


const Table =  (props) => {
  const {
    data,
    cellRenderers,
    RowHeaderComponent,
    RowComponent,
    totalItems,
    itemsPerPage,
    currentPage,
    onPageChange,
    pageReach,
    sortKey,
    sortOrder,
    onSort,
    history,
    modelName,
    selected,
    selectedChange,
  } = props;
  const formattedCellRenderers = formatCellRenderers(cellRenderers);
  const cellStyles = map(formattedCellRenderers, (renderer) => {
    return renderer.style;
  });
  const headerCellStyles = cellStyles.map(cellStyle => {
    return { ...tableHeaderCell, ...cellStyle };
  });

  return (
    <Fragment>
      <TableCard>
        <CardContent flexDirection="column">
          {/* HEADER */}
          <RowHeaderComponent
            cells={map(formattedCellRenderers, (renderer) => {
              return (
                <TableCellSortable
                  allowSort={renderer.allowSort}
                  sortKey={sortKey}
                  sortOrder={sortOrder}
                  onSort={onSort}
                  name={typeof renderer.data === 'string' ? renderer.data : renderer.label}
                  label={renderer.label}
                />
              );
            })}
            styles={headerCellStyles}
          />
          {/* END HEADER */}

          {/* if there are accordians, render each row as accordian panel header, and accordian panel accordingly,
          else render a normal row */}

          <RowsContainer>
            {data.map((rowData, i) => {
              return (
                <RowDiv key={i}>
                  <RowComponent
                    cells={formattedCellRenderers.map((renderer) => {
                      const cellData = typeof renderer.data === 'string'
                        ? get(rowData, renderer.data)
                        : renderer.data(rowData);
                      const displayValue = renderer.displayValue ? renderer.displayValue(cellData, rowData) : null;
                      const CellComponent = renderer.component;
                      if (renderer.componentProps) assign(renderer, renderer.componentProps); // merge componentProps into props
                      return (
                        <CellComponent
                          cellData={cellData}
                          rowData={rowData}
                          displayValue={displayValue}
                          history={history}
                          modelName={modelName}
                          selected={selected}
                          selectedChange={selectedChange}
                          {...omit(renderer, ['data', 'label', 'component', 'componentProps', 'style', 'displayValue'])}
                          otherProps={props}
                        />
                      );
                    })}
                    styles={cellStyles}
                  />
                </RowDiv>
              );
            })}
          </RowsContainer>
        </CardContent>
      </TableCard>
      {itemsPerPage > 0 && false ?
        <StyledPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={onPageChange}
          reach={pageReach}
        />
      : null}
    </Fragment>
  );
};

Table.defaultProps = {
  data: [{}],
  cellRenderers: [],
  RowHeaderComponent: rowProps => <TableRow {...rowProps} isHeader />,
  RowComponent: rowProps => <TableRow {...rowProps} />,
  AccordianHeaderComponent: rowProps => <TableRow {...rowProps} />,
  AccordianRowComponent: rowProps => <TableRow {...rowProps} />,
  itemsPerPage: 25,
  pageReach: 5,
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  RowHeaderComponent: PropTypes.func,
  RowComponent: PropTypes.func,
  AccordianRowComponent: PropTypes.func,
  AccordianHeaderComponent: PropTypes.func,
  cellRenderers: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        key: PropTypes.string,
        render: PropTypes.func,
      }),
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
      }),
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        render: PropTypes.func,
      }),
    ])
  ),
  sortKey: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  pageReach: PropTypes.number,
  onPageChange: PropTypes.func,
  onSort: PropTypes.func,
  selected: PropTypes.array,
  selectedChange: PropTypes.func,
  showAccordianCheckbox: PropTypes.bool,
  accordianSelection: PropTypes.string,
};

export default Table;
