import React, { Fragment } from 'react';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { Field, FormSpy } from 'react-final-form';
import styled from 'styled-components';
import { palette, size } from 'styled-theme';

import map from 'lodash/map';
import omit from 'lodash/omit';
import isFunction from 'lodash/isFunction';
import flatMap from 'lodash/flatMap';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import find from 'lodash/find';
import merge from 'lodash/merge';
import isNull from 'lodash/isNull';

import { modalShow } from '../store/modal/actions';
import store from '../store';

import Heading from '../components/atoms/Heading';
import Card from '../components/atoms/Card';
import { validations } from '../utils/form';
import P from '../components/atoms/P';
import Button from '../components/atoms/Button';
import FieldComponent from '../components/molecules/FieldComponent';
import Form from '../components/molecules/Form';
import useSpecForm from '../hooks/useSpecForm';
import PreviewModal from './PreviewModal';

const StyledHeading = styled(Heading)`
  margin: 0;
  line-height: ${size('lineHeight.heading.h3')};
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 -16px;
  justify-content: flex-end;
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

const ActionDeleteButton = styled(ActionButton)`
  margin-left: 30px;
`;

const StyledError = styled(P)`
  color: ${palette('error', 1)};
  background-color: #f7dddd;
  padding: 0.36px 8px;
  border: ${palette('error', 1)} 1px solid;
  border-radius: 4px;
  transition: all 1s ease;
  margin-bottom: 36px;
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NonSectionSpecsWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
  > div {
    width: 50%;
    padding-right: ${size('padding.default')};
    max-width: 50vw;
  }

  > div:nth-child(even) {
    padding-right: 0;
  }
`;

const FooterWrapper = styled.div`
`;

const FooterNote = styled(P)`
  padding-left: 8px;
`;

const ExplicitNullField = withProps({ parse: (v) => (isNull(v) ? null : v) })(Field);

const Spec = (props) => {
  const {
    required,
    fieldConfigs,
    disabled,
    formDisabled,
    config,
    setUploading,
    values,
    ...fieldProps
  } = props;
  const fieldConfig = fieldConfigs[fieldProps.name] || {};
  const mappedFieldConfig = omit({
    ...fieldConfig,
    ...(
      isFunction(fieldConfig.mapSpecPropsToFieldProps)
        ? fieldConfig.mapSpecPropsToFieldProps({ ...props })
        : {}
    ),
  }, 'mapSpecPropsToFieldProps');
  const FormField = get(mappedFieldConfig, 'CustomField', ExplicitNullField);
  const FormFieldComponent = get(mappedFieldConfig, 'CustomComponent', FieldComponent);
  const mappedRequired = mappedFieldConfig.required === false ? false : required;
  if (mappedFieldConfig.skip) return null;
  return (
    <FormField
      component={FormFieldComponent}
      validate={mappedRequired ? validations.required : null}
      disabled={disabled || formDisabled}
      config={config}
      setUploading={setUploading}
      {...fieldProps}
      {...mappedFieldConfig}
      values={values}
    />
  );
};
const SpecForm = (props) => {
  const {
    deletable,
    config,
    actions = [],
    showPreview,
    disabled,
    setOnSuccessAction,
    uploading,
    data,
    formName,
    spec: originalSpec,
    setUploading,
    footer,
    footerNote,
    footerContent,
    skipFields = [],
  } = props;
  const {
    errorMessage, formDisabled, handleDelete, onSubmit, initialValues,
  } = useSpecForm({
    endpoint: config.endpoint,
    id: get(data, 'id'),
    data,
    isCreateForm: true,
    pageUrl: config.pageUrl,
    labelsByEndpoint: config.labelsByEndpoint,
  });

  const spec = originalSpec.filter(({ name }) => skipFields.indexOf(name) < 0);
  const fieldConfigs = config.fieldConfigs || {};

  const multiLanguageLabel = {
    Submit: 'Submit',
  };

  const initialValueListFromFieldConfig = map(fieldConfigs, (val, key) => ({
    key,
    value: val,
  }));
  const initialValuesFromFieldConfig = reduce(
    initialValueListFromFieldConfig,
    (a, c) => {
      const initialValue = typeof get(c, 'value.initialValue', null) === 'function'
        ? get(c, 'value.initialValue', null)(initialValues)
        : get(c, 'value.initialValue', null);
      // override initialValue so field initialValue does not override form initialValues
      if (initialValue != null) {
        c.value.initialValue = initialValue;
      }
      return {
        ...a,
        ...(initialValue ? { [get(c, 'value.initialValueKey') || c.key]: initialValue } : {}),
      };
    },
    {},
  );
  const mergedInitialValues = {
    ...initialValues,
    ...initialValuesFromFieldConfig,
  };
  const specProps = {
    fieldConfigs,
    disabled,
    formDisabled,
    config,
    setUploading,
  };
  return (
    <>
      {errorMessage
        ? <StyledError semiBold error>{`${errorMessage} - Sorry, please try again!`}</StyledError>
        : null}
      <Form
        name={`SpecForm-${config.endpoint}`}
        onSubmit={onSubmit}
        initialValues={mergedInitialValues}
        render={(renderProps, a, b) => {
          const { form, values, ...others } = renderProps;
          const { handleSubmit, submitting } = others;
          return (
            <form onSubmit={handleSubmit}>
              <Card>
                <HeadingWrapper>
                  <StyledHeading level={4}>{formName}</StyledHeading>
                  <ButtonWrapper className="spec-form__submit">
                    {showPreview && false
                      ? (
                        <FormSpy subscription={{
                          values: true,
                          pristine: true,
                        }}
                        >
                          {(spyProps) => (
                            <ActionButton
                              key="preview-action"
                              type="button"
                              onClick={() => {
                                store.dispatch(modalShow('spec-form-preview-modal'));
                              }}
                              {...others}
                            >
                              Show Preview
                            </ActionButton>
                          )}
                        </FormSpy>
                      )

                      : null}
                    <ActionButton
                      type="submit"
                      disabled={submitting || formDisabled || uploading}
                    >
                      {multiLanguageLabel.Submit}
                    </ActionButton>

                    {actions.map(({
                      label, action, isSubmitHandler, disabled: actionIsDisabled, ...others
                    }) => (
                      <ActionButton
                        key={`${label}-action`}
                        type="button"
                        disabled={isSubmitHandler ? (submitting || formDisabled || uploading) : actionIsDisabled}
                        onClick={() => {
                          if (isSubmitHandler) {
                            setOnSuccessAction(() => action);
                            setTimeout(() => {
                              handleSubmit();
                            }, 0);
                            return;
                          }
                          return action(values, props, renderProps);
                        }}
                        {...others}
                      >
                        {label}
                      </ActionButton>
                    ))}

                    {deletable && handleDelete
                      ? (
                        <ActionDeleteButton
                          type="button"
                          palette="red"
                          onClick={handleDelete}
                          disabled={submitting || formDisabled || uploading}
                        >
                          {get(config, 'deleteButton.text', null) || 'Delete'}
                        </ActionDeleteButton>
                      )
                      : null}
                  </ButtonWrapper>
                </HeadingWrapper>
                {spec.length !== 0
                  && (
                  <NonSectionSpecsWrapper>
                    {spec.map((data, index) => (
                      <Spec
                        key={index}
                        {...specProps}
                        {...data}
                        values={values}
                      />
                    ))}
                  </NonSectionSpecsWrapper>
                  )}
                {footer
                  ? (
                    <FooterWrapper>
                      {footerNote
                        ? <FooterNote>{footerContent}</FooterNote>
                        : null }
                    </FooterWrapper>
                  )
                  : null}
              </Card>
              <pre>{JSON.stringify(values, 0, 2)}</pre>
              <PreviewModal values={values} />
            </form>
          );
        }}
      />
    </>
  );
};

SpecForm.defaultProps = {
  deletable: false,
  tabs: [],
  footer: false,
  footerNote: false,
  footerContent: '',
};

SpecForm.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    sections: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.string),
    })),
  })),
  formName: PropTypes.string,
  formDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  deletable: PropTypes.bool,
  spec: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    required: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })),
  })),
  localizationSpec: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    required: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
    })),
  })),
  config: PropTypes.shape({
    endpoint: PropTypes.string.isRequired,
  }).isRequired,
  initialValues: PropTypes.object,
  disabled: PropTypes.object,
  uploading: PropTypes.bool,
  setUploading: PropTypes.func,
  footer: PropTypes.bool,
  footerNote: PropTypes.bool,
  footerContent: PropTypes.string,
};

export default SpecForm;
