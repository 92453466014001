import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import startCase from 'lodash/startCase';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { palette, size } from 'styled-theme';
import { select } from 'glamor';
import Form from '../Form';
import Button from '../../atoms/Button';
import FieldComponent from '../FieldComponent';
import Label from '../../atoms/Label';
import withFilterBlock from '../../../containers/enhancers/withFilterBlock';
import IconButton from '../IconButton';

const StyledForm = styled.form`
  padding: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    width: 49%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const FilterBlockHeader = styled.div`
  display: flex;
  margin-bottom: 10px;
  color: ${palette('danger', 0)};
  cursor: pointer;
  font-weight: bold;
  align-items: center;

  @media (max-width: ${size('mobileBreakpoint')}) {
    display: none;
  }
`;

const FilterBlockContainer = styled.div`
  order: 3;
  width: 100%;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.2s ease;
  box-shadow: ${size('boxShadow.small')};
  background-color: ${palette('white', 0)};
  border-radius: 4px;
  border: 0.5px solid transparent;

  &.active {
    overflow: visible;
    max-height: 1000px;
    border: 0.5px solid ${palette('primary', 0)};
    transition: all 0.2s ease;
    margin-bottom: 20px;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    display: none;
  }
`;

const InputTextWrapper = styled.div`

`;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 21px;
`;

const RadioWrapper = styled.div`
  display: flex;
  margin-left: -10px;
  > * {
    width: 19%;
    padding: 10px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  > * {
    margin-right: 10px;
  }
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const StyledIconButton = styled(IconButton)`
  color: ${palette('primary', 0)};
  background-color: ${palette('white', 0)};
  border: 1px solid ${palette('primary', 0)};
  border-radius: 0px;

  > div {
    span {
      border-color: #fff;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${palette('white', 0)};
    color: ${palette('primary', 0)};
  }
`;

const ClearButton = styled(Button)`
  color: ${palette('grayscale', 0)};
  background-color: ${palette('white', 0)};
  border: 1px solid ${palette('grayscale', 0)};
  border-radius: 0px;
  margin-right: 0px;

  &:hover,
  &:focus,
  &:active {
    color: ${palette('grayscale', 0)};
    background-color: ${palette('white', 0)};
    border: 1px solid ${palette('grayscale', 0)};
  }
`;

const FilterItem = ({ form, filter, values }) => {
  switch (filter.type) {
    case 'text':
      return (
        <InputTextWrapper>
          <Field
            name={filter.name}
            label={filter.label}
            component={FieldComponent}
            showTime={filter.showTime}
            type="text"
            placeholder={filter.placeholder || `Enter ${filter.label}`}
          />
        </InputTextWrapper>
      );
    case 'number':
      return (
        <div>
          <Field
            name={filter.name}
            label={filter.label}
            component={FieldComponent}
            type="number"
            placeholder={filter.placeholder || `Enter ${filter.label} eg. 1, 2`}
          />
        </div>
      );
    case 'spec':
      return (
        <div>
          <Field
            component={FieldComponent}
            type="spec"
            placeholder={filter.placeholder || `Enter ${filter.label}`}
            {...filter}
          />
        </div>
      );
    case 'checkbox':
      return (
        <CheckboxWrapper>
          <Field
            name={filter.name}
            label={filter.label}
            component={FieldComponent}
            type="checkbox"
            renderError={false}
          />
        </CheckboxWrapper>
      );
    case 'radio': {
      const radioOptions = filter.radios;
      return (
        <div>
          <Label>{startCase(filter.label)}</Label>
          <RadioWrapper>
            {
              Object.keys(radioOptions).map((option) => (
                <Field
                  key={radioOptions[option]}
                  name={filter.name}
                  type="radio"
                  label={startCase(radioOptions[option])}
                  value={radioOptions[option]}
                  id={radioOptions[option]}
                  component={FieldComponent}
                  renderError={false}
                />
              ))
            }
          </RadioWrapper>
        </div>
      );
    }
    case 'dateRange':
      return (
        <div>
          <Field
            name={filter.name}
            label={filter.label}
            component={FieldComponent}
            type="dateRange"
            renderError={false}
            showDate={filter.showDate}
            showTime={filter.showTime}
            showYear={filter.showYear}
          />
        </div>
      );
    case 'select':
    case 'multi-select': {
      return (
        <div>
          <Field
            component={FieldComponent}
            placeholder={filter.placeholder || `Enter ${filter.label}`}
            renderError={false}
            {...filter}
          />
        </div>
      );
    }
    case 'checkboxGroup':
      return (
        <div>
          <Field
            name={filter.name}
            label={filter.label}
            options={filter.options}
            component={FieldComponent}
            type="checkboxGroup"
            renderError={false}
          />
        </div>
      );
    case 'radioGroup':
      return (
        <div>
          <Field
            name={filter.name}
            label={filter.label}
            options={filter.options}
            component={FieldComponent}
            type="radioGroup"
            renderError={false}
          />
        </div>
      );
    default:
      return null;
  }
};

const FilterBlock = ({
  formName,
  filterConfig,
  onReset,
  onSubmit,
  initialValues,
  showFilterBlock,
  setShowFilterBlock,
  setFilterBlockVisibility,
  t,
  query,
  setQuery,
  radioGroupKey,
  filterBy,
  ...rest
}) => {
  const multiLanguageLabel = {
    Filter: 'Filter',
    Search: 'Search',
    Clear: 'Clear',
  };
  // if (window.innerWidth < 1024) return null;
  return (
    <>
      <FilterBlockContainer
        active={showFilterBlock}
        className={showFilterBlock ? 'active' : 'inactive'}
      >
        <Form
          name={formName}
          initialValues={{ ...filterBy }}
          onSubmit={onSubmit}
          subscription={{ values: true }}
          render={({
            handleSubmit, submitting, form, values,
          }) => (
            <StyledForm onSubmit={handleSubmit}>
              <Wrapper>
                {filterConfig.map((filter, index) => (
                  <FilterItem
                    form={form}
                    key={index}
                    filter={filter}
                    values={values}
                  />
                ))}
              </Wrapper>
              <ButtonWrapper>
                <Button small type="submit" disabled={submitting} style={{ borderRadius: 0 }}>
                  {multiLanguageLabel.Search}
                </Button>
                <ClearButton small type="reset" onClick={() => onReset(form)}>
                  {multiLanguageLabel.Clear}
                </ClearButton>
              </ButtonWrapper>
            </StyledForm>
          )}
        />
      </FilterBlockContainer>
      <FilterBlockHeader onClick={() => setShowFilterBlock(!showFilterBlock)}>
        <StyledIconButton
          style={{ height: 40 }}
          icon={!showFilterBlock ? 'filter-dropdown' : 'filter-goback'}
          iconReverse={showFilterBlock}
          right
        >{multiLanguageLabel.Filter}
        </StyledIconButton>
      </FilterBlockHeader>
    </>
  );
};

FilterBlock.propTypes = {
  formName: PropTypes.string,
  filterConfig: PropTypes.array,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  showFilterBlock: PropTypes.bool,
  setShowFilterBlock: PropTypes.func,
  setFilterBlockVisibility: PropTypes.func,
  t: PropTypes.func,
};

FilterBlock.defaultProps = {
  formName: 'filter-form',
};

const StyledFilterBlock = styled(FilterBlock)`
  @media (max-width: ${size('mobileBreakpoint')}) {
    display: none;
  }
`;

export default compose(withFilterBlock)(StyledFilterBlock);
