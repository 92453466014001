import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';

export default (options = {}) => {
  const { endpoint, setErrorMessage, user, type, action } = options;

  const history = useHistory();
  const [formDisabled, setFormDisabled] = useState(true);

  const { api } = global;
  let onSubmit;

  const getRedirectUrl = () => {
    if (type === 'parent') {
      return '/user';
    } if (type === 'student') {
      return '/student';
    } if (type === 'staff') {
      return '/staff';
    } if (type === 'package') {
      return '/package';
    } if (type === 'library') {
      return '/library';
    } if (type === 'inventory') {
      return '/inventory';
    } if (type === 'sale') {
      return '/sale';
    } if (type === 'broadcast') {
      return '/broadcast';
    }
  };

  if (action === 'create') {
    onSubmit = async (values, options = {}) => {
      values.type = type;

      return api
        .post(`/${endpoint}`, values)
        .then(() => {
          if (type === 'instructor' || type === 'settings' || type === 'libraryDetail' || type === 'inventoryItem' || type === 'room' || type === 'group') window.location.reload();
          else history.push(getRedirectUrl());
        })
        .catch((error) => setErrorMessage(error.message));
    }
  } else if (action === 'delete') {
    onSubmit = async (id, options = {}) => {
      console.log(options);
      return api
        .delete(`/${endpoint}/${id}`, { params: id, data: options })
        .then(() => { window.location.reload(false) })
        .catch((error) => setErrorMessage(error.message));
    }
  } else if (action === 'update') {
    onSubmit = async (values) => {
      // console.log(values);
      return api
        .put(`/${endpoint}`, values)
        .then(() => { window.location.reload(false) })
        .catch((error) => setErrorMessage(error.message));
    }
  } else if (action === 'edit') {
    onSubmit = async (values) => {
      const getEditRedirectUrl = () => {
        if (type === 'parent') return `/user/show/${values.userId}`;
        if (type === 'package') return `/package/show/${values.packageId}`;
        if (type === 'library') return `/library/show/${values.libraryId}`;
        if (type === 'inventory') return `/inventory/show/${values.inventoryId}`;
        if (type === 'staff') return `/staff/show/${values.userId}`;
        if (type === 'student') return `/student/show/${values.id}`;
      };

      return api
        .put(`/${endpoint}`, values)
        .then(() => {
          if (type === 'sale' || type === 'broadcast') history.goBack()
          else history.push(getEditRedirectUrl());
        })
        .catch((error) => setErrorMessage(error.message));
    }
  } else if (action === 'updateInventoryItem') {
    onSubmit = async (values) => {
      return api
        .put(`/${endpoint}`, values)
        .then((res) => {
          if (!res.inventoryId) window.location.reload(false);
          else {
            history.push(`${res.inventoryId}`);
            window.location.reload(false);
          }
        })
        .catch((error) => setErrorMessage(error.message));
    }
  }

  return {
    onSubmit,
    formDisabled,
  };
};
