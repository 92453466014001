import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import NotFound from '../NotFound';
import ListView from './ListView';
import EditView from './EditView';

const SalePage = ({ match: { path } }) => (
  <Switch>
    <Route exact path={path} component={ListView} />
    <Route path={`${path}/create`} component={EditView} />
    <Route path={`${path}/edit/:id`} component={EditView} />
    <Route component={NotFound} />
  </Switch>
);

SalePage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default SalePage;
