import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Link from '../../atoms/Link';
import useCalendarForm from '../../../hooks/useCalendarForm';
import Flex from '../../atoms/Flex';
import FieldComponent from '../../molecules/FieldComponent';
import { validations } from '../../../utils/form';
import inputOptions from '../../../utils/form/inputOptions';
import ErrorAlert from '../../molecules/ErrorAlert';

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 45vw;
  min-width: 45vw;
  padding: 20px;
  max-height: 98vh;

  > form {
    height: calc(100% - 87px);
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledButton2 = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 5px;
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 17px 15px 22px 0px;
  width: 160px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledButton = styled(Button)`
  min-width: 180px;
  margin: ${size('margin.default')} 0px ${size('margin.default')};
  border-radius: 0px;
  width: calc(100% - 20px);
`;

const CreditValueWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const CreditValueText = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
`;

const CreditValueField = styled(StyledField)`
  input {
    padding-right: 40px;
  }
`;

const ErrorAlertContainer = styled.div`
  > div {
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: 75vw;
      width: fit-content;
    }
  }
`;

const PopUpCredit = ({ open, user, child, instructor, currPackage, selectedCredit, type, onClose }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const { onSubmit } = useCalendarForm({
    endpoint: type === 'edit' ? 'package/editCredit' : 'package/updateCredit',
    setErrorMessage,
    type: 'attendance',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const sortedChild = child.map((c) => {
    c.label = `${c.firstName} ${c.lastName}`;
    c.value = c.id;
    return c;
  }).sort((a, b) => (a.label > b.label ? 1 : -1));

  const sortedInstructor = instructor.map((i) => {
    i.label = `${i.firstName} ${i.lastName}`;
    i.value = i.id;
    return i;
  }).sort((a, b) => (a.label > b.label ? 1 : -1));

  const initialValues = selectedCredit ? {
    packageCode: `P${currPackage.id}`,
    creditType: selectedCredit.type,
    description: selectedCredit.description,
    student: selectedCredit.childId,
    date: selectedCredit.date,
    instructor: selectedCredit.instructorId,
    initialPrice: selectedCredit.initPrice !== null ? Number(selectedCredit.initPrice) : null,
    lateFee: selectedCredit.formattedLateFee,
    credit: selectedCredit.status === 'Pending' ? (selectedCredit.initPrice !== null ? Number(selectedCredit.initPrice) : null) : (selectedCredit.creditUsed ? Number(selectedCredit.creditUsed) : null),
  } : {
    packageCode: `P${currPackage.id}`,
  };

  return (
    <StyledPopup
      open={open}
      trigger={type === 'edit' ? <StyledButton2> Edit </StyledButton2> : null}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          <PopUpButton className="close" onClick={type === 'edit' ? () => close() : () => { close(); onClose(); }}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper> {type === 'edit' ? 'Edit' : type === 'add' ? 'Add Credit' : 'Deduct Credit'} </PopUpTextWrapper>
          <Form
            name="CreditForm"
            onSubmit={onSubmit}
            initialValues={initialValues}
            initialValuesEqual={() => true}
            render={({ handleSubmit, submitting, values }) => {
              if (type === 'edit') values.creditId = selectedCredit.id;
              values.packageId = currPackage.id;
              values.actionType = type;
              values.valueType = currPackage.valueType;

              let creditOptions = inputOptions.creditTypeOptions;
              if (type === 'add') creditOptions = inputOptions.creditTypeOptions.filter((c) => c.label !== 'Purchase' && c.label !== 'Credit Deduct');
              else creditOptions = inputOptions.creditTypeOptions.filter((c) => c.label !== 'Sell' && c.label !== 'Credit Transfer' && c.label !== 'Refund');

              let finalCredits = (selectedCredit && selectedCredit.creditUsed) ? Number(selectedCredit.creditUsed) : null;
              if (selectedCredit && selectedCredit.status === 'Pending') {
                finalCredits = Number(+(Number(values.initialPrice * values.lateFee).toFixed(2)));
              }

              return (
                <StyledForm onSubmit={handleSubmit}>
                  <div style={{ paddingRight: '20px', maxHeight: '100%', overflow: 'auto' }}>
                    <ErrorAlertContainer>
                      <ErrorAlert
                        errorMessage={errorMessage}
                        show={errorMessage !== null}
                      />
                    </ErrorAlertContainer>

                    <FieldFlex>
                      <StyledText>Package Code: </StyledText>
                      <StyledField
                        name="packageCode"
                        type="text"
                        disabled
                        component={FieldComponent}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Credit Type: </StyledText>
                      <StyledField
                        name="creditType"
                        type="select"
                        component={FieldComponent}
                        disabled={type === 'edit'}
                        options={creditOptions}
                        validate={validations.required}
                        menuPortalTarget={document.getElementById('popup-root')}
                        menuPlacement="auto"
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Description: </StyledText>
                      <StyledField
                        name="description"
                        type="text"
                        disabled={type === 'edit'}
                        component={FieldComponent}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Student (if any): </StyledText>
                      <StyledField
                        name="student"
                        type="select"
                        component={FieldComponent}
                        disabled={type === 'edit'}
                        isClearable={type !== 'edit'}
                        options={type === 'edit' ? sortedChild : sortedChild.filter((c) => !c.deletedAt)}
                        menuPortalTarget={document.getElementById('popup-root')}
                        menuPlacement="auto"
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Date: </StyledText>
                      <StyledField
                        name="date"
                        type="date"
                        component={FieldComponent}
                        disabled={type === 'edit'}
                        isClearable={type !== 'edit'}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Instructor (if any): </StyledText>
                      <StyledField
                        name="instructor"
                        type="select"
                        component={FieldComponent}
                        options={(type === 'edit' ? sortedInstructor : sortedInstructor.filter((c) => !c.deletedAt))}
                        isClearable={type !== 'edit'}
                        disabled={type === 'edit'}
                        menuPortalTarget={document.getElementById('popup-root')}
                        menuPlacement="auto"
                      />
                    </FieldFlex>

                    { (selectedCredit && selectedCredit.status) === 'Pending' ? (
                      <div>
                        <FieldFlex>
                          <StyledText>Initial Price: </StyledText>
                          <CreditValueWrapper>
                            <CreditValueField
                              name="initialPrice"
                              type="number"
                              min={0}
                              step="any"
                              component={FieldComponent}
                              validate={validations.required}
                            />
                            <CreditValueText> HKD </CreditValueText>
                          </CreditValueWrapper>
                        </FieldFlex>

                        <FieldFlex>
                          <StyledText>Cancellation Fee: </StyledText>
                          <StyledField
                            name="lateFee"
                            type="select"
                            component={FieldComponent}
                            options={inputOptions.cancellationFeeOptions}
                            validate={validations.required}
                            menuPortalTarget={document.getElementById('popup-root')}
                            menuPlacement="auto"
                          />
                        </FieldFlex>
                      </div>
                    ) : null}

                    <FieldFlex>
                      <StyledText>{user.role === 'superAdmin' ? 'External Credit:' : 'Credit Used:'}</StyledText>
                      <CreditValueWrapper>
                        <CreditValueField
                          name="credit"
                          type="number"
                          min={0}
                          step="any"
                          disabled={selectedCredit && selectedCredit.status === 'Pending'}
                          initialValue={finalCredits}
                          component={FieldComponent}
                          validate={validations.required}
                        />
                        <CreditValueText> HKD </CreditValueText>
                      </CreditValueWrapper>
                    </FieldFlex>
                  </div>

                  <StyledButton type="submit" disabled={submitting}> Save </StyledButton>
                </StyledForm>
              )
            }}
          />
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpCredit.propTypes = {
  user: PropTypes.object.isRequired,
  child: PropTypes.array.isRequired,
  instructor: PropTypes.array.isRequired,
};

export default PopUpCredit;
