import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { size, palette, font } from 'styled-theme';
import omit from 'lodash/omit';
import moment from 'moment';
import get from 'lodash/get';
import FieldComponent from '../../molecules/FieldComponent';
import Button from '../../atoms/Button';
import Card from '../../atoms/Card';
import { validations } from '../../../utils/form';
import inputOptions from '../../../utils/form/inputOptions';
import Icon from '../../atoms/Icon';
import Flex from '../../atoms/Flex';

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 5px;
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 17px 15px 22px 0px;
  width: 180px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin: ${size('margin.default')} auto ${size('margin.default')};
  border-radius: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
`;

const OtherStyledButton = styled(Button)`
  width: 100%;
  margin: ${size('margin.default')} auto ${size('margin.default')};
  color: ${palette('primary', 0)};
  background-color: ${palette('white', 0)};
  border-color: ${palette('primary', 0)};
  border-radius: 0px;
  margin: 10px 50px 0px 0px;
  text-align: center;

  &:hover,
  &:focus,
  &:active {
    background-color: ${palette('white', 0)};
    color: ${palette('primary', 0)};
    text-decoration: none;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
`;

const StyledText2 = styled.div`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  padding: 10px 0px 20px 0px;
  text-align: center;
`;

const StyledText3 = styled.div`
  font-family: ${font('primary')};
  color: ${palette('red', 3)};
  font-size: 14px;
  padding: 10px 0px 20px 0px;
  text-align: center;
`;

const RequestForm = ({ onSubmit, child, instructor, selectedEvent, eventInstructor, type, user, selectedRequest, allLessons }) => {
  const sortedChild = child.map((c) => {
    c.label = `${c.firstName} ${c.lastName}`;
    c.value = c.id;
    return c;
  }).sort((a, b) => (a.label > b.label ? 1 : -1));

  const sortedInstructor = instructor.map((i) => {
    i.label = `${i.firstName} ${i.lastName}`;
    i.value = i.id;
    return i;
  }).sort((a, b) => (a.label > b.label ? 1 : -1));

  const [firstPage, setFirstPage] = useState(true);

  let initialValues = null;

  if (user.role === 'parent') {
    initialValues = {
      title: selectedEvent.title,
      student: selectedEvent.childId,
      instructor: eventInstructor[0],
      date: selectedEvent.date,
      startTime: moment(selectedEvent.startTime).format('HH:mm'),
      endTime: moment(selectedEvent.endTime).format('HH:mm'),
    }
  } else if (user.role === 'admin' || user.role === 'superAdmin') {
    if (type === 'reschedule' || type === 'cancel') {
      let recurring = {};
      if (type === 'reschedule') recurring = { recurringType: selectedRequest.recurringType };

      initialValues = {
        lessonType: selectedRequest.event.lessonType,
        title: selectedRequest.event.title,
        student: selectedRequest.childId,
        instructor: selectedRequest.instructorId,
        date: selectedRequest.event.date,
        startTime: moment(selectedRequest.event.startTime).format('HH:mm'),
        endTime: moment(selectedRequest.event.endTime).format('HH:mm'),
        reason: selectedRequest.reason,
        note: selectedRequest.note,
        ...recurring,
      }
    } else if (type === 'purchase') {
      initialValues = {
        student: selectedRequest.childId,
        instructor: selectedRequest.instructorId,
        itemType: selectedRequest.itemType,
        itemName: selectedRequest.itemName,
        publisher: selectedRequest.publisher,
        note: selectedRequest.note,
        reason: selectedRequest.reason,
      }
    }
  }

  let lessonOptions = user.role === 'parent' ? allLessons : allLessons.filter((l) => l.type === 'Private Lesson');

  lessonOptions.map((l) => {
    l.label = l.name;
    l.value = l.name;
    return l;
  })

  const allLessonOptions = inputOptions.privateLessonOptions.concat(inputOptions.groupLessonOptions);

  const timeToLesson = moment(selectedEvent.startTime).diff(moment(), 'hours');
  let timeType = null;
  let discount = null;

  if (timeToLesson < 24) {
    timeType = 24;
    discount = '100%';
  } else if (timeToLesson < 48) {
    timeType = 48;
    discount = '50%';
  }

  return (
    <Form
      name="RequestForm"
      onSubmit={onSubmit}
      initialValues={initialValues}
      initialValuesEqual={() => true}
      render={({ handleSubmit, invalid, submitting }) => (
        <StyledForm onSubmit={handleSubmit}>
          <div style={{ paddingRight: '20px', maxHeight: '100%', overflow: 'auto' }}>
            { (firstPage === false) ? (
              <div>
                { (timeType != null) ? (
                  <TopWrapper>
                    <Icon icon={timeType === 24 ? 'lesson-cancel' : 'lesson-cancel-orange'} height="85" />
                    <StyledText3 style={{ color: timeType === 48 && '#FF6B00' }}>
                      Please note that you have less than {timeType} hours until your class and {discount} of the class fee will be deducted. If you wish to reschedule the class instead of cancelling, please press “reschedule”. A kind reminder that students have to keep regular attendance to maintain the weekly slot. M.int Academy may give away your slot if regular attendance is not met. The admin team will be in touch if such a situation arises. Thank you!
                    </StyledText3>
                  </TopWrapper>
                ) : (
                  <TopWrapper>
                    <Icon icon="lesson-cancel-more" height="85" style={{ width: '95px' }} />
                    <StyledText2>
                      Your class will be cancelled. A kind reminder that students have to keep regular attendance to maintain the weekly slot. M.int Academy may give away your slot if regular attendance is not met. The admin team will be in touch if such a situation arises. Thank you!
                    </StyledText2>
                  </TopWrapper>
                )}
              </div>
            ) : null}
            { (type === 'reschedule' || type === 'cancel') && (
              <div>
                { (user.role === 'admin' || user.role === 'superAdmin') && (
                  <FieldFlex>
                    <StyledText>Lesson Type: </StyledText>
                    <StyledField
                      name="lessonType"
                      type="select"
                      component={FieldComponent}
                      disabled
                      options={inputOptions.lessonTypeOptions}
                      validate={validations.required}
                    />
                  </FieldFlex>
                )}

                <FieldFlex>
                  <StyledText>Lesson: </StyledText>
                  <StyledField
                    name="title"
                    type="select"
                    component={FieldComponent}
                    options={lessonOptions}
                    disabled
                    validate={validations.required}
                  />
                </FieldFlex>
              </div>
            )}

            <FieldFlex>
              <StyledText>Student: </StyledText>
              <StyledField
                name="student"
                type="select"
                component={FieldComponent}
                options={sortedChild}
                disabled
                validate={validations.required}
              />
            </FieldFlex>

            <FieldFlex>
              <StyledText>Instructor: </StyledText>
              <StyledField
                name="instructor"
                type="select"
                component={FieldComponent}
                options={sortedInstructor}
                disabled
                validate={validations.required}
              />
            </FieldFlex>

            { (type === 'reschedule' || type === 'cancel') && (
              <FieldFlex>
                <StyledText>Date: </StyledText>
                <StyledField
                  name="date"
                  type="date"
                  component={FieldComponent}
                  disabled
                  isClearable={false}
                  validate={validations.required}
                />
              </FieldFlex>
            )}

            { (type === 'reschedule' || type === 'cancel') && (
              <FieldFlex>
                <StyledText>Time: </StyledText>
                <FieldFlex style={{ width: '100%', alignItems: 'baseline', flexDirection: 'row' }}>
                  <StyledField
                    name="startTime"
                    type="time"
                    component={FieldComponent}
                    disabled
                    isClearable={false}
                    validate={validations.required}
                  />

                  <div style={{ marginLeft: '10px' }}> - </div>
                  <StyledField style={{ marginLeft: '10px' }}
                    name="endTime"
                    type="time"
                    disabled
                    isClearable={false}
                    component={FieldComponent}
                  />
                </FieldFlex>
              </FieldFlex>
            )}

            { type === 'reschedule' && (
              <FieldFlex>
                <StyledText>Type: </StyledText>
                <StyledField
                  name="recurringType"
                  type="select"
                  component={FieldComponent}
                  disabled={!firstPage || (user.role === 'admin' || user.role === 'superAdmin')}
                  options={inputOptions.typeOptions}
                  validate={validations.required}
                  menuPortalTarget={document.getElementById('popup-root')}
                  menuPlacement="auto"
                />
              </FieldFlex>
            )}

            { type === 'purchase' && (
              <div>
                <FieldFlex>
                  <StyledText>Type: </StyledText>
                  <StyledField
                    name="itemType"
                    type="select"
                    component={FieldComponent}
                    disabled={user.role === 'admin' || user.role === 'superAdmin'}
                    options={inputOptions.purchaseRequestTypeOptions}
                    validate={validations.required}
                    menuPortalTarget={document.getElementById('popup-root')}
                    menuPlacement="auto"
                  />
                </FieldFlex>

                <FieldFlex>
                  <StyledText>Product: </StyledText>
                  <StyledField
                    name="itemName"
                    type="text"
                    component={FieldComponent}
                    disabled={user.role === 'admin' || user.role === 'superAdmin'}
                    validate={validations.required}
                  />
                </FieldFlex>

                <FieldFlex>
                  <StyledText>Publisher / Maker <div> (if any): </div> </StyledText>
                  <StyledField
                    name="publisher"
                    type="text"
                    component={FieldComponent}
                    disabled={user.role === 'admin' || user.role === 'superAdmin'}
                  />
                </FieldFlex>
              </div>
            )}

            { (type === 'reschedule' || type === 'cancel') ? (
              <FieldFlex>
                <StyledText>Reason: </StyledText>
                <StyledField
                  name="reason"
                  type="select"
                  component={FieldComponent}
                  disabled={!firstPage || (user.role === 'admin' || user.role === 'superAdmin')}
                  options={inputOptions.reasonOptions}
                  validate={validations.required}
                  menuPortalTarget={document.getElementById('popup-root')}
                  menuPlacement="auto"
                />
              </FieldFlex>
            ) : (
              <FieldFlex>
                <StyledText>Reason (if any): </StyledText>
                <StyledField
                  name="reason"
                  type="text"
                  component={FieldComponent}
                  disabled={user.role === 'admin' || user.role === 'superAdmin'}
                />
              </FieldFlex>
            )}

            <FieldFlex style={{ alignItems: 'normal' }}>
              <StyledText> {type === 'reschedule' ? 'Preferred time/date:' : 'Note (if any):'} </StyledText>
              <StyledField
                name="note"
                type="textarea"
                disabled={!firstPage || (user.role === 'admin' || user.role === 'superAdmin')}
                component={FieldComponent}
              />
            </FieldFlex>
          </div>
          { user.role === 'parent' && (
            <div style={{ marginRight: 20 }}>
              { type === 'cancel' && (
                <div>
                  { firstPage
                    ? <StyledButton onClick={() => setFirstPage(false)} disabled={invalid}> Next </StyledButton>
                    : (
                      <ButtonWrapper style={{ paddingRight: 0 }}>
                        <StyledButton style={{ marginRight: '50px' }} onClick={() => setFirstPage(true)}> Back </StyledButton>
                        <StyledButton disabled={submitting} type="submit"> Confirm </StyledButton>
                      </ButtonWrapper>
                    )}
                </div>
              )}
              { type === 'reschedule' && (<StyledButton disabled={submitting} type="submit"> Confirm </StyledButton>)}
            </div>
          )}
          { ((user.role === 'admin' || user.role === 'superAdmin') && (type === 'reschedule' || type === 'cancel')) ? (
            <ButtonWrapper>
              <OtherStyledButton to={`/calendar?date=${get(selectedRequest, 'event.date', '')}`}> Go to Calendar </OtherStyledButton>
              <StyledButton type="submit" disabled={submitting}> Completed </StyledButton>
            </ButtonWrapper>
          ) : ((user.role === 'admin' || user.role === 'superAdmin') && type === 'purchase') && (
            <ButtonWrapper>
              <OtherStyledButton to={get(selectedRequest, 'inventoryId', null) ? `/inventory/show/${get(selectedRequest, 'inventoryId', '')}` : '/inventory'}> Go to Inventory </OtherStyledButton>
              <StyledButton type="submit" disabled={submitting}> Completed </StyledButton>
            </ButtonWrapper>
          )}
        </StyledForm>
      )}
    />
  )
};

RequestForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  child: PropTypes.array.isRequired,
  instructor: PropTypes.array.isRequired,
  selectedEvent: PropTypes.object,
  eventInstructor: PropTypes.array,
  type: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  selectedRequest: PropTypes.object,
  allLessons: PropTypes.array,
};

export default RequestForm;
