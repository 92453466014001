import { useState } from 'react';
import get from 'lodash/get';

export default (options = {}) => {
  const { endpoint, setErrorMessage } = options;
  const [formDisabled, setFormDisabled] = useState(true);

  const { api } = global;
  let onSubmit;

  onSubmit = async (id, color) => {
    const values = { id, color };

    return api
      .post(`/${endpoint}`, { values })
      .catch((error) => setErrorMessage(error.message));
  };

  return {
    onSubmit,
    formDisabled,
  };
};
