import { compose, withProps, withState } from 'recompose';
import SetPasswordForm from '../../components/organisms/SetPasswordForm';
import withQueryParams from '../../containers/enhancers/withQueryParams';

export default compose(
  withQueryParams(),
  withState('error', 'setError', null),
  withState('success', 'setSuccess', false),
  withProps(({
    queryParams,
    setError,
    setSuccess,
  }) => {
    const { token, userId } = queryParams;
    return {
      onSubmit: values => {
        setError(null);
        setSuccess(false);
        return global.authApi.post(`/set-password/${userId}`, {
          token,
          ...values,
        })
          .then(() => {
            setSuccess(true);
          })
          .catch(err => {
            setError(err.message);
          });
      },
    };
  }),
)(SetPasswordForm);
