/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image, PDFViewer, Font } from '@react-pdf/renderer';
import toUpper from 'lodash/toUpper';
import get from 'lodash/get';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Flex from '../../atoms/Flex';
import invoiceLogo from '../../../asset/image/invoicelogo.png';
import MyImage from '../../atoms/Image';
import avenirMedium from '../../../asset/font/AvenirLTStd-Medium.otf';
import avenirBook from '../../../asset/font/AvenirLTStd-Book.otf';
import avenirBookItalic from '../../../asset/font/Avenir-Book-Italic.ttf';
import Loading from '../../atoms/Loading';

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  padding: 20px;
  max-height: 98vh;
  max-width: 90vw;
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;
  text-align: left;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const SaveButton = styled(Button)`
  min-width: 160px;
  width: 100%;
  margin: ${size('margin.default')} auto ${size('margin.default')};
  border-radius: 0px;
  margin-top: 25px;
  margin-bottom: 0px;
`;

const Wrapper = styled.div`
  max-height: 100%;
  overflow-y: auto;
`;

const StyledPDFDownloadLink = styled(PDFDownloadLink)`
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`;

Font.register({
  family: 'Avenir Medium',
  fonts: [{ format: 'opentype', src: avenirMedium }],
});
Font.register({
  family: 'Avenir Book',
  fonts: [
    { format: 'opentype', src: avenirBook },
    { format: 'truetype', src: avenirBookItalic, fontStyle: 'italic' },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: 595,
    minWidth: 595,
    border: '1px solid #F2F2F2',
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol1Header: {
    width: '60%',
    borderStyle: 'solid',
    borderColor: '#BDBDBD',
    borderBottomColor: '#EBEBEB',
    borderWidth: 1,
    borderTopWidth: 0,
    display: 'flex',
    backgroundColor: '#EBEBEB',
  },
  tableCol2Header: {
    width: '40%',
    borderStyle: 'solid',
    borderColor: '#BDBDBD',
    borderBottomColor: '#EBEBEB',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: 'flex',
    backgroundColor: '#EBEBEB',
  },
  tableCol1: {
    width: '60%',
    borderStyle: 'solid',
    borderColor: '#BDBDBD',
    borderWidth: 1,
    borderTopWidth: 0,
    display: 'flex',
  },
  tableCol2: {
    width: '40%',
    borderStyle: 'solid',
    borderColor: '#BDBDBD',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: 'flex',
  },
  tableCellHeader: {
    fontSize: 12,
    flex: 1,
    fontFamily: 'Avenir Medium',
    textAlign: 'center',
  },
  tableCell1: {
    marginRight: 5,
    marginLeft: 5,
    fontSize: 10,
    fontFamily: 'Avenir Book',
  },
  tableCell2: {
    marginRight: 5,
    fontSize: 10,
    flex: 1,
    textAlign: 'right',
    fontFamily: 'Avenir Book',
  },
  headerContainer: {
    display: 'flex',
    borderBottom: '2px solid #000',
    marginBottom: 10,
  },
  logo: {
    height: 80,
    width: 80,
    alignSelf: 'center',
    marginTop: 48,
    marginBottom: 12,
  },
  boldText: {
    color: '#000',
    fontSize: 12,
    fontFamily: 'Avenir Medium',
  },
  styledText: {
    color: '#000',
    fontSize: 10,
    fontFamily: 'Avenir Book',
  },
  rightText: {
    textAlign: 'right',
    fontFamily: 'Avenir Book',
  },
  dateText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    color: '#000',
    fontFamily: 'Avenir Medium',
    fontSize: 10,
  },
  dateKeyText: {
    flex: 1,
    textAlign: 'right',
  },
  dateValueText: {
    minWidth: 100,
    maxWidth: 100,
    flex: 1,
    textAlign: 'right',
  },
});

const PopUpInvoice = ({ user, type, selectedRecord, businessProfile }) => {
  const docName = type === 'package' ? (selectedRecord.invoice.name || `INV-${selectedRecord.invoice.id}`) : type === 'Sale' ? (selectedRecord.invoiceOrNote.name || `INV-${selectedRecord.invoiceOrNote.id}`) : (selectedRecord.invoiceOrNote.name || `CN-${selectedRecord.invoiceOrNote.id}`);
  let packageName = '';
  if (selectedRecord.valueType === 'Hourly') {
    if (selectedRecord.hours) packageName = `${selectedRecord.hours}-hour${selectedRecord.type === 'Trial Package' ? ' Trial' : ''} Package`;
    else if (selectedRecord.minutes) packageName = `${selectedRecord.minutes}-minute${selectedRecord.type === 'Trial Package' ? ' Trial' : ''} Package`;
  } else if (selectedRecord.valueType === 'Credit') {
    if (selectedRecord.type === 'Private / Semiprivate Package') packageName = `$${Number(Number(selectedRecord.packageValue) + Number(selectedRecord.offsetDiscount)).toLocaleString()} Credit Package`;
    else if (selectedRecord.type === 'Group Package') packageName = `Group Package - ${selectedRecord.totalLessons} sessions`;
    else if (selectedRecord.type === 'Trial Package') packageName = 'Trial Package - Credit';
  }
  packageName = `${packageName}${selectedRecord.description ? ` (${selectedRecord.description})` : ''}`;
  const myDoc = (docType) => (
    <Document title={docName}>
      <Page size="A4" style={styles.page}>
        { docType === 'view' ? <MyImage src={invoiceLogo} alt="Image not found." style={styles.logo} /> : <Image src={invoiceLogo} alt="Image not found." style={styles.logo} />}
        <View style={{ padding: '0px 24px' }}>
          <View style={styles.headerContainer}>
            <Text style={{ ...styles.rightText, ...styles.boldText, flex: 1 }}>{type === 'Buy Back' ? 'CREDIT NOTE' : 'INVOICE'}</Text>
          </View>

          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 30 }}>
              <View style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                <Text style={{ ...styles.boldText }}>{get(businessProfile, 'businessProfile.name', null) ? toUpper(businessProfile.businessProfile.name) : ''}</Text>
                <Text style={styles.styledText}>{get(businessProfile, 'businessProfile.address', '')}</Text>
                <Text style={styles.styledText}>{get(businessProfile, 'businessProfile.district', '')}</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                <Text style={{ ...styles.rightText, ...styles.boldText }}>{docName}</Text>

                { type === 'Buy Back' ? (
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <View style={styles.dateText}>
                      <Text style={styles.dateKeyText}>PAYMENT DATE: </Text>
                      <Text style={styles.dateValueText}>{moment.tz(selectedRecord.paymentDate, 'DD/MM/YYYY', 'Asia/Singapore').format('MMMM DD, YYYY')}</Text>
                    </View>
                    <View style={styles.dateText}>
                      <Text style={styles.dateKeyText}>CREDIT NOTE DATE: </Text>
                      <Text style={styles.dateValueText}>{moment.tz(selectedRecord.invoiceOrNote.createdAt, 'Asia/Singapore').format('MMMM DD, YYYY')}</Text>
                    </View>
                  </View>
                ) : (
                  <View style={styles.dateText}>
                    <Text style={styles.dateKeyText}>INVOICE DATE: </Text>
                    <Text style={styles.dateValueText}>{type === 'package' ? (selectedRecord.invoiceDate ? moment.tz(selectedRecord.invoiceDate, 'Asia/Singapore').format('MMMM DD, YYYY') : 'N/A') : moment.tz(selectedRecord.invoiceOrNote.createdAt, 'Asia/Singapore').format('MMMM DD, YYYY')}</Text>
                  </View>
                )}

                { selectedRecord.dueDate && (
                  <View style={styles.dateText}>
                    <Text style={styles.dateKeyText}>DUE DATE: </Text>
                    <Text style={styles.dateValueText}>{moment.tz(selectedRecord.dueDate, 'Asia/Singapore').format('MMMM DD, YYYY')}</Text>
                  </View>
                )}
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', marginBottom: 40 }}>
              <Text style={styles.boldText}>PREPARED FOR</Text>
              <Text style={styles.styledText}>{type === 'package' ? (get(selectedRecord, 'parent.title', null) ? `${get(selectedRecord, 'parent.title', '')} ${get(selectedRecord, 'parent.name', '')}` : get(selectedRecord, 'parent.name', '')) : (get(selectedRecord, 'user.parentTitle', null) ? `${get(selectedRecord, 'user.parentTitle', '')} ${selectedRecord.soldToName}` : selectedRecord.soldToName)}</Text>
              { selectedRecord.studentNames && (
                <Text style={{ ...styles.styledText, fontStyle: 'italic' }}>Student(s): {type === 'package' ? selectedRecord.studentNames.map((s) => s.name).join(', ') : selectedRecord.studentNames}</Text>
              )}
            </View>
          </View>

          { type === 'package' ? (
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol1Header, padding: docType === 'view' ? '5px 0px' : '7px 0px 3px' }}>
                  <Text style={styles.tableCellHeader}>DESCRIPTION</Text>
                </View>
                <View style={{ ...styles.tableCol2Header, padding: docType === 'view' ? '5px 0px' : '7px 0px 3px' }}>
                  <Text style={styles.tableCellHeader}>AMOUNT</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol1, padding: docType === 'view' ? '8px 0px' : '12px 0px 9px' }}>
                  <Text style={styles.tableCell1}>{packageName}</Text>
                </View>
                <View style={{ ...styles.tableCol2, padding: docType === 'view' ? '8px 0px' : '12px 0px 9px' }}>
                  <Text style={styles.tableCell2}>{`$${Number(Number(selectedRecord.packageValue) + Number(selectedRecord.offsetDiscount)).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol1, padding: docType === 'view' ? '8px 0px' : '13px 0px 8px' }}>
                  <Text style={{ ...styles.tableCell2, fontFamily: 'Avenir Medium' }}>SUBTOTAL</Text>
                </View>
                <View style={{ ...styles.tableCol2, padding: docType === 'view' ? '8px 0px' : '13px 0px 8px' }}>
                  <Text style={styles.tableCell2}>{`$${Number(Number(selectedRecord.packageValue) + Number(selectedRecord.offsetDiscount)).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}</Text>
                </View>
              </View>
              { (selectedRecord.offsetDiscount && selectedRecord.offsetDiscount > 0) && (
                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol1, padding: docType === 'view' ? '8px 0px' : '12px 0px 9px' }}>
                    <Text style={{ ...styles.tableCell2 }}>Discount</Text>
                  </View>
                  <View style={{ ...styles.tableCol2, padding: docType === 'view' ? '8px 0px' : '12px 0px 9px' }}>
                    <Text style={styles.tableCell2}>{`$${Number(selectedRecord.offsetDiscount).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}</Text>
                  </View>
                </View>
              )}
              { selectedRecord.less && (
                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol1, padding: docType === 'view' ? '8px 0px' : '11px 0px 10px' }}>
                    <Text style={{ ...styles.tableCell2, fontStyle: 'italic' }}>{`Less ${Object.keys(selectedRecord.less)[0]}`}</Text>
                  </View>
                  <View style={{ ...styles.tableCol2, padding: docType === 'view' ? '8px 0px' : '13px 0px 8px' }}>
                    <Text style={styles.tableCell2}>{`$${Number(Object.values(selectedRecord.less)[0]).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}</Text>
                  </View>
                </View>
              )}
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol1, padding: docType === 'view' ? '8px 0px' : '13px 0px 8px', borderBottomWidth: 0, borderLeftWidth: 0 }}>
                  <Text style={{ ...styles.tableCell2, fontFamily: 'Avenir Medium' }}>TOTAL</Text>
                </View>
                <View style={{ ...styles.tableCol2, padding: docType === 'view' ? '8px 0px' : '13px 0px 8px' }}>
                  <Text style={styles.tableCell2}>{`$${Number(selectedRecord.less ? Number(Number(selectedRecord.packageValue) + Number(selectedRecord.offsetDiscount)) - Object.values(selectedRecord.less)[0] - selectedRecord.offsetDiscount : Number(Number(selectedRecord.packageValue) + Number(selectedRecord.offsetDiscount)) - selectedRecord.offsetDiscount).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}</Text>
                </View>
              </View>
            </View>
          ) : (
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol1Header, padding: docType === 'view' ? '5px 0px' : '7px 0px 3px' }}>
                  <Text style={styles.tableCellHeader}>DESCRIPTION</Text>
                </View>
                <View style={{ ...styles.tableCol2Header, padding: docType === 'view' ? '5px 0px' : '7px 0px 3px' }}>
                  <Text style={styles.tableCellHeader}>AMOUNT</Text>
                </View>
              </View>
              { selectedRecord.inventoryItems.map((i) => (
                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol1, padding: docType === 'view' ? '8px 0px' : '12px 0px 9px' }}>
                    <Text style={styles.tableCell1}>{`${i.inventory.product}${i.inventory.publisherOrMaker ? ` (${i.inventory.publisherOrMaker})` : ''}${(i.description && i.inventory.section !== 'Instruments for Rental') ? ` [${i.description}]` : ''}${i.inventory.section === 'Instruments for Rental' ? ' [Rental]' : ''}`}</Text>
                  </View>
                  <View style={{ ...styles.tableCol2, padding: docType === 'view' ? '8px 0px' : '12px 0px 9px' }}>
                    <Text style={styles.tableCell2}>{`$${i.salePrice.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}</Text>
                  </View>
                </View>
              ))}
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol1, padding: docType === 'view' ? '8px 0px' : '13px 0px 8px', borderBottomWidth: 0, borderLeftWidth: 0 }}>
                  <Text style={{ ...styles.tableCell2, fontFamily: 'Avenir Medium' }}>TOTAL</Text>
                </View>
                <View style={{ ...styles.tableCol2, padding: docType === 'view' ? '8px 0px' : '13px 0px 8px' }}>
                  <Text style={styles.tableCell2}>{`$${selectedRecord.paymentPriceNum.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}</Text>
                </View>
              </View>
            </View>
          )}

          { (type === 'package' || type === 'Sale') && (
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              { get(businessProfile, 'businessProfile.description') && (
                <View style={{ marginTop: 40, marginBottom: 30, display: 'flex', flexDirection: 'row' }}>
                  <Text style={styles.styledText}>
                    <Text style={{ ...styles.boldText, fontSize: 10 }}>Payment Information: </Text>
                    {get(businessProfile, 'businessProfile.description', '')}
                  </Text>
                </View>
              )}

              <View style={{ display: 'flex' }}>
                <Text style={{ ...styles.styledText, flex: 1, textAlign: 'center', fontStyle: 'italic' }}>
                  By making this payment, you are acknowledging that you have reviewed and understand M.int Academy’s Policies (http://www.mintacademyhk.com/school-policies).
                </Text>
              </View>
            </View>
          )}

          <View style={{ marginTop: 40, marginBottom: 30, display: 'flex' }}>
            <Text style={{ ...styles.boldText, fontSize: 10, flex: 1, textAlign: 'center' }}>
              Thank you for joining the M.int Journey!
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )

  return (
    <StyledPopup
      trigger={<StyledButton> {docName} </StyledButton>}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          <PopUpButton className="close" onClick={() => { close(); }}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper> { (type === 'package' || type === 'Sale') ? 'Invoice' : 'Credit Note'} </PopUpTextWrapper>

          <Wrapper>
            { myDoc('view') }
          </Wrapper>

          <StyledPDFDownloadLink document={myDoc('download')} fileName={`${docName}.pdf`}>
            {({ blob, url, loading, error }) => (!url ? <div style={{ marginTop: 10 }}> <Loading /> </div> : <SaveButton> Save as PDF </SaveButton>)}
          </StyledPDFDownloadLink>
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpInvoice.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopUpInvoice;
