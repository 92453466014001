import { withRouter } from 'react-router-dom';
import { withProps, compose } from 'recompose';
import get from 'lodash/get';
import qs from 'qs';

// TODO
// may need to be able to set default params if not provided
const withQueryParams = (
  setInitialQueryParams = (props = {}) => props.parseQueryFromUrl(),
) => compose(
  withRouter,
  withProps(({ location }) => ({
    parseQueryFromUrl: () => qs.parse(location.search, { ignoreQueryPrefix: true }),
  })),
  withProps(({
    location,
    history,
    parseQueryFromUrl,
    ...props
  }) => ({
    queryParams: setInitialQueryParams({ location, history, parseQueryFromUrl, ...props }),
    setQueryParams: (mapper, options) => {
      const shouldReplace = get(options, 'replace', true);
      const oldQuery = parseQueryFromUrl();

      const queryString = qs.stringify(
        {
          ...mapper(oldQuery),
        },
        {
          skipNulls: true,
        }
      );

      if (shouldReplace) {
        history.replace(`?${queryString}`);
      } else {
        history.push(`?${queryString}`);
      }
    },
  }))
);

export default withQueryParams;
