import { useState, useCallback, useEffect } from 'react';

export default (options = {}) => {
  const { endpoint = undefined, id, query = {}, initialValues = {}} = options;
  const {
    loading: initialLoading = true,
    error: initialError = null,
    data: initialData,
  } = initialValues;
  const [loading, setLoading] = useState(initialLoading);
  const [error, setError] = useState(initialError);
  const [data, setData] = useState(initialData);
  const fetchEventData = useCallback(async () => {
    // console.log('sending request with this query:', query)
    const { api } = global;
    const promise = Promise.all(endpoint ? [api.get(`/${endpoint}`, { params: query })] : []);
    setLoading(true);
    return promise
      .then(([response]) => {
        setData(response.data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [query]);

  useEffect(() => {
    fetchEventData();
  }, query.isSameWeek ? [query.userId, query.view] : [query.userId, query.currentDate, query.view]);

  return { loading, error, data, fetchEventData, setLoading, setError, setData };
};
