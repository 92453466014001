import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { createFilter, components } from 'react-select';
import styled from 'styled-components';
import includes from 'lodash/includes';
import flatten from 'lodash/flatten';
import { FixedSizeList as List } from 'react-window';
import get from 'lodash/get';
import Icon from '../Icon';
import customStyle from '../Select/styles';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../Loading';

const StyledIcon = styled(Icon)`
  margin-bottom: -6px;
  padding: 0px;
`;

const MenuContainer = styled.div`
  > div {
    > div {
      div {
        div {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = 35;
  const [value] = getValue();
  const listHeight = Math.min(maxHeight, height * children.length) || 200;

  let initialOffset = options.indexOf(value) * height;
  initialOffset = initialOffset > listHeight ? initialOffset : 0;

  return (
    <MenuContainer>
      {children.length ? (
        <List
          height={listHeight}
          itemCount={children.length}
          itemSize={height}
          initialScrollOffset={initialOffset}
        >
          {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
      ) : <div style={{ padding: '10px 8px' }}> No options available </div>}
    </MenuContainer>
  );
};

const multiSelectChangeHandler = (func, setCountStudent, lessonType, formType) => (values) => {
  if (setCountStudent !== undefined && formType !== undefined && lessonType !== undefined) {
    setCountStudent(values.length);
    if (lessonType != null && lessonType === 'Semiprivate Lesson' && values.length < 2 && (formType === 'reschedule' || formType === 'rescheduleAll')) {
      return false;
    }
  }

  func(values.map((value) => value.value))

};

const MultiSelect = ({
  options,
  input,
  type,
  invalid,
  setIsDirty,
  selectedAll,
  setSelectedAll,
  updateSelectedFiles,
  selectedFiles,
  disabled,
  meta,
  isDirty,
  endpoint,
  query,
  valueBy = (v) => v.id,
  labelBy = (v) => v.id,
  isDoubleClickRemove,
  isLabelClicked,
  setIsLabelClicked,
  setCountStudent,
  setNotifStrikethrough,
  lessonType,
  formType,
  removeInputOnSelect = true,
  ...props
}) => {
  const selectRef = React.createRef();

  const { data, loading } = useGeneralData({
    endpoint,
    query,
  });

  const [inputValue, setInputValue] = useState('');

  if (loading) return <Loading />
  options = data ? data.map((v) => ({
    value: valueBy(v),
    label: labelBy(v),
  })) : options;

  const { Option } = components;
  const isInvalid = (meta.error && invalid) || (meta.error && isDirty);
  const selectedOption = options.filter(option => {
    return includes(input.value, option.value);
  });
  const IconOption = props => {
    return (
      <div>
        <Option {...props}>
          {props.isSelected ? (
            <StyledIcon icon="checkbox-checked" height="1.5" />
          ) : (
            <StyledIcon icon="checkbox-unchecked" height="1.5" />
          )}
          {props.data.label}
        </Option>
      </div>
    );
  };

  let allComponents = { Option: IconOption, MenuList };

  if (isDoubleClickRemove) {
    const handleMultiValueClick = (e, multiProps) => {
      if (setCountStudent !== undefined && formType !== undefined && lessonType !== undefined && lessonType === 'Semiprivate Lesson') {
        const studentActive = selectedOption.filter((val) => {
          return !val.isStrikeThrough;
        });
        selectRef.current.select.blur();
        if (studentActive.length === 2 && !multiProps.data.isStrikeThrough) {
          setNotifStrikethrough(true);
          return false;
        }

      }

      const option = selectedOption.find((opt) => opt.value === multiProps.data.value);
      option.isStrikeThrough = !option.isStrikeThrough;
      selectRef.current.select.blur();
      setIsLabelClicked(!isLabelClicked)
    }

    const MultiValueLabel = (multiProps) => {
      return (
        <div
          onClick={e => handleMultiValueClick(e, multiProps)}
          onTouchEnd={e => handleMultiValueClick(e, multiProps)}
        >
          <components.MultiValueLabel {...multiProps} />
        </div>
      )
    };

    allComponents = { ...allComponents, MultiValueLabel };
  }

  return (
    <ReactSelect
      className="react-select-container"
      closeMenuOnSelect={false}
      isMulti
      isClearable
      invalid={invalid}
      onBlur={() => setIsDirty(true)}
      onBlurResetsInput={false}
      onCloseResetsInput={false}
      hideSelectedOptions
      styles={customStyle({ invalid: isInvalid, disabled })}
      options={options}
      filterOption={createFilter({
        ignoreAccents: false,
        stringify: (option) => {
          return `${option.label} ${(get(option, 'data.tags') || []).join(' ')}`;
        },
      })}
      components={allComponents}
      ref={selectRef}
      openMenuOnClick={!isDoubleClickRemove}
      isDisabled={disabled}
      onChange={multiSelectChangeHandler(input.onChange, setCountStudent, lessonType, formType, setNotifStrikethrough)}
      value={selectedOption}
      defaultValue={meta.initial}
      inputValue={inputValue}
      onInputChange={(value, event) => {
        if (removeInputOnSelect) {
          setInputValue(value);
          return;
        }
        if (event.action !== 'set-value') {
          setInputValue(value);
        }
      }}
      {...props}
    />
  );
};

MultiSelect.propTypes = {
  meta: PropTypes.object,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  selectedFiles: PropTypes.array,
  options: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
MultiSelect.defaultProps = {
  options: [],
}

export default MultiSelect;
