import { useState, useCallback, useEffect } from 'react';
import qs from 'qs';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import useQueryParams from './useQueryParams';

export default (options = {}) => {
  const {
    modelName = undefined,
    query: defaultQuery = {},
    initialValues = {},
    itemsPerPage: initialItemsPerPage = 0,
  } = options;
  const {
    loading: initialLoading = true,
    error: initialError = null,
    data: initialData,
  } = initialValues;
  const [
    loading,
    setLoading,
  ] = useState(initialLoading);
  const [
    error,
    setError,
  ] = useState(initialError || null);
  const [
    data,
    setData,
  ] = useState(initialData || []);
  const [
    count,
    setCount,
  ] = useState(0);
  const [
    itemsPerPage,
    setItemsPerPage,
  ] = useState(initialItemsPerPage);

  const {
    queryParams,
    setQueryParams,
  } = useQueryParams()

  const queryString = qs.stringify(queryParams);
  const {
    currentPage = 1,
    order = {},
    filter: filterBy,
    search,
    where,
  } = queryParams;

  const setCurrentPage = useCallback((newPage) => {
    setQueryParams((old) => ({
      ...old,
      currentPage: newPage,
    }));
  }, [setQueryParams]);

  const setFilterBy = useCallback((newFilter) => {
    setQueryParams((old) => ({
      ...old,
      filter: newFilter,
      currentPage: 1,
    }));
  }, [setQueryParams]);

  const onSearch = useCallback(({ searchBy: newSearchBy }) => {
    setQueryParams((old) => ({
      ...old,
      search: newSearchBy,
      currentPage: 1,
    }));
  }, [setQueryParams])

  const {
    sortKey,
    sortOrder,
  } = order;

  const params = {
    offset: itemsPerPage >= 1 ? (currentPage - 1) * itemsPerPage : null,
    limit: itemsPerPage >= 1 ? itemsPerPage : null,
    ...((sortKey && sortOrder) ? {
      order: {
        sortKey,
        sortOrder,
      },
    } : {}),
    filter: filterBy,
    search,
    ...defaultQuery,
    where: {
      ...(get(defaultQuery, 'where', {}) || {}),
      ...where,
    },
  };

  const paramsString = qs.stringify(params);

  const fetchData = useCallback(async () => {
    const { api } = global;
    let promise;

    if (typeof modelName !== 'string') {
      promise = Promise.reject(`Unexpected value "${modelName}" for options.modelName`);
    } else {
      promise = api.get(`/${modelName}`, {
        params: qs.parse(paramsString),
      });
    }
    setLoading(true);
    return promise
      .then((response) => {
        setData(response.data);
        setCount(Number(response.count));
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    modelName,
    paramsString,
  ]);

  const onFilter = useCallback(async ({ filterBy }) => {
    const parsedFilterBy = omitBy(filterBy, (val, key) => {
      if (isObject(val)) {
        return isEmpty(omitBy(val, isNil));
      }
      return isNil(val)
    });
    setFilterBy(parsedFilterBy);
  }, [setFilterBy])

  const onSort = useCallback(async ({ sortKey, sortOrder }) => {
    setQueryParams((old) => ({
      ...old,
      sortKey,
      sortOrder,
    }))
  }, [setQueryParams]);

  const onPageChange = useCallback(async ({ currentPage: newPage }) => {
    setCurrentPage(newPage);
  }, [setCurrentPage]);

  const onItemsPerPageChange = useCallback(async ({ itemsPerPage: currentItemsPerPage }) => {
    setItemsPerPage(currentItemsPerPage);
  }, [setItemsPerPage]);

  useEffect(() => {
    fetchData();
  }, [
    queryString,
    fetchData,
  ]);

  return {
    loading,
    error,
    data,
    fetchData,
    setLoading,
    setError,
    setData,
    onSearch,
    searchBy: search,
    onSort,
    sortKey,
    sortOrder,
    onFilter,
    filterBy,
    onPageChange,
    currentPage,
    count,
    pageSize: itemsPerPage,
    onItemsPerPageChange,
  };
};
