import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import { palette, size, font } from 'styled-theme';
import { validations } from '../../../utils/form';
import Button from '../../atoms/Button';
import FieldComponent from '../../molecules/FieldComponent';
import Form from '../../molecules/Form';
import Heading from '../../atoms/Heading';
import P from '../../atoms/P';
import Link from '../../atoms/Link';
import Flex from '../../atoms/Flex';

const StyledHeading = styled(Heading)`
  margin: 0;
  text-align: center;
  margin-top: 12px;
`;

const StyledP = styled(P)`
  color: #3998B5;
  margin: 0 0 ${size('margin.large')} 0;
  padding: ${size('padding.default')} 0;
  text-align: center;
  // border-bottom: 1px solid #FF9997;
`;

const StyledForm = styled.form`
  >div {
    >div {
      min-height: 16px;
    }
  }
`;

const StyledButton = styled(Button)`
  min-width: 180px;
  width: 100%;
  margin: 20px 0px 20px;
  border-radius: 0px;
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const LinkWrapper = styled.div`
  text-align: right;
  margin: 6px 0;
`;

const StyledLink = styled(Link)`
  color: #66C0DB;
`;

const VerificationWrapper = styled.div`

`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 120px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const Message = styled(P)`
  margin-bottom: ${size('margin.default')};
  font-size: 14px;
  color: ${props => (props.verified === false ? palette('error', 4) : palette('success', 4))};
`;

const LoginForm = ({ onSubmit, verified }) => (
  <React.Fragment>
    <VerificationWrapper>
      {verified && <Message>Your email has been successfully verified. Please sign in to continue</Message>}
      {verified === false && <Message verified={verified}>There was a problem verifying your email. Please contact our support</Message>}
    </VerificationWrapper>
    <Form
      name="LoginForm"
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <StyledForm onSubmit={handleSubmit}>
          <FieldFlex>
            <StyledText> Account: </StyledText>
            <StyledField
              name="email"
              placeholder="Email"
              type="text"
              component={FieldComponent}
              validate={validations.required}
            />
          </FieldFlex>

          <FieldFlex>
            <StyledText> Password: </StyledText>
            <StyledField
              name="password"
              placeholder="Password"
              type="password"
              component={FieldComponent}
              validate={validations.required}
            />
          </FieldFlex>

          <StyledButton
            type="submit"
            disabled={submitting}
          >
            Login
          </StyledButton>

        </StyledForm>
      )}
    />
  </React.Fragment>
);

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
