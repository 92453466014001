import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import useUserForm from '../../../hooks/useUserForm';
import ErrorAlert from '../../molecules/ErrorAlert';

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;
  font-weight: normal;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const ConfirmButton = styled(Button)`
  display: flex;
  flex-direction: row;
  border-radius: 0px;
  margin-bottom: 0px;
  width: 50%;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 30vw;
  min-width: 30vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const StyledText = styled.div`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  padding: 20px 0px 30px 0px;
  text-align: center;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const ErrorAlertContainer = styled.div`
  > div {
    max-width: 28vw;
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: none;
    }
  }
`;

const PopUpUpdate = ({ user, endpoint, record, type, disabled }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { onSubmit } = useUserForm({
    endpoint,
    setErrorMessage,
    action: 'update',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
        setIsSubmitting(false);
      }, 3000);
    }
  }, [errorMessage]);

  let buttonText = '';
  let header = '';
  let text = '';

  if (type === 'checkout') {
    buttonText = 'Check Out';
    header = 'Check Out Item';
    text = 'Are you sure you want to check out this item?';
  } else if (type === 'return') {
    buttonText = 'Return';
    header = 'Return Item';
    text = 'Are you sure you want to return this item?';
  } else if (type === 'notYetArrived') {
    buttonText = 'Not Yet Arrived';
    header = 'Edit Status';
    text = 'Are you sure you want to make this item not yet arrived?';
  } else if (type === 'makeAvailable') {
    buttonText = 'Make Available';
    header = 'Edit Status';
    text = 'Are you sure you want to make this item available?';
  }

  return (
    <StyledPopup
      trigger={<StyledButton disabled={disabled} style={{ color: disabled && '#C1C1C1' }}> {buttonText} </StyledButton>}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          <ErrorAlertContainer>
            <ErrorAlert
              errorMessage={errorMessage}
              show={errorMessage !== null}
            />
          </ErrorAlertContainer>
          <PopUpButton className="close" onClick={close}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper>
            {header}
          </PopUpTextWrapper>
          <StyledText>
            {text}
          </StyledText>
          <ConfirmButton style={{ width: '100%' }} disabled={isSubmitting} onClick={() => { setIsSubmitting(true); onSubmit(record); }}> Confirm </ConfirmButton>
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpUpdate.propTypes = {
  user: PropTypes.object.isRequired,
};

export default PopUpUpdate;
