import { call, put, fork, take } from 'redux-saga/effects';

import {
  signInSuccess,
  signInError,
  SIGN_IN_REQUEST,
  signUpError,
  SIGN_UP_REQUEST,
  SIGN_OUT,
} from './actions';

export function* handleSignIn(params) {
  const { authApi, api } = global;
  try {
    const response = yield call([authApi, authApi.post], '/login', { ...params });
    yield call([localStorage, localStorage.setItem], 'access_token', response.data.token);
    yield call([api, api.setToken], response.data.token);
    yield call([authApi, authApi.setToken], response.data.token);
    yield put(signInSuccess(response.data));
  } catch (e) {
    yield put(signInError(e));
  }
}

export function* handleSignOut() {
  const { authApi, api } = global;
  yield call([localStorage, localStorage.setItem], 'access_token', '');
  yield call([api, api.unsetToken]);
  yield call([authApi, authApi.unsetToken]);
}

export function* watchSignInRequest() {
  while (true) {
    const { payload } = yield take(SIGN_IN_REQUEST);
    yield call(handleSignIn, payload);
  }
}

export function* handleSignUp(params) {
  const { authApi, api } = global;
  try {
    const response = yield call([authApi, authApi.post], '/register', params);
    yield put(signInSuccess(response.data));
    yield call([localStorage, localStorage.setItem], 'access_token', response.data.token);
    yield call([api, api.setToken], response.data.token);
    yield call([authApi, authApi.setToken], response.data.token);
  } catch (e) {
    yield put(signUpError(e));
  }
}

export function* watchSignUpRequest() {
  while (true) {
    const { payload } = yield take(SIGN_UP_REQUEST);
    yield call(handleSignUp, payload);
  }
}
export function* watchSignOutRequest() {
  while (true) {
    yield take(SIGN_OUT);
    yield call(handleSignOut);
  }
}

export default function* authenticationSaga() {
  yield fork(watchSignInRequest);
  yield fork(watchSignUpRequest);
  yield fork(watchSignOutRequest);
}
