import { createGlobalStyle } from 'styled-components';
import { palette, size } from 'styled-theme';

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    height: 100%;
  }

  body {
    height: 100%;
    font-family: 'Avenir Book';
    font-size: 14px;
    line-height: 1rem;
    letter-spacing: 0px;
    word-spacing: 0px;

    &.no-scroll {
      overflow-y: hidden;
    }
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  p, ul, ol, pre, table, blockquote {
    margin-top: 0rem;
    margin-bottom: 1.75rem;
  }

  ul ul, ol ol, ul ol, ol ul {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  ul {
    list-style-type: none;

    li {
      display: inline;
      margin: 0 8px;
    }
  }

  .avatar-uploader > .ant-upload-list-picture-card > .ant-upload {
    width: 128px;
    height: 128px;
  }

  .ant-upload-list-picture-card-container {
    width: 128px;
    height: 128px;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: ${palette('primary', 0)};
    border-color: ${palette('primary', 0)};
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
  }

  .ant-radio-group-large .ant-radio-button-wrapper {
    font-size: 14px;
  }

  .ant-radio-button-wrapper:last-child, .ant-radio-button-wrapper:first-child {
    border-radius: 0px;
  }

  .ant-radio-button-wrapper:hover {
    color: ${palette('primary', 0)};
  }

  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: ${palette('primary', 0)};
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: ${palette('primary', 0)};
  }

  .ant-radio-group-middle .ant-radio-button-wrapper {
    border-left-width: 1px;
  }

  .ant-pagination-item, .ant-pagination-next .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link {
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-pagination-item-active, .ant-pagination-item-active: hover, .ant-pagination-item-active: focus, .ant-pagination-item-active: active,
  .ant-pagination-item: hover, .ant-pagination-prev > .ant-pagination-item-link: hover, .ant-pagination-next > .ant-pagination-item-link: hover,
  .ant-pagination-options-quick-jumper input:hover, .ant-pagination-options-quick-jumper input:focus {
    border-color: ${palette('primary', 0)};
  }

  .ant-pagination-options-quick-jumper input:focus {
    box-shadow:none;
  }

  .ant-pagination-prev > .ant-pagination-item-link: hover, .ant-pagination-next > .ant-pagination-item-link: hover,
  .ant-pagination-item-active a, .ant-pagination-item:hover a, .ant-pagination-item:focus a, .ant-pagination-item:active a {
    color: ${palette('primary', 0)};
  }

  .ant-pagination-disabled > .ant-pagination-item-link: hover {
    border-color: #d9d9d9;
    color: #d9d9d9;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover,
  .ant-table-thead th.ant-table-column-sort {
    background: ${palette('primary', 0)};
  }

  .ant-table-column-sorter {
    color: ${palette('white', 0)};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab:hover {
    color: ${palette('black', 0)};
  }

  .ant-tabs-ink-bar {
    background: ${palette('black', 0)};
  }

  .ant-tabs > .ant-tabs-nav {
    margin: 0px;
  }

  tr.ant-table-expanded-row > td {
    padding: 0px;
  }

  .tui-full-calendar-time-date {
    cursor: default;
  }

  .tui-full-calendar-time-schedule {
    cursor: pointer;
  }

  .ant-tabs-tab-btn {
    font-family: 'Avenir Roman';
  }

  table {
    thead {
      tr {
        th {
          font-family: 'Avenir Roman';
        }
      }
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #E9F4F5;
  }

  .ant-table-pagination.ant-pagination {
    margin: 16px 0 0 0;
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: ${palette('primary', 0)};
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${palette('primary', 0)};
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${palette('primary', 0)};
    box-shadow: none;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #E9F4F5;
  }

  .ant-tabs > .ant-tabs-nav {
    @media (max-width: ${size('mobileBreakpoint')}) {
      padding: 0px 12px;
    }
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    @media (max-width: ${size('mobileBreakpoint')}) {
      left: 12px;
      right: 12px;
    }
  }

  .ant-pagination-options-quick-jumper {
    > input {
      margin-right: 0px;
    }
  }

  .ant-pagination.ant-table-pagination.ant-table-pagination-right {
    margin-right: -8px;
  }

  .ant-pagination.ant-pagination-simple.ant-table-pagination.ant-table-pagination-right {
    margin-right: 0px;
  }

  #app {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    height: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    height: 100%;
    position: fixed;
    width: 100%;
  }

  #popup-root {
    height: 100vh !important;

    .popup-overlay {
      height: 100% !important;
      .popup-content {
        height: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        max-height: 90vh !important;

        > div {
          height: 100% !important;
          position: relative !important;
          flex: 0 1 auto !important;
          flex-basis: max-content !important;
          max-height: 90vh !important;
          > button {
            top: 20px !important;
            right: 20px !important;
          }
          > form {
            flex: 0;
          }
        }
      }
    }
  }
`;
export default GlobalStyle;
