import React, { Fragment, useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { size, palette, font } from 'styled-theme';
import omit from 'lodash/omit';
import moment from 'moment';
import get from 'lodash/get';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import FieldComponent from '../../molecules/FieldComponent';
import Button from '../../atoms/Button';
import Card from '../../atoms/Card';
import { validations } from '../../../utils/form';
import inputOptions from '../../../utils/form/inputOptions';
import Flex from '../../atoms/Flex';
import Icon from '../../atoms/Icon';
import Input from '../../atoms/Input';
import ErrorAlert from '../../molecules/ErrorAlert';
import useLessonData from '../../../hooks/useLessonData';
import Loading from '../../atoms/Loading';

const StyledForm = styled.form`
  >div {
    >div {
      min-height: 16px;
    }
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  width: 50%;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
    width: 100%;
  }
`;

const CheckBoxField = styled(Flex)`
  align-items: center;
  width: 50%;
  height: 20px;
  padding-bottom: 10px;

  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
    margin-bottom: 20px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 150px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledButton = styled(Button)`
  min-width: 180px;
  margin: 20px 0px 20px;
  border-radius: 0px;
  width: 50%;

  @media (max-width: ${size('mobileBreakpoint')}){
    width: 100%;
  }
`;

const MultiSelectText = styled(StyledText)`
  padding-top: 17px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C7C7C7;
  margin-bottom: 12px;
`;

const TopText = styled.div`
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const InputContainer = styled.div`
  width: 100%;
  > div {
    margin: 0px;
    margin-left: 5px;

    @media (max-width: ${size('mobileBreakpoint')}){
      margin-left: 8px;
    }
    > label {
      > div {
        left: 0px;
      }
    }
  }
`;

const StyledInput = styled(Input)`
  transform: scale(1.2);
  font-size: 12px;
`;

const CreditValueWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const CreditValueText = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
`;

const CreditValueField = styled(StyledField)`
  input {
    padding-right: 40px;
  }
`;

const MainWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  width: 50%;
  > div: last-child {
    > div: first-child {
      display: none;
    }
    > div: nth-child(2) {
      margin: 0px;
      > label {
        left: 3px;
        > input {
          margin-right: 3px;
          transform: scale(1.4);
        }
      }
    }
  }
`;

const checkOffPeak = (start, end) => {
  let offPeak = false;
  const startTime = moment(start).format('HH:mm:ss');
  const endTime = moment(end).format('HH:mm:ss');
  const dayOfWeek = moment(start).format('dddd');

  if (dayOfWeek === 'Monday' || dayOfWeek === 'Friday') {
    if (moment(startTime, 'HH:mm:ss').isSameOrAfter(moment('18:00:00', 'HH:mm:ss')) && moment(endTime, 'HH:mm:ss').isSameOrBefore(moment('19:00:00', 'HH:mm:ss'))) offPeak = true;
  } else if (dayOfWeek === 'Tuesday' || dayOfWeek === 'Wednesday' || dayOfWeek === 'Thursday') {
    if ((moment(startTime, 'HH:mm:ss').isSameOrAfter(moment('10:30:00', 'HH:mm:ss')) && moment(endTime, 'HH:mm:ss').isSameOrBefore(moment('15:00:00', 'HH:mm:ss'))) || (moment(startTime, 'HH:mm:ss').isSameOrAfter(moment('18:00:00', 'HH:mm:ss')) && moment(endTime, 'HH:mm:ss').isSameOrBefore(moment('19:30:00', 'HH:mm:ss')))) offPeak = true;
  }
  return offPeak;
};

const LessonForm = ({
  onSubmit,
  errorMessage,
  child,
  instructor,
  selectedEvent,
  studentId,
  eventInstructor,
  type,
  allLessons,
  allPackageInfo,
  untilLesson,
  classData,
  oldStudentIdStatus,
  calendars,
  attendances,
  ...others
}) => {
  const [freeTrials, setFreeTrials] = useState(null);
  const [studIds, setStudIds] = useState(studentId || []);
  const [isLabelClicked, setIsLabelClicked] = useState(false);
  const [countStudent, setCountStudent] = useState(null);
  const [notifStrikethrough, setNotifStrikethrough] = useState(false);

  const { data: packageStudent } = useLessonData({
    endpoint: 'package/student',
    query: { studentId: studIds, eventId: get(selectedEvent, 'id', null), recurrenceId: get(selectedEvent, 'recurrenceId', null) }
  });

  const sortedChild = child.map((c) => {
    if (oldStudentIdStatus && oldStudentIdStatus.map((ss) => Number(ss.childId)).includes(Number(c.id)) && c.isStrikeThrough == null) {
      c.isStrikeThrough = !!oldStudentIdStatus.find((ss) => Number(ss.childId) === Number(c.id)).isCancelled;
    }
    c.label = type === 'delete' ? `${c.firstName} ${c.lastName}` : `${c.firstName} ${c.lastName} (ST${c.id})`;
    c.value = c.id;
    c.isStrikeThrough = c.isStrikeThrough || false;
    return c;
  }).sort((a, b) => (a.id > b.id ? 1 : -1));
  const sortedInstructor = instructor.map((i) => {
    i.label = `${i.firstName} ${i.lastName}`;
    i.value = i.id;
    return i;
  }).sort((a, b) => (a.label > b.label ? 1 : -1));

  let initialValues = null;

  if (selectedEvent != null) {
    if (type === 'reschedule') {
      initialValues = {
        lessonType: selectedEvent.lessonType,
        title: selectedEvent.title,
        student: studentId,
        instructor: eventInstructor[0],
        roomId: selectedEvent.roomId ? selectedEvent.roomId.toString() : null,
        date: selectedEvent.date,
        startTime: moment(selectedEvent.startTime).format('HH:mm'),
        endTime: moment(selectedEvent.endTime).format('HH:mm'),
        note: selectedEvent.note,
        externalNote: selectedEvent.externalNote,
      }
    } else if (type === 'delete') {
      initialValues = {
        lessonType: selectedEvent.lessonType,
        title: selectedEvent.title,
        student: studentId,
        instructor: eventInstructor[0],
        roomId: selectedEvent.roomId ? selectedEvent.roomId.toString() : null,
        date: selectedEvent.date,
        startTime: moment(selectedEvent.startTime).format('HH:mm'),
        endTime: moment(selectedEvent.endTime).format('HH:mm'),
        note: selectedEvent.note,
        externalNote: selectedEvent.externalNote,
      }
    } else if (type === 'rescheduleAll') {
      initialValues = {
        lessonType: selectedEvent.lessonType,
        title: selectedEvent.title,
        student: studentId,
        instructor: eventInstructor[0],
        roomId: selectedEvent.roomId ? selectedEvent.roomId.toString() : null,
        date: selectedEvent.date,
        duration: `${moment(selectedEvent.endTime).diff(moment(selectedEvent.startTime), 'minutes')} mins`,
        startTime: moment(selectedEvent.startTime).format('HH:mm'),
        endTime: moment(selectedEvent.endTime).format('HH:mm'),
        repeat: selectedEvent.recurrenceType,
        recurringDay: Number(moment(selectedEvent.date).format('E')),
        note: selectedEvent.note,
        externalNote: selectedEvent.externalNote,
        checkOffPeak: selectedEvent.isOffPeak == null ? checkOffPeak(moment(selectedEvent.startTime), moment(selectedEvent.endTime)) : selectedEvent.isOffPeak,
      }
    }
  }

  const addMinutes = get(classData, '0.studentNamesWithStatus.0.addMinutes', null);

  return (
    <Form
      name="LessonForm"
      onSubmit={onSubmit}
      initialValues={initialValues}
      initialValuesEqual={() => true}
      render={({ handleSubmit, submitting, values, dirtyFields }) => {
        let lessonOptions = [];
        let lessonTypeSelected = true;

        if (values.lessonType === 'Private Lesson') lessonOptions = allLessons.filter((l) => l.type === 'Private Lesson');
        else if (values.lessonType === 'Group Lesson') lessonOptions = allLessons.filter((l) => l.type === 'Group Lesson');
        else if (values.lessonType === 'Semiprivate Lesson') lessonOptions = allLessons.filter((l) => l.type === 'Semiprivate Lesson - 2 persons');

        lessonOptions.map((l) => {
          l.label = l.name;
          l.value = l.name;
          return l;
        })

        lessonOptions = lessonOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

        if (values.lessonType != null) lessonTypeSelected = false;

        let selectedStudents = [];
        if (values.student) {
          selectedStudents = sortedChild.filter((c) => values.student.includes(c.id));
          if (freeTrials == null || selectedStudents.length !== freeTrials.length) {
            setFreeTrials(selectedStudents.map((c) => {
              const initialFreeTrial = (allPackageInfo && allPackageInfo.filter((pi) => pi.studentId === c.id).length > 0) ? allPackageInfo.filter((pi) => pi.studentId === c.id)[0].hasFreeTrial : false;
              return ({ id: c.id, isFreeTrial: c.isFreeTrial || initialFreeTrial });
            }));
          }
        }
        selectedStudents = selectedStudents.map((ss) => {
          return {
            ...ss,
            attendance: find(attendances, (at) => at.childId === ss.id),
          };
        });
        values.studentPackages = selectedStudents;
        if (type === 'reschedule' || type === 'rescheduleAll') {
          values.editEventId = selectedEvent.eventId;
          values.oldStudents = oldStudentIdStatus;
          values.editRecurrenceId = selectedEvent.recurrenceId;
          values.editRecurrenceType = selectedEvent.recurrenceType;
        }
        values.untilLesson = untilLesson;

        const setStudentsFreeTrial = (childId, hasFreeTrial) => {
          const index = freeTrials.findIndex((i) => i.id === childId);
          freeTrials[index].isFreeTrial = hasFreeTrial;
          setFreeTrials([...freeTrials]);
        }

        let initialEndTime;
        let initialStartTime;
        const estimatedRemaining = 0;
        let initialRemainingAmount;
        let currentStartTime;
        let timeDifference;
        if (type === 'reschedule') {
          const classCurrentPrice = get(classData, '0.studentNamesWithStatus.0.currentClassPrice', null);
          currentStartTime = moment.tz(values.startTime, 'HH:mm:ss', 'Asia/Singapore').set({ year: moment(selectedEvent.startTime).get('year'), month: moment(selectedEvent.startTime).get('month'), date: moment(selectedEvent.startTime).get('date') });
          const currentEndTime = moment.tz(values.endTime, 'HH:mm:ss', 'Asia/Singapore').set({ year: moment(selectedEvent.endTime).get('year'), month: moment(selectedEvent.endTime).get('month'), date: moment(selectedEvent.endTime).get('date') });
          const durationDifference = moment(currentEndTime).diff(moment(currentStartTime), 'minutes') - moment(selectedEvent.endTime).diff(moment(selectedEvent.startTime), 'minutes');
          timeDifference = moment(selectedEvent.endTime).diff(moment(selectedEvent.startTime), 'minutes');
          initialRemainingAmount = timeDifference === 0 ? 0 : +(initialRemainingAmount - (durationDifference / timeDifference) * classCurrentPrice).toFixed(2);
          if (!values.startTime || !values.endTime) initialRemainingAmount = null;
          initialEndTime = moment(selectedEvent.endTime).format('HH:mm');
          initialStartTime = moment(selectedEvent.startTime).format('HH:mm');
        }

        if (!isEqual(sortBy(studIds), sortBy(values.student))) setStudIds(values.student);
        if (type === 'rescheduleAll') {
          if (initialValues.checkOffPeak === values.checkOffPeak) values.isOffPeakChanged = false;
          else values.isOffPeakChanged = true;
          values.oldOffPeak = selectedEvent.isOffPeak;
          values.dirtyFields = dirtyFields;
        }
        values.calendars = calendars;
        return (
          <StyledForm onSubmit={handleSubmit}>
            <ErrorAlert
              errorMessage={
                <div>
                  <div style={{ marginTop: 8 }}>Cannot be less than 2 because this is {values.lessonType} </div>
                  <Flex style={{ justifyContent: 'center' }}>
                    <StyledButton onClick={() => { setCountStudent(null); }}> Okay </StyledButton>
                  </Flex>
                </div>
              }
              show={(values.lessonType === 'Semiprivate Lesson' && countStudent != null && countStudent < 2 && (type === 'reschedule' || type === 'rescheduleAll')) ? true : false}
              style={{ position: 'absolute', maxWidth: 'none', marginTop: 8 }}
            />
            <ErrorAlert
              errorMessage={
                <div>
                  <div style={{ marginTop: 8 }}>Minimum 2 students required </div>
                  <Flex style={{ justifyContent: 'center' }}>
                    <StyledButton onClick={() => { setNotifStrikethrough(false); }}> Okay </StyledButton>
                  </Flex>
                </div>
              }
              show={(values.lessonType === 'Semiprivate Lesson' && notifStrikethrough && (type === 'reschedule' || type === 'rescheduleAll')) ? true : false}
              style={{ position: 'absolute', maxWidth: 'none', marginTop: 8 }}
            />
            <MainWrapper style={{ paddingRight: 10 }}>
              <FieldFlex style={{ width: type === 'delete' && '100%' }}>
                <StyledText>Lesson Type: </StyledText>
                <StyledField
                  name="lessonType"
                  type="select"
                  component={FieldComponent}
                  disabled={type === 'delete' || type === 'reschedule' || type === 'rescheduleAll'}
                  options={inputOptions.lessonTypeOptions}
                  validate={validations.required}
                />
              </FieldFlex>

              <FieldFlex style={{ width: type === 'delete' && '100%' }}>
                <StyledText>Lesson: </StyledText>
                <StyledField
                  name="title"
                  type="select"
                  component={FieldComponent}
                  options={lessonOptions}
                  disabled={lessonTypeSelected || type === 'delete'}
                  validate={validations.required}
                />
              </FieldFlex>

              <FieldFlex style={{ width: type === 'delete' && '100%', alignItems: 'normal' }}>
                <MultiSelectText> Student: </MultiSelectText>
                <StyledField
                  name="student"
                  type="multi-select"
                  component={FieldComponent}
                  disabled={type === 'delete' || (type === 'reschedule' && values.lessonType === 'Private Lesson') || (type === 'rescheduleAll' && values.lessonType === 'Private Lesson')}
                  options={type === 'create' ? sortedChild.filter((i) => i.status === 'Current' && !i.deletedAt) : sortedChild.filter((i) => i.deletedAt == null)}
                  validate={validations.required}
                  isDoubleClickRemove={(type === 'reschedule' && values.lessonType !== 'Private Lesson') || (type === 'rescheduleAll' && !values.lessonType !== 'Private Lesson')}
                  isLabelClicked={isLabelClicked}
                  setIsLabelClicked={setIsLabelClicked}
                  setCountStudent={setCountStudent}
                  setNotifStrikethrough={setNotifStrikethrough}
                  lessonType={values.lessonType}
                  formType={type}
                />
              </FieldFlex>

              <FieldFlex style={{ width: type === 'delete' && '100%' }}>
                <StyledText>Instructor: </StyledText>
                <StyledField
                  name="instructor"
                  type="select"
                  component={FieldComponent}
                  disabled={type === 'delete'}
                  options={type === 'create' ? sortedInstructor.filter((i) => i.status === 'Current') : sortedInstructor.filter((i) => i.status === 'Current' || Number(i.id) === get(eventInstructor, '0', null))}
                  validate={validations.required}
                />
              </FieldFlex>

              <FieldFlex style={{ width: type === 'delete' && '100%' }}>
                <StyledText>Room: </StyledText>
                <StyledField
                  name="roomId"
                  type="spec"
                  component={FieldComponent}
                  disabled={type === 'delete'}
                  shouldChose
                  labelBy={(v) => v.name}
                  reference={{ endpoint: 'room' }}
                  associationType="own"
                />
              </FieldFlex>

              {type !== 'rescheduleAll' && (
                <FieldFlex style={{ width: type === 'delete' && '100%' }}>
                  <StyledText>Date: </StyledText>
                  <StyledField
                    name="date"
                    type="date"
                    disabled={type === 'delete'}
                    isClearable={type === 'create' || type === 'reschedule'}
                    component={FieldComponent}
                    validate={validations.required}
                  />
                </FieldFlex>
              )}

              <FieldFlex style={{ width: type === 'delete' && '100%' }}>
                <StyledText>Time: </StyledText>
                <FieldFlex style={{ width: '100%', alignItems: 'baseline', flexDirection: 'row' }}>
                  <StyledField
                    name="startTime"
                    type="time"
                    disabled={type === 'delete'}
                    isClearable={type === 'delete' ? false : true}
                    component={FieldComponent}
                    initialValue={initialStartTime}
                    validate={validations.time}
                  />

                  <div style={{ marginLeft: '10px' }}> - </div>
                  <StyledField style={{ marginLeft: '10px' }}
                    name="endTime"
                    type="time"
                    disabled={type === 'delete'}
                    isClearable={type === 'delete' ? false : true}
                    initialValue={initialEndTime}
                    component={FieldComponent}
                    validate={validations.time}
                  />
                </FieldFlex>
              </FieldFlex>

              {type === 'rescheduleAll' && (
                <CheckBoxContainer style={{ display: 'none' }}>
                  <StyledText />
                  <StyledField
                    name="checkOffPeak"
                    type="checkbox"
                    label="Off-peak"
                    component={FieldComponent}
                  />
                </CheckBoxContainer>
              )}

              <FieldFlex style={{ width: type === 'delete' && '100%' }}>
                <StyledText>Note: </StyledText>
                <StyledField
                  name="note"
                  type="text"
                  component={FieldComponent}
                  disabled={type === 'delete'}
                  validate={validations.maxLength255}
                />
              </FieldFlex>

              <FieldFlex style={{ width: type === 'delete' && '100%' }}>
                <StyledText>Note (external): </StyledText>
                <StyledField
                  name="externalNote"
                  type="text"
                  component={FieldComponent}
                  disabled={type === 'delete'}
                  validate={validations.maxLength255}
                />
              </FieldFlex>

              {(type === 'create' || type === 'rescheduleAll') && (
                <div>
                  <FieldFlex style={{ width: type === 'delete' && '100%' }}>
                    <StyledText>Repeat: </StyledText>
                    <StyledField
                      name="repeat"
                      type="select"
                      component={FieldComponent}
                      options={inputOptions.recurrenceOptions.filter((i) => i.value !== 'Monthly' && i.value !== 'Annually')}
                      disabled={type === 'rescheduleAll'}
                      validate={validations.required}
                    />
                  </FieldFlex>

                  {(values.repeat != null && values.repeat !== 'Does not repeat' && type === 'create') && (
                    <FieldFlex style={{ width: type === 'delete' && '100%' }}>
                      <StyledText>Ends: </StyledText>
                      <StyledField
                        name="ends"
                        type="date"
                        component={FieldComponent}
                        validate={validations.required}
                      />
                    </FieldFlex>
                  )}

                  {(values.repeat != null && values.repeat !== 'Does not repeat' && type === 'rescheduleAll') && (
                    <FieldFlex style={{ width: type === 'delete' && '100%' }}>
                      <StyledText>Recurring: </StyledText>
                      <StyledField
                        name="recurringDay"
                        type="select"
                        component={FieldComponent}
                        options={inputOptions.weekDays}
                        validate={validations.required}
                      />
                    </FieldFlex>
                  )}
                </div>
              )}

              {type === 'rescheduleAll' && (
                <FieldFlex style={{ width: type === 'delete' && '100%' }}>
                  <StyledText>Edit From: </StyledText>
                  <StyledField
                    name="editStartDate"
                    type="date"
                    component={FieldComponent}
                  />
                </FieldFlex>
              )}
            </MainWrapper>
            <MainWrapper style={{ paddingRight: 10 }}>

              {(selectedStudents && selectedStudents.length > 0 && (type === 'create' || type === 'reschedule' || type === 'rescheduleAll')) ? (
                <div>
                  <StyledFlex>
                    <Icon icon="package-info" height={20} style={{ marginRight: 5 }} />
                    <TopText> Package Information </TopText>
                  </StyledFlex>

                  {selectedStudents.map((s) => {
                    const activePackages = (packageStudent && packageStudent.length > 0) ? packageStudent.filter((ap) => ap.children.map((ch) => Number(ch.id)).includes(Number(s.id))) : [];

                    const packageInfo = values[`package${s.id}`];
                    s.selectedPackage = packageInfo;

                    if (freeTrials) {
                      const thisStudent = freeTrials.filter((f) => f.id === s.id)[0];
                      if (thisStudent) s.isFreeTrial = thisStudent.isFreeTrial;
                    }

                    let isFreeTrialDisabled = true;
                    if (s.selectedPackage) {
                      const selectedPackage = activePackages.filter((p) => p.id === JSON.parse(s.selectedPackage).id)[0];
                      if (selectedPackage) {
                        isFreeTrialDisabled = (!selectedPackage.freeTrial || selectedPackage.freeTrial <= 0);
                        s.freeTrialDuration = s.isFreeTrial ? selectedPackage.freeTrial : null;
                      }
                    }

                    let initValue = null;
                    if ((type === 'reschedule' || type === 'rescheduleAll') && studentId.includes(s.id)) {
                      const myPackage = allPackageInfo.filter((pi) => pi.studentId === s.id)[0];
                      const myPackageInfo = activePackages.filter((mpi) => mpi.id === myPackage.packageId)[0];
                      initValue = myPackageInfo ? JSON.stringify({ id: myPackageInfo.id, type: myPackageInfo.type }) : null;
                    }

                    return (
                      <div style={{ display: !s.isStrikeThrough ? 'block' : 'none' }}>
                        <FieldFlex style={{ width: type === 'delete' && '100%' }}>
                          <StyledText>Student: </StyledText>
                          <StyledField
                            name={`student${s.id}`}
                            type="text"
                            component={FieldComponent}
                            initialValue={`${s.firstName} ${s.lastName} (ST${s.id})`}
                            disabled
                            validate={validations.required}
                          />
                        </FieldFlex>

                        <FieldFlex style={{ width: '100%' }}>
                          <FieldFlex>
                            <StyledText>Package: </StyledText>
                            <StyledField
                              name={`package${s.id}`}
                              type="select"
                              component={FieldComponent}
                              initialValue={(type === 'reschedule' || type === 'rescheduleAll') ? initValue : undefined}
                              options={(activePackages && activePackages.length > 0) ? activePackages : []}
                              validate={validations.required}
                            />
                          </FieldFlex>

                          {s.selectedPackage && (
                            <CheckBoxField>
                              <StyledText style={{ width: 25 }} />
                              <InputContainer>
                                <StyledInput type="checkbox" label="Free Trial" disabled={isFreeTrialDisabled} checked={isFreeTrialDisabled ? false : s.isFreeTrial} onChange={() => setStudentsFreeTrial(s.id, !s.isFreeTrial)} />
                              </InputContainer>
                            </CheckBoxField>
                          )}
                        </FieldFlex>

                        {(type === 'reschedule'
                          && (selectedEvent.lessonType === 'Private Lesson'
                            || selectedEvent.lessonType === 'Semiprivate Lesson')
                        ) ? (
                          <Fragment style={{ width: '100%' }}>
                            <FieldFlex>
                              <StyledText>Estimated Price: </StyledText>
                              <CreditValueWrapper>
                                <CreditValueField
                                  name={`abc${s.id}`}
                                  type="text"
                                  component={FieldComponent}
                                  initialValue={s.attendance != undefined ? Number(s.attendance.finalPrice) : ''}
                                  disabled
                                />
                                <CreditValueText> HKD </CreditValueText>
                              </CreditValueWrapper>
                            </FieldFlex>
                            <FieldFlex>
                              <StyledText>Estimated Remaining: </StyledText>
                              <CreditValueWrapper>
                                <CreditValueField
                                  name={`xyz${s.id}`}
                                  type="text"
                                  component={FieldComponent}
                                  initialValue={s.attendance != undefined ? Number(s.attendance.estimatedRemaining) : ''}
                                  disabled
                                />
                                <CreditValueText> HKD </CreditValueText>
                              </CreditValueWrapper>
                            </FieldFlex>
                          </Fragment>
                        ) : null}
                      </div>
                    )
                  })}
                </div>
              ) : null}

              {type === 'delete' ? (
                <StyledButton type="submit" disabled={submitting} style={{ width: '100%' }}>
                  Save
                </StyledButton>
              ) : (
                <FieldFlex>
                  <StyledText />
                  <Flex style={{ width: '100%' }}>
                    <StyledButton type="submit" disabled={submitting}>
                      Save
                    </StyledButton>
                  </Flex>
                </FieldFlex>
              )}
            </MainWrapper>
          </StyledForm >
        )
      }}
    />
  )
};

LessonForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  child: PropTypes.array.isRequired,
  instructor: PropTypes.array.isRequired,
  selectedEvent: PropTypes.object,
  studentId: PropTypes.array,
  eventInstructor: PropTypes.array,
  type: PropTypes.string.isRequired,
  allLessons: PropTypes.array,
  allPackageInfo: PropTypes.array,
};

export default LessonForm;
