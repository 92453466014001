import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import { ConfigProvider, Empty } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import get from 'lodash/get';
import P from '../../../components/atoms/P';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Flex from '../../../components/atoms/Flex';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import Button from '../../../components/atoms/Button';
import useUserForm from '../../../hooks/useUserForm';
import inputOptions from '../../../utils/form/inputOptions';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import Loading from '../../../components/atoms/Loading';
import PopUpInvoice from '../../../components/organisms/PopUpInvoice';
import useGeneralData from '../../../hooks/useGeneralData';
import PopUpEditInvoice from '../../../components/organisms/PopUpEditInvoice';
import PopUpDelete from '../../../components/organisms/PopUpDelete';

const Wrapper = styled.div`
  padding-bottom: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
    height: 100%;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};
  min-width: 200px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const ModelContainer = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
  > div {
    @media (max-width: ${size('mobileBreakpoint')}) {
      height: 100%;
    }
    > div: first-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        max-height: ${(props) => (props.divHeight ? 'none' : '56px')}
      }
    }
    > div: last-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        height: calc(100% - 56px);
        height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')}
      }
      > div {
        @media (max-width: ${size('mobileBreakpoint')}) {
          height: 100%;
        }
        > div {
          @media (max-width: ${size('mobileBreakpoint')}) {
            height: 100%;
          }
          > div: last-child {
            @media (max-width: ${size('mobileBreakpoint')}) {
              height: 100%;
            }
            > div {
              @media (max-width: ${size('mobileBreakpoint')}) {
                height: calc(100% - 40px);
              }
              > div {
                @media (max-width: ${size('mobileBreakpoint')}) {
                  height: 100%;
                }
                > div: last-child {
                  max-height: 100% !important;
                  @media (max-width: ${size('mobileBreakpoint')}) {
                    max-height: calc(100% - 55px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;
  font-weight: normal;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledLink = styled(Link)`
  color: #3E90F7;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-family: ${font('secondary')};

  &:hover,
  &:focus,
  &:active {
    color: #3E90F7;
  }
`;

const ErrorAlertContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  > div {
    > div {
      height: 100%;
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const modelName = 'sale';

const itemsPerPage = 25;

const ListView = (props) => {
  const { user, location } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [divHeight, setDivHeight] = useState(0);

  const { data, loading } = useGeneralData({
    endpoint: 'user/getBusinessProfile',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  if (loading) return <Loading />

  const cellRenderers = [
    {
      title: 'Sale ID',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Cell>{`S-${id}`}</Cell>,
      width: 90,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Buy Back Type',
      dataIndex: 'buyBackType',
      key: 'buyBackType',
      render: (content) => <Cell>{content || 'N/A'}</Cell>,
      width: 130,
    },
    {
      title: 'Sale to / Buy Back From',
      dataIndex: 'soldTo',
      key: 'soldTo',
      width: 190,
      render: (info) => <Cell>{((info && info.id) ? <Link to={`/user/show/${info.id}`}>{`${info.name}`}</Link> : info)}</Cell>,
    },
    {
      title: 'Student',
      dataIndex: 'studentInfo',
      key: 'studentInfo',
      width: 90,
      render: (student) => <Cell>{(student ? <Link to={`/student/show/${student.id}`}>{student.name}</Link> : 'N/A')}</Cell>,
    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      render: (content) => <Cell>{content}</Cell>,
      width: 130,
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (content) => <Cell>{content}</Cell>,
      width: 150,
    },
    {
      title: 'Package ID',
      dataIndex: 'packageInfo',
      key: 'packageInfo',
      render: (packageInfo) => <Cell>{((packageInfo && packageInfo.id) ? <Link to={`/package/show/${packageInfo.id}`}>{`${packageInfo.prefix}${packageInfo.id}`}</Link> : 'N/A')}</Cell>,
      width: 110,
    },
    {
      title: 'Total Price',
      dataIndex: 'paymentPrice',
      key: 'paymentPrice',
      width: 120,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Credit Card Charge',
      dataIndex: 'creditCardCharge',
      key: 'creditCardCharge',
      width: 170,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Total Profit',
      dataIndex: 'totalProfit',
      key: 'totalProfit',
      width: 120,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Total Internal Revenue',
      dataIndex: 'formattedTotalRevenue',
      key: 'formattedTotalRevenue',
      width: 190,
      render: (content) => <Cell>{content || 'N/A'}</Cell>,
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 110,
      render: (creator) => <Cell>{(user.role === 'superAdmin' ? <Link to={`/staff/show/${creator.id}`}>{creator.name}</Link> : creator.name)}</Cell>,
    },
    {
      title: 'Invoice / Credit Note Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      render: (date) => <Cell>{date}</Cell>,
      width: 200,
    },
    {
      title: 'Invoice / Credit Note',
      dataIndex: 'invoiceOrNote',
      key: 'invoiceOrNote',
      render: (inv, record) => <Cell>{(inv.id ? <PopUpInvoice user={user} type={record.type} selectedRecord={record} businessProfile={data} /> : 'N/A')}</Cell>,
      width: 190,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Cell>
          <Flex>
            <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
              <StyledLink to={{ pathname: `sale/edit/${record.id}`, state: { prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` } }}>Edit</StyledLink>
            </div>
            <div style={{ paddingLeft: 5 }}>
              <PopUpEditInvoice user={user} record={record} type={record.type} />
            </div>
            <div style={{ paddingLeft: 5 }}>
              <PopUpDelete user={user} endpoint="sale" id={record.id} type="Sale" />
            </div>
          </Flex>
        </Cell>
      ),
      width: 90,
    },
  ];

  const expandableColumns = [
    {
      title: 'Product ID',
      dataIndex: 'productId',
      key: 'productId',
      render: (id) => <Link to={`inventory/show/${id}`}>{`INV-PR${id}`}</Link>,
    },
    {
      title: 'Product Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Section',
      dataIndex: 'section',
      key: 'section',
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Publisher / Maker',
      dataIndex: 'publisherOrMaker',
      key: 'publisherOrMaker',
    },
    {
      title: 'Item ID',
      dataIndex: 'itemId',
      key: 'itemId',
    },
    {
      title: 'Selling Price',
      dataIndex: 'formattedSalePrice',
      key: 'formattedSalePrice',
    },
    {
      title: 'Purchased Price',
      dataIndex: 'formattedPurchasePrice',
      key: 'formattedPurchasePrice',
    },
    {
      title: 'Rental Period',
      dataIndex: 'rentalPeriod',
      key: 'rentalPeriod',
    },
  ];

  const filterConfig = [
    {
      type: 'radioGroup',
      name: 'type',
      label: 'Type',
      options: [
        { label: 'All', value: 'All' },
        { label: 'Sale', value: 'Sale' },
        { label: 'Buy Back', value: 'Buy Back' },
      ],
    },
    {
      type: 'spec',
      name: 'buyerId',
      label: 'Sale To / Buy Back From',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: { endpoint: 'user', query: { where: { role: 'parent' }, order: { sortKey: ['firstName', 'lastName'], sortOrder: 'asc' } } },
      associationType: 'own',
    },
    {
      type: 'spec',
      name: 'studentId',
      label: 'Student',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: { endpoint: 'student', query: { order: { sortKey: ['child.firstName', 'child.lastName'], sortOrder: 'asc' } } },
      associationType: 'own',
    },
    {
      type: 'dateRange',
      name: 'paymentDate',
      label: 'Payment Date',
      showTime: false,
    },
    {
      type: 'checkboxGroup',
      name: 'paymentMethod',
      label: 'Payment Method',
      options: inputOptions.salesPaymentOptions,
    },
    {
      type: 'checkboxGroup',
      name: 'inventoryItems->inventory.type',
      label: 'Product Type',
      options: inputOptions.inventoryTypeOptions,
    },
    {
      type: 'multi-select',
      name: 'inventoryItems->inventory.section',
      label: 'Section',
      endpoint: 'inventory/inventorySections',
      valueBy: (v) => v.value,
      labelBy: (v) => v.label,
    },
    {
      type: 'multi-select',
      name: 'inventoryItems->inventory.product',
      label: 'Product',
      endpoint: 'inventory/productOptions',
      valueBy: (v) => v.product,
      labelBy: (v) => v.product,
    },
    {
      type: 'multi-select',
      name: 'inventoryItems->inventory.publisherOrMaker',
      label: 'Publisher / Maker',
      endpoint: 'inventory/publisherOrMakerOptions',
      valueBy: (v) => v.publisherOrMaker,
      labelBy: (v) => v.publisherOrMaker,
    },
  ];

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderText> Sale Management </HeaderText>
        <Flex>
          <ErrorAlert
            errorMessage={errorMessage}
            show={errorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw / 2)' }}
          />
        </Flex>
      </Flex>
      <ModelContainer divHeight={divHeight}>
        <ModelPage
          showHeader={false}
          modelName={modelName}
          itemsPerPage={itemsPerPage}
          allowSearch
          filterConfig={filterConfig}
          query={{ where: { saleStatus: 'All' }, userRole: user.role }}
          initialQuery={{
            currentPage: 1,
            where: {
              saleStatus: 'All',
            },
          }}
          radioGroup={['All', 'Paid', 'Unpaid']}
          radioGroupKey="saleStatus"
          showCreateButton={user.role === 'admin' || user.role === 'superAdmin'}
          showExportButton={user.role === 'admin' || user.role === 'superAdmin'}
          createState={{ prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` }}
          {...props}
          render={(renderProps) => {
            const { height } = renderProps;
            if (divHeight !== height) setDivHeight(height);
            return (
              <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Sale" />}>
                <AntDTable
                  modelName={modelName}
                  cellRenderers={user.role === 'admin' ? cellRenderers.filter((c) => c.title !== 'Total Internal Revenue') : cellRenderers}
                  scroll={{ x: 'max-content', y: '65vh' }}
                  pagination={{ pageSize: itemsPerPage, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                  rowKey="id"
                  expandable={{
                    expandedRowRender: (record) => (
                      <AntDTable
                        modelName={modelName}
                        cellRenderers={expandableColumns}
                        pagination={false}
                        rowKey="id"
                        data={record.inventoryItems}
                        isExpanded
                      />
                    ),
                  }}
                  {...renderProps}
                />
              </ConfigProvider>
            )
          }}
        />
      </ModelContainer>
    </Wrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
