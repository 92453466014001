import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import SignaturePad from 'react-signature-canvas';
import get from 'lodash/get';
import { ifProp } from 'styled-tools';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Link from '../../atoms/Link';
import RequestForm from '../RequestForm';
import useCalendarForm from '../../../hooks/useCalendarForm';
import Icon from '../../atoms/Icon';
import Flex from '../../atoms/Flex';
import FieldComponent from '../../molecules/FieldComponent';
import { validations } from '../../../utils/form';
import inputOptions from '../../../utils/form/inputOptions';
import Image from '../../atoms/Image';
import defaultProfile from '../../../asset/image/defaultprofile.png';
import Input from '../../atoms/Input';
import ErrorAlert from '../../molecules/ErrorAlert';

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 45vw;
  min-width: 45vw;
  padding: 20px;
  max-height: 98vh;

  > form {
    height: ${ifProp('saveButton', 'calc(100% - 47px)', 'calc(100% - 87px)')};
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledButton2 = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;
  text-align: left;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 5px;
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const FormulaField = styled(FieldFlex)`
  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 15px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 17px 15px 22px 0px;
  width: 150px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledButton = styled(Button)`
  min-width: 180px;
  margin: ${size('margin.default')} 0px ${size('margin.default')};
  border-radius: 0px;
  width: calc(100% - 20px);
`;

const CreditValueWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const CreditValueText = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
`;

const CreditValueField = styled(StyledField)`
  input {
    padding-right: 40px;
  }
`;

const PercentValueField = styled(StyledField)`
  input {
    padding-right: 25px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  > div {
    margin: 0px;
    margin-left: ${ifProp('extraMargin', '10px', ifProp('smallMargin', '8px', '7px'))};
    > label {
      > div {
        left: 0px;
        top: 1px;
      }
    }
  }
`;

const StyledInput = styled(Input)`
  transform: scale(1.1);
  font-size: 12px;
`;

const SignatureButton = styled(Button)`
  background-color: #fff;
  border: 1px solid #BDBDBD;
  border-radius: 0px;
  display: flex;
  color: #000;
  margin-bottom: 0;

  &:hover,
  &:focus,
  &:active {
    color: #000;
    background-color: #fff;
  }
`;

const StyledIcon = styled(Icon)`
  height: 16px;
  min-width: 16px;
  margin-right: 10px;
`;

const StyledIcon2 = styled(Icon)`
  height: 20px;
  min-width: 20px;
  margin-right: 10px;
`;

const SignatureContainer = styled.div`
  .signatureCanvas {
    border: 1px solid #BDBDBD;
    width: 100%;
    min-height: 400px;
    border-radius: 4px;
  }
`;

const ProfileText = styled(StyledText)`
  padding-top: 8px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
    padding-bottom: 6px;
  }
`;

const ErrorAlertContainer = styled.div`
  > div {
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: 75vw;
      width: fit-content;
    }
  }
`;

const PopUpAttendance = ({ user, id, child, instructor, selectedEvent, allLessons, type, action }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [completeMessage, setCompleteMessage] = useState(null);
  const [checked, setChecked] = useState(selectedEvent.showOffPeak);
  const [freeTrialChecked, setFreeTrialChecked] = useState(selectedEvent.showFreeTrial);
  const [freeTrialPackageChecked, setFreeTrialPackageChecked] = useState(selectedEvent.showFreeTrialPackage);
  const signatureCanvas = useRef({});
  const [signatureUrl, setSignatureUrl] = useState(selectedEvent.signature);
  const [showSign, setShowSign] = useState(!signatureUrl && !selectedEvent.isConfirmed);
  const [loading, setLoading] = useState(false);

  const { onSubmit } = useCalendarForm({
    endpoint: 'event/updateAttendance',
    setErrorMessage,
    type: 'attendance',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const sortedChild = child ? child.map((c) => {
    c.label = `${c.firstName} ${c.lastName}`;
    c.value = c.id;
    return c;
  }).sort((a, b) => (a.label > b.label ? 1 : -1)) : [{ value: get(selectedEvent, 'child.id', null), label: `${get(selectedEvent, 'child.firstName', null)} ${get(selectedEvent, 'child.lastName', null)}` }];

  const sortedInstructor = instructor ? instructor.map((i) => {
    i.label = `${i.firstName} ${i.lastName}`;
    i.value = i.id;
    return i;
  }).sort((a, b) => (a.label > b.label ? 1 : -1)) : [{ value: get(selectedEvent, 'user.id', null), label: `${get(selectedEvent, 'user.firstName', null)} ${get(selectedEvent, 'user.lastName', null)}` }];

  const disableOrHide = (type === 'Cancelled' || action === 'View' || (action === 'Upcoming' && Number(user.id) === 409));

  const initialValues = {
    student: selectedEvent.childId,
    title: selectedEvent.title,
    instructor: selectedEvent.instructorId,
    date: selectedEvent.date,
    startTime: moment(selectedEvent.startTime).format('HH:mm'),
    endTime: moment(selectedEvent.endTime).format('HH:mm'),
    initialPrice: selectedEvent.initialPrice,
    defaultDiscount: selectedEvent.defaultDiscount,
    otherDiscount: selectedEvent.otherDiscount,
    teachingNotes: selectedEvent.teachingNotes,
    cancelledFinalPrice: selectedEvent.creditsDeductNum,
  };

  const signatureTrigger = (signatureUrl || selectedEvent.isConfirmed) ? <SignatureButton> <StyledIcon2 icon="hide-eye" /> Click to View </SignatureButton> : <SignatureButton> <StyledIcon icon="signature" /> Click to Sign </SignatureButton>;
  const clear = () => signatureCanvas.current.clear();
  const save = async () => {
    if (signatureCanvas.current.isEmpty()) {
      setSignatureUrl(null);
      setShowSign(true);
    } else {
      const imageData = await new Promise((resolve) => signatureCanvas.current.getCanvas().toBlob(resolve, 'image/png'));
      const { data: signedUrl } = await global.api.get('/s3/getSignedUrl', {
        params: { fileName: 'signature', fileType: 'image/png', pathPrefix: 'images/signature' },
      });

      let imgUrl;
      setLoading(true);

      try {
        const { url } = await fetch(signedUrl, {
          method: 'PUT',
          body: imageData,
          headers: { 'Content-Type': 'image/png' },
        });
        [imgUrl] = url.split('?');
        setSignatureUrl(imgUrl);
        setShowSign(false);
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    }
  }

  return (
    <StyledPopup
      trigger={<StyledButton2> {disableOrHide ? 'View' : 'Edit'} </StyledButton2>}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper saveButton={disableOrHide}>
          <PopUpButton className="close" onClick={() => { close(); setSignatureUrl(selectedEvent.signature); setShowSign(!selectedEvent.signature && !selectedEvent.isConfirmed) }}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper> {disableOrHide ? 'View' : 'Edit'} </PopUpTextWrapper>
          <Form
            name="AttendanceForm"
            onSubmit={onSubmit}
            initialValues={initialValues}
            initialValuesEqual={() => true}
            render={({ handleSubmit, submitting, values }) => {
              let lessonOptions = allLessons ? allLessons.filter((l) => l.type === selectedEvent.lessonType) : [{ name: selectedEvent.title }];
              if (selectedEvent.lessonType === 'Semiprivate Lesson') lessonOptions = allLessons ? allLessons.filter((l) => l.type === 'Semiprivate Lesson - 2 persons') : lessonOptions;
              lessonOptions.map((l) => {
                l.label = l.name;
                l.value = l.name;
                return l;
              })
              lessonOptions = lessonOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

              values.id = selectedEvent.id;
              values.checkedOffPeak = checked;
              values.checkedFreeTrial = freeTrialChecked;
              values.signature = signatureUrl;
              values.oldLessonPrice = selectedEvent.initialPrice;
              values.oldDefaultDiscount = selectedEvent.defaultDiscount;
              values.oldOtherDiscount = selectedEvent.otherDiscount;

              const defaultDiscountDecimal = (1 - (values.defaultDiscount / 100));
              const otherDiscountDecimal = (1 - (values.otherDiscount / 100));
              const offPeakDiscount = checked ? (1 - 0.05) : 1;
              const freeTrialDiscount = freeTrialChecked ? 0 : 1;
              const freeTrialPackageDiscount = freeTrialPackageChecked ? 0 : 1;
              const initPrice = values.initialPrice || 0;
              const formula = `${initPrice}${defaultDiscountDecimal < 1 ? ` x ${+(Number(defaultDiscountDecimal)).toFixed(4)}` : ''}${otherDiscountDecimal < 1 ? ` x ${+(Number(otherDiscountDecimal)).toFixed(4)}` : ''}${checked ? ' x 0.95' : ''}${freeTrialChecked ? ' x 0' : ''}${freeTrialPackageChecked ? ' x 0' : ''}`;
              const finalCredits = +(Number(initPrice * defaultDiscountDecimal * otherDiscountDecimal * offPeakDiscount * freeTrialDiscount * freeTrialPackageDiscount)).toFixed(2);

              return (
                <StyledForm onSubmit={handleSubmit}>
                  <div style={{ paddingRight: '20px', maxHeight: '100%', overflow: 'auto' }}>
                    <ErrorAlertContainer>
                      <ErrorAlert
                        errorMessage={errorMessage}
                        show={errorMessage !== null}
                      />
                    </ErrorAlertContainer>

                    <FieldFlex style={{ alignItems: 'normal' }}>
                      <ProfileText>Profile: </ProfileText>
                      <div style={{ width: '100%' }}>
                        <Image width={128} height={128} src={selectedEvent.child.profileImage || defaultProfile} alt="Image not found" style={{ minWidth: 128 }} />
                      </div>
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Student: </StyledText>
                      <StyledField
                        name="student"
                        type="select"
                        component={FieldComponent}
                        disabled
                        options={sortedChild}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Lesson: </StyledText>
                      <StyledField
                        name="title"
                        type="select"
                        component={FieldComponent}
                        options={lessonOptions}
                        disabled
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Instructor: </StyledText>
                      <StyledField
                        name="instructor"
                        type="select"
                        component={FieldComponent}
                        options={sortedInstructor}
                        disabled
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Date: </StyledText>
                      <StyledField
                        name="date"
                        type="date"
                        component={FieldComponent}
                        disabled
                        isClearable={false}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Time: </StyledText>
                      <FieldFlex style={{ width: '100%', alignItems: 'baseline', flexDirection: 'row' }}>
                        <StyledField
                          name="startTime"
                          type="time"
                          component={FieldComponent}
                          disabled
                          isClearable={false}
                          validate={validations.required}
                        />

                        <div style={{ marginLeft: '10px' }}> - </div>
                        <StyledField style={{ marginLeft: '10px' }}
                          name="endTime"
                          type="time"
                          disabled
                          isClearable={false}
                          component={FieldComponent}
                        />
                      </FieldFlex>
                    </FieldFlex>

                    { user.role === 'instructor' ? (
                      <FieldFlex style={{ alignItems: 'normal' }}>
                        <ProfileText style={{ paddingTop: 14 }}>Teaching Notes: </ProfileText>
                        <StyledField
                          name="teachingNotes"
                          type="textarea"
                          component={FieldComponent}
                          disabled={disableOrHide}
                        />
                      </FieldFlex>
                    ) : null}

                    { ((user.role === 'admin' || user.role === 'superAdmin') && type !== 'Cancelled' && !(Number(user.id) === 409)) ? (
                      <div>
                        <FieldFlex>
                          <StyledText>Initial Price: </StyledText>
                          <CreditValueWrapper>
                            <CreditValueField
                              name="initialPrice"
                              type="number"
                              min={0}
                              step="any"
                              component={FieldComponent}
                              disabled={disableOrHide}
                              validate={validations.required}
                            />
                            <CreditValueText> HKD </CreditValueText>
                          </CreditValueWrapper>
                        </FieldFlex>

                        <FieldFlex>
                          <StyledText>Lesson Discount: </StyledText>
                          <CreditValueWrapper>
                            <PercentValueField
                              name="defaultDiscount"
                              type="number"
                              component={FieldComponent}
                              disabled={disableOrHide}
                              min={0}
                              max={100}
                              step="any"
                              validate={validations.required}
                            />
                            <CreditValueText> % </CreditValueText>
                          </CreditValueWrapper>
                        </FieldFlex>

                        <FieldFlex>
                          <StyledText>Other Discount: </StyledText>
                          <CreditValueWrapper>
                            <PercentValueField
                              name="otherDiscount"
                              type="number"
                              component={FieldComponent}
                              disabled={disableOrHide}
                              min={0}
                              max={100}
                              step="any"
                              validate={validations.required}
                            />
                            <CreditValueText> % </CreditValueText>
                          </CreditValueWrapper>
                        </FieldFlex>

                        <FieldFlex style={{ height: 20, paddingBottom: 10, marginBottom: 10, flexDirection: 'row' }}>
                          <StyledText />
                          <InputContainer>
                            <StyledInput type="checkbox" label="Off-peak (5%)" checked={checked} disabled={disableOrHide} onChange={() => setChecked(!checked)} />
                          </InputContainer>
                        </FieldFlex>

                        <FieldFlex style={{ height: 20, paddingBottom: 10, marginBottom: freeTrialPackageChecked ? 10 : 0, flexDirection: 'row' }}>
                          <StyledText />
                          <InputContainer smallMargin>
                            <StyledInput type="checkbox" label="Free Trial (100%)" checked={freeTrialChecked} disabled={disableOrHide} onChange={() => setFreeTrialChecked(!freeTrialChecked)} />
                          </InputContainer>
                        </FieldFlex>

                        { freeTrialPackageChecked ? (
                          <FieldFlex style={{ height: 20, paddingBottom: 10, flexDirection: 'row' }}>
                            <StyledText />
                            <InputContainer extraMargin>
                              <StyledInput type="checkbox" label="Free Trial Package (100%)" checked={freeTrialPackageChecked} disabled onChange={() => setFreeTrialPackageChecked(!freeTrialPackageChecked)} />
                            </InputContainer>
                          </FieldFlex>
                        ) : null}

                        <FormulaField>
                          <StyledText>Formula: </StyledText>
                          <CreditValueField
                            name="formula"
                            type="text"
                            initialValue={formula}
                            component={FieldComponent}
                            disabled
                          />
                        </FormulaField>

                        <FieldFlex>
                          <StyledText>Final Price: </StyledText>
                          <CreditValueWrapper>
                            <CreditValueField
                              name="finalCredits"
                              type="number"
                              min={0}
                              step="any"
                              initialValue={finalCredits}
                              component={FieldComponent}
                              disabled
                            />
                            <CreditValueText> HKD </CreditValueText>
                          </CreditValueWrapper>
                        </FieldFlex>
                      </div>
                    ) : null}

                    { ((user.role === 'admin' || user.role === 'superAdmin') && type === 'Cancelled') ? (
                      <FieldFlex>
                        <StyledText>Final Price: </StyledText>
                        <CreditValueWrapper>
                          <CreditValueField
                            name="cancelledFinalPrice"
                            type="number"
                            min={0}
                            step="any"
                            component={FieldComponent}
                            disabled
                          />
                          <CreditValueText> HKD </CreditValueText>
                        </CreditValueWrapper>
                      </FieldFlex>
                    ) : null}

                    { (user.role === 'parent' && type === 'Completed') ? (
                      <div>
                        { selectedEvent.signature ? (
                          <FieldFlex style={{ alignItems: 'normal' }}>
                            <ProfileText> Signature: </ProfileText>
                            <div style={{ width: '100%' }}>
                              <Image height={200} src={signatureUrl} alt="My Signature" style={{ minWidth: '100%', border: '1px solid #BDBDBD', borderRadius: 4 }} />
                            </div>
                          </FieldFlex>
                        ) : (
                          <FieldFlex style={{ alignItems: 'normal' }}>
                            <ProfileText> Signature: </ProfileText>
                            <div style={{ width: '100%' }}>
                              <div style={{ height: 200, minWidth: '100%', border: '1px solid #BDBDBD', borderRadius: 4 }} />
                            </div>
                          </FieldFlex>
                        )}
                      </div>
                    ) : null}

                    {(type !== 'Cancelled' && action !== 'Upcoming' && user.role !== 'instructor' && user.role !== 'parent') ? (
                      <FieldFlex style={{ alignItems: 'normal' }}>
                        <ProfileText>Signature: </ProfileText>
                        <div style={{ width: '100%' }}>
                          <StyledPopup
                            trigger={signatureTrigger}
                            modal
                            closeOnDocumentClick={false}
                            closeOnEscape={false}
                          >
                            {(close2) => (
                              <PopUpWrapper>
                                <PopUpButton
                                  className="close"
                                  onClick={() => {
                                    close2();
                                    if (signatureUrl || selectedEvent.isConfirmed) setShowSign(false);
                                    else setShowSign(true);
                                  }}
                                >
                                  &times;
                                </PopUpButton>
                                <PopUpTextWrapper> Signature </PopUpTextWrapper>
                                { showSign ? (
                                  <div>
                                    <SignatureContainer>
                                      <SignaturePad ref={signatureCanvas} canvasProps={{ className: 'signatureCanvas' }} />
                                    </SignatureContainer>
                                    <Flex style={{ justifyContent: 'space-between' }}>
                                      <StyledButton onClick={clear} style={{ width: '50%', marginRight: 20, minWidth: 0 }}> Clear </StyledButton>
                                      <StyledButton onClick={async () => { await save(); close2(); }} style={{ width: '50%', marginLeft: 20, minWidth: 0 }}> Apply </StyledButton>
                                    </Flex>
                                  </div>
                                ) : signatureUrl ? (
                                  <div>
                                    <Image width="100%" height={400} src={signatureUrl} alt="My Signature" style={{ minWidth: '100%', border: '1px solid #BDBDBD', borderRadius: 4 }} />
                                    { !disableOrHide ? <StyledButton style={{ width: '100%' }} onClick={() => setShowSign(true)}> Edit </StyledButton> : null}
                                  </div>
                                ) : (
                                  <FieldFlex style={{ alignItems: 'normal' }}>
                                    <div style={{ width: '100%' }}>
                                      <div style={{ height: 400, minWidth: '100%', border: '1px solid #BDBDBD', borderRadius: 4 }} />
                                    </div>
                                  </FieldFlex>
                                )}
                              </PopUpWrapper>
                            )}
                          </StyledPopup>
                        </div>
                      </FieldFlex>
                    ) : null}
                  </div>

                  { !disableOrHide ? <StyledButton type="submit" disabled={submitting}> Save </StyledButton> : null}
                </StyledForm>
              )
            }}
          />
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpAttendance.propTypes = {
  user: PropTypes.object.isRequired,
  id: PropTypes.number,
  child: PropTypes.array.isRequired,
  instructor: PropTypes.array.isRequired,
  selectedEvent: PropTypes.object,
  allLessons: PropTypes.array,
};

export default PopUpAttendance;
