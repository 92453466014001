import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import { ifProp } from 'styled-tools';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Flex from '../../atoms/Flex';
import FieldComponent from '../../molecules/FieldComponent';
import { validations } from '../../../utils/form';
import useUserForm from '../../../hooks/useUserForm';
import ErrorAlert from '../../molecules/ErrorAlert';
import Input from '../../atoms/Input';

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 35vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 150px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 12px;
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const StyledButton = styled(Button)`
  min-width: 160px;
  width: 100%;
  margin: ${size('margin.default')} auto ${size('margin.default')};
  border-radius: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
`;

const PriceTextWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const PriceText = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
`;

const PriceField = styled(StyledField)`
  input {
    padding-right: 50px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  > div {
    margin: 0px;
    margin-left: ${ifProp('smallMargin', '9px', '10px')};
    > label {
      > div {
        left: 0px;
      }
    }
  }
`;

const StyledInput = styled(Input)`
  transform: scale(1.2);
  font-size: 12px;
`;

const CheckBoxContainer = styled(FieldFlex)`
  height: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 25px;
    margin-bottom: 0px;
  }
`;

const ErrorAlertContainer = styled.div`
  > div {
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: 75vw;
      width: fit-content;
    }
  }
`;

const PopUpAddLesson = ({ open, type, record, onClose }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [noDiscount, setNoDiscount] = useState(type === 'create' ? false : record.noDiscount);
  const [noHourly, setNoHourly] = useState(type === 'create' ? false : record.noHourly);

  const { onSubmit } = useUserForm({
    endpoint: type !== 'update' ? 'user/lessonTypes' : `user/lessonTypes/${record.id}`,
    setErrorMessage,
    type: 'settings',
    action: type !== 'update' ? 'create' : 'update',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const recordLessonType = type === 'create' ? record : record.type;
  return (
    <StyledPopup
      open={open}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          <PopUpButton className="close" onClick={() => { close(); onClose(); }}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper> {type === 'create' ? 'Create' : type === 'update' ? 'Editt' : 'Edit'} </PopUpTextWrapper>

          <Form
            name="AddLessonForm"
            onSubmit={onSubmit}
            initialValues={type === 'create' ? null : { name: record.name, createdAt: record.createdAt, price: record.price }}
            initialValuesEqual={() => true}
            render={({ handleSubmit, values, submitting }) => {
              values.lessonType = recordLessonType;
              values.noDiscount = noDiscount;
              values.noHourly = noHourly;
              values.action = type;

              if (type === 'edit') values.record = record;
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <ErrorAlertContainer>
                    <ErrorAlert
                      errorMessage={errorMessage}
                      show={errorMessage !== null}
                    />
                  </ErrorAlertContainer>

                  <FieldFlex>
                    <StyledText>Lesson: </StyledText>
                    <StyledField
                      name="name"
                      type="text"
                      component={FieldComponent}
                      validate={validations.required}
                    />
                  </FieldFlex>

                  <FieldFlex>
                    <StyledText>Last Edit: </StyledText>
                    <StyledField
                      name="createdAt"
                      type="date"
                      component={FieldComponent}
                      validate={validations.required}
                    />
                  </FieldFlex>

                  <FieldFlex>
                    <StyledText>Base Pricing per Hour: </StyledText>
                    <PriceTextWrapper>
                      <PriceField
                        name="price"
                        type="number"
                        component={FieldComponent}
                        min={0}
                        step="any"
                        validate={recordLessonType !== 'Group Lesson' && validations.required}
                      />
                      <PriceText> HKD </PriceText>
                    </PriceTextWrapper>
                  </FieldFlex>

                  <CheckBoxContainer>
                    <StyledText />
                    <InputContainer smallMargin>
                      <StyledInput type="checkbox" label="Fixed Price" checked={noHourly} onChange={() => setNoHourly(!noHourly)} />
                    </InputContainer>
                  </CheckBoxContainer>

                  <CheckBoxContainer style={{ marginBottom: 0 }}>
                    <StyledText />
                    <InputContainer>
                      <StyledInput type="checkbox" label="No Discount" checked={noDiscount} onChange={() => setNoDiscount(!noDiscount)} />
                    </InputContainer>
                  </CheckBoxContainer>

                  <StyledButton type="submit" disabled={submitting}> Save </StyledButton>
                </StyledForm>
              )
            }}
          />
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpAddLesson.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  record: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default PopUpAddLesson;
