// import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
import { withProps, compose } from 'recompose';
import nil from 'lodash/isNil';

import { styles } from '../../../utils/table';
import Block from '../../atoms/Block';

const {
  defaultCell: defaultCellStyles,
} = styles;


const DefaultCellComponent = compose(
  withProps(({ cellData, displayValue }) => ({
    children:  nil(displayValue) ? nil(cellData) ? 'N/A' : String(cellData) : String(displayValue),
    style: defaultCellStyles,
  }))
)(Block);


export default DefaultCellComponent;
