import { compose, withState, withHandlers } from 'recompose';

const enhance = compose(
  withState('data', 'setData', undefined),
  withState('initialValues', 'setInitialValues', ({ filterBy }) => {
    return filterBy
  }),
  withHandlers({
    onSubmit: ({ onFilter }) => (values) => {
      onFilter({ filterBy: values });
    },
    onReset: ({ onFilter, setInitialValues }) => (form) => {
      form.reset();
      setInitialValues({});
      onFilter({
        filterKey: [],
        filterValue: [],
      });
    },
  }),
  withState('showFilterBlock', 'setShowFilterBlock', false),
  withHandlers({
    setFilterBlockVisibility: ({
      showFilterBlock,
      setShowFilterBlock,
    }) => () => {
      setShowFilterBlock(!showFilterBlock);
    },
  }),
);

export default enhance;
