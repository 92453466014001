import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import startCase from 'lodash/startCase';
import { Tabs, ConfigProvider, Empty, Tag } from 'antd';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Button from '../../../components/atoms/Button';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import PopUpSalaryBonus from '../../../components/organisms/PopUpSalaryBonus';
import PopUpFile from '../../../components/organisms/PopUpFile';
import Image from '../../../components/atoms/Image';
import defaultProfile from '../../../asset/image/defaultprofile.png'
import useQueryParams from '../../../hooks/useQueryParams';
import PopUpDelete from '../../../components/organisms/PopUpDelete';

const Wrapper = styled.div`
  height: 100%;
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100% - 66px);
  padding-bottom: 10px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
    min-height: calc(100% - 56px);
    padding-bottom: 0px;
  }
`;

const LeftWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 30%;
  margin-right: 15px;
  max-height: 100%;
  overflow: auto;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 12px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
    max-height: calc(50vh - 50px);
  }
`;

const RightWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  width: 70%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 0px;
    width: 100%;
    flex: 1;
  }
`;

const DetailWrapper = styled(Flex)`
  flex-direction: column;
`;

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  padding: 0px 0px 15px 0px;
  border-bottom: 1px dashed #E7E7E7;
`;

const LeftTopWrapper = styled(Flex)`
  justify-content: center;
  flex-direction: column;
`;

const ImageWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
  justify-content: center;
  margin-top: 0px;
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding: 10px 0px;
`;

const LabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const DetailLabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  min-width: 100px;
  margin-right: 20px;
  font-family: ${font('tertiary')};
`;

const EditWrapper = styled(Flex)`
  justify-content: flex-end;
`;

const StyledLink = styled(Link)`
  color: #3E90F7;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    color: #3E90F7;
  }
`;

const ModelContainer = styled.div`
  > div {
    padding: 24px 0px;
    > div {
      display: flex;
      justify-content: flex-end;
    }
  }

  .ant-table-body {
    max-height: 100% !important;
    @media (max-width: ${size('mobileBreakpoint')}) {
      max-height: ${(props) => (props.divHeight ? `calc(100vh - ${props.divHeight}px - 260px)` : 'calc(100vh - 56px - 260px)')} !important;
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const ShowView = (props) => {
  const { user, match, location } = props;
  const [updateSalaryBonus, setUpdateSalaryBonus] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [divHeight, setDivHeight] = useState(0);
  const [salaryDivHeight, setSalaryDivHeight] = useState(0);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  const { data, loading } = useGeneralData({
    endpoint: 'user/userData',
    query: { userId: match.params.id, type: 'staff' },
  });

  const history = useHistory();

  const { queryParams, setQueryParams } = useQueryParams();

  if (loading) return <Loading />

  if (!data || !data.formattedUser) return <Redirect to="/staff" />;

  const { TabPane } = Tabs;

  const onTabChange = (tabValue) => {
    setQueryParams((old) => ({
      ...old,
      currentPage: 1,
      where: {
        ...old.where,
        tab: tabValue,
      },
    }), { replace: true });
  }

  const fileColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 80,
      render: (content) => <Cell style={{ maxWidth: 250 }}>{content}</Cell>,
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      width: 70,
      render: (url) => <Cell style={{ maxWidth: 'fit-content' }}><StyledLink to={{ pathname: url }} target="_blank" rel="noopener noreferrer"> {url} </StyledLink></Cell>,
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (text, record) => (
        <Cell>
          <PopUpDelete user={user} endpoint="user/file" id={record.id} type="File" />
        </Cell>
      ),
    },
  ];

  const salaryBonusColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 75,
      render: (date) => <Cell>{moment.tz(date, 'Asia/Singapore').format('DD/MM/YYYY')}</Cell>,
    },
    {
      title: 'Salary',
      dataIndex: 'salary',
      key: 'salary',
      width: 90,
      render: (salary) => <Cell>{(salary ? `$${Number(salary).toLocaleString()}` : 'N/A')}</Cell>,
    },
    {
      title: 'Extra Bonus',
      dataIndex: 'extraBonus',
      key: 'extraBonus',
      width: 120,
      render: (bonus) => <Cell>{(bonus ? `${bonus} hours` : 'N/A')}</Cell>,
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (text, record) => (
        <Cell>
          <PopUpDelete user={user} endpoint="user/salaryBonus" id={record.id} type="Salary and Bonus" />
        </Cell>
      ),
    },
  ];

  const profileImg = data.formattedUser.profileImage === null ? defaultProfile : data.formattedUser.profileImage;

  return (
    <Wrapper>
      {updateSalaryBonus ? (
        <PopUpSalaryBonus
          open
          userId={match.params.id}
          latestSalaryBonus={data.latestSalaryBonus}
          onClose={() => setUpdateSalaryBonus(null)}
        />
      ) : null}
      {uploadFile ? (
        <PopUpFile
          open
          type="staff"
          userId={match.params.id}
          onClose={() => setUploadFile(null)}
        />
      ) : null}
      <Flex>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> Staff Management </HeaderText>
        </HeaderTextWrapper>
      </Flex>
      <BottomWrapper>
        <LeftWrapper>
          <LeftTopWrapper>
            <EditWrapper>
              <StyledLink to={`/staff/edit/${match.params.id}`}>
                <Icon icon="edit-icon" height={21} style={{ minWidth: 21 }} />
                <div>Edit</div>
              </StyledLink>
            </EditWrapper>
            <ImageWrapper>
              <Image width={128} height={128} src={profileImg} alt="Image not found" style={{ minWidth: 128 }} />
              <LabelText style={{ fontSize: 16, paddingTop: 10 }}>{data.formattedUser.name}</LabelText>
            </ImageWrapper>
          </LeftTopWrapper>
          <DetailWrapper>
            <SectionWrapper>
              <StyledFlex>
                <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
                <LabelText style={{ marginRight: 0 }}> Basic Information </LabelText>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Account </DetailLabelText>
                <div>{data.formattedUser.email}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Role </DetailLabelText>
                <div>{startCase(data.formattedUser.role)}</div>
              </StyledFlex>
              <StyledFlex style={{ alignItems: 'normal' }}>
                <DetailLabelText> Lesson(s) </DetailLabelText>
                <div>{data.formattedUser.lessonNames ? data.formattedUser.lessonNames.map((l) => <div style={{ paddingBottom: 3 }}>{l}</div>) : 'N/A'}</div>
              </StyledFlex>
            </SectionWrapper>
            <SectionWrapper style={{ paddingTop: 10, borderBottomStyle: 'dashed' }}>
              <StyledFlex>
                <Icon icon="contact-info" height={20} style={{ marginRight: 5 }} />
                <LabelText style={{ marginRight: 0 }}> Contact Information </LabelText>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Main Contact </DetailLabelText>
                <div>{(data.formattedUser.mainContact && data.formattedUser.mainContact.name) ? data.formattedUser.mainContact.name : 'N/A'}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Phone Number </DetailLabelText>
                <div>{(data.formattedUser.mainContact && data.formattedUser.mainContact.phoneNumber) ? data.formattedUser.mainContact.phoneNumber : 'N/A'}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Email </DetailLabelText>
                <div>{(data.formattedUser.mainContact && data.formattedUser.mainContact.email) ? data.formattedUser.mainContact.email : 'N/A'}</div>
              </StyledFlex>
            </SectionWrapper>
            <SectionWrapper style={{ paddingTop: 10, borderBottomStyle: 'dashed' }}>
              <StyledFlex>
                <Icon icon="other-info" height={20} style={{ marginRight: 5 }} />
                <LabelText style={{ marginRight: 0 }}> Other Information </LabelText>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Address </DetailLabelText>
                <div>{data.formattedUser.address || 'N/A'}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Education </DetailLabelText>
                <div>{data.formattedUser.education || 'N/A'}</div>
              </StyledFlex>
            </SectionWrapper>
          </DetailWrapper>
        </LeftWrapper>
        <RightWrapper>
          <Tabs activeKey={get(queryParams, ['where', 'tab'])} onChange={(tabValue) => onTabChange(tabValue)}>
            <TabPane tab="Files" key="Files">
              <ModelContainer divHeight={divHeight}>
                <ModelPage
                  showHeader={false}
                  apiRoute="user/files"
                  itemsPerPage={25}
                  query={{ where: { id: match.params.id, tab: 'Files' } }}
                  initialQuery={{
                    currentPage: 1,
                    where: {
                      tab: 'Files',
                    },
                  }}
                  actions={[{
                    action: () => setUploadFile(true),
                    label: 'Add',
                  }]}
                  allowSearch={false}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (divHeight !== height) setDivHeight(height);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Files" />}>
                        <AntDTable
                          cellRenderers={fileColumns}
                          pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          scroll={{ x: 'max-content', y: '40vh' }}
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
            <TabPane tab="Salary and Bonus" key="Salary and Bonus">
              <ModelContainer divHeight={salaryDivHeight}>
                <ModelPage
                  showHeader={false}
                  apiRoute="user/salaryBonus"
                  itemsPerPage={25}
                  query={{ where: { staffId: match.params.id, tab: 'Salary and Bonus' } }}
                  initialQuery={{
                    currentPage: 1,
                    where: {
                      tab: 'Salary and Bonus',
                    },
                  }}
                  actions={[{
                    action: () => setUpdateSalaryBonus(true),
                    label: 'Update',
                  }]}
                  allowSearch={false}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (salaryDivHeight !== height) setSalaryDivHeight(height);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />}>
                        <AntDTable
                          cellRenderers={salaryBonusColumns}
                          pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          scroll={{ x: 'max-content', y: '40vh' }}
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
          </Tabs>
        </RightWrapper>
      </BottomWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state.authentication),
  }
}

export default connect(mapStateToProps)(ShowView);
