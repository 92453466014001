import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import get from 'lodash/get';
import Modal from '../containers/Modal';

const SpecFormPreview = ({ user }) => {
  return (
    <Modal name="spec-form-preview-modal">
      <div>sdf</div>
    </Modal>
  );
}
const mapStateToProps = (state, { name, isOpen }) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps)(SpecFormPreview);
