/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Tabs, ConfigProvider, Empty, Tag, Table } from 'antd';
import { size, palette, font } from 'styled-theme';
import Popup from 'reactjs-popup';
import { ifProp } from 'styled-tools';
import moment from 'moment';
import get from 'lodash/get';
import { Field, Form } from 'react-final-form';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Button from '../../../components/atoms/Button';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import PopUpLesson from '../../../components/organisms/PopupLesson';
import useCalendarForm from '../../../hooks/useCalendarForm';
import PopUpRequest from '../../../components/organisms/PopUpRequest';
import PopUpAttendance from '../../../components/organisms/PopUpAttendance';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import useQueryParams from '../../../hooks/useQueryParams';
import FieldComponent from '../../../components/molecules/FieldComponent';
import { validations } from '../../../utils/form';
import inputOptions from '../../../utils/form/inputOptions';
import PopUpDeleteLesson from '../../../components/organisms/PopUpDeleteLesson';

const Wrapper = styled.div`
  height: 100%;
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100% - 66px);
  padding-bottom: 10px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
    min-height: calc(100% - 56px);
    padding-bottom: 0px;
  }
`;

const LeftWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 30%;
  margin-right: 15px;
  overflow: auto;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 12px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
    max-height: calc(50vh - 50px);
  }
`;

const RightWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  width: 70%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 0px;
    width: 100%;
    flex: 1;
  }
`;

const DetailWrapper = styled(Flex)`
  flex-direction: column;
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding: 10px 0px;
`;

const LabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const DetailLabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  min-width: 100px;
  margin-right: 20px;
  font-family: ${font('tertiary')};
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledLink = styled(Link)`
  color: #3E90F7;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    color: #3E90F7;
  }
`;

const StudentLink = styled(Link)`
  color: ${ifProp('red', '#FF0000', '#3E90F7')};

  &:hover,
  &:focus,
  &:active {
    color: ${ifProp('red', '#FF0000', '#3E90F7')};
  }
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 30vw;
  min-width: 30vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpLargeWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 45vw;
  min-width: 45vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledText = styled.div`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  padding: 20px 0px 30px 0px;
  text-align: center;
`;

const StyledButton2 = styled(Button)`
  display: flex;
  flex-direction: row;
  border-radius: 0px;
  margin-right: 10px;
  margin-bottom: 0px;
  width: 100%;
`;

const StyledTag = styled(Tag)`
  min-width: 70px;
  text-align: center;
  font-size: 14px;
  width: 100%;
`;

const ModelContainer = styled.div`
  > div {
    padding: 24px 0px;

    @media (min-width: calc(${size('mobileBreakpoint')} + 1px)){
      padding: 24px 0px 8px 0px;

      .ant-table-pagination-right {
        flex-wrap: wrap;
      }

      .ant-pagination-prev {
        margin-bottom: 16px;
      }
    }

    > div {
      > div: last-child {
        flex-wrap: ${(props) => (props.isWrapButtons && 'wrap')};
        height: ${(props) => (props.isWrapButtons && 'auto')};
        margin-bottom: ${(props) => (props.isWrapButtons && '0px')};
        > button {
          height: ${(props) => (props.isWrapButtons && '40px')};
          margin-bottom: ${(props) => (props.isWrapButtons && '16px')};
          @media (max-width: ${size('mobileBreakpoint')}) {
            margin-bottom: ${(props) => (props.isWrapButtons && '12px')};
          }
        }
      }
    }
  }

  .ant-table-body {
    max-height: 100% !important;
    @media (max-width: ${size('mobileBreakpoint')}) {
      max-height: ${(props) => (props.divHeight ? `calc(100vh - ${props.divHeight}px - 260px)` : 'calc(100vh - 56px - 260px)')} !important;
    }
  }
`;

const AttendanceContainer = styled.div`
  > div {
    padding: 24px 0px;

    @media (min-width: calc(${size('mobileBreakpoint')} + 1px)){
      padding: 24px 0px 8px 0px;

      .ant-table-pagination-right {
        flex-wrap: wrap;
      }

      .ant-pagination-prev {
        margin-bottom: 16px;
      }
    }
  }

  .ant-table-body {
    max-height: 100% !important;
    @media (max-width: ${size('mobileBreakpoint')}) {
      max-height: ${(props) => (props.divHeight ? `calc(100vh - ${props.divHeight}px - 260px)` : 'calc(100vh - 56px - 260px)')} !important;
    }
  }
`;

const TeachingNotes = styled.div`
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledSpan = styled.span`
  display: inline-block;
`;

const OverrideButton = styled(Button)`
  min-width: 100px;
  margin: 0px;
  margin-top: 10px;
  border-radius: 0px;
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 12px;
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const RenewFieldText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 120px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const AddClassFieldText = styled.div`
  font-weight: bold;
  width: 80px;
  min-width: 80px;
  display: flex;
  text-align: right;
  height: 32px;
  font-family: ${font('tertiary')};
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 6px;
  padding-right: 15px;

  @media (max-width: ${size('mobileBreakpoint')}){
    height: auto;
    text-align: left;
    padding: 0px;
    width: auto;
    justify-content: normal;
    margin-top: 0px;
    padding-right: 0px;
  }
`;

const RenewSaveButton = styled(Button)`
  min-width: 160px;
  width: 100%;
  margin: ${size('margin.default')} auto ${size('margin.default')};
  border-radius: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
`;

const PackageLink = styled(Link)`
  text-decoration: underline;
  color: #3E90F7;
  margin-left: 5px;
`;

const Cell = styled.div`
  width: max-content;
`;

const ErrorAlertContainer = styled.div`
  > div {
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: 75vw;
      width: fit-content;
    }
  }
`;

const PopUpErrorAlertContainer = styled.div`
  > div {
    max-width: 28vw;
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: none;
    }
  }
`;

const RenewErrorAlertContainer = styled.div`
  > div {
    max-width: 43vw;
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: none;
    }
  }
`;

const CheckBoxContainer = styled.div`
  margin-bottom: 5px;
  > div {
    > div: first-child {
      display: none;
    }
    > div: nth-child(2) {
      margin: 0px;
      > label {
        left: 3px;
        > input {
          transform: scale(1.4);
          margin-right: 5px;
        }
        > div {
          color: #303030;
          top: 1px;
        }
      }
    }
    > div: last-child {
      margin-top: 5px;
    }
  }
`;

const Note = styled.div`
  max-width: 250px;
`;

const SubmitButton = styled(Button)`
  display: flex;
  flex-direction: row;
  border-radius: 0px;
  margin-bottom: 0px;
  width: 100%;
`;

const ShowView = (props) => {
  const { user, match, location } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [cancelAll, setCancelAll] = useState(null);
  const [renew, setRenew] = useState(null);
  const [revertErrorMessage, setRevertErrorMessage] = useState(null);
  const [revertAllErrorMessage, setRevertAllErrorMessage] = useState(null);
  const [revertId, setRevertId] = useState(null);
  const [errorFormValue, setErrorFormValue] = useState(null);
  const [renewErrorMessage, setRenewErrorMessage] = useState(null);
  const [attendanceDivHeight, setAttendanceDivHeight] = useState(0);
  const [divHeight, setDivHeight] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDoubleSubmit, setIsDoubleSubmit] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [addClass, setAddClass] = useState(false);
  const [deleteAll, setDeleteAll] = useState(null);
  const [revertAll, setRevertAll] = useState(null);
  const [popupErrorMessage, setPopupErrorMessage] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [confirmAll, setConfirmAll] = useState(null);
  const [confirmPopupErrorMessage, setConfirmPopupErrorMessage] = useState(null);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [addClassErrorMessage, setAddClassErrorMessage] = useState(null);
  const [renewErrorFormValue, setRenewErrorFormValue] = useState(null);
  const [addClassErrorFormValue, setAddClassErrorFormValue] = useState(null);
  const [isRenewDoubleSubmit, setIsRenewDoubleSubmit] = useState(false);
  const [isAddClassDoubleSubmit, setIsAddClassDoubleSubmit] = useState(false);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  const { data, loading } = useGeneralData({
    endpoint: 'lesson/lessonData',
    query: { recurrenceId: match.params.id },
  });

  const { onSubmit } = useCalendarForm({
    endpoint: 'event/revert',
    setErrorMessage: setRevertErrorMessage,
    user,
    type: 'lessonRevert',
    setErrorFormValue,
  });

  const { onSubmit: onRevertAll } = useCalendarForm({
    endpoint: 'event/revertAll',
    setErrorMessage: setRevertAllErrorMessage,
    user,
    type: 'lessonRevertAll',
    setErrorFormValue,
  });


  const { onSubmit: onCancelAll } = useCalendarForm({
    endpoint: 'event/cancelSelected',
    setErrorMessage: setPopupErrorMessage,
    user,
    type: 'cancelAll',
  });

  const { onSubmit: onConfirm } = useCalendarForm({
    endpoint: 'lesson/confirm',
    setErrorMessage,
    user,
    type: 'attendance',
  });

  const { onSubmit: onRenew } = useCalendarForm({
    endpoint: 'lesson/renew',
    setErrorMessage: setRenewErrorMessage,
    user,
    type: 'renew',
    setErrorFormValue: setRenewErrorFormValue,
  });

  const { onSubmit: onAddClass } = useCalendarForm({
    endpoint: 'lesson/addClass',
    setErrorMessage: setAddClassErrorMessage,
    user,
    type: 'renew',
    setErrorFormValue: setAddClassErrorFormValue,
  });

  const { onSubmit: onDeleteAll } = useCalendarForm({
    endpoint: 'event/deleteSelected',
    setErrorMessage: setPopupErrorMessage,
    user,
    type: 'deleteAll',
  });

  const { onSubmit: onConfirmAll } = useCalendarForm({
    endpoint: 'lesson/confirmAll',
    setErrorMessage: setConfirmPopupErrorMessage,
    user,
    type: 'cancelAll',
  });

  const { queryParams, setQueryParams } = useQueryParams()

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
        setIsConfirming(false);
      }, 3000);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (popupErrorMessage !== null) {
      setTimeout(() => {
        setPopupErrorMessage(null);
      }, 3000);
    }
  }, [popupErrorMessage]);

  useEffect(() => {
    if (renewErrorMessage !== null && !renewErrorFormValue) {
      setTimeout(() => {
        setRenewErrorMessage(null);
      }, 3000);
    }
  }, [renewErrorMessage, renewErrorFormValue]);

  useEffect(() => {
    if (addClassErrorMessage !== null && !addClassErrorFormValue) {
      setTimeout(() => {
        setAddClassErrorMessage(null);
      }, 3000);
    }
  }, [addClassErrorMessage, addClassErrorFormValue]);

  useEffect(() => {
    if (revertErrorMessage !== null && !errorFormValue) {
      setTimeout(() => {
        setRevertErrorMessage(null);
        setIsSubmitting(false);
      }, 3000);
    }
  }, [errorFormValue, revertErrorMessage]);

  useEffect(() => {
    if (popupErrorMessage !== null) {
      setTimeout(() => {
        setConfirmPopupErrorMessage(null);
      }, 3000);
    }
  }, [popupErrorMessage]);

  const history = useHistory();

  if (loading) return <Loading />

  if (!data) return <Redirect to="/lesson" />;
  if (!data.formattedLesson) return <Redirect to="/lesson" />;

  const { TabPane } = Tabs;

  const classesRadioGroupKey = 'startTime';
  const attendanceRadioGroupKey = 'type';

  const cellRenderers = [
    {
      title: 'Class',
      dataIndex: 'title',
      key: 'title',
      width: 90,
      render: (title) => <Cell>{title}</Cell>,
    },
    {
      title: 'Date',
      dataIndex: 'formattedDate',
      key: 'formattedDate',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Time',
      dataIndex: 'formattedTime',
      key: 'formattedTime',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Student',
      dataIndex: 'studentNamesWithStatus',
      key: 'studentNamesWithStatus',
      width: 100,
      render: (studentNamesWithStatus) => (
        <Cell style={{ maxWidth: 300 }}>
          {
            user.role === 'parent'
              ? studentNamesWithStatus.filter((c) => Number(c.userId) === Number(user.id)).map((s, index) => <span>{(index ? ', ' : '')}<StyledSpan style={{ textDecoration: s.showCancelled && 'line-through' }}>{s.parentId === Number(user.id) ? <StudentLink to={`/student/show/${s.id}`} red={s.hasNoCredit && s.parentId === Number(user.id)}>{s.nameWithStatus}</StudentLink> : s.nameWithStatus}</StyledSpan></span>)
              : studentNamesWithStatus.map((s, index) => (
                <span>{(index ? ', ' : '')}
                  <StyledSpan style={{ textDecoration: s.showCancelled && 'line-through' }}>
                    <StudentLink to={`/student/show/${s.id}`} red={(user.role === 'admin' || user.role === 'superAdmin') && s.hasNoCredit}>
                      {s.nameWithStatus}
                    </StudentLink>
                    { (user.role === 'admin' || user.role === 'superAdmin') && (
                      <span>
                        {' ('}
                        <StudentLink to={`/package/show/${get(s, 'formattedPackage.id', null)}`} red={(user.role === 'admin' || user.role === 'superAdmin') && s.hasNoCredit}>
                          {get(s, 'formattedPackage.prefix', null)}{get(s, 'formattedPackage.id', null)}
                        </StudentLink>
                        {')'}
                      </span>
                    )}
                  </StyledSpan>
                </span>
              ))
          }
        </Cell>
      ),
    },
    {
      title: 'Instructor',
      dataIndex: 'instructor',
      key: 'instructor',
      width: 120,
      render: (instructor) => <Cell>{(user.role === 'superAdmin' ? <Link to={`/staff/show/${instructor.id}`}>{instructor.name}</Link> : instructor.name)}</Cell>,
    },
    {
      title: 'Room',
      dataIndex: 'roomName',
      key: 'roomName',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    ...(user.role !== 'parent' ? (
      [{
        title: 'Note (internal)',
        dataIndex: 'note',
        key: 'note',
        render: (note) => <Cell><Note> { note || 'N/A' } </Note></Cell>,
        width: 75,
      }]
    ) : []),
    {
      title: 'Note',
      dataIndex: 'externalNote',
      key: 'externalNote',
      render: (note) => <Cell><Note> { note || 'N/A' } </Note></Cell>,
      width: 75,
    },
    {
      title: 'Class Status',
      dataIndex: 'isCancelled',
      key: 'isCancelled',
      width: 130,
      render: (cancelled, record) => {
        let text = cancelled ? 'Cancelled' : 'Confirmed';
        text = record.deletedAt ? "Deleted" : text;
        return <Cell><StyledTag color={cancelled ? 'red' : 'green'} key={cancelled}>{text}</StyledTag></Cell>
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (text, record) => {
        return (
          <Cell>
            <div style={{ width: user.role === 'parent' ? 120 : 140, maxWidth: user.role === 'parent' ? 120 : 140 }}>
              {(user.role === 'admin' || user.role === 'superAdmin') && (
                <div>
                  { !record.isCancelled ? (
                    <Flex>
                      <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}> <StyledLink to={`/lesson/${match.params.id}/editSingle/${record.id}`} disabled={record.hasAttendanceConfirmed} style={{ color: record.hasAttendanceConfirmed && '#C1C1C1' }}> Edit </StyledLink></div>
                      <div style={{ paddingLeft: 5, borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                        <PopUpLesson
                          user={user}
                          child={data.children}
                          instructor={data.instructors}
                          selectedEvent={record}
                          id={record.eventId}
                          studentId={record.studentIds}
                          eventInstructor={[record.instructorId]}
                          allLessons={data.allLessons}
                          type="delete"
                          page="lesson"
                        />
                      </div>
                      <div style={{ paddingLeft: 5 }}>
                        <PopUpDeleteLesson user={user} selectedEvent={record} id={record.eventId} type="delete" page="lesson" />
                      </div>
                    </Flex>
                  ) : (
                    !record.deletedAt ?
                    <Flex>
                      <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                        <StyledButton disabled={isSubmitting && !revertErrorMessage} onClick={() => { setIsSubmitting(true); onSubmit(record.eventId); setRevertId(record.eventId) }}> Revert </StyledButton>
                      </div>
                      <div style={{ paddingLeft: 5 }}>
                        <PopUpDeleteLesson user={user} selectedEvent={record} id={record.eventId} type="delete" page="lesson" />
                      </div>
                    </Flex>
                    : <Flex></Flex>
                  )}
                </div>
              )}
              {user.role === 'parent' && (
                <div>
                  { record.formattedType === 'Private' ? (
                    <Flex>
                      <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                        <PopUpRequest
                          user={user}
                          id={record.id}
                          child={[{ firstName: get(record, 'child.firstName', null), lastName: get(record, 'child.lastName', null), id: get(record, 'child.id', null) }]}
                          instructor={[{ firstName: get(record, 'thisInstructor.firstName', null), lastName: get(record, 'thisInstructor.lastName', null), id: get(record, 'thisInstructor.id', null) }]}
                          selectedEvent={record}
                          eventInstructor={[record.instructorId]}
                          requests={data.requests}
                          allLessons={[{ name: record.title }]}
                          isCancelled={record.isCancelled}
                          type="reschedule"
                          page="lesson"
                        />
                      </div>
                      <div style={{ paddingLeft: 5 }}>
                        <PopUpRequest
                          user={user}
                          id={record.id}
                          child={[{ firstName: get(record, 'child.firstName', null), lastName: get(record, 'child.lastName', null), id: get(record, 'child.id', null) }]}
                          instructor={[{ firstName: get(record, 'thisInstructor.firstName', null), lastName: get(record, 'thisInstructor.lastName', null), id: get(record, 'thisInstructor.id', null) }]}
                          selectedEvent={record}
                          eventInstructor={[record.instructorId]}
                          requests={data.requests}
                          allLessons={[{ name: record.title }]}
                          isCancelled={record.isCancelled}
                          type="cancel"
                          page="lesson"
                        />
                      </div>
                    </Flex>
                  ) : 'N/A' }
                </div>
              )}
            </div>
          </Cell>
        )
      },
    },
  ];

  let attendanceCells = [
    {
      title: 'Class',
      dataIndex: 'title',
      key: 'title',
      width: 90,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Date',
      dataIndex: 'formattedDate',
      key: 'formattedDate',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Time',
      dataIndex: 'formattedTime',
      key: 'formattedTime',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
      width: 100,
      render: (student) => <Cell>{(user.role === 'parent' && (Number(student.parentId) !== Number(user.id))) ? student.name : <StudentLink to={`/student/show/${student.id}`} red={['parent', 'admin', 'superAdmin'].includes(user.role) && !student.hasCredits}>{student.name}</StudentLink>}</Cell>,
    },
    {
      title: 'Package Code',
      dataIndex: 'packageInfo',
      key: 'packageInfo',
      width: 140,
      render: (packageInfo) => <Cell>{((packageInfo && packageInfo.id) ? <Link to={`/package/show/${packageInfo.id}`}>{`${packageInfo.prefix}${packageInfo.id}`}</Link> : 'N/A')}</Cell>,
    },
    {
      title: 'Instructor',
      dataIndex: 'instructor',
      key: 'instructor',
      width: 120,
      render: (instructor) => <Cell>{(user.role === 'superAdmin' ? <Link to={`/staff/show/${instructor.id}`}>{instructor.name}</Link> : instructor.name)}</Cell>,
    },
    {
      title: 'Room',
      dataIndex: 'roomName',
      key: 'roomName',
      width: 75,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Teaching Notes',
      dataIndex: 'teachingNotes',
      key: 'teachingNotes',
      width: 140,
      render: (notes) => <Cell><TeachingNotes> { notes || 'N/A' } </TeachingNotes></Cell>,
    },
    {
      title: 'Status',
      dataIndex: 'cancelledById',
      key: 'cancelledById',
      width: 90,
      render: (id) => <Cell><StyledTag color={id ? 'red' : 'green'} key={id}>{id ? 'Cancelled' : 'Confirmed'}</StyledTag></Cell>,
    },
    // {
    //   title: 'Final Price',
    //   dataIndex: 'creditsDeduct',
    //   key: 'creditsDeduct',
    //   width: 130,
    //   render: (credits) => <Cell>{credits}</Cell>,
    // },
    ...((user.role !== 'parent' && user.id !== 409) ? [{
      title: 'Class Price',
      dataIndex: 'classPrice',
      key: 'classPrice',
      width: 130,
      render: (credits) => <Cell>{`$${(+Number(credits).toFixed(2)).toLocaleString()}`}</Cell>,
    }] : []),
    {
      title: 'Signature',
      dataIndex: 'hasSignature',
      key: 'hasSignature',
      width: 120,
      render: (credits) => <Cell>{credits}</Cell>,
    },
    {
      title: 'Confirmed By',
      dataIndex: 'confirmedByName',
      key: 'confirmedByName',
      width: 140,
      render: (confirmedBy) => <Cell>{(confirmedBy.id ? (user.role === 'superAdmin' ? <Link to={`/staff/show/${confirmedBy.id}`}>{confirmedBy.name}</Link> : confirmedBy.name) : 'N/A')}</Cell>,
    },
    {
      title: 'Cancelled By',
      dataIndex: 'cancelledByName',
      key: 'cancelledByName',
      width: 140,
      render: (cancelledBy) => <Cell>{(cancelledBy.id ? (cancelledBy.role === 'parent' ? <Link to={`/user/show/${cancelledBy.id}`}>{cancelledBy.name}</Link> : (user.role === 'superAdmin' ? <Link to={`/staff/show/${cancelledBy.id}`}>{cancelledBy.name}</Link> : cancelledBy.name)) : 'N/A')}</Cell>,
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (text, record) => (
        <Cell>
          {(user.role === 'admin' || user.role === 'superAdmin') && (
            <Flex>
              {(!record.cancelledById && !record.isUpcoming) ? (
                <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                  <StyledButton disabled={record.isConfirmed || (isConfirming && !errorMessage)} style={{ color: record.isConfirmed && '#C1C1C1' }} onClick={() => { setIsConfirming(true); onConfirm(record); }}> Confirm </StyledButton>
                </div>
              ) : null}
              <div style={{ paddingLeft: (!record.cancelledById && !record.isUpcoming) && 5 }}>
                <PopUpAttendance
                  user={user}
                  child={data.children}
                  instructor={data.instructors}
                  selectedEvent={record}
                  id={record.id}
                  allLessons={data.allLessons}
                  type={record.cancelledById ? 'Cancelled' : 'Completed'}
                  action={record.isConfirmed ? 'View' : record.isUpcoming ? 'Upcoming' : 'Edit'}
                />
              </div>
            </Flex>
          )}
          {(user.role === 'instructor' || user.role === 'parent') && (
            <PopUpAttendance
              user={user}
              child={data.children}
              instructor={data.instructors}
              selectedEvent={record}
              id={record.id}
              allLessons={data.allLessons}
              type={(record.cancelledById || record.isUpcoming) ? 'Cancelled' : 'Completed'}
              action={(user.role === 'parent' || (user.role === 'instructor' && user.id !== record.instructorId)) ? 'View' : null}
            />
          )}
        </Cell>
      ),
    },
  ];

  const onTabChange = (tabValue) => {
    const radioGroupKeys = tabValue === 'Classes' ? { [classesRadioGroupKey]: 'Upcoming', [attendanceRadioGroupKey]: null } : { [classesRadioGroupKey]: null, [attendanceRadioGroupKey]: 'Upcoming' }
    setQueryParams((old) => ({
      currentPage: 1,
      where: {
        tab: tabValue,
        ...radioGroupKeys,
      },
    }), { replace: true });
    setSelectedRowKeys([]);
    setSelectedClasses([]);
  }

  if (user.role === 'instructor') attendanceCells = attendanceCells.filter((c) => c.title !== 'Package Code' && c.title !== 'Final Price' && c.title !== 'Class Price' && c.title !== 'Signature' && c.title !== 'Cancelled By' && c.title !== 'Confirmed By');
  else if (user.role === 'parent') attendanceCells = attendanceCells.filter((c) => c.title !== 'Package Code' && c.title !== 'Final Price' && c.title !== 'Class Price' && c.title !== 'Teaching Notes' && c.title !== 'Cancelled By' && c.title !== 'Confirmed By' && c.key !== 'note');
  else if (user.role === 'admin' || user.role === 'superAdmin') attendanceCells = attendanceCells.filter((c) => c.title !== 'Teaching Notes');

  const filterConfig = [
    {
      type: 'radioGroup',
      name: 'lessonType',
      label: 'Type',
      options: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'Private',
          value: 'Private Lesson',
        },
        {
          label: 'Semiprivate',
          value: 'Semiprivate Lesson',
        },
        {
          label: 'Group',
          value: 'Group Lesson',
        },
      ],
    },
    {
      type: 'radioGroup',
      name: 'classStatus',
      label: 'Class Status',
      options: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'Confirmed',
          value: 'Confirmed',
        },
        {
          label: 'Cancelled',
          value: 'Cancelled',
        },
      ],
    },
    {
      type: 'radioGroup',
      name: 'packageType',
      label: 'Package Type',
      options: [{ label: 'All', value: 'All' }, ...inputOptions.packageTypeOptions],
    },
    {
      type: 'radioGroup',
      name: 'freeTrial',
      label: 'Free Trial Class',
      options: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'Free Trial',
          value: 'Free Trial',
        },
      ],
    },
    {
      type: 'dateRange',
      name: 'date',
      label: 'Date',
      showDate: true,
      showTime: false,
    },
    {
      type: 'spec',
      name: 'childId',
      label: 'Student',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: {
        endpoint: 'student',
        query: {
          where: user.role === 'parent' ? { parentId: user.id } : user.role === 'instructor' ? { 'lessons.instructorId': user.id } : {},
          order: {
            sortKey: [
              'child.firstName',
              'child.lastName',
            ],
            sortOrder: 'asc',
          },
        },
      },
      associationType: 'own',
    },
    {
      type: 'spec',
      name: 'instructorId',
      label: 'Instructor',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: {
        endpoint: 'user',
        query: {
          where: {
            role: 'instructor',
            status: 'Current',
          },
          order: {
            sortKey: [
              'firstName',
              'lastName',
            ],
            sortOrder: 'asc',
          },
        },
      },
      associationType: 'own',
    },
    {
      type: 'spec',
      name: 'roomId',
      label: 'Room',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => v.name,
      reference: {
        endpoint: 'room',
      },
      associationType: 'own',
    },
  ];

  const attendanceFilterConfig = [
    {
      type: 'radioGroup',
      name: 'status',
      label: 'Status',
      options: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'Confirmed',
          value: 'Confirmed',
        },
        {
          label: 'Cancelled',
          value: 'Cancelled',
        },
      ],
    },
    {
      type: 'radioGroup',
      name: 'signature',
      label: 'Signature',
      options: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'Yes',
          value: 'Yes',
        },
        {
          label: 'No',
          value: 'No',
        },
      ],
    },
    {
      type: 'dateRange',
      name: 'date',
      label: 'Date',
      showDate: true,
      showTime: false,
    },
    {
      type: 'spec',
      name: 'childId',
      label: 'Student',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: {
        endpoint: 'student',
        query: {
          where: user.role === 'parent' ? { parentId: user.id } : user.role === 'instructor' ? { 'lessons.instructorId': user.id } : {},
          order: {
            sortKey: [
              'child.firstName',
              'child.lastName',
            ],
            sortOrder: 'asc',
          },
        },
      },
      associationType: 'own',
    },
    {
      type: 'spec',
      name: 'instructorId',
      label: 'Instructor',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: {
        endpoint: 'user',
        query: {
          where: {
            role: 'instructor',
            status: 'Current',
          },
          order: {
            sortKey: [
              'firstName',
              'lastName',
            ],
            sortOrder: 'asc',
          },
        },
      },
      associationType: 'own',
    },
    {
      type: 'spec',
      name: 'roomId',
      label: 'Room',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => v.name,
      reference: {
        endpoint: 'room',
      },
      associationType: 'own',
    },
  ];

  const modelName = 'lesson';

  const actionButtons = ((user.role === 'admin' || user.role === 'superAdmin') && (get(queryParams, 'where.startTime', {}) || {}) === 'Upcoming') ?
    [
      {
        action: () => setAddClass(true),
        label: 'Add Class',
      },
      {
        action: () => setRenew(true),
        label: 'Renew',
      },
    ] : [];
  const checkBoxSelectButtons = (user.role === 'admin' || user.role === 'superAdmin') ? [
    {
      action: () => setCancelAll(true),
      label: 'Cancel All',
      disabled: !(selectedClasses && selectedClasses.length > 0),
    },
    {
      action: () => setDeleteAll(true),
      label: 'Release All',
      disabled: !(selectedClasses && selectedClasses.length > 0),
    },
    {
      action: () => onRevertAll({ ids: selectedClasses }),
      label: 'Revert All',
      disabled: !(selectedClasses && selectedClasses.length > 0),
    },
  ] : []
  const onRow = (record, index) => {
    let style = {}
    if (record.hasDifferences) {
      style["background"] = "rgba(255, 255, 0)";
    }
    if (record.isCancelled && record.deletedAt === null) {
      style["background"] = "rgb(255, 159, 0)";
    }
    if (record.deletedAt !== null) {
      style["background"] = "rgba(193, 193, 193)";
    }
    return {
      style
    }
  }

  return (
    <Wrapper>
      {cancelAll ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpWrapper>
              <PopUpErrorAlertContainer>
                <ErrorAlert
                  errorMessage={popupErrorMessage}
                  show={popupErrorMessage !== null}
                />
              </PopUpErrorAlertContainer>
              <PopUpButton className="close" onClick={() => { close(); setCancelAll(false); }}>
                &times;
              </PopUpButton>
              <PopUpTextWrapper>
                Cancel All
              </PopUpTextWrapper>
              <StyledText>
                {`Are you sure you want to cancel the ${selectedClasses.length} selected ${selectedClasses.length > 1 ? 'classes' : 'class'}?`}
              </StyledText>
              <Form
                name="CancelledClassesFrom"
                onSubmit={onCancelAll}
                initialValues={null}
                initialValuesEqual={() => true}
                render={({ handleSubmit, values, submitting }) => {
                  values.ids = selectedClasses;
                  return (
                    <StyledForm onSubmit={handleSubmit}>
                      <SubmitButton type="submit" disabled={submitting}> Confirm </SubmitButton>
                    </StyledForm>
                  )
                }}
              />
            </PopUpWrapper>
          )}
        </StyledPopup>
      ) : null}
      {deleteAll ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpWrapper>
              <PopUpErrorAlertContainer>
                <ErrorAlert
                  errorMessage={popupErrorMessage}
                  show={popupErrorMessage !== null}
                />
              </PopUpErrorAlertContainer>
              <PopUpButton className="close" onClick={() => { close(); setDeleteAll(false); }}>
                &times;
              </PopUpButton>
              <PopUpTextWrapper>
                Delete All
              </PopUpTextWrapper>
              <StyledText>
                {`Are you sure you want to delete the ${selectedClasses.length} selected ${selectedClasses.length > 1 ? 'classes' : 'class'}?`}
              </StyledText>
              <Form
                name="DeleteClassesFrom"
                onSubmit={onDeleteAll}
                initialValues={null}
                initialValuesEqual={() => true}
                render={({ handleSubmit, values, submitting }) => {
                  values.ids = selectedClasses;
                  values.force = true;
                  return (
                    <StyledForm onSubmit={handleSubmit}>
                      <SubmitButton type="submit" disabled={submitting}> Confirm </SubmitButton>
                    </StyledForm>
                  )
                }}
              />
            </PopUpWrapper>
          )}
        </StyledPopup>
      ) : null}
      {renew ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpLargeWrapper>
              <PopUpButton className="close" onClick={() => { close(); setRenew(false); setRenewErrorMessage(null); }}>
                &times;
              </PopUpButton>
              <RenewErrorAlertContainer>
                <ErrorAlert
                  errorMessage={
                    (renewErrorMessage && renewErrorFormValue) ? (
                      <div>
                        {renewErrorMessage.split(',').slice(0, 3).map((e) => <div style={{ marginBottom: 3 }}> {e} </div>)}
                        {renewErrorMessage.split(',').length > 3 ? <div style={{ marginBottom: 2 }}> and {renewErrorMessage.split(',').length - 3} more. </div> : null}
                        <div>
                          <div style={{ marginTop: 8 }}> Would you still like to proceed? </div>
                          <Flex style={{ justifyContent: 'center' }}>
                            <OverrideButton disabled={isRenewDoubleSubmit} onClick={() => { setIsRenewDoubleSubmit(true); renewErrorFormValue.override = true; onRenew(renewErrorFormValue); }} style={{ marginRight: 15 }}> Yes </OverrideButton>
                            <OverrideButton onClick={() => { setRenewErrorMessage(null); }}> No </OverrideButton>
                          </Flex>
                        </div>
                      </div>
                    )
                      : renewErrorMessage
                  }
                  show={renewErrorMessage !== null}
                />
              </RenewErrorAlertContainer>
              <PopUpTextWrapper>
                Renew
              </PopUpTextWrapper>
              <Form
                name="RenewClassesForm"
                onSubmit={onRenew}
                initialValues={null}
                initialValuesEqual={() => true}
                render={({ handleSubmit, values, submitting }) => {
                  values.recurrenceId = match.params.id;
                  return (
                    <StyledForm onSubmit={handleSubmit}>
                      <FieldFlex>
                        <RenewFieldText>Start Date: </RenewFieldText>
                        <StyledField
                          name="startDate"
                          type="date"
                          component={FieldComponent}
                          validate={validations.required}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <RenewFieldText>Repeat: </RenewFieldText>
                        <StyledField
                          name="repeat"
                          type="select"
                          component={FieldComponent}
                          options={inputOptions.recurrenceOptions.filter((i) => i.value !== 'Daily' && i.value !== 'Does not repeat')}
                          validate={validations.required}
                          menuPortalTarget={document.getElementById('popup-root')}
                          menuPlacement="auto"
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <RenewFieldText>Until: </RenewFieldText>
                        <StyledField
                          name="date"
                          type="date"
                          component={FieldComponent}
                          validate={validations.required}
                        />
                      </FieldFlex>

                      <RenewSaveButton type="submit" disabled={submitting}> Save </RenewSaveButton>
                    </StyledForm>
                  )
                }}
              />
            </PopUpLargeWrapper>
          )}
        </StyledPopup>
      ) : null}
      {addClass ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpLargeWrapper>
              <PopUpButton className="close" onClick={() => { close(); setAddClass(false); setAddClassErrorMessage(null); }}>
                &times;
              </PopUpButton>
              <RenewErrorAlertContainer>
                <ErrorAlert
                  errorMessage={
                    (addClassErrorMessage && addClassErrorFormValue) ? (
                      <div>
                        {addClassErrorMessage.split(',').slice(0, 3).map((e) => <div style={{ marginBottom: 3 }}> {e} </div>)}
                        {addClassErrorMessage.split(',').length > 3 ? <div style={{ marginBottom: 2 }}> and {addClassErrorMessage.split(',').length - 3} more. </div> : null}
                        <div>
                          <div style={{ marginTop: 8 }}> Would you still like to proceed? </div>
                          <Flex style={{ justifyContent: 'center' }}>
                            <OverrideButton disabled={isAddClassDoubleSubmit} onClick={() => { setIsAddClassDoubleSubmit(true); addClassErrorFormValue.override = true; onAddClass(addClassErrorFormValue); }} style={{ marginRight: 15 }}> Yes </OverrideButton>
                            <OverrideButton onClick={() => { setAddClassErrorMessage(null); }}> No </OverrideButton>
                          </Flex>
                        </div>
                      </div>
                    )
                      : addClassErrorMessage
                  }
                  show={addClassErrorMessage !== null}
                />
              </RenewErrorAlertContainer>
              <PopUpTextWrapper>
                Add Class
              </PopUpTextWrapper>
              <Form
                name="AddClassForm"
                onSubmit={onAddClass}
                initialValues={{
                  startTime: get(data, 'formattedLesson.startTime', null) ? moment(get(data, 'formattedLesson.startTime', null)).format('HH:mm') : null,
                  endTime: get(data, 'formattedLesson.endTime', null) ? moment(get(data, 'formattedLesson.endTime', null)).format('HH:mm') : null,
                }}
                initialValuesEqual={() => true}
                render={({ handleSubmit, values, submitting }) => {
                  values.recurrenceId = match.params.id;
                  return (
                    <StyledForm onSubmit={handleSubmit}>
                      <FieldFlex style={{ alignItems: 'flex-start' }}>
                        <AddClassFieldText>Date: </AddClassFieldText>
                        <StyledField
                          name="date"
                          type="date"
                          component={FieldComponent}
                          validate={validations.required}
                        />
                      </FieldFlex>

                      <FieldFlex style={{ alignItems: 'flex-start' }}>
                        <AddClassFieldText>Time: </AddClassFieldText>
                        <FieldFlex style={{ width: '100%', alignItems: 'baseline', flexDirection: 'row' }}>
                          <StyledField
                            name="startTime"
                            type="time"
                            isClearable
                            component={FieldComponent}
                            validate={validations.time}
                          />

                          <div style={{ marginLeft: '10px' }}> - </div>
                          <StyledField style={{ marginLeft: '10px' }}
                            name="endTime"
                            type="time"
                            isClearable
                            component={FieldComponent}
                            validate={validations.time}
                          />
                        </FieldFlex>
                      </FieldFlex>

                      <RenewSaveButton type="submit" disabled={submitting}> Save </RenewSaveButton>
                    </StyledForm>
                  )
                }}
              />
            </PopUpLargeWrapper>
          )}
        </StyledPopup>
      ) : null}
      {confirmAll ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpWrapper>
              <PopUpErrorAlertContainer>
                <ErrorAlert
                  errorMessage={confirmPopupErrorMessage}
                  show={confirmPopupErrorMessage !== null}
                />
              </PopUpErrorAlertContainer>
              <PopUpButton className="close" onClick={() => { close(); setConfirmAll(false); }}>
                &times;
              </PopUpButton>
              <PopUpTextWrapper>
                Confirm All
              </PopUpTextWrapper>
              <StyledText>
                {`Are you sure you want to confirm the ${selectedRowKeys.length} selected ${selectedRowKeys.length > 1 ? 'attendances' : 'attendance'}?`}
              </StyledText>
              <Form
                name="ConfirmedClassesFrom"
                onSubmit={onConfirmAll}
                initialValues={null}
                initialValuesEqual={() => true}
                render={({ handleSubmit, values, submitting }) => {
                  values.ids = selectedRowKeys;
                  return (
                    <StyledForm onSubmit={handleSubmit}>
                      <SubmitButton type="submit" disabled={submitting}> Confirm </SubmitButton>
                    </StyledForm>
                  )
                }}
              />
            </PopUpWrapper>
          )}
        </StyledPopup>
      ) : null}
      <Flex style={{ position: 'relative' }}>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> Lesson Management </HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <ErrorAlert
            errorMessage={
              revertErrorMessage ? (
                <div>
                  {revertErrorMessage.split(',').slice(0, 3).map((e) => <div style={{ marginBottom: 2 }}> {e} </div>)}
                  {revertErrorMessage.split(',').length > 3 ? <div style={{ marginBottom: 2 }}> and {revertErrorMessage.split(',').length - 3} more. </div> : null}
                  { errorFormValue === 403 ? (
                    <div>
                      <div style={{ marginTop: 8 }}> Would you still like to proceed? </div>
                      <Flex style={{ justifyContent: 'center' }}>
                        <OverrideButton disabled={isDoubleSubmit} onClick={() => { setIsDoubleSubmit(true); onSubmit(revertId, true) }} style={{ marginRight: 15 }}> Yes </OverrideButton>
                        <OverrideButton onClick={() => { setIsSubmitting(false); setRevertErrorMessage(null); setRevertId(null) }}> No </OverrideButton>
                      </Flex>
                    </div>
                  ) : null}
                </div>
              )
                : errorMessage || null
            }
            show={revertErrorMessage !== null || errorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw / 2)' }}
          />
        </Flex>
      </Flex>
      <Flex style={{ position: 'relative' }}>
        <Flex>
          <ErrorAlert
            errorMessage={
              revertAllErrorMessage ? (
                <div>
                  {revertAllErrorMessage.split(',').slice(0, 3).map((e) => <div style={{ marginBottom: 2 }}> {e} </div>)}
                  {revertAllErrorMessage.split(',').length > 3 ? <div style={{ marginBottom: 2 }}> and {revertAllErrorMessage.split(',').length - 3} more. </div> : null}
                  { errorFormValue === 403 ? (
                    <div>
                      <div style={{ marginTop: 8 }}> Would you still like to proceed? </div>
                      <Flex style={{ justifyContent: 'center' }}>
                        <OverrideButton
                          disabled={isDoubleSubmit}
                          onClick={() => {
                            setIsDoubleSubmit(true);
                            onRevertAll({
                              ids: selectedClasses,
                              override: true,
                            })
                          }}
                          style={{ marginRight: 15 }}
                        >
                          Yes
                        </OverrideButton>
                        <OverrideButton
                          onClick={() => {
                            setIsSubmitting(false);
                            setRevertAllErrorMessage(null);
                          }}
                        >
                          No
                        </OverrideButton>
                      </Flex>
                    </div>
                  ) : null}
                </div>
              )
                : revertAllErrorMessage || null
            }
            show={revertAllErrorMessage !== null || errorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw / 2)' }}
          />
        </Flex>
      </Flex>
      <BottomWrapper>
        <LeftWrapper>
          <DetailWrapper>
            <StyledFlex style={{ justifyContent: 'space-between' }}>
              <Flex style={{ alignItems: 'center' }}>
                <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
                <LabelText style={{ marginRight: 0 }}> Lesson Information </LabelText>
              </Flex>
              { (user.role === 'admin' || user.role === 'superAdmin') ? (
                <div>
                  { data.hasUnconfirmedClasses ? (
                    <StyledLink to={`/lesson/${match.params.id}/edit`}>
                      <Icon icon="edit-icon" height={21} style={{ minWidth: 21 }} />
                      <div>Edit</div>
                    </StyledLink>
                  ) : (
                    <StyledButton disabled style={{ display: 'flex', flexDirection: 'row', color: '#C1C1C1' }}>
                      <Icon icon="edit-disabled" height={21} style={{ minWidth: 21 }} />
                      <div>Edit</div>
                    </StyledButton>
                  )}
                </div>
              ) : null}
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Lesson </DetailLabelText>
              <div>{data.formattedLesson.title}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Type </DetailLabelText>
              <div>{data.formattedLesson.formattedType}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Recurring </DetailLabelText>
              <div>{data.formattedLesson.formattedRecurring}</div>
            </StyledFlex>
            <StyledFlex style={{ alignItems: 'flex-start' }}>
              <DetailLabelText> Student(s) </DetailLabelText>
              { data.formattedLesson.studentPackages ? (
                <Flex direction="column">
                  { data.formattedLesson.studentPackages.length > 0 ? data.formattedLesson.studentPackages.map((sp, index) => {
                    const packageInfo = ((user.role === 'admin' || user.role === 'superAdmin') && sp.packageId) ? <span> (<PackageLink style={{ marginLeft: 0 }} to={`/package/show/${sp.packageId}`}>{sp.prefix}{sp.packageId}</PackageLink>) </span> : null;
                    return <div style={{ paddingTop: index !== 0 && 8 }}> {sp.name} {packageInfo}{sp.cancelledById ? ' (Cancelled)' : ''}  </div>
                  }) : 'N/A'}
                </Flex>
              ) : <div> N/A </div>}
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Instructor </DetailLabelText>
              <div>{data.formattedLesson.instructorName}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Room </DetailLabelText>
              <div>{data.formattedLesson.roomName}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Time </DetailLabelText>
              <div>{data.formattedLesson.formattedTime}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Last Class </DetailLabelText>
              <div>{get(data, 'formattedLesson.lastClass.0.date', null) ? moment(data.formattedLesson.lastClass[0].date).format('DD/MM/YYYY') : 'N/A'}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Scheduled by </DetailLabelText>
              <div>{data.formattedLesson.scheduledBy}</div>
            </StyledFlex>
          </DetailWrapper>
        </LeftWrapper>
        <RightWrapper>
          <Tabs activeKey={get(queryParams, ['where', 'tab'])} onChange={(tabValue) => onTabChange(tabValue)} style={{ height: '100%' }}>
            <TabPane tab="Class Attendance" key="Class Attendance">
              <AttendanceContainer divHeight={attendanceDivHeight} showMargin={user.role === 'admin' || user.role === 'superAdmin'}>
                <ModelPage
                  {...props}
                  showHeader={false}
                  modelName="lesson/attendance"
                  itemsPerPage={25}
                  query={{ where: { recurrenceId: match.params.id, tab: 'Class Attendance', type: 'Upcoming' } }}
                  initialQuery={{
                    currentPage: 1,
                    where: {
                      tab: 'Classes',
                      startTime: 'Upcoming',
                    },
                  }}
                  radioGroup={(user.role === 'admin' || user.role === 'superAdmin') ? ['All', 'Upcoming', 'Unconfirmed', 'Confirmed'] : ['All', 'Upcoming', 'Past']}
                  radioGroupKey="type"
                  filterConfig={user.role === 'instructor' ? attendanceFilterConfig.filter((c) => c.label !== 'Signature' && c.label !== 'Instructor') : attendanceFilterConfig}
                  allowSearch
                  showCreateButton={false}
                  actions={(user.role === 'admin' || user.role === 'superAdmin') ? [
                    { action: () => setConfirmAll(true), label: 'Confirm All', disabled: !(selectedRowKeys && selectedRowKeys.length > 0) },
                  ] : []}
                  render={(renderProps) => {
                    const { height, loading: attendanceTableLoading } = renderProps;
                    if (attendanceDivHeight !== height) setAttendanceDivHeight(height);
                    if (selectedRowKeys && selectedRowKeys.length > 0 && attendanceTableLoading) setSelectedRowKeys([]);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Attendance" />}>
                        <AntDTable
                          modelName="lesson/attendance"
                          cellRenderers={attendanceCells}
                          pagination={{
                            currentPage: renderProps.currentPage,
                            pageSize: renderProps.pageSize,
                            showQuickJumper: true,
                            hideOnSinglePage: true,
                            showSizeChanger: !!((user.role === 'admin' || user.role === 'superAdmin')),
                            pageSizeOptions: [10, 20, 25, 50, 100],
                          }}
                          rowKey="id"
                          rowSelection={(user.role === 'admin' || user.role === 'superAdmin') ? {
                            selectedRowKeys,
                            onChange: (keys) => setSelectedRowKeys(keys),
                            getCheckboxProps: (record) => ({
                              disabled: !(!record.cancelledById && !record.isUpcoming && !record.isConfirmed),
                            }),
                          } : false}
                          scroll={{ x: 'max-content', y: '40vh' }}
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </AttendanceContainer>
            </TabPane>
            <TabPane tab="Classes" key="Classes">
              <ModelContainer divHeight={divHeight} showMargin={user.role === 'admin' || user.role === 'superAdmin'} isWrapButtons={(user.role === 'admin' || user.role === 'superAdmin') && get(queryParams, ['where', 'startTime'], null) === 'Upcoming'}>
                <ModelPage
                  {...props}
                  showHeader={false}
                  modelName={modelName}
                  allowSearch
                  itemsPerPage={25}
                  query={{ where: { recurrenceId: match.params.id, page: 'Main', tab: 'Classes', startTime: 'Upcoming' } }}
                  initialQuery={{
                    currentPage: 1,
                    where: {
                      tab: 'Classes',
                      startTime: 'Upcoming',
                    },
                  }}
                  filterConfig={user.role === 'instructor' ? filterConfig.filter((f) => f.label !== 'Instructor' && f.label !== 'Package Type') : user.role === 'parent' ? filterConfig.filter((f) => f.label !== 'Package Type') : filterConfig}
                  radioGroup={[
                    'All',
                    'Upcoming',
                    'Completed',
                  ]}
                  radioGroupKey={classesRadioGroupKey}
                  showCreateButton={false}
                  actions={[...actionButtons, ...checkBoxSelectButtons]}
                  render={(renderProps) => {
                    const { height, loading: classLoading } = renderProps;
                    if (divHeight !== height) setDivHeight(height);
                    if (selectedClasses && selectedClasses.length > 0 && classLoading) setSelectedClasses([]);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Classes" />}>
                        <AntDTable
                          modelName={modelName}
                          cellRenderers={user.role === 'instructor' ? cellRenderers.filter((c) => c.title !== 'Action') : user.role === 'parent' ? cellRenderers.filter((c) => c.title !== 'Note') : cellRenderers}
                          rowKey="id"
                          rowSelection={(user.role === 'admin' || user.role === 'superAdmin') ? {
                            selectedRowKeys: selectedClasses,
                            onChange: (keys) => setSelectedClasses(keys),
                            getCheckboxProps: (record) => ({
                              disabled: record.hasAttendanceConfirmed,
                            }),
                          } : false}
                          pagination={{
                            currentPage: renderProps.currentPage,
                            pageSize: renderProps.pageSize,
                            showQuickJumper: true,
                            hideOnSinglePage: true,
                            showSizeChanger: !!((user.role === 'admin' || user.role === 'superAdmin')),
                            pageSizeOptions: [10, 20, 25, 50, 100],
                          }}
                          scroll={{ x: 'max-content', y: '40vh' }}
                          onRow={onRow}
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
          </Tabs>
        </RightWrapper>
      </BottomWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state.authentication),
  }
}

export default connect(mapStateToProps)(ShowView);
