import React from 'react';
import styled from 'styled-components';

import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  branch,
  renderComponent,
  onlyUpdateForKeys,
  withState,
} from 'recompose';
import get from 'lodash/get';
import { palette } from 'styled-theme';

import SignUp from '../SignUp';
import ForgotPassword from '../ForgotPassword';
import SetPassword from '../SetPassword';
import Loading from '../../components/atoms/Loading';
import Page from '../../components/templates/Page';
import { signInSuccess as signInAction } from '../../store/authentication/actions';

import Home from '../Home';
import Admin from './Admin';
import NotFound from '../NotFound';
// import SurveyPublicPage from '../SurveyPublic';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${palette('white', 0)};
  box-shadow: rgba(50, 50, 93, 0.1) 0px 2px 4px;
  height: 100%;
  background-color: #F5F5F5;
  overflow: auto;
`;

const App = () => (
  <Wrapper>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/reset-password" exact component={ForgotPassword} />
      <Route path="/set-password" exact component={SetPassword} />

      <Admin />

      {/* 404 */}
      <Route component={NotFound} />
    </Switch>
  </Wrapper>
);

const LoadingState = () => (
  <Page style={{ padding: 0, backgroundColor: 'white' }}>
    <Loading />
  </Page>
);

const enhance = compose(
  withState('loading', 'setLoading', true),
  withRouter,
  onlyUpdateForKeys(['loading', 'location']),
  connect(null, (dispatch) => ({ signInSuccess: (data) => dispatch(signInAction(data)) })),
  lifecycle({
    componentDidMount() {
      const { setLoading, signInSuccess } = this.props;
      const { api, authApi } = global;

      const token = localStorage.getItem('access_token');
      api.setToken(token);
      authApi.setToken(token);

      authApi
        .get('/me')
        .then(({ data }) => {
          localStorage.setItem('access_token', data.token);
          global.api.setToken(data.token);
          global.authApi.setToken(data.token);
          signInSuccess(data);
        })
        .catch((err) => {
          if (get(err, 'response.status') === 401) {
            localStorage.setItem('access_token', '');
          }
        })
        .then(() => setLoading(false));
    },
  }),
  branch(({ loading }) => loading, renderComponent(LoadingState)),
);

export default enhance(App);
