import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/P';

const StyledAddItem = styled(Text)`
  cursor: pointer;
  text-align: center;
`;
const Flex = styled.div`
  display: flex;
  flex-direction:  ${({ flexDirection }) => flexDirection};
`;

const ItemList = ({
  items,
  itemName,
  onRemove,
  onChange,
  onAddItem,
  hideButtons,
  InputComponent,
  ...others
}) => {
  return (
    <Flex flexDirection="column">
      {items.map((item, i) => {
        return (
          <React.Fragment key={i}>
            <InputComponent
              onRemove={() => {
                onRemove(item, i);
              }}
              onChange={val => {
                onChange(val, item, i);
              }}
              value={item}
              {...others}
              index={i}
            />
          </React.Fragment>
        );
      })}
      {!hideButtons ?
        <Fragment>
          <Divider />
          <StyledAddItem align="center" variant="subtitle1" onClick={onAddItem}>
            Add {itemName} +
          </StyledAddItem>
          <Divider />
        </Fragment>
      : null}
    </Flex>
  );
};

ItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  itemName: PropTypes.string,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  onAddItem: PropTypes.func,
  InputComponent: PropTypes.func,
  hideButtons: PropTypes.bool,
};

ItemList.defaultProps = {
  items: [],
  itemName: 'Item',
  hideButtons: false,
  onRemove(...args) {
    console.log('[ItemList] onRemove()', args);
  },
  onChange(...args) {
    console.log('[ItemList] onChange()', args);
  },
  onAddItem(...args) {
    console.log('[ItemList] onAddItem()', args);
  },
  InputComponent: () => <div>Input Component</div>,
};

export default ItemList;
