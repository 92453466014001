import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import { ConfigProvider, Empty } from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Button from '../../../components/atoms/Button';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import useUserForm from '../../../hooks/useUserForm';
import Flex from '../../../components/atoms/Flex';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import PopUpDelete from '../../../components/organisms/PopUpDelete';

const Wrapper = styled.div`
  padding-bottom: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
    height: 100%;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const ModelContainer = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
  > div {
    @media (max-width: ${size('mobileBreakpoint')}) {
      height: 100%;
    }
    > div: first-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        max-height: ${(props) => (props.divHeight ? 'none' : '56px')}
      }
    }
    > div: last-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        height: calc(100% - 56px);
        height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')}
      }
      > div {
        @media (max-width: ${size('mobileBreakpoint')}) {
          height: 100%;
        }
        > div {
          @media (max-width: ${size('mobileBreakpoint')}) {
            height: 100%;
          }
          > div: last-child {
            @media (max-width: ${size('mobileBreakpoint')}) {
              height: 100%;
            }
            > div {
              @media (max-width: ${size('mobileBreakpoint')}) {
                height: calc(100% - 40px);
              }
              > div {
                @media (max-width: ${size('mobileBreakpoint')}) {
                  height: 100%;
                }
                > div: last-child {
                  max-height: 100% !important;
                  @media (max-width: ${size('mobileBreakpoint')}) {
                    max-height: calc(100% - 55px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const modelName = 'user/staff';

const ListView = (props) => {
  const { user, location } = props;
  const [divHeight, setDivHeight] = useState(0);

  const itemsPerPage = 25;

  const cellRenderers = [
    {
      title: 'Name',
      dataIndex: 'staff',
      key: 'staff',
      render: (staff) => <Cell><Link to={{ pathname: `/staff/show/${staff.id}`, state: { prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` } }}>{staff.name}</Link></Cell>,
      width: 75,
    },
    {
      title: 'Account',
      dataIndex: 'email',
      key: 'email',
      render: (content) => <Cell>{content}</Cell>,
      width: 90,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (content) => <Cell>{content}</Cell>,
      width: 140,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (content) => <Cell style={{ maxWidth: 300 }}>{content}</Cell>,
      width: 100,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Cell>
          <PopUpDelete user={user} endpoint="user" id={record.id} type="Staff" />
        </Cell>
      ),
      width: 90,
    },
  ];

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderText> Staff Management </HeaderText>
      </Flex>
      <ModelContainer divHeight={divHeight}>
        <ModelPage
          showHeader={false}
          modelName={modelName}
          allowSearch
          itemsPerPage={itemsPerPage}
          query={{ where: { status: 'Current', role: 'All' } }}
          initialQuery={{
            currentPage: 1,
            where: {
              role: 'All',
            },
          }}
          showExportButton
          exportDataRoute="/user/staffExport"
          radioGroup={['All', 'Instructor', 'Admin', 'Super Admin', 'Library Helper']}
          radioGroupKey="role"
          showCreateButton
          apiRoute="staff"
          createState={{ prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` }}
          {...props}
          render={(renderProps) => {
            const { height } = renderProps;
            if (divHeight !== height) setDivHeight(height);
            return (
              <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Staff" />}>
                <AntDTable
                  modelName={modelName}
                  cellRenderers={cellRenderers}
                  rowKey="id"
                  pagination={{ pageSize: itemsPerPage, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                  scroll={{ x: 'max-content', y: '65vh' }}
                  {...renderProps}
                />
              </ConfigProvider>
            )
          }}
        />
      </ModelContainer>
    </Wrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
