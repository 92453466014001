import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { size, palette, font } from 'styled-theme';
import { ifProp } from 'styled-tools';
import { Field, Form } from 'react-final-form';
import { Redirect, useHistory } from 'react-router-dom';
import moment from 'moment';
import Popup from 'reactjs-popup';
import get from 'lodash/get';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import { validations } from '../../../utils/form';
import FieldComponent from '../../../components/molecules/FieldComponent';
import Button from '../../../components/atoms/Button';
import useRequestForm from '../../../hooks/useRequestForm';
import useGeneralData from '../../../hooks/useGeneralData';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import inputOptions from '../../../utils/form/inputOptions';
import Loading from '../../../components/atoms/Loading';
import Link from '../../../components/atoms/Link';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 100%;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
  }
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const FormWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 66px);
  height: calc(100% - 66px);
  overflow-y: auto;
  padding: 5px 25px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 5px 12px;
    max-height: calc(100% - 56px);
    height: calc(100% - 56px);
  }
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C7C7C7;
  margin-bottom: 12px;
`;

const TopText = styled.div`
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 200px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledForm = styled.form`
  >div {
    >div {
      min-height: 16px;
    }
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const StyledButton = styled(Button)`
  width: 50%;
  min-width: 180px;
  margin: 20px 0px 20px;
  border-radius: 0px;

  @media (max-width: ${size('mobileBreakpoint')}){
    width: 100%;
  }
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 30vw;
  min-width: 30vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const CompletedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

const PopUpText = styled.div`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  padding: 10px 0px 20px 0px;
  text-align: center;
  margin-top: 20px;
`;

const MainWrapper = styled(Flex)`
  flex-direction: column;
  width: 50%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    width: 100%;
  }
`;

const TextNoteText = styled(StyledText)`
  padding-top: 12px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const PublisherStyledText = styled(StyledText)`
  display: block;
  @media (max-width: ${size('mobileBreakpoint')}){
    display: none;
  }
`;

const MobilePublisherStyledText = styled(StyledText)`
  display: none;
  @media (max-width: ${size('mobileBreakpoint')}){
    display: block;
  }
`;

const EditView = (props) => {
  const { user, match, location } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [completeMessage, setCompleteMessage] = useState(null);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const { data, loading } = useGeneralData({
    endpoint: 'request',
    query: { id: get(match, 'params.id', null) },
  });

  const { onSubmit } = useRequestForm({
    endpoint: match.path === '/purchase-request/create' ? 'request/purchase' : `request/edit/${match.params.id}`,
    user,
    setErrorMessage,
    setCompleteMessage,
    action: match.path === '/purchase-request/create' ? 'create' : 'edit',
  });

  const history = useHistory();

  let type;
  if (match.path === '/purchase-request/create') type = 'create';
  else if (match.path === '/purchase-request/edit/:id') type = 'edit';
  else return <Redirect to="purchase-request" />

  if (loading) return <Loading />

  if (!data || !data.students || !Array.isArray(data.students)) return <Redirect to="/purchase-request" />;
  if (type === 'edit' && !get(data, 'request', null)) return <Redirect to="/purchase-request" />;

  const mappedProduct = [...get(data, 'productOptions', []), { product: 'Others', type: ['Accessory', 'Book', 'Instrument', 'Others'] }].map((p) => {
    p.label = p.product;
    p.value = p.product;
    p.typeArray = [].concat(...[p.type]);
    return p;
  })

  const mappedPublisherMaker = [...get(data, 'publisherOrMakerOptions', []), { publisherOrMaker: 'Others'}].map((p) => {
    p.label = p.publisherOrMaker;
    p.value = p.publisherOrMaker;
    return p;
  })

  const mappedStudents = get(data, 'students', []).map((s) => {
    s.label = `${s.firstName} ${s.lastName} (ST${s.id})`;
    return s;
  });
  const mappedInstructors = get(data, 'instructors', []).map((s) => {
    s.label = `${s.firstName} ${s.lastName}`;
    return s;
  })

  let initialValues = null;

  if (type === 'edit') {
    initialValues = {
      childId: get(data, 'request.childId', null),
      instructorId: get(data, 'request.instructorId', null),
      itemType: get(data, 'request.itemType', null),
      itemSource: get(data, 'request.itemSource', null),
      itemName: get(get(data, 'productOptions', []).find((p) => p.product === get(data, 'request.itemName', null)), 'product', 'Others'),
      customItemName: get(get(data, 'productOptions', []).find((p) => p.product === get(data, 'request.itemName', null)), 'product', 'Others') === 'Others' ? get(data, 'request.itemName', null) : null,
      publisher: get(data, 'request.publisher', null) ? get(get(data, 'publisherOrMakerOptions', []).find((p) => p.publisherOrMaker === get(data, 'request.publisher', null)), 'publisherOrMaker', 'Others') : null,
      customPublisherMaker: (get(data, 'request.publisher', null) ? get(get(data, 'publisherOrMakerOptions', []).find((p) => p.publisherOrMaker === get(data, 'request.publisher', null)), 'publisherOrMaker', 'Others') : null) === 'Others' ? get(data, 'request.publisher', null) : null,
      reason: get(data, 'request.reason', null),
      note: get(data, 'request.note', null),
    };
  }

  return (
    <Wrapper>
      {completeMessage ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          <PopUpWrapper>
            <PopUpTextWrapper>
              Purchase Request
            </PopUpTextWrapper>
            <CompletedWrapper>
              <Icon icon="request-sent" height="60" />
              <PopUpText> Purchase request sent. </PopUpText>
            </CompletedWrapper>
          </PopUpWrapper>
        </StyledPopup>
      ) : null}
      <Flex style={{ position: 'relative' }}>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> {type === 'create' ? 'Create New' : 'Edit'} </HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <ErrorAlert
            errorMessage={errorMessage}
            show={errorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw  / 2)' }}
          />
        </Flex>
      </Flex>
      <FormWrapper>
        <StyledFlex>
          <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
          <TopText> Purchase Request </TopText>
        </StyledFlex>
        <Form
          name="PurchaseRequestForm"
          onSubmit={onSubmit}
          initialValues={initialValues}
          initialValuesEqual={() => true}
          render={({ handleSubmit, submitting, values }) => {
            if (type === 'edit') values.requestId = get(data, 'request.id', null);
            return (
              <StyledForm onSubmit={handleSubmit}>
                <Flex>
                  <MainWrapper style={{ paddingRight: 10 }}>
                    <FieldFlex>
                      <StyledText>Student: </StyledText>
                      <StyledField
                        name="childId"
                        type="select"
                        component={FieldComponent}
                        options={mappedStudents}
                      />
                    </FieldFlex>
                    <FieldFlex>
                      <StyledText>Instructor: </StyledText>
                      <StyledField
                        name="instructorId"
                        type="select"
                        component={FieldComponent}
                        options={mappedInstructors}
                        validate={validations.required}
                      />
                    </FieldFlex>
                    <FieldFlex>
                      <StyledText>Source: </StyledText>
                      <StyledField
                        name="itemSource"
                        type="select"
                        component={FieldComponent}
                        options={inputOptions.purchaseRequestSourceOptions}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Type: </StyledText>
                      <StyledField
                        name="itemType"
                        type="select"
                        component={FieldComponent}
                        options={inputOptions.purchaseRequestTypeOptions}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Product: </StyledText>
                      <StyledField
                        name="itemName"
                        type="select"
                        component={FieldComponent}
                        options={mappedProduct.filter((p) => {
                          const itemTypeCheck = values.itemType ? p.typeArray.includes(values.itemType) : true;
                          const itemSourceCheck = values.itemSource ? (p.source === values.itemSource || p.product === 'Others') : true;
                          return itemTypeCheck && itemSourceCheck;
                        })}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    { values.itemName === 'Others' ? (
                      <FieldFlex>
                        <StyledText />
                        <StyledField
                          name="customItemName"
                          type="text"
                          component={FieldComponent}
                          validate={validations.required}
                        />
                      </FieldFlex>
                    ) : null}

                    <FieldFlex>
                      <PublisherStyledText>Publisher / Maker <div> (if any): </div> </PublisherStyledText>
                      <MobilePublisherStyledText>Publisher / Maker (if any): </MobilePublisherStyledText>
                      <StyledField
                        name="publisher"
                        type="select"
                        component={FieldComponent}
                        isClearable
                        options={mappedPublisherMaker}
                      />
                    </FieldFlex>

                    { values.publisher === 'Others' ? (
                      <FieldFlex>
                        <StyledText />
                        <StyledField
                          name="customPublisherMaker"
                          type="text"
                          component={FieldComponent}
                          validate={validations.required}
                        />
                      </FieldFlex>
                    ) : null}

                    <FieldFlex>
                      <StyledText>Reason (if any): </StyledText>
                      <StyledField
                        name="reason"
                        type="text"
                        component={FieldComponent}
                      />
                    </FieldFlex>

                    <FieldFlex style={{ alignItems: 'normal' }}>
                      <TextNoteText> Note (if any): </TextNoteText>
                      <StyledField
                        name="note"
                        type="textarea"
                        component={FieldComponent}
                        validate={validations.maxLength(1000)}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText />
                      <Flex style={{ width: '100%' }}>
                        <StyledButton type="submit" disabled={submitting}>
                          Save
                        </StyledButton>
                      </Flex>
                    </FieldFlex>
                  </MainWrapper>
                </Flex>
              </StyledForm>
            )
          }}
        />
      </FormWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state.authentication),
})

export default connect(mapStateToProps)(EditView);
