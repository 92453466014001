/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ifProp } from 'styled-tools';
import { Redirect } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { Tabs, ConfigProvider, Empty } from 'antd';
import get from 'lodash/get';
import Popup from 'reactjs-popup';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import Link from '../../../components/atoms/Link';
import Button from '../../../components/atoms/Button';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import useUserForm from '../../../hooks/useUserForm';
import FieldComponent from '../../../components/molecules/FieldComponent';
import { validations } from '../../../utils/form';
import ImageUpload from '../../../components/atoms/ImageUpload';
import AntDTable from '../../../components/organisms/AntDTable';
import useQueryParams from '../../../hooks/useQueryParams';
import ModelPage from '../../../containers/ModelPage';
import PopUpDelete from '../../../components/organisms/PopUpDelete';
import uniq from 'lodash/uniq';

const Wrapper = styled.div`
  padding-bottom: 10px;
  height: 100%;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  max-height: 66px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100% - 66px);

  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
`;

const SettingsWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0 25px 25px 25px;
  width: 100%;
  height: 100%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 0px;
  }

  > div {
    height: 100%;
    > div: last-child {
      height: calc(100% - 46px);
      > div {
        height: 100%;
        > div {
          height: 100%;
          > div {
            height: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 200px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const MultiSelectText = styled(StyledText)`
  padding-top: 17px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 12px;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 20px;
  padding-top: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: ${(props) => (props.noTablePadding ? '12px 0px' : '12px')};
  }
  > div {
    height: 100%;
    > div {
      height: 100%;
      > div: last-child {
        height: 100%;
        > div {
          height: calc(100% - 40px);
          > div {
            height: 100%;
            > div {
              height: 100%;
              > div: last-child {
                max-height: calc(100% - 55px) !important;
              }
            }
          }
        }
      }
    }
  }
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C7C7C7;
  margin-bottom: 12px;
`;

const TopText = styled.div`
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const StyledButton = styled(Button)`
  width: 50%;
  min-width: 180px;
  margin: 30px 0px 20px;
  border-radius: 0px;

  @media (max-width: ${size('mobileBreakpoint')}){
    width: 100%;
  }
`;

const ResetButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 12px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }
`;

const DashedButton = styled(Button)`
  border: 1px dashed ${palette('grayscale', 3)};
  background-color: ${ifProp('disabled', '#CCCCCC', palette('white', 0))};
  color: ${palette('black', 0)};
  border-radius: 4px;
  margin-top: 5px;

  &:hover,
  &:focus,
  &:active {
    background-color: ${palette('white', 0)};
    color: ${palette('black', 0)};
  }
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 30vw;
  min-width: 30vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpGroupWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 45vw;
  min-width: 45vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const SubmitButton = styled(Button)`
  min-width: 160px;
  width: 100%;
  margin: ${size('margin.default')} auto ${size('margin.default')};
  border-radius: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
`;

const ModelContainer = styled.div`
  > div {
    padding: 24px 0px;
    height: 100%;
    > div: first-child {
      max-height: ${(props) => (props.divHeight ? 'none' : '56px')};
      > * {
        :nth-child(2) {
          margin-right: 10px;
        }
      }
    }
    > div: last-child {
      height: calc(100% - 56px);
      height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')};
      > div {
        height: 100%;
        > div {
          height: 100%;
          > div: last-child {
            height: 100%;
            > div {
              height: calc(100% - 40px);
              > div {
                height: 100%;
                > div: last-child {
                  max-height: calc(100% - 55px) !important;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const DeleteButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const SettingsFormWrapper = styled(Flex)`
  flex-direction: column;
  width: 50%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    width: 100%;
  }
`;

const BasicWrapper = styled(Flex)`
  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
  }
`;

const BasicInfoLeftWrapper = styled(SettingsFormWrapper)`
  padding-right: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-right: 0px;
  }
`;

const BasicInfoRightWrapper = styled(SettingsFormWrapper)`
  padding-left: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-left: 0px;
  }
`;

const TextNoteText = styled(StyledText)`
  padding-top: 12px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const SaveButtonWrapper = styled(FieldFlex)`
  @media (max-width: ${size('mobileBreakpoint')}){
    display: none;
  }
`;

const MobileSaveButtonWrapper = styled(FieldFlex)`
  display: none;
  @media (max-width: ${size('mobileBreakpoint')}){
    display: flex;
    padding-right: 10px;
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const ErrorAlertContainer = styled.div`
  > div {
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: 75vw;
      width: fit-content;
    }
  }
`;

const EditView = ({ user, location }) => {
  const [
    errorMessage,
    setErrorMessage,
  ] = useState(null);
  const [
    additionalContacts,
    setAdditionalContacts,
  ] = useState(null);
  const [
    image,
    setImage,
  ] = useState(null);
  const [
    resetClick,
    setResetClick,
  ] = useState(false);
  const [addRoom, setAddRoom] = useState(null);
  const [mainErrorMessage, setMainErrorMessage] = useState(null);
  const [divHeight, setDivHeight] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [groupDivHeight, setGroupDivHeight] = useState(0);
  const [addGroup, setAddGroup] = useState(null);
  const [groupErrorMessage, setGroupErrorMessage] = useState(null);
  const [invSectionDivHeight, setInvSectionDivHeight] = useState(0);
  const [libSectionDivHeight, setLibSectionDivHeight] = useState(0);
  const [addInvSection, setAddInvSection] = useState(null);
  const [addLibSection, setAddLibSection] = useState(null);
  const [invSectionErrorMessage, setInvSectionErrorMessage] = useState(null);
  const [libSectionErrorMessage, setLibSectionErrorMessage] = useState(null);

  const { queryParams, setQueryParams } = useQueryParams({ initialQueryParams: { where: { tab: 'My Profile' } } });

  const tab = get(queryParams, ['where', 'tab'], 'My Profile');

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (mainErrorMessage !== null) {
      setTimeout(() => {
        setMainErrorMessage(null);
        setIsSubmitting(false);
      }, 3000);
    }
  }, [mainErrorMessage]);

  useEffect(() => {
    if (groupErrorMessage !== null) {
      setTimeout(() => {
        setGroupErrorMessage(null);
      }, 3000);
    }
  }, [groupErrorMessage]);

  useEffect(() => {
    if (invSectionErrorMessage !== null) {
      setTimeout(() => {
        setInvSectionErrorMessage(null);
      }, 3000);
    }
  }, [invSectionErrorMessage]);

  useEffect(() => {
    if (libSectionErrorMessage !== null) {
      setTimeout(() => {
        setLibSectionErrorMessage(null);
      }, 3000);
    }
  }, [libSectionErrorMessage]);

  const { onSubmit } = useUserForm({
    endpoint: tab === 'My Profile' ? `user/${user.id}/settings` : tab === 'Business Profile' ? `user/${user.id}/businessProfile` : null,
    setErrorMessage: setMainErrorMessage,
    type: user.role,
    action: 'update',
  });

  const { onSubmit: onCreateRoom } = useUserForm({
    endpoint: 'room',
    setErrorMessage,
    type: 'room',
    action: 'create',
  });

  const { onSubmit: onEditRoom } = useUserForm({
    endpoint: 'room/edit',
    setErrorMessage,
    action: 'update',
  });

  const { onSubmit: onCreateGroup } = useUserForm({
    endpoint: 'group',
    setErrorMessage: setGroupErrorMessage,
    type: 'group',
    action: 'create',
  });

  const { onSubmit: onEditGroup } = useUserForm({
    endpoint: 'group/edit',
    setErrorMessage: setGroupErrorMessage,
    action: 'update',
  });

  const { onSubmit: onCreateInventorySection } = useUserForm({
    endpoint: 'inventorySection',
    setErrorMessage: setInvSectionErrorMessage,
    type: 'room',
    action: 'create',
  });

  const { onSubmit: onEditInventorySection } = useUserForm({
    endpoint: 'inventorySection/edit',
    setErrorMessage: setInvSectionErrorMessage,
    action: 'update',
  });

  const { onSubmit: onCreateLibrarySection } = useUserForm({
    endpoint: 'librarySection',
    setErrorMessage: setLibSectionErrorMessage,
    type: 'room',
    action: 'create',
  });

  const { onSubmit: onEditLibrarySection } = useUserForm({
    endpoint: 'librarySection/edit',
    setErrorMessage: setLibSectionErrorMessage,
    action: 'update',
  });

  if (user == null) return <Redirect to="/" />

  const { TabPane } = Tabs;

  const onTabChange = (tabValue) => {
    setQueryParams((old) => ({
      where: {
        tab: tabValue,
      },
    }), { replace: true });
  }

  const getNumber = (arr) => {
    let num;

    if (arr.length === 0) num = 1;
    else if (arr.includes(1)) num = 2;
    else num = 1;

    return num;
  }

  let initialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    contact: user.mainContact ? user.mainContact.name : null,
    relationship: user.mainContact ? user.mainContact.relationship : null,
    phoneNumber: user.mainContact ? user.mainContact.phoneNumber : null,
    otherEmail: user.mainContact ? user.mainContact.email : null,
    address: user.address,
    education: user.education,
    emailNotification: user.emailNotification ? user.emailNotification : false,
    notificationEmails: user.notificationEmails ? user.notificationEmails : [],
  };

  if (user.role === 'parent') {
    const contacts = user.otherContacts;
    let otherContacts = {};
    const initialContacts = [];

    if (contacts) {
      otherContacts = {
        contact1: contacts.name1,
        relationship1: contacts.relationship1,
        phoneNumber1: contacts.phoneNumber1,
        otherEmail1: contacts.email1,
      }

      initialContacts.push(1);

      if (contacts.name2 != null && contacts.relationship2 != null && contacts.phoneNumber2 != null && contacts.email2 != null) {
        otherContacts = {
          ...otherContacts,
          contact2: contacts.name2,
          relationship2: contacts.relationship2,
          phoneNumber2: contacts.phoneNumber2,
          otherEmail2: contacts.email2,
        }
        initialContacts.push(2);
      }
    }

    if (additionalContacts == null) setAdditionalContacts([...initialContacts]);

    initialValues = {
      ...initialValues,
      ...otherContacts,
    }
  } else if (additionalContacts == null) setAdditionalContacts([]);

  const cellRenderers = [{
    title: 'Lesson Type',
    dataIndex: 'lessonType',
    key: 'lessonType',
    render: (lesson) => <Link to={{ pathname: `settings/${lesson.href}`, state: { prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` } }}>{lesson.name}</Link>,
  }];

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (text, record) => (
        <Cell>
          <Flex>
            <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
              <StyledPopup
                trigger={<DeleteButton style={{ minWidth: 'auto' }}> Edit </DeleteButton>}
                modal
                closeOnDocumentClick={false}
                closeOnEscape={false}
              >
                {(close) => (
                  <PopUpWrapper>
                    <PopUpButton className="close" onClick={close}>
                      &times;
                    </PopUpButton>
                    <PopUpTextWrapper> Edit </PopUpTextWrapper>
                    <div>
                      <Form
                        name={tab === 'Rooms' ? 'EditRoomForm' : tab === 'Inventory Sections' ? 'EditInvSectionForm' : 'EditLibrSectionForm'}
                        onSubmit={tab === 'Rooms' ? onEditRoom : tab === 'Inventory Sections' ? onEditInventorySection : onEditLibrarySection}
                        initialValues={{ name: record.name }}
                        initialValuesEqual={() => true}
                        render={({ handleSubmit, values, submitting }) => {
                          values.id = record.id;
                          values.oldName = record.name;
                          return (
                            <StyledForm onSubmit={handleSubmit}>
                              <ErrorAlertContainer>
                                <ErrorAlert
                                  errorMessage={tab === 'Rooms' ? errorMessage : tab === 'Inventory Sections' ? invSectionErrorMessage : libSectionErrorMessage}
                                  show={tab === 'Rooms' ? errorMessage !== null : tab === 'Inventory Sections' ? invSectionErrorMessage !== null : libSectionErrorMessage !== null}
                                />
                              </ErrorAlertContainer>

                              <FieldFlex>
                                <StyledText style={{ width: 120 }}>Name: </StyledText>
                                <StyledField
                                  name="name"
                                  type="text"
                                  component={FieldComponent}
                                  validate={validations.required}
                                />
                              </FieldFlex>

                              <SubmitButton type="submit" disabled={submitting}> Save </SubmitButton>
                            </StyledForm>
                          )
                        }}
                      />
                    </div>
                  </PopUpWrapper>
                )}
              </StyledPopup>
            </div>
            <div style={{ paddingLeft: 5 }}>
              <PopUpDelete user={user} endpoint={tab === 'Rooms' ? 'room' : tab === 'Inventory Sections' ? 'inventorySection' : 'librarySection'} id={record.id} type={tab === 'Rooms' ? 'Room' : tab === 'Inventory Sections' ? 'Inventory Section' : 'Library Section'} />
            </div>
          </Flex>
        </Cell>
      ),
    },
  ];

  const groupColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (content) => <Cell>{content}</Cell>,
      width: 70,
      fixed: true,
    },
    {
      title: 'Users',
      dataIndex: 'users',
      key: 'users',
      render: (users) => <Cell>{users.map((u, index) => <span>{`${u.firstName || ''} ${u.lastName || ''}`}{index === users.length - 1 ? '' : ', '}</span>)}</Cell>,
      width: 75,
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (text, record) => (
        <Cell>
          <Flex>
            <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
              <StyledPopup
                trigger={<DeleteButton style={{ minWidth: 'auto' }}> Edit </DeleteButton>}
                modal
                closeOnDocumentClick={false}
                closeOnEscape={false}
              >
                {(close) => (
                  <PopUpGroupWrapper>
                    <PopUpButton className="close" onClick={close}>
                      &times;
                    </PopUpButton>
                    <PopUpTextWrapper> Edit </PopUpTextWrapper>
                    <div>
                      <Form
                        name="EditGroupForm"
                        onSubmit={onEditGroup}
                        initialValues={{ name: record.name, userId: record.users.map((u) => u.id.toString()) }}
                        initialValuesEqual={() => true}
                        render={({ handleSubmit, values, submitting }) => {
                          values.id = record.id;
                          values.oldUserGroups = record.users.map((u) => Number(u.id));
                          return (
                            <StyledForm onSubmit={handleSubmit}>
                              <ErrorAlertContainer>
                                <ErrorAlert
                                  errorMessage={groupErrorMessage}
                                  show={groupErrorMessage !== null}
                                />
                              </ErrorAlertContainer>

                              <FieldFlex>
                                <StyledText style={{ width: 120 }}>Name: </StyledText>
                                <StyledField
                                  name="name"
                                  type="text"
                                  component={FieldComponent}
                                  validate={validations.required}
                                />
                              </FieldFlex>

                              <FieldFlex style={{ alignItems: 'normal' }}>
                                <MultiSelectText style={{ width: 120 }}>Users: </MultiSelectText>
                                <StyledField
                                  name="userId"
                                  type="spec"
                                  component={FieldComponent}
                                  shouldChose
                                  isMulti
                                  labelBy={(v) => `${v.firstName || ''} ${v.lastName || ''}`}
                                  reference={{ endpoint: 'user/group', query: { where: { type: 'edit', role: ['instructor', 'parent', 'admin', 'superAdmin'], userIds: get(record, 'users', []).map((u) => Number(u.id)) }, order: { sortKey: ['firstName', 'lastName'], sortOrder: 'asc' } } }}
                                  associationType="own"
                                  validate={validations.required}
                                />
                              </FieldFlex>

                              <SubmitButton type="submit" disabled={submitting}> Save </SubmitButton>
                            </StyledForm>
                          )
                        }}
                      />
                    </div>
                  </PopUpGroupWrapper>
                )}
              </StyledPopup>
            </div>
            <div style={{ paddingLeft: 5 }}>
              <PopUpDelete user={user} endpoint="group" id={record.id} type="Group" />
            </div>
          </Flex>
        </Cell>
      ),
      fixed: 'right',
    },
  ];

  const data = [
    {
      key: '1',
      lessonType: {
        href: 'private',
        name: 'Private Lesson',
      },
    },
    {
      key: '2',
      lessonType: {
        href: 'semiprivate2',
        name: 'Semiprivate Lesson - 2 persons',
      },
    },
    {
      key: '3',
      lessonType: {
        href: 'semiprivate3',
        name: 'Semiprivate Lesson - 3 persons',
      },
    },
    {
      key: '4',
      lessonType: {
        href: 'semiprivate4',
        name: 'Semiprivate Lesson - 4 persons',
      },
    },
    {
      key: '5',
      lessonType: {
        href: 'group',
        name: 'Group Lesson',
      },
    },
  ];

  return (
    <Wrapper>
      {addRoom ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpWrapper>
              <PopUpButton className="close" onClick={() => { close(); setAddRoom(false); }}>
                &times;
              </PopUpButton>
              <PopUpTextWrapper>
                Add New Room
              </PopUpTextWrapper>
              <Form
                name="AddRoomForm"
                onSubmit={onCreateRoom}
                initialValues={null}
                render={({ handleSubmit, submitting }) => (
                  <StyledForm onSubmit={handleSubmit}>
                    <ErrorAlertContainer>
                      <ErrorAlert
                        errorMessage={errorMessage}
                        show={errorMessage !== null}
                      />
                    </ErrorAlertContainer>

                    <FieldFlex>
                      <StyledText style={{ width: 120 }}>Name: </StyledText>
                      <StyledField
                        name="name"
                        type="text"
                        component={FieldComponent}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <SubmitButton type="submit" disabled={submitting}> Save </SubmitButton>
                  </StyledForm>
                )}
              />
            </PopUpWrapper>
          )}
        </StyledPopup>
      ) : null}
      {addGroup ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpGroupWrapper>
              <PopUpButton className="close" onClick={() => { close(); setAddGroup(false); }}>
                &times;
              </PopUpButton>
              <PopUpTextWrapper>
                Add New Group
              </PopUpTextWrapper>
              <Form
                name="AddGroupForm"
                onSubmit={onCreateGroup}
                initialValues={null}
                render={({ handleSubmit, submitting }) => (
                  <StyledForm onSubmit={handleSubmit}>
                    <ErrorAlertContainer>
                      <ErrorAlert
                        errorMessage={groupErrorMessage}
                        show={groupErrorMessage !== null}
                      />
                    </ErrorAlertContainer>

                    <FieldFlex>
                      <StyledText style={{ width: 120 }}>Name: </StyledText>
                      <StyledField
                        name="name"
                        type="text"
                        component={FieldComponent}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex style={{ alignItems: 'normal' }}>
                      <MultiSelectText style={{ width: 120 }}>Users: </MultiSelectText>
                      <StyledField
                        name="userId"
                        type="spec"
                        component={FieldComponent}
                        shouldChose
                        isMulti
                        labelBy={(v) => `${v.firstName || ''} ${v.lastName || ''}`}
                        reference={{ endpoint: 'user/group', query: { where: { type: 'create', role: ['instructor', 'parent', 'admin', 'superAdmin'] }, order: { sortKey: ['firstName', 'lastName'], sortOrder: 'asc' } } }}
                        associationType="own"
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <SubmitButton type="submit" disabled={submitting}> Save </SubmitButton>
                  </StyledForm>
                )}
              />
            </PopUpGroupWrapper>
          )}
        </StyledPopup>
      ) : null}
      {addInvSection ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpWrapper>
              <PopUpButton className="close" onClick={() => { close(); setAddInvSection(false); }}>
                &times;
              </PopUpButton>
              <PopUpTextWrapper>
                Add New Inventory Section
              </PopUpTextWrapper>
              <Form
                name="addInvSectionForm"
                onSubmit={onCreateInventorySection}
                initialValues={null}
                render={({ handleSubmit, submitting }) => (
                  <StyledForm onSubmit={handleSubmit}>
                    <ErrorAlertContainer>
                      <ErrorAlert
                        errorMessage={invSectionErrorMessage}
                        show={invSectionErrorMessage !== null}
                      />
                    </ErrorAlertContainer>

                    <FieldFlex>
                      <StyledText style={{ width: 120 }}>Name: </StyledText>
                      <StyledField
                        name="name"
                        type="text"
                        component={FieldComponent}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <SubmitButton type="submit" disabled={submitting}> Save </SubmitButton>
                  </StyledForm>
                )}
              />
            </PopUpWrapper>
          )}
        </StyledPopup>
      ) : null}
      {addLibSection ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpWrapper>
              <PopUpButton className="close" onClick={() => { close(); setAddLibSection(false); }}>
                &times;
              </PopUpButton>
              <PopUpTextWrapper>
                Add New Library Section
              </PopUpTextWrapper>
              <Form
                name="AddLibSectionForm"
                onSubmit={onCreateLibrarySection}
                initialValues={null}
                render={({ handleSubmit, submitting }) => (
                  <StyledForm onSubmit={handleSubmit}>
                    <ErrorAlertContainer>
                      <ErrorAlert
                        errorMessage={libSectionErrorMessage}
                        show={libSectionErrorMessage !== null}
                      />
                    </ErrorAlertContainer>

                    <FieldFlex>
                      <StyledText style={{ width: 120 }}>Name: </StyledText>
                      <StyledField
                        name="name"
                        type="text"
                        component={FieldComponent}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <SubmitButton type="submit" disabled={submitting}> Save </SubmitButton>
                  </StyledForm>
                )}
              />
            </PopUpWrapper>
          )}
        </StyledPopup>
      ) : null}
      <Flex style={{ position: 'relative' }}>
        <HeaderText> Settings </HeaderText>
        <Flex>
          <ErrorAlert
            errorMessage={mainErrorMessage}
            show={mainErrorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw / 2)' }}
          />
        </Flex>
      </Flex>
      <MainWrapper>
        <SettingsWrapper>
          <Tabs activeKey={get(queryParams, ['where', 'tab'])} onChange={(tabValue) => onTabChange(tabValue)}>
            <TabPane tab="My Profile" key="My Profile">
              <FormWrapper style={{ overflow: 'auto' }}>
                <StyledFlex style={{ paddingTop: 0, height: 'auto' }}>
                  <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
                  <TopText> Basic Information </TopText>
                </StyledFlex>
                <Form
                  name="MyProfileForm"
                  onSubmit={onSubmit}
                  initialValues={initialValues}
                  initialValuesEqual={() => true}
                  render={({ handleSubmit, values, submitting }) => {
                    values.userId = user.id;

                    if (additionalContacts.length === 1) {
                      if (additionalContacts.includes(1)) {
                        values.contact2 = null;
                        values.relationship2 = null;
                        values.phoneNumber2 = null;
                        values.otherEmail2 = null;
                      } else if (additionalContacts.includes(2)) {
                        values.contact1 = null;
                        values.relationship1 = null;
                        values.phoneNumber1 = null;
                        values.otherEmail1 = null;
                      }
                    }

                    if (additionalContacts.length === 0) {
                      values.contact1 = null;
                      values.relationship1 = null;
                      values.phoneNumber1 = null;
                      values.otherEmail1 = null;
                      values.contact2 = null;
                      values.relationship2 = null;
                      values.phoneNumber2 = null;
                      values.otherEmail2 = null;
                    }

                    values.contactAmount = additionalContacts.length;
                    values.profileImage = image;
                    return (
                      <StyledForm onSubmit={handleSubmit}>
                        <BasicWrapper style={{ paddingRight: 10 }}>
                          <BasicInfoLeftWrapper>
                            <FieldFlex>
                              <StyledText>Account: </StyledText>
                              <StyledField
                                name="email"
                                type="text"
                                component={FieldComponent}
                                disabled
                                validate={validations.required && validations.email}
                              />
                            </FieldFlex>

                            <FieldFlex>
                              <StyledText>First Name: </StyledText>
                              <StyledField
                                name="firstName"
                                type="text"
                                component={FieldComponent}
                                validate={validations.required}
                              />
                            </FieldFlex>

                            <FieldFlex>
                              <StyledText>Last Name: </StyledText>
                              <StyledField
                                name="lastName"
                                type="text"
                                component={FieldComponent}
                              />
                            </FieldFlex>

                            <FieldFlex>
                              <StyledText>Notifications: </StyledText>
                              <StyledField
                                name="emailNotification"
                                type="select"
                                options={[{ label: 'On', value: true }, { label: 'Off', value: false }]}
                                component={FieldComponent}
                              />
                            </FieldFlex>

                            <FieldFlex>
                              <StyledText>Other Notified Emails: </StyledText>
                              <StyledField
                                name="notificationEmails"
                                type="text-list"
                                component={FieldComponent}
                                validate={(values) => {
                                  const result = (values || []).map((value) => {
                                    const error = validations.email(value);
                                    if (error) return `${error} - ${value}`;
                                  }).filter((v) => typeof v === 'string').join(', ');
                                  return result;
                                }}
                              />
                            </FieldFlex>

                            <FieldFlex>
                              <StyledText />
                              <Flex style={{ width: '100%' }}>
                                <ResetButton onClick={() => setResetClick(!resetClick)}>
                                  <div style={{ fontFamily: 'Avenir Book' }}>Reset Password</div>
                                  <Icon icon={resetClick ? 'reset-arrow-up' : 'reset-arrow-down'} height={12} style={{ marginLeft: 5 }} />
                                </ResetButton>
                              </Flex>
                            </FieldFlex>

                            { resetClick === true ? (
                              <div>
                                <FieldFlex>
                                  <StyledText>New Password: </StyledText>
                                  <StyledField
                                    name="password"
                                    type="password"
                                    component={FieldComponent}
                                    validate={validations.required && validations.password}
                                  />
                                </FieldFlex>

                                <FieldFlex>
                                  <StyledText>Confirm Password: </StyledText>
                                  <StyledField
                                    name="confirmPassword"
                                    type="password"
                                    component={FieldComponent}
                                    validate={validations.required && validations.password}
                                  />
                                </FieldFlex>
                              </div>
                            ) : null}
                          </BasicInfoLeftWrapper>

                          <BasicInfoRightWrapper>
                            <FieldFlex style={{ alignItems: 'normal' }}>
                              <TextNoteText style={{ width: 100, paddingBottom: 6 }}>Photo: </TextNoteText>
                              <ImageUpload image={image} setImage={setImage} existingImage={user.profileImage} endpoint="settings" />
                            </FieldFlex>
                          </BasicInfoRightWrapper>
                        </BasicWrapper>

                        <Flex direction="column">
                          <StyledFlex>
                            <Icon icon="contact-info" height={20} style={{ marginRight: 5 }} />
                            <TopText> Contact Information (optional)</TopText>
                          </StyledFlex>
                          <BasicWrapper style={{ paddingRight: 10 }}>
                            <BasicInfoLeftWrapper>
                              <FieldFlex>
                                <StyledText>Main contact: </StyledText>
                                <StyledField
                                  name="contact"
                                  type="text"
                                  component={FieldComponent}
                                />
                              </FieldFlex>

                              { user.role === 'parent' ? (
                                <FieldFlex>
                                  <StyledText style={{ lineHeight: '18px' }}>Relationship to student: </StyledText>
                                  <StyledField
                                    name="relationship"
                                    type="text"
                                    component={FieldComponent}
                                  />
                                </FieldFlex>
                              ) : null}

                              <FieldFlex>
                                <StyledText>Phone Number: </StyledText>
                                <StyledField
                                  name="phoneNumber"
                                  type="text"
                                  component={FieldComponent}
                                />
                              </FieldFlex>

                              <FieldFlex>
                                <StyledText>Email: </StyledText>
                                <StyledField
                                  name="otherEmail"
                                  type="text"
                                  component={FieldComponent}
                                  validate={validations.multipleEmail}
                                />
                              </FieldFlex>

                              { user.role === 'parent' ? (
                                <div>
                                  <FieldFlex>
                                    <StyledText>Address: </StyledText>
                                    <StyledField
                                      name="address"
                                      type="text"
                                      component={FieldComponent}
                                    />
                                  </FieldFlex>

                                  <SaveButtonWrapper>
                                    <StyledText />
                                    <Flex style={{ width: '100%' }}>
                                      <StyledButton type="submit" disabled={submitting}>
                                        Save
                                      </StyledButton>
                                    </Flex>
                                  </SaveButtonWrapper>
                                </div>
                              ) : null }
                            </BasicInfoLeftWrapper>

                            { user.role === 'parent' ? (
                              <BasicInfoRightWrapper>
                                { additionalContacts.length > 0 ? (
                                  additionalContacts.map((c) => (
                                    <Flex direction="column" key={c}>
                                      <FieldFlex>
                                        <StyledText>Contact: </StyledText>
                                        <StyledField
                                          name={`contact${c}`}
                                          type="text"
                                          component={FieldComponent}
                                        />
                                      </FieldFlex>

                                      <FieldFlex>
                                        <StyledText>Relationship to student: </StyledText>
                                        <StyledField
                                          name={`relationship${c}`}
                                          type="text"
                                          component={FieldComponent}
                                        />
                                      </FieldFlex>

                                      <FieldFlex>
                                        <StyledText>Phone Number: </StyledText>
                                        <StyledField
                                          name={`phoneNumber${c}`}
                                          type="text"
                                          component={FieldComponent}
                                        />
                                      </FieldFlex>

                                      <FieldFlex>
                                        <StyledText>Email: </StyledText>
                                        <StyledField
                                          name={`otherEmail${c}`}
                                          type="text"
                                          component={FieldComponent}
                                          validate={validations.multipleEmail}
                                        />
                                      </FieldFlex>

                                      <DashedButton onClick={() => setAdditionalContacts(additionalContacts.filter((i) => i !== c))}>
                                        <Flex style={{
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                        >
                                          <Icon icon="remove-contact" height={19} />
                                          <StyledText style={{
                                            padding: 0,
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            marginLeft: 10,
                                            paddingTop: 1,
                                            width: 'auto',
                                          }}
                                          >Remove Contact
                                          </StyledText>
                                        </Flex>
                                      </DashedButton>
                                    </Flex>
                                  ))
                                ) : null}
                                <DashedButton
                                  onClick={() => setAdditionalContacts([
                                    ...additionalContacts,
                                    getNumber(additionalContacts),
                                  ])}
                                  disabled={additionalContacts.length >= 2}
                                >
                                  <Flex style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                  >
                                    <Icon icon="add-contact" height={19} />
                                    <StyledText style={{
                                      padding: 0,
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      marginLeft: 10,
                                      paddingTop: 1,
                                      width: 'auto',
                                    }}
                                    >Add New Contact
                                    </StyledText>
                                  </Flex>
                                </DashedButton>
                              </BasicInfoRightWrapper>
                            ) : null}
                          </BasicWrapper>

                          { (user.role === 'admin' || user.role === 'superAdmin' || user.role === 'instructor') ? (
                            <Flex direction="column">
                              <StyledFlex>
                                <Icon icon="other-info" height={20} style={{ marginRight: 5 }} />
                                <TopText> Other Information (optional)</TopText>
                              </StyledFlex>
                              <BasicWrapper>
                                <BasicInfoLeftWrapper>
                                  <FieldFlex>
                                    <StyledText>Address: </StyledText>
                                    <StyledField
                                      name="address"
                                      type="text"
                                      component={FieldComponent}
                                    />
                                  </FieldFlex>

                                  <FieldFlex>
                                    <StyledText>Education: </StyledText>
                                    <StyledField
                                      name="education"
                                      type="text"
                                      component={FieldComponent}
                                    />
                                  </FieldFlex>

                                  <Flex>
                                    <StyledText />
                                    <Flex style={{ width: '100%' }}>
                                      <StyledButton type="submit" disabled={submitting}>
                                        Save
                                      </StyledButton>
                                    </Flex>
                                  </Flex>
                                </BasicInfoLeftWrapper>
                              </BasicWrapper>
                            </Flex>
                          ) : null}

                          { user.role === 'parent' ? (
                            <MobileSaveButtonWrapper>
                              <StyledText />
                              <Flex style={{ width: '100%' }}>
                                <StyledButton type="submit" disabled={submitting}>
                                  Save
                                </StyledButton>
                              </Flex>
                            </MobileSaveButtonWrapper>
                          ) : null }
                        </Flex>
                      </StyledForm>
                    )
                  }}
                />
              </FormWrapper>
            </TabPane>
            { user.role === 'superAdmin' ? (
              <TabPane tab="Business Profile" key="Business Profile">
                <FormWrapper style={{ overflow: 'auto' }}>
                  <StyledFlex style={{ paddingTop: 0, height: 'auto' }}>
                    <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
                    <TopText> Business Information </TopText>
                  </StyledFlex>
                  <Form
                    name="businessProfileForm"
                    onSubmit={onSubmit}
                    initialValues={{
                      name: get(user, 'businessProfile.name', null),
                      address: get(user, 'businessProfile.address', null),
                      district: get(user, 'businessProfile.district', null),
                      description: get(user, 'businessProfile.description', null),
                    }}
                    initialValuesEqual={() => true}
                    render={({ handleSubmit, submitting }) => (
                      <StyledForm onSubmit={handleSubmit}>
                        <SettingsFormWrapper style={{ paddingRight: 10 }}>
                          <FieldFlex>
                            <StyledText>Company Name: </StyledText>
                            <StyledField
                              name="name"
                              type="text"
                              component={FieldComponent}
                              validate={validations.required}
                            />
                          </FieldFlex>

                          <FieldFlex>
                            <StyledText>Address: </StyledText>
                            <StyledField
                              name="address"
                              type="text"
                              component={FieldComponent}
                              validate={validations.required}
                            />
                          </FieldFlex>

                          <FieldFlex>
                            <StyledText>District: </StyledText>
                            <StyledField
                              name="district"
                              type="text"
                              component={FieldComponent}
                              validate={validations.required}
                            />
                          </FieldFlex>
                        </SettingsFormWrapper>

                        <Flex direction="column">
                          <StyledFlex>
                            <Icon icon="contact-info" height={20} style={{ marginRight: 5 }} />
                            <TopText> Payment Information </TopText>
                          </StyledFlex>
                          <SettingsFormWrapper style={{ paddingRight: 10 }}>
                            <FieldFlex style={{ alignItems: 'normal' }}>
                              <TextNoteText>Description (Invoice): </TextNoteText>
                              <StyledField
                                name="description"
                                type="textarea"
                                component={FieldComponent}
                                validate={validations.required}
                              />
                            </FieldFlex>

                            <FieldFlex>
                              <StyledText />
                              <Flex style={{ width: '100%' }}>
                                <StyledButton type="submit" disabled={submitting} style={{ marginTop: 20 }}>
                                  Save
                                </StyledButton>
                              </Flex>
                            </FieldFlex>
                          </SettingsFormWrapper>
                        </Flex>
                      </StyledForm>
                    )}
                  />
                </FormWrapper>
              </TabPane>
            ) : null}

            { user.role === 'superAdmin' ? (
              <TabPane tab="Lesson Type" key="Lesson Type">
                <FormWrapper noTablePadding>
                  <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Lesson Types" />}>
                    <AntDTable
                      cellRenderers={cellRenderers}
                      scroll={{ x: 'max-content', y: '65vh' }}
                      data={data}
                      rowKey="id"
                    />
                  </ConfigProvider>
                </FormWrapper>
              </TabPane>
            ) : null }

            { (user.role === 'admin' || user.role === 'superAdmin') ? (
              <TabPane tab="Rooms" key="Rooms">
                <ModelContainer divHeight={divHeight}>
                  <ModelPage
                    showHeader={false}
                    modelName="room"
                    itemsPerPage={25}
                    actions={[{
                      action: () => setAddRoom(true),
                      label: 'Create New',
                    }]}
                    render={(renderProps) => {
                      const { height } = renderProps;
                      if (divHeight !== height) setDivHeight(height);
                      return (
                        <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Rooms" />}>
                          <AntDTable
                            modelName="room"
                            cellRenderers={tableColumns}
                            scroll={{ x: 'max-content', y: '65vh' }}
                            pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                            rowKey="id"
                            {...renderProps}
                          />
                        </ConfigProvider>
                      )
                    }}
                  />
                </ModelContainer>
              </TabPane>
            ) : null }

            { (user.role === 'admin' || user.role === 'superAdmin') ? (
              <TabPane tab="Groups" key="Groups">
                <ModelContainer divHeight={groupDivHeight}>
                  <ModelPage
                    showHeader={false}
                    modelName="group"
                    itemsPerPage={25}
                    actions={[{
                      action: () => setAddGroup(true),
                      label: 'Create New',
                    }]}
                    render={(renderProps) => {
                      const { height } = renderProps;
                      if (groupDivHeight !== height) setGroupDivHeight(height);
                      return (
                        <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Groups" />}>
                          <AntDTable
                            modelName="group"
                            cellRenderers={groupColumns}
                            scroll={{ x: 'max-content', y: '65vh' }}
                            pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                            rowKey="id"
                            {...renderProps}
                          />
                        </ConfigProvider>
                      )
                    }}
                  />
                </ModelContainer>
              </TabPane>
            ) : null }

            { (user.role === 'admin' || user.role === 'superAdmin') ? (
              <TabPane tab="Inventory Sections" key="Inventory Sections">
                <ModelContainer divHeight={invSectionDivHeight}>
                  <ModelPage
                    showHeader={false}
                    modelName="inventorySection"
                    itemsPerPage={25}
                    actions={[{
                      action: () => setAddInvSection(true),
                      label: 'Create New',
                    }]}
                    render={(renderProps) => {
                      const { height } = renderProps;
                      if (invSectionDivHeight !== height) setInvSectionDivHeight(height);
                      return (
                        <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Sections" />}>
                          <AntDTable
                            modelName="inventorySection"
                            cellRenderers={tableColumns}
                            scroll={{ x: 'max-content', y: '65vh' }}
                            pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                            rowKey="id"
                            {...renderProps}
                          />
                        </ConfigProvider>
                      )
                    }}
                  />
                </ModelContainer>
              </TabPane>
            ) : null }

            { (user.role === 'admin' || user.role === 'superAdmin') ? (
              <TabPane tab="Library Sections" key="Library Sections">
                <ModelContainer divHeight={libSectionDivHeight}>
                  <ModelPage
                    showHeader={false}
                    modelName="librarySection"
                    itemsPerPage={25}
                    actions={[{
                      action: () => setAddLibSection(true),
                      label: 'Create New',
                    }]}
                    render={(renderProps) => {
                      const { height } = renderProps;
                      if (libSectionDivHeight !== height) setLibSectionDivHeight(height);
                      return (
                        <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Sections" />}>
                          <AntDTable
                            modelName="librarySection"
                            cellRenderers={tableColumns}
                            scroll={{ x: 'max-content', y: '65vh' }}
                            pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                            rowKey="id"
                            {...renderProps}
                          />
                        </ConfigProvider>
                      )
                    }}
                  />
                </ModelContainer>
              </TabPane>
            ) : null }
          </Tabs>
        </SettingsWrapper>
      </MainWrapper>
    </Wrapper>
  );
};

EditView.propTypes = {
  authenticated: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps)(EditView);
