import { compose, withHandlers } from 'recompose';
import { toast } from 'react-toastify';
import { css } from 'glamor';

import theme from '../../theme.js';

const getToastType = (type) => {
  let toastType;
  switch (type) {
    case 'success':
      toastType = toast.TYPE.SUCCESS;
      break;
    case 'warning':
      toastType = toast.TYPE.WARNING;
      break;
    case 'info':
      toastType = toast.TYPE.INFO;
      break;
    case 'error':
      toastType = toast.TYPE.ERROR;
      break;
    default:
      toastType = toast.TYPE.DEFAULT;
  }
  return toastType;
};

const getToastColor = (type) => {
  let toastColor;
  switch (type) {
    case 'success':
      toastColor = theme.palette.success[4];
      break;
    case 'warning':
      toastColor = theme.palette.warning[4];
      break;
    case 'info':
      toastColor = '#1a5e99';
      break;
    case 'error':
      toastColor = theme.palette.red[4];
      break;
    default:
      toastColor = theme.palette.success[4];
  }
  return toastColor;
};

const showToast = () => ({ type, message, autoClose = false, className, ...options }) => {
  return toast(message, {
    autoClose,
    hideProgressBar: true,
    type: getToastType(type),
    className: css({
      background: getToastColor(type),
      borderRadius: '4px',
      textAlign: 'center',
      ...className,
    }),
    ...options,
  });
};
const enhance = compose(
  withHandlers({
    showToast,
    updateToast: () => (targetToast, { message, type, className, ...options }) => {
      toast.update(targetToast, {
        render: message,
        type: getToastType(type),
        autoClose: 2000,
        className: css({
          background: getToastColor(type),
          borderRadius: '4px',
          textAlign: 'center',
          ...className,
        }),
        ...options,
      });
    },
    dismissToast: () => (targetToast) => {
      toast.dismiss(targetToast);
    },
  })
);

export default enhance;
export { showToast };
