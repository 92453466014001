import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isModalOpen } from '../store/modal/selectors';
import { modalHide } from '../store/modal/actions';
import Modal from '../components/molecules/Modal';

const ModalContainer = props => <Modal {...props} />;

ModalContainer.defaultProps = {
  isOpen: false,
};

ModalContainer.propTypes = {
  name: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};

const mapStateToProps = (state, { name, isOpen }) => {
  return {
    isOpen: isOpen || isModalOpen(state.modal, name),
  };
};

const mapDispatchToProps = (dispatch, { name }) => ({
  onClose: () => dispatch(modalHide(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
