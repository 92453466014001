import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Flex from '../../atoms/Flex';
import FieldComponent from '../../molecules/FieldComponent';
import { validations } from '../../../utils/form';
import useUserForm from '../../../hooks/useUserForm';
import ErrorAlert from '../../molecules/ErrorAlert';

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 35vw;
  padding: 20px;

  > form {
    height: calc(100% - 86px);
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 150px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 12px;
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const StyledButton = styled(Button)`
  min-width: 160px;
  width: 100%;
  margin: ${size('margin.default')} auto ${size('margin.default')};
  border-radius: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
`;

const ErrorAlertContainer = styled.div`
  > div {
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: 75vw;
      width: fit-content;
    }
  }
`;

const PopUpAddChild = ({ open, userId, students, onClose }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const { onSubmit } = useUserForm({
    endpoint: `user/${userId}/addChild`,
    setErrorMessage,
    type: 'parent',
    action: 'update',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const sortedStudents = students.map((c) => {
    c.label = `${c.firstName} ${c.lastName} (ST${c.id})`;
    c.value = c.id;
    return c;
  }).sort((a, b) => (a.label > b.label ? 1 : -1));

  return (
    <StyledPopup
      open={open}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          <PopUpButton className="close" onClick={() => { close(); onClose(); }}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper> Add Student </PopUpTextWrapper>

          <Form
            name="AddChildForm"
            onSubmit={onSubmit}
            render={({ handleSubmit, values, submitting }) => {
              values.userId = userId;

              return (
                <StyledForm onSubmit={handleSubmit}>
                  <div style={{ maxHeight: '100%', overflow: 'auto' }}>
                    <ErrorAlertContainer>
                      <ErrorAlert
                        errorMessage={errorMessage}
                        show={errorMessage !== null}
                      />
                    </ErrorAlertContainer>

                    <FieldFlex>
                      <StyledText>Student: </StyledText>
                      <StyledField
                        name="student"
                        type="select"
                        component={FieldComponent}
                        options={sortedStudents}
                        validate={validations.required}
                        menuPortalTarget={document.getElementById('popup-root')}
                        menuPlacement="auto"
                      />
                    </FieldFlex>
                  </div>

                  <StyledButton type="submit" disabled={submitting}> Save </StyledButton>
                </StyledForm>
              )
            }}
          />
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpAddChild.propTypes = {
  open: PropTypes.bool.isRequired,
  students: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopUpAddChild;
