import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import { ConfigProvider, Empty } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import get from 'lodash/get';
import P from '../../../components/atoms/P';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Flex from '../../../components/atoms/Flex';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import Button from '../../../components/atoms/Button';
import PopUpDelete from '../../../components/organisms/PopUpDelete';

const Wrapper = styled.div`
  padding-bottom: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
    height: 100%;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};
  min-width: 200px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const ModelContainer = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
  > div {
    @media (max-width: ${size('mobileBreakpoint')}) {
      height: 100%;
    }
    > div: first-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        max-height: ${(props) => (props.divHeight ? 'none' : '56px')}
      }
    }
    > div: last-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        height: calc(100% - 56px);
        height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')}
      }
      > div {
        @media (max-width: ${size('mobileBreakpoint')}) {
          height: 100%;
        }
        > div {
          @media (max-width: ${size('mobileBreakpoint')}) {
            height: 100%;
          }
          > div: last-child {
            @media (max-width: ${size('mobileBreakpoint')}) {
              height: 100%;
            }
            > div {
              @media (max-width: ${size('mobileBreakpoint')}) {
                height: calc(100% - 40px);
              }
              > div {
                @media (max-width: ${size('mobileBreakpoint')}) {
                  height: 100%;
                }
                > div: last-child {
                  max-height: 100% !important;
                  @media (max-width: ${size('mobileBreakpoint')}) {
                    max-height: calc(100% - 55px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const StyledLink = styled(Link)`
  color: #3E90F7;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-family: ${font('secondary')};

  &:hover,
  &:focus,
  &:active {
    color: #3E90F7;
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const modelName = 'broadcast';

const itemsPerPage = 25;

const ListView = (props) => {
  const { user, location } = props;
  const [divHeight, setDivHeight] = useState(0);

  const cellRenderers = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description) => description,
      className: 'broadcastDescription',
      width: 600,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date) => <Cell>{moment(date).format('DD/MM/YYYY')}</Cell>,
      width: 100,
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date) => <Cell>{moment(date).format('DD/MM/YYYY')}</Cell>,
      width: 100,
    },
    {
      title: 'Send to',
      dataIndex: 'sendToText',
      key: 'sendToText',
      width: 90,
      render: (content) => <Cell>{content || 'N/A'}</Cell>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Cell>
          <Flex>
            <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
              <StyledLink to={{ pathname: `broadcast/edit/${record.id}`, state: { prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` } }}>Edit</StyledLink>
            </div>
            <div style={{ paddingLeft: 5 }}>
              <PopUpDelete user={user} endpoint="broadcast" id={record.id} type="Broadcast" />
            </div>
          </Flex>
        </Cell>
      ),
      width: 90,
    },
  ];

  const filterConfig = [
    {
      type: 'dateRange',
      name: 'startDate',
      label: 'Start Date',
      showTime: false,
    },
    {
      type: 'dateRange',
      name: 'endDate',
      label: 'End Date',
      showTime: false,
    },
  ];

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderText> Broadcast </HeaderText>
      </Flex>
      <ModelContainer divHeight={divHeight}>
        <ModelPage
          showHeader={false}
          modelName={modelName}
          itemsPerPage={itemsPerPage}
          allowSearch
          filterConfig={filterConfig}
          query={{}}
          initialQuery={{
            currentPage: 1,
          }}
          showCreateButton
          createState={{ prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` }}
          {...props}
          render={(renderProps) => {
            const { height } = renderProps;
            if (divHeight !== height) setDivHeight(height);
            return (
              <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Broadcast" />}>
                <AntDTable
                  modelName={modelName}
                  cellRenderers={cellRenderers}
                  scroll={{ x: 'max-content', y: '65vh' }}
                  pagination={{ pageSize: itemsPerPage, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                  rowKey="id"
                  {...renderProps}
                />
              </ConfigProvider>
            )
          }}
        />
      </ModelContainer>
    </Wrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
