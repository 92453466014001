import { useState, useCallback, useEffect } from 'react';

export default (options = {}) => {
  const { userId = null, query = {}, initialValues = {} } = options;
  const {
    loading: initialLoading = true,
    error: initialError = null,
    data: initialData,
  } = initialValues;
  const [loading, setLoading] = useState(initialLoading);
  const [error, setError] = useState(initialError);
  const [data, setData] = useState(initialData);
  const fetchEventData = useCallback(async () => {
    // console.log('sending request with this query:', query)
    const { api } = global;
    const promise = Promise.all([api.get(`/notification/latest`, { params: query })]);
    setLoading(true);
    return promise
      .then(([response]) => {
        setData(response.data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [query]);

  useEffect(() => {
    fetchEventData();
  }, [userId]);
  console.log(data);
  return { loading, error, data, fetchEventData, setLoading, setError, setData };
};
