import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, palette, size } from 'styled-theme';
import LinesEllipsis from 'react-lines-ellipsis';
import { ifProp } from 'styled-tools';
import Dropzone from 'react-dropzone';
import Spinner from '../../atoms/Spinner';
import Card from '../../atoms/Card';
import Icon from '../../atoms/Icon';

import Button from '../../atoms/Button';

const Wrapper = styled.div`
  width: ${ifProp('small', '150px', '200px')};
  margin: ${ifProp('small', '12px', '24px')};
`;

const ImgContainer = styled.div`
  display: block;
  height: ${ifProp('small', '150px', '200px')};
  width: 100%;
  background-image: ${({ src }) => `url('${src}')`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const SelectedFileTextContainer = styled.div`
  color: ${palette('black', 0)};
  position: relative;
`;

const PreviewWrapper = styled(Card)`
  border-radius: 0;
  font-size: 14px;
  font-family: ${font('primary')};
  color: ${palette('grayscale', 3)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  padding: 0;
`;

const UploadingMaskWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const ImageMask = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 0.5;
`;

const CenterWrapper = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`;

const SmallSpinnerWrapper = styled.div`
  height: 16px;
  width: 24px;
  display: flex;
`;

const DescriptionWrapper = styled.div`
  margin: 0;
  padding: ${size('padding.tb')};
  font-size: ${size('fontSize.body.b2')};
  line-height: ${size('lineHeight.body.b2')};
  min-height: calc(${size('lineHeight.body.b2')} * 2 + ${size('fontSize.body.b2')} * 2);
  border-bottom: 1px ${palette('primary', 0)} solid;
  color: ${palette('primary', 0)};
  margin-bottom: 16px;
`;

const ThumbnailFlag = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  color: ${palette('primary', 0)};
`;

const ReSelectMaskWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;

  &:hover {
    opacity: 100;
  }
`;

const ReSelectButton = styled(Button)`
  margin: 0;
`;
const ReSelectDropzone = styled.div`
  width: 100%;
  height: 100%;
  border: none;
`;

const checkIsImage = (fileName) => {
  let fileType = fileName.split('.').pop();
  if (fileType.includes('?')) {
    fileType = fileType.split('?').shift();
  }
  return ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG'].includes(fileType);
};

const File = ({
  accept,
  file,
  margin,
  width,
  height,
  handleDeleteFile,
  uploading,
  isThumbnail,
  handleUploadFile,
  showImagePreview,
  ...props
}) => {
  if (file.src) {
    const fileName = file.key || file.src;
    const isImage = checkIsImage(fileName);

    if (isImage && showImagePreview) {
      return (
        <Wrapper {...props}>
          <PreviewWrapper style={{ margin, width, height }}>
            <ImgContainer {...file} {...props} />
            {isThumbnail && (
              <ThumbnailFlag>
                <Icon icon="flag" />
              </ThumbnailFlag>
            )}
            {uploading ? (
              <UploadingMaskWrapper>
                <ImageMask />
                <CenterWrapper>
                  <Spinner />
                </CenterWrapper>
              </UploadingMaskWrapper>
            ) : (
              <ReSelectMaskWrapper>
                <Dropzone
                  accept={accept}
                  onDrop={(files) => handleUploadFile(files[0], file)}
                  multiple={false}
                >
                  {({ getInputProps, getRootProps }) => (
                    <ReSelectDropzone {...getRootProps()}>
                      <input {...getInputProps()} />
                      <ImageMask />
                      <CenterWrapper>
                        <ReSelectButton>Re-select File</ReSelectButton>
                      </CenterWrapper>
                    </ReSelectDropzone>
                  )}
                </Dropzone>
              </ReSelectMaskWrapper>
            )}
          </PreviewWrapper>
          <React.Fragment>
            {/*
              <DescriptionWrapper>
                <LinesEllipsis text={`File Name: ${uploading ? '- - -' : fileName}`} maxLine={1} trimRight basedOn='letters' ellipsis=". . ." />
              </DescriptionWrapper>
            */}
            <Button style={{ marginTop: 8 }} onClick={() => handleDeleteFile(file)}>Remove Photo</Button>
          </React.Fragment>
        </Wrapper>
      );
    }
    return (
      <SelectedFileTextContainer>
        <Wrapper style={{ margin: 0, width: '100%' }}>
          {uploading ? (
            <SmallSpinnerWrapper>
              <Spinner />
              Uploading...
            </SmallSpinnerWrapper>
          ) : (
            <React.Fragment>
              <b>Selected :</b> {fileName}
              <Button onClick={() => handleDeleteFile(file)} style={{ marginTop: 15, marginBottom: 0 }}>Remove</Button>
            </React.Fragment>
          )}
        </Wrapper>
      </SelectedFileTextContainer>
    );
  }
  return null;
};

File.propTypes = {
  file: PropTypes.object,
  margin: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  handleDeleteFile: PropTypes.func,
  handleUploadFile: PropTypes.func,
  uploading: PropTypes.bool,
  isThumbnail: PropTypes.bool,
  isSmall: PropTypes.bool,
};

export default File;
