const inputOptions = {
  privateLessonOptions: [
    {
      label: 'A-Level Music',
      value: 'A-Level Music',
    },
    {
      label: 'Accompaniment',
      value: 'Accompaniment',
    },
    {
      label: 'Aural Test',
      value: 'Aural Test',
    },
    {
      label: 'Cello',
      value: 'Cello',
    },
    {
      label: 'Clarinet',
      value: 'Clarinet',
    },
    {
      label: 'Composition',
      value: 'Composition',
    },
    {
      label: 'Flute',
      value: 'Flute',
    },
    {
      label: 'GCSE Music',
      value: 'GCSE Music',
    },
    {
      label: 'Guitar',
      value: 'Guitar',
    },
    {
      label: 'IB Music',
      value: 'IB Music',
    },
    {
      label: 'Piano',
      value: 'Piano',
    },
    {
      label: 'Recorder',
      value: 'Recorder',
    },
    {
      label: 'Saxophone',
      value: 'Saxophone',
    },
    {
      label: 'Scales',
      value: 'Scales',
    },
    {
      label: 'Sight-reading',
      value: 'Sight-reading',
    },
    {
      label: 'Theory',
      value: 'Theory',
    },
    {
      label: 'Trumpet',
      value: 'Trumpet',
    },
    {
      label: 'Ukulele',
      value: 'Ukulele',
    },
    {
      label: 'Viola',
      value: 'Viola',
    },
    {
      label: 'Violin',
      value: 'Violin',
    },
    {
      label: 'Voice',
      value: 'Voice',
    },
  ],
  groupLessonOptions: [
    {
      label: 'Leaves',
      value: 'Leaves',
    },
    {
      label: 'Mini VocalMINTS',
      value: 'Mini VocalMINTS',
    },
    {
      label: 'Seedlings',
      value: 'Seedlings',
    },
    {
      label: 'Seeds',
      value: 'Seeds',
    },
    {
      label: 'Sprouts',
      value: 'Sprouts',
    },
    {
      label: 'VocalMINTS',
      value: 'VocalMINTS',
    },
  ],
  lessonTypeOptions: [
    {
      label: 'Private Lesson',
      value: 'Private Lesson',
    },
    {
      label: 'Semiprivate Lesson',
      value: 'Semiprivate Lesson',
    },
    {
      label: 'Group Lesson',
      value: 'Group Lesson',
    },
  ],
  durationOptions: [
    {
      label: '15 mins',
      value: '15 mins',
    },
    {
      label: '30 mins',
      value: '30 mins',
    },
    {
      label: '45 mins',
      value: '45 mins',
    },
    {
      label: '60 mins',
      value: '60 mins',
    },
    {
      label: '75 mins',
      value: '75 mins',
    },
    {
      label: '90 mins',
      value: '90 mins',
    },
    {
      label: '105 mins',
      value: '105 mins',
    },
    {
      label: '120 mins',
      value: '120 mins',
    },
  ],
  recurrenceOptions: [
    {
      label: 'Does not repeat',
      value: 'Does not repeat',
    },
    {
      label: 'Daily',
      value: 'Daily',
    },
    {
      label: 'Weekly',
      value: 'Weekly',
    },
    {
      label: 'Every 2 weeks',
      value: 'Every 2 weeks',
    },
    {
      label: 'Monthly',
      value: 'Monthly',
    },
    {
      label: 'Annually',
      value: 'Annually',
    },
  ],
  typeOptions: [
    {
      label: 'One-off',
      value: 'One-off',
    },
    {
      label: 'Permanent',
      value: 'Permanent',
    },
  ],
  reasonOptions: [
    {
      label: 'Sickness',
      value: 'Sickness',
    },
    {
      label: 'Other Reason',
      value: 'Other Reason',
    },
  ],
  itemTypeOptions: [
    {
      label: 'Book',
      value: 'Book',
    },
    {
      label: 'Instrument',
      value: 'Instrument',
    },
    {
      label: 'Accessory',
      value: 'Accessory',
    },
  ],
  genderOptions: [
    {
      label: 'Male',
      value: 'Male',
    },
    {
      label: 'Female',
      value: 'Female',
    },
  ],
  staffRoleOptions: [
    {
      label: 'Instructor',
      value: 'instructor',
    },
    {
      label: 'Admin',
      value: 'admin',
    },
    {
      label: 'Super Admin',
      value: 'superAdmin',
    },
    {
      label: 'Library Helper',
      value: 'libraryHelper',
    },
  ],
  studentStatusOptions: [
    {
      label: 'Current',
      value: 'Current',
    },
    {
      label: 'Inactive',
      value: 'Inactive',
    },
    {
      label: 'Past',
      value: 'Past',
    },
  ],
  packageTypeOptions: [
    {
      label: 'Private / Semiprivate Package',
      value: 'Private / Semiprivate Package',
    },
    {
      label: 'Group Package',
      value: 'Group Package',
    },
    {
      label: 'Trial Package',
      value: 'Trial Package',
    },
  ],
  packageValueOptions: [
    {
      label: '$5,000',
      value: '5000',
    },
    {
      label: '$10,000',
      value: '10000',
    },
    {
      label: '$20,000',
      value: '20000',
    },
    {
      label: '$50,000',
      value: '50000',
    },
    {
      label: '$100,000',
      value: '100000',
    },
    {
      label: 'Custom',
      value: 'Custom',
    },
  ],
  otherDiscountOptions: [
    {
      label: 'Friends & Family Discount - 10%',
      value: 10,
    },
    {
      label: 'Loyalty Discount - 5%',
      value: 5,
    },
  ],
  paymentMethodOptions: [
    {
      label: 'Credit Card',
      value: 'Credit Card',
    },
    {
      label: 'Bank Transfer',
      value: 'Bank Transfer',
    },
    {
      label: 'Cheque',
      value: 'Cheque',
    },
    {
      label: 'Cash',
      value: 'Cash',
    },
    {
      label: 'Credit Transfer',
      value: 'Credit Transfer',
    },
  ],
  extendedLeaveReason: [
    {
      label: 'Travel',
      value: 'Travel',
    },
    {
      label: 'Study Abroad',
      value: 'Study Abroad',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ],
  weekDays: [
    {
      label: 'Monday',
      value: 1,
    },
    {
      label: 'Tuesday',
      value: 2,
    },
    {
      label: 'Wednesday',
      value: 3,
    },
    {
      label: 'Thursday',
      value: 4,
    },
    {
      label: 'Friday',
      value: 5,
    },
    {
      label: 'Saturday',
      value: 6,
    },
    {
      label: 'Sunday',
      value: 7,
    },
  ],
  lessonStatus: [
    {
      label: 'Active',
      value: 'Active',
    },
    {
      label: 'Inactive',
      value: 'Inactive',
    },
    {
      label: 'Sub-in',
      value: 'Sub-in',
    },
    {
      label: 'One-time Trial',
      value: 'One-time Trial',
    },
    {
      label: 'Ex',
      value: 'Ex',
    },
  ],
  valueTypeOptions: [
    {
      label: 'Credit',
      value: 'Credit',
    },
    {
      label: 'Hourly',
      value: 'Hourly',
    },
  ],
  hourOptions: [
    {
      label: '5-hour',
      value: 5,
    },
    {
      label: '10-hour',
      value: 10,
    },
    {
      label: '20-hour',
      value: 20,
    },
    {
      label: '50-hour',
      value: 50,
    },
    {
      label: 'Custom (hours)',
      value: 'Custom (hours)',
    },
  ],
  minsOptions: [
    {
      label: '30-minute',
      value: 30,
    },
    {
      label: '45-minute',
      value: 45,
    },
    {
      label: '60-minute',
      value: 60,
    },
    {
      label: 'Custom (mins)',
      value: 'Custom (mins)',
    },
  ],
  creditTypeOptions: [
    {
      label: 'Lesson',
      value: 'Lesson',
    },
    {
      label: 'Purchase',
      value: 'Purchase',
    },
    {
      label: 'Sell',
      value: 'Sell',
    },
    {
      label: 'Credit Transfer',
      value: 'Credit Transfer',
    },
    {
      label: 'Credit Deduct',
      value: 'Credit Deduct',
    },
    {
      label: 'Refund',
      value: 'Refund',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ],
  libraryTypeOptions: [
    {
      label: 'Accessory',
      value: 'Accessory',
    },
    {
      label: 'Book',
      value: 'Book',
    },
    {
      label: 'Instrument',
      value: 'Instrument',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ],
  inventoryTypeOptions: [
    {
      label: 'Accessory',
      value: 'Accessory',
    },
    {
      label: 'Book',
      value: 'Book',
    },
    {
      label: 'Fee',
      value: 'Fee',
    },
    {
      label: 'Instrument',
      value: 'Instrument',
    },
    {
      label: 'Ticket',
      value: 'Ticket',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ],
  librarySectionOptions: [
    {
      label: 'Academic Books',
      value: 'Academic Books',
    },
    {
      label: 'Archived Books',
      value: 'Archived Books',
    },
    {
      label: 'Aural',
      value: 'Aural',
    },
    {
      label: 'Brass',
      value: 'Brass',
    },
    {
      label: 'Cello',
      value: 'Cello',
    },
    {
      label: 'Chamber',
      value: 'Chamber',
    },
    {
      label: 'Children\'s Books',
      value: 'Children\'s Books',
    },
    {
      label: 'Christmas Songs',
      value: 'Christmas Songs',
    },
    {
      label: 'Clarinet',
      value: 'Clarinet',
    },
    {
      label: 'Flute',
      value: 'Flute',
    },
    {
      label: 'Guitar',
      value: 'Guitar',
    },
    {
      label: 'Oboe',
      value: 'Oboe',
    },
    {
      label: 'Piano Beginner',
      value: 'Piano Beginner',
    },
    {
      label: 'Piano Duets',
      value: 'Piano Duets',
    },
    {
      label: 'Piano Exam',
      value: 'Piano Exam',
    },
    {
      label: 'Piano Finger Exercises',
      value: 'Piano Finger Exercises',
    },
    {
      label: 'Piano General',
      value: 'Piano General',
    },
    {
      label: 'Piano Jazz',
      value: 'Piano Jazz',
    },
    {
      label: 'Piano Pop',
      value: 'Piano Pop',
    },
    {
      label: 'Piano Sight-Reading',
      value: 'Piano Sight-Reading',
    },
    {
      label: 'Saxophone',
      value: 'Saxophone',
    },
    {
      label: 'Teaching Resources',
      value: 'Teaching Resources',
    },
    {
      label: 'Theory Grade 5',
      value: 'Theory Grade 5',
    },
    {
      label: 'Theory Grades 1-4',
      value: 'Theory Grades 1-4',
    },
    {
      label: 'Theory Grades 6-8',
      value: 'Theory Grades 6-8',
    },
    {
      label: 'Ukulele',
      value: 'Ukulele',
    },
    {
      label: 'Viola',
      value: 'Viola',
    },
    {
      label: 'Violin',
      value: 'Violin',
    },
    {
      label: 'Voice',
      value: 'Voice',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ],
  inventorySectionOptions: [
    {
      label: 'Accompaniment Base Fee',
      value: 'Accompaniment Base Fee',
    },
    {
      label: 'Admin Fee',
      value: 'Admin Fee',
    },
    {
      label: 'Brass',
      value: 'Brass',
    },
    {
      label: 'Buy Back Instruments',
      value: 'Buy Back Instruments',
    },
    {
      label: 'Cello',
      value: 'Cello',
    },
    {
      label: 'Clarinet',
      value: 'Clarinet',
    },
    {
      label: 'Clarinet Separate Accessories',
      value: 'Clarinet Separate Accessories',
    },
    {
      label: 'Competition Fee',
      value: 'Competition Fee',
    },
    {
      label: 'Digital Pianos',
      value: 'Digital Pianos',
    },
    {
      label: 'Exam Fee',
      value: 'Exam Fee',
    },
    {
      label: 'External Ticket',
      value: 'External Ticket',
    },
    {
      label: 'Flute / Toot',
      value: 'Flute / Toot',
    },
    {
      label: 'Guitar and Ukulele',
      value: 'Guitar and Ukulele',
    },
    {
      label: 'Guitar and Ukulele Accessories',
      value: 'Guitar and Ukulele Accessories',
    },
    {
      label: 'Instruments for Rental',
      value: 'Instruments for Rental',
    },
    {
      label: 'Internal Ticket',
      value: 'Internal Ticket',
    },
    {
      label: 'Misc Accessories',
      value: 'Misc Accessories',
    },
    {
      label: 'Piano',
      value: 'Piano',
    },
    {
      label: 'Recording Fee',
      value: 'Recording Fee',
    },
    {
      label: 'Rental Damages',
      value: 'Rental Damages',
    },
    {
      label: 'Rental Fee',
      value: 'Rental Fee',
    },
    {
      label: 'Repair Fee',
      value: 'Repair Fee',
    },
    {
      label: 'Saxophone',
      value: 'Saxophone',
    },
    {
      label: 'Saxophone Separate Accessories',
      value: 'Saxophone Separate Accessories',
    },
    {
      label: 'Sheet Music',
      value: 'Sheet Music',
    },
    {
      label: 'Strings',
      value: 'Strings',
    },
    {
      label: 'Strings Accessories',
      value: 'Strings Accessories',
    },
    {
      label: 'Theory',
      value: 'Theory',
    },
    {
      label: 'Trumpet',
      value: 'Trumpet',
    },
    {
      label: 'Viola',
      value: 'Viola',
    },
    {
      label: 'Violin',
      value: 'Violin',
    },
    {
      label: 'Voice',
      value: 'Voice',
    },
    {
      label: 'Wind Brass Accessories',
      value: 'Wind Brass Accessories',
    },
    {
      label: 'Woodwinds',
      value: 'Woodwinds',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ],
  salesPaymentOptions: [
    {
      label: 'Credit Card',
      value: 'Credit Card',
    },
    {
      label: 'Bank Transfer',
      value: 'Bank Transfer',
    },
    {
      label: 'Cheque',
      value: 'Cheque',
    },
    {
      label: 'Cash',
      value: 'Cash',
    },
    {
      label: 'Package Credit',
      value: 'Package Credit',
    },
  ],
  salesStatusOptions: [
    {
      label: 'Paid',
      value: 'Paid',
    },
    {
      label: 'Unpaid',
      value: 'Unpaid',
    },
  ],
  purchaseRequestTypeOptions: [
    {
      label: 'Others (Please specify in note)',
      value: 'Others',
    },
    {
      label: 'Accessory',
      value: 'Accessory',
    },
    {
      label: 'Book',
      value: 'Book',
    },
    {
      label: 'Instrument',
      value: 'Instrument',
    },
  ],
  purchaseRequestSourceOptions: [
    {
      label: 'Inventory',
      value: 'Inventory',
    },
    {
      label: 'Library',
      value: 'Library',
    },
  ],
  blockTypeOptions: [
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: 'External',
      value: 'External',
    },
  ],
  cancellationFeeOptions: [
    {
      label: '0%',
      value: 0,
    },
    {
      label: '50%',
      value: 0.5,
    },
    {
      label: '100%',
      value: 1,
    },
  ],
  buyBackTypeOptions: [
    {
      label: 'Buy Back',
      value: 'Buy Back',
    },
    {
      label: 'Refund',
      value: 'Refund',
    },
  ],
  arrivalStatusOptions: [
    {
      label: 'Available',
      value: 'Available',
    },
    {
      label: 'Not Yet Arrived',
      value: 'Not Yet Arrived',
    },
  ],
  inventoryArrivalStatusOptions: [
    {
      label: 'Available',
      value: 'Arrived',
    },
    {
      label: 'Not Yet Arrived',
      value: 'Not Yet Arrived',
    },
  ],
  broadcastUserOptions: [
    {
      label: 'All Users',
      value: 'allUsers',
    },
    {
      label: 'Parents',
      value: 'parent',
    },
    {
      label: 'Instructors',
      value: 'instructor',
    },
    {
      label: 'Admins',
      value: 'admin',
    },
    {
      label: 'Super Admins',
      value: 'superAdmin',
    },
  ],
  recurringEventOptions: [
    {
      label: 'This event',
      value: 'this',
    },
    {
      label: 'This and following events',
      value: 'thisFollowing',
    },
  ],
  recurringBlockTimeOptions: [
    {
      label: 'This block time',
      value: 'this',
    },
    {
      label: 'This and following block times',
      value: 'thisFollowing',
    },
  ],
}

export default inputOptions;
