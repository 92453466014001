import theme from '../../../theme';

const { palette } = theme;

const customStyle = ({ invalid, disabled }) => ({
  option: (base, state) => ({
    ...base,
    cursor: 'pointer',
    backgroundColor: state.isFocused ? palette.primary[0] : 'white',
    color: state.isFocused ? 'white' : 'black',
    ':active': {
      backgroundColor: 'white',
    },
    ':hover': {
      backgroundColor: palette.primary[0],
      color: palette.white[0],
    },
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: disabled ? palette.grayscale[2] : 'white',
    borderRadius: '4px',
    ...(
      invalid ? {
        border: `1px solid ${palette.red[3]}`,
      } : {}
    ),
    color: '#12426B',
    boxShadow: 'none',
    // height: '36px',
    minWidth: '120px',
    overflow: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    ':hover': {
      border: `1px solid ${palette.primary[0]}`,
    },
    ':active': {
      border: `1px solid ${palette.primary[3]}`,
    },
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    color: 'black',
  }),
  menu: base => ({
    ...base,
    border: `1px solid ${palette.primary[0]}`,
    borderRadius: '4px',
    // boxShadow: '0 3px 6px rgba(0,0,0,0.2)',
  }),
  placeholder: base => ({
    ...base,
    color: `${palette.grayscale[1]}`,
    fontSize: '14px',
  }),
});

export default customStyle;
