/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Tabs, ConfigProvider, Empty, Tag } from 'antd';
import { size, palette, font } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import get from 'lodash/get';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Button from '../../../components/atoms/Button';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import useUserForm from '../../../hooks/useUserForm';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import Image from '../../../components/atoms/Image';
import placeholder from '../../../asset/image/placeholder.jpeg';
import FieldComponent from '../../../components/molecules/FieldComponent';
import { validations } from '../../../utils/form';
import inputOptions from '../../../utils/form/inputOptions';
import PopUpDelete from '../../../components/organisms/PopUpDelete';
import PopUpUpdate from '../../../components/organisms/PopUpUpdate';

const Wrapper = styled.div`
  height: 100%;
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100% - 66px);
  padding-bottom: 10px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
    min-height: calc(100% - 56px);
    padding-bottom: 0px;
  }
`;

const LeftWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 30%;
  margin-right: 15px;
  overflow: auto;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 12px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
    max-height: calc(50vh - 50px);
  }
`;

const RightWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  width: 70%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 0px;
    width: 100%;
    flex: 1;
  }
`;

const DetailWrapper = styled(Flex)`
  flex-direction: column;
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding: 10px 0px;
`;

const LabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const DetailLabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  width: 120px;
  min-width: 120px;
  margin-right: 20px;
  font-family: ${font('tertiary')};
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledLink = styled(Link)`
  color: #3E90F7;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    color: #3E90F7;
  }
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 30vw;
  min-width: 30vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 120px;
  text-align: right;

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledTag = styled(Tag)`
  min-width: 70px;
  text-align: center;
  font-size: 14px;
`;

const StyledFlex2 = styled(Flex)`
  width: 100%;
  justify-content: center;
  > div {
    > div {
      height: 100%;
    }
  }
`;

const ModelContainer = styled.div`
  > div {
    padding: 24px 0px;
  }

  .ant-table-body {
    max-height: 100% !important;
    @media (max-width: ${size('mobileBreakpoint')}) {
      max-height: ${(props) => (props.divHeight ? `calc(100vh - ${props.divHeight}px - 260px)` : 'calc(100vh - 56px - 260px)')} !important;
    }
  }
`;

const LeftTopWrapper = styled(Flex)`
  justify-content: center;
  flex-direction: column;
`;

const ImageWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
  justify-content: center;
  margin-top: 0px;
`;

const EditWrapper = styled(Flex)`
  justify-content: flex-end;
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 12px;
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const SubmitButton = styled(Button)`
  min-width: 160px;
  width: 100%;
  margin: ${size('margin.default')} auto ${size('margin.default')};
  border-radius: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
`;

const Cell = styled.div`
  width: max-content;
`;

const ErrorAlertContainer = styled.div`
  > div {
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: 75vw;
      width: fit-content;
    }
  }
`;


const ShowView = (props) => {
  const { user, match, location } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [addItem, setAddItem] = useState(null);
  const [divHeight, setDivHeight] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  const { data, loading } = useGeneralData({
    endpoint: 'library/libraryDetail',
    query: { libraryId: match.params.id },
  });

  const { onSubmit } = useUserForm({
    endpoint: 'library/addItem',
    setErrorMessage,
    type: 'libraryDetail',
    action: 'create',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
        setIsSubmitting(false);
      }, 3000);
    }
  }, [errorMessage]);

  const history = useHistory();

  if (loading) return <Loading />

  if (!data || !data.product) return <Redirect to="/library" />;

  const { TabPane } = Tabs;

  const cellRenderers = [
    {
      title: 'Item ID',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Cell>{(id ? `LIB-IT${id}` : 'N/A')}</Cell>,
      width: 90,
    },
    {
      title: 'Status',
      key: 'checkoutId',
      render: (id, record) => <Cell><StyledTag color={record.arrivalStatus === 'Not Yet Arrived' ? 'red' : record.checkoutId ? 'orange' : 'green'}>{record.arrivalStatus === 'Not Yet Arrived' ? 'Not Yet Arrived' : record.checkoutId ? 'Checked Out' : 'Available'}</StyledTag></Cell>,
      width: 90,
    },
    {
      title: 'Checked out by',
      dataIndex: 'checkedOutBy',
      key: 'checkedOutBy',
      width: 140,
      render: (checkedOutBy) => <Cell>{(checkedOutBy ? (user.role === 'superAdmin' ? <Link to={`/staff/show/${checkedOutBy.id}`}>{checkedOutBy.name}</Link> : checkedOutBy.name) : 'N/A')}</Cell>,
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (text, record) => (
        <Cell>
          <Flex>
            { (user.role === 'admin' || user.role === 'superAdmin' || user.role === 'libraryHelper') ? (
              <Flex>
                { record.arrivalStatus === 'Not Yet Arrived' ? (
                  <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                    <PopUpUpdate user={user} endpoint="library/makeAvailable" record={record} type="makeAvailable" />
                  </div>
                ) : !record.checkoutId ? (
                  <Flex>
                    <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                      <PopUpUpdate user={user} endpoint="library/checkout" record={record} type="checkout" disabled={user.role === 'libraryHelper'} />
                    </div>
                    <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5, paddingLeft: 5 }}>
                      <PopUpUpdate user={user} endpoint="library/notYetArrived" record={record} type="notYetArrived" />
                    </div>
                  </Flex>
                ) : (
                  <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                    <PopUpUpdate user={user} endpoint="library/return" record={record} type="return" disabled={false} />
                  </div>
                )}
                <div style={{ paddingLeft: 5 }}>
                  <PopUpDelete user={user} endpoint="library/deleteItem" id={record.id} type="Item" disabled={record.checkoutId} />
                </div>
              </Flex>
            ) : (
              <Flex>
                { record.arrivalStatus === 'Not Yet Arrived' ? 'N/A'
                  : !record.checkoutId ? (
                    <div>
                      <PopUpUpdate user={user} endpoint="library/checkout" record={record} type="checkout" />
                    </div>
                  ) : (
                    <div>
                      <PopUpUpdate user={user} endpoint="library/return" record={record} type="return" disabled={Number(record.checkoutId) !== Number(user.id)} />
                    </div>
                  )}
              </Flex>
            )}
          </Flex>
        </Cell>
      ),
    },
  ];

  return (
    <Wrapper>
      {addItem ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpWrapper>
              <PopUpButton className="close" onClick={() => { close(); setAddItem(false); }}>
                &times;
              </PopUpButton>
              <PopUpTextWrapper>
                Add New Item
              </PopUpTextWrapper>
              <Form
                name="AddItemForm"
                onSubmit={onSubmit}
                initialValues={{ arrivalStatus: 'Available', quantity: 1 }}
                render={({ handleSubmit, values, submitting }) => {
                  values.libraryId = match.params.id;

                  return (
                    <StyledForm onSubmit={handleSubmit}>
                      <ErrorAlertContainer>
                        <ErrorAlert
                          errorMessage={errorMessage}
                          show={errorMessage !== null}
                        />
                      </ErrorAlertContainer>

                      <FieldFlex>
                        <StyledText>Status: </StyledText>
                        <StyledField
                          name="arrivalStatus"
                          type="select"
                          component={FieldComponent}
                          options={inputOptions.arrivalStatusOptions}
                          validate={validations.required}
                          menuPortalTarget={document.getElementById('popup-root')}
                          menuPlacement="auto"
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Quantity: </StyledText>
                        <StyledField
                          name="quantity"
                          type="number"
                          component={FieldComponent}
                          min={1}
                          step="1"
                          validate={validations.required}
                        />
                      </FieldFlex>

                      <SubmitButton type="submit" disabled={submitting}> Save </SubmitButton>
                    </StyledForm>
                  )
                }}
              />
            </PopUpWrapper>
          )}
        </StyledPopup>
      ) : null}
      <Flex style={{ position: 'relative' }}>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> Library </HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <ErrorAlert
            errorMessage={errorMessage}
            show={errorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw / 2)' }}
          />
        </Flex>
      </Flex>
      <BottomWrapper>
        <LeftWrapper>
          <LeftTopWrapper>
            <EditWrapper>
              {(user.role === 'admin' || user.role === 'superAdmin' || user.role === 'libraryHelper') ? (
                <StyledLink to={`/library/edit/${match.params.id}`}>
                  <Icon icon="edit-icon" height={21} style={{ minWidth: 21 }} />
                  <div>Edit</div>
                </StyledLink>
              ) : null}
            </EditWrapper>
            <ImageWrapper>
              <Image width={128} height={128} src={data.product.photo || placeholder} alt="Image not found" style={{ minWidth: 128 }} />
              <LabelText style={{ fontSize: 16, paddingTop: 15, textAlign: 'center' }}>{data.product.product}</LabelText>
            </ImageWrapper>
          </LeftTopWrapper>
          <DetailWrapper>
            <StyledFlex>
              <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
              <LabelText style={{ marginRight: 0 }}> Basic Information </LabelText>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Product ID </DetailLabelText>
              <div>{`LIB-PR${data.product.id}`}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Type </DetailLabelText>
              <div>{data.product.type}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Section </DetailLabelText>
              <div>{data.product.section}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Publisher / Maker </DetailLabelText>
              <div>{data.product.publisher || 'N/A'}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Quantity </DetailLabelText>
              <div>{data.product.libraryDetails[0].id ? Number(data.product.libraryDetails.length) : 0}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Checked out </DetailLabelText>
              <div>{data.product.checkOutCount}</div>
            </StyledFlex>
          </DetailWrapper>
        </LeftWrapper>
        <RightWrapper>
          <Tabs defaultActiveKey="Items">
            <TabPane tab="Items" key="Items">
              <ModelContainer divHeight={divHeight}>
                <ModelPage
                  showHeader={false}
                  modelName="library/itemDetails"
                  itemsPerPage={25}
                  query={{ where: { libraryId: match.params.id, status: 'All' } }}
                  initialQuery={{
                    currentPage: 1,
                    where: {
                      status: 'All',
                    },
                  }}
                  radioGroup={['All', 'Available', 'Checked Out', 'Not Yet Arrived']}
                  radioGroupKey="status"
                  radioGroupSize="middle"
                  allowSearch={false}
                  actions={(user.role === 'admin' || user.role === 'superAdmin' || user.role === 'libraryHelper') ? [{ action: () => setAddItem(true), label: 'Add New Item' }] : []}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (divHeight !== height) setDivHeight(height);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Products" />}>
                        <AntDTable
                          modelName="library/itemDetails"
                          cellRenderers={cellRenderers}
                          pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          scroll={{ x: 'max-content', y: '40vh' }}
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
          </Tabs>
        </RightWrapper>
      </BottomWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state.authentication),
  }
}

export default connect(mapStateToProps)(ShowView);
