import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { size, palette, font } from 'styled-theme';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import get from 'lodash/get';
import FieldComponent from '../../molecules/FieldComponent';
import Button from '../../atoms/Button';
import Card from '../../atoms/Card';
import { validations } from '../../../utils/form';
import inputOptions from '../../../utils/form/inputOptions';
import ErrorAlert from '../../molecules/ErrorAlert';
import Flex from '../../atoms/Flex';
import Input from '../../atoms/Input';

const StyledForm = styled.form`
  >div {
    >div {
      min-height: 16px;
    }
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 120px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledButton = styled(Button)`
  min-width: 180px;
  margin: 20px 0px 20px;
  border-radius: 0px;
  width: 50%;

  @media (max-width: ${size('mobileBreakpoint')}){
    width: 100%;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  > div {
    margin: 0px;
    margin-left: 7px;
    > label {
      > div {
        left: 0px;
      }
    }
  }
`;

const StyledInput = styled(Input)`
  transform: scale(1.2);
  font-size: 12px;
`;

const MainWrapper = styled(Flex)`
  flex-direction: column;
  width: 50%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    width: 100%;
  }
`;

const MultiSelectText = styled(StyledText)`
  padding-top: 17px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const CheckBoxTest = styled(StyledText)`
  @media (max-width: ${size('mobileBreakpoint')}){
    padding: 10px 0px 22px 0px;
  }
`;

const EventForm = ({ onSubmit, errorMessage, allParticipants, selectedEvent, allEvents = [], eventParticipants = [], type, calendars }) => {
  const timeDifference = get(selectedEvent, 'startTime', null) && get(selectedEvent, 'endTime', null) ? moment(selectedEvent.endTime).diff(moment(selectedEvent.startTime), 'days', true) : null;
  const isAllDay = timeDifference ? timeDifference >= 1 : false;
  const [allDay, setAllDay] = useState(isAllDay);

  const sortedParticipants = allParticipants.map((i) => {
    i.label = `${i.firstName} ${i.lastName}`;
    i.value = i.id;
    i.tags = [get(i, 'role')];
    return i;
  }).sort((a, b) => (a.label > b.label ? 1 : -1));

  let initialValues = null;
  const lastEvent = sortBy(allEvents, (event) => moment(event.startTime).valueOf())[allEvents.length - 1];

  if (selectedEvent != null) {
    initialValues = ({
      title: selectedEvent.title,
      participants: eventParticipants || [],
      date: moment(selectedEvent.startTime).format('YYYY-MM-DD'),
      endDate: moment(selectedEvent.endTime).subtract(1, 'days').format('YYYY-MM-DD'),
      startTime: moment(selectedEvent.startTime).format('HH:mm'),
      endTime: moment(selectedEvent.endTime).format('HH:mm'),
      roomId: selectedEvent.roomId ? selectedEvent.roomId.toString() : null,
      repeat: selectedEvent.recurrenceType,
      recurring: 'this',
      ends: lastEvent ? moment(lastEvent.startTime).format('YYYY-MM-DD') : null,
    });
  }

  return (
    <Form
      name="EventForm"
      onSubmit={onSubmit}
      initialValues={initialValues}
      initialValuesEqual={() => true}
      render={({ handleSubmit, submitting, values }) => {
        if (type === 'reschedule') {
          values.editEventId = selectedEvent.eventId;
          values.oldRecurrenceId = selectedEvent.recurrenceId;
          values.oldDate = selectedEvent.date;
          values.oldAllDay = isAllDay;
        }
        values.allDay = allDay;
        if (values.allDay) {
          values.startTime = null;
          values.endTime = null;
        } else values.endDate = null;
        values.calendars = calendars;

        return (
          <StyledForm onSubmit={handleSubmit}>
            <MainWrapper style={{ paddingRight: 10 }}>
              <FieldFlex>
                <StyledText>Event: </StyledText>
                <StyledField
                  name="title"
                  type="text"
                  component={FieldComponent}
                  validate={validations.required}
                />
              </FieldFlex>

              <FieldFlex style={{ alignItems: 'normal' }}>
                <MultiSelectText>Participants: </MultiSelectText>
                <StyledField
                  name="participants"
                  type="multi-select"
                  removeInputOnSelect={false}
                  component={FieldComponent}
                  options={type === 'create' ? sortedParticipants.filter((i) => i.status === 'Current') : sortedParticipants.filter((i) => i.status === 'Current' || eventParticipants.includes(Number(i.id)))}
                />
              </FieldFlex>

              <FieldFlex>
                <StyledText>{values.allDay ? 'Start Date:' : 'Date:'} </StyledText>
                <StyledField
                  name="date"
                  type="date"
                  component={FieldComponent}
                  validate={validations.required}
                />
              </FieldFlex>

              { values.allDay && (
                <FieldFlex>
                  <StyledText>End Date: </StyledText>
                  <StyledField
                    name="endDate"
                    type="date"
                    component={FieldComponent}
                    validate={validations.required}
                  />
                </FieldFlex>
              )}

              { !values.allDay && (
                <FieldFlex>
                  <StyledText>Time: </StyledText>
                  <FieldFlex style={{ width: '100%', alignItems: 'baseline', flexDirection: 'row' }}>
                    <StyledField
                      name="startTime"
                      type="time"
                      component={FieldComponent}
                      validate={validations.time}
                    />
                    <div style={{ marginLeft: '10px' }}> - </div>
                    <StyledField
                      style={{ marginLeft: '10px' }}
                      name="endTime"
                      type="time"
                      component={FieldComponent}
                      validate={validations.time}
                    />
                  </FieldFlex>
                </FieldFlex>
              )}

              <FieldFlex style={{ display: 'flex', alignItems: 'flex-start', marginTop: 2, flexDirection: 'row' }}>
                <CheckBoxTest />
                <InputContainer>
                  <StyledInput type="checkbox" label="All day" checked={allDay} onChange={() => setAllDay(!allDay)} />
                </InputContainer>
              </FieldFlex>

              <FieldFlex>
                <StyledText>Room: </StyledText>
                <StyledField
                  name="roomId"
                  type="spec"
                  component={FieldComponent}
                  shouldChose
                  labelBy={(v) => v.name}
                  reference={{ endpoint: 'room' }}
                  associationType="own"
                />
              </FieldFlex>

              <div>
                <FieldFlex>
                  <StyledText>Repeat: </StyledText>
                  <StyledField
                    name="repeat"
                    type="select"
                    component={FieldComponent}
                    disabled={type === 'reschedule'}
                    options={inputOptions.recurrenceOptions.filter((i) => i.value !== 'Daily')}
                    validate={validations.required}
                  />
                </FieldFlex>
                {(type === 'reschedule') && (values.repeat != null && values.repeat !== 'Does not repeat') && (
                  <FieldFlex>
                    <StyledText>Recurring: </StyledText>
                    <StyledField
                      name="recurring"
                      type="select"
                      component={FieldComponent}
                      options={inputOptions.recurringEventOptions}
                      validate={validations.required}
                    />
                  </FieldFlex>
                )}
                {(
                  (type === 'create' && values.repeat != null && values.repeat !== 'Does not repeat')
                  || (type === 'reschedule' && values.recurring === 'thisFollowing')
                ) ? (
                  <FieldFlex>
                    <StyledText>Ends: </StyledText>
                    <StyledField
                      name="ends"
                      type="date"
                      component={FieldComponent}
                      validate={validations.required}
                    />
                  </FieldFlex>
                  ) : null}
              </div>
              { type === 'delete' ? (
                <StyledButton type="submit" disabled={submitting}>
                  Save
                </StyledButton>
              ) : (
                <FieldFlex>
                  <StyledText />
                  <Flex style={{ width: '100%' }}>
                    <StyledButton type="submit" disabled={submitting}>
                      Save
                    </StyledButton>
                  </Flex>
                </FieldFlex>
              )}
            </MainWrapper>
          </StyledForm>
        )
      }}
    />
  )
};

EventForm.propTypes = {
  onSubmit: PropTypes.func,
  allParticipants: PropTypes.array.isRequired,
  selectedEvent: PropTypes.object,
  eventParticipants: PropTypes.array,
  type: PropTypes.string.isRequired,
};

export default EventForm;
