import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { size, palette, font } from 'styled-theme';
import moment from 'moment';
import { ifProp } from 'styled-tools';
import { Field, Form } from 'react-final-form';
import { Redirect, useHistory } from 'react-router-dom';
import { isEmpty, isEqual, xorWith } from 'lodash';
import { Divider } from 'antd';
import get from 'lodash/get';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import { validations } from '../../../utils/form';
import FieldComponent from '../../../components/molecules/FieldComponent';
import Button from '../../../components/atoms/Button';
import useUserForm from '../../../hooks/useUserForm';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import inputOptions from '../../../utils/form/inputOptions';
import useLessonData from '../../../hooks/useLessonData';
import Link from '../../../components/atoms/Link';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 100%;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
  }
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const FormWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 66px);
  height: calc(100% - 66px);
  overflow-y: auto;
  padding: 5px 25px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 5px 12px;
    max-height: calc(100% - 56px);
    height: calc(100% - 56px);
  }
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C7C7C7;
  margin-bottom: 12px;
`;

const TopText = styled.div`
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 240px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledForm = styled.form`
  >div {
    >div {
      min-height: 16px;
    }
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const StyledButton = styled(Button)`
  width: 50%;
  min-width: 180px;
  margin: 20px 0px 20px;
  border-radius: 0px;

  @media (max-width: ${size('mobileBreakpoint')}){
    width: 100%;
  }
`;

const ItemIDWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const ItemIDText = styled.div`
  position: absolute;
  top: 18px;
  right: 10px;
`;

const ItemIDField = styled(StyledField)`
  input {
    padding-right: 40px;
    height: 38px;
  }
`;

const DashedButton = styled(Button)`
  border: 1px dashed ${palette('grayscale', 3)};
  background-color: ${ifProp('disabled', '#CCCCCC', palette('white', 0))};
  color: ${palette('black', 0)};
  border-radius: 4px;
  margin-top: 5px;
  width: 100%;
  padding: 0px;

  &:hover,
  &:focus,
  &:active {
    background-color: ${palette('white', 0)};
    color: ${palette('black', 0)};
  }
`;

const ItemText = styled.div`
  font-weight: bold;
  margin-left: 10px;
  padding-top: 1px;
  text-align: center;
`;

const DividerContainer = styled.div`
  min-width: auto;
  width: 100%;

  .ant-divider-horizontal.ant-divider-with-text {
    margin-top: 0px;
    margin-bottom: 12px;
    border-top-color: ${palette('grayscale', 0)};
  }
`;

const MainWrapper = styled(Flex)`
  flex-direction: column;
  width: 50%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    width: 100%;
  }
`;

const BasicWrapper = styled(Flex)`
  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
  }
`;

const BasicInfoLeftWrapper = styled(MainWrapper)`
  padding-right: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-right: 0px;
  }
`;

const BasicInfoRightWrapper = styled(MainWrapper)`
  padding-left: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-left: 0px;
  }
`;

const EditView = (props) => {
  const { user, match, location } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [itemList, setItemList] = useState([]);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const { data, loading } = useGeneralData({
    endpoint: 'sale/saleInfo',
    query: { id: match.path === '/sale/create' ? null : match.params.id },
  });

  const { data: inventorySectionsData, loading: inventorySectionsLoading } = useGeneralData({
    endpoint: 'inventory/inventorySections',
  });


  const { onSubmit } = useUserForm({
    endpoint: match.path === '/sale/create' ? 'sale/' : `sale/edit/${match.params.id}`,
    setErrorMessage,
    type: 'sale',
    action: match.path === '/sale/create' ? 'create' : 'edit',
  });

  const [studId, setStudId] = useState(get(data, 'sale.studentId', null));

  const { data: saleStudentPackage } = useLessonData({
    endpoint: 'sale/studentPackage',
    query: { studentId: studId, saleId: match.params.id }
  });

  const history = useHistory();

  let type;

  if (match.path === '/sale/create') type = 'create';
  else if (match.path === '/sale/edit/:id') type = 'edit';
  else return <Redirect to="sale" />

  if (loading || inventorySectionsLoading) return <Loading />

  if (!data) return <Redirect to="/sale" />;
  if (type === 'edit' && !data.sale) return <Redirect to="/sale" />;

  let initialValues = null;

  if (type === 'edit') {
    initialValues = {
      ...data.sale,
      ...data.sale.additionalItemsObject,
      dueDate: data.sale.dueDate ? moment(data.sale.dueDate).format('YYYY-MM-DD') : null,
      paymentDate: data.sale.paymentDate ? moment(data.sale.paymentDate).format('YYYY-MM-DD') : null,
    };
    if (data.sale.additionalItems && itemList.length === 0) setItemList(data.sale.additionalItems);
  } else if (get(location, 'state.id', null)) {
    initialValues = {
      itemSection: get(location, 'state.inventory.section', null),
      productName: get(location, 'state.inventoryId', null),
      item: get(location, 'state.id', null),
      itemPrice: get(location, 'state.sellingPrice', null),
    }
  }

  const sortedChildren = data.children.map((c) => {
    c.label = `${c.firstName} ${c.lastName} (ST${c.id})`;
    c.value = c.id;
    return c;
  }).sort((a, b) => (a.label > b.label ? 1 : -1));

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> {type === 'create' ? 'Create New' : 'Edit'} </HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <ErrorAlert
            errorMessage={errorMessage}
            show={errorMessage !== null}
            style={{ position: 'absolute', maxWidth: 'none', marginTop: 8 }}
          />
        </Flex>
      </Flex>
      <FormWrapper>
        <StyledFlex>
          <Icon icon="sale-info" height={20} style={{ marginRight: 5 }} />
          <TopText> Sale Management </TopText>
        </StyledFlex>
        <Form
          name="SaleForm"
          onSubmit={onSubmit}
          initialValues={initialValues}
          initialValuesEqual={() => true}
          render={({ handleSubmit, submitting, values }) => {
            if (type === 'edit') {
              values.saleId = match.params.id;
              values.oldPackageId = get(data, 'sale.packageId', null);
            }

            const chosenStudent = data.children.filter((c) => c.id === values.student);
            const studentId = (chosenStudent.length > 0) ? chosenStudent[0].id : null;

            const initialFilteredInventories = data.inventories.filter((inventory) => inventory.section === values.itemSection);
            const initialSortedInventories = initialFilteredInventories.map((inventory) => {
              inventory.label = inventory.publisherOrMaker ? `${inventory.product} (${inventory.publisherOrMaker})` : inventory.product;
              inventory.value = inventory.id;
              return inventory;
            }).sort((a, b) => (a.label > b.label ? 1 : -1));

            const initialFilteredInventoryItems = data.inventoryItems.filter((item) => Number(item.inventoryId) === Number(values.productName));
            const initialSortedInventoryItems = initialFilteredInventoryItems.map((item) => {
              item.label = `INV-IT${item.id}`;
              item.value = item.id;
              return item;
            }).sort((a, b) => (a.label > b.label ? 1 : -1));

            const updatedList = itemList.map((i) => ({ id: i.id, saleInventoryId: values[`saleInventoryId${i.id}`], itemSection: values[`itemSection${i.id}`], productName: values[`productName${i.id}`], item: values[`item${i.id}`], itemPrice: values[`itemPrice${i.id}`], rentalStart: values[`rentalStart${i.id}`], rentalEnd: values[`rentalEnd${i.id}`] }))

            const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));
            if (!isArrayEqual(itemList, updatedList)) {
              setItemList(updatedList);
            }

            if (!initialSortedInventories.map((inv) => inv.value).includes(values.productName)) {
              values.item = null;
              values.itemPrice = null;
              values.productName = null;
            }

            if (!initialSortedInventoryItems.map((invItem) => invItem.value).includes(values.item)) {
              values.item = null;
              values.itemPrice = null;
            }

            values.additionalItems = itemList;

            const activePackages = (saleStudentPackage && saleStudentPackage.length > 0) ? saleStudentPackage.filter((ap) => ap.children.map((ch) => Number(ch.id)).includes(Number(studentId))) : [];

            const initValue = get(data, 'sale.packageId', null) ? get(activePackages.filter((p) => p.id === data.sale.packageId), '0.value', null) : null;

            if (values.paymentMethod !== 'Package Credit') values.package = null;
            if (values.paymentMethod !== 'Credit Card') values.creditCardCharge = null;
            if (!studentId || !activePackages.map((p) => p.value).includes(values.package)) values.package = null;


            const itemSectionObj = inventorySectionsData.filter(function (item) {
              return item.name == values.itemSection;
            });

            // if (values.itemSection !== 'Second Hand / Rental Instruments') {
            if (itemSectionObj.length !== 0 && itemSectionObj[0].id !== 15) {
              values.rentalStart = null;
              values.rentalEnd = null;
            }

            if (Number(studId) !== Number(studentId)) setStudId(Number(studentId));


            return (
              <StyledForm onSubmit={handleSubmit}>
                <BasicWrapper style={{ paddingRight: 10 }}>
                  <BasicInfoLeftWrapper>
                    <Flex style={{ width: '100% ' }}>
                      {type === 'create' ? (
                        <div style={{ width: '100%' }}>
                          <FieldFlex>
                            <StyledText>Sale To: </StyledText>
                            <StyledField
                              name="student"
                              type="select"
                              options={sortedChildren}
                              component={FieldComponent}
                              isClearable
                              placeholder="Select student"
                            />
                          </FieldFlex>

                          <FieldFlex>
                            <StyledText />
                            <DividerContainer>
                              <Divider dashed>OR</Divider>
                            </DividerContainer>
                          </FieldFlex>

                          <FieldFlex>
                            <StyledText />
                            <StyledField
                              name="buyerName"
                              type="text"
                              component={FieldComponent}
                              disabled={type === 'edit'}
                              placeholder="Input manually"
                            />
                          </FieldFlex>
                        </div>
                      ) : (
                        <div style={{ width: '100%' }}>
                          {data.sale.buyerId ? (
                            <FieldFlex>
                              <StyledText>{data.sale.type === 'Buy Back' ? 'Buy Back From:' : 'Sale To:'}</StyledText>
                              <StyledField
                                name="student"
                                type="select"
                                options={sortedChildren}
                                component={FieldComponent}
                                isClearable
                                disabled={type === 'edit'}
                                placeholder="Select student"
                              />
                            </FieldFlex>
                          ) : (
                            <FieldFlex>
                              <StyledText>{data.sale.type === 'Buy Back' ? 'Buy Back From:' : 'Sale To:'}</StyledText>
                              <StyledField
                                name="buyerName"
                                type="text"
                                component={FieldComponent}
                                disabled={type === 'edit'}
                                placeholder="Input manually"
                              />
                            </FieldFlex>
                          )}

                          {data.sale.type === 'Buy Back' ? (
                            <FieldFlex>
                              <StyledText>Buy Back Type: </StyledText>
                              <StyledField
                                name="buyBackType"
                                type="select"
                                component={FieldComponent}
                                options={inputOptions.buyBackTypeOptions}
                                validate={validations.required}
                                disabled={type === 'edit'}
                              />
                            </FieldFlex>
                          ) : null}
                        </div>
                      )}
                    </Flex>
                  </BasicInfoLeftWrapper>

                  <BasicInfoRightWrapper>
                    <FieldFlex>
                      <StyledText>Item Section: </StyledText>
                      <StyledField
                        name="itemSection"
                        type="select"
                        component={FieldComponent}
                        disabled={type === 'edit'}
                        options={inventorySectionsData}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Product: </StyledText>
                      <StyledField
                        name="productName"
                        type="select"
                        component={FieldComponent}
                        options={initialSortedInventories}
                        disabled={type === 'edit'}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex style={{ paddingBottom: (itemSectionObj.length !== 0 && itemSectionObj[0].id === 15) ? 0 : 20 }}>
                      <StyledText>Item ID: </StyledText>
                      <FieldFlex style={{ width: '100%', flexDirection: 'row' }}>
                        <StyledField
                          style={{ width: '60%' }}
                          name="item"
                          type="select"
                          component={FieldComponent}
                          options={initialSortedInventoryItems}
                          disabled={type === 'edit'}
                          validate={validations.required}
                        />

                        <ItemIDWrapper style={{ width: '40%', marginLeft: '10px' }}>
                          <ItemIDField
                            name="itemPrice"
                            type="number"
                            component={FieldComponent}
                            min={0}
                            initialValue={type === 'create' ? ((values.item && initialSortedInventoryItems && initialSortedInventoryItems.length > 0) ? initialSortedInventoryItems.filter((i) => i.id === values.item)[0].sellingPrice : null) : values.itemPrice}
                            step="any"
                            disabled={type === 'edit'}
                            validate={validations.required}
                          />
                          <ItemIDText> HKD </ItemIDText>
                        </ItemIDWrapper>
                      </FieldFlex>
                    </FieldFlex>

                    {/* {values.itemSection === 'Second Hand / Rental Instruments' ? ( */}
                    {(itemSectionObj.length !== 0 && itemSectionObj[0].id === 15) ? (
                      <div style={{ paddingBottom: 20 }}>
                        <FieldFlex>
                          <StyledText>Rental Start: </StyledText>
                          <StyledField
                            name="rentalStart"
                            type="date"
                            initialValue={type === 'create' ? null : values.rentalStart}
                            component={FieldComponent}
                            validate={validations.required}
                          />
                        </FieldFlex>

                        <FieldFlex>
                          <StyledText>Rental End: </StyledText>
                          <StyledField
                            name="rentalEnd"
                            type="date"
                            initialValue={type === 'create' ? null : values.rentalEnd}
                            component={FieldComponent}
                            validate={validations.required}
                          />
                        </FieldFlex>
                      </div>
                    ) : null}

                    {itemList.length > 0 ? (
                      itemList.map((item, index) => {
                        const filteredInventories = data.inventories.filter((inventory) => inventory.section === values[`itemSection${item.id}`]);

                        const sortedInventories = filteredInventories.map((inventory) => {
                          inventory.label = inventory.publisherOrMaker ? `${inventory.product} (${inventory.publisherOrMaker})` : inventory.product;
                          inventory.value = inventory.id;
                          return inventory;
                        }).sort((a, b) => (a.label > b.label ? 1 : -1));

                        const filteredInventoryItems = data.inventoryItems.filter((invItem) => Number(invItem.inventoryId) === Number(values[`productName${item.id}`]));
                        const sortedInventoryItems = filteredInventoryItems.map((invItem) => {
                          invItem.label = `INV-IT${invItem.id}`;
                          invItem.value = invItem.id;
                          return invItem;
                        }).sort((a, b) => (a.label > b.label ? 1 : -1));

                        if (!sortedInventories.map((inv) => inv.value).includes(values[`productName${item.id}`])) {
                          values[`item${item.id}`] = null;
                          values[`itemPrice${item.id}`] = null;
                          values[`productName${item.id}`] = null;
                        }

                        if (!sortedInventoryItems.map((invItem) => invItem.value).includes(values[`item${item.id}`])) {
                          values[`item${item.id}`] = null;
                          values[`itemPrice${item.id}`] = null;
                        }

                        const itemSectionListObj = inventorySectionsData.filter((section) => section.name === values[`itemSection${item.id}`]);

                        {/* if (values[`itemSection${item.id}`] !== 'Second Hand / Rental Instruments') { */ }
                        if (itemSectionListObj.length !== 0 && itemSectionListObj[0].id !== 15) {
                          values[`rentalStart${item.id}`] = null;
                          values[`rentalEnd${item.id}`] = null;
                        }
                        return (
                          <Flex direction="column" key={item.id} style={{ paddingBottom: type === 'edit' && 20 }}>
                            <FieldFlex>
                              <StyledText>Item Section: </StyledText>
                              <StyledField
                                name={`itemSection${item.id}`}
                                type="select"
                                component={FieldComponent}
                                initialValue={type === 'create' ? null : values[`itemSection${item.id}`]}
                                options={inventorySectionsData}
                                disabled={type === 'edit'}
                                validate={validations.required}
                              />
                            </FieldFlex>

                            <FieldFlex>
                              <StyledText>Product: </StyledText>
                              <StyledField
                                name={`productName${item.id}`}
                                type="select"
                                component={FieldComponent}
                                initialValue={type === 'create' ? null : values[`productName${item.id}`]}
                                options={sortedInventories}
                                disabled={type === 'edit'}
                                validate={validations.required}
                              />
                            </FieldFlex>

                            <FieldFlex>
                              <StyledText>Item ID: </StyledText>
                              <FieldFlex style={{ width: '100%', flexDirection: 'row' }}>
                                <StyledField
                                  style={{ width: '60%' }}
                                  name={`item${item.id}`}
                                  type="select"
                                  options={sortedInventoryItems}
                                  initialValue={type === 'create' ? null : values[`item${item.id}`]}
                                  component={FieldComponent}
                                  disabled={type === 'edit'}
                                  validate={validations.required}
                                />

                                <ItemIDWrapper style={{ width: '40%', marginLeft: '10px' }}>
                                  <ItemIDField
                                    name={`itemPrice${item.id}`}
                                    type="number"
                                    component={FieldComponent}
                                    min={0}
                                    initialValue={type === 'create' ? ((values[`item${item.id}`] && sortedInventoryItems && sortedInventoryItems.length > 0) ? sortedInventoryItems.filter((i) => i.id === values[`item${item.id}`])[0].sellingPrice : null) : values[`itemPrice${item.id}`]}
                                    step="any"
                                    disabled={type === 'edit'}
                                    validate={validations.required}
                                  />
                                  <ItemIDText> HKD </ItemIDText>
                                </ItemIDWrapper>
                              </FieldFlex>
                            </FieldFlex>

                            {/* {values[`itemSection${item.id}`] === 'Second Hand / Rental Instruments' ? ( */}
                            {itemSectionListObj.length !== 0 && itemSectionListObj[0].id === 15 ? (
                              <div>
                                <FieldFlex>
                                  <StyledText>Rental Start: </StyledText>
                                  <StyledField
                                    name={`rentalStart${item.id}`}
                                    type="date"
                                    component={FieldComponent}
                                    initialValue={type === 'create' ? null : values[`rentalStart${item.id}`]}
                                    validate={validations.required}
                                  />
                                </FieldFlex>

                                <FieldFlex>
                                  <StyledText>Rental End: </StyledText>
                                  <StyledField
                                    name={`rentalEnd${item.id}`}
                                    type="date"
                                    component={FieldComponent}
                                    initialValue={type === 'create' ? null : values[`rentalEnd${item.id}`]}
                                    validate={validations.required}
                                  />
                                </FieldFlex>
                              </div>
                            ) : null}

                            {type === 'create' ? (
                              <FieldFlex>
                                <StyledText />
                                <DashedButton onClick={() => setItemList(itemList.filter((curr) => curr.id !== item.id))}>
                                  <Flex style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <Icon icon="remove-contact" height={19} />
                                    <ItemText>Remove Item </ItemText>
                                  </Flex>
                                </DashedButton>
                              </FieldFlex>
                            ) : null}
                          </Flex>
                        )
                      })
                    ) : null}
                    {type === 'create' ? (
                      <FieldFlex>
                        <StyledText />
                        <DashedButton onClick={() => setItemList([...itemList, { id: Math.max(...itemList.map((currItem) => currItem.id), 0) + 1, itemSection: '', productName: '', item: '', itemPrice: '' }])}>
                          <Flex style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Icon icon="add-contact" height={19} />
                            <ItemText>Add Item </ItemText>
                          </Flex>
                        </DashedButton>
                      </FieldFlex>
                    ) : null}
                  </BasicInfoRightWrapper>
                </BasicWrapper>

                <Flex direction="column">
                  <StyledFlex>
                    <Icon icon="payment-info" height={20} style={{ marginRight: 5 }} />
                    <TopText> Payment Information </TopText>
                  </StyledFlex>
                  <Flex style={{ paddingRight: 10 }}>
                    <MainWrapper>
                      {type === 'create' || (type === 'edit' && data.sale.type === 'Sale') ? (
                        <FieldFlex>
                          <StyledText>Due Date: </StyledText>
                          <StyledField
                            name="dueDate"
                            type="date"
                            component={FieldComponent}
                          />
                        </FieldFlex>
                      ) : null}

                      <FieldFlex>
                        <StyledText>Payment Date: </StyledText>
                        <StyledField
                          name="paymentDate"
                          type="date"
                          component={FieldComponent}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Payment Method: </StyledText>
                        <StyledField
                          name="paymentMethod"
                          type="select"
                          component={FieldComponent}
                          options={inputOptions.salesPaymentOptions}
                          isClearable
                        />
                      </FieldFlex>

                      {values.paymentMethod === 'Package Credit' ? (
                        <FieldFlex>
                          <StyledText>Package: </StyledText>
                          <StyledField
                            name="package"
                            type="select"
                            component={FieldComponent}
                            initialValue={initValue}
                            options={(activePackages && activePackages.length > 0) ? activePackages : []}
                            validate={validations.required}
                          />
                        </FieldFlex>
                      ) : null}

                      {values.paymentMethod === 'Credit Card' ? (

                        <FieldFlex>
                          <StyledText>Credit Card Charge </StyledText>
                          <ItemIDWrapper>
                            <ItemIDField
                              name="creditCardCharge"
                              type="number"
                              component={FieldComponent}
                              min={0}
                              step="any"
                              validate={validations.required}
                            />
                            <ItemIDText> HKD </ItemIDText>
                          </ItemIDWrapper>
                        </FieldFlex>
                      ) : null}

                      <FieldFlex>
                        <StyledText />
                        <Flex style={{ width: '100%' }}>
                          <StyledButton type="submit" disabled={submitting}>
                            Save
                          </StyledButton>
                        </Flex>
                      </FieldFlex>
                    </MainWrapper>
                  </Flex>
                </Flex>
              </StyledForm>
            )
          }}
        />
      </FormWrapper>
    </Wrapper >
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state.authentication),
})

export default connect(mapStateToProps)(EditView);
