import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { size, palette, font } from 'styled-theme';
import { ifProp } from 'styled-tools';
import { Field, Form } from 'react-final-form';
import { Redirect, useHistory } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash/get';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import { validations } from '../../../utils/form';
import FieldComponent from '../../../components/molecules/FieldComponent';
import Button from '../../../components/atoms/Button';
import useUserForm from '../../../hooks/useUserForm';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import inputOptions from '../../../utils/form/inputOptions';
import Link from '../../../components/atoms/Link';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 100%;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
  }
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const FormWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 66px);
  height: calc(100% - 66px);
  overflow-y: auto;
  padding: 5px 25px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 5px 12px;
    max-height: calc(100% - 56px);
    height: calc(100% - 56px);
  }
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C7C7C7;
  margin-bottom: 12px;
`;

const TopText = styled.div`
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 200px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledForm = styled.form`
  >div {
    >div {
      min-height: 16px;
    }
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const StyledButton = styled(Button)`
  width: 50%;
  min-width: 180px;
  margin: 20px 0px 20px;
  border-radius: 0px;

  @media (max-width: ${size('mobileBreakpoint')}){
    width: 100%;
  }
`;

const LessValueWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const LessValueText = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
`;

const LessValueField = styled(StyledField)`
  input {
    padding-right: 40px;
  }
`;

const PercentValueField = styled(StyledField)`
  input {
    padding-right: 25px;
  }
`;

const HoursValueField = styled(StyledField)`
  input {
    padding-right: 50px;
  }
`;

const MainWrapper = styled(Flex)`
  flex-direction: column;
  width: 50%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    width: 100%;
  }
`;

const ExtraInfoWrapper = styled(Flex)`
  padding-right: 0px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
    padding-right: 10px;
  }
`;

const ExtraInfoLeftWrapper = styled(MainWrapper)`
  padding-right: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-right: 0px;
  }
`;

const ExtraInfoRightWrapper = styled(MainWrapper)`
  padding-left: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-left: 0px;
  }
`;

const TextNoteText = styled(StyledText)`
  padding-top: 12px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const MultiSelectText = styled(StyledText)`
  padding-top: 17px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const EditView = (props) => {
  const { user, match, location } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const { data, loading } = useGeneralData({
    endpoint: 'package/createEditInfo',
  });

  const { data: packageData, loading: loading2 } = useGeneralData({
    endpoint: 'package/packageData',
    query: { packageId: match.params.id },
  });

  const { onSubmit } = useUserForm({
    endpoint: match.path === '/package/create' ? 'package/' : `package/edit/${match.params.id}`,
    setErrorMessage,
    type: 'package',
    action: match.path === '/package/create' ? 'create' : 'edit',
  });

  const history = useHistory();

  let type;

  if (match.path === '/package/create') type = 'create';
  else if (match.path === '/package/edit/:id') type = 'edit';
  else return <Redirect to="package" />

  if (user.role === 'parent') return <Redirect to="/package" />

  if (loading) return <Loading />
  if (loading2) return <Loading />

  if (!data) return <Redirect to="/package" />;
  if (!packageData) return <Redirect to="/package" />;
  if (type === 'edit' && !packageData.packageInfo[0]) return <Redirect to="/package" />;

  let initialValues = null;
  let editDefDiscount;
  let editFreeTrial;
  let editExpiryDate;

  if (type === 'edit') {
    const editPackage = packageData.packageInfo[0].package;
    const otherObj = {};
    const packageValue = Number(editPackage.packageValue) + Number(editPackage.offsetDiscount);
    if (editPackage.valueType === 'Hourly') {
      if (editPackage.hours) {
        if (Number(editPackage.hours) !== 5 && Number(editPackage.hours) !== 10 && Number(editPackage.hours) !== 20 && Number(editPackage.hours) !== 50) {
          otherObj.hours = 'Custom (hours)';
          otherObj.hourCustom = Number(editPackage.hours);
        } else otherObj.hours = Number(editPackage.hours);
      } else if (Number(editPackage.minutes) !== 30 && Number(editPackage.minutes) !== 45 && Number(editPackage.minutes) !== 60) {
        otherObj.mins = 'Custom (mins)';
        otherObj.minsCustom = Number(editPackage.minutes);
      } else otherObj.mins = Number(editPackage.minutes);
    } else if (editPackage.valueType === 'Credit') {
      if (editPackage.type === 'Private / Semiprivate Package') {
        if (packageValue !== 5000 && packageValue !== 10000 && packageValue !== 20000 && packageValue !== 50000 && packageValue !== 100000) {
          otherObj.packageValue = 'Custom';
          otherObj.creditValue = packageValue;
        }
      }
    }

    editDefDiscount = packageData.packageInfo[0].package.defaultDiscount ? packageData.packageInfo[0].package.defaultDiscount * 100 : null;
    editFreeTrial = packageData.packageInfo[0].package.freeTrial;
    editExpiryDate = packageData.packageInfo[0].package.expiryDate ? moment(packageData.packageInfo[0].package.expiryDate).format('YYYY-MM-DD') : null;

    initialValues = {
      ...editPackage,
      packageCode: `${editPackage.prefix}${editPackage.id}`,
      packageType: editPackage.type,
      packageValue: packageValue.toString(),
      otherDiscount: editPackage.otherDiscount ? editPackage.otherDiscount * 100 : null,
      creditTransfer: editPackage.creditTransfer ? Number(editPackage.creditTransfer) : null,
      freeCredit: editPackage.freeCredit ? Number(editPackage.freeCredit) : null,
      student: get(packageData, 'packageInfo.0.child', []).map((c) => c.id),
      lessText: editPackage.less ? Object.keys(editPackage.less) : null,
      lessValue: editPackage.less ? Object.values(editPackage.less) : null,
      offsetDisc: (editPackage.offsetDiscount && editPackage.offsetDiscount > 0) ? Number(editPackage.offsetDiscount) : null,
      offsetCommission: (editPackage.commission && editPackage.commission > 0) ? Number(editPackage.commission) : null,
      offsetCreditCard: (editPackage.creditCardCharge && editPackage.creditCardCharge > 0) ? Number(editPackage.creditCardCharge) : null,
      due: editPackage.dueDate ? moment(editPackage.dueDate).format('YYYY-MM-DD') : null,
      payment: editPackage.paymentDate ? moment(editPackage.paymentDate).format('YYYY-MM-DD') : null,
      activation: editPackage.activationDate ? moment(editPackage.activationDate).format('YYYY-MM-DD') : null,
      priceEffectiveDate: editPackage.priceEffectiveDate ? moment(editPackage.priceEffectiveDate).format('YYYY-MM-DD') : null,
      createdAt: editPackage.createdAt ? moment(editPackage.createdAt).format('YYYY-MM-DD') : null,
      invoice: editPackage.invoiceDate ? moment(editPackage.invoiceDate).format('YYYY-MM-DD') : null,
      ...otherObj,
    };
  } else if (type === 'create') {
    initialValues = { packageCode: `P${data.latestId}` };
  }

  const sortedChildren = packageData.children.map((c) => {
    c.label = `${c.firstName} ${c.lastName} (ST${c.id})`;
    c.value = c.id;
    return c;
  }).sort((a, b) => (a.label > b.label ? 1 : -1));

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> {type === 'create' ? 'Create New' : 'Edit'} </HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <ErrorAlert
            errorMessage={errorMessage}
            show={errorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw  / 2)' }}
          />
        </Flex>
      </Flex>
      <FormWrapper>
        <StyledFlex>
          <Icon icon="package-info" height={20} style={{ marginRight: 5 }} />
          <TopText> Package Information </TopText>
        </StyledFlex>
        <Form
          name="PackageForm"
          onSubmit={onSubmit}
          initialValues={initialValues}
          initialValuesEqual={() => true}
          render={({ handleSubmit, submitting, values, dirtyFields }) => {
            if (type === 'edit') values.packageId = match.params.id;
            if (type === 'create') values.packageCode = values.packageType === 'Group Package' ? `G${data.latestId}` : values.packageType === 'Trial Package' ? `T${data.latestId}` : `P${data.latestId}`;

            let defDiscount;
            let freeTrial;
            if (values.packageType === 'Private / Semiprivate Package') {
              if (values.valueType === 'Credit') {
                if (values.packageValue === '5000') {
                  if (!dirtyFields.defaultDisc) defDiscount = 5;
                  if (!dirtyFields.freeTrial) freeTrial = 30;
                } else if (values.packageValue === '10000') {
                  if (!dirtyFields.defaultDisc) defDiscount = 10;
                  if (!dirtyFields.freeTrial) freeTrial = 60;
                } else if (values.packageValue === '20000') {
                  if (!dirtyFields.defaultDisc) defDiscount = 15;
                  if (!dirtyFields.freeTrial) freeTrial = 120;
                } else if (values.packageValue === '50000') {
                  if (!dirtyFields.defaultDisc) defDiscount = 20;
                  if (!dirtyFields.freeTrial) freeTrial = 240;
                } else if (values.packageValue === '100000') {
                  if (!dirtyFields.defaultDisc) defDiscount = 23;
                  if (!dirtyFields.freeTrial) freeTrial = 480;
                } else if (values.packageValue === 'Custom') {
                  defDiscount = null;
                  editDefDiscount = null;
                  values.defaultDisc = null;
                  if (!dirtyFields.freeTrial) freeTrial = null;
                } else {
                  defDiscount = null;
                  editDefDiscount = null;
                  values.defaultDisc = null;
                  values.otherDiscount = null;
                  values.packageValue = null;
                }
              } else if (values.valueType === 'Hourly') {
                defDiscount = null;
                editDefDiscount = null;
                values.defaultDisc = null;
                freeTrial = null;
                editFreeTrial = null;
              }
            } else {
              defDiscount = null;
              editDefDiscount = null;
              values.defaultDisc = null;
              freeTrial = null;
              editFreeTrial = null;
            }

            if (values.packageType === 'Trial Package') values.hours = null;
            if (values.packageType === 'Private / Semiprivate Package') values.mins = null;

            let expiryDate;

            if (values.activation != null && (values.packageType === 'Private / Semiprivate Package')) {
              if (values.valueType === 'Credit' && values.packageValue != null) {
                if (values.packageValue === '5000' && !dirtyFields.expiry) expiryDate = moment(values.activation).add(3, 'months').format('YYYY-MM-DD');
                else if (values.packageValue === '10000' && !dirtyFields.expiry) expiryDate = moment(values.activation).add(6, 'months').format('YYYY-MM-DD');
                else if (values.packageValue === '20000' && !dirtyFields.expiry) expiryDate = moment(values.activation).add(12, 'months').format('YYYY-MM-DD');
                else if (values.packageValue === '50000' && !dirtyFields.expiry) expiryDate = moment(values.activation).add(24, 'months').format('YYYY-MM-DD');
                else if (values.packageValue === '100000' && !dirtyFields.expiry) expiryDate = moment(values.activation).add(36, 'months').format('YYYY-MM-DD');
              } else if (values.valueType === 'Hourly' && values.hours != null) {
                if (values.hours === 5 && !dirtyFields.expiry) expiryDate = moment(values.activation).add(3, 'months').format('YYYY-MM-DD');
                else if (values.hours === 10 && !dirtyFields.expiry) expiryDate = moment(values.activation).add(6, 'months').format('YYYY-MM-DD');
                else if (values.hours === 20 && !dirtyFields.expiry) expiryDate = moment(values.activation).add(12, 'months').format('YYYY-MM-DD');
                else if (values.hours === 50 && !dirtyFields.expiry) expiryDate = moment(values.activation).add(24, 'months').format('YYYY-MM-DD');
              }
            }

            if (type === 'edit') {
              if (!get(packageData, 'packageInfo.0.package.expiryDate', null) && !dirtyFields.expiry) editExpiryDate = expiryDate;
            }

            return (
              <StyledForm onSubmit={handleSubmit}>
                <Flex>
                  <MainWrapper style={{ paddingRight: 10 }}>
                    <FieldFlex>
                      <StyledText>Package Code: </StyledText>
                      <StyledField
                        name="packageCode"
                        type="text"
                        disabled
                        component={FieldComponent}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Package Type: </StyledText>
                      <StyledField
                        name="packageType"
                        type="select"
                        component={FieldComponent}
                        options={inputOptions.packageTypeOptions}
                        validate={validations.required}
                        disabled={type === 'edit'}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Value Type: </StyledText>
                      <StyledField
                        name="valueType"
                        type="select"
                        component={FieldComponent}
                        options={inputOptions.valueTypeOptions}
                        validate={validations.required}
                        initialValue={type === 'edit' ? initialValues.valueType : (values.packageType === 'Group Package' ? 'Credit' : null)}
                        disabled={values.packageType === 'Group Package' || type === 'edit'}
                      />
                    </FieldFlex>

                    { values.valueType === 'Hourly' ? (
                      <div>
                        { values.packageType === 'Trial Package' ? (
                          <FieldFlex>
                            <StyledText>Duration: </StyledText>
                            <StyledField
                              name="mins"
                              type="select"
                              component={FieldComponent}
                              options={inputOptions.minsOptions}
                              validate={validations.required}
                            />
                          </FieldFlex>
                        ) : (
                          <FieldFlex>
                            <StyledText>Duration: </StyledText>
                            <StyledField
                              name="hours"
                              type="select"
                              component={FieldComponent}
                              options={inputOptions.hourOptions}
                              validate={validations.required}
                            />
                          </FieldFlex>
                        )}

                        { values.hours === 'Custom (hours)' && (
                          <FieldFlex>
                            <StyledText />
                            <LessValueWrapper>
                              <HoursValueField
                                name="hourCustom"
                                type="number"
                                component={FieldComponent}
                                min={0}
                                step="any"
                                validate={validations.required}
                              />
                              <LessValueText> hours </LessValueText>
                            </LessValueWrapper>
                          </FieldFlex>
                        )}

                        { values.mins === 'Custom (mins)' && (
                          <FieldFlex>
                            <StyledText />
                            <LessValueWrapper>
                              <LessValueField
                                name="minsCustom"
                                type="number"
                                component={FieldComponent}
                                min={0}
                                step="any"
                                validate={validations.required}
                              />
                              <LessValueText> mins </LessValueText>
                            </LessValueWrapper>
                          </FieldFlex>
                        )}
                      </div>
                    ) : null}

                    { (values.valueType === 'Credit' && (values.packageType === 'Private / Semiprivate Package')) ? (
                      <div>
                        <FieldFlex>
                          <StyledText>Package Value: </StyledText>
                          <StyledField
                            name="packageValue"
                            type="select"
                            component={FieldComponent}
                            initialValue={type === 'edit' ? initialValues.packageValue : null}
                            options={inputOptions.packageValueOptions}
                            validate={validations.required}
                          />
                        </FieldFlex>

                        { values.packageValue === 'Custom' && (
                          <FieldFlex>
                            <StyledText />
                            <LessValueWrapper>
                              <LessValueField
                                name="creditValue"
                                type="number"
                                component={FieldComponent}
                                min={0}
                                step="any"
                                validate={validations.required}
                              />
                              <LessValueText> HKD </LessValueText>
                            </LessValueWrapper>
                          </FieldFlex>
                        )}
                      </div>
                    ) : (
                      <FieldFlex>
                        <StyledText>Package Value: </StyledText>
                        <LessValueWrapper>
                          <LessValueField
                            name="packageValue"
                            type="number"
                            initialValue={type === 'edit' ? initialValues.packageValue : null}
                            component={FieldComponent}
                            min={0}
                            step="any"
                            validate={validations.required}
                          />
                          <LessValueText> HKD </LessValueText>
                        </LessValueWrapper>
                      </FieldFlex>
                    )}

                    <FieldFlex>
                      <StyledText>Description: </StyledText>
                      <StyledField
                        name="description"
                        type="text"
                        component={FieldComponent}
                      />
                    </FieldFlex>

                    <FieldFlex style={{ alignItems: 'normal' }}>
                      <MultiSelectText>Student: </MultiSelectText>
                      <StyledField
                        name="student"
                        type="multi-select"
                        component={FieldComponent}
                        options={type === 'create' ? sortedChildren.filter((c) => c.status === 'Current' && !c.deletedAt) : sortedChildren.filter((c) => get(packageData, 'packageInfo.0.child', []).map((ch) => Number(ch.id)).includes(Number(c.id)) || (get(packageData, 'packageInfo.0.child', []).map((ch) => Number(ch.parentId)).includes(Number(c.parentId)) && c.status === 'Current' && !c.deletedAt))}
                        validate={validations.required}
                      />
                    </FieldFlex>
                  </MainWrapper>
                </Flex>

                <Flex direction="column">
                  <StyledFlex>
                    <Icon icon="other-info" height={20} style={{ marginRight: 5 }} />
                    <TopText> Extra Information (optional)</TopText>
                  </StyledFlex>
                  <ExtraInfoWrapper>
                    <ExtraInfoLeftWrapper>
                      <FieldFlex>
                        <StyledText>Discount: </StyledText>
                        <LessValueWrapper>
                          <LessValueField
                            name="offsetDisc"
                            type="number"
                            component={FieldComponent}
                            min={0}
                            step="any"
                          />
                          <LessValueText> HKD </LessValueText>
                        </LessValueWrapper>
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Credit Transfer: </StyledText>
                        <LessValueWrapper>
                          <LessValueField
                            name="creditTransfer"
                            type="number"
                            initialValue={type === 'edit' ? initialValues.creditTransfer : null}
                            component={FieldComponent}
                            min={0}
                            step="any"
                          />
                          <LessValueText> HKD </LessValueText>
                        </LessValueWrapper>
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Free Credit Added: </StyledText>
                        <LessValueWrapper>
                          <LessValueField
                            name="freeCredit"
                            type="number"
                            initialValue={type === 'edit' ? initialValues.freeCredit : null}
                            component={FieldComponent}
                            min={0}
                            step="any"
                          />
                          <LessValueText> HKD </LessValueText>
                        </LessValueWrapper>
                      </FieldFlex>

                      { (values.packageType === 'Private / Semiprivate Package' && values.valueType === 'Credit' && values.packageValue !== 'Custom' && values.packageValue !== null) ? (
                        <div>
                          <FieldFlex>
                            <StyledText>Lesson Discount: </StyledText>
                            <LessValueWrapper>
                              <PercentValueField
                                name="defaultDisc"
                                type="number"
                                initialValue={type === 'edit' ? editDefDiscount : defDiscount}
                                component={FieldComponent}
                                min={0}
                                step="any"
                              />
                              <LessValueText> % </LessValueText>
                            </LessValueWrapper>
                          </FieldFlex>

                          <FieldFlex>
                            <StyledText>Other Discount: </StyledText>
                            <StyledField
                              name="otherDiscount"
                              type="select"
                              component={FieldComponent}
                              options={inputOptions.otherDiscountOptions}
                              isClearable
                            />
                          </FieldFlex>
                        </div>
                      ) : (
                        <div>
                          <FieldFlex>
                            <StyledText>Lesson Discount: </StyledText>
                            <LessValueWrapper>
                              <PercentValueField
                                name="defaultDisc"
                                type="number"
                                initialValue={type === 'edit' ? editDefDiscount : defDiscount}
                                component={FieldComponent}
                                min={0}
                                step="any"
                              />
                              <LessValueText> % </LessValueText>
                            </LessValueWrapper>
                          </FieldFlex>

                          <FieldFlex>
                            <StyledText>Other Discount: </StyledText>
                            <LessValueWrapper>
                              <PercentValueField
                                name="otherDiscount"
                                type="number"
                                component={FieldComponent}
                                initialValue={type === 'edit' ? initialValues.otherDiscount : null}
                                max={100}
                                min={0}
                                step="any"
                              />
                              <LessValueText> % </LessValueText>
                            </LessValueWrapper>
                          </FieldFlex>
                        </div>
                      )}

                      <FieldFlex>
                        <StyledText>No. of Classes: </StyledText>
                        <StyledField
                          name="totalLessons"
                          type="number"
                          component={FieldComponent}
                          validate={values.packageType === 'Group Package' && validations.required}
                          min={0}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Remaining Free Trial: </StyledText>
                        <LessValueWrapper>
                          <LessValueField
                            name="freeTrial"
                            type="number"
                            component={FieldComponent}
                            initialValue={type === 'edit' ? editFreeTrial : freeTrial}
                            min={0}
                            step="any"
                          />
                          <LessValueText> mins </LessValueText>
                        </LessValueWrapper>
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Less: </StyledText>
                        <FieldFlex style={{ width: '100%', alignItems: 'baseline', flexDirection: 'row' }}>
                          <StyledField
                            style={{ width: '60%' }}
                            name="lessText"
                            type="text"
                            component={FieldComponent}
                          />

                          <LessValueWrapper style={{ width: '40%', marginLeft: '10px' }}>
                            <LessValueField
                              name="lessValue"
                              type="number"
                              component={FieldComponent}
                              min={0}
                              step="any"
                            />
                            <LessValueText> HKD </LessValueText>
                          </LessValueWrapper>
                        </FieldFlex>
                      </FieldFlex>
                    </ExtraInfoLeftWrapper>

                    <ExtraInfoRightWrapper>
                      <FieldFlex>
                        <StyledText>Commission to Instructor: </StyledText>
                        <LessValueWrapper>
                          <LessValueField
                            name="offsetCommission"
                            type="number"
                            component={FieldComponent}
                            min={0}
                            step="any"
                          />
                          <LessValueText> HKD </LessValueText>
                        </LessValueWrapper>
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Credit Card Charge: </StyledText>
                        <LessValueWrapper>
                          <LessValueField
                            name="offsetCreditCard"
                            type="number"
                            component={FieldComponent}
                            min={0}
                            step="any"
                          />
                          <LessValueText> HKD </LessValueText>
                        </LessValueWrapper>
                      </FieldFlex>

                      <FieldFlex style={{ alignItems: 'normal' }}>
                        <TextNoteText> Internal Remark: </TextNoteText>
                        <StyledField
                          name="internalRemark"
                          type="textarea"
                          component={FieldComponent}
                        />
                      </FieldFlex>

                      <FieldFlex style={{ alignItems: 'normal' }}>
                        <TextNoteText> External Remark: </TextNoteText>
                        <StyledField
                          name="externalRemark"
                          type="textarea"
                          component={FieldComponent}
                        />
                      </FieldFlex>
                    </ExtraInfoRightWrapper>
                  </ExtraInfoWrapper>
                </Flex>

                <Flex direction="column">
                  <StyledFlex>
                    <Icon icon="payment-info" height={20} style={{ marginRight: 5 }} />
                    <TopText> Payment Information </TopText>
                  </StyledFlex>
                  <Flex>
                    <MainWrapper style={{ paddingRight: 10 }}>
                      <FieldFlex>
                        <StyledText>Invoice Date: </StyledText>
                        <StyledField
                          name="invoice"
                          type="date"
                          component={FieldComponent}
                          validate={validations.required}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Due Date: </StyledText>
                        <StyledField
                          name="due"
                          type="date"
                          component={FieldComponent}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Payment Date: </StyledText>
                        <StyledField
                          name="payment"
                          type="date"
                          component={FieldComponent}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Payment Method: </StyledText>
                        <StyledField
                          name="paymentMethod"
                          type="select"
                          component={FieldComponent}
                          options={inputOptions.paymentMethodOptions}
                          isClearable
                        />
                      </FieldFlex>
                    </MainWrapper>
                  </Flex>
                </Flex>

                <Flex direction="column">
                  <StyledFlex>
                    <Icon icon="status" height={20} style={{ marginRight: 5 }} />
                    <TopText> Status </TopText>
                  </StyledFlex>
                  <Flex>
                    <MainWrapper style={{ paddingRight: 10 }}>
                      <FieldFlex>
                        <StyledText>Price Effective Date: </StyledText>
                        <StyledField
                          name="priceEffectiveDate"
                          type="date"
                          component={FieldComponent}
                        />
                      </FieldFlex>
                      <FieldFlex>
                        <StyledText>Activation Date: </StyledText>
                        <StyledField
                          name="activation"
                          type="date"
                          component={FieldComponent}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Expiry Date: </StyledText>
                        <StyledField
                          name="expiry"
                          type="date"
                          initialValue={type === 'edit' ? editExpiryDate : expiryDate}
                          component={FieldComponent}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText />
                        <Flex style={{ width: '100%' }}>
                          <StyledButton type="submit" disabled={submitting}>
                            Save
                          </StyledButton>
                        </Flex>
                      </FieldFlex>
                    </MainWrapper>
                  </Flex>
                </Flex>
              </StyledForm>
            )
          }}
        />
      </FormWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state.authentication),
})

export default connect(mapStateToProps)(EditView);
