import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import NotFound from '../NotFound';
import EditView from './EditView';
import ShowView from './ShowView';

const SettingsPage = ({ match: { path } }) => (
  <Switch>
    <Route exact path={path} component={EditView} />
    <Route path={`${path}/:id`} component={ShowView} />
    <Route component={NotFound} />
  </Switch>
);

SettingsPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default SettingsPage;
