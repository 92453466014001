import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { size, palette, font } from 'styled-theme';
import { Field, Form } from 'react-final-form';
import { Redirect, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import { validations } from '../../../utils/form';
import FieldComponent from '../../../components/molecules/FieldComponent';
import Button from '../../../components/atoms/Button';
import useUserForm from '../../../hooks/useUserForm';
import ImageUpload from '../../../components/atoms/ImageUpload';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import inputOptions from '../../../utils/form/inputOptions';
import Link from '../../../components/atoms/Link';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 100%;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
  }
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const FormWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 66px);
  height: calc(100% - 66px);
  overflow-y: auto;
  padding: 5px 25px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 5px 12px;
    max-height: calc(100% - 56px);
    height: calc(100% - 56px);
  }
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C7C7C7;
  margin-bottom: 12px;
`;

const TopText = styled.div`
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 200px;
  text-align: right;
  font-family: ${font('tertiary')};
  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledForm = styled.form`
  >div {
    >div {
      min-height: 16px;
    }
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const StyledButton = styled(Button)`
  width: 50%;
  min-width: 180px;
  margin: 20px 0px 20px;
  border-radius: 0px;

  @media (max-width: ${size('mobileBreakpoint')}){
    width: 100%;
  }
`;

const MainWrapper = styled(Flex)`
  flex-direction: column;
  width: 50%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    width: 100%;
  }
`;

const BasicWrapper = styled(Flex)`
  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
  }
`;

const BasicInfoLeftWrapper = styled(MainWrapper)`
  padding-right: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-right: 0px;
  }
`;

const BasicInfoRightWrapper = styled(MainWrapper)`
  padding-left: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-left: 0px;
  }
`;

const TextNoteText = styled(StyledText)`
  padding-top: 12px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const ResetButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 12px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }
`;

const EditView = ({ user, match, location }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [image, setImage] = useState(null);
  const [resetClick, setResetClick] = useState(false);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const { data, loading } = useGeneralData({
    endpoint: match.path === '/staff/create' ? null : 'user/userData',
    query: { userId: match.params.id, type: 'staff' },
  });

  const { onSubmit } = useUserForm({
    endpoint: match.path === '/staff/create' ? 'user/createStaff' : `user/${match.params.id}/edit`,
    setErrorMessage,
    type: 'staff',
    action: match.path === '/staff/create' ? 'create' : 'edit',
  });

  const history = useHistory();

  let type;
  if (match.path === '/staff/create') type = 'create';
  else if (match.path === '/staff/edit/:id') type = 'edit';
  else return <Redirect to="staff" />

  if (loading) return <Loading />

  if (type === 'edit' && !data) return <Redirect to="/staff" />
  if (type === 'edit' && !data.formattedUser) return <Redirect to="/staff" />
  if (type === 'edit' && !['instructor', 'admin', 'superAdmin', 'libraryHelper'].includes(data.formattedUser.role)) return <Redirect to="/staff" />

  let initialValues;

  if (type === 'edit') {
    const mainContacts = data.formattedUser.mainContact ? {
      contact: data.formattedUser.mainContact.name,
      phoneNumber: data.formattedUser.mainContact.phoneNumber,
      otherEmail: data.formattedUser.mainContact.email,
    } : {};

    initialValues = {
      ...data.formattedUser,
      ...mainContacts,
    }
  }

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> {type === 'create' ? 'Create New' : 'Edit'} </HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <ErrorAlert
            errorMessage={errorMessage}
            show={errorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw / 2)' }}
          />
        </Flex>
      </Flex>
      <FormWrapper>
        <StyledFlex>
          <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
          <TopText> Basic Information </TopText>
        </StyledFlex>
        <Form
          name="StudentForm"
          onSubmit={onSubmit}
          initialValues={initialValues}
          initialValuesEqual={() => true}
          render={({ handleSubmit, submitting, values }) => {
            if (type === 'edit') {
              values.userId = match.params.id;
            }

            values.profileImage = image;
            return (
              <StyledForm onSubmit={handleSubmit}>
                <BasicWrapper style={{ paddingRight: 10 }}>
                  <BasicInfoLeftWrapper>
                    <FieldFlex>
                      <StyledText>First Name: </StyledText>
                      <StyledField
                        name="firstName"
                        type="text"
                        component={FieldComponent}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Last Name: </StyledText>
                      <StyledField
                        name="lastName"
                        type="text"
                        component={FieldComponent}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Account: </StyledText>
                      <StyledField
                        name="email"
                        type="text"
                        component={FieldComponent}
                        validate={validations.required && validations.email}
                      />
                    </FieldFlex>

                    { type === 'create' ? (
                      <FieldFlex>
                        <StyledText>Parent Account: </StyledText>
                        <StyledField
                          name="parentEmail"
                          type="text"
                          component={FieldComponent}
                        />
                      </FieldFlex>
                    ) : null}

                    { type === 'edit' ? (
                      <FieldFlex>
                        <StyledText />
                        <Flex style={{ width: '100%' }}>
                          <ResetButton onClick={() => setResetClick(!resetClick)}>
                            <div style={{ fontFamily: 'Avenir Book' }}>Reset Password</div>
                            <Icon icon={resetClick ? 'reset-arrow-up' : 'reset-arrow-down'} height={12} style={{ marginLeft: 5 }} />
                          </ResetButton>
                        </Flex>
                      </FieldFlex>
                    ) : null}

                    { (resetClick === true || type === 'create') ? (
                      <div>
                        <FieldFlex>
                          <StyledText>New Password: </StyledText>
                          <StyledField
                            name="password"
                            type="password"
                            component={FieldComponent}
                            validate={validations.required && validations.password}
                          />
                        </FieldFlex>

                        <FieldFlex>
                          <StyledText>Confirm Password: </StyledText>
                          <StyledField
                            name="confirmPassword"
                            type="password"
                            component={FieldComponent}
                            validate={validations.required && validations.password}
                          />
                        </FieldFlex>
                      </div>
                    ) : null}

                    <FieldFlex>
                      <StyledText>Role: </StyledText>
                      <StyledField
                        name="role"
                        type="select"
                        component={FieldComponent}
                        options={inputOptions.staffRoleOptions}
                        validate={validations.required}
                        disabled={type === 'edit'}
                      />
                    </FieldFlex>
                  </BasicInfoLeftWrapper>
                  <BasicInfoRightWrapper>
                    <FieldFlex style={{ alignItems: 'normal' }}>
                      <TextNoteText style={{ width: 100, paddingBottom: 6 }}>Photo: </TextNoteText>
                      <ImageUpload image={image} setImage={setImage} existingImage={type === 'create' ? null : data.formattedUser.profileImage} endpoint="staff" />
                    </FieldFlex>
                  </BasicInfoRightWrapper>
                </BasicWrapper>

                <Flex direction="column">
                  <StyledFlex>
                    <Icon icon="contact-info" height={20} style={{ marginRight: 5 }} />
                    <TopText> Contact Information (optional)</TopText>
                  </StyledFlex>
                  <Flex>
                    <MainWrapper style={{ paddingRight: 10 }}>
                      <FieldFlex>
                        <StyledText>Main contact: </StyledText>
                        <StyledField
                          name="contact"
                          type="text"
                          component={FieldComponent}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Phone Number: </StyledText>
                        <StyledField
                          name="phoneNumber"
                          type="text"
                          component={FieldComponent}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Email: </StyledText>
                        <StyledField
                          name="otherEmail"
                          type="text"
                          component={FieldComponent}
                          validate={validations.multipleEmail}
                        />
                      </FieldFlex>
                    </MainWrapper>
                  </Flex>
                </Flex>

                <Flex direction="column">
                  <StyledFlex>
                    <Icon icon="other-info" height={20} style={{ marginRight: 5 }} />
                    <TopText> Other Information (optional)</TopText>
                  </StyledFlex>
                  <Flex>
                    <MainWrapper style={{ paddingRight: 10 }}>
                      <FieldFlex>
                        <StyledText>Address: </StyledText>
                        <StyledField
                          name="address"
                          type="text"
                          component={FieldComponent}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Education: </StyledText>
                        <StyledField
                          name="education"
                          type="text"
                          component={FieldComponent}
                        />
                      </FieldFlex>

                      <Flex>
                        <StyledText />
                        <Flex style={{ width: '100%' }}>
                          <StyledButton type="submit" disabled={submitting}>
                            Save
                          </StyledButton>
                        </Flex>
                      </Flex>
                    </MainWrapper>
                  </Flex>
                </Flex>
              </StyledForm>
            )
          }}
        />
      </FormWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state.authentication),
})

export default connect(mapStateToProps)(EditView);
