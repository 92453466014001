import { compose, withHandlers } from 'recompose';

const enhance = compose(
  withHandlers({
    onSubmit: ({ onSearch }) => values => {
      const { searchKeyword } = values;
      onSearch({ searchBy: searchKeyword });
    },
    onReset: ({ onSearch }) => (form) => {
      form.reset();
      onSearch({ searchKey: [], searchValue: [] });
    },
  }),
);

export default enhance;
