import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import { size, palette, font } from 'styled-theme';
import { ConfigProvider, Empty, Tag } from 'antd';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import moment from 'moment'
import { ifProp } from 'styled-tools';
import { Redirect } from 'react-router-dom';
import Popup from 'reactjs-popup';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import Flex from '../../../components/atoms/Flex';
import Button from '../../../components/atoms/Button';
import useCalendarForm from '../../../hooks/useCalendarForm';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import Card from '../../../components/atoms/Card';
import PopUpEvent from '../../../components/organisms/PopUpEvent';
import PopUpBlockTime from '../../../components/organisms/PopUpBlockTime';

const Wrapper = styled.div`
  padding-bottom: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
    height: 100%;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const ModelContainer = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
  > div {
    @media (min-width: calc(${size('mobileBreakpoint')} + 1px)){
      padding: 24px 24px 8px 24px;

      .ant-table-pagination-right {
        flex-wrap: wrap;
      }

      .ant-pagination-options {
        margin-bottom: 16px;
      }

      .ant-pagination-prev {
        margin-bottom: 16px;
      }
    }

    @media (max-width: ${size('mobileBreakpoint')}) {
      height: 100%;
    }
    > div: first-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        max-height: ${(props) => (props.divHeight ? 'none' : '56px')};
      }
    }
    > div: last-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        height: calc(100% - 56px);
        height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')}
      }
      > div {
        @media (max-width: ${size('mobileBreakpoint')}) {
          height: 100%;
        }
        > div {
          @media (max-width: ${size('mobileBreakpoint')}) {
            height: 100%;
          }
          > div: last-child {
            @media (max-width: ${size('mobileBreakpoint')}) {
              height: 100%;
            }
            > div {
              @media (max-width: ${size('mobileBreakpoint')}) {
                height: calc(100% - 40px);
              }
              > div {
                @media (max-width: ${size('mobileBreakpoint')}) {
                  height: 100%;
                }
                > div: last-child {
                  max-height: 100% !important;
                  @media (max-width: ${size('mobileBreakpoint')}) {
                    max-height: calc(100% - 55px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 30vw;
  min-width: 30vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledText = styled.div`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  padding: 20px 0px 30px 0px;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  display: flex;
  flex-direction: row;
  border-radius: 0px;
  margin-bottom: 0px;
  width: 100%;
`;

const PopUpErrorAlertContainer = styled.div`
  > div {
    max-width: 28vw;
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: none;
    }
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 12px;
  }
`;

const ListView = (props) => {
  const { user } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [divHeight, setDivHeight] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteAll, setDeleteAll] = useState(null);
  const [popupErrorMessage, setPopupErrorMessage] = useState(null);

  const { onSubmit: onDeleteAll } = useCalendarForm({
    endpoint: 'event/deleteSelectedNonLessons',
    setErrorMessage: setPopupErrorMessage,
    user,
    type: 'deleteAll',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (popupErrorMessage !== null) {
      setTimeout(() => {
        setPopupErrorMessage(null);
      }, 3000);
    }
  }, [popupErrorMessage]);

  if (user.role !== 'admin' && user.role !== 'superAdmin' && user.role !== 'instructor') return <Redirect to="/calendar" />;

  const cells = [
    {
      title: 'Title',
      dataIndex: 'title',
      width: 90,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 80,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Recurring',
      dataIndex: 'recurrenceType',
      key: 'recurrenceType',
      render: (content) => <Cell>{content}</Cell>,
      width: 100,
    },
    {
      title: 'Date',
      dataIndex: 'formattedDate',
      key: 'formattedDate',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Time',
      dataIndex: 'formattedTime',
      key: 'formattedTime',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Participant(s)',
      dataIndex: 'participants',
      key: 'participants',
      width: 120,
      render: (participants) => <Cell>{participants.length > 0 ? participants.map((p, index) => <span>{(index ? ', ' : '')}{user.role === 'superAdmin' ? <Link to={`/staff/show/${p.userId}`}>{`${p.lastName ? `${p.firstName} ${p.lastName}` : p.firstName}`}</Link> : `${p.lastName ? `${p.firstName} ${p.lastName}` : p.firstName}`}</span>) : 'N/A'}</Cell>,
    },
    {
      title: 'Block Time Type',
      dataIndex: 'blockType',
      key: 'blockType',
      width: 150,
      render: (blockType) => <Cell>{blockType}</Cell>,
    },
    {
      title: 'Room',
      dataIndex: 'roomName',
      key: 'roomName',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (text, record) => {
        if (user.role === 'instructor') {
          return <Flex>N/A</Flex>
        }
        return (
          <Cell>
            {record.type === 'Event' ? (
              <Flex>
                <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                  <Link to={{ pathname: `/calendar/${record.id}/editevent`, state: { type: 'eventReschedule' } }}> <StyledButton style={{ minWidth: 'auto' }}> Edit </StyledButton> </Link>
                </div>
                <div style={{ paddingLeft: 5 }}>
                  <PopUpEvent
                    user={user}
                    selectedEvent={record}
                    id={record.eventId}
                    type="delete"
                    triggerButton={<StyledButton> Delete </StyledButton>}
                  />
                </div>
              </Flex>
            ) : (
              <Flex>
                <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                  <Link to={{ pathname: `/calendar/${record.id}/editblocktime`, state: { type: 'eventReschedule' } }}> <StyledButton style={{ minWidth: 'auto' }}> Edit </StyledButton> </Link>
                </div>
                <div style={{ paddingLeft: 5 }}>
                  <PopUpBlockTime user={user} selectedEvent={record} id={record.eventId} type="delete" triggerButton={<StyledButton> Delete </StyledButton>} />
                </div>
              </Flex>
            )}
          </Cell>
        );
      }
    },
  ];

  const filterConfig = [
    {
      type: 'radioGroup',
      name: 'eventType',
      label: 'Type',
      options: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'Event',
          value: 'Event',
        },
        {
          label: 'Block Time',
          value: 'Block Time',
        },
      ],
    },
    {
      type: 'radioGroup',
      name: 'blockType',
      label: 'Block Time Type',
      options: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'Internal',
          value: 'Internal',
        },
        {
          label: 'External',
          value: 'External',
        },
        {
          label: 'N/A',
          value: 'N/A',
        },
      ],
    },
    {
      type: 'dateRange',
      name: 'date',
      label: 'Date',
      showDate: true,
      showTime: false,
    },
    {
      type: 'spec',
      name: 'staffId',
      label: 'Participant',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => (v.lastName ? `${v.firstName} ${v.lastName}` : v.firstName),
      reference: {
        endpoint: 'user',
        query: {
          where: {
            role: ['instructor', 'admin', 'superAdmin'],
            status: 'Current',
          },
          order: {
            sortKey: [
              'firstName',
              'lastName',
            ],
            sortOrder: 'asc',
          },
        },
      },
      associationType: 'own',
    },
    {
      type: 'spec',
      name: 'roomId',
      label: 'Room',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => v.name,
      reference: {
        endpoint: 'room',
      },
      associationType: 'own',
    },
  ];

  return (
    <Wrapper>
      {deleteAll ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpWrapper>
              <PopUpErrorAlertContainer>
                <ErrorAlert
                  errorMessage={popupErrorMessage}
                  show={popupErrorMessage !== null}
                />
              </PopUpErrorAlertContainer>
              <PopUpButton className="close" onClick={() => { close(); setDeleteAll(false); }}>
                &times;
              </PopUpButton>
              <PopUpTextWrapper>
                Delete All
              </PopUpTextWrapper>
              <StyledText>
                {`Are you sure you want to delete the ${selectedRowKeys.length} selected ${selectedRowKeys.length > 1 ? 'events' : 'event'}?`}
              </StyledText>
              <Form
                name="DeleteClassesFrom"
                onSubmit={onDeleteAll}
                initialValues={null}
                initialValuesEqual={() => true}
                render={({ handleSubmit, values, submitting }) => {
                  values.ids = selectedRowKeys;
                  return (
                    <StyledForm onSubmit={handleSubmit}>
                      <SubmitButton type="submit" disabled={submitting}> Confirm </SubmitButton>
                    </StyledForm>
                  )
                }}
              />
            </PopUpWrapper>
          )}
        </StyledPopup>
      ) : null}
      <Flex style={{ position: 'relative' }}>
        <HeaderText> Event Management </HeaderText>
        <Flex>
          <ErrorAlert
            errorMessage={errorMessage}
            show={errorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw / 2)' }}
          />
        </Flex>
      </Flex>
      <ModelContainer divHeight={divHeight}>
        <ModelPage
          {...props}
          showHeader={false}
          apiRoute="event/nonLessonEvents"
          itemsPerPage={25}
          filterConfig={filterConfig}
          allowSearch
          query={{ where: { type: 'Upcoming' } }}
          initialQuery={{
            currentPage: 1,
            where: {
              type: 'Upcoming',
            },
          }}
          radioGroup={['All', 'Upcoming', 'Completed']}
          radioGroupKey="type"
          showCreateButton={false}
          showExportButton
          exportDataRoute="/event/nonLessonsExport"
          actions={[
            { action: () => setDeleteAll(true), label: 'Delete All', disabled: !(selectedRowKeys && selectedRowKeys.length > 0) },
          ]}
          render={(renderProps) => {
            const { height, loading, pageSize } = renderProps;
            if (divHeight !== height) setDivHeight(height);
            if (selectedRowKeys && selectedRowKeys.length > 0 && loading) setSelectedRowKeys([]);
            return (
              <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Events" />}>
                <AntDTable
                  cellRenderers={cells}
                  pagination={{ pageSize, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: !!((user.role === 'admin' || user.role === 'superAdmin')), pageSizeOptions: [10, 20, 25, 50, 100] }}
                  rowKey="id"
                  rowSelection={{
                    selectedRowKeys,
                    onChange: (keys) => setSelectedRowKeys(keys),
                  }}
                  scroll={{ x: 'max-content', y: '65vh' }}
                  {...renderProps}
                />
              </ConfigProvider>
            )
          }}
        />
      </ModelContainer>
    </Wrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
