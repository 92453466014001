import { useState, useCallback, useEffect } from 'react';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import Promise from 'bluebird';
import { stringify } from 'qs';

export default (options = {}) => {
  const { query = {}, initialValues = {}, selectedRequest } = options;
  const {
    loading: initialLoading = true,
    error: initialError = null,
    data: initialData,
  } = initialValues;

  const [loading, setLoading] = useState(initialLoading);
  const [error, setError] = useState(initialError);
  const [data, setData] = useState(initialData);
  const [searchBy, setSearchBy] = useState(null);

  const fetchDashboardData = useCallback(async () => {
    const { api } = global;

    const params = omitBy({
      search: searchBy,
    }, (val, key) => isNil(val));

    const promise = api.get('/event/dashboard', { params });
    setLoading(true);
    return Promise.resolve()
      .then(() => promise)
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [query, searchBy]);
  const queryString = stringify(query);

  const onSearch = useCallback(async ({ searchBy }) => {
    setSearchBy(searchBy);
  }, []);

  useEffect(() => {
    fetchDashboardData();
  }, [queryString, searchBy]); // empty dependency prevents infinite loop

  return { loading, error, data, fetchDashboardData, setLoading, setError, setData, onSearch, searchBy };
};
