import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { switchProp, ifProp, prop } from 'styled-tools';

import Icon from '../../atoms/Icon';
import Button from '../../atoms/Button';

const StyledButton = styled(Button)`
  max-width: 100%;
  width: ${ifProp(
    'hasText',
    'auto',
    switchProp(prop('size'), {
      small: '24px', /* 1.5rem */
      medium: '32px', /* 2rem */
      large: '48px', /* 3rem */
    }),
  )};
  ${ifProp(
    { hasText: false },
    css`
      padding: 0;
    `,
  )};
  box-sizing: border-box;

  ${ifProp(
    'responsive',
    css`
      @media screen and (max-width: ${prop('breakpoint')}px) {
        padding: 0;
        width: ${switchProp(prop('size'), {
          small: '24px', /* 1.5rem */
          medium: '32px', /* 2rem */
          large: '48px', /* 3rem */
        })};
      }
    `,
  )};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getIconHeight = (size) => {
  switch (size) {
    case 'small':
      return '14'; /* 0.875 */
    case 'medium':
      return '16'; /* 1 */
    case 'large':
      return '18'; /* 1.125 */
    default:
      return size;
  }
};

const Text = styled.span`
  margin-left: ${ifProp(
    'right',
    '0',
    switchProp(prop('size'), {
      small: '4px', /* 0.25rem */
      medium: '4px', /* 0.375rem */
      large: '8px', /* 0.5rem */
    }),
  )};
  margin-right: ${ifProp(
    'right',
    switchProp(prop('size'), {
      small: '4px', /* 0.25rem */
      medium: '4px', /* 0.375rem */
      large: '8px', /* 0.5rem */
    }),
    '0',
  )};

  @media screen and (max-width: ${prop('breakpoint')}px) {
    display: ${ifProp('responsive', 'none !important')};
  }
`;

const IconButton = ({ icon, children, size, iconSize = 16, style, ...props }) => {
  const { breakpoint, right, responsive } = props;
  const { type, ...remaining } = props;
  const iconElement = <Icon icon={icon} height={getIconHeight(iconSize)} {...remaining} />;

  return (
    <StyledButton hasText={!!children} size={size} {...props} style={style}>
      <Wrapper>
        {right || iconElement}
        {children && (
          <Text
            responsive={responsive}
            breakpoint={breakpoint}
            right={right}
            size={size}
          >
            {children}
          </Text>
        )}
        {right && iconElement}
      </Wrapper>
    </StyledButton>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  responsive: PropTypes.bool,
  breakpoint: PropTypes.number,
  right: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

IconButton.defaultProps = {
  breakpoint: 420,
  size: 'medium',
};

export default IconButton;
