import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import kebabCase from 'lodash/kebabCase';
import { compose, withState, withProps } from 'recompose';
import orderBy from 'lodash/orderBy';
import { size } from 'styled-theme';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Spinner from '../../atoms/Spinner';

import File from './File';
import FileDropzone from '../FileDropzone';

const FileDisplayWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* margin: -${size('margin.large')}; */
  position: relative;
`;

const SmallSpinnerWrapper = styled.div`
  height: 16px;
  width: 24px;
  display: flex;
  margin-bottom: 10px;
`;

const replaceArrayElem = (array, identityFunc, replacedBy) => array.map((elem) => {
  const found = identityFunc(elem);
  if (found) return replacedBy;
  return elem;
});

const enhance = compose(
  withState('uploading', 'setUploading', false),
  withProps(({ uploading }) => ({
    loading: uploading,
    loadingContainerProps: { style: { position: 'initial' } },
  })),
  // withLoadingState(false),
  // withState('fileStatus', 'setFileStatus', []),
);

class FileManager extends Component {
  constructor(props) {
    super(props);
    this.upload = this.upload.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
  }

  componentWillMount() {
    const { input } = this.props;
    const mappedFiles = this.mapInputForDisplay(input.value);
    const pathPrefix = this.mapConfigToPathPrefix(this.props.config);
    this.setState({
      files: mappedFiles,
      pathPrefix,
    });
    input.onChange(mappedFiles);
  }

  onDrop(droppedFiles) {
    const {
      // setFileStatus,
      setUploading,
      uploading,
      limit,
    } = this.props;
    const {
      files,
    } = this.state;
    if (uploading) return;

    // setFileStatus(droppedFiles.map(() => ({ uploading: true })));
    const limitedDropppedFiles = droppedFiles.slice(0, limit);
    setUploading(true);
    Promise.all(
      limitedDropppedFiles.map((limitedDropppedFile, index) => this.upload(limitedDropppedFile, null, index).then(() => {
        //   fileStatus,
        // } = this.props;
        // setFileStatus(fileStatus.slice().splice(index, 1, { uploading: false }));
      })),
    ).then(() => {
      setUploading(false);
    });
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { input } = this.props;
    this.setState(({ files }) => ({
      files: arrayMove(files, oldIndex, newIndex),
    }));
    setTimeout(() => {
      input.onChange(this.state.files);
    }, 0);
  }

  mapInputForDisplay(urls) {
    if (Array.isArray(urls)) {
      return urls
        .filter((url) => url)
        .map((url) => {
          // Convert AWS S3 file url to file name
          const key = decodeURIComponent(url).split('-').slice(1).join('');
          return {
            src: url,
            alt: key,
            key,
          };
        });
    }
    return [];
  }

  // mapInputForDisplay(files) {
  //   return files.map(file => ({
  //     src: file.value || file.preview,
  //     alt: file.name,
  //     key: file.name,
  //   }));
  // }

  mapConfigToPathPrefix(config) {
    switch (config.endpoint) {
      case 'files':
        return `documents/${kebabCase(config.endpoint)}`;
      default:
        return `images/${kebabCase(config.endpoint)}`;
    }
  }

  async handleUploadFile(addedFile, originalFile) {
    const { setUploading, uploading } = this.props;
    if (uploading) return;

    setUploading(true);
    await this.upload(addedFile, originalFile);
    setUploading(false);
  }

  handleDeleteFile() {
    const { input } = this.props;
    return (fileToDelete) => {
      const files = this.state.files.filter((file) => file.src !== fileToDelete.src);
      this.setState({ files });
      input.onChange(files);
    };
  }

  async upload(addedFile, fileToReplace, index) {
    const amountOfExistingFiles = this.state.files.length;
    const orderOfFile = amountOfExistingFiles + index;
    const { pathPrefix } = this.state;
    const { input, limit } = this.props;
    const { name: fileName, type: fileType } = addedFile;
    let imgUrl;
    let error = null;

    const { data: signedUrl } = await global.api.get('/s3/getSignedUrl', {
      params: {
        fileName,
        fileType,
        pathPrefix,
      },
    });

    const newFile = {
      src: addedFile.preview,
      alt: addedFile.name,
      key: addedFile.name,
      order: orderOfFile,
      signedUrl,
      isNewFile: true,
      uploading: true,
      error: null,
    };

    this.setState({
      files: fileToReplace
        ? replaceArrayElem(this.state.files, (file) => file.src === fileToReplace.src, newFile)
        : orderBy([
          ...this.state.files,
          newFile,
        ], ['order'], ['asc']),
    });

    try {
      const { url } = await fetch(signedUrl, {
        method: 'PUT',
        body: addedFile,
        headers: {
          'Content-Type': fileType,
        },
      });
      [imgUrl] = url.split('?');
    } catch (err) {
      error = err;
    }

    let files = replaceArrayElem(
      this.state.files,
      (file) => file.signedUrl === newFile.signedUrl,
      {
        ...newFile,
        src: imgUrl,
        uploading: false,
        error,
      },
    );

    if (files.length > limit) {
      files = files.slice(limit);
    }

    this.setState({ files });
    // const inputFiles = files.map((f) => f.src);
    input.onChange(files);
  }

  render() {
    const {
      accept,
      width,
      height,
      uploading,
      instruction,
      maxSize,
      shouldPreview,
      renderPreview,
      limit,
      showImagePreview,
      multiple,
    } = this.props;
    const { files } = this.state;

    const SortableItem = SortableElement(({ value: file, index }) => (
      <File
        isThumbnail={index === 0}
        file={file}
        width={width}
        height={height}
        handleDeleteFile={this.handleDeleteFile()}
        uploading={file.uploading}
        error={file.error}
        handleUploadFile={this.upload}
        showImagePreview={showImagePreview}
      />
    ));
    const SortableList = SortableContainer(({ items }) => (
      <FileDisplayWrapper>
        {items.map((value, index) => (
          <SortableItem key={value.src} index={index} value={value} />
        ))}
      </FileDisplayWrapper>
    ));

    const styleForDiv = renderPreview
      ? {
        display: 'flex',
      }
      : {};
    return (
      <div style={styleForDiv}>
        <FileDropzone
          className="fileDropZone"
          accept={accept}
          onDrop={this.onDrop}
          instruction={instruction}
          maxSize={maxSize}
          multiple={multiple}
        />

        { uploading ? (
          <SmallSpinnerWrapper>
            <Spinner />
            Uploading...
          </SmallSpinnerWrapper>
        ) : null}

        {renderPreview
          && renderPreview({
            files: this.state.files,
            handleDeleteFile: this.handleDeleteFile,
            handleUploadFile: this.handleUploadFile,
          })}
        {shouldPreview && !renderPreview && this.state.files ? (
          <SortableList
            items={this.state.files.filter((file) => file.src)}
            axis="xy"
            onSortEnd={this.onSortEnd}
            distance={1}
            hideSortableGhost={false}
          />
        ) : null}
      </div>
    );
  }
}

FileManager.propTypes = {
  input: PropTypes.object,
  accept: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  instruction: PropTypes.string,
  maxSize: PropTypes.number,
  // maxNum: PropTypes.number,
  config: PropTypes.object,
  setUploading: PropTypes.func,
  shouldPreview: PropTypes.bool,
  renderPreview: PropTypes.func,
  limit: PropTypes.number,
};

FileManager.defaultProps = {
  accept: 'image/jpeg, image/png',
  width: '200px',
  height: '200px',
  instruction: 'Drop file here',
  maxSize: undefined,
  shouldPreview: true,
  showImagePreview: true,
  limit: 0,
  multiple: true,
  // maxNum: 1,
};

export default enhance(FileManager);
