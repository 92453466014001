import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { size, palette, font } from 'styled-theme';
import { ifProp } from 'styled-tools';
import { Field, Form } from 'react-final-form';
import { Redirect, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import { validations } from '../../../utils/form';
import FieldComponent from '../../../components/molecules/FieldComponent';
import Button from '../../../components/atoms/Button';
import useUserForm from '../../../hooks/useUserForm';
import ImageUpload from '../../../components/atoms/ImageUpload';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import Link from '../../../components/atoms/Link';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 100%;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
  }
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const FormWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 66px);
  height: calc(100% - 66px);
  overflow-y: auto;
  padding: 5px 25px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 5px 12px;
    max-height: calc(100% - 56px);
    height: calc(100% - 56px);
  }
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C7C7C7;
  margin-bottom: 12px;
`;

const TopText = styled.div`
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const StyledText = styled.div`
  padding: 10px 15px 22px 0px;
  width: 200px;
  text-align: right;
  font-family: ${font('tertiary')};
  font-weight: bold;

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledForm = styled.form`
  >div {
    >div {
      min-height: 16px;
    }
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const StyledButton = styled(Button)`
  width: 50%;
  min-width: 180px;
  margin: 20px 0px 20px;
  border-radius: 0px;

  @media (max-width: ${size('mobileBreakpoint')}){
    width: 100%;
  }
`;

const DashedButton = styled(Button)`
  border: 1px dashed ${palette('grayscale', 3)};
  background-color: ${ifProp('disabled', '#CCCCCC', palette('white', 0))};
  color: ${palette('black', 0)};
  border-radius: 4px;
  margin-top: 5px;
  width: 100%;
  padding: 0px;

  &:hover,
  &:focus,
  &:active {
    background-color: ${palette('white', 0)};
    color: ${palette('black', 0)};
  }
`;

const ContactText = styled.div`
  font-weight: bold;
  margin-left: 10px;
  padding-top: 1px;
  text-align: center;
`;

const MainWrapper = styled(Flex)`
  flex-direction: column;
  width: 50%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    width: 100%;
  }
`;

const BasicInfoWrapper = styled(Flex)`
  padding-right: 0px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
    padding-right: 10px;
  }
`;

const BasicInfoLeftWrapper = styled(MainWrapper)`
  padding-right: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-right: 0px;
  }
`;

const BasicInfoRightWrapper = styled(MainWrapper)`
  padding-left: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-left: 0px;
  }
`;

const SaveButtonWrapper = styled(FieldFlex)`
  @media (max-width: ${size('mobileBreakpoint')}){
    display: none;
  }
`;

const MobileSaveButtonWrapper = styled(FieldFlex)`
  display: none;
  @media (max-width: ${size('mobileBreakpoint')}){
    display: flex;
    padding-right: 10px;
  }
`;

const TextNoteText = styled(StyledText)`
  padding-top: 12px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const ResetButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 12px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }
`;

const EditView = (props) => {
  const { user, match, location } = props;
  const [additionalContacts, setAdditionalContacts] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [image, setImage] = useState(null);
  const [resetClick, setResetClick] = useState(false);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const { data, loading } = useGeneralData({
    endpoint: match.path === '/user/create' ? null : 'user/userData',
    query: { userId: match.params.id },
  });

  const { onSubmit } = useUserForm({
    endpoint: match.path === '/user/create' ? 'user/create' : `user/${match.params.id}/edit`,
    setErrorMessage,
    type: 'parent',
    action: match.path === '/user/create' ? 'create' : 'edit',
  });

  const history = useHistory();

  let type;

  if (match.path === '/user/create') type = 'create';
  else if (match.path === '/user/edit/:id') type = 'edit';
  else return <Redirect to="user" />

  if (loading) return <Loading />

  if (type === 'edit' && !data) return <Redirect to="/user" />
  if (type === 'edit' && !data.formattedUser) return <Redirect to="/user" />

  const getNumber = (arr) => {
    let num;

    if (arr.length === 0) num = 1;
    else if (arr.includes(1)) num = 2;
    else num = 1;

    return num;
  }

  let initialValues = null;

  if (type === 'edit') {
    const contacts = data.formattedUser.otherContacts;
    const mainContactsInit = data.formattedUser.mainContact;
    let otherContacts = {};
    let mainContacts = {};
    const initialContacts = [];

    if (contacts != null) {
      otherContacts = {
        contact1: contacts.name1,
        relationship1: contacts.relationship1,
        phoneNumber1: contacts.phoneNumber1,
        otherEmail1: contacts.email1,
      }

      initialContacts.push(1);

      if (contacts.name2 != null && contacts.relationship2 != null && contacts.phoneNumber2 != null && contacts.email2 != null) {
        otherContacts = {
          ...otherContacts,
          contact2: contacts.name2,
          relationship2: contacts.relationship2,
          phoneNumber2: contacts.phoneNumber2,
          otherEmail2: contacts.email2,
        }
        initialContacts.push(2);
      }
    }

    if (mainContactsInit != null) {
      mainContacts = {
        contact: data.formattedUser.mainContact.name ? data.formattedUser.mainContact.name : null,
        relationship: data.formattedUser.mainContact.relationship ? data.formattedUser.mainContact.relationship : null,
        phoneNumber: data.formattedUser.mainContact.phoneNumber ? data.formattedUser.mainContact.phoneNumber : null,
        otherEmail: data.formattedUser.mainContact.email ? data.formattedUser.mainContact.email : null,
      }
    }

    if (additionalContacts == null) setAdditionalContacts([...initialContacts]);

    initialValues = {
      firstName: data.formattedUser.firstName,
      lastName: data.formattedUser.lastName,
      email: data.formattedUser.email,
      address: data.formattedUser.address ? data.formattedUser.address : null,
      title: data.formattedUser.parentTitle || null,
      notificationEmails: data.formattedUser.notificationEmails ? data.formattedUser.notificationEmails : [],
      ...mainContacts,
      ...otherContacts,
    }
  } else if (type === 'create') {
    if (additionalContacts == null) setAdditionalContacts([]);
  }

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> {type === 'create' ? 'Create New' : 'Edit'} </HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <ErrorAlert
            errorMessage={errorMessage}
            show={errorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw / 2)' }}
          />
        </Flex>
      </Flex>
      <FormWrapper>
        <StyledFlex>
          <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
          <TopText> Basic Information </TopText>
        </StyledFlex>
        <Form
          name="UserForm"
          onSubmit={onSubmit}
          initialValues={initialValues}
          initialValuesEqual={() => true}
          render={({ handleSubmit, submitting, values }) => {
            if (type === 'edit') values.userId = match.params.id;

            if (additionalContacts.length === 1) {
              if (additionalContacts.includes(1)) {
                values.contact2 = null;
                values.relationship2 = null;
                values.phoneNumber2 = null;
                values.otherEmail2 = null;
              } else if (additionalContacts.includes(2)) {
                values.contact1 = null;
                values.relationship1 = null;
                values.phoneNumber1 = null;
                values.otherEmail1 = null;
              }
            }

            if (additionalContacts.length === 0) {
              values.contact1 = null;
              values.relationship1 = null;
              values.phoneNumber1 = null;
              values.otherEmail1 = null;
              values.contact2 = null;
              values.relationship2 = null;
              values.phoneNumber2 = null;
              values.otherEmail2 = null;
            }

            values.contactAmount = additionalContacts.length;
            values.profileImage = image;
            return (
              <StyledForm onSubmit={handleSubmit}>
                <BasicInfoWrapper style={{ paddingRight: 10 }}>
                  <BasicInfoLeftWrapper>
                    <FieldFlex>
                      <StyledText>First Name: </StyledText>
                      <StyledField
                        name="firstName"
                        type="text"
                        component={FieldComponent}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Last Name: </StyledText>
                      <StyledField
                        name="lastName"
                        type="text"
                        component={FieldComponent}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Account: </StyledText>
                      <StyledField
                        name="email"
                        type="text"
                        component={FieldComponent}
                        validate={validations.required && validations.email}
                      />
                    </FieldFlex>

                    { type === 'edit' ? (
                      <FieldFlex>
                        <StyledText />
                        <Flex style={{ width: '100%' }}>
                          <ResetButton onClick={() => setResetClick(!resetClick)}>
                            <div style={{ fontFamily: 'Avenir Book' }}>Reset Password</div>
                            <Icon icon={resetClick ? 'reset-arrow-up' : 'reset-arrow-down'} height={12} style={{ marginLeft: 5 }} />
                          </ResetButton>
                        </Flex>
                      </FieldFlex>
                    ) : null}

                    { (resetClick === true || type === 'create') ? (
                      <div>
                        <FieldFlex>
                          <StyledText>New Password: </StyledText>
                          <StyledField
                            name="password"
                            type="password"
                            component={FieldComponent}
                            validate={validations.required && validations.password}
                          />
                        </FieldFlex>

                        <FieldFlex>
                          <StyledText>Confirm Password: </StyledText>
                          <StyledField
                            name="confirmPassword"
                            type="password"
                            component={FieldComponent}
                            validate={validations.required && validations.password}
                          />
                        </FieldFlex>
                      </div>
                    ) : null}

                    <FieldFlex>
                      <StyledText>Title: </StyledText>
                      <StyledField
                        name="title"
                        type="text"
                        component={FieldComponent}
                      />
                    </FieldFlex>
                    <FieldFlex>
                              <StyledText>Other Notified Emails: </StyledText>
                              <StyledField
                                name="notificationEmails"
                                type="text-list"
                                component={FieldComponent}
                                validate={(values) => {
                                  const result = (values || []).map((value) => {
                                    const error = validations.email(value);
                                    if (error) return `${error} - ${value}`;
                                  }).filter((v) => typeof v === 'string').join(', ');
                                  return result;
                                }}
                              />
                            </FieldFlex>
                  </BasicInfoLeftWrapper>
                  <BasicInfoRightWrapper>
                    <FieldFlex style={{ alignItems: 'normal' }}>
                      <TextNoteText style={{ paddingBottom: 6 }}>Photo: </TextNoteText>
                      <ImageUpload image={image} setImage={setImage} existingImage={type === 'create' ? null : data.formattedUser.profileImage} endpoint="parent" />
                    </FieldFlex>
                  </BasicInfoRightWrapper>
                </BasicInfoWrapper>

                <Flex direction="column">
                  <StyledFlex>
                    <Icon icon="contact-info" height={20} style={{ marginRight: 5 }} />
                    <TopText> Contact Information (optional)</TopText>
                  </StyledFlex>
                  <BasicInfoWrapper style={{ paddingRight: 10 }}>
                    <BasicInfoLeftWrapper>
                      <FieldFlex>
                        <StyledText>Main contact: </StyledText>
                        <StyledField
                          name="contact"
                          type="text"
                          component={FieldComponent}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Relationship to student: </StyledText>
                        <StyledField
                          name="relationship"
                          type="text"
                          component={FieldComponent}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Phone Number: </StyledText>
                        <StyledField
                          name="phoneNumber"
                          type="text"
                          component={FieldComponent}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Email: </StyledText>
                        <StyledField
                          name="otherEmail"
                          type="text"
                          component={FieldComponent}
                          validate={validations.multipleEmail}
                        />
                      </FieldFlex>

                      <FieldFlex>
                        <StyledText>Address: </StyledText>
                        <StyledField
                          name="address"
                          type="text"
                          component={FieldComponent}
                        />
                      </FieldFlex>

                      <SaveButtonWrapper>
                        <StyledText />
                        <Flex style={{ width: '100%' }}>
                          <StyledButton type="submit" disabled={submitting}>
                            Save
                          </StyledButton>
                        </Flex>
                      </SaveButtonWrapper>
                    </BasicInfoLeftWrapper>
                    <BasicInfoRightWrapper>
                      { additionalContacts.length > 0 ? (
                        additionalContacts.map((c) => (
                          <Flex direction="column" key={c}>
                            <FieldFlex>
                              <StyledText>Other Contact: </StyledText>
                              <StyledField
                                name={`contact${c}`}
                                type="text"
                                component={FieldComponent}
                              />
                            </FieldFlex>

                            <FieldFlex>
                              <StyledText>Relationship to student: </StyledText>
                              <StyledField
                                name={`relationship${c}`}
                                type="text"
                                component={FieldComponent}
                              />
                            </FieldFlex>

                            <FieldFlex>
                              <StyledText>Phone Number: </StyledText>
                              <StyledField
                                name={`phoneNumber${c}`}
                                type="text"
                                component={FieldComponent}
                              />
                            </FieldFlex>

                            <FieldFlex>
                              <StyledText>Email: </StyledText>
                              <StyledField
                                name={`otherEmail${c}`}
                                type="text"
                                component={FieldComponent}
                                validate={validations.multipleEmail}
                              />
                            </FieldFlex>

                            <FieldFlex>
                              <StyledText />
                              <DashedButton onClick={() => setAdditionalContacts(additionalContacts.filter((i) => i !== c))}>
                                <Flex style={{ justifyContent: 'center', alignItems: 'center' }}>
                                  <Icon icon="remove-contact" height={19} />
                                  <ContactText>Remove Contact </ContactText>
                                </Flex>
                              </DashedButton>
                            </FieldFlex>
                          </Flex>
                        ))
                      ) : null}
                      <FieldFlex>
                        <StyledText />
                        <DashedButton onClick={() => setAdditionalContacts([...additionalContacts, getNumber(additionalContacts)])} disabled={additionalContacts.length >= 2}>
                          <Flex style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Icon icon="add-contact" height={19} />
                            <ContactText>Add New Contact </ContactText>
                          </Flex>
                        </DashedButton>
                      </FieldFlex>
                    </BasicInfoRightWrapper>
                  </BasicInfoWrapper>

                  <MobileSaveButtonWrapper>
                    <StyledText />
                    <Flex style={{ width: '100%' }}>
                      <StyledButton type="submit" disabled={submitting}>
                        Save
                      </StyledButton>
                    </Flex>
                  </MobileSaveButtonWrapper>
                </Flex>
              </StyledForm>
            )
          }}
        />
      </FormWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state.authentication),
})

export default connect(mapStateToProps)(EditView);
