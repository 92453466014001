import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import { compose, withState } from 'recompose';
import get from 'lodash/get';
import { Creatable } from 'react-select';
import moment from 'moment-timezone';
import { palette } from 'styled-theme';
import { Checkbox, Radio } from 'antd';
import Flex from '../../atoms/Flex';
import Block from '../../atoms/Block';
import Label from '../../atoms/Label';
import Input from '../../atoms/Input';
import Icon from '../../atoms/Icon';
import Select from '../../atoms/Select';
import P from '../../atoms/P';
import MultiSelect from '../../atoms/MultiSelect';
import ColorPicker from '../ColorPicker'
import FileManager from '../FileManager';
import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';
import DateRangePicker from '../DateRangePicker';
import SpecField from '../../../containers/SpecField';
import PasswordField from '../PasswordField';

const Wrapper = styled.div`
  label {
    input[type='checkbox'],
    input[type='radio'] {
      margin-right: 8px;
    }
  }
  margin-bottom: 8px;
  width: 100%;
`;

const Error = styled(Block)`
  font-size: 12px;
  min-height: 12px;
  color: ${palette('error', 0)};
`;

class CustomInput extends React.PureComponent {
  render() {
    const { input, ...others } = this.props;
    return (
      <Input {...others} {...input} />
    );
  }
}

const renderInput = ({
  isDirty, setIsDirty, renderError, ...props
}, invalid) => {
  const {
    label, input, meta, setUploading, maxCount,
  } = props;
  const { type } = input;
  switch (type) {
    case 'select': {
      return (
        <Select
          invalid={invalid}
          isDirty={isDirty}
          setIsDirty={setIsDirty}
          type={type}
          {...props}
        />
      );
    }
    case 'multi-select': {
      return (
        <MultiSelect
          invalid={invalid}
          isDirty={isDirty}
          setIsDirty={setIsDirty}
          type={type}
          {...props}
        />
      );
    }
    case 'text-list': {
      return (
        <Creatable
          components={{
            DropdownIndicator: null,
          }}
          className="react-select-container"
          isClearable
          isMulti
          invalid={invalid}
          isDirty={isDirty}
          onChange={(value) => {
            if (value.length > maxCount) return null;
            input.onChange(value.map((val) => val.value));
          }}
          placeholder="Type something and press enter..."
          onBlurResetsInput={false}
          onCloseResetsInput={false}
          hideSelectedOptions={false}
          value={(input.value || []).map((val) => ({
            label: val,
            value: val,
            __isNew__: true,
          }))}
          {...props}
        />
      );
    }
    case 'color': {
      return (
        <ColorPicker
          {...props}
          {...input}
        />
      );
    }
    case 'spec': {
      return (
        <SpecField
          invalid={invalid}
          isDirty={isDirty}
          {...props}
        />
      );
    }
    case 'image': {
      return (
        <FileManager
          input={input}
          limit={1}
          maxSize={2097152}
          setUploading={setUploading}
          {...props}
        />
      );
    }
    case 'file': {
      return (
        <FileManager
          input={input}
          limit={1}
          maxSize={10000000}
          setUploading={setUploading}
          {...props}
        />
      );
    }
    case 'date': {
      const { value, onChange } = input;
      const { showTimeSelect } = props;
      const dateFormat = showTimeSelect ? 'YYYY-MM-DD HH:mm Z' : 'YYYY-MM-DD';
      const dateSelected = value ? moment.tz(value, dateFormat, 'Asia/Singapore') : null;
      return (
        <DatePicker
          selected={dateSelected}
          onChange={(v) => onChange(
            v == null ? null
              : moment(v).format(dateFormat),
          )}
          customInput={<CustomInput invalid={invalid} />}
          {...props}
        />
      );
    }
    case 'time': {
      const { value, onChange } = input;
      const timeSelected = value ? moment.tz(value, 'HH:mm:ss', 'Asia/Singapore') : null;
      const { type, ...others } = input;
      return (
        <TimePicker
          selected={moment(timeSelected).isValid() ? timeSelected : null}
          onChange={(v) => onChange(
            v == null ? null : moment(v).format('HH:mm'),
          )}
          customInput={<CustomInput invalid={invalid} input={others} />}
          {...props}
        />
      );
    }
    case 'dateRange': {
      const { name } = input;
      return (
        <DateRangePicker
          name={name}
          {...props}
        />
      );
    }
    case 'number': {
      return (
        <Input
          invalid={invalid}
          type="number"
          {...input}
          {...props}
        />
      );
    }
    case 'password': {
      return (
        <PasswordField
          invalid={invalid}
          {...input}
          {...props}
        />
      );
    }
    case 'checkboxGroup': {
      const { value, onChange } = input;
      return (
        <Checkbox.Group
          value={value}
          onChange={(v) => onChange(v)}
          {...props}
        />
      );
    }
    case 'radioGroup': {
      const { value, onChange } = input;
      return (
        <Radio.Group
          value={Object.values(value)[0]}
          onChange={(v) => onChange({ equal: v.target.value })}
          {...props}
        />
      );
    }
    default: {
      return <Input invalid={invalid} {...input} {...props} />;
    }
  }
};

const FieldComponent = ({ style, className, ...props }) => {
  const {
    label,
    input,
    meta = {},
    isDirty,
    type,
    instruction,
    renderError,
    ...rest
  } = props;
  const renderInputOnly = [
    'checkbox',
    'radio',
  ].includes(type);
  const parsedRenderError = (renderError == null) ? !['dateRange'].includes(type) : renderError;
  let errorMessage = null;
  let invalid = meta.touched && !!meta.error;
  errorMessage = invalid && meta.error ? <span>{meta.error}</span> : null;
  if (type === 'select' || type === 'multi-select') {
    invalid = (meta.error && meta.invalid) || (meta.error && meta.dirty);
    if (invalid) errorMessage = <span>{meta.error}</span>;
  }
  // react-select does not set `meta.touched` to `true` after selecting an option
  // instead, it does set `meta.active` to `true` after choosing an option
  // it never sets this back to `false`, so we'll treat it as react-select's
  // `touched` values
  return (
    <Wrapper style={style} className={className}>
      {renderInputOnly && <Input type={type} label={label} invalid={invalid} {...input} />}
      <Flex direction="row" style={{ justifyContent: 'space-between' }}>
        {!renderInputOnly && label && (
          <Label
            style={{
              fontWeight: 'bold',
              textTransform: 'capitalize',
            }}
            htmlFor={input.id}
            className={classnames({ active: meta.active })}
          >
            {label}
          </Label>
        )}
        {instruction ? (
          <P onClick={() => instruction(props)} style={{ margin: 0 }}>
            Learn More
          </P>
        ) : null}
      </Flex>
      {!renderInputOnly && renderInput(props, invalid)}
      {
        parsedRenderError
        && (
        <Error id={`${input.name}Error`} role="alert" palette="red">
          {errorMessage}
        </Error>
        )
      }
    </Wrapper>
  );
};

const conditionPropType = (condition, passType, failType) => (
  props,
  propName,
  componentName,
) => {
  const propTypes = {
    [propName]: condition(props) ? passType : failType,
  };

  return PropTypes.checkPropTypes(propTypes, props, 'prop', componentName);
};

FieldComponent.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
  options: conditionPropType(
    (props) => props.type === 'select',
    PropTypes.array.isRequired,
    PropTypes.array,
  ),
  // Used by select/multi-select to manually set dirty after a
  // blur event. `react-select` doesn't handle it well enough to
  // handle showing our error messages and setting the invalid state
  isDirty: PropTypes.bool,
  setIsDirty: PropTypes.func,
  renderError: PropTypes.bool,
};

FieldComponent.defaultProps = {
};

export default compose(withState('isDirty', 'setIsDirty', false))(FieldComponent);
