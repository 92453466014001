import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import Card from '../../../components/atoms/Card';
import Button from '../../../components/atoms/Button';
import Link from '../../../components/atoms/Link';
import { selectUser } from '../../../store/authentication/selectors';
import BlockTimeForm from '../../../components/organisms/BlockTimeForm';
import useCalendarForm from '../../../hooks/useCalendarForm';
import useGeneralData from '../../../hooks/useGeneralData';
import Icon from '../../../components/atoms/Icon';
import Loading from '../../../components/atoms/Loading';
import Flex from '../../../components/atoms/Flex';
import ErrorAlert from '../../../components/molecules/ErrorAlert';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 100%;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
  }
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const FormWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 66px);
  height: calc(100% - 66px);
  overflow-y: auto;
  padding: 5px 25px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 5px 12px;
    max-height: calc(100% - 56px);
    height: calc(100% - 56px);
  }
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C7C7C7;
  margin-bottom: 12px;
`;

const TopText = styled.div`
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const OverrideButton = styled(Button)`
  min-width: 100px;
  margin: 0px;
  margin-top: 10px;
  border-radius: 0px;
`;

const BlockTimeView = (props) => {
  const { user, match, location } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorFormValue, setErrorFormValue] = useState(null);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  const type = match.path === '/calendar/blocktime' ? 'create' : 'reschedule';

  const { data, loading } = useGeneralData({
    endpoint: 'event/getEvent',
    query: {
      id: match.params.id,
    },
  });

  let id;
  let selectedEvent;

  const { onSubmit } = useCalendarForm({
    endpoint: 'event/blocktime',
    setErrorMessage,
    user,
    id,
    type: get(location, 'state.type', null) || type,
    selectedEvent,
    setErrorFormValue,
  });

  useEffect(() => {
    if (errorMessage !== null && !errorFormValue) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorFormValue, errorMessage]);

  const history = useHistory();

  if (user.role !== 'admin' && user.role !== 'superAdmin') return <Redirect to="/calendar" />;
  if (loading) return <Loading />

  if (!data) return <Redirect to="/calendar" />;
  if (user.role !== 'admin' && user.role !== 'superAdmin') return <Redirect to="/calendar" />;

  if (data.thisEvent.length === 0 && type === 'reschedule') return <Redirect to="/calendar" />;

  selectedEvent = type === 'create' ? null : data.thisEvent;
  id = type === 'create' ? null : selectedEvent[0].eventId;

  const filteredParticipants = type === 'create' ? null : data.sameStudentEvents.filter((p) => p.userId !== null);
  const participantsId = (type === 'create' || filteredParticipants.length <= 0) ? null : filteredParticipants.map((p) => p.userId);

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> {type === 'create' ? 'Create Block Time' : 'Edit Block Time'} </HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <ErrorAlert
            errorMessage={
              (errorMessage && errorFormValue) ? (
                <div>
                  {errorMessage.split(',').slice(0, 10).map((e) => <div style={{ marginBottom: 2 }}> {e} </div>)}
                  {errorMessage.split(',').length > 10 ? <div style={{ marginBottom: 2 }}> and {errorMessage.split(',').length - 10} more. </div> : null}
                  <div style={{ marginTop: 8 }}> Would you still like to proceed? </div>
                  <Flex style={{ justifyContent: 'center' }}>
                    <OverrideButton onClick={() => { errorFormValue.override = true; onSubmit(errorFormValue); }} style={{ marginRight: 15 }}> Yes </OverrideButton>
                    <OverrideButton onClick={() => { setErrorFormValue(null); setErrorMessage(null); }}> No </OverrideButton>
                  </Flex>
                </div>
              )
                : errorMessage
            }
            show={errorMessage !== null}
            style={{ position: 'absolute', maxWidth: 'none', marginTop: 8 }}
          />
        </Flex>
      </Flex>
      <FormWrapper>
        <StyledFlex>
          <Icon icon="block-time" height={24} style={{ marginRight: 5 }} />
          <TopText> Block Time Information </TopText>
        </StyledFlex>
        {type === 'create' && <BlockTimeForm onSubmit={onSubmit} errorMessage={errorMessage} selectedEvent={selectedEvent} allParticipants={data.eventParticipants} eventParticipants={participantsId} type="create" calendars={get(location, 'state.calendars', [])} /> }
        {type === 'reschedule' && (
          <BlockTimeForm
            onSubmit={onSubmit}
            errorMessage={errorMessage}
            selectedEvent={selectedEvent[0]}
            allEvents={data.allEvents}
            allParticipants={data.eventParticipants}
            eventParticipants={participantsId}
            type="reschedule" 
            calendars={get(location, 'state.calendars', [])}
          />
        )}
      </FormWrapper>
    </Wrapper>
  );
}

BlockTimeView.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: selectUser(state.authentication),
})

export default connect(mapStateToProps)(BlockTimeView);
