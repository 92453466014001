import React, { useState } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import { ConfigProvider, Empty, Space } from 'antd';
import get from 'lodash/get';
import P from '../../../components/atoms/P';
import ModelPage from '../../../containers/ModelPage';
import ModelTable from '../../../components/organisms/ModelTable';
import Loader from '../../../components/atoms/Loading';
import OverView from '../../../components/organisms/OverView';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Flex from '../../../components/atoms/Flex'

const Wrapper = styled.div`
  padding-bottom: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
    height: 100%;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const StyledSpan = styled.span`
  display: inline-block;
`;

const ModelContainer = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
  > div {
    @media (max-width: ${size('mobileBreakpoint')}) {
      height: 100%;
    }
    > div: first-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        max-height: ${(props) => (props.divHeight ? 'none' : '56px')};
      }
    }
    > div: last-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        height: calc(100% - 56px);
        height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')}
      }
      > div {
        @media (max-width: ${size('mobileBreakpoint')}) {
          height: 100%;
        }
        > div {
          @media (max-width: ${size('mobileBreakpoint')}) {
            height: 100%;
          }
          > div: last-child {
            @media (max-width: ${size('mobileBreakpoint')}) {
              height: 100%;
            }
            > div {
              @media (max-width: ${size('mobileBreakpoint')}) {
                height: calc(100% - 40px);
              }
              > div {
                @media (max-width: ${size('mobileBreakpoint')}) {
                  height: 100%;
                }
                > div: last-child {
                  max-height: 100% !important;
                  @media (max-width: ${size('mobileBreakpoint')}) {
                    max-height: calc(100% - 55px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const modelName = 'user/instructor';

const itemsPerPage = 25;

const filterConfig = [{
  type: 'multi-select',
  name: 'lessons.lessonName',
  label: 'Lesson',
  endpoint: 'lesson/lessonOptions',
  valueBy: (v) => v.lessonName,
  labelBy: (v) => v.lessonName,
}];

const ListView = (props) => {
  const { user, location } = props;
  const [divHeight, setDivHeight] = useState(0);

  const cellRenderers = [
    {
      title: 'Instructor',
      dataIndex: 'instructor',
      key: 'instructor',
      width: 110,
      render: (i) => <Cell><Link to={{ pathname: `/instructor/show/${i.id}`, state: { prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` } }}>{i.name}</Link></Cell>,
    },
    {
      title: 'Lesson(s)',
      dataIndex: 'myLessons',
      key: 'myLessons',
      width: 100,
      render: (lessons) => <Cell style={{ maxWidth: 350 }}>{(lessons ? lessons.map((l, index) => <span>{(index ? ', ' : '')}<StyledSpan>{l.lessonName}</StyledSpan></span>) : 'N/A')}</Cell>,
    },
    {
      title: 'No. of Active Students',
      dataIndex: 'activeStudents',
      key: 'activeStudents',
      render: (content) => <Cell>{content}</Cell>,
      width: 180,
    },
    {
      title: 'No. of Inactive Students',
      dataIndex: 'inactiveStudents',
      key: 'inactiveStudents',
      render: (content) => <Cell>{content}</Cell>,
      width: 190,
    },
    {
      title: 'No. of Sub-in Students',
      dataIndex: 'subInStudents',
      key: 'subInStudents',
      render: (content) => <Cell>{content}</Cell>,
      width: 180,
    },
    {
      title: 'No. of One-time Trial Students',
      dataIndex: 'ottStudents',
      key: 'ottStudents',
      render: (content) => <Cell>{content}</Cell>,
      width: 230,
    },
    {
      title: 'No. of Ex Students',
      dataIndex: 'exStudents',
      key: 'exStudents',
      render: (content) => <Cell>{content}</Cell>,
      width: 160,
    },
  ];

  return (
    <Wrapper>
      <HeaderText> Instructor Management </HeaderText>
      <ModelContainer divHeight={divHeight}>
        <ModelPage
          showHeader={false}
          modelName={modelName}
          itemsPerPage={itemsPerPage}
          filterConfig={filterConfig}
          query={{ where: { role: 'instructor' } }}
          initialQuery={{ currentPage: 1 }}
          allowSearch
          apiRoute="instructor"
          {...props}
          render={(renderProps) => {
            const { height } = renderProps;
            if (divHeight !== height) setDivHeight(height);
            return (
              <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Instructors" />}>
                <AntDTable
                  modelName={modelName}
                  cellRenderers={cellRenderers}
                  pagination={{ pageSize: itemsPerPage, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                  scroll={{ x: 'max-content', y: '65vh' }}
                  rowKey="id"
                  {...renderProps}
                />
              </ConfigProvider>
            )
          }}
        />
      </ModelContainer>
    </Wrapper>
  )
};

export default ListView;
