import { reversePalette } from 'styled-theme/composer';
import { flatten } from './utils/objects';


const theme = {};

theme.palette = {
  primary: [
    '#84CDBD',
    '#D9EEFF',
    '#84CDBDAA',
  ],
  secondary: [
    '#FFC93F',
  ],
  white: ['#fff', '#f6f9fc'],
  black: ['#000000'],
  grayscale: [
    '#9D9D9D',
    '#797979',
    '#F3F3F3',
  ],
  red: [
    '#47000a',
    '#7a0011',
    '#ad0018',
    '#e0001f',
    '#ff1435',
    '#ff4760',
    '#ff7a8c',
  ],
  yellow: [
    '#a16600',
    '#d48600',
    '#ffa408',
    '#ffb73b',
    '#ffca6e',
    '#ffdca1',
    '#ffefd4',
  ],
  green: [
    '#0d200e',
    '#1b451d',
    '#EDF9F6',
    '#388e3c',
    '#46b34b',
    '#68c46d',
    '#8dd290',
  ],
  blue: ['#072E4B'],
  black: ['#303030'],
};

theme.reversePalette = reversePalette(theme.palette);

theme.fonts = {
  primary: 'Avenir Book',
  secondary: 'Avenir Medium',
  tertiary: 'Avenir Roman',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
  quote: 'Georgia, serif',
};
const sizes = {
  padding: {
    default: '12px',
    large: '36px',
  },
  margin: {
    default: '12px',
    large: '24px',
  },
  maxWidth: '1100px',
  breakpoint: '1100px',
  mobileBreakpoint: '1024px',
};

theme.sizes = flatten(sizes);

export default theme;
