import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';

import SpecPage from '../../../containers/SpecPage';
import { selectUser } from '../../../store/authentication/selectors';
import inputOptions from '../../../utils/form/inputOptions';


const EditView = props => {
  const { user } = props;
  const isEdit = true;
  const config = {
    endpoint: 'user',
    id: user.id,
    fieldConfigs: {
      organisationId: {
        shouldChose: true,
        label: 'organisation',
        labelBy: (d) => d.name,
        // initialValue: (v) => get(v, 'userId', get(user, 'id')),
        disabled: get(user, 'role') !== 'superAdmin',
      },
      profileImage: {
        type: 'image',
        initialValue: (v) => {
          if (!get(v, 'profileImage')) return null;
          return [v.profileImage];
        },
      },
      password: {
        required: true,
      },
      confirmPassword: {
        required: true,
      },
      gender: {
        type: 'select',
        options: inputOptions.genderOptions,
      },
      age: {
        type: 'select',
        options: inputOptions.ageOptions,
      },
      educationalLevel: {
        type: 'select',
        options: inputOptions.educationalLevelOptions,
      },
      employmentStatus: {
        type: 'select',
        options: inputOptions.employmentStatusOptions,
      },
      languagePreference: {
        type: 'select',
        options: inputOptions.languagePreferenceOptions,
      }
    },
    skipFields: [
      'bookmarks',
      'setPasswordToken',
      'setPasswordTokenGeneratedAt',
      ...(isEdit ? ['password', 'confirmPassword'] : []),
      ...(get(user, 'role') !== 'superAdmin' ? ['role'] : []),
    ],
  };
  return (
    <SpecPage
      actions={[{
        action: () => window.location.href = '/reset-password',
        label: 'reset password',
      }]}
      config={config}
      showHeader
      {...props}
    />
  );
};


const mapStateToProps = (state) => {
  return {
    user: selectUser(state.authentication),
  }
}

export default connect(mapStateToProps)(EditView);
