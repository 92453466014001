import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import { Divider } from 'antd';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Flex from '../../atoms/Flex';
import FieldComponent from '../../molecules/FieldComponent';
import { validations } from '../../../utils/form';
import useUserForm from '../../../hooks/useUserForm';
import ErrorAlert from '../../molecules/ErrorAlert';
import useCalendarForm from '../../../hooks/useCalendarForm';

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 45vw;
  min-width: 45vw;
  padding: 20px;
  max-height: 98vh;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 150px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 12px;
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const TextNoteText = styled(StyledText)`
  padding-top: 14px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const ErrorAlertContainer = styled.div`
  > div {
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: 75vw;
      width: fit-content;
    }
  }
`;

const SaveButton = styled(Button)`
  min-width: 180px;
  margin: ${size('margin.default')} 0px ${size('margin.default')};
  border-radius: 0px;
  width: 100%;
`;

const PopUpTeachingNotes = ({ user, selectedRecord, type }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const { onSubmit } = useCalendarForm({
    endpoint: 'student/editTeachingNotes',
    setErrorMessage,
    type: 'attendance',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const initialValues = {
    title: selectedRecord.title,
    instructorName: selectedRecord.instructorName,
    date: selectedRecord.date,
    startTime: moment(selectedRecord.startTime).format('HH:mm'),
    endTime: moment(selectedRecord.endTime).format('HH:mm'),
    teachingNotes: selectedRecord.teachingNotes,
  };

  return (
    <StyledPopup
      trigger={<StyledButton style={{ minWidth: 'auto' }}> {type === 'view' ? 'View' : 'Edit'} </StyledButton>}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          <PopUpButton className="close" onClick={() => { close(); }}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper> {type === 'view' ? 'View' : 'Edit'} </PopUpTextWrapper>

          <div style={{ paddingRight: '20px', maxHeight: '100%', overflow: 'auto' }}>
            <Form
              name="TeachingNotesForm"
              initialValues={initialValues}
              onSubmit={onSubmit}
              initialValuesEqual={() => true}
              render={({ handleSubmit, values, submitting }) => {
                values.id = selectedRecord.id;
                return (
                  <StyledForm onSubmit={handleSubmit}>
                    <ErrorAlertContainer>
                      <ErrorAlert
                        errorMessage={errorMessage}
                        show={errorMessage !== null}
                      />
                    </ErrorAlertContainer>

                    <FieldFlex>
                      <StyledText>Lesson: </StyledText>
                      <StyledField
                        name="title"
                        type="text"
                        component={FieldComponent}
                        disabled
                      />
                    </FieldFlex>

                    { (user.role === 'admin' || user.role === 'superAdmin') ? (
                      <FieldFlex>
                        <StyledText>Instructor: </StyledText>
                        <StyledField
                          name="instructorName"
                          type="text"
                          component={FieldComponent}
                          disabled
                        />
                      </FieldFlex>
                    ) : null}

                    <FieldFlex>
                      <StyledText>Date: </StyledText>
                      <StyledField
                        name="date"
                        type="date"
                        component={FieldComponent}
                        disabled
                        isClearable={false}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Time: </StyledText>
                      <FieldFlex style={{ width: '100%', alignItems: 'baseline', flexDirection: 'row' }}>
                        <StyledField
                          name="startTime"
                          type="time"
                          component={FieldComponent}
                          disabled
                          isClearable={false}
                          validate={validations.required}
                        />

                        <div style={{ marginLeft: '10px' }}> - </div>
                        <StyledField style={{ marginLeft: '10px' }}
                          name="endTime"
                          type="time"
                          disabled
                          isClearable={false}
                          component={FieldComponent}
                        />
                      </FieldFlex>
                    </FieldFlex>

                    <FieldFlex style={{ alignItems: 'normal' }}>
                      <TextNoteText>Teaching Notes: </TextNoteText>
                      <StyledField
                        name="teachingNotes"
                        type="textarea"
                        component={FieldComponent}
                        disabled={type === 'view'}
                      />
                    </FieldFlex>

                    { type === 'edit' ? <SaveButton type="submit" disabled={submitting}> Save </SaveButton> : null}
                  </StyledForm>
                )
              }}
            />
          </div>
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpTeachingNotes.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopUpTeachingNotes;
