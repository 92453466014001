import React from 'react';
import PropTypes from 'prop-types';
import { palette } from 'styled-theme';
import { ifProp } from 'styled-tools';
import styled from 'styled-components';

import Row from '../../atoms/Row';

const StyledRow = styled(Row)`
  li {
    &:not(:first-child) {
      border-left: 1px solid ${palette('grayscale', 4)}; /* 0.0625rem */
    }
    color: ${ifProp({ isHeader: true }, palette('white', 0))};
    font-family: 'Montserrat', 'sans-serif';
    font-weight: ${ifProp({ isHeader: true}, 'bold')};
  }
  /* &:hover, */
  /* &:focus {
    background-color: ${palette('grayscale', 5)};
  } */
  text-transform: ${ifProp({ isHeader: true }, 'capitalize', 'inherit')};
  background-color: ${ifProp({ isHeader: true }, palette('primary', 0))};
`;

const TableRow = ({ ...props }) => (
  <StyledRow { ...props }/>
);

TableRow.propTypes = {
  isHeader: PropTypes.bool,
};

export default TableRow;
