import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { ifProp } from 'styled-tools';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Flex from '../../atoms/Flex';
import Link from '../../atoms/Link';
import LessonForm from '../LessonForm';
import useCalendarForm from '../../../hooks/useCalendarForm';
import Icon from '../../atoms/Icon';
import ErrorAlert from '../../molecules/ErrorAlert';

const StyledButton = styled(Button)`
  margin: ${size('margin.default')} auto ${size('margin.default')};
  border-radius: 0px;
  margin-left: 10px;
`;

const StyledButton2 = styled(Button)`
  display: flex;
  flex-direction: row;
  border-radius: 0px;
  margin-bottom: 0px;
  width: 50%;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 45vw;
  min-width: 45vw;
  padding: 20px;
  max-height: 95vh;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('primary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
  font-family: ${font('tertiary')};
`;

const StyledText = styled.div`
  font-family: ${font('primary')};
  color: ${palette('red', 3)};
  font-size: 14px;
  padding: 10px 0px 20px 0px;
  text-align: center;
`;

const StyledText2 = styled.div`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  padding: 10px 0px 20px 0px;
  text-align: center;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledIcon = styled(Icon)`
  height: 14px;
  min-width: 14px;
  margin-right: 5px;

  > svg {
    stroke: ${ifProp('disabled', '#C1C1C1', 'none')};
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton3 = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorAlertContainer = styled.div`
  > div {
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: 75vw;
      width: fit-content;
    }
  }
`;

const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
  padding-right: 10px;
  overflow-y: scroll;
`;

const PopUpLesson = ({ user, child, instructor, selectedEvent, id, studentId, eventInstructor, allLessons, type, page }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const { onSubmit } = useCalendarForm({
    endpoint: 'event',
    setErrorMessage,
    user,
    id,
    type,
    selectedEvent,
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const timeToLesson = moment(selectedEvent.startTime).diff(moment(), 'hours');
  const { lessonType, hasAttendanceConfirmed } = selectedEvent;

  let timeType = null;

  if (timeToLesson < 24) timeType = 24;
  else if (timeToLesson < 48) timeType = 48;

  const trigger = page === 'lesson' ? <StyledButton3 disabled={hasAttendanceConfirmed} style={{ color: hasAttendanceConfirmed && '#C1C1C1' }}> Cancel </StyledButton3> : <StyledButton2 disabled={hasAttendanceConfirmed} style={{ color: hasAttendanceConfirmed && '#C1C1C1' }}> <StyledIcon icon="cancel" disabled={hasAttendanceConfirmed} /> Cancel </StyledButton2>

  return (
    <StyledPopup
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          <PopUpButton className="close" onClick={close}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper>
            Cancel Class
          </PopUpTextWrapper>
          <Container>
            <ErrorAlertContainer>
              <ErrorAlert
                errorMessage={errorMessage}
                show={errorMessage !== null}
              />
            </ErrorAlertContainer>
            { (lessonType === 'Private Lesson' && timeType != null) && (
              <TopWrapper>
                <Icon icon="lesson-cancel" height="85" />
                <StyledText>
                  Please note that there is less than {timeType} hours until the class, please go to package credit history to either confirm or edit the class cancellation fee.
                </StyledText>
              </TopWrapper>
            )}
            { ((lessonType === 'Semiprivate Lesson' || lessonType === 'Group Lesson') && timeType != null) && (
              <TopWrapper>
                <Icon icon="lesson-cancel" height="85" />
                <StyledText>
                  Please note that the class will be cancelled for everyone and also that there is less than {timeType} hours until the class, please go to package credit history to either confirm or edit the class cancellation fee.
                </StyledText>
              </TopWrapper>
            )}
            { ((lessonType === 'Semiprivate Lesson' || lessonType === 'Group Lesson') && timeType == null) && (
              <TopWrapper>
                <Icon icon="lesson-cancel-more" height="85" />
                <StyledText2>
                  Please note that the class will be cancelled for everyone.
                </StyledText2>
              </TopWrapper>
            )}
            <LessonForm
              onSubmit={onSubmit}
              errorMessage={errorMessage}
              child={child}
              instructor={instructor}
              selectedEvent={selectedEvent}
              studentId={studentId}
              eventInstructor={eventInstructor}
              allLessons={allLessons}
              allPackageInfo={null}
              untilLesson={timeType}
              type="delete"
            />
          </Container>
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpLesson.propTypes = {
  user: PropTypes.object.isRequired,
  child: PropTypes.array.isRequired,
  instructor: PropTypes.array.isRequired,
  selectedEvent: PropTypes.object,
  id: PropTypes.number,
  studentId: PropTypes.array,
  eventInstructor: PropTypes.array,
  allLessons: PropTypes.array,
  type: PropTypes.string.isRequired,
  page: PropTypes.string,
};

export default PopUpLesson;
