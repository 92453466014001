import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Flex from '../../atoms/Flex';
import useRequestForm from '../../../hooks/useRequestForm';
import RequestForm from '../RequestForm';

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 45vw;
  min-width: 45vw;
  padding: 20px;
  max-height: 97vh;

  > form {
    height: calc(100% - 87px);
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const StyledText = styled.div`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 14px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const PopUpDashboardRequest = ({ open, user, request, students, instructors, allLessons, onClose }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const { onSubmit } = useRequestForm({
    endpoint: 'request',
    user,
    id: request.id,
    setErrorMessage,
  });

  let requestText = null;
  if (request.type === 'reschedule') requestText = 'Class Reschedule Request';
  else if (request.type === 'cancel') requestText = 'Class Cancellation Notification';
  else if (request.type === 'purchase') requestText = 'Purchase Request';

  return (
    <StyledPopup
      open={open}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          <PopUpButton className="close" onClick={() => { close(); onClose(); }}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper> {requestText} </PopUpTextWrapper>
          <RequestForm
            onSubmit={onSubmit}
            child={students}
            instructor={instructors}
            selectedEvent={{}}
            eventInstructor={[]}
            type={request.type}
            user={user}
            selectedRequest={request}
            allLessons={allLessons}
          />
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpDashboardRequest.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  students: PropTypes.array.isRequired,
  instructors: PropTypes.array.isRequired,
  allLessons: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopUpDashboardRequest;
