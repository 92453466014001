/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import { compose, withState, lifecycle, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import startCase from 'lodash/startCase';
import isArray from 'lodash/isArray';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import chunk from 'lodash/chunk';
import omit from 'lodash/omit';
import times from 'lodash/times';
import find from 'lodash/find';
import styled from 'styled-components';
import { palette, size } from 'styled-theme';
import { FixedSizeList as List } from 'react-window';
import Icon from '../../components/atoms/Icon';
import FieldComponent from '../../components/molecules/FieldComponent';
import Text from '../../components/atoms/P';
import sequelizeSpecToFormSpec from '../../utils/form/sequelizeSpecToFormSpec';
import Select from './Select';
import withLoadingState from '../enhancers/withLoadingState';
import ItemList from '../../components/molecules/ItemList';
import customStyle from '../../components/atoms/Select/styles';


const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const InputContainer = styled.div`
  width: 30%;
  flex-grow: 1;
  margin-right: 8px;
`;

const CloseButton = styled.div`
  cursor: pointer;
  margin: auto 0 auto ${size('margin.default')};
`;

const MenuContainer = styled.div`
  > div {
    > div {
      div {
        div {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = 35;
  const [value] = getValue();
  const listHeight = Math.min(maxHeight, height * children.length) || 200;
  let initialOffset = options.indexOf(value) * height;
  initialOffset = initialOffset > listHeight ? initialOffset : 0;
  return (
    <MenuContainer>
      { children.length ? (
        <List
          height={listHeight}
          itemCount={children.length}
          itemSize={height}
          initialScrollOffset={initialOffset}
        >
          {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
      ) : <div style={{ padding: '10px 8px' }}> No options available </div>}
    </MenuContainer>
  );
};

const enhance = compose(
  withRouter,
  withProps(() => ({
    initialValue: [],
  })),
  withState('error', 'setError', null),
  withState('spec', 'setSpec', null),
  withState('data', 'setData', null),
  withState('loading', 'setLoading', true),
  lifecycle({
    componentDidMount() {
      const {
        match: { params: { id } },
        field,
        input,
        through,
        reference,
        shouldChose,
      } = this.props;
      const { setLoading, setData, setSpec, setError, config: { endpoint, query = {} } = {} } = this.props;
      // data should be null when rendering a form for createing a new entry
      const specEndpoint = through ? `/${endpoint}/${reference.endpoint}/spec` : `/${reference.endpoint}/spec`;
      return Promise.all([
        shouldChose ? global.api.get(`/${reference.endpoint}`, { params: reference.query }) : { data: [] },
        global.api.get(specEndpoint),
      ])
        .then(([response, spec]) => {
          const fieldSpecList = sequelizeSpecToFormSpec(spec);
          setSpec(fieldSpecList);
          setData(get(response, 'data', {}));
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    },
  }),
  withLoadingState(),
);

const ItemListInput = ({ index, onRemove, currentInputValue, valuePath, options, shouldChose, input, fieldSpecList, fieldConfigs, spec }) => {
  const value = get(find(options, (option) => option.value === get(currentInputValue, [index, valuePath])), 'value');
  return (
    <RowContainer>
      {shouldChose ?
        <InputContainer>
          <Select
            type="select"
            value={value || ''}
            options={options}
            onChange={(newVal) => {
              const newInputVal = currentInputValue.slice();
              newInputVal[index] = {
                ...newInputVal[index],
                [valuePath]: newVal.value,
              };
              input.onChange(newInputVal);
            }}
          />
        </InputContainer>
      : null}
      {fieldSpecList.map((fieldSpec) => {
        const {
          name,
          type,
        } = fieldSpec;
        const inputSpec = {
          name,
          type,
        };
        // const fieldOptions = (get(find(data, { id: value }), name, []) || [])
          // .map((text) => ({ label: text, value: text }));
        const fieldConfig = get(fieldConfigs, [fieldSpec.name], {});
        const specFromProps = find(spec, { name });

        return (
          <InputContainer key={name} style={get(fieldConfig, 'containerStyle', {})}>
            <FieldComponent
              {...fieldSpec}
              {...specFromProps}
              meta={{}}
              input={{
                ...inputSpec,
                ...get(fieldConfig, 'input', {}),
                onChange: (newVal) => {
                  const fieldValue = get(newVal, 'target.value', newVal);
                  const newInputVal = currentInputValue.slice();
                  newInputVal[index] = {
                    ...newInputVal[index],
                    [name]: fieldValue,
                  };
                  input.onChange(newInputVal);
                },
                value: get(currentInputValue, [index, name], []),
              }}
              {...omit(fieldConfig, ['input', 'containerStyle'])}
            />
          </InputContainer>
        );
      })}
      <CloseButton
        role="button"
        tabIndex="0"
        onKeyDown={() => {}}
        onClick={onRemove}
      >
        <Icon
          icon="close"
        />
      </CloseButton>
    </RowContainer>
  );
};
const SpecField = (props) => {
  const {
    spec,
    data,
    input,
    config,
    label,
    shouldChose,
    disabled = false,
    name,
    labelBy = (data) => data.id,
    reference = {},
    skipFields = [],
    through,
    associationType,
    fieldConfigs = {},
    meta,
    values,
    isMulti,
    placeholder,
    invalid,
    isDirty,
    ...others
  } = props;
  const options = data ? data.map((v) => {
    return {
      value: v.id.toString(),
      label: labelBy(v),
      rawData: v,
    };
  }) : [];
  const fieldSpecList = spec ? spec.filter((s) => skipFields.indexOf(s.name) === -1) : [];
  const valuePath = `${get(reference, 'endpoint')}${capitalize(get(reference, 'key'))}`;
  const specFieldName = get(input, 'name', '') || get(reference, 'endpoint');
  const { groupBySize } = fieldConfigs;
  const currentInputValue = (isArray(input.value) && isEmpty(input.value)) ? times(groupBySize || 1).map(() => ({})) : (input.value || [{}]);
  const specProps = {
    fieldConfigs,
    disabled,
    // formDisabled,
    config,
    // setUploading,
    values,
  };
  const multiSelectChangeHandler = (func) => (values) => func(values.map((value) => value.value));
  const isInvalid = (meta.error && invalid) || (meta.error && isDirty);
  return (
    <div>
      {associationType === 'own' || !isArray(currentInputValue) ?
        shouldChose ? (
          <Select
            type="select"
            value={currentInputValue || ''}
            options={options}
            onChange={isMulti ? multiSelectChangeHandler(input.onChange) : (newVal) => {
              const newInputVal = newVal;
              input.onChange(get(newInputVal, 'value'));
            }}
            disabled={disabled}
            components={{ MenuList }}
            isMulti={isMulti}
            input={input}
            hideSelectedOptions={isMulti}
            placeholder={placeholder}
            styles={customStyle({ invalid: isInvalid, disabled })}
          />
        )
          :
          fieldSpecList.map(fieldSpec => {
            const fieldConfig = get(fieldConfigs, [fieldSpec.name], {});
            const mappedFieldConfig = omit({
              ...fieldConfig,
              ...(
                isFunction(fieldConfig.mapSpecPropsToFieldProps)
                  ? fieldConfig.mapSpecPropsToFieldProps({ ...specProps })
                  : {}
              ),
            }, 'mapSpecPropsToFieldProps');
            if (mappedFieldConfig.skip) return null;

            const FormField = get(fieldConfig, 'CustomField', Field);
            const FormFieldComponent = get(fieldConfig, 'CustomComponent', FieldComponent);
            return (
              <FormField
                {...fieldSpec}
                key={`${specFieldName}.${fieldSpec.name}`}
                name={`${specFieldName}.${fieldSpec.name}`}
                component={FormFieldComponent}
                {...mappedFieldConfig}
              />
            );
          })
      :
          <ItemList
            items={(groupBySize ? chunk(currentInputValue, 4) : currentInputValue)}
            itemName={startCase(label)}
            InputComponent={ItemListInput}
            onRemove={(data, index) => {
              const newInputVal = currentInputValue.slice();
              newInputVal.splice(groupBySize ? (index * groupBySize) : index, groupBySize || 1);
              input.onChange(newInputVal);
            }}
            onAddItem={() => {
              const newInputVal = currentInputValue.slice();
              times(groupBySize || 1).forEach(() => newInputVal.push({}));
              input.onChange(newInputVal);
            }}
            currentInputValue={currentInputValue}
            valuePath={valuePath}
            options={options}
            shouldChose={shouldChose}
            input={input}
            fieldSpecList={fieldSpecList}
            fieldConfigs={fieldConfigs}
            {...fieldConfigs}
            spec={spec}
          />
      }
    </div>
  );
};

SpecField.propTypes = {
  title: PropTypes.string,
  level: PropTypes.number,
  renderRight: PropTypes.func,
  icon: PropTypes.string,
  user: PropTypes.object,
  isPageSpecField: PropTypes.bool,
  shouldChoose: PropTypes.bool,
};
SpecField.defaultProps = {
  shouldChose: true,
};

export default enhance(SpecField);
