import React, { Component } from 'react';
import styled from 'styled-components';
// import { CSVLink } from 'react-csv';
import { compose, withState } from 'recompose';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import qs from 'qs';
import omitBy from 'lodash/omitBy';
import startCase from 'lodash/startCase';
import { size } from 'styled-theme';
import withToast from '../../../containers/enhancers/withToast';
import Button from '../../atoms/Button';
// import { normalizeCSVData } from '../../../utils/csv';
import { apiUrl } from '../../../config';


const StyledA = styled.a`
  display: none;
`

const Wrapper = styled.div`
  > button {
    border-radius: 0px;
    height: 40px;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    display: none;
  }
`

class CSVDownloadLink extends Component {
  constructor(props) {
    super(props);
    this.handleCSVDownload = this.handleCSVDownload.bind(this);
  }

  handleCSVDownload() {
    return global.authApi.get(`/getExportAuthorizationToken`)
      .then(({ data }) => {
        const { token } = data;
        this.props.setAuthorizationToken(token);
        this.csvLink.click();
      })
      .then(() => {
        this.props.showToast({
          message: `${startCase(this.props.modelName)} CSV download started`,
          type: 'success',
          autoClose: 2000,
        });
      })
      .catch(error => {
        this.props.showToast({
          message: `${startCase(this.props.modelName)} CSV download error. ${error}`,
          type: 'error',
          autoClose: 2000,
        });
      });
  }

  render() {
    const {
      modelName,
      query,
      queryParams,
      authorizationToken,
      exportDataRoute,
    } = this.props;

    const params = omitBy({
      ...{ ...query, ...queryParams, where: { ...query.where, ...queryParams.where } },
      currentPage: null,
    }, isNil);

    const apiRoute = exportDataRoute ? `${apiUrl}${exportDataRoute}` : `${apiUrl}/${modelName}/export`;

    return (
      <Wrapper>
        <Button onClick={this.handleCSVDownload}>
          Export CSV
        </Button>

        <a
          style={{ display: 'none' }}
          href={`${apiRoute}?token=${authorizationToken}&${qs.stringify(params)}`}
          target="_blank"
          rel="noopener noreferrer"
          ref={(csvLink) => {
            this.csvLink = csvLink;
          }}
        >
          Export CSV Link
        </a>
      </Wrapper>
    );
  }
}

const enhance = compose(
  withToast,
  withState('authorizationToken', 'setAuthorizationToken', null),
);

CSVDownloadLink.propTypes = {
  modelName: PropTypes.string,
  query: PropTypes.object,
  showToast: PropTypes.func,
  updateToast: PropTypes.func,
};

export default enhance(CSVDownloadLink);
