import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import get from 'lodash/get';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Link from '../../atoms/Link';
import useCalendarForm from '../../../hooks/useCalendarForm';
import Icon from '../../atoms/Icon';
import { validations } from '../../../utils/form';
import inputOptions from '../../../utils/form/inputOptions';
import FieldComponent from '../../molecules/FieldComponent';
import Flex from '../../atoms/Flex';

const StyledButton2 = styled(Button)`
  display: flex;
  flex-direction: row;
  border-radius: 0px;
  margin-bottom: 0px;
  width: 50%;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 40vw;
  min-width: 40vw;
  padding: 20px;

  > form {
    height: calc(100% - 103px);
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('primary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
  font-family: ${font('tertiary')};
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 130px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledButton = styled(Button)`
  min-width: 180px;
  margin: 20px 0px 20px;
  border-radius: 0px;
  width: 100%;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledIcon = styled(Icon)`
  height: 14px;
  min-width: 14px;
  margin-right: 5px;
`;

const StyledForm = styled.form`
  >div {
    >div {
      min-height: 16px;
    }
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const MultiSelectText = styled(StyledText)`
  padding-top: 17px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const PopUpExtendedLeave = ({ user, child, selectedEvent, recurrenceId, allEvents }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const { onSubmit } = useCalendarForm({
    endpoint: 'event/extendedleave',
    setErrorMessage,
    user,
    id: recurrenceId,
    type: 'delete',
    selectedEvent,
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const trigger = <StyledButton2> <StyledIcon icon="cancel" /> Delete </StyledButton2>

  const myChildren = child.filter((c) => c.parentId === user.id && c.status === 'Current');
  const sortedChild = myChildren.map((c) => {
    c.label = `${c.firstName} ${c.lastName}`;
    c.value = c.id;
    return c;
  }).sort((a, b) => (a.label > b.label ? 1 : -1));

  const recurringEvents = allEvents.filter((d) => d.recurrenceId === recurrenceId);
  const { reason } = JSON.parse(selectedEvent.note);

  return (
    <StyledPopup
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          <PopUpButton className="close" onClick={close}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper>
            Delete Extended Leave Request
          </PopUpTextWrapper>
          <Form
            name="ExtendedLeaveForm"
            onSubmit={onSubmit}
            initialValues={{
              student: recurringEvents.map((c) => c.childId),
              startDate: get(selectedEvent, 'startTime', null) ? moment(selectedEvent.startTime).format('YYYY-MM-DD') : null,
              endDate: get(selectedEvent, 'endTime', null) ? moment(selectedEvent.endTime).subtract(1, 'days').format('YYYY-MM-DD') : null,
              reason,
            }}
            initialValuesEqual={() => true}
            render={({ handleSubmit, submitting }) => (
              <StyledForm onSubmit={handleSubmit}>
                <div style={{ maxHeight: '100%', overflow: 'auto' }}>
                  <Flex direction="column" style={{ width: '100%', paddingRight: 10 }}>
                    <FieldFlex style={{ alignItems: 'normal ' }}>
                      <MultiSelectText>Student: </MultiSelectText>
                      <StyledField
                        name="student"
                        type="multi-select"
                        component={FieldComponent}
                        options={sortedChild}
                        disabled
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Start Date: </StyledText>
                      <StyledField
                        name="startDate"
                        type="date"
                        component={FieldComponent}
                        disabled
                        isClearable={false}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>End Date: </StyledText>
                      <StyledField
                        name="endDate"
                        type="date"
                        component={FieldComponent}
                        disabled
                        isClearable={false}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Reason: </StyledText>
                      <StyledField
                        name="reason"
                        type="select"
                        component={FieldComponent}
                        options={inputOptions.extendedLeaveReason}
                        disabled
                        validate={validations.required}
                      />
                    </FieldFlex>
                  </Flex>
                </div>

                <div style={{ paddingRight: 10 }}>
                  <StyledButton type="submit" disabled={submitting}> Confirm </StyledButton>
                </div>
              </StyledForm>
            )}
          />
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpExtendedLeave.propTypes = {
  user: PropTypes.object.isRequired,
  child: PropTypes.array.isRequired,
  selectedEvent: PropTypes.object,
  recurrenceId: PropTypes.number,
  allEvents: PropTypes.array,
};

export default PopUpExtendedLeave;
