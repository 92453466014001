import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';

import { ConfigProvider, Empty } from 'antd';
import { connect } from 'react-redux';
import { ifProp, prop } from 'styled-tools';
import Popup from 'reactjs-popup';
import { Field, Form } from 'react-final-form';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import ModelPage from '../../../containers/ModelPage';
import Loading from '../../../components/atoms/Loading';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Flex from '../../../components/atoms/Flex'
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import inputOptions from '../../../utils/form/inputOptions';
import PopUpDelete from '../../../components/organisms/PopUpDelete';
import Card from '../../../components/atoms/Card';
import Button from '../../../components/atoms/Button';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import useUserForm from '../../../hooks/useUserForm';
import { validations } from '../../../utils/form';
import FieldComponent from '../../../components/molecules/FieldComponent';

const Wrapper = styled.div`
  padding-bottom: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
    height: 100%;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const StyledSpan = styled.span`
  display: inline-block;
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;
  font-weight: normal;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 30vw;
  min-width: 30vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 120px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 12px;
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const SubmitButton = styled(Button)`
  min-width: 160px;
  width: 100%;
  margin: ${size('margin.default')} auto ${size('margin.default')};
  border-radius: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
`;

const ErrorAlertContainer = styled.div`
  > div {
    max-width: 28vw;
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: none;
    }
  }
`;

const ModelContainer = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
  > div {
    @media (max-width: ${size('mobileBreakpoint')}) {
      height: 100%;
    }
    > div: first-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        max-height: ${(props) => (props.divHeight ? 'none' : '56px')};
      }
    }
    > div: last-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        height: calc(100% - 56px);
        height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')}
      }
      > div {
        @media (max-width: ${size('mobileBreakpoint')}) {
          height: 100%;
        }
        > div {
          @media (max-width: ${size('mobileBreakpoint')}) {
            height: 100%;
          }
          > div: last-child {
            @media (max-width: ${size('mobileBreakpoint')}) {
              height: 100%;
            }
            > div {
              @media (max-width: ${size('mobileBreakpoint')}) {
                height: calc(100% - 40px);
              }
              > div {
                @media (max-width: ${size('mobileBreakpoint')}) {
                  height: 100%;
                }
                > div: last-child {
                  max-height: 100% !important;
                  @media (max-width: ${size('mobileBreakpoint')}) {
                    max-height: calc(100% - 55px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const modelName = 'student';

const ListView = (props) => {
  const { user, location } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [divHeight, setDivHeight] = useState(0);

  const { onSubmit } = useUserForm({
    endpoint: 'student/editStatus',
    setErrorMessage,
    action: 'update',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const itemsPerPage = 25;

  const cellRenderers = [
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
      render: (content) => <Cell>{content}</Cell>,
      width: 110,
    },
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
      render: (student) => <Cell><Link to={{ pathname: `/student/show/${student.id}`, state: { prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` } }}>{student.name}</Link></Cell>,
      width: 90,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      render: (content) => <Cell>{content}</Cell>,
      width: 90,
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dobDate',
      key: 'dobDate',
      render: (content) => <Cell>{content}</Cell>,
      width: 120,
    },
    {
      title: 'School',
      dataIndex: 'school',
      key: 'school',
      render: (content) => <Cell>{content}</Cell>,
      width: 90,
    },
    {
      title: 'School Year',
      dataIndex: 'schoolYear',
      key: 'schoolYear',
      render: (content) => <Cell>{content}</Cell>,
      width: 110,
    },
    {
      title: 'Lesson(s)',
      dataIndex: 'currentActiveLessons',
      key: 'currentActiveLessons',
      width: 100,
      render: (lessons) => (
        <Cell style={{ maxWidth: 350 }}>
          {(lessons
            ? uniqBy(lessons, 'lessonName')
              .map((l, index) => <span>{(index ? ', ' : '')}<StyledSpan>{l.lessonName}</StyledSpan></span>)
            : 'N/A'
          )}
        </Cell>
      ),
    },
    {
      title: 'Instructor',
      dataIndex: 'currentInstructors',
      key: 'instructor',
      width: 100,
      render: (instructorNames) => {
        const filteredInstructorNames = (instructorNames || []).filter((v) => v.id != null);
        return (
          <Cell style={{ maxWidth: 280 }}>
            {filteredInstructorNames.length > 0 ? (
              filteredInstructorNames
                .map((i, index) => (
                  <span>
                    {(index ? ', ' : '')}
                    {(user.role === 'superAdmin') ? (
                      <Link to={`/staff/show/${i.id}`}>
                        <StyledSpan>{i.name}</StyledSpan>
                      </Link>
                    ) : (
                      <StyledSpan>{i.name}</StyledSpan>
                    )}
                  </span>
                ))
            ) : 'N/A'}
          </Cell>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Cell>
          <Flex>
            <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
              <StyledPopup
                trigger={<StyledButton> Edit Status </StyledButton>}
                modal
                closeOnDocumentClick={false}
                closeOnEscape={false}
              >
                {(close) => (
                  <PopUpWrapper>
                    <PopUpButton className="close" onClick={close}>
                      &times;
                    </PopUpButton>
                    <PopUpTextWrapper> Edit Status </PopUpTextWrapper>
                    <div>
                      <Form
                        name="studentEditStatusForm"
                        onSubmit={onSubmit}
                        initialValues={{ status: record.status }}
                        initialValuesEqual={() => true}
                        render={({ handleSubmit, values, submitting }) => {
                          values.id = record.id;
                          return (
                            <StyledForm onSubmit={handleSubmit}>
                              <ErrorAlertContainer>
                                <ErrorAlert
                                  errorMessage={errorMessage}
                                  show={errorMessage !== null}
                                />
                              </ErrorAlertContainer>

                              <FieldFlex>
                                <StyledText>Status: </StyledText>
                                <StyledField
                                  name="status"
                                  type="select"
                                  component={FieldComponent}
                                  options={inputOptions.studentStatusOptions}
                                  validate={validations.required}
                                  menuPortalTarget={document.getElementById('popup-root')}
                                  menuPlacement="auto"
                                />
                              </FieldFlex>

                              <SubmitButton type="submit" disabled={submitting}> Save </SubmitButton>
                            </StyledForm>
                          )
                        }}
                      />
                    </div>
                  </PopUpWrapper>
                )}
              </StyledPopup>
            </div>
            <div style={{ paddingLeft: 5 }}>
              <PopUpDelete user={user} endpoint="student" id={record.id} type="Student" />
            </div>
          </Flex>
        </Cell>
      ),
      width: 90,
    },
  ];

  const filterConfig = [
    {
      type: 'radioGroup',
      name: 'gender',
      label: 'Gender',
      options: [{ label: 'All', value: 'All' }, ...inputOptions.genderOptions],
    },
    {
      type: 'dateRange',
      name: 'dob',
      label: 'Date of Birth',
      showYear: true,
      showTime: false,
    },
    {
      type: 'multi-select',
      name: 'lessons.lessonName',
      label: 'Lesson',
      endpoint: 'lesson/lessonOptions',
      valueBy: (v) => v.lessonName,
      labelBy: (v) => v.lessonName,
    },
    {
      type: 'spec',
      name: 'lessons->user.id',
      label: 'Instructor',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: { endpoint: 'user', query: { where: { role: 'instructor', status: 'Current' }, order: { sortKey: ['firstName', 'lastName'], sortOrder: 'asc' } } },
      associationType: 'own',
    },
  ];

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderText> Student Management </HeaderText>
      </Flex>
      <ModelContainer divHeight={divHeight} role={user.role}>
        <ModelPage
          showHeader={false}
          modelName={modelName}
          allowSearch
          itemsPerPage={itemsPerPage}
          filterConfig={user.role === 'instructor' ? filterConfig.filter((f) => f.label !== 'Instructor') : filterConfig}
          query={user.role === 'parent' ? {
            where: { parentId: user.id, status: 'Current' },
            populateClass: true,
          }
          : user.role === 'instructor' ? {
            where: { 'lessons.instructorId': user.id, status: 'Current' },
            populateClass: true,
          }
          : {
            where: { status: 'Current' },
            populateClass: true,
          }}
          initialQuery={{
            currentPage: 1,
            where: {
              status: 'Current',
            },
          }}
          radioGroup={['All', 'Current', 'Inactive', 'Past']}
          radioGroupKey="status"
          showCreateButton={user.role === 'admin' || user.role === 'superAdmin'}
          showExportButton={user.role === 'admin' || user.role === 'superAdmin'}
          createState={{ prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` }}
          {...props}
          render={(renderProps) => {
            const { height } = renderProps;
            if (divHeight !== height) setDivHeight(height);
            return (
              <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Students" />}>
                <AntDTable
                  modelName={modelName}
                  scroll={{ x: 'max-content', y: '65vh' }}
                  cellRenderers={user.role === 'instructor' ? cellRenderers.filter((c) => c.title !== 'Instructor' && c.title !== 'Action') : user.role === 'parent' ? cellRenderers.filter((c) => c.title !== 'Action') : cellRenderers}
                  rowKey="id"
                  pagination={{ pageSize: itemsPerPage, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                  {...renderProps}
                />
              </ConfigProvider>
            )
          }}
        />
      </ModelContainer>
    </Wrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
