import { combineReducers } from 'redux';

import authentication from './authentication/reducer';
import modal from './modal/reducer';
import leftMenu from './left-menu/reducer';
import leftCalendarMenu from './left-calendar-menu/reducer';
import notificationMenu from './notification-menu/reducer';

const reducers = combineReducers({
  modal,
  authentication,
  leftMenu,
  leftCalendarMenu,
  notificationMenu,
});

export default reducers;
