import get from 'lodash/get';
import find from 'lodash/find';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import startsWith from 'lodash/startsWith';
import endsWith from 'lodash/endsWith';
import toUpper from 'lodash/toUpper';
import pluralize from 'pluralize';

const fieldTypes = {
  STRING: 'text',
  TEXTAREA: 'textarea',
  DATE: 'date',
  DATEONLY: 'date',
  INTEGER: 'number',
  BIGINT: 'number',
  ENUM: 'select',
  ARRAY: (field) => {
    if (!field.values) return 'text-list';
    return 'multi-select';
  },
  RANGE: 'select',
  BOOLEAN: 'select',
  FILE: 'file',
  FILES: 'files',
  IMAGE: 'image',
  JSONB: 'object',
  LOCALIZATIONS: 'localizations',

};

const fieldOptions = {
};

// FIELD
//
// {
//   allowNull: true,
//   field: 'organisationId',
//   fieldName: 'organisationId',
//   model: 'deal',
//   references: {
//     key: 'id',
//     model: 'organisation',
//   },
//   type: 'INTEGER'
// }

const parseField = (field) => {
  const formFieldType = get(fieldTypes, field.type, 'text');
  const formField = {
    name: field.field,
    type: typeof formFieldType === 'function' ? formFieldType(field) : formFieldType,
    required: !get(field, 'allowNull', true),
    label: startCase(field.label || field.field),
    disabled: field.readOnly,
  };

  if (formField.type === 'select') {
    formField.options = field.type === 'BOOLEAN'
      ? [
        {
          value: true,
          label: 'true',
        }, {
          value: false,
          label: 'false',
        },
      ]
      : formField.options = field.type === 'RANGE'
        ? field.rangeOptions.map(v => ({
          value: v,
          label: v.length === 1
            ? `${v[0]}+`
            : `${v[0]} - ${v[1]}`,
        }))
        : field.values.map(v => ({
          value: v,
          label: startCase(v),
        }));
  }

  if (formField.type === 'multi-select') {
    formField.options = get(fieldOptions, formField.name, null);
  }

  return formField;
};

const parseFields = ({ fields, associations }) => fields.reduce((acc, field) => {
  // Check for `field.references`
  // This means it's a reference to another table
  if (field.references) {
    // Find the relevant own association for that `field`
    const fieldAssociation = find(associations, a => (
      (a.foreignKey === field.field && startsWith(lowerCase(a.type), 'belong')) ||
      startsWith(lowerCase(a.type), 'has one')
    ));
    // If the association is a `hasMany`, it will not be passed to the form
    // e.g. relationship is `Organisation.hasMany(Deal)`
    //    => foreignKey would not have relevant association in Deal's spec
    // e.g. relationship is `Deal.hasMany(Organisation)`
    //    => foreignKey would be in the Organisation table, not the Deal table
    if (fieldAssociation) {
      const formField = parseField(field);
      // endpoint: We now have `belongsTo` associations, so the endpoint to call will be
      // the `targetModel`
      // key: We will set the response as the field's options, and `response.el[i][key]`
      // will be used as the `value` for each option
      acc.push(Object.assign({}, formField, {
        type: 'spec',
        options: [],
        associationType: 'own',
        required: true,
        reference: {
          endpoint: fieldAssociation.targetModel,
          key: field.references.key,
          identifier: fieldAssociation.labeledBy,
        },
      }));
    }
  } else if (field.type !== 'VIRTUAL') {
    acc.push(parseField(field));
  }

  return acc;
}, []);


const isSupportedType = (type) => {
  return get(fieldTypes, type) != null;
};


const parseAssociations = ({ associations }) => {
  const getFieldType = (assc) => {
    const fieldType = endsWith(assc.type, 'Many') ? toUpper(pluralize(assc.targetModel)) : toUpper(assc.targetModel);
    return fieldType;
  };
  // OWN
  //
  // belongsTo
  //
  // REMOTE
  //
  // hasMany
  // belongsToMany
  const associationFormSpecs = associations
    .map((a) => {
      return {
        associationType: ['HasMany', 'HasOne', 'BelongsToMany'].indexOf(a.type) > -1 ? 'remote' : 'own',
        ...a,
      };
    })
    // ownAssociations are already handled in parseFields
    .filter((assc) => assc.associationType === 'remote')
    .filter((assc) => (isSupportedType(getFieldType(assc)) && assc.targetModel !== 'files'))
    .map(a => {
      const associatedField = {
        type: 'spec',
        unique: true,
        name: a.as,
        through: a.through,
        label: startCase(a.as),
        reference: {
          endpoint: a.targetModel,
          key: 'id',
          identifier: a.labeledBy,
        },
      };
      return associatedField;
    });
  return associationFormSpecs;
};


const parseSequelizeSpec = spec => [...parseFields(spec), ...parseAssociations(spec)];

export default parseSequelizeSpec;
