import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RDatePicker from 'react-datepicker';
import { palette } from 'styled-theme';
import { ifProp } from 'styled-tools';
import 'react-datepicker/dist/react-datepicker.css';

const InputWrap = styled.div`
  border: 1px solid ${palette('grey', 1)};
  border-radius: 6px;
  height: 56px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background-color: ${ifProp('disabled', palette('grey', 2), palette('white', 0))};
  &:hover {
    cursor: pointer;
  }
`;

const IconWrapper = styled.span`
  color: ${palette('grey', 0)};
  cursor: pointer;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Icon = ({ style }) => {
  return <IconWrapper dangerouslySetInnerHTML={{ __html: '' }} style={style} />;
};

const Input = styled.input`
  flex: 1;
  color: ${ifProp('isProfile', palette('grey', 0), palette('black', 0))};
  font-size: 16px;
  border: none;
  margin-right: 8px;
  width: calc(100% - 16px);
  max-width: calc(100% - 16px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: ${palette('grey', 2)};
    color: ${palette('grey', 0)};
  }

  ::placeholder {
    color: ${palette('black', 0)};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${palette('black', 0)};
  }

  ::-ms-input-placeholder {
    color: ${palette('black', 0)};
  }
`;

const Container = styled.div`
  position: relative;

  & .react-datepicker-wrapper {
    display: inline-block;
    width: 100%;
  }
  & .react-datepicker__input-container {
    width: 100%;
    position: relative;
    display: inline-block;
  }
  &
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    display: inline-block;
    color: ${palette('black', 0)};
    background-color: ${palette('white', 0)};
    width: 100%;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ul.react-datepicker__time-list {
    padding: 0px;
  }
  .react-datepicker__time-container {
    min-width: 100px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    padding-right: 0;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: auto;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background-color: ${palette('grayscale', 2)};
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #216ba5;
    color: ${palette('white', 0)};
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected: hover {
    background-color: #216ba5;
    color: ${palette('white', 0)};
  }
  .react-datepicker__header {
    background: ${palette('white', 0)};
  }
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle {
    border-top: none;
    /* border-bottom-color: ${palette('primary', 2)}; */
    border-bottom-color: ${palette('tertiary', 3)};
  }

  .react-datepicker__navigation {
    outline: none;
  }

  .react-datepicker__day {
    border-radius: 100%;
    color: ${palette('grayscale', 4)};

    &:hover {
      border-radius: 100%;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${palette('secondary', 0)};
    border-radius: 100%;

    &:hover {
      background-color: ${palette('secondary', 1)};
      border-radius: 100%;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    /* selected day color */
    /* background-color: ${palette('secondary', 1)}; */
    background-color: ${palette('tertiary', 3)};
    color: ${palette('white', 0)};
    &:hover {
      /* background-color: ${palette('secondary', 1)}; */
      background-color: #f0f0f0;
      color: ${palette('grayscale', 4)};
    }
  }

  .react-datepicker__time-container {
    min-width: 110px;
  }

  .react-datepicker__day-names div {
    /* color: ${palette('grey', 3)}; */
    color: #919191;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
  }

  .react-datepicker__current-month {
    /* color: ${palette('black', 0)}; */
    color: #555555;
    font-weight: bold;
    font-size: 16px;
  }

  .react-datepicker__day--outside-month {
    color: #b2b2b2;
    font-weight: normal;
    font-size: 14px;
  }

  .react-datepicker {
    font-family: inherit;
    font-weight: normal;
    font-size: 14px;
    color: ${palette('grayscale', 4)};
    border: 1px ${palette('tertiary', 3)} solid;
  }
  /* hightlight day color, apply for Today as default */
  .react-datepicker__day--highlighted {
    background-color: ${palette('primary', 1)};
    font-weight: bold;
    color: ${palette('white', 0)};
    &:hover {
      background-color: #f0f0f0;
      color: ${palette('grayscale', 4)};
      /* background-color: ${palette('primary', 2)}; */
    }
  }

  .react-datepicker__year-option {
    color: ${palette('black', 0)};
  }

  .react-datepicker__year-read-view--down-arrow {
    top: 5px;
  }

  .react-datepicker__year-read-view--selected-year {
    font-size: 12px;
    color: ${palette('black', 0)};
  }
`;

class CustomInput extends React.PureComponent {
  render() {
    const {
      onClick,
      disabled,
      arrowIconStyle,
      isProfile,
      ...other
    } = this.props;
    return (
      <InputWrap disabled={disabled} onClick={onClick}>
        <Input isProfile={isProfile} disabled={disabled} {...other} />
        <Icon style={arrowIconStyle} />
      </InputWrap>
    );
  }
}

CustomInput.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  arrowIconStyle: PropTypes.object,
  isProfile: PropTypes.bool,
};

const DatePicker = ({
  dateFormat,
  isTimePicker,
  arrowIconStyle,
  placeholder,
  onChange,
  ...other
}) => {
  return (
    <Container>
      <RDatePicker
        readOnly={false}
        isClearable
        dateFormat={isTimePicker ? 'HH:mm' : dateFormat}
        timeFormat={isTimePicker ? 'HH:mm' : null}
        showTimeSelect={isTimePicker}
        showTimeSelectOnly={isTimePicker}
        timeIntervals={15}
        highlightDates={[moment().toDate()]}
        placeholderText={placeholder}
        popperProps={{ positionFixed: true, modifiers: [{ name: 'flip' }] }}
        readOnly={other.disabled}
        onChange={other.disabled ? () => null : onChange}
        {...other}
      />
    </Container>
  );
};

DatePicker.propTypes = {
  isProfile: PropTypes.bool,
  dateFormat: PropTypes.string,
  isTimePicker: PropTypes.bool,
  selected: PropTypes.object,
  onChange: PropTypes.func,
  arrowIconStyle: PropTypes.object,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  placeholder: PropTypes.string,
};

DatePicker.defaultProps = {
  isProfile: false,
  dateFormat: 'DD MMM YYYY',
  arrowIconStyle: {
    width: '16px',
    height: '16px',
  },
  minDate: null,
  maxDate: null,
};

export default DatePicker;
