import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import Card from '../../../components/atoms/Card';
import Button from '../../../components/atoms/Button';
import Link from '../../../components/atoms/Link';
import LessonForm from '../../../components/organisms/LessonForm';
import useCalendarForm from '../../../hooks/useCalendarForm';
import Icon from '../../../components/atoms/Icon';
import { selectUser } from '../../../store/authentication/selectors';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import Flex from '../../../components/atoms/Flex';
import ErrorAlert from '../../../components/molecules/ErrorAlert';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 100%;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
  }
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const FormWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 66px);
  height: calc(100% - 66px);
  overflow-y: auto;
  padding: 5px 25px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 5px 12px;
    max-height: calc(100% - 56px);
    height: calc(100% - 56px);
  }
`;

const StyledText = styled.div`
  font-family: ${font('primary')};
  color: ${palette('red', 3)};
  font-size: 14px;
  text-align: center;
  padding-left: 5px;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 15px;
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C7C7C7;
  margin-bottom: 12px;
`;

const TopText = styled.div`
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const StyledButton = styled(Button)`
  min-width: 100px;
  margin: 0px;
  margin-top: 10px;
  border-radius: 0px;
`;

const EditView = (props) => {
  const { user, match, location } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorFormValue, setErrorFormValue] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOverrideSubmitting, setIsOverrideSubmitting] = useState(false);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  const isAllEdit = match.path === '/lesson/:recurrenceId/edit';

  const { data, loading } = useGeneralData({
    endpoint: isAllEdit ? 'lesson/getEvent' : 'event/getEvent',
    query: isAllEdit ? { where: { recurrenceId: match.params.recurrenceId }, type: 'lesson' } : { id: match.params.id, type: 'lesson' },
  });

  const { data: classData, loading: classLoading } = useGeneralData({
    endpoint: isAllEdit ? null : 'lesson',
    query: { where: { recurrenceId: match.params.recurrenceId, id: match.params.id } },
  });

  let id;
  let selectedEvent;

  const { onSubmit } = useCalendarForm({
    endpoint: isAllEdit ? 'event/updateAll' : 'event',
    setErrorMessage,
    user,
    id,
    type: 'lessonReschedule',
    selectedEvent,
    recurrenceId: match.params.recurrenceId,
    setErrorFormValue,
  });

  useEffect(() => {
    if (errorMessage !== null && !errorFormValue) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorFormValue, errorMessage]);

  const history = useHistory();

  if (loading || classLoading) return <Loading />

  if (!data || isNaN(match.params.recurrenceId)) return <Redirect to="/lesson" />;
  if (user.role !== 'admin' && user.role !== 'superAdmin') return <Redirect to={`/lesson/show/${match.params.recurrenceId}`} />;
  if (data.thisEvent.length === 0) return <Redirect to={`/lesson/show/${match.params.recurrenceId}`} />;
  if (Number(data.thisEvent[0].recurrenceId) !== Number(match.params.recurrenceId)) return <Redirect to="/lesson" />;

  selectedEvent = data.thisEvent;
  id = selectedEvent[0].eventId;

  const studentIds = isAllEdit ? data.thisEvent.map((e) => e.childId) : data.sameStudentEvents.map((e) => e.childId);
  const oldStudentIdStatus = isAllEdit ? data.thisEvent.map((e) => ({ childId: e.childId, isCancelled: e.cancelledById })) : data.sameStudentEvents.map((e) => ({ childId: e.childId, isCancelled: e.cancelledById }));
  const allPackageInfo = isAllEdit ? data.thisEvent.map((f) => ({ studentId: f.childId, packageId: f.packageId, hasFreeTrial: f.isFreeTrial })) : data.sameStudentEvents.map((f) => ({ studentId: f.childId, packageId: f.packageId, hasFreeTrial: f.isFreeTrial }));
  const instructorIds = data.allInstructor.map((i) => i.id);
  const instructorId = isAllEdit ? data.thisEvent.map((e) => e.instructorId) : data.sameStudentEvents.filter((e) => instructorIds.includes(e.instructorId)).map((e) => e.instructorId);

  const timeToLesson = moment(selectedEvent[0].startTime).diff(moment(), 'hours');

  let timeType = null;

  if (timeToLesson < 24) timeType = 24;
  else if (timeToLesson < 48) timeType = 48;

  const totalErrors = get(errorFormValue, 'totalErrors', 10);
  const isCancelErrors = get(errorFormValue, 'totalErrors', null);

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>

        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> Edit {isAllEdit ? 'Unconfirmed Classes' : 'Class'} </HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <ErrorAlert
            errorMessage={
              (errorMessage && errorFormValue) ? (
                <div>
                  {errorMessage.split(',').slice(0, totalErrors).map((e) => <div style={{ marginBottom: 2 }}> {e} </div>)}
                  {errorMessage.split(',').length > totalErrors ? <div style={{ marginBottom: 2 }}> and {errorMessage.split(',').length - totalErrors} more. </div> : null}
                  <div style={{ marginTop: 8 }}> Would you still like to proceed? </div>
                  <Flex style={{ justifyContent: 'center' }}>
                    { isCancelErrors ? (
                      <StyledButton disabled={isSubmitting} onClick={() => { setIsSubmitting(true); errorFormValue.cancelOverride = true; errorFormValue.override = false; onSubmit(errorFormValue); }} style={{ marginRight: 15 }}> Yes </StyledButton>
                    ) : (
                      <StyledButton disabled={isOverrideSubmitting} onClick={() => { setIsOverrideSubmitting(true); errorFormValue.cancelOverride = true; errorFormValue.override = true; onSubmit(errorFormValue); }} style={{ marginRight: 15 }}> Yes </StyledButton>
                    )}
                    <StyledButton onClick={() => { setIsSubmitting(false); setIsOverrideSubmitting(false); setErrorFormValue(null); setErrorMessage(null); }}> No </StyledButton>
                  </Flex>
                </div>
              )
                : errorMessage
            }
            show={errorMessage !== null}
            style={{ position: 'absolute', maxWidth: 'none', marginTop: 8 }}
          />
        </Flex>
      </Flex>
      <FormWrapper>
        <StyledFlex>
          <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
          <TopText> Lesson Information </TopText>
        </StyledFlex>
        <div>
          { timeType != null ? (
            <TopWrapper>
              <Icon icon="edit-lesson" height="24" style={{ minWidth: 24 }} />
              <StyledText>
                Please note that there is less than {timeType} hours until the class, please go to package credit history to either confirm or edit the class cancellation fee.
              </StyledText>
            </TopWrapper>
          ) : null }
          <LessonForm
            onSubmit={onSubmit}
            errorMessage={errorMessage}
            child={data.children}
            instructor={data.allInstructor}
            attendances={data.attendances}
            selectedEvent={selectedEvent[0]}
            studentId={studentIds}
            eventInstructor={instructorId}
            allLessons={data.allLessons}
            allPackageInfo={allPackageInfo}
            type={isAllEdit ? 'rescheduleAll' : 'reschedule'}
            untilLesson={timeType}
            classData={classData}
            oldStudentIdStatus={oldStudentIdStatus}
          />
        </div>
      </FormWrapper>
    </Wrapper>
  );
}

EditView.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: selectUser(state.authentication),
})

export default connect(mapStateToProps)(EditView);
