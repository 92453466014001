import React from 'react';
import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import { palette, size } from 'styled-theme';
import { validations } from '../../../utils/form';
import Button from '../../atoms/Button';
import FieldComponent from '../../molecules/FieldComponent';
import Form from '../../molecules/Form';
import Heading from '../../atoms/Heading';
import Block from '../../atoms/Block';
import P from '../../atoms/P';

const StyledHeading = styled(Heading)`
  margin: 0;
  text-align: center;
`;

const StyledP = styled(P)`
  color: #3998B5;
  margin: 0 0 ${size('margin.large')} 0;
  padding: ${size('padding.default')} 0;
  text-align: center;
  // border-bottom: 1px solid #FF9997;
`;

const StyledForm = styled.form`
  >div {
    >div {
      min-height: 16px;
    }
  }
`;

const StyledButton = styled(Button)`
  min-width: 160px;
  width: 100%;
  margin: ${size('margin.default')} auto 0;
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const Message = styled(Block)`
  font-size: 13px;
  min-height: 24px;
  color: ${ifProp('isError', palette('secondary', 0), palette('success', 4))};
`;

const ForgotPasswordForm = ({ onSubmit, error, success, ...props }) => (
  <React.Fragment>
    <StyledHeading level={4}>Reset Password</StyledHeading>
    <StyledP>Reset password and we'll email you a link</StyledP>
    <Form
      name="ForgotPasswordForm"
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, pristine }) => (
        <StyledForm onSubmit={handleSubmit}>
          <StyledField
            name="email"
            placeholder="Email"
            type="text"
            component={FieldComponent}
            validate={validations.required}
            disabled={success}
          />
          {error ? <Message isError>{error}</Message> : null}
          {success && !error ? <Message>Email has been sent</Message> : null}
          <StyledButton
            type="submit"
            disabled={pristine || submitting || success}
          >
            Send reset link
          </StyledButton>
        </StyledForm>
      )}
      {...props}
    />
  </React.Fragment>
);

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  success: PropTypes.bool,
};

export default ForgotPasswordForm;
