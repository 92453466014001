import React, { useState } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Tabs, ConfigProvider, Empty, Tag } from 'antd';
import moment from 'moment';
import Popup from 'reactjs-popup';
import get from 'lodash/get';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Button from '../../../components/atoms/Button';
import Loading from '../../../components/atoms/Loading';
import PopUpAddLesson from '../../../components/organisms/PopUpAddLesson';
import useGeneralData from '../../../hooks/useGeneralData';

const Wrapper = styled.div`
  height: 100%;
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100% - 66px);
  padding-bottom: 10px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
    padding-bottom: 0px;
  }
`;

const LeftWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 30%;
  margin-right: 15px;
  overflow: auto;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 12px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
    max-height: calc(50vh - 50px);
  }
`;

const RightWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  width: 70%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 0px;
    width: 100%;
    flex: 1;
  }
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding: 10px 0px 20px 0px;
`;

const LabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const DetailLabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px;
  min-width: 80px;
  font-family: ${font('tertiary')};
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const ModelContainer = styled.div`
  > div {
    padding: 24px 0px;
    > div {
      > * {
        :nth-child(2) {
          margin-right: 10px;
        }
      }
    }
  }

  .ant-table-body {
    max-height: 100% !important;
    @media (max-width: ${size('mobileBreakpoint')}) {
      max-height: ${(props) => (props.divHeight ? `calc(100vh - ${props.divHeight}px - 260px)` : 'calc(100vh - 56px - 260px)')} !important;
    }
  }
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 40vw;
  min-width: 40vw;
  padding: 20px;
  max-height: 95vh;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const HistoryContainer = styled.div`
  > div {
    padding: 0px;
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const ShowView = (props) => {
  const { user, match, location } = props;
  const [addLesson, setAddLesson] = useState(null);
  const [lessonRecord, setLessonRecord] = useState(null);
  const [divHeight, setDivHeight] = useState(0);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  let lessonType;

  if (match.params.id === 'private') lessonType = 'Private Lesson';
  else if (match.params.id === 'semiprivate2') lessonType = 'Semiprivate Lesson - 2 persons';
  else if (match.params.id === 'semiprivate3') lessonType = 'Semiprivate Lesson - 3 persons';
  else if (match.params.id === 'semiprivate4') lessonType = 'Semiprivate Lesson - 4 persons';
  else if (match.params.id === 'group') lessonType = 'Group Lesson';

  const { data, loading } = useGeneralData({
    endpoint: 'user/lessonTypesHistory',
    query: { type: lessonType },
  });

  const history = useHistory();

  if (loading) return <Loading />
  if (!lessonType) return <Redirect to="/settings" />
  if (user.role !== 'superAdmin') return <Redirect to="/settings" />

  const { TabPane } = Tabs;

  const clickedButton = (type, record) => {
    setLessonRecord(record);
    setAddLesson(type);
  }

  const historyColumns = [
    {
      title: 'Base Pricing per Hour',
      dataIndex: 'price',
      key: 'price',
      render: (price) => (price ? `$${price.toLocaleString()}` : 'N/A'),
      width: '60%',
    },
    {
      title: 'Edit Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment.tz(createdAt, 'Asia/Singapore').format('DD/MM/YYYY'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Cell>
          <Flex>
            <StyledButton style={{ minWidth: 'auto' }} onClick={() => clickedButton('update', record)}> Edit </StyledButton>
          </Flex>
        </Cell>
      )
    }
  ]

  const cellRenderers = [
    {
      title: 'Lesson',
      dataIndex: 'name',
      key: 'name',
      render: (content) => <Cell>{content}</Cell>,
      width: 90,
    },
    {
      title: 'Base Pricing per Hour',
      dataIndex: 'price',
      key: 'price',
      render: (price) => {
        const formattedPrice = price == null ? 'N/A' : `$${price.toLocaleString()}`;
        return <Cell>{formattedPrice}</Cell>;
      },
      width: 180,
    },
    {
      title: 'Last Edit',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => <Cell>{moment.tz(createdAt, 'Asia/Singapore').format('DD/MM/YYYY')}</Cell>,
      width: 100,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Cell>
          <Flex>
            <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
              <StyledButton style={{ minWidth: 'auto' }} onClick={() => clickedButton('edit', record)}> Edit </StyledButton>
            </div>
            <div style={{ paddingLeft: 5 }}>
              <StyledPopup
                trigger={<StyledButton style={{ margin: 0 }}> History </StyledButton>}
                modal
                closeOnDocumentClick={false}
                closeOnEscape={false}
              >
                {(close) => (
                  <PopUpWrapper>
                    <PopUpButton className="close" onClick={close}>
                      &times;
                    </PopUpButton>
                    <PopUpTextWrapper>
                      History
                    </PopUpTextWrapper>
                    <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Prices" />}>
                      <AntDTable
                        cellRenderers={historyColumns}
                        data={data.filter((c) => c.lessonTypeId === record.lessonTypeId)}
                        rowKey="id"
                        scroll={{ x: data.filter((c) => c.lessonTypeId === record.lessonTypeId).length > 0 ? 'max-content' : '100%', y: '70vh' }}
                      />
                    </ConfigProvider>
                  </PopUpWrapper>
                )}
              </StyledPopup>
            </div>
          </Flex>
        </Cell>
      ),
      width: 90,
    },
  ];

  return (
    <Wrapper>
      {addLesson ? (
        <PopUpAddLesson
          open
          type={addLesson}
          record={lessonRecord}
          onClose={() => setAddLesson(null)}
        />
      ) : null}
      <HeaderTextWrapper>
        <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
        <HeaderText> Settings </HeaderText>
      </HeaderTextWrapper>
      <BottomWrapper>
        <LeftWrapper>
          <StyledFlex>
            <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
            <LabelText style={{ marginRight: 0 }}> Lesson Information </LabelText>
          </StyledFlex>
          <Flex>
            <DetailLabelText> Lesson Type </DetailLabelText>
            <div>{lessonType}</div>
          </Flex>
        </LeftWrapper>
        <RightWrapper>
          <Tabs defaultActiveKey="lessonType">
            <TabPane tab="Lesson Type" key="lessonType">
              <ModelContainer divHeight={divHeight}>
                <ModelPage
                  showHeader={false}
                  modelName="user/lessonTypes"
                  itemsPerPage={25}
                  query={{ where: { type: lessonType } }}
                  initialQuery={{ currentPage: 1 }}
                  actions={[{
                    action: () => clickedButton('create', lessonType),
                    label: 'Create New',
                  }]}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (divHeight !== height) setDivHeight(height);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Lessons" />}>
                        <AntDTable
                          modelName="user/lessonTypes"
                          cellRenderers={cellRenderers}
                          scroll={{ x: 'max-content', y: '40vh' }}
                          pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
          </Tabs>
        </RightWrapper>
      </BottomWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state.authentication),
  }
}

export default connect(mapStateToProps)(ShowView);
