import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { palette, size } from 'styled-theme';
import { switchProp, prop } from 'styled-tools';
import omit from 'lodash/omit';

const styles = css`
  color: ${({ color }) => color ? color : palette({ gray: 0 }, 0)};
  font-family: ${prop('fontFamily', 'Montserrat, sans-serif;')};
  font-weight: ${size('fontWeight.bold')};
  font-size: ${switchProp(prop('level'), {
    1: size('fontSize.heading.h1'),
    2: size('fontSize.heading.h2'),
    3: size('fontSize.heading.h3'),
    4: size('fontSize.heading.h4'),
  })};
  line-height: ${switchProp(prop('level'), {
    1: size('lineHeight.heading.h1'),
    2: size('lineHeight.heading.h2'),
    3: size('lineHeight.heading.h3'),
    4: size('lineHeight.heading.h4'),
  })};
  margin-top: ${switchProp(prop('level'), {
    1: '24px',
    2: '12px',
    3: '0',
    4: '0',
  })};
  margin-bottom: ${switchProp(prop('level'), {
    1: '3rem',
    2: '24px',
    3: '0',
    4: '0',
  })};
`;

const Heading = styled(
  ({ level, children, reverse, palette, theme, ...props }) =>
    React.createElement(`h${level}`, omit(props, ['isPageHeader']), children),
)`
  ${styles};
`;

Heading.propTypes = {
  level: PropTypes.number,
  children: PropTypes.node,
  palette: PropTypes.string,
  reverse: PropTypes.bool,
  fontFamily: PropTypes.string,
};

Heading.defaultProps = {
  level: 1,
  palette: 'primary',
};

export default Heading;
