import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';


class RouteScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

RouteScrollToTop.propTypes = {
  children: PropTypes.any.isRequired,
  location: PropTypes.object,
};

export default withRouter(RouteScrollToTop);
