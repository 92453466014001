import React from 'react';
import PropTypes from 'prop-types';
import { TabList, TabPanel } from 'react-tabs';
import startCase from 'lodash/startCase';

import {
  StyledContent,
  StyledTab,
  StyledTabs,
} from './styled';

import P from '../../atoms/P';

const TabComponent = ({ label, ...others }) => {
  const {
    selected,
  } = others;
  return (
    <StyledTab {...others}>
      <P>{startCase(label)}</P>
    </StyledTab>
  );
};
TabComponent.tabsRole = 'Tab';

const ContentComponent = ({ label }) => <P>{label}</P>;

const ThemedTab = (props) => {
  const {
    tabs,
    renderPane,
    TabComponent,
    selectedTabIndex,
    ...others
  } = props;

  return (
    <StyledTabs selectedIndex={selectedTabIndex === -1 ? 0 : selectedTabIndex} {...others}>
      <TabList>
        {tabs.map(({ label }) => (
          <TabComponent key={label} label={label} />
        ))}
      </TabList>
      {tabs.map((tab) => {
        const { label } = tab;
          return (
            renderPane &&
            <TabPanel key={label}>
              <StyledContent>
                {renderPane({ ...props, tab })}
              </StyledContent>
            </TabPanel>
            );
      })}
    </StyledTabs>
  );
};

ThemedTab.defaultProps = {
  tabs: [],
  TabComponent,
  forceRenderTabPanel: true,
};

ThemedTab.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    filterBy: PropTypes.object,
  })),
  TabComponent: PropTypes.func,
  forceRenderTabPanel: PropTypes.bool,
  selectedTabIndex: PropTypes.number,
};

export default ThemedTab;
export { TabComponent };
