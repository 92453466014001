import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { size, palette, font } from 'styled-theme';
import { ifProp } from 'styled-tools';
import { Field, Form } from 'react-final-form';
import { Redirect, useHistory } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash/get';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import { validations } from '../../../utils/form';
import FieldComponent from '../../../components/molecules/FieldComponent';
import Button from '../../../components/atoms/Button';
import useUserForm from '../../../hooks/useUserForm';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import inputOptions from '../../../utils/form/inputOptions';
import ImageUpload from '../../../components/atoms/ImageUpload';
import Link from '../../../components/atoms/Link';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 100%;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
  }
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const FormWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 66px);
  height: calc(100% - 66px);
  overflow-y: auto;
  padding: 5px 25px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 5px 12px;
    max-height: calc(100% - 56px);
    height: calc(100% - 56px);
  }
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C7C7C7;
  margin-bottom: 12px;
`;

const TopText = styled.div`
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 10px 15px 22px 0px;
  width: 200px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledForm = styled.form`
  >div {
    >div {
      min-height: 16px;
    }
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const StyledButton = styled(Button)`
  width: 50%;
  min-width: 180px;
  margin: 20px 0px 20px;
  border-radius: 0px;

  @media (max-width: ${size('mobileBreakpoint')}){
    width: 100%;
  }
`;

const MainWrapper = styled(Flex)`
  flex-direction: column;
  width: 50%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    width: 100%;
  }
`;

const BasicWrapper = styled(Flex)`
  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
  }
`;

const BasicInfoLeftWrapper = styled(MainWrapper)`
  padding-right: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-right: 0px;
  }
`;

const BasicInfoRightWrapper = styled(MainWrapper)`
  padding-left: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-left: 0px;
  }
`;

const TextNoteText = styled(StyledText)`
  padding-top: 12px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const SaveButtonWrapper = styled(FieldFlex)`
  @media (max-width: ${size('mobileBreakpoint')}){
    display: none;
  }
`;

const MobileSaveButtonWrapper = styled(FieldFlex)`
  display: none;
  @media (max-width: ${size('mobileBreakpoint')}){
    display: flex;
    padding-right: 10px;
  }
`;

const PublisherStyledText = styled(StyledText)`
  display: block;
  @media (max-width: ${size('mobileBreakpoint')}){
    display: none;
  }
`;

const MobilePublisherStyledText = styled(StyledText)`
  display: none;
  @media (max-width: ${size('mobileBreakpoint')}){
    display: block;
  }
`;

const EditView = (props) => {
  const { user, match, location } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [image, setImage] = useState(null);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const { data, loading } = useGeneralData({
    endpoint: 'inventory/inventoryInfo',
    query: { id: match.path === '/inventory/create' ? null : match.params.id },
  });

  const { data: publisherMakerData, loading: publisherMakerLoading } = useGeneralData({
    endpoint: 'inventory/publisherOrMakerOptions',
  });

  const { data: inventorySectionsData, loading: inventorySectionsLoading } = useGeneralData({
    endpoint: 'inventory/inventorySections',
  });

  const { onSubmit } = useUserForm({
    endpoint: match.path === '/inventory/create' ? 'inventory/' : `inventory/edit/${match.params.id}`,
    setErrorMessage,
    type: 'inventory',
    action: match.path === '/inventory/create' ? 'create' : 'edit',
  });

  const history = useHistory();

  let type;

  if (match.path === '/inventory/create') type = 'create';
  else if (match.path === '/inventory/edit/:id') type = 'edit';
  else return <Redirect to="inventory" />

  if (loading || publisherMakerLoading || inventorySectionsLoading) return <Loading />

  if (!data) return <Redirect to="/inventory" />;
  if (type === 'edit' && !data.product) return <Redirect to="/inventory" />;

  const mappedPublisherMaker = [...publisherMakerData, { publisherOrMaker: 'Others'}].map((p) => {
    p.label = p.publisherOrMaker;
    p.value = p.publisherOrMaker;
    return p;
  })

  let initialValues = null;

  if (type === 'edit') {
    const customObj = {};
    if (get(data, 'product.publisherOrMaker', null) && mappedPublisherMaker.map((pm) => pm.value).includes(get(data, 'product.publisherOrMaker', null)) === false) {
      customObj.publisherOrMaker = 'Others';
      customObj.customPublisherMaker = get(data, 'product.publisherOrMaker', null);
    }
    initialValues = {
      ...data.product,
      productCode: `INV-PR${data.product.id}`,
      inventoryType: data.product.type,
      ...customObj,
    };
  } else if (type === 'create') {
    initialValues = { productCode: `INV-PR${data.latestId}` };
  }

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> {type === 'create' ? 'Create New' : 'Edit'} </HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <ErrorAlert
            errorMessage={errorMessage}
            show={errorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw  / 2)' }}
          />
        </Flex>
      </Flex>
      <FormWrapper>
        <StyledFlex>
          <Icon icon="library-info" height={20} style={{ marginRight: 5 }} />
          <TopText> Inventory Management </TopText>
        </StyledFlex>
        <Form
          name="InventoryForm"
          onSubmit={onSubmit}
          initialValues={initialValues}
          initialValuesEqual={() => true}
          render={({ handleSubmit, submitting, values }) => {
            if (type === 'edit') values.inventoryId = match.params.id;

            values.photo = image;

            return (
              <StyledForm onSubmit={handleSubmit}>
                <BasicWrapper style={{ paddingRight: 10 }}>
                  <BasicInfoLeftWrapper>
                    <FieldFlex>
                      <StyledText>Product ID: </StyledText>
                      <StyledField
                        name="productCode"
                        type="text"
                        disabled
                        component={FieldComponent}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Type: </StyledText>
                      <StyledField
                        name="inventoryType"
                        type="select"
                        component={FieldComponent}
                        options={inputOptions.inventoryTypeOptions}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Section: </StyledText>
                      <StyledField
                        name="section"
                        type="select"
                        component={FieldComponent}
                        options={inventorySectionsData}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Product: </StyledText>
                      <StyledField
                        name="product"
                        type="text"
                        component={FieldComponent}
                        validate={validations.required}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <PublisherStyledText>Publisher / Maker <div> (if any): </div> </PublisherStyledText>
                      <MobilePublisherStyledText>Publisher / Maker (if any): </MobilePublisherStyledText>
                      <StyledField
                        name="publisherOrMaker"
                        type="select"
                        component={FieldComponent}
                        isClearable
                        options={mappedPublisherMaker}
                      />
                    </FieldFlex>

                    { values.publisherOrMaker === 'Others' ? (
                      <FieldFlex>
                        <StyledText />
                        <StyledField
                          name="customPublisherMaker"
                          type="text"
                          component={FieldComponent}
                          validate={validations.required}
                        />
                      </FieldFlex>
                    ) : null}

                    <SaveButtonWrapper>
                      <StyledText />
                      <Flex style={{ width: '100%' }}>
                        <StyledButton type="submit" disabled={submitting}>
                          Save
                        </StyledButton>
                      </Flex>
                    </SaveButtonWrapper>
                  </BasicInfoLeftWrapper>

                  <BasicInfoRightWrapper>
                    <FieldFlex style={{ alignItems: 'normal' }}>
                      <TextNoteText style={{ width: 100, paddingBottom: 6 }}>Photo: </TextNoteText>
                      <ImageUpload image={image} setImage={setImage} existingImage={type === 'create' ? null : data.product.photo} endpoint="inventory" />
                    </FieldFlex>
                  </BasicInfoRightWrapper>

                  <MobileSaveButtonWrapper>
                    <StyledText />
                    <Flex style={{ width: '100%' }}>
                      <StyledButton type="submit" disabled={submitting}>
                        Save
                      </StyledButton>
                    </Flex>
                  </MobileSaveButtonWrapper>
                </BasicWrapper>
              </StyledForm>
            )
          }}
        />
      </FormWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state.authentication),
})

export default connect(mapStateToProps)(EditView);
