import React from 'react';
import styled from 'styled-components';
import { size } from 'styled-theme';
import Card from '../../components/atoms/Card';
import Logo from '../../components/atoms/Logo';
import SetPasswordForm from './SetPasswordForm';


const StyledLogo = styled(Logo)`
  display: flex;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const ContentWrapper = styled(Card)`
  width: 100%;
  max-width: 425px;
  padding: 36px;
  border-radius: 10px;
`;

const SetPassword = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <StyledLogo colored />
        <SetPasswordForm />
      </ContentWrapper>
    </Wrapper>
  );
};

export default SetPassword;
