import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import get from 'lodash/get';
import includes from 'lodash/includes';
import customStyle from './styles';
// import DataView from '../../../containers/DataView';
import DataView from '../../../components/molecules/DataView';
import Card from '../../../components/atoms/Card';

const Select = ({
  options,
  invalid,
  isClearable,
  value,
  disabled,
  preview = [],
  isMulti,
  input,
  ...props
}) => {
  const selectedOption = isMulti ? options.filter(option => includes(input.value, option.value)) : options.filter(option => option.value === value);
  return (
    <React.Fragment>
      <ReactSelect
        className="select"
        autoBlur
        options={options}
        closeMenuOnSelect
        isClearable={isClearable}
        onBlurResetsInput={false}
        onCloseResetsInput={false}
        isDisabled={disabled}
        styles={customStyle({ invalid, disabled })}
        defaultValue={selectedOption}
        value={selectedOption}
        isMulti={isMulti}
        {...props}
      />
      {preview.length > 0 && (
        <Card>
          <DataView
            entries={preview.map((name) => {
              return {
                label: name,
                value: get(selectedOption[0], ['rawData', name]) || '-',
              };
            })}
          />
        </Card>
      )}
    </React.Fragment>
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.any.isRequired,
  invalid: PropTypes.bool,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func,
  preview: PropTypes.arrayOf(PropTypes.string),
};

Select.defaultProps = {
  isClearable: true,
};

export default Select;
