import React, { Fragment, useState, useRef, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import { palette, size } from 'styled-theme';
import { compose, withState } from 'recompose';
import omit from 'lodash/omit';
import ReactSelect, { createFilter } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import RadioGroup from '../components/molecules/RadioGroup';
import Header from '../components/organisms/containers/Header';
import Button from '../components/atoms/Button';
import FilterBlock from '../components/molecules/FilterBlock';
import SearchBar from '../components/molecules/SearchBar';
import Tab from '../components/molecules/Tab';
import useTableData from '../hooks/useTableData';
import useQueryParams from '../hooks/useQueryParams';
import CSVLink from '../components/molecules/CSVLink';
import customStyle from '../components/atoms/Select/styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0px;
  flex-wrap: wrap;
  margin-left: -10px !important;
  /* justify-content: space-between; */
  > * {
    margin-bottom: 16px;
    @media (max-width: ${size('mobileBreakpoint')}) {
      margin-bottom: 12px;
    }
    margin-left: 10px !important;
  }

  .inactive {
    margin-bottom: 0px;
    margin-right: 0px;
  }
  .active {
    margin-right: 0px;
  }

  button {
    margin-bottom: 0;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 0px 12px;
  }
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  height: 40px;
  margin-left: 0px !important;

  > button {
    margin-left: 10px !important;
  }
  /* > button {
    :not(:last-child) {
      margin-right: 10px;
    }
  } */
`;

const ListViewWrapper = styled.div`
  padding: 24px;
  background: ${palette('white', 0)};
  box-shadow: ${size('boxShadow.small')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 12px 0px !important;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 0px;

  height: 100%;
`;

const MenuContainer = styled.div`
  > div {
    > div {
      div {
        div {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = 35;
  const [value] = getValue();
  const listHeight = Math.min(maxHeight, height * children.length) || 200;
  let initialOffset = options.indexOf(value) * height;
  initialOffset = initialOffset > listHeight ? initialOffset : 0;
  return (
    <MenuContainer>
      { children.length ? (
        <List
          height={listHeight}
          itemCount={children.length}
          itemSize={height}
          initialScrollOffset={initialOffset}
        >
          {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
      ) : <div style={{ padding: '10px 8px' }}> No options available </div>}
    </MenuContainer>
  );
};

const RadioWrapper = styled.div`
  .selector {
    display: none;
  }
  @media (max-width: ${size('mobileBreakpoint')}){
    .selector {
      display: flex;
      > div {
        width: 100%;
      }
    }
    .radiobutton {
      display: none;
    }
  }
`;

const SelectorWrapper = styled.div`
  width: 115px;
  min-width: 115px;
`;

const RadioButtomEmptyDiv = styled.div`
  @media (max-width: 425px){
    display: none;
  }
`;

const ModelPage = (props) => {
  const {
    modelName,
    apiRoute,
    pageName,
    history,
    showCreateButton,
    showExportButton,
    showHeader,
    render,
    filterConfig,
    allowSearch,
    actions,
    searchPlaceholder,
    isShowView,
    tabs,
    query,
    itemsPerPage,
    exportDataRoute,
    radioGroup,
    radioGroupKey,
    radioGroupSize,
    customSelect,
    initialQuery,
    // scopeBy,
    createState,
    ...rest
  } = props;

  const {
    queryParams,
    setQueryParams,
  } = useQueryParams({
    initialQueryParams: initialQuery,
  });

  const {
    data = [],
    loading,
    onPageChange,
    onSearch,
    searchBy,
    onSort,
    fetchData,
    sortKey,
    sortOrder,
    onFilter,
    filterBy,
    currentPage,
    count,
    pageSize,
    onItemsPerPageChange,
  } = useTableData({
    modelName: modelName || apiRoute,
    query,
    itemsPerPage,
  });

  const [height, setHeight] = useState(0);
  const ref = useRef(null)

  useLayoutEffect(() => {
    setHeight(get(ref, 'current.offsetHeight', 0));
  }, [get(ref, 'current.offsetHeight', null)])

  const handleResize = () => {
    setHeight(get(ref, 'current.offsetHeight', 0));
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  return (
    <Fragment>
      {showHeader && <Header title={pageName || modelName} />}
      <ListViewWrapper>
        <Wrapper ref={ref}>
          {customSelect}

          {(radioGroup && radioGroup.length > 0) ? (
            <RadioWrapper style={{ marginRight: 'auto' }}>
              <div className="radiobutton">
                <RadioGroup
                  options={radioGroup}
                  value={get(queryParams, [
                    'where',
                    radioGroupKey,
                  ])}
                  onChange={(value) => {
                    setQueryParams((old) => ({
                      ...old,
                      currentPage: 1,
                      where: {
                        ...old.where,
                        [radioGroupKey]: value,
                      },
                    }));
                  }}
                  radioGroupSize={radioGroupSize}
                />
              </div>
              <SelectorWrapper className="selector">
                <ReactSelect
                  className="select"
                  autoBlur
                  components={{ MenuList }}
                  filterOption={createFilter({ ignoreAccents: false })}
                  options={radioGroup.map((r) => ({ label: r, value: r }))}
                  onChange={(value) => {
                    setQueryParams((old) => ({
                      ...old,
                      currentPage: 1,
                      where: {
                        ...old.where,
                        [radioGroupKey]: value.value,
                      },
                    }));
                  }}
                  closeMenuOnSelect
                  isClearable={false}
                  onBlurResetsInput={false}
                  onCloseResetsInput={false}
                  hideSelectedOptions={false}
                  styles={customStyle({ invalid: false, disabled: false })}
                  value={{ label: get(queryParams, ['where', radioGroupKey]), value: get(queryParams, ['where', radioGroupKey])}}
                  isDisabled={false}
                />
              </SelectorWrapper>
            </RadioWrapper>
          ) : <RadioButtomEmptyDiv style={{ marginRight: 'auto' }} /> }

          {allowSearch ? (
            <SearchBar onSearch={onSearch} placeholder={searchPlaceholder} searchBy={searchBy} />
          ) : null}
          {filterConfig && (
            <FilterBlock
              onFilter={onFilter}
              filterConfig={filterConfig}
              filterBy={filterBy}
              radioGroupKey={radioGroupKey}
            />
          )}

          { (showCreateButton || actions.length > 0) && (
            <ActionButtonWrapper>
              {showCreateButton && (
                <StyledButton onClick={() => history.push({ pathname: `/${apiRoute || modelName}/create`, state: createState })}>
                  Create New
                </StyledButton>
              )}

              {actions.map(({ label, action, ...others }) => (
                <StyledButton onClick={(e) => action(props, e)} {...others}>
                  {label}
                </StyledButton>
              ))}
            </ActionButtonWrapper>
          )}

          {showExportButton && (
            <CSVLink
              modelName={modelName}
              query={query}
              queryParams={queryParams}
              exportDataRoute={exportDataRoute}
            />
          )}
        </Wrapper>
        {(tabs && tabs.length > 0)
          ? (
            <Tab
              tabs={tabs}
              selectedTabIndex={findIndex(tabs, { scopeName: get(queryParams, 'scopeBy') }) || 0}
              onSelect={(newIndex) => {
                setQueryParams((old) => ({
                  ...old,
                  scopeBy: tabs[newIndex].scopeName,
                }), { replace: true });
                setTimeout(() => {
                  fetchData();
                }, 0);
              }}
            />
          )
          : null}
        {render
          && render({
            data,
            history,
            onSort,
            fetchData,
            sortKey,
            sortOrder,
            onPageChange,
            currentPage,
            itemsPerPage,
            count,
            loading,
            height,
            pageSize,
            onItemsPerPageChange,
            ...rest,
          })}
      </ListViewWrapper>
    </Fragment>
  );
};

ModelPage.defaultProps = {
  showHeader: true,
  allowSearch: true,
  showExportButton: false,
  actions: [],
  radioGroup: [],
  query: {},
};

ModelPage.propTypes = {
  modelName: PropTypes.string.isRequired,
  apiRoute: PropTypes.string, // to override Model route if required
  exportDataRuute: PropTypes.string, // to override Export route if required
  pageName: PropTypes.string,
  data: PropTypes.array,
  history: PropTypes.object,
  render: PropTypes.func.isRequired,
  showViewButton: PropTypes.bool,
  showImportButton: PropTypes.bool,
  showCreateButton: PropTypes.bool,
  showExportButton: PropTypes.bool,
  showBulkActionButton: PropTypes.bool,
  bulkActionApiRoute: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  bulkActionButtonName: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  bulkActionPurpose: PropTypes.string,
  showHeader: PropTypes.bool,
  itemsPerPage: PropTypes.number, // default = 25, null -> table with infinite size
  filterConfig: PropTypes.array,
  onFilter: PropTypes.func,
  filterBy: PropTypes.object,
  allowSearch: PropTypes.bool,
  onSearch: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
  })),
  searchBy: PropTypes.object,
  query: PropTypes.object,
  fetchData: PropTypes.func,
  isShowView: PropTypes.bool,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  tabs: PropTypes.array,
  radioGroup: PropTypes.array,
  radioGroupKey: PropTypes.string,
  radioGroupSize: PropTypes.string,
  // scopeBy: PropTypes.object,
  onScopeChange: PropTypes.func,
  queryParams: PropTypes.object,
  setQueryParams: PropTypes.func,
  setQuery: PropTypes.func,
};

export default compose(
  withState('selected', 'setSelected', []),
)(ModelPage);
