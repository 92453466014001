import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import NotFound from '../NotFound';
import ListView from './ListView';
import ShowView from './ShowView';

const InstructorPage = ({ match: { path } }) => (
  <Switch>
    <Route exact path={path} component={ListView} />
    <Route path={`${path}/show/:id`} component={ShowView} />
    <Route component={NotFound} />
  </Switch>
);

InstructorPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default InstructorPage;
