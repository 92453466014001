import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import NotFound from '../NotFound';
import MainView from './MainView';
import LessonView from './LessonView';
import EventView from './EventView';
import BlockTimeView from './BlockTimeView';
import ExtendedLeaveView from './ExtendedLeaveView';

const CalendarPage = ({ match: { path } }) => (
  <Switch>
    <Route exact path={path} component={MainView} />
    <Route path={`${path}/lesson`} component={LessonView} />
    <Route path={`${path}/:id/edit`} component={LessonView} />
    <Route path={`${path}/event`} component={EventView} />
    <Route path={`${path}/:id/editevent`} component={EventView} />
    <Route path={`${path}/blocktime`} component={BlockTimeView} />
    <Route path={`${path}/:id/editblocktime`} component={BlockTimeView} />
    <Route path={`${path}/extendedleave`} component={ExtendedLeaveView} />
    <Route path={`${path}/:id/editextendedleave`} component={ExtendedLeaveView} />
    <Route component={NotFound} />
  </Switch>
);

CalendarPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default CalendarPage;
