import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { palette, size } from 'styled-theme';
import IconButton from '../../molecules/IconButton';
import withSearch from '../../../containers/enhancers/withSearch';
import Form from '../Form';
import FieldComponent from '../FieldComponent';

const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  /* @media (max-width: ${size('mobileBreakpoint')}){
    margin-right: 0px;
  } */
`;

const StyledField = styled(Field)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 280px;
  & input {
    border: none;
  }
  & div[role=alert] {
    position: absolute;
    top: 100%;
  }
  @media (max-width: 768px){
    width: 100px;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  padding: ${size('padding')};
  box-shadow: none;
  font-size: 12px;
  color: ${palette('primary', 0)};
  height: 38px;
  display: flex;
  align-items: center;

  @media (max-width: 768px){
    font-size: 14px;
    padding-top: 2px;
  }
`;

const StyledIconButton = styled(IconButton)`
  border: none;
  box-shadow: none;
  min-width: 50px;
  color: ${palette('primary', 0)};
  margin-bottom: 0px;

  @media (max-width: 768px){
    min-width: 30px;
  }
`;

const StyledForm = styled.form`
  /* @media (max-width: ${size('mobileBreakpoint')}){
    width: 100%;
    margin-bottom: 10px;
  } */
  border: 1px solid ${palette('primary', 0)};
  /* border-radius: 4px 4px 4px 4px; */
`;

const SearchBar = ({
  placeholder,
  searchBy,
  onSubmit,
  onReset,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  return (
    <Form
      name={'searchBar'}
      disabled
      initialValues={{ searchKeyword: searchBy }}
      onSubmit={onSubmit}
      subscription={{ values: true }}
      render={({ form, handleSubmit, submitting }) => (
        <StyledForm onSubmit={handleSubmit}>
          <SearchBarWrapper>
            <StyledField
              name={'searchKeyword'}
              component={FieldComponent}
              style={{ margin: 0 }}
              type="text"
              placeholder={placeholder}
              renderError
            />
            <CloseButton onClick={() => onReset(form)}>{isMobile ? 'X' : 'clear'}</CloseButton>
            <StyledIconButton
              transparent
              iconSize={16}
              icon="search"
              type="submit"
              disabled={submitting}
            />
          </SearchBarWrapper>
        </StyledForm>
      )}
    />
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  searchBy: PropTypes.any,
};

SearchBar.defaultProps = {
  placeholder: 'Search Keywords',
};

export default withSearch(SearchBar);
