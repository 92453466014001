import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import { ConfigProvider, Empty, Space, Tag } from 'antd';
import get from 'lodash/get';
import P from '../../../components/atoms/P';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Flex from '../../../components/atoms/Flex';
import PopUpDelete from '../../../components/organisms/PopUpDelete';

const Wrapper = styled.div`
  padding-bottom: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
    height: 100%;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const StyledSpan = styled.span`
  display: inline-block;
`;

const StyledTag = styled(Tag)`
  text-align: center;
  font-size: 14px;
  width: 100%;
`;

const ModelContainer = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
  > div {
    @media (max-width: ${size('mobileBreakpoint')}) {
      height: 100%;
    }
    > div: first-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        max-height: ${(props) => (props.divHeight ? 'none' : '56px')}
      }
    }
    > div: last-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        height: calc(100% - 56px);
        height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')}
      }
      > div {
        @media (max-width: ${size('mobileBreakpoint')}) {
          height: 100%;
        }
        > div {
          @media (max-width: ${size('mobileBreakpoint')}) {
            height: 100%;
          }
          > div: last-child {
            @media (max-width: ${size('mobileBreakpoint')}) {
              height: 100%;
            }
            > div {
              @media (max-width: ${size('mobileBreakpoint')}) {
                height: calc(100% - 40px);
              }
              > div {
                @media (max-width: ${size('mobileBreakpoint')}) {
                  height: 100%;
                }
                > div: last-child {
                  max-height: 100% !important;
                  @media (max-width: ${size('mobileBreakpoint')}) {
                    max-height: calc(100% - 55px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const modelName = 'user/management';

const itemsPerPage = 25;

const filterConfig = [
  {
    type: 'spec',
    name: 'children.id',
    label: 'Student',
    isMulti: true,
    shouldChose: true,
    labelBy: (v) => `${v.firstName} ${v.lastName}`,
    reference: { endpoint: 'student', query: { order: { sortKey: ['child.firstName', 'child.lastName'], sortOrder: 'asc' } } },
    associationType: 'own',
  },
  {
    type: 'radioGroup',
    name: 'emailNotification',
    label: 'Email Notification',
    options: [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ],
  },
  {
    type: 'radioGroup',
    name: 'policy',
    label: 'Policy',
    options: [
      {
        label: 'All',
        value: 'All',
      },
      {
        label: 'Signed',
        value: 'Signed',
      },
      {
        label: 'Unsigned',
        value: 'Unsigned',
      },
    ],
  },
]

const ListView = (props) => {
  const { user, location } = props;
  const [divHeight, setDivHeight] = useState(0);

  const cellRenderers = [
    {
      title: 'Name',
      dataIndex: 'parent',
      key: 'parent',
      width: 75,
      render: (p) => <Cell><Link to={{ pathname: `/user/show/${p.id}`, state: { prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` } }}>{p.name}</Link></Cell>,
    },
    {
      title: 'Account',
      dataIndex: 'email',
      key: 'email',
      render: (content) => <Cell>{content}</Cell>,
      width: 90,
    },
    {
      title: 'Student(s)',
      dataIndex: 'students',
      key: 'students',
      render: (students) => <Cell style={{ maxWidth: 350 }}>{(students ? students.map((s, index) => <span>{(index ? ', ' : '')}<Link to={`/student/show/${s.id}`}><StyledSpan>{s.name}</StyledSpan></Link></span>) : 'N/A')}</Cell>,
      width: 100,
    },
    {
      title: 'Policy',
      dataIndex: 'isPolicyConfirmed',
      key: 'isPolicyConfirmed',
      width: 90,
      render: (signed) => <Cell style={{ width: '100%' }}><StyledTag color={signed ? 'green' : 'red'} key={signed}>{signed ? 'Signed' : 'Unsigned'}</StyledTag></Cell>,
    },
    {
      title: 'Email Notification',
      dataIndex: 'emailNotification',
      key: 'emailNotification',
      width: 110,
      render: (content) => <Cell>{content ? 'Yes' : 'No'}</Cell>,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAtDate',
      key: 'createdAt',
      render: (content) => <Cell>{content}</Cell>,
      width: 110,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Cell>
          <PopUpDelete user={user} endpoint="user/parent" id={record.id} type="Parent" />
        </Cell>
      ),
      width: 90,
    },
  ];

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderText> Parent Management </HeaderText>
      </Flex>
      <ModelContainer divHeight={divHeight}>
        <ModelPage
          showHeader={false}
          modelName={modelName}
          filterConfig={filterConfig}
          itemsPerPage={itemsPerPage}
          query={{ where: { role: 'parent' } }}
          initialQuery={{ currentPage: 1 }}
          allowSearch
          showCreateButton
          showExportButton
          exportDataRoute="/user/export"
          apiRoute="user"
          createState={{ prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` }}
          {...props}
          render={(renderProps) => {
            const { height } = renderProps;
            if (divHeight !== height) setDivHeight(height);
            return (
              <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Users" />}>
                <AntDTable
                  modelName={modelName}
                  cellRenderers={cellRenderers}
                  pagination={{ pageSize: itemsPerPage, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                  rowKey="id"
                  scroll={{ x: 'max-content', y: '65vh' }}
                  {...renderProps}
                />
              </ConfigProvider>
            )
          }}
        />
      </ModelContainer>
    </Wrapper>
  )
};

export default ListView;
