import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import { ConfigProvider, Empty, Tabs } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactSelect, { createFilter } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import get from 'lodash/get';
import sumBy from 'lodash/sumBy';
import P from '../../../components/atoms/P';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Flex from '../../../components/atoms/Flex';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import Button from '../../../components/atoms/Button';
import useUserForm from '../../../hooks/useUserForm';
import inputOptions from '../../../utils/form/inputOptions';
import customStyle from '../../../components/atoms/Select/styles';
import useQueryParams from '../../../hooks/useQueryParams';
import Card from '../../../components/atoms/Card';

const Wrapper = styled.div`
  height: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100% - 66px);
  padding-bottom: 10px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    min-height: calc(100% - 56px);
    padding-bottom: 0px;
  }
`;

const TabWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0 25px 25px 25px;
  width: 100%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 0px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const MenuContainer = styled.div`
  > div {
    > div {
      div {
        div {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = 35;
  const [value] = getValue();
  const listHeight = Math.min(maxHeight, height * children.length) || 200;
  let initialOffset = options.indexOf(value) * height;
  initialOffset = initialOffset > listHeight ? initialOffset : 0;
  return (
    <MenuContainer>
      { children.length ? (
        <List
          height={listHeight}
          itemCount={children.length}
          itemSize={height}
          initialScrollOffset={initialOffset}
        >
          {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
      ) : <div style={{ padding: '10px 8px' }}> No options available </div>}
    </MenuContainer>
  );
};

const ModelContainer = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
  > div {
    padding: 24px 0px;
    @media (max-width: ${size('mobileBreakpoint')}) {
      height: 100%;
    }
    > div: first-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        max-height: ${(props) => (props.divHeight ? 'none' : '56px')};
      }
    }
    > div: last-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        height: calc(100% - 56px);
        height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')}
      }
      > div {
        @media (max-width: ${size('mobileBreakpoint')}) {
          height: 100%;
        }
        > div {
          @media (max-width: ${size('mobileBreakpoint')}) {
            height: 100%;
          }
          > div: last-child {
            @media (max-width: ${size('mobileBreakpoint')}) {
              height: 100%;
            }
            > div {
              @media (max-width: ${size('mobileBreakpoint')}) {
                height: calc(100% - 40px);
              }
              > div {
                @media (max-width: ${size('mobileBreakpoint')}) {
                  height: 100%;
                }
                > div: last-child {
                  max-height: 100% !important;
                  @media (max-width: ${size('mobileBreakpoint')}) {
                    max-height: calc(100% - 55px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const { TabPane } = Tabs;

const StyledTabPane = styled(TabPane)`
  height: 100%;
`;

const itemsPerPage = 100;

const getAllMonthYears = (startDate) => {
  const dateToday = moment().format('YYYY-MM-DD');
  let newDate = moment(startDate).format('YYYY-MM-DD');
  const monthYears = [];
  while (moment(newDate).isBefore(dateToday)) {
    const currentDay = moment(newDate).get('date');
    const monthStartDate = currentDay < 28 ? moment(newDate).set('date', 28).subtract(1, 'months').startOf('day') : moment(newDate).set('date', 28).startOf('day');
    const monthEndDate = currentDay < 28 ? moment(newDate).set('date', 27).endOf('day') : moment(newDate).set('date', 27).add(1, 'months').endOf('day');

    monthYears.push({ label: `${monthStartDate.format('DD MMM YYYY')} - ${monthEndDate.format('DD MMM YYYY')}`, value: JSON.stringify({ startDate: monthStartDate.format('YYYY-MM-DD'), endDate: monthEndDate.format('YYYY-MM-DD') }) });
    newDate = moment(newDate).add(1, 'months').format('YYYY-MM-DD');
  }
  return [...monthYears].reverse();
}

const ListView = (props) => {
  const { user } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const monthYears = getAllMonthYears('2021-07-01');
  const [divHeight, setDivHeight] = useState(0);
  const [lateFeeDivHeight, setLateFeeDivHeight] = useState(0);

  const { queryParams, setQueryParams } = useQueryParams({ initialQueryParams: { range: JSON.parse(monthYears[0].value) } });

  const currentRange = get(queryParams, 'range', JSON.parse(monthYears[0].value));

  const totalWrapper = (fn, format = false) => {
    return (data, rowData) => {
      if (get(rowData, 'type') !== 'total') {
        return fn(data, rowData);
      }
      console.log(rowData);
      const formatted = Number(data) ? Number(Math.floor(data * 100) / 100).toLocaleString() : data;
      return (
        <Cell style={{ fontWeight: 'bold' }}>{formatted}</Cell>
      );
    }
  }

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const cellRenderers = [
    {
      title: 'Instructor',
      dataIndex: 'instructor',
      key: 'instructor',
      render: (instructor) => <Cell><Link to={`/staff/show/${instructor.id}`}>{`${instructor.name}`}</Link></Cell>,
      width: 110,
    },
    {
      title: 'No. of Classes',
      dataIndex: 'classes',
      key: 'classes',
      render: (content) => <Cell>{content}</Cell>,
      width: 130,
    },
    {
      title: 'No. of Teaching Hours',
      dataIndex: 'teachingHours',
      key: 'teachingHours',
      render: (content) => <Cell>{content}</Cell>,
      width: 180,
    },
    {
      title: 'Revenue Generated',
      dataIndex: 'revenue',
      key: 'revenue',
      render: (content) => <Cell>{content}</Cell>,
      width: 160,
    },
    {
      title: 'Weighted Average',
      dataIndex: 'weightedAverage',
      key: 'weightedAverage',
      render: (content) => <Cell>{content}</Cell>,
      width: 160,
    },
    {
      title: 'Base Salary',
      dataIndex: 'salary',
      key: 'salary',
      render: (content) => <Cell>{content}</Cell>,
      width: 120,
    },
    {
      title: 'Exceeded Revenue Hours',
      dataIndex: 'exceededHours',
      key: 'exceededHours',
      render: (content) => <Cell>{content}</Cell>,
      width: 200,
    },
    {
      title: 'Revenue Share Bonus',
      dataIndex: 'shareBonus',
      key: 'shareBonus',
      render: (content) => <Cell>{content}</Cell>,
      width: 170,
    },
  ].map(v => ({ ...v, render: totalWrapper(v.render) }));

  const expandableColumns = [
    {
      title: 'Class',
      width: 90,
      render: (key, row) => <Cell><Link to={`/lesson/show/${row.recurrenceId}`}>{row.title}</Link></Cell>,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => <Cell>{`${moment(date).format('DD/MM/YYYY')}`}</Cell>,
      width: 75,
    },
    {
      title: 'Time',
      dataIndex: 'formattedTime',
      key: 'formattedTime',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
      width: 100,
      render: (student) => <Cell><Link to={`/student/show/${student.id}`}>{`${student.firstName} ${student.lastName || ''}`}</Link></Cell>,
    },
    {
      title: 'Package Code',
      dataIndex: 'packageInfo',
      key: 'packageInfo',
      width: 140,
      render: (packageInfo) => <Cell>{((packageInfo && packageInfo.id) ? <Link to={`/package/show/${packageInfo.id}`}>{`${packageInfo.prefix}${packageInfo.id}`}</Link> : 'N/A')}</Cell>,
    },
    {
      title: 'Price',
      dataIndex: 'creditsDeducted',
      key: 'creditsDeducted',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Internal Revenue',
      dataIndex: 'lessonRevenueString',
      key: 'lessonRevenueString',
      render: (content) => <Cell>{content}</Cell>,
      width: 160,
    },
  ]

  const lateFeeColumns = [
    {
      title: 'Class',
      dataIndex: 'recurrenceId',
      width: 90,
      render: (key, row) => {
        return <Cell><Link to={`/lesson/show/${row.recurrenceId}`}>{row.title}</Link></Cell>
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => <Cell>{`${moment(date).format('DD/MM/YYYY')}`}</Cell>,
      width: 75,
    },
    {
      title: 'Time',
      dataIndex: 'formattedTime',
      key: 'formattedTime',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
      width: 100,
      render: (student) => <Cell><Link to={`/student/show/${student.id}`}>{student.name}</Link></Cell>,
    },
    {
      title: 'Package Code',
      dataIndex: 'packageInfo',
      key: 'packageInfo',
      width: 140,
      render: (packageInfo) => <Cell>{((packageInfo && packageInfo.id) ? <Link to={`/package/show/${packageInfo.id}`}>{`${packageInfo.prefix}${packageInfo.id}`}</Link> : 'N/A')}</Cell>,
    },
    {
      title: 'Instructor',
      dataIndex: 'instructor',
      key: 'instructor',
      width: 120,
      render: (instructor) => <Cell><Link to={`/staff/show/${instructor.id}`}>{instructor.name}</Link></Cell>,
    },
    {
      title: 'Room',
      dataIndex: 'roomName',
      key: 'roomName',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Cancelled By',
      dataIndex: 'cancelledByInfo',
      key: 'cancelledByInfo',
      width: 140,
      render: (cancelledBy) => <Cell>{(cancelledBy.id ? (cancelledBy.role === 'parent' ? <Link to={`/user/show/${cancelledBy.id}`}>{cancelledBy.name}</Link> : <Link to={`/staff/show/${cancelledBy.id}`}>{cancelledBy.name}</Link>) : 'N/A')}</Cell>,
    },
    {
      title: 'Price',
      dataIndex: 'creditsDeducted',
      key: 'creditsDeducted',
      render: (content) => <Cell>{`$${(+Number(content).toFixed(2)).toLocaleString()}`}</Cell>,
      width: 75,
    },
    {
      title: 'Internal Revenue',
      dataIndex: 'lessonRevenue',
      key: 'lessonRevenue',
      render: (content) => <Cell>{`$${(+Number(content).toFixed(2)).toLocaleString()}`}</Cell>,
      width: 160,
    },
  ].map(v => ({ ...v, render: totalWrapper(v.render) }));

  const filterConfig = [
    {
      type: 'spec',
      name: 'id',
      label: 'Instructor',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: { endpoint: 'user', query: { where: { role: 'instructor' }, order: { sortKey: ['firstName', 'lastName'], sortOrder: 'asc' } } },
      associationType: 'own',
    },
  ];

  const lateFeefilterConfig = [
    {
      type: 'multi-select',
      name: 'title',
      label: 'Class',
      endpoint: 'lesson/lessonOptions',
      valueBy: (v) => v.lessonName,
      labelBy: (v) => v.lessonName,
    },
    {
      type: 'spec',
      name: 'childId',
      label: 'Student',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: {
        endpoint: 'student',
        query: {
          where: {},
          order: {
            sortKey: [
              'child.firstName',
              'child.lastName',
            ],
            sortOrder: 'asc',
          },
        },
      },
      associationType: 'own',
    },
    {
      type: 'spec',
      name: 'instructorId',
      label: 'Instructor',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: {
        endpoint: 'user',
        query: {
          where: {
            role: 'instructor',
            status: 'Current',
          },
          order: {
            sortKey: [
              'firstName',
              'lastName',
            ],
            sortOrder: 'asc',
          },
        },
      },
      associationType: 'own',
    },
    {
      type: 'spec',
      name: 'roomId',
      label: 'Room',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => v.name,
      reference: {
        endpoint: 'room',
      },
      associationType: 'own',
    },
  ];

  const customSelect = (
    <div style={{ width: 240 }}>
      <ReactSelect
        className="select"
        autoBlur
        components={{ MenuList }}
        filterOption={createFilter({ ignoreAccents: false })}
        options={monthYears}
        onChange={(value) => setQueryParams((old) => ({
          ...old,
          currentPage: 1,
          range: JSON.parse(value.value),
        }), { replace: true })}
        closeMenuOnSelect
        isClearable={false}
        onBlurResetsInput={false}
        onCloseResetsInput={false}
        hideSelectedOptions={false}
        styles={customStyle({ invalid: false, disabled: false })}
        value={monthYears.filter((option) => option.value === JSON.stringify(currentRange))[0]}
        defaultValue={monthYears[0]}
        isDisabled={false}
      />
    </div>
  )

  const onTabChange = (tabValue) => {
    setQueryParams((old) => ({
      ...old,
      filter: null,
      search: null,
      currentPage: 1,
      where: {
        tab: tabValue,
      },
    }), { replace: true });
  };
  return (
    <Wrapper>
      <HeaderText>Performance</HeaderText>
      <InnerWrapper>
        <TabWrapper>
          <Tabs activeKey={get(queryParams, ['where', 'tab'])} onChange={(tabValue) => onTabChange(tabValue)}>
            <StyledTabPane tab="Instructor Performance" key="Instructor Performance">
              <ModelContainer divHeight={divHeight}>
                <ModelPage
                  showHeader={false}
                  modelName="performance"
                  itemsPerPage={itemsPerPage}
                  allowSearch
                  query={{ where: { date: currentRange, tab: 'Instructor Performance' } }}
                  initialQuery={{ currentPage: 1, where: { tab: 'Instructor Performance' } }}
                  filterConfig={filterConfig}
                  showCreateButton={false}
                  showExportButton
                  customSelect={customSelect}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (divHeight !== height) setDivHeight(height);

                    const performanceData = get(renderProps, 'data.performance', []);
                    const totalData = [
                      'classes',
                      'teachingHours',
                      { dataIndex: 'revenue', dataSource: 'revenueRaw' },
                      'creditsDeducted',
                      'lessonRevenue',
                      'exceededHours',
                      { dataIndex: 'shareBonus', dataSource: 'shareBonusRaw' },
                    ].reduce((ac, cu) => {
                      const index = typeof cu === 'string' ? cu : cu.dataIndex;
                      const source = typeof cu === 'string' ? cu : cu.dataSource;
                      return {
                        ...ac,
                        [index]: sumBy(performanceData, source),
                      };
                    }, { type: 'total' });
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />}>
                        <AntDTable
                          cellRenderers={cellRenderers}
                          scroll={{ x: 'max-content', y: '65vh' }}
                          expandable={{
                            rowExpandable: (record) => get(record, 'events', []).length > 0,
                            expandedRowRender: (record) => (
                              <AntDTable
                                modelName="performance"
                                cellRenderers={expandableColumns}
                                pagination={false}
                                rowKey="id"
                                data={record.events}
                                isExpanded
                              />
                            ),
                          }}
                          pagination={{ pageSize: itemsPerPage, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          {...renderProps}
                          data={[
                            ...performanceData,
                            ...(performanceData.length > 0 ? [totalData] : []),
                          ]}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </StyledTabPane>
            <StyledTabPane tab="Late Cancellation Fee" key="Late Cancellation Fee">
              <ModelContainer divHeight={lateFeeDivHeight}>
                <ModelPage
                  showHeader={false}
                  apiRoute="performance/lateFee"
                  itemsPerPage={itemsPerPage}
                  allowSearch
                  query={{ where: { date: currentRange, tab: 'Late Cancellation Fee' } }}
                  initialQuery={{ currentPage: 1, where: { tab: 'Late Cancellation Fee' } }}
                  filterConfig={lateFeefilterConfig}
                  showCreateButton={false}
                  showExportButton
                  exportDataRoute="/performance/lateFeeExport"
                  customSelect={customSelect}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (lateFeeDivHeight !== height) setLateFeeDivHeight(height);

                    const lateFeeData = get(renderProps, 'data.lateFee', []);

                    const totalData = [
                      'creditsDeducted',
                      'lessonRevenue',
                    ].reduce((ac, cu) => {
                      const index = typeof cu === 'string' ? cu : cu.dataIndex;
                      const source = typeof cu === 'string' ? cu : cu.dataSource;
                      return {
                        ...ac,
                        [index]: sumBy(lateFeeData, source),
                      };
                    }, { type: 'total' });
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />}>
                        <AntDTable
                          cellRenderers={lateFeeColumns}
                          scroll={{ x: 'max-content', y: '65vh' }}
                          pagination={{ pageSize: itemsPerPage, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          {...renderProps}
                          data={[
                            ...lateFeeData,
                            ...(lateFeeData.length > 0 ? [totalData] : []),
                          ]}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </StyledTabPane>
          </Tabs>
        </TabWrapper>
      </InnerWrapper>
    </Wrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
