import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import get from 'lodash/get';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Link from '../../atoms/Link';
import useCalendarForm from '../../../hooks/useCalendarForm';
import Flex from '../../atoms/Flex';
import FieldComponent from '../../molecules/FieldComponent';
import { validations } from '../../../utils/form';
import inputOptions from '../../../utils/form/inputOptions';
import ErrorAlert from '../../molecules/ErrorAlert';
import useUserForm from '../../../hooks/useUserForm';

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 35vw;
  padding: 20px;

  > form {
    height: calc(100% - 103px);
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;
  font-weight: normal;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 5px;
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 17px 15px 22px 0px;
  width: 160px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const SaveButton = styled(Button)`
  min-width: 180px;
  margin: 20px 0px 20px;
  margin-bottom: 0px;
  border-radius: 0px;
  width: 100%;
`;

const ErrorAlertContainer = styled.div`
  > div {
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: 75vw;
      width: fit-content;
    }
  }
`;

const PopUpEditInvoice = ({ user, record, type }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const { onSubmit: onEdit } = useUserForm({
    endpoint: type === 'package' ? 'package/editInvoice' : 'sale/editInvoiceOrNote',
    setErrorMessage,
    action: 'update',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const initialName = type === 'package' ? (record.invoice.name || `INV-${record.invoice.id}`) : type === 'Sale' ? (record.invoiceOrNote.name || `INV-${record.invoiceOrNote.id}`) : (record.invoiceOrNote.name || `CN-${record.invoiceOrNote.id}`);
  const disableEdit = type === 'package' ? !get(record, 'invoice.id', null) : !get(record, 'invoiceOrNote.id', null);

  return (
    <StyledPopup
      trigger={<StyledButton disabled={disableEdit} style={{ color: disableEdit && '#C1C1C1' }}> Edit { type === 'Buy Back' ? 'Credit Note' : 'Invoice' } </StyledButton>}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          <PopUpButton className="close" onClick={close}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper> Edit { type === 'Buy Back' ? 'Credit Note' : 'Invoice' } </PopUpTextWrapper>
          <div>
            <Form
              name="EditInvoiceForm"
              onSubmit={onEdit}
              initialValues={{ name: initialName }}
              initialValuesEqual={() => true}
              render={({ handleSubmit, submitting, values }) => {
                values.id = type === 'package' ? get(record, 'invoice.id', null) : get(record, 'invoiceOrNote.id', null);
                values.type = type;
                return (
                  <StyledForm onSubmit={handleSubmit}>
                    <div style={{ maxHeight: '100%', overflow: 'auto' }}>
                      <ErrorAlertContainer>
                        <ErrorAlert
                          errorMessage={errorMessage}
                          show={errorMessage !== null}
                        />
                      </ErrorAlertContainer>

                      <FieldFlex>
                        <StyledText>Name: </StyledText>
                        <StyledField
                          name="name"
                          type="text"
                          component={FieldComponent}
                          validate={validations.required}
                        />
                      </FieldFlex>
                    </div>

                    <SaveButton type="submit" disabled={submitting}> Save </SaveButton>
                  </StyledForm>
                )
              }}
            />
          </div>
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpEditInvoice.propTypes = {
  user: PropTypes.object.isRequired,
  child: PropTypes.array.isRequired,
  instructor: PropTypes.array.isRequired,
};

export default PopUpEditInvoice;
