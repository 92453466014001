// Needed for babel to compile redux-saga to ES5
import 'regenerator-runtime/runtime';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

// Compose middleware and devTools
let composed;
if (window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.env !== 'production') {
  composed = compose(applyMiddleware(sagaMiddleware), window.__REDUX_DEVTOOLS_EXTENSION__());
} else {
  composed = compose(applyMiddleware(sagaMiddleware));
}

const store = createStore(reducers, {}, composed);

let sagaTask = sagaMiddleware.run(sagas);

if (module.hot) {
  module.hot.accept('./reducers', () => {
    store.replaceReducer(reducers);
  });

  module.hot.accept('./sagas', () => {
    sagaTask.cancel();
    sagaTask.done.then(() => {
      sagaTask = sagaMiddleware.run(sagas);
    });
  });
}

export default store;
