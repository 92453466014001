import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { size, palette } from 'styled-theme';
import Card from '../../components/atoms/Card';
import P from '../../components/atoms/P';
import Logo from '../../components/atoms/Logo';
import { signInRequest } from '../../store/authentication/actions';
import {
  selectSignInError,
  selectUserRole,
} from '../../store/authentication/selectors';
import LoginForm from './LoginForm';
import Flex from '../../components/atoms/Flex';
import mintBackground from '../../asset/image/mint-background.png';
import largeMintBackground from '../../asset/image/mint-background-2.png';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledLogo = styled(Logo)`
  display: flex;
  margin: 0 auto;
  width: 240px;
  height: 240px;

  @media (max-width: 768px) {
    width: 180px;
    height: 180px;
  }
`;

const ContentWrapper = styled(Card)`
  display: flex;
  width: 500px;
  margin: 24px;
  padding: 0;
  flex-direction: row;
`;

const ImageContainer = styled.div`
  background-image: url(${null});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 400px;
`;

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${size('padding.large')};

  > form {
    > div {
      > div {
        > span {
          font-size: 11px;
        }
      }
    }
  }
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 6px;

  p {
    color: red;
    font-size: 11px;
    margin: 0;
  }
`;

const LoginText = styled.div`
  color: ${palette('primary', 0)};
  font-size: 24px;
  font-weight: bold;
  padding: 36px 0px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 24px 0px;
  }
`;

const StyledFlex = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 20px;
`;

const Home = ({
  authenticated,
  user,
  role,
  visited,
  signInError,
  markVisited,
  ...props
}) => {
  const getDefaultPathName = () => {
    if (user) {
      if (user.role === 'libraryHelper') return '/library';
      if (Number(user.id) === 409) return '/class-attendance';
      return '/calendar';
    }

    return '/';
  };

  const { from } = props.location.state || {
    from: { pathname: getDefaultPathName() },
  };

  if (authenticated) return <Redirect to={from} />; // todo

  return (
    <Wrapper>
      <ContentWrapper>
        <FormContainer>
          <StyledFlex>
            <StyledLogo colored />
            <LoginText>Login</LoginText>
          </StyledFlex>
          <LoginForm />
          <ErrorWrapper>
            {signInError && <P palette='error'>{signInError.message}</P>}
          </ErrorWrapper>
        </FormContainer>
      </ContentWrapper>
    </Wrapper>
  );
};

Home.propTypes = {
  location: PropTypes.object,
  signInError: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  authenticated: PropTypes.bool,
  user: PropTypes.object,
  role: PropTypes.string,
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: state.authentication.user,
  role: selectUserRole(state.authentication),
  signInError: selectSignInError(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  handleSignIn: (params) => dispatch(signInRequest(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
