import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import Heading from '../components/atoms/Heading';
import SpecForm from './SpecForm';
import Header from '../components/organisms/containers/Header';

import useSpecData from '../hooks/useSpecData';

const SpecPage = ({ initialValues, onSuccess, config, actions, match, showHeader, children, ...others }) => {
  const {
    loading,
    data,
    errorMessage,
    spec,
  } = useSpecData({
    match,
    idPath: config.idPath,
    id: config.id,
    endpoint: config.endpoint,
    query: config.query,
  });
  if (loading) return <div>loading</div>;
  if (errorMessage) return <div>{JSON.stringify(errorMessage, 0, 2)}</div>;

  const containerProps = pick(others, ['style', 'className']);
  const title = `${isEmpty(data) ? 'Create' : 'Edit'} ${startCase(config.pageName || config.endpoint)}`;
  return (
    <div {...containerProps}>
      <div>
        {showHeader ?
          <Header title={title} />
        : null}
        <SpecForm
          onSuccess={onSuccess}
          config={config}
          initialValues={initialValues}
          data={data}
          tabs={config.tabs}
          skipFields={config.skipFields}
          spec={spec}
          formName={config.formName || title}
          actions={actions}
          {...others}
        />
      </div>
      {children && children({ data })}
    </div>
  );
};

SpecPage.propTypes = {
  children: PropTypes.func,
  config: PropTypes.object,
};


export default SpecPage;
