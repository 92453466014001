import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import get from 'lodash/get';
import qs from 'qs';

export default (options = {}) => {
  const { endpoint, setErrorMessage, user, id, type, selectedEvent, setCompleteMessage, recurrenceId, setErrorFormValue } = options;
  const history = useHistory();
  const location = useLocation();
  const [formDisabled, setFormDisabled] = useState(true);
  console.log(type);
  const { api } = global;
  let onSubmit;
  if (type === 'create') {
    onSubmit = async (values) => {
      values.creator = user;
      return api
        .post(`/${endpoint}`, values)
        .then((res) => {
          const queryCalendar = get(res, 'data.calendars', []).length > 0 ? `&${qs.stringify({ id: get(res, 'data.calendars', []) }, { skipNulls: true })}` : '';
          history.push(`/calendar?view=${get(location, 'state.view', 'daily') || 'daily'}&date=${get(res, 'data.date', null)}${queryCalendar}`);
        })
        .catch((error) => {
          if (Number(error.response.status) === 403) {
            setErrorFormValue(error.response.errors);
            setErrorMessage(error.message);
          } else {
            if (setErrorFormValue) setErrorFormValue(null);
            setErrorMessage(error.message);
          }
        });
    };
  } else if (type === 'reschedule') {
    onSubmit = async (values) => {
      values.creator = user;
      return api
        .put(`/${endpoint}`, values)
        .then((res) => {
          const queryCalendar = get(res, 'data.calendars', []).length > 0 ? `&${qs.stringify({ id: get(res, 'data.calendars', []) }, { skipNulls: true })}` : '';
          history.push(`/calendar?view=${get(location, 'state.view', 'daily') || 'daily'}&date=${get(res, 'data.date', null)}${queryCalendar}`);
        })
        .catch((error) => {
          if (Number(error.response.status) === 403) {
            setErrorFormValue(error.response.errors);
            setErrorMessage(error.message);
          } else {
            if (setErrorFormValue) setErrorFormValue(null);
            setErrorMessage(error.message);
          }
        });
    };
  } else if (type === 'delete') {
    onSubmit = async () => {
      return api
        .delete(`/${endpoint}/${id}`, { params: id })
        .then(() => { window.location.reload(false) })
        .catch((error) => setErrorMessage(error.message));
    }
  } else if (type === 'revert') {
    onSubmit = async (value) => {
      return api
        .put(`/${endpoint}/${id}`, { override: value })
        .then(() => { window.location.reload(false) })
        .catch((error) => {
          if (Number(error.response.status) === 403) {
            setErrorFormValue(403);
            setErrorMessage(error.message);
          } else {
            if (setErrorFormValue) setErrorFormValue(null);
            setErrorMessage(error.message);
          }
        });
    }
  } else if (type === 'extendedleave') {
    onSubmit = async (values) => {
      values.creator = user;
      return api
        .post(`/${endpoint}`, values)
        .then((res) => {
          setCompleteMessage(true);
          return res;
        })
        .then((res) => {
          new Promise((resolve) => setTimeout(resolve, 3000));
          return res;
        })
        .then((res) => {
          const queryCalendar = get(res, 'data.calendars', []).length > 0 ? `&${qs.stringify({ id: get(res, 'data.calendars', []) }, { skipNulls: true })}` : '';
          history.push(`/calendar?view=${get(location, 'state.view', 'daily') || 'daily'}&date=${get(res, 'data.date', null)}${queryCalendar}`);
        })
        .catch((error) => setErrorMessage(error.message));
    }
  } else if (type === 'lessonReschedule') {
    onSubmit = async (values) => {
      values.creator = user;
      return api
        .put(`/${endpoint}`, values)
        .then(() => { history.goBack(); })
        .catch((error) => {
          if (Number(error.response.status) === 403) {
            setErrorFormValue(error.response.errors);
            setErrorMessage(error.message);
          } else {
            if (setErrorFormValue) setErrorFormValue(null);
            setErrorMessage(error.message);
          }
        });
    };
  } else if (type === 'lessonRevert') {
    onSubmit = async (eventId, override) => {
      return api
        .put(`/${endpoint}/${eventId}`, { override })
        .then(() => { window.location.reload(false) })
        .catch((error) => {
          if (Number(error.response.status) === 403) {
            setErrorFormValue(403);
            setErrorMessage(error.message);
          } else {
            if (setErrorFormValue) setErrorFormValue(null);
            setErrorMessage(error.message);
          }
        });
    };
  } else if (type === 'attendance' || type === 'cancelAll' || type === 'deleteAll' || type === 'lessonRevertAll') {
    onSubmit = async (values) => {
      return api
        .put(`/${endpoint}`, values)
        .then(() => { window.location.reload(false); })
        .catch((error) => {
          if (Number(error.response.status) === 403) {
            setErrorFormValue(403);
            setErrorMessage(error.message);
          } else {
            if (setErrorFormValue) setErrorFormValue(null);
            setErrorMessage(error.message);
          }
        });
    };
  } else if (type === 'policy') {
    onSubmit = async (values) => {
      return api
        .post(`/${endpoint}`, values)
        .then(() => { window.location.reload(false); })
        .catch((error) => setErrorMessage(error.message));
    }
  } else if (type === 'renew') {
    onSubmit = async (values) => api
      .put(`/${endpoint}`, values)
      .then(() => { window.location.reload(false) })
      .catch((error) => {
        if (Number(error.response.status) === 403) {
          setErrorFormValue(error.response.errors);
          setErrorMessage(error.message);
        } else {
          if (setErrorFormValue) setErrorFormValue(null);
          setErrorMessage(error.message);
        }
      });
  } else if (type === 'deleteEvent') {
    onSubmit = async (values) => api
      .put(`/${endpoint}`, values)
      .then(() => { window.location.reload(false); })
      .catch((error) => setErrorMessage(error.message));
  } else if (type === 'eventReschedule') {
    onSubmit = async (values) => {
      values.creator = user;
      return api
        .put(`/${endpoint}`, values)
        .then(() => { history.goBack(); })
        .catch((error) => {
          if (Number(error.response.status) === 403) {
            setErrorFormValue(error.response.errors);
            setErrorMessage(error.message);
          } else {
            if (setErrorFormValue) setErrorFormValue(null);
            setErrorMessage(error.message);
          }
        });
    };
  }

  return {
    onSubmit,
    formDisabled,
  };
};
