import React from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import styled from 'styled-components';

import Link from '../../atoms/Link';
import TableRow from '../../atoms/TableRow';
import Table from '../../molecules/Table';

const StyledLink = styled(Link)`
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const ModelTable = ({ cellRenderers, modelName, disableRowLink, ...props }) => (
  <Table
    modelName={modelName}
    cellRenderers={cellRenderers}
    disableRowLink={disableRowLink}
    RowComponent={(rowProps) => {
      return has(rowProps, 'cells[0].props.rowData.id') && !disableRowLink
        ? <StyledLink to={`/${modelName}/${rowProps.cells[0].props.rowData.id}`}><TableRow {...rowProps}/></StyledLink>
        : <TableRow {...rowProps} />;
    }}
    {...props}
  />
);

ModelTable.propTypes = {
  modelName: PropTypes.string.isRequired,
  cellRenderers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  disableRowLink: PropTypes.bool,
};

export default ModelTable;
