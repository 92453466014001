import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import get from 'lodash/get';
import { ifProp } from 'styled-tools';
import { switchProp } from 'styled-tools';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Link from '../../atoms/Link';
import PopUpLesson from '../PopupLesson';
import PopUpEvent from '../PopUpEvent';
import PopUpBlockTime from '../PopUpBlockTime';
import PopUpRequest from '../PopUpRequest';
import Icon from '../../atoms/Icon';
import Flex from '../../atoms/Flex';
import useCalendarForm from '../../../hooks/useCalendarForm';
import PopUpExtendedLeave from '../PopUpExtendedLeave';
import ErrorAlert from '../../molecules/ErrorAlert';
import PopUpDeleteLesson from '../PopUpDeleteLesson';

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  padding: 20px;
  width: ${switchProp('role', {
    instructor: '30vw',
    parent: '30vw',
    admin: '50vw',
    superAdmin: '50vw',
  })};

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px 10px 0px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledText = styled.div`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 14px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #3E90F7;
  margin-left: 5px;
`;

const StyledButton2 = styled(Button)`
  margin: ${size('margin.default')} auto ${size('margin.default')};
  border-radius: 0px;
  margin-left: 10px;
`;

const StyledLink2 = styled(Link)`
  &:hover, &:focus {
    text-decoration: none;
  }

  width: 50%;
  margin-right: 20px;
`;

const StyledButton3 = styled(Button)`
  display: flex;
  flex-direction: row;
  border-radius: 0px;
  margin-right: 20px;
  margin-bottom: 0px;
`;

const StyledIcon = styled(Icon)`
  height: 14px;
  min-width: 14px;
  margin-right: 5px;

  > svg {
    stroke: ${ifProp('disabled', '#C1C1C1', 'none')};
  }
`;

const StyledButton = styled(Button)`
  min-width: 100px;
  margin: 0px;
  margin-top: 10px;
  border-radius: 0px;
`;

const ErrorAlertContainer = styled.div`
  > div {
    max-width: 28vw;
    @media (max-width: ${size('mobileBreakpoint')}) {
      max-width: none;
    }
  }
`;

const TeachingNoteLink = styled(Link)`
  &:hover, &:focus {
    text-decoration: none;
  }
  width: 100%;
`;

const getTitleIcon = (e) => {
  if (e.type === 'Event') return 'event'
  if (e.type === 'Block Time' || e.type === 'Personal Block') return 'block-time';
  if (e.type === 'Lesson') return 'lesson';
}

const PopUpViewEvents = ({ open, user, schedule, allEvents, child, instructor, requests, allLessons, calendars, prevPath, view, onClose }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorFormValue, setErrorFormValue] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDoubleSubmit, setIsDoubleSubmit] = useState(false);

  const startTime = moment(new Date(schedule.start));
  const endTime = moment(new Date(schedule.end));
  const date = moment(startTime).format('DD/MM/YYYY');

  let selectedEvent = allEvents.filter((a) => a.id === Number(schedule.id));
  const filteredEvents = (user.role === 'parent' && get(schedule, 'raw.type', null) === 'Lesson')
    ? allEvents.filter((d) => d.eventId === selectedEvent[0].eventId && Number(d.userId) === Number(user.id))
    : allEvents.filter((d) => d.eventId === selectedEvent[0].eventId);
  selectedEvent = selectedEvent.map((se) => {
    const hasAttendanceConfirmed = filteredEvents.filter((student) => student.isConfirmed).length > 0;
    se.hasAttendanceConfirmed = hasAttendanceConfirmed;
    return se;
  });

  const isCancelled = filteredEvents.filter((s) => s.status === 'Scheduled').length === 0;
  const hasAttendanceConfirmed = filteredEvents.filter((student) => student.isConfirmed).length > 0;

  const studentIds = isCancelled ? filteredEvents.filter((e) => e.childId !== null).map((e) => e.childId) : filteredEvents.filter((e) => e.childId !== null && e.status === 'Scheduled').map((e) => e.childId);
  const eventStudents = isCancelled ? filteredEvents.filter((e) => e.childId !== null) : filteredEvents.filter((e) => e.childId !== null && e.status === 'Scheduled');
  const packageStudents = eventStudents.map((e) => ({ studentId: e.childId, parentId: get(e, 'child.parentId', null), name: `${get(e, 'child.firstName', '')} ${get(e, 'child.lastName', '')}`, packageId: e.packageId, prefix: get(e, 'package.type', null) === 'Group Package' ? 'G' : get(e, 'package.type', null) === 'Trial Package' ? 'T' : 'P' }))

  const instructorId = [...new Set(eventStudents.map((e) => e.instructorId))];
  const instructorInfo = { id: get(eventStudents, '0.instructor.id', null), name: `${get(eventStudents, '0.instructor.firstName', null)} ${get(eventStudents, '0.instructor.lastName', '')}` };

  const { onSubmit } = useCalendarForm({
    endpoint: 'event/revert',
    setErrorMessage,
    user,
    id: selectedEvent[0].eventId,
    type: 'revert',
    selectedEvent,
    setErrorFormValue,
  });

  useEffect(() => {
    if (errorMessage !== null && !errorFormValue) {
      setTimeout(() => {
        setErrorMessage(null);
        setIsSubmitting(false);
      }, 3000);
    }
  }, [errorFormValue, errorMessage]);

  let participantNames = [];
  console.log(filteredEvents);
  if (user.role !== 'parent') {
    participantNames = filteredEvents
      .filter((p) => p.userId != null) // some events do not have participants
      .map((p) => ({ id: get(p, 'participant.id', null), name: `${get(p, 'participant.firstName', '')} ${get(p, 'participant.lastName', '')}`.trim() }));
  }

  const startEndDifference = moment(get(selectedEvent, '0.endTime', null)).diff(moment(get(selectedEvent, '0.startTime', null)), 'days', true);

  return (
    <StyledPopup
      open={open}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper role={user.role}>
          <PopUpButton className="close" onClick={() => { close(); onClose(); }}>
            &times;
          </PopUpButton>
          <ErrorAlertContainer>
            <ErrorAlert
              errorMessage={
                errorMessage ? (
                  <div>
                    {errorMessage.split(',').slice(0, 3).map((e) => <div style={{ marginBottom: 2 }}> {e} </div>)}
                    {errorMessage.split(',').length > 3 ? <div style={{ marginBottom: 2 }}> and {errorMessage.split(',').length - 3} more. </div> : null}
                    { errorFormValue === 403 ? (
                      <div>
                        <div style={{ marginTop: 8 }}> Would you still like to proceed? </div>
                        <Flex style={{ justifyContent: 'center' }}>
                          <StyledButton disabled={isDoubleSubmit} onClick={() => { setIsDoubleSubmit(true); onSubmit(true); }} style={{ marginRight: 15 }}> Yes </StyledButton>
                          <StyledButton onClick={() => { setIsSubmitting(false); setErrorMessage(null); }}> No </StyledButton>
                        </Flex>
                      </div>
                    ) : null}
                  </div>
                )
                  : errorMessage
              }
              show={errorMessage !== null}
            />
          </ErrorAlertContainer>
          <DetailWrapper>
            <TextWrapper style={{ marginBottom: '10px', alignItems: 'center' }}>
              <Icon icon={getTitleIcon(selectedEvent[0])} height={20} style={{ marginRight: '10px' }} />
              <StyledText style={{ fontWeight: 'bold', fontSize: '16px', fontFamily: 'Avenir Roman' }}>
                {selectedEvent[0].type === 'Lesson' && user.role !== 'parent' ? <StyledLink style={{ textDecoration: 'none' }} to={`/lesson/show/${selectedEvent[0].recurrenceId}`}>{`${schedule.title}`}</StyledLink> : `${schedule.title}` }
              </StyledText>
            </TextWrapper>
            <StyledText>{startEndDifference >= 1 ? (startEndDifference === 1 ? `${startTime.format('DD/MM/YYYY')}` : `${startTime.format('DD/MM/YYYY')} - ${endTime.format('DD/MM/YYYY')}`) : `${date} ${startTime.format('HH:mm a')} - ${endTime.format('HH:mm a')}`}</StyledText>
            { selectedEvent[0].type === 'Lesson' && (
              <div>
                <Flex style={{ marginBottom: '10px', marginTop: '20px' }}>
                  <Icon icon="person" height={24} style={{ minWidth: '24px' }} />
                  <Flex style={{ marginTop: 5 }}>
                    <StyledText>Student:&nbsp;</StyledText>
                    <div> { packageStudents.map((info) => {
                      const studentInfo = (['instructor', 'admin', 'superAdmin'].includes(user.role) || (user.role === 'parent' && Number(info.parentId) === Number(user.id))) ? <span><StyledLink style={{ marginLeft: 0, textDecoration: 'none' }} to={`/student/show/${info.studentId}`}>{info.name}</StyledLink></span> : info.name;
                      const packageInfo = ((user.role === 'admin' || user.role === 'superAdmin') && info.packageId) ? <span> (<StyledLink style={{ marginLeft: 0, textDecoration: 'none' }} to={`/package/show/${info.packageId}`}>{info.prefix}{info.packageId}</StyledLink>) </span> : null;
                      return <div>{studentInfo} {packageInfo}</div>
                    })}
                    </div>
                  </Flex>
                </Flex>
                <Flex style={{ marginBottom: ((user.role === 'admin' || user.role === 'superAdmin') || (selectedEvent[0].note && user.role !== 'parent') || get(selectedEvent, '0.room.name', null)) ? '10px' : '0px' }}>
                  <Icon icon="person" height={24} style={{ minWidth: '24px' }} />
                  <StyledText style={{ marginTop: 5 }}>Instructor: {user.role === 'superAdmin' ? <span><StyledLink style={{ marginLeft: 0, textDecoration: 'none' }} to={`/staff/show/${instructorInfo.id}`}>{instructorInfo.name}</StyledLink></span> : instructorInfo.name}</StyledText>
                </Flex>
                { get(selectedEvent, '0.room.name', null) ? (
                  <Flex style={{ marginBottom: (selectedEvent[0].note && user.role !== 'parent') ? 10 : 0 }}>
                    <Icon icon="lesson-package" height={24} style={{ minWidth: '24px' }} />
                    <Flex style={{ marginTop: 5 }}>
                      <StyledText>Room:&nbsp;</StyledText>
                      <StyledText>{ get(selectedEvent, '0.room.name', null) }</StyledText>
                    </Flex>
                  </Flex>
                ) : null}
                {(user.role === 'admin' || user.role === 'superAdmin' || user.role === 'instructor') && selectedEvent[0].note ? (
                  <Flex>
                    <Icon icon="lesson-package" height={24} style={{ minWidth: '24px' }} />
                    <Flex style={{ marginTop: 5 }}>
                      <StyledText>Note (internal):&nbsp;</StyledText>
                      <StyledText>{ selectedEvent[0].note }</StyledText>
                    </Flex>
                  </Flex>
                ) : null}
                { (selectedEvent[0].externalNote) ? (
                  <Flex>
                    <Icon icon="lesson-package" height={24} style={{ minWidth: '24px' }} />
                    <Flex style={{ marginTop: 5 }}>
                      <StyledText>Note:&nbsp;</StyledText>
                      <StyledText>{ selectedEvent[0].externalNote }</StyledText>
                    </Flex>
                  </Flex>
                ) : null}
                { (user.role === 'admin' || user.role === 'superAdmin') && (
                  <div>
                    { isCancelled === false ? (
                      <Flex style={{ justifyContent: 'space-between', marginTop: '25px' }}>
                        <StyledLink2 to={{ pathname: `/calendar/${selectedEvent[0].id}/edit`, state: { calendars, prevPath, view } }} disabled={hasAttendanceConfirmed} style={{ color: hasAttendanceConfirmed && '#C1C1C1', minWidth: 40 }}> <StyledButton3 style={{ width: '100%', color: hasAttendanceConfirmed && '#C1C1C1' }} disabled={hasAttendanceConfirmed}> <StyledIcon icon="reschedule" disabled={hasAttendanceConfirmed} /> Edit </StyledButton3> </StyledLink2>
                        <PopUpLesson
                          user={user}
                          child={child}
                          instructor={instructor}
                          selectedEvent={selectedEvent[0]}
                          id={selectedEvent[0].eventId}
                          studentId={studentIds}
                          eventInstructor={instructorId}
                          allLessons={allLessons}
                          type="delete"
                          page="calendar"
                        />
                        <PopUpDeleteLesson user={user} selectedEvent={selectedEvent[0]} id={selectedEvent[0].eventId} type="delete" page="calendar" />
                      </Flex>
                    ) : (
                      <Flex style={{ justifyContent: 'space-between', marginTop: 25 }} >
                        <StyledButton3 style={{ width: '50%' }} disabled={isSubmitting && !errorMessage} onClick={() => { setIsSubmitting(true); onSubmit(); }}>
                          <Icon icon="revert-lesson" height={24} style={{ minWidth: 24 }} /> Revert
                        </StyledButton3>
                        <PopUpDeleteLesson user={user} selectedEvent={selectedEvent[0]} id={selectedEvent[0].eventId} type="delete" page="calendar" />
                      </Flex>
                    )}
                  </div>
                )}
                { user.role === 'instructor' && (
                  <Flex style={{ marginTop: '25px' }}>
                    <TeachingNoteLink to={`/lesson/show/${get(selectedEvent, '0.recurrenceId', '')}?currentPage=1&where%5Btab%5D=Class%20Attendance&where%5Btype%5D=All&filter%5Bdate%5D%5BstartDate%5D=${get(selectedEvent, '0.date', '')}&filter%5Bdate%5D%5BendDate%5D=${get(selectedEvent, '0.date', '')}`}>
                      <StyledButton3 style={{ width: '100%' }}> Go to Class Attendance </StyledButton3>
                    </TeachingNoteLink>
                  </Flex>
                )}
              </div>
            )}
            {selectedEvent[0].type === 'Event' ? (
              <div>
                {participantNames.length > 0 ? (
                  <Flex style={{ marginTop: '20px' }}>
                    <Icon icon="person" height={24} style={{ minWidth: '24px' }} />
                    <StyledText style={{ marginTop: 5 }}>Participants:&nbsp;</StyledText>
                    <StyledText style={{ marginTop: 5 }}>{participantNames.map((p, index) => (user.role === 'superAdmin' ? <span>{(index ? ', ' : '')}<StyledLink style={{ marginLeft: 0, textDecoration: 'none' }} to={`/staff/show/${p.id}`}>{p.name}</StyledLink></span> : <span>{(index ? ', ' : '')}{p.name}</span>))}</StyledText>
                  </Flex>
                ) : null}
                { get(selectedEvent, '0.room.name', null) ? (
                  <Flex style={{ marginBottom: user.role === 'instructor' ? 0 : 10, marginTop: 10 }}>
                    <Icon icon="lesson-package" height={24} style={{ minWidth: '24px' }} />
                    <Flex style={{ marginTop: 5 }}>
                      <StyledText>Room:&nbsp;</StyledText>
                      <StyledText>{ get(selectedEvent, '0.room.name', null) }</StyledText>
                    </Flex>
                  </Flex>
                ) : null}
                { (user.role === 'admin' || user.role === 'superAdmin') && (
                  <Flex style={{ justifyContent: 'space-between', marginTop: '25px' }}>
                    <StyledLink2 to={{ pathname: `/calendar/${selectedEvent[0].id}/editevent`, state: { calendars, prevPath, view } }}> <StyledButton3 style={{ width: '100%' }}> <StyledIcon icon="reschedule" /> Edit </StyledButton3> </StyledLink2>
                    <PopUpEvent
                      user={user}
                      selectedEvent={selectedEvent[0]}
                      id={selectedEvent[0].eventId}
                      type="delete"
                    />
                  </Flex>
                )}
              </div>
            ) : null}
            { (selectedEvent[0].type === 'Block Time') && (
              <div>
                { (selectedEvent[0].userId && user.role !== 'parent') ? (
                  <Flex style={{ marginBottom: '10px', marginTop: '20px' }}>
                    <Icon icon="person" height={24} style={{ minWidth: '24px' }} />
                    <StyledText style={{ marginTop: 5 }}>Participants:&nbsp;</StyledText>
                    <StyledText style={{ marginTop: 5 }}>{participantNames.map((p, index) => (user.role === 'superAdmin' ? <span>{(index ? ', ' : '')}<StyledLink style={{ marginLeft: 0, textDecoration: 'none' }} to={`/staff/show/${p.id}`}>{p.name}</StyledLink></span> : <span>{(index ? ', ' : '')}{p.name}</span>))}</StyledText>
                  </Flex>
                ) : null}
                { get(selectedEvent, '0.room.name', null) ? (
                  <Flex style={{ marginTop: (!get(selectedEvent, '0.userId', null) || user.role === 'parent') ? 20 : 10 }}>
                    <Icon icon="lesson-package" height={24} style={{ minWidth: '24px' }} />
                    <Flex style={{ marginTop: 5 }}>
                      <StyledText>Room:&nbsp;</StyledText>
                      <StyledText>{ get(selectedEvent, '0.room.name', null) }</StyledText>
                    </Flex>
                  </Flex>
                ) : null}
                { (user.role === 'admin' || user.role === 'superAdmin') && (
                  <Flex style={{ justifyContent: 'space-between', marginTop: '25px' }}>
                    <StyledLink2 to={{ pathname: `/calendar/${selectedEvent[0].id}/editblocktime`, state: { calendars, prevPath, view } }}> <StyledButton3 style={{ width: '100%' }}> <StyledIcon icon="reschedule" /> Edit </StyledButton3> </StyledLink2>
                    <PopUpBlockTime user={user} selectedEvent={selectedEvent[0]} id={selectedEvent[0].eventId} type="delete" />
                  </Flex>
                )}
              </div>
            )}
            { selectedEvent[0].type === 'Personal Block' && (
              <div>
                <Flex style={{ marginBottom: '10px', marginTop: '20px' }}>
                  <Icon icon="person" height={24} style={{ minWidth: '24px' }} />
                  <StyledText style={{ marginTop: 5 }}>Student:&nbsp;</StyledText>
                  <StyledText style={{ marginTop: 5 }}>{packageStudents.map((p, index) => <span>{(index ? ', ' : '')}<StyledLink style={{ marginLeft: 0, textDecoration: 'none' }} to={`/student/show/${p.studentId}`}>{p.name}</StyledLink></span>)}</StyledText>
                </Flex>
                { user.role === 'parent' && (
                  <Flex style={{ justifyContent: 'space-between', marginTop: '25px' }}>
                    <StyledLink2 to={{ pathname: `/calendar/${selectedEvent[0].id}/editextendedleave`, state: { calendars, prevPath, view } }}> <StyledButton3 style={{ width: '100%' }}> <StyledIcon icon="reschedule" /> Edit </StyledButton3> </StyledLink2>
                    <PopUpExtendedLeave user={user} child={child} selectedEvent={selectedEvent[0]} recurrenceId={selectedEvent[0].recurrenceId} allEvents={allEvents} />
                  </Flex>
                )}
              </div>
            )}
          </DetailWrapper>
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpViewEvents.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  allEvents: PropTypes.array.isRequired,
  child: PropTypes.array.isRequired,
  instructor: PropTypes.array.isRequired,
  allParticipants: PropTypes.array.isRequired,
  requests: PropTypes.array,
  allLessons: PropTypes.array,
  calendars: PropTypes.array,
  onClose: PropTypes.func.isRequired,
};

export default PopUpViewEvents;
