import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Tabs, ConfigProvider, Empty, Tag } from 'antd';
import { size, palette, font } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import get from 'lodash/get';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Button from '../../../components/atoms/Button';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import useUserForm from '../../../hooks/useUserForm';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import Image from '../../../components/atoms/Image';
import placeholder from '../../../asset/image/placeholder.jpeg';
import PopUpInventory from '../../../components/organisms/PopUpInventory';
import PopUpBuyBack from '../../../components/organisms/PopUpBuyBack';
import PopUpDelete from '../../../components/organisms/PopUpDelete';
import PopUpUpdate from '../../../components/organisms/PopUpUpdate';

const Wrapper = styled.div`
  height: 100%;
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100% - 66px);
  padding-bottom: 10px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
    min-height: calc(100% - 56px);
    padding-bottom: 0px;
  }
`;

const LeftWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 30%;
  margin-right: 15px;
  overflow: auto;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 12px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
    max-height: calc(50vh - 50px);
  }
`;

const RightWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  width: 70%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 0px;
    width: 100%;
    flex: 1;
  }
`;

const DetailWrapper = styled(Flex)`
  flex-direction: column;
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  padding: 10px 0px;
`;

const LabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const DetailLabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  width: 120px;
  min-width: 120px;
  margin-right: 20px;
  font-family: ${font('tertiary')};
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledLink = styled(Link)`
  color: #3E90F7;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-family: ${font('secondary')};

  &:hover,
  &:focus,
  &:active {
    color: #3E90F7;
  }
`;

const StyledTag = styled(Tag)`
  min-width: 70px;
  text-align: center;
  font-size: 14px;
`;

const StyledFlex2 = styled(Flex)`
  width: 100%;
  justify-content: center;
  > div {
    > div {
      height: 100%;
    }
  }
`;

const ModelContainer = styled.div`
  > div {
    padding: 24px 0px;
  }

  .ant-table-body {
    max-height: 100% !important;
    @media (max-width: ${size('mobileBreakpoint')}) {
      max-height: ${(props) => (props.divHeight ? `calc(100vh - ${props.divHeight}px - 260px)` : 'calc(100vh - 56px - 260px)')} !important;
    }
  }
`;

const LeftTopWrapper = styled(Flex)`
  justify-content: center;
  flex-direction: column;
`;

const ImageWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
  justify-content: center;
  margin-top: 0px;
`;

const EditWrapper = styled(Flex)`
  justify-content: flex-end;
`;

const Note = styled.div`
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Cell = styled.div`
  width: max-content;
`;

const ShowView = (props) => {
  const { user, match, location } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [addItem, setAddItem] = useState(null);
  const [itemType, setItemType] = useState(null);
  const [itemData, setItemData] = useState(null);
  const [divHeight, setDivHeight] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  const { data, loading } = useGeneralData({
    endpoint: 'inventory/inventoryItem',
    query: { inventoryId: match.params.id },
  });

  const { data: optionsData, loading: optionsLoader } = useGeneralData({
    endpoint: 'sale/saleInfo',
    query: { id: -1 },
  });

  const { data: inventorySectionsData, loading: inventorySectionsLoading } = useGeneralData({
    endpoint: 'inventory/inventorySections',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
        setIsSubmitting(false);
      }, 3000);
    }
  }, [errorMessage]);

  const history = useHistory();

  if (loading || optionsLoader || inventorySectionsLoading) return <Loading />

  if (!data || !data.product) return <Redirect to="/inventory" />;

  const { TabPane } = Tabs;

  const showPopUp = (type, item) => {
    setItemType(type);
    setItemData(item);
    setAddItem(true);
  }

  const cellRenderers = [
    {
      title: 'Item ID',
      dataIndex: 'id',
      key: 'id',
      width: 90,
      render: (text, record) => <Cell>{(record.id ? <StyledButton onClick={() => showPopUp('edit', record)} style={{ fontWeight: 'normal' }}> {`INV-IT${record.id}`} </StyledButton> : 'N/A')}</Cell>,
    },
    {
      title: 'Purchased Date',
      dataIndex: 'purchasedDate',
      key: 'purchasedDate',
      width: 140,
      render: (date) => <Cell>{(date ? moment(date).format('DD/MM/YYYY') : 'N/A')}</Cell>,
    },
    {
      title: 'Purchased From',
      dataIndex: 'purchasedFrom',
      key: 'purchasedFrom',
      width: 140,
      render: (name) => <Cell>{(name || 'N/A')}</Cell>,
    },
    {
      title: 'Purchased Price',
      dataIndex: 'purchasedPrice',
      key: 'purchasedPrice',
      width: 140,
      render: (price) => <Cell>{(`$${Number(price).toLocaleString()}` || 'N/A')}</Cell>,
    },
    {
      title: get(data, 'product.section', null) === 'Instruments for Rental' ? 'Rental Price (Monthly)' : 'Selling Price',
      dataIndex: 'sellingPrice',
      key: 'sellingPrice',
      render: (price) => <Cell>{(`$${Number(price).toLocaleString()}` || 'N/A')}</Cell>,
      width: 180,
    },
    {
      title: 'Status',
      key: 'soldTo',
      width: 90,
      render: (id, record) => <Cell><StyledTag color={record.soldTo ? 'orange' : record.arrivalStatus === 'Arrived' ? 'green' : 'red'}>{record.soldTo ? (data.product.section === 'Instruments for Rental' ? 'Rented out' : 'Sold') : record.arrivalStatus === 'Arrived' ? 'Available' : 'Not Yet Arrived'}</StyledTag></Cell>,
    },
    {
      title: 'Owner',
      dataIndex: 'soldToName',
      key: 'soldToName',
      width: 90,
      render: (soldTo) => <Cell>{((soldTo && soldTo.id) ? <Link to={`/student/show/${soldTo.id}`}>{`${soldTo.name}`}</Link> : soldTo)}</Cell>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'note',
      width: 110,
      render: (description) => <Cell><Note> { description || 'N/A' } </Note></Cell>,
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      width: 75,
      render: (note) => <Cell><Note> { note || 'N/A' } </Note></Cell>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Cell>
          <Flex>
            <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
              <StyledLink to={{ pathname: '/sale/create', state: record }} disabled={record.soldTo} style={{ color: record.soldTo && '#C1C1C1' }}> Sell </StyledLink>
            </div>
            <div style={{ borderRight: '1px solid #ADADAD', paddingLeft: 5, paddingRight: 5 }}>
              <PopUpBuyBack user={user} selectedItem={record} optionsData={optionsData} />
            </div>
            <div style={{ borderRight: '1px solid #ADADAD', paddingLeft: 5, paddingRight: 5 }}>
              <PopUpUpdate user={user} endpoint="inventoryItems/makeAvailable" record={record} type="makeAvailable" disabled={(!record.soldTo && record.arrivalStatus === 'Arrived')} />
            </div>
            { get(data, 'product.section', null) !== 'Instruments for Rental' ? (
              <div style={{ borderRight: '1px solid #ADADAD', paddingLeft: 5, paddingRight: 5 }}>
                <PopUpUpdate user={user} endpoint="inventoryItems/notYetArrived" record={record} type="notYetArrived" disabled={(record.soldTo || record.arrivalStatus === 'Not Yet Arrived')} />
              </div>
            ) : null}
            <div style={{ paddingLeft: 5 }}>
              <PopUpDelete user={user} endpoint="inventoryItems/deleteItem" id={record.id} type="Item" disabled={record.soldTo} />
            </div>
          </Flex>
        </Cell>
      ),
      width: 90,
    },
  ];

  const expandableColumns = [
    {
      title: 'Sale ID',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Link to={`/sale?currentPage=1&where%5BsaleStatus%5D=All&search=S-${id}`}>{`S-${id}`}</Link>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Buy Back Type',
      dataIndex: 'buyBackType',
      key: 'buyBackType',
      render: (content) => <Cell>{content || 'N/A'}</Cell>,
    },
    {
      title: 'Sale To / Buy Back From',
      dataIndex: 'saleTo',
      key: 'saleTo',
      render: (info) => ((info && info.id) ? <Link to={`/user/show/${info.id}`}>{`${info.name}`}</Link> : info),
    },
    {
      title: 'Student',
      dataIndex: 'studentInfo',
      key: 'studentInfo',
      render: (student) => (student ? <Link to={`/student/show/${student.id}`}>{student.name}</Link> : 'N/A'),
    },
    {
      title: 'Selling Price',
      dataIndex: 'formattedSalePrice',
      key: 'formattedSalePrice',
    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
    },
    {
      title: 'Package ID',
      dataIndex: 'packageInfo',
      key: 'packageInfo',
      render: (packageInfo) => ((packageInfo && packageInfo.id) ? <Link to={`/package/show/${packageInfo.id}`}>{`${packageInfo.prefix}${packageInfo.id}`}</Link> : 'N/A'),
    },
    {
      title: 'Credit Card Charge',
      dataIndex: 'creditCardCharge',
      key: 'creditCardCharge',
    },
    {
      title: 'Rental Period',
      dataIndex: 'rentalPeriod',
      key: 'rentalPeriod',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Flex>
          <StyledLink to={`/sale/edit/${record.id}`}> Edit </StyledLink>
        </Flex>
      ),
    },
  ];

  return (
    <Wrapper>
      {addItem ? (
        <PopUpInventory
          open
          user={user}
          selectedItem={itemData}
          type={itemType}
          sections={inventorySectionsData}
          onClose={() => { setAddItem(null); setItemType(null); setItemData(null) }}
        />
      ) : null}
      <Flex style={{ position: 'relative' }}>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> Inventory Management </HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <ErrorAlert
            errorMessage={errorMessage}
            show={errorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw / 2)' }}
          />
        </Flex>
      </Flex>
      <BottomWrapper>
        <LeftWrapper>
          <LeftTopWrapper>
            <EditWrapper>
              <StyledLink to={`/inventory/edit/${match.params.id}`}>
                <Icon icon="edit-icon" height={21} style={{ minWidth: 21 }} />
                <div>Edit</div>
              </StyledLink>
            </EditWrapper>
            <ImageWrapper>
              <Image width={128} height={128} src={data.product.photo || placeholder} alt="Image not found" style={{ minWidth: 128 }} />
              <LabelText style={{ fontSize: 16, paddingTop: 15, textAlign: 'center' }}>{data.product.product}</LabelText>
            </ImageWrapper>
          </LeftTopWrapper>
          <DetailWrapper>
            <StyledFlex>
              <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
              <LabelText style={{ marginRight: 0 }}> Basic Information </LabelText>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Product ID </DetailLabelText>
              <div>{`INV-PR${data.product.id}`}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Type </DetailLabelText>
              <div>{data.product.type}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Section </DetailLabelText>
              <div>{data.product.section}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Publisher / Maker </DetailLabelText>
              <div>{data.product.publisherOrMaker || 'N/A'}</div>
            </StyledFlex>
            <StyledFlex>
              <DetailLabelText> Quantity </DetailLabelText>
              <div>{(data.product.inventoryItems.length > 0 && data.product.inventoryItems[0].id) ? Number(data.product.inventoryItems.length) : 0}</div>
            </StyledFlex>
          </DetailWrapper>
        </LeftWrapper>
        <RightWrapper>
          <Tabs defaultActiveKey="Items">
            <TabPane tab="Items" key="Items">
              <ModelContainer divHeight={divHeight}>
                <ModelPage
                  showHeader={false}
                  modelName="inventoryItems"
                  itemsPerPage={25}
                  query={{ where: { inventoryId: match.params.id, status: 'All' } }}
                  initialQuery={{
                    currentPage: 1,
                    where: {
                      status: 'All',
                    },
                  }}
                  radioGroup={data.product.section === 'Instruments for Rental' ? ['All', 'Available', 'Rented out'] : ['All', 'Available', 'Sold', 'Not Yet Arrived']}
                  radioGroupKey="status"
                  radioGroupSize="middle"
                  allowSearch={false}
                  actions={[{ action: () => showPopUp('create', { inventoryId: match.params.id, section: data.product.section }), label: 'Add New Item' }]}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (divHeight !== height) setDivHeight(height);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Inventory" />}>
                        <AntDTable
                          modelName="inventoryItems"
                          cellRenderers={cellRenderers}
                          pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          scroll={{ x: 'max-content', y: '40vh' }}
                          expandable={{
                            rowExpandable: (record) => record.saleLogs,
                            expandedRowRender: (record) => (
                              <AntDTable
                                modelName="inventoryItems"
                                cellRenderers={get(data, 'product.section', null) === 'Instruments for Rental' ? expandableColumns : expandableColumns.filter((c) => c.title !== 'Rental Period')}
                                pagination={false}
                                rowKey="id"
                                data={record.saleLogs}
                                isExpanded
                              />
                            ),
                          }}
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
          </Tabs>
        </RightWrapper>
      </BottomWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state.authentication),
  }
}

export default connect(mapStateToProps)(ShowView);
