import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { font, palette, size } from 'styled-theme';
import Card from '../../components/atoms/Card';
import P from '../../components/atoms/P';
import Logo from '../../components/atoms/Logo';
import ForgotPasswordForm from './ForgotPasswordForm';
// import homeBackgroundImage from '../../assets/images/home-background-2.png';
// import homeCardImage from '../../assets/images/home-card-image.png';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const StyledBackLink = styled(Link)`
  color: #3998B5;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  margin-bottom: 8px;
  font-family: ${font('primary')};
`;

const StyledLogo = styled(Logo)`
  display: flex;
  margin: 0 auto;
  margin-bottom: 8px;
`;

const ContentWrapper = styled(Card)`
  display: flex;
  width: 420px;
  margin: 24px;
  padding: 0;
  border-radius: 10px;
  padding: 40px;
`;

const FormContainer = styled.div`
  width: 100%;
  padding: ${size('padding.large')}

  >form {
    >div {
      >div{
        >span {
          font-size: 11px;
        }
      }
    }
  }
`;

const ForgotPassword = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <FormContainer>
          <StyledBackLink to='/'>{'<'} Go Back</StyledBackLink>
          <StyledLogo colored={true} />
          <ForgotPasswordForm />
        </FormContainer>
      </ContentWrapper>
    </Wrapper>
  )
};

export default ForgotPassword;
