import React from 'react';
import { connect } from 'react-redux';
import filter from 'lodash/filter';
import get from 'lodash/get';
import moment from 'moment';
import styled from 'styled-components';

import { palette, size, font } from 'styled-theme';

import useNotificationData from '../hooks/useNotificationData';
import Flex from '../components/atoms/Flex';
import Icon from '../components/atoms/Icon';

const RightMenuWrapper = styled.div`
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${palette('white', 0)};
  /* background-color: #37C5B9; */
  align-items: center;
  overflow-y: auto;
  border-right: 1px solid #ECECEC;
  z-index: 2;
  width: 100%;
  padding: 10px;
`;

const NotificationHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: ${font('tertiary')};
`;

const MainFlex = styled(Flex)`
  flex-direction: column;
  width: 100%;
`;

const ItemContainerWithIcon = styled(Flex)`
  padding: 10px 0px;
  border-bottom: 1px solid #E8E8E8;
  align-items: flex-start;
`;

const ItemContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
  width: 100%;
`;

const TitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: ${font('tertiary')};
`;

const DescriptionText = styled.div`
  margin-bottom: 5px;
  word-break: break-word;
`;

const TimeText = styled.div`
  font-size: 12px;
  color: #757575;
`;

const NoItemText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding: 20px 0px;
  color: #757575;
`;

const NotificationContainer = ({ ...props }) => {
  const {
    data,
    loading,
  } = useNotificationData();
  const broadcastData = filter(data, (d) => (moment(d.startDate).isSameOrBefore(moment().tz('Asia/Singapore')) && moment(d.endDate).isSameOrAfter(moment().tz('Asia/Singapore'))) && d.type === 'broadcast');
  const newNotifications = filter(data, (d) => !d.read && d.type !== 'broadcast');
  const allNotifications = filter(data, (d) => d.read);

  if (loading) return null;
  return (
    <RightMenuWrapper>
      <MainFlex>
        <NotificationHeader> Broadcast </NotificationHeader>
        {(broadcastData && broadcastData.length > 0) ? (
          broadcastData.map((d, index) => {
            const isLast = index === broadcastData.length - 1;
            return (
              <ItemContainerWithIcon style={{ borderBottom: isLast && 'none' }}>
                <Icon icon="broadcast" height={40} style={{ minWidth: '40px' }} />
                <ItemContainer>
                  <Flex style={{ marginRight: '5px', justifyContent: 'space-between' }}>
                    <TitleText> Broadcast </TitleText>
                    <TimeText> {moment(d.startDate).fromNow()} </TimeText>
                  </Flex>
                  <DescriptionText> {d.description || ''} </DescriptionText>
                </ItemContainer>
              </ItemContainerWithIcon>
            )
          })
        ) : <NoItemText>No current broadcasts</NoItemText>}
      </MainFlex>
      <MainFlex style={{ marginTop: 15, display: 'none' }}>
        <NotificationHeader> New Notifications </NotificationHeader>
        {(newNotifications && newNotifications.length > 0) ? (
          newNotifications.map((d, index) => {
            const isLast = index === newNotifications.length - 1;
            return (
              <ItemContainerWithIcon style={{ borderBottom: isLast && 'none' }}>
                <Icon icon={d.type === 'cancellation' ? 'cancel-request' : d.type === 'reschedule' ? 'reschedule-request' : 'class-attendance'} height={40} style={{ minWidth: '40px' }} />
                <ItemContainer>
                  <Flex style={{ marginRight: '5px', justifyContent: 'space-between' }}>
                    <TitleText> { d.type === 'cancellation' ? 'Class Cancellation' : d.type === 'reschedule' ? 'Class Reschedule' : 'Class Attendance' }</TitleText>
                    <TimeText> {d.cancelledAt ? moment(d.cancelledAt).fromNow() : ''} </TimeText>
                  </Flex>
                  <DescriptionText>
                    {`${`${get(d, 'child.firstName')} ${get(d, 'child.lastName')}` || ''}${d.description ? ' - ' : ''}${d.description || ''}`}
                  </DescriptionText>
                  {/*<DescriptionText>
                    {`Cancelled by ${get(d, 'user.firstName')} ${get(d, 'user.lastName')}`}
                  </DescriptionText>*/}
                  <DescriptionText>
                    {`${moment.tz(d.startTime, 'Asia/Singapore').format('DD/MM/YYYY')} ${moment.tz(d.endTime, 'Asia/Singapore').format('HH:mm a')} - ${moment.tz(d.endTime, 'Asia/Singapore').format('HH:mm a')}`}
                  </DescriptionText>
                </ItemContainer>
              </ItemContainerWithIcon>
            )
          })
        ) : <NoItemText>No new notifications</NoItemText>}
      </MainFlex>
      {/*
      <MainFlex style={{ marginTop: 15 }}>
        <NotificationHeader> All Notifications </NotificationHeader>
        {(allNotifications && allNotifications.length > 0) ? (
          allNotifications.map((d, index) => {
            const isLast = index === allNotifications.length - 1;
            return (
              <ItemContainerWithIcon style={{ borderBottom: isLast && 'none' }}>
                <Icon icon={d.type === 'broadcast' ? 'broadcast' : d.type === 'cancellation' ? 'cancel-request' : d.type === 'reschedule' ? 'reschedule-request' : 'class-attendance'} height={40} style={{ minWidth: '40px' }} />
                <ItemContainer>
                  <Flex style={{ marginRight: '5px', justifyContent: 'space-between' }}>
                    <TitleText> {d.type === 'broadcast' ? 'Broadcast' : d.type === 'cancellation' ? 'Class Cancellation' : d.type === 'reschedule' ? 'Class Reschedule' : 'Class Attendance'}</TitleText>
                    <TimeText> {moment(d.startDate).fromNow()} </TimeText>
                  </Flex>
                  <DescriptionText> {(d.type === 'cancellation' || d.type === 'attendance' || d.type === 'reschedule') ? `${`${d.student} - ` || ''}${d.description || ''}` : `${d.description || ''}`} </DescriptionText>
                  {(d.type === 'cancellation' || d.type === 'attendance' || d.type === 'reschedule') ? (
                    <DescriptionText> {`${moment.tz(d.startDate, 'Asia/Singapore').format('DD/MM/YYYY')} ${moment.tz(d.startDate, 'Asia/Singapore').format('HH:mm a')} - ${moment.tz(d.endDate, 'Asia/Singapore').format('HH:mm a')}`} </DescriptionText>
                  ) : null}
                </ItemContainer>
              </ItemContainerWithIcon>
            )
          })
        ) : <NoItemText>No notifications</NoItemText>}
      </MainFlex>
      */}
    </RightMenuWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer);
