import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { compose, onlyUpdateForKeys } from 'recompose';
import styled from 'styled-components';
import { size } from 'styled-theme';

import { selectUserRole } from '../../../../store/authentication/selectors';
import Header from '../../../../components/organisms/containers/Header'

const RouteWrapper = styled.div`
  /* padding: 60px 1rem 60px calc(250px + 1rem); */
  padding-left: calc(250px + 25px); /* calc(250px + 2rem); */
  max-width: 100vw;
  padding-right: 25px; /* 2rem */
  flex-grow: 1;
  height: calc(100% - 40px);

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-left: 0px;
    padding-right: 0px;
    height: calc(100% - 60px);
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

const AdminRoute = ({ component: Component, authenticated, role, location, ...rest }) => (
  <Wrapper>
    <Header />
    <Route
      {...rest}
      render={(props) => (authenticated && (role === 'admin' || role === 'superAdmin' || role === 'parent' || role === 'instructor' || role === 'libraryHelper') ? (
        <RouteWrapper>
          <Component {...props} />
        </RouteWrapper>
      ) : (
        <Redirect to={{ pathname: '/', state: { from: location } }} />
      ))}
    />
  </Wrapper>
);

AdminRoute.propTypes = {
  location: PropTypes.object.isRequired,
  component: PropTypes.any.isRequired,
  authenticated: PropTypes.bool.isRequired,
  role: PropTypes.string,
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  role: selectUserRole(state.authentication),
});

const enhance = compose(withRouter, onlyUpdateForKeys(['authenticated', 'location']));

// https://github.com/ReactTraining/react-router/issues/4671
// https://reacttraining.com/react-router/core/guides/dealing-with-update-blocking
//
// By default, react-router components (e.g. Route, Switch) will be blocked from updating
// if a library is controlling `shouldComponentUpdate` (e.g. react-redux)
//
// To get around this, there are 2 things we've done:
// - Create our `connect` component, then wrap it with `withRouter`
// - Add `location` to `onlyUpdateForKeys` to make sure our component re-renders when
// `location` changes
export default enhance(connect(mapStateToProps)(AdminRoute));
