import { useState } from 'react';
import get from 'lodash/get';

export default (options = {}) => {
  const { endpoint, userId, setErrorMessage } = options;
  const [formDisabled, setFormDisabled] = useState(true);

  const { api } = global;
  let onSubmitSwitch;

  onSubmitSwitch = async (checked) => {
    const values = { checked, userId };

    return api
      .post(`/${endpoint}/${userId}/emailreminder`, { values })
      .catch((error) => setErrorMessage(error.message));
  };

  return {
    onSubmitSwitch,
    formDisabled,
  };
};
