import React, { useState, useRef, useMemo, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { font, palette, size } from 'styled-theme';
import { render, componentDidUpdate } from 'react-dom';
import moment from 'moment';
import TUICalendar from '@toast-ui/react-calendar';
import { ColorPicker } from '@salesforce/design-system-react';
import { Switch } from 'antd';
import DatePicker from 'react-datepicker';
import ReactDOMServer from 'react-dom/server';
import { ConfigProvider, Empty } from 'antd';
import ReactSelect, { createFilter } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import Popup from 'reactjs-popup';
import get from 'lodash/get';
import { every, isEmpty, isEqual, differenceWith } from 'lodash';
import { switchProp, ifProp } from 'styled-tools';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Radio } from 'antd';
import { Field, Form } from 'react-final-form';
import map from 'lodash/map';
import Loading from '../../atoms/Loading';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import PopUpViewEvents from '../PopUpViewEvents';
import useGeneralData from '../../../hooks/useGeneralData';
import useCalendarColor from '../../../hooks/useCalendarColor';
import useCalendarSwitch from '../../../hooks/useCalendarSwitch';
import Input from '../../atoms/Input';
import Card from '../../atoms/Card';
import Flex from '../../atoms/Flex';
import Link from '../../atoms/Link';
import AntDTable from '../AntDTable';
import useQueryParams from '../../../hooks/useQueryParams';
import useCalendarForm from '../../../hooks/useCalendarForm';
import ErrorAlert from '../../molecules/ErrorAlert';
import ModelPage from '../../../containers/ModelPage';
import LeftMenuContainerComp from '../../molecules/LeftMenuContainer';
import leftMenuAction from '../../../store/left-calendar-menu/actions';
import Logo from '../../atoms/Logo';
import FieldComponent from '../../molecules/FieldComponent';
import { validations } from '../../../utils/form';
import customStyle from '../../atoms/Select/styles';
import useCalendarData from '../../../hooks/useCalendarData';

import 'react-datepicker/dist/react-datepicker.css';
import 'antd/dist/antd.css';
import 'tui-calendar/dist/tui-calendar.css';
import '@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.min.css';

const { open: leftMenuOpen } = leftMenuAction;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  height: calc(100% - 15px);
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  @media (max-width: ${size('mobileBreakpoint')}){
    width: 100%;
  }

  .calendarContainer {
    height: ${switchProp('role', {
      instructor: (props) => (props.topHeight ? `calc(100% - ${props.topHeight}px)` : 'calc(100% - 60px)'),
      parent: (props) => (props.topHeight ? `calc(100% - ${props.topHeight}px)` : 'calc(100% - 60px)'),
      admin: (props) => (props.topHeight ? `calc(100% - ${props.topHeight}px)` : 'calc(100% - 100px)'),
      superAdmin: (props) => (props.topHeight ? `calc(100% - ${props.topHeight}px)` : 'calc(100% - 100px)'),
    })};

    @media (max-width: ${size('mobileBreakpoint')}){
      height: calc(100% - 34px);
    }
  }
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-left: 20px;

  @media (max-width: ${size('mobileBreakpoint')}){
    display: none;
  }
`;

const LeftTopWrapper = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}){
    display: none;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: ${ifProp({ role: 'instructor' }, 'row')};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 13px;
`;

const StyledButton = styled(Button)`
  border: 1px;
  color: ${palette('primary', 0)};
  border-color: ${palette('primary', 0)};
  background-color: white;
  border-radius: 0px;
  border-style: solid;
  height: 28px;
  width: 25px;
  min-width: 25px;
  margin-right: 15px;
  margin-bottom: 0px;
  padding: 0px;
  line-height: 0px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
  }
`;

const ColorWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin: 20px 0px 0px 0px;
  padding: 10px;
  border-radius: 0px;
  height: 100%;
  overflow-y: auto;
  width: 100%;

  @media (max-width: ${size('mobileBreakpoint')}){
    margin: 0px;
  }
`;

const EmailWrapper = styled(Card)`
  display: flex;
  flex-direction: row;
  margin: 20px 0px 20px 0px;
  padding: 20px 10px 20px 10px;
  background-color: ${palette('primary', 0)};
  border-radius: 0px;
  align-items: center;
`;

const StyledFlex = styled(Flex)`
  align-items: center;
`;

const ClassesCheckboxContainer = styled(Flex)`
  align-items: center;
  > div {
    margin: 5px 15px;
  }
`;

const StyledText = styled.div`
  color: ${palette('black', 0)};
  margin-top: 10px;
  margin-left: 10px;
`;

const StyledCheckBox = styled(Input)`
  transform: scale(1.1);
  margin-top: 10px;
`;

const StyledColorPicker = styled(ColorPicker)`
  svg {
    height: 0px;
    width: 0px
  }

  ul[role=tablist] {
    margin-bottom: 0px;
  }
`;

const StyledButton2 = styled(Button)`
  margin: 0px;
  border-radius: 0px;
  padding: 2px 12px;
`;

const StyledLink = styled(Link)`
  &:hover, &:focus {
    text-decoration: none;
  }
`;

const StyledButton3 = styled(Button)`
  min-width: 110px;
  width: 110px;
  color: ${palette('primary', 0)};
  background-color: ${palette('white', 0)};
  border-color: ${palette('primary', 0)};
  border-radius: 0px;
  margin: 0px;
  margin-left: 15px;
  text-align: center;
  display: flex;
  line-height: normal;

  &:hover,
  &:focus,
  &:active {
    background-color: ${palette('white', 0)};
    color: ${palette('primary', 0)};
    text-decoration: none;
  }
`;

const StyledButton4 = styled(Button)`
  min-width: 70px;
  width: 70px;
  display: flex;
  color: #797979;
  background-color: ${palette('white', 0)};
  border-color: #797979;
  border-radius: 0px;
  margin: 0px;
  text-align: center;
  line-height: normal;
  margin-right: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: ${palette('white', 0)};
    color: #797979;
    text-decoration: none;
  }
`;

const Container = styled.div`
  .react-datepicker, .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__day-names, .react-datepicker__week {
    display: flex;
    justify-content: center;
  }

  .react-datepicker__day-name, .react-datepicker__day {
    width: 100%;
  }

  .react-datepicker__day--selected, .react-datepicker__day--selected:hover {
    background-color: ${palette('primary', 0)};
  }

  .react-datepicker__day, .react-datepicker__day:hover {
    border-radius: 0%;
  }

  .react-datepicker__navigation {
    outline: none;
  }

  .react-datepicker__header {
    background-color: #fff;
  }

  .react-datepicker__day--outside-month {
    color: #b2b2b2;
  }
`;

const CalendarContainer = styled.div`
  > div {
    > div {
      height: 100%;
      > div {
        height: 100%;
        min-height: auto;
        display: flex;
        flex-direction: column;
        > div: last-child {
          flex: 1;
          display: flex;
          flex-direction: column;
          height: 0px !important;
          > div: last-child {
            flex: 1;
            height: 0px !important;
          }
        }
      }
    }
  }
`;

const ColorPickerContainer = styled.div`
  .slds-button__icon_x-small {
    display: none;
  }
`;

const ModelContainer = styled.div`
  > div {
    padding: 0px;
    background: none;
    > div {
      margin-left: 0px !important;
      > div: first-child {
        margin-left: 0px !important;
      }
      > div:last-child {
        margin-bottom: 0px;
        margin-left: 0px !important;
        > button {
          height: 100%;
          padding: 2px 12px;
        }
      }
    }
  }
`;

const MobileDateContainer = styled.div`
  display: flex;
  position: relative;

  @media (min-width: calc(${size('mobileBreakpoint')} + 1px)){
    display: none;
  }
`;

const MobileDateButton = styled(Button)`
  margin: 0;
  padding: 0px;
  padding-left: 0px;
  line-height: normal;
  height: 24px;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }

  @media (min-width: calc(${size('mobileBreakpoint')} + 1px)){
    display: none;
  }
`;

const MobileDatePicker = styled.div`
  order: 3;
  width: 0px;
  position: absolute;
  width: 100%;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.2s ease;
  box-shadow: ${size('boxShadow.small')};
  border-radius: 4px;
  z-index: 3;
  top: 34px;
  max-width: 500px;

  &.active {
    overflow: visible;
    max-height: 1000px;
    transition: all 0.2s ease;
  }

  @media (min-width: calc(${size('mobileBreakpoint')} + 1px)){
    display: none;
  }
`;

const MobileCalendarButton = styled(Button)`
  display: flex;
  margin: 0;
  padding: 0px;
  min-width: 35px;
  width: 35px;
  top: calc((100% - 60px) / 2);
  left: 0;
  position: absolute;
  z-index: 1;
  border-radius: 0px 35px 35px 0px;
  height: 70px;

  @media (min-width: calc(${size('mobileBreakpoint')} + 1px)){
    display: none;
  }
`;

const LeftCalendarMenuWrapper = styled.div`
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: ${props => (`${props.width}px`)};
  background-color: ${palette('white', 0)};
  /* background-color: #37C5B9; */
  align-items: center;
  overflow-y: auto;
  border-right: 1px solid #ECECEC;
  z-index: 2;
`;

const LeftMenuWrapper = styled.div`
  @media (min-width: calc(${size('mobileBreakpoint')} + 1px)){
    display: none;
  }
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
  width: 90vw;
  min-width: 90vw;
  padding: 20px;

  @media (min-width: calc(${size('mobileBreakpoint')} + 1px)){
    display: none;
  }
`;

const PopUpPolicyWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 35vw;
  min-width: 35vw;
  padding: 20px;

  > form {
    height: calc(100% - 36px);
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const RadioGroupContainer = styled.div`
  > div {
    display: flex;
    flex-direction: column;

    label {
      margin-top: 20px;
      > span: first-child {
        margin-right: 5px;
      }
    }
  }
`;

const PolicyContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #D7D7D7;
  margin-bottom: 20px;
`;

const StyledLogo = styled(Logo)`
  display: flex;
  margin: 0 auto;
  width: 240px;
  height: 240px;

  @media (max-width: 768px){
    width: 180px;
    height: 180px;
  }
`;

const PolicyHeader = styled.div`
  color: ${palette('primary', 0)};
  font-size: 24px;
  font-weight: bold;
  padding: 36px 0px;
  text-align: center;

  @media (max-width: 768px){
    padding: 24px 0px;
  }
`;

const PolicyLink = styled(Link)`
  color: #3E90F7;
  align-items: center;
  font-weight: bold;
  display: inline;

  &:hover,
  &:focus,
  &:active {
    color: #3E90F7;
  }
`;

const StyledForm = styled.form`
  >div {
    >div {
      min-height: 16px;
    }
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const PolicyCheckBoxContainer = styled.div`
  margin-bottom: 5px;
  > div {
    > div: first-child {
      display: none;
    }
    > div: nth-child(2) {
      margin: 0px;
      > label {
        left: 3px;
        > input {
          transform: scale(1.4);
        }
        > div {
          color: #303030;
        }
      }
    }
    > div: last-child {
      margin-top: 5px;
    }
  }
`;

const SelectWrapper = styled.div`
  width: 110px;
  min-width: 110px;
  margin-right: 15px;

  > div {
    > div {
      min-width: 110px;
    }
  }
`;

const MenuContainer = styled.div`
  > div {
    > div {
      div {
        div {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = 35;
  const [value] = getValue();
  const listHeight = Math.min(maxHeight, height * children.length) || 200;
  let initialOffset = options.indexOf(value) * height;
  initialOffset = initialOffset > listHeight ? initialOffset : 0;
  return (
    <MenuContainer>
      { children.length ? (
        <List
          height={listHeight}
          itemCount={children.length}
          itemSize={height}
          initialScrollOffset={initialOffset}
        >
          {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
      ) : <div style={{ padding: '10px 8px' }}> No options available </div>}
    </MenuContainer>
  );
};

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) } : null;
}

const setContrast = (rgb) => (rgb ? ((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 125 ? '#000000' : '#ffffff') : '#000000');

const formatSchedules = (schedulesInput, role, allEvents, formCalendars, userId) => {
  const formatted = schedulesInput.map((schedule) => {
    let calId = -1;
    let lessonType = '';
    let attendees = [];

    let isCancelled = false;
    if (schedule.type === 'Lesson') {
      const lessonStudents = role === 'parent' ? allEvents.filter((s) => s.eventId === schedule.eventId && Number(s.package.parentId) === Number(userId)) : allEvents.filter((s) => s.eventId === schedule.eventId);
      isCancelled = lessonStudents.filter((s) => s.status === 'Scheduled').length === 0;
      const allStudentPackages = isCancelled ? lessonStudents.filter((ls) => ({ packageType: get(ls, 'package.type', null), isFreeTrial: ls.isFreeTrial })) : lessonStudents.filter((c) => c.status === 'Scheduled').map((ls) => ({ packageType: get(ls, 'package.type', null), isFreeTrial: ls.isFreeTrial }));
      const isTrialClass = allStudentPackages.filter((asp) => asp.packageType === 'Trial Package' || asp.isFreeTrial).length === allStudentPackages.length;
      const lessonTypeSplit = schedule.lessonType.split(' ');
      lessonType = ` (${lessonTypeSplit[0]}${isTrialClass ? ' Trial' : ''})`;
      attendees = lessonStudents.map((c) => ((c.status === 'Cancelled' || c.status === 'Admin Cancelled') ? `<del>${`${c.child.firstName} ${c.child.lastName}`.trim()}</del>` : `${`${c.child.firstName} ${c.child.lastName}`.trim()}`));
    }

    if ((role === 'admin' || role === 'superAdmin') && schedule.type === 'Lesson') calId = schedule.instructorId.toString();
    else if (role === 'parent' && schedule.type === 'Lesson' && schedule.lessonType === 'Private Lesson') calId = schedule.childId.toString();

    const colorObj = { borderColor: '#000000' };

    if (calId === -1) {
      if (schedule.type === 'Block Time' || schedule.type === 'Personal Block') {
        const allParticipants = allEvents.filter((s) => s.eventId === schedule.eventId);
        if (allParticipants.length > 1 || !get(allParticipants, '0.participant.id', null) || role === 'parent' || role === 'instructor') {
          calId = '-2';
        } else calId = schedule.userId.toString();
      } else if (schedule.type === 'Event') {
        const allParticipants = allEvents.filter((s) => s.eventId === schedule.eventId);
        if (allParticipants.length > 1 || !get(allParticipants, '0.participant.id', null) || role === 'parent' || role === 'instructor') {
          calId = '-3';
        } else calId = schedule.userId.toString();
      } else if (schedule.lessonType === 'Semiprivate Lesson') {
        calId = '-4';
      } else if (schedule.lessonType === 'Group Lesson') {
        calId = '-5';
      } else if (schedule.lessonType === 'Private Lesson') {
        calId = '-6';
      }
    }

    let customBgColor;
    if (formCalendars && formCalendars.length > 0) {
      const currentCalendar = formCalendars.filter((c) => c.id === calId);
      customBgColor = `${get(currentCalendar, '0.bgColor', '')}80`
    }

    const showNote = ((role === 'admin' || role === 'superAdmin' || role === 'instructor') && schedule.type === 'Lesson');

    return ({
      id: schedule.id.toString(),
      calendarId: calId,
      category: 'time',
      title: `${schedule.title} ${lessonType}`,
      start: schedule.startTime,
      end: schedule.endTime,
      isVisible: true,
      isReadOnly: true,
      raw: showNote ? {
        status: isCancelled,
        note: schedule.note,
        externalNote: schedule.externalNote,
        type: schedule.type,
      } : {
        status:
        isCancelled,
        note: null,
        type: schedule.type,
      },
      eventId: schedule.eventId,
      isAllDay: moment(schedule.endTime).diff(moment(schedule.startTime), 'days', true) >= 1,
      attendees,
      ...colorObj,
      customStyle: (moment(schedule.endTime).isBefore(moment()) && customBgColor) ? `background-color: ${customBgColor}` : '',
    })
  })
  return formatted
}

const ignoredUserIds = [
  410, // tracy
  442, // greg
  20, // altitude instructor
  18, // altitude admin
  19, // altitude super admin
];

const formatCalendar = (calendarInput, role, initUserCalendar) => {
  const userCalendar = (initUserCalendar == null || !Array.isArray(initUserCalendar)) ? [] : initUserCalendar;
  return [
    ...userCalendar,
    ...calendarInput
      // .filter((item) => every((ignoredUserIds), (igId) => igId !== item.instructorId))
      .map((c) => {
        let calId = -1;
        if (role === 'parent') calId = c.id.toString();
        else if (role === 'admin' || role === 'superAdmin') calId = c.instructorId.toString();
        else if (role === 'instructor') calId = c.childId.toString();
        return ({
          id: calId,
          name: c.firstName + c.lastName,
          color: setContrast(hexToRgb(c.calendarColor)),
          bgColor: c.calendarColor || '#',
          dragBgColor: c.calendarColor,
          borderColor: '#000000',
        })
      }),
  ]
}


const Calendar = ({ user, onClose, open, location }) => {
  const calendarRef = useRef(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [calendar, setCalendar] = useState(null)
  const [schedules, setSchedules] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [revertErrorMessage, setRevertErrorMessage] = useState(null);
  const [items, setItems] = useState(null);
  const [emailSwitch, setEmailSwitch] = useState(user.emailNotification === true);
  const [formSchedules, setFormSchedules] = useState(null);
  const [formCalendars, setFormCalendars] = useState(null);
  const [blockTimeHidden, setBlockTimeHidden] = useState(false);
  const [eventHidden, setEventHidden] = useState(false);
  const [privateHidden, setPrivateHidden] = useState(false);
  const [semiprivateHidden, setSemiprivateHidden] = useState(false);
  const [groupHidden, setGroupHidden] = useState(false);
  const [otherCalendarIds, setOtherCalendarIds] = useState([]);
  const [showConfirmed, setShowConfirmed] = useState(true);
  const [showCancelled, setShowCancelled] = useState(true);
  const [errorFormValue, setErrorFormValue] = useState(null);
  const [showMobileDatePicker, setShowMobileDatePicker] = useState(false);
  const [showMobileCreate, setShowMobileCreate] = useState(false);
  const [mobileCreateRadio, setMobileCreateRadio] = useState(false);
  const [showPolicy, setShowPolicy] = useState(user.role === 'parent' && !user.isPolicyConfirmed);
  const [isSameWeek, setIsSameWeek] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [userCalendars, setUserCalendars] = useState(null);

  const { onSubmit } = useCalendarColor({
    endpoint: 'event/color',
    setErrorMessage,
  });

  const { onSubmit: onUpdateColor } = useCalendarColor({
    endpoint: 'event/typeColor',
    setErrorMessage,
  });

  const { queryParams, setQueryParams } = useQueryParams({ initialQueryParams: { view: 'daily', date: moment().format('YYYY-MM-DD'), id: [] } });

  const currentDate = moment(get(queryParams, 'date', moment().format('YYYY-MM-DD'))).isValid() ? get(queryParams, 'date', moment().format('YYYY-MM-DD')) : moment().format('YYYY-MM-DD');

  const [height, setHeight] = useState(['parent', 'instructor'].includes(user.role) ? 60 : 100);
  const ref = useRef(null)

  useLayoutEffect(() => {
    setHeight(get(ref, 'current.offsetHeight', ['parent', 'instructor'].includes(user.role) ? 60 : 100));
  }, [get(ref, 'current.offsetHeight', null)])

  const handleResize = () => {
    setHeight(get(ref, 'current.offsetHeight', ['parent', 'instructor'].includes(user.role) ? 60 : 100));
    if (window.innerWidth <= 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])

  if (isMobile && get(queryParams, 'view', 'daily') === 'weekly') setQueryParams((old) => ({ ...old, view: 'daily' }), { replace: true });

  const { data, loading, setData } = useCalendarData({
    endpoint: 'event',
    query: {
      userId: user.id,
      currentDate,
      view: get(queryParams, 'view', 'daily'),
      isSameWeek,
    },
  });

  const { onSubmitSwitch } = useCalendarSwitch({
    endpoint: 'user',
    userId: user.id,
    setErrorMessage,
  });

  const { onSubmit: onPolicyConfirm } = useCalendarForm({
    endpoint: 'user/policy',
    type: 'policy',
    setCompleteMessage: setShowPolicy,
    setErrorMessage,
  });

  useEffect(() => {
    if (revertErrorMessage !== null && !errorFormValue) {
      setTimeout(() => {
        setRevertErrorMessage(null);
      }, 3000);
    }
  }, [errorFormValue, revertErrorMessage]);

  const userCalendarIds = user.role === 'instructor' ? [-2, -3, -4, -5, -6] : user.role === 'parent' ? [-2, -4, -5] : [-2, -3];
  const initialUserCalendars = userCalendarIds.map((id) => {
    let name = '';
    let color = '#';
    if (id === -2) {
      name = 'Block';
      color = get(get(data, 'userCalendars', []).filter((c) => c.calendar === 'Block'), '0.color', null) || '#654321';
    } else if (id === -3) {
      name = 'Event';
      color = get(get(data, 'userCalendars', []).filter((c) => c.calendar === 'Event'), '0.color', null) || '#b5651d';
    } else if (id === -4) {
      name = 'Semiprivate';
      color = get(get(data, 'userCalendars', []).filter((c) => c.calendar === 'Semiprivate'), '0.color', null) || '#DDE26A';
    } else if (id === -5) {
      name = 'Group';
      color = get(get(data, 'userCalendars', []).filter((c) => c.calendar === 'Group'), '0.color', null) || '#EED9C4';
    } else if (id === -6) {
      name = 'Private';
      color = get(get(data, 'userCalendars', []).filter((c) => c.calendar === 'Private'), '0.color', null) || '#84CDBD';
    }

    return ({
      id: id.toString(),
      name,
      color: setContrast(hexToRgb(color)),
      bgColor: color || '#',
      dragBgColor: color,
      borderColor: '#000000',
    })
  })

  const onClickSchedule = (e) => {
    setSelectedSchedule(e.schedule);
  };

  const tuicalendar = useMemo(() => (
    <CalendarContainer {...{ role: user.role }} className="calendarContainer">
      <TUICalendar
        ref={calendarRef}
        height="100%"
        view={(get(queryParams, 'view', 'daily') === 'weekly' && !isMobile) ? 'week' : 'day'}
        week={{ startDayOfWeek: 1 }}
        taskView={false}
        scheduleView={['time', 'allday']}
        useCreationPopup={false}
        useDetailPopup={false}
        template={{
          time(schedule) {
            const startTime = moment(new Date(schedule.start));
            const endTime = moment(new Date(schedule.end));
            const attendees = schedule.attendees.length !== 0 ? schedule.attendees.join(', ') : '';
            let text = '';
            const lessonNote = ReactDOMServer.renderToString(
              <Icon
                icon={schedule.color === '#ffffff' ? 'lesson-note-white' : 'lesson-note-black'}
                height={8}
                style={{ minWidth: 8, marginRight: 5 }}
              />
            );
            const crossOut = schedule.raw.status ? 'text-decoration: line-through;' : '';
            const duration = schedule.isAllDay ? '' : `${startTime.format('HH:mm a')} - ${endTime.format('HH:mm a')}`;

            text = get(schedule, 'raw.type', null) === 'Lesson' ? `<div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ${crossOut}"> ${attendees} ${duration} </div> <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ${crossOut}"> ${schedule.title} </div>`
              : `<div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"> ${schedule.title} </div> <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"> ${attendees} ${duration} </div>`;

            return `<div style="padding-top: 2px; padding-left: 2px; display:flex;"><div>${(schedule.raw.note || schedule.raw.externalNote) ? lessonNote : ''}</div><div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${text}</div></div>`;
          },
        }}
        disableClick
        disableDblClick
        isReadOnly
        onClickSchedule={onClickSchedule}
        theme={{
          'week.timegridOneHour.height': '104px',
          'week.timegridHalfHour.height': '52px',
          'week.timegridHalfHour.borderBottom': '1px solid #ECECEC',
        }}
      />
    </CalendarContainer>
  ), [get(queryParams, 'view', 'daily')])

  let updatedData = null;
  let formattedSchedules = null;
  let formattedCal = null;

  if (calendarRef.current) calendarRef.current.getInstance().setDate(moment(currentDate).toDate());

  if (data) {
    if (user.role === 'parent') {
      updatedData = data.children
        .filter((c) => !c.deletedAt)
        .map((c) => ({ ...c, hidden: false }));
    } else if (user.role === 'admin' || user.role === 'superAdmin') {
      updatedData = data.adminCalendar.map((i) => ({ ...i, hidden: false }));
    } else if (user.role === 'instructor') updatedData = [];
    if (items === null) {
      setItems(updatedData);
    }
    if (userCalendars === null) setUserCalendars(initialUserCalendars);
    if (formCalendars) formattedSchedules = formatSchedules(data.events, user.role, data.allEvents, formCalendars, user.id);

    if (formSchedules === null || !isEmpty(differenceWith(formSchedules, formattedSchedules, isEqual))) setFormSchedules(formattedSchedules);
    formattedCal = formatCalendar(updatedData, user.role, userCalendars || initialUserCalendars);

    if (formCalendars === null) setFormCalendars(formattedCal);
    if (formSchedules) {
      calendarRef.current.getInstance().clear();
      let filteredFormSchedules = formSchedules.filter((s) => map((get(queryParams, 'id', {}) || {}), (ids) => Number(ids)).includes(Number(s.calendarId)) === false && otherCalendarIds.includes(Number(s.calendarId)) === false);
      if (blockTimeHidden) filteredFormSchedules = filteredFormSchedules.filter((s) => get(s, 'raw.type', null) !== 'Block Time');
      if (eventHidden) filteredFormSchedules = filteredFormSchedules.filter((s) => get(s, 'raw.type', null) !== 'Event');
      if (!showConfirmed) filteredFormSchedules = filteredFormSchedules.filter((s) => get(s, 'raw.status', null) || get(s, 'raw.type', null) !== 'Lesson');
      if (!showCancelled) filteredFormSchedules = filteredFormSchedules.filter((s) => !get(s, 'raw.status', null) || get(s, 'raw.type', null) !== 'Lesson');
      calendarRef.current.getInstance().createSchedules(filteredFormSchedules);
    }
    calendarRef.current.getInstance().setCalendars(formCalendars);
  }
  const setItem = (id, color, hide) => {
    let index = null;
    if (user.role === 'parent') index = items.findIndex((i) => i.id === id);
    else if (user.role === 'admin' || user.role === 'superAdmin') index = items.findIndex((i) => i.instructorId === id);
    else if (user.role === 'instructor') index = items.findIndex((i) => i.childId === id);

    if (color != null) {
      onSubmit(id, color);
      items[index].calendarColor = color;
      setItems([...items]);
      const formattedCalendar = formatCalendar(items, user.role, userCalendars);
      setFormCalendars(formattedCalendar);
    } else {
      const currentHideIds = map((get(queryParams, 'id', {}) || {}), (ids) => Number(ids));
      const latestHideIds = hide ? [...new Set([...currentHideIds, Number(id)])] : currentHideIds.filter((c) => Number(c) !== Number(id));

      setQueryParams((old) => ({
        ...old,
        id: latestHideIds,
      }), { replace: true });
    }
  };

  const setOtherIds = (hide, calendarIds) => {
    let otherIds;
    if (hide) otherIds = [...otherCalendarIds, ...calendarIds];
    else otherIds = otherCalendarIds.filter((id) => calendarIds.includes(Number(id)) === false);
    setOtherCalendarIds([...otherIds]);
    if (calendarIds.includes(-2)) setBlockTimeHidden(hide);
    if (calendarIds.includes(-3)) setEventHidden(hide);
    if (calendarIds.includes(-4)) setSemiprivateHidden(hide);
    if (calendarIds.includes(-5)) setGroupHidden(hide);
    if (calendarIds.includes(-6)) setPrivateHidden(hide);
  }

  const handleClickNextButton = () => {
    setIsSameWeek(false);
    setData(null)
    calendarRef.current.getInstance().clear();
    setQueryParams((old) => ({
      ...old,
      date: get(queryParams, 'view', 'daily') === 'weekly' ? moment(currentDate).add(1, 'weeks').format('YYYY-MM-DD') : moment(currentDate).add(1, 'days').format('YYYY-MM-DD'),
    }), { replace: true });
    setFormSchedules(null);
  };
  const handleClickPrevButton = () => {
    setIsSameWeek(false);
    setData(null)
    calendarRef.current.getInstance().clear();
    setQueryParams((old) => ({
      ...old,
      date: get(queryParams, 'view', 'daily') === 'weekly' ? moment(currentDate).subtract(1, 'weeks').format('YYYY-MM-DD') : moment(currentDate).subtract(1, 'days').format('YYYY-MM-DD'),
    }), { replace: true });
    setFormSchedules(null);
  };

  const handleCloseModal = () => {
    setSelectedSchedule(null);
  };

  const onDateSelect = (value) => {
    setIsSameWeek(false);
    if (get(queryParams, 'view', 'daily') === 'weekly' && moment.tz(value.toDate(), 'Asia/Singapore').isBetween(moment.tz(currentDate, 'Asia/Singapore').startOf('isoWeek'), moment.tz(currentDate, 'Asia/Singapore').endOf('isoWeek'), undefined, [])) {
      setIsSameWeek(true);
      setQueryParams((old) => ({
        ...old,
        date: moment(value.toDate()).format('YYYY-MM-DD'),
      }), { replace: true });
    } else if (moment(value.toDate()).format('YYYY-MM-DD') !== currentDate) {
      setData(null)
      calendarRef.current.getInstance().clear();
      setQueryParams((old) => ({
        ...old,
        date: moment(value.toDate()).format('YYYY-MM-DD'),
      }), { replace: true });
      setFormSchedules(null);
    }
  };

  const setHiddens = (hide) => {
    let allHideIds = [];
    if (hide) {
      if (user.role === 'parent') allHideIds = items.map((c) => c.id);
      else if (user.role === 'admin' || user.role === 'superAdmin') allHideIds = items.map((c) => c.instructorId);
      else if (user.role === 'instructor') allHideIds = items.map((c) => c.childId);
    }

    setQueryParams((old) => ({
      ...old,
      id: allHideIds,
    }), { replace: true });
  }

  const onChangeUserCalendar = (type, color, id) => {
    const index = userCalendars.findIndex((i) => i.id === id);
    onUpdateColor({ userId: user.id, type }, color);
    userCalendars[index].bgColor = color;
    userCalendars[index].dragBgColor = color;
    userCalendars[index].color = setContrast(hexToRgb(color));
    setUserCalendars([...userCalendars]);
    const formattedCalendar = formatCalendar(items, user.role, userCalendars);
    setFormCalendars(formattedCalendar);
  };

  let currHideIds = [];
  if (user.role === 'parent') currHideIds = (items && items.length > 0) ? items.map((c) => Number(c.id)) : [];
  else if (user.role === 'admin' || user.role === 'superAdmin') currHideIds = (items && items.length > 0) ? items.map((c) => Number(c.instructorId)) : [];
  else if (user.role === 'instructor') currHideIds = (items && items.length > 0) ? items.map((c) => Number(c.childId)) : [];

  const isAllHiddens = currHideIds.filter((x) => map((get(queryParams, 'id', {}) || {}), (ids) => Number(ids)).includes(x)).length > 0;

  const defaultColors = ['#e3abec','#c2dbf7','#9fd6ff','#9de7da','#9df0c0','#fff099','#fed49a','#d073e0','#86baf3','#5ebbff','#44d8be','#3be282','#ffe654','#ffb758','#bd35bd','#5779c1','#5679c0','#00aea9','#3cba4c','#f5bc25','#f99221','#580d8c','#001970','#0a2399','#0b7477','#0b6b50','#b67e11','#b85d0d'];

  const currUserCalendars = (userCalendars == null || !Array.isArray(userCalendars)) ? [] : userCalendars;
  const calendarFilters = (
    <ColorWrapper>
      <div>
        <StyledText style={{ fontWeight: 'bold' }}> My Calendars </StyledText>
        <ClassesCheckboxContainer style={{ paddingTop: 5 }}>
          <StyledCheckBox type="checkbox" checked={showConfirmed} onChange={() => setShowConfirmed(!showConfirmed)} />
          <StyledText style={{ margin: '10px 0px 0px 0px' }}> Confirmed Classes </StyledText>
        </ClassesCheckboxContainer>
        <ClassesCheckboxContainer style={{ borderBottom: '1px solid #DCDCDC', paddingBottom: 10 }}>
          <StyledCheckBox type="checkbox" checked={showCancelled} onChange={() => setShowCancelled(!showCancelled)} />
          <StyledText style={{ margin: '10px 0px 0px 0px' }}> Cancelled Classes </StyledText>
        </ClassesCheckboxContainer>
      </div>
      <div>
        { (user.role === 'instructor' || user.role === 'parent') && (
          <div>
            { user.role === 'instructor' ? (
              <StyledFlex>
                <StyledCheckBox type="checkbox" checked={!privateHidden} onChange={() => setOtherIds(!privateHidden, [-6])} />
                <ColorPickerContainer>
                  <StyledColorPicker value={get(currUserCalendars.filter((c) => c.id === '-6'), '0.bgColor', '#84CDBD') || '#84CDBD'} events={{ onChange: (e, d) => onChangeUserCalendar('Private', d.color, '-6') }} swatchColors={defaultColors} hideInput />
                </ColorPickerContainer>
                <StyledText> Private </StyledText>
              </StyledFlex>
            ) : null}
            <StyledFlex>
              <StyledCheckBox type="checkbox" checked={!semiprivateHidden} onChange={() => setOtherIds(!semiprivateHidden, [-4])} />
              <ColorPickerContainer>
                <StyledColorPicker value={get(currUserCalendars.filter((c) => c.id === '-4'), '0.bgColor', '#DDE26A') || '#DDE26A'} events={{ onChange: (e, d) => onChangeUserCalendar('Semiprivate', d.color, '-4') }} swatchColors={defaultColors} hideInput />
              </ColorPickerContainer>
              <StyledText> Private / Semiprivate </StyledText>
            </StyledFlex>
            <StyledFlex>
              <StyledCheckBox type="checkbox" checked={!groupHidden} onChange={() => setOtherIds(!groupHidden, [-5])} />
              <ColorPickerContainer>
                <StyledColorPicker value={get(currUserCalendars.filter((c) => c.id === '-5'), '0.bgColor', '#EED9C4') || '#EED9C4'} events={{ onChange: (e, d) => onChangeUserCalendar('Group', d.color, '-5') }} swatchColors={defaultColors} hideInput />
              </ColorPickerContainer>
              <StyledText> Group </StyledText>
            </StyledFlex>
          </div>
        )}
        { (user.role === 'admin' || user.role === 'superAdmin' || user.role === 'instructor') && (
          <StyledFlex>
            <StyledCheckBox type="checkbox" checked={!eventHidden} onChange={() => setOtherIds(!eventHidden, [-3])} />
            <ColorPickerContainer>
              <StyledColorPicker value={get(currUserCalendars.filter((c) => c.id === '-3'), '0.bgColor', '#b5651d') || '#b5651d'} events={{ onChange: (e, d) => onChangeUserCalendar('Event', d.color, '-3') }} swatchColors={defaultColors} hideInput />
            </ColorPickerContainer>
            <StyledText> Event </StyledText>
          </StyledFlex>
        )}
        <StyledFlex style={{ borderBottom: user.role === 'instructor' ? 'none' : '1px solid #DCDCDC', paddingBottom: user.role === 'instructor' ? 0 : 8 }}>
          <StyledCheckBox type="checkbox" checked={!blockTimeHidden} onChange={() => setOtherIds(!blockTimeHidden, [-2])} />
          <ColorPickerContainer>
            <StyledColorPicker value={get(currUserCalendars.filter((c) => c.id === '-2'), '0.bgColor', '#654321') || '#654321'} events={{ onChange: (e, d) => { onChangeUserCalendar('Block', d.color, '-2') } }} swatchColors={defaultColors} hideInput />
          </ColorPickerContainer>
          <StyledText> Block Time </StyledText>
        </StyledFlex>
        { user.role !== 'instructor' ? (
          <StyledFlex>
            <StyledCheckBox type="checkbox" checked={!isAllHiddens} onChange={() => setHiddens(!isAllHiddens)} />
            <StyledText style={{ marginLeft: '0px' }}> View All </StyledText>
          </StyledFlex>
        ) : null}
      </div>
      {items != null && (items.filter((item) => {
        const filtered = every((ignoredUserIds), (igId) => igId !== item.instructorId);
        return filtered;
      })
        .map((item) => {
          let id = null;
          if (user.role === 'parent') id = item.id;
          else if (user.role === 'admin' || user.role === 'superAdmin') id = item.instructorId;
          else if (user.role === 'instructor') id = item.childId;

          const isHidden = map((get(queryParams, 'id', {}) || {}), (ids) => Number(ids)).includes(Number(id));

          return (
            <div>
              <StyledFlex key={id}>
                <StyledCheckBox type="checkbox" checked={!isHidden} onChange={() => setItem(id, null, !isHidden)} />
                <ColorPickerContainer>
                  <StyledColorPicker value={item.calendarColor} events={{ onChange: (e, d) => setItem(id, d.color, null) }} swatchColors={defaultColors} hideInput />
                </ColorPickerContainer>
                <StyledText> {`${item.firstName} ${item.lastName}`}</StyledText>
              </StyledFlex>
            </div>
          )
        })
      )}
    </ColorWrapper>
  )

  const selectOptions = [{ label: 'Daily', value: 'daily' }, { label: 'Weekly', value: 'weekly' }];

  return (
    <Wrapper>
      {selectedSchedule ? (
        <PopUpViewEvents
          open
          user={user}
          schedule={selectedSchedule}
          allEvents={data.allEvents}
          child={data.children}
          instructor={data.allInstructor}
          requests={data.requests}
          allLessons={data.allLessons}
          calendars={map((get(queryParams, 'id', {}) || {}), (ids) => Number(ids))}
          prevPath={`${get(location, 'pathname', '')}${get(location, 'search', '')}`}
          view={get(queryParams, 'view', 'daily')}
          onClose={handleCloseModal}
        />
      ) : null}
      {showMobileCreate ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpWrapper>
              <PopUpButton className="close" onClick={() => { close(); setShowMobileCreate(false); setMobileCreateRadio(false); }}>
                &times;
              </PopUpButton>
              <PopUpTextWrapper>
                Create New
              </PopUpTextWrapper>
              <RadioGroupContainer>
                <Radio.Group value={mobileCreateRadio} onChange={(v) => setMobileCreateRadio(v.target.value)} options={user.role === 'parent' ? [{ label: 'Create Extended Leave', value: 'extendedleave' }] : [{ label: 'Create Lesson', value: 'lesson' }, { label: 'Create Event', value: 'event' }, { label: 'Create Block Time', value: 'blocktime' }]} />
              </RadioGroupContainer>
              <StyledLink to={mobileCreateRadio ? { pathname: `calendar/${mobileCreateRadio}`, state: { calendars: map((get(queryParams, 'id', {}) || {}), (ids) => Number(ids)), prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}`, view: get(queryParams, 'view', 'daily') } } : null} style={{ marginTop: 20 }}> <StyledButton2 style={{ width: '100%' }}> Next </StyledButton2> </StyledLink>
            </PopUpWrapper>
          )}
        </StyledPopup>
      ) : null}

      {showPolicy ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpPolicyWrapper>
              <Form
                name="PolicyForm"
                onSubmit={onPolicyConfirm}
                render={({ handleSubmit, submitting }) => (
                  <StyledForm onSubmit={handleSubmit}>
                    <div style={{ maxHeight: '100%', overflow: 'auto' }}>
                      <PolicyContainer>
                        <StyledLogo colored />
                        <PolicyHeader>M.int Policy Declaration</PolicyHeader>
                      </PolicyContainer>
                      <Flex direction="column" style={{ marginBottom: 20 }}>
                        <StyledText style={{ margin: 0, marginBottom: 10 }}>
                          I hereby declare that I understand, accept and agree to be bound by M.int Academy’s School Policies.
                        </StyledText>
                        <StyledText style={{ margin: 0, marginBottom: 10 }}>
                          M.int Academy reserves the right to amend any School Policy and all terms and conditions without prior notice. The latest version will be on <PolicyLink to={{ pathname: 'https://www.mintacademyhk.com' }} target="_blank" rel="noopener noreferrer"> www.mintacademyhk.com</PolicyLink>.
                        </StyledText>
                        <StyledText style={{ margin: 0, marginBottom: 10 }}>
                          本人同意遵守萬音天地之《學校政策》並受其約束。
                        </StyledText>
                        <StyledText style={{ margin: 0, marginBottom: 10 }}>
                          萬音天地保留更改任何學院政策及條款的權利，恕不另行通知。最新版本將上載於 <PolicyLink to={{ pathname: 'https://www.mintacademyhk.com' }} target="_blank" rel="noopener noreferrer">www.mintacademyhk.com</PolicyLink>。
                        </StyledText>
                      </Flex>
                      <PolicyCheckBoxContainer>
                        <StyledField
                          name="policyChecked"
                          type="checkbox"
                          label="I agree / 本人同意"
                          component={FieldComponent}
                          validate={validations.required}
                        />
                      </PolicyCheckBoxContainer>
                    </div>

                    <StyledButton2 type="submit" disabled={submitting} style={{ width: '100%' }}> Confirm </StyledButton2>
                  </StyledForm>
                )}
              />
            </PopUpPolicyWrapper>
          )}
        </StyledPopup>
      ) : null}

      <LeftWrapper topHeight={height} {...{ role: user.role }}>
        <LeftMenuWrapper>
          <LeftMenuContainerComp onClose={onClose} open={open}>
            <LeftCalendarMenuWrapper width={250}>
              {(user.role === 'parent') && (
                <EmailWrapper style={{ marginTop: 0 }}>
                  <StyledText style={{ marginRight: 5, marginTop: 0 }}>Receive class reminder on email? </StyledText>
                  <Switch checked={emailSwitch} onClick={() => { setEmailSwitch(!emailSwitch); onSubmitSwitch(!emailSwitch) }} />
                </EmailWrapper>
              )}
              { items && calendarFilters }
            </LeftCalendarMenuWrapper>
          </LeftMenuContainerComp>
        </LeftMenuWrapper>
        <MobileCalendarButton onClick={() => leftMenuOpen()}> <Icon icon="black-arrow-right" height={18} style={{ left: -2 }} /> </MobileCalendarButton>
        <LeftTopWrapper ref={ref} {...{ role: user.role }}>
          <TextWrapper>
            <SelectWrapper>
              <ReactSelect
                className="select"
                autoBlur
                components={{ MenuList }}
                filterOption={createFilter({ ignoreAccents: false })}
                options={selectOptions}
                onChange={(value) => {
                  setIsSameWeek(false);
                  setData(null);
                  calendarRef.current.getInstance().clear();
                  setQueryParams((old) => ({ ...old, view: value.value }), { replace: true });
                  setFormSchedules(null);
                }}
                closeMenuOnSelect
                isClearable={false}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                hideSelectedOptions={false}
                styles={customStyle({ invalid: false, disabled: false })}
                value={selectOptions.filter((option) => option.value === get(queryParams, 'view', 'daily'))[0]}
                defaultValue="daily"
                isDisabled={false}
                isSearchable={false}
              />
            </SelectWrapper>
            <StyledButton4 onClick={() => onDateSelect(moment())}> Today </StyledButton4>
            <StyledButton onClick={handleClickPrevButton}> <Icon icon="calleftarrow" height={9} /> </StyledButton>
            <StyledButton onClick={handleClickNextButton}> <Icon icon="calrightarrow" height={9} /> </StyledButton>
            <div style={{ fontWeight: 'bold', fontFamily: 'Avenir Roman', minWidth: 'max-content' }}>
              {get(queryParams, 'view', 'daily') === 'weekly' ? `${moment.tz(currentDate, 'Asia/Singapore').startOf('isoWeek').format('DD/MM/YYYY')} - ${moment.tz(currentDate, 'Asia/Singapore').endOf('isoWeek').format('DD/MM/YYYY')}` : moment(currentDate).format('DD/MM/YYYY')}
            </div>
            {user.role !== 'parent' ? (
              <StyledLink to="/lesson?currentPage=1&where%5BstartTime%5D=All&filter%5BclassStatus%5D%5Bequal%5D=Cancelled">
                <StyledButton3>
                  <Icon icon="calendar-delete" height={18} style={{ minWidth: 18, marginRight: 8 }} />
                  <div> Cancelled </div>
                </StyledButton3>
              </StyledLink>
            ) : null}
          </TextWrapper>
          <ButtonWrapper>
            {(user.role === 'admin' || user.role === 'superAdmin')
              ? <StyledLink to={{ pathname: 'calendar/lesson', state: { calendars: map((get(queryParams, 'id', {}) || {}), (ids) => Number(ids)), prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}`, view: get(queryParams, 'view', 'daily') } }} style={{ marginRight: 10 }}> <StyledButton2> Create Lesson </StyledButton2> </StyledLink>
              : null}
            {(user.role === 'admin' || user.role === 'superAdmin')
              ? <StyledLink to={{ pathname: 'calendar/event', state: { calendars: map((get(queryParams, 'id', {}) || {}), (ids) => Number(ids)), prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}`, view: get(queryParams, 'view', 'daily') } }} style={{ marginRight: 10 }}> <StyledButton2> Create Event </StyledButton2> </StyledLink>
              : null}
            {(user.role === 'admin' || user.role === 'superAdmin')
              ? <StyledLink to={{ pathname: 'calendar/blocktime', state: { calendars: map((get(queryParams, 'id', {}) || {}), (ids) => Number(ids)), prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}`, view: get(queryParams, 'view', 'daily') } }} style={{ marginRight: 10 }}> <StyledButton2> Block Time </StyledButton2> </StyledLink>
              : null}
            {(user.role === 'admin' || user.role === 'superAdmin' || user.role === 'instructor')
              ? (
                <ModelContainer>
                  <ModelPage
                    showHeader={false}
                    allowSearch={false}
                    showCreateButton={false}
                    showExportButton
                    query={{ user }}
                    exportDataRoute="/event/export"
                  />
                </ModelContainer>
              ) : null }
          </ButtonWrapper>
        </LeftTopWrapper>
        <MobileDateContainer>
          <MobileDateButton onClick={() => setShowMobileDatePicker(!showMobileDatePicker)}>
            <div style={{ fontSize: 18, fontWeight: 'bold', paddingTop: 3, marginRight: 5, color: '#000' }}> { moment(currentDate).format('MMMM D, YYYY')} </div>
            <Icon icon={showMobileDatePicker ? 'black-arrow-up' : 'black-arrow-down'} height={24} />
          </MobileDateButton>
          <MobileDatePicker active={showMobileDatePicker} className={showMobileDatePicker ? 'active' : 'inactive'}>
            <Container>
              <DatePicker selected={moment(currentDate)} onChange={(date) => onDateSelect(date)} inline />
            </Container>
          </MobileDatePicker>
        </MobileDateContainer>
        {tuicalendar}
        { (user.role === 'parent' || user.role === 'admin' || user.role === 'superAdmin') && (
          <MobileCalendarButton style={{ top: 'calc(100% - 100px)', left: 'calc(100% - 100px)', borderRadius: 50, width: 50, height: 50 }} onClick={() => setShowMobileCreate(true)}> <Icon icon="calendar-plus" height={19} /> </MobileCalendarButton>
        )}
      </LeftWrapper>
      <RightWrapper>
        <Container>
          <DatePicker selected={moment(currentDate)} onChange={(date) => onDateSelect(date)} inline />
        </Container>
        {(user.role === 'parent') && (
          <EmailWrapper>
            <StyledText style={{ marginRight: 5, marginTop: 0 }}>Receive class reminder on email? </StyledText>
            <Switch checked={emailSwitch} onClick={() => { setEmailSwitch(!emailSwitch); onSubmitSwitch(!emailSwitch) }} />
          </EmailWrapper>
        )}
        { items && calendarFilters }
      </RightWrapper>
    </Wrapper>
  );
}

const mapStateToProps = (state) => {
  const leftMenuState = state.leftCalendarMenu;
  return {
    open: leftMenuState.open,
  };
};

const mapDispatchToProps = () => ({
  onClose: () => leftMenuAction.close(),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Calendar));
