import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';
import { font, palette, size } from 'styled-theme';

import Button from '../../atoms/Button';

const StyledDropzone = styled.div`
  font-size: 14px;
  width: 100%;
  height: 10rem;
  font-family: ${font('primary')};
  color: ${ifProp('invalid', palette('red', 3), palette('primary', 0))};
  border: 0.0625rem dashed ${ifProp('invalid', palette('red', 3), palette('primary', 0))};
  border-radius: 4px;
  margin-bottom: 1.25em;
  background: #f4f6f8;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: ${size('fontWeight.bold')};
  line-height: ${size('lineHeight.body.b3')};
  cursor: ${ifProp('disabled', 'no-drop', 'pointer')};
`;

const StyledButton = styled(Button)`
  margin: 13px 0;
`;

const FileDropzone = ({ instruction, disabled, onDrop, accept, multiple, ...props }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept, multiple });
  const rootProps = getRootProps();
  const inputProps = getInputProps();

  return (
    <StyledDropzone {...rootProps} disabled={disabled}>
      <input {...inputProps} disabled={disabled} />
      <div>{instruction}</div>
      <div>or</div>
      <StyledButton disabled={disabled}>Select File</StyledButton>
    </StyledDropzone>
  );
};


FileDropzone.propTypes = {
  onDrop: PropTypes.func,
  accept: PropTypes.string,
  instruction: PropTypes.string,
  invalid: PropTypes.bool,
};

FileDropzone.defaultProps = {
  instruction: 'Try dropping some files here, or click to select files to upload.',
  invalid: false,
};

export default FileDropzone;
