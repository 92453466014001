import styled from 'styled-components';
import { Tabs, Tab } from 'react-tabs';
import { ifProp } from 'styled-tools';
import { palette } from 'styled-theme';

export const StyledTabs = styled(Tabs)`
  > .react-tabs__tab-list{
    display: flex;
    margin-bottom: 0;
    background-color: ${palette('primary', 0)};
    padding: 0px 24px 0px;
  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }
`;

export const StyledTab = styled(Tab)`
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
  padding: 4px 12px 0px;
  font-size: 16px;
  margin: 0px 1px;
  border-bottom: 4px solid ${ifProp('selected', '#FF9997', 'transparent')};
  background-color: transparent;
  color: ${palette('white', 0)};
  font-weight: ${ifProp('selected', 'bold', 'normal')};
  p {
    color: ${palette('white', 0)};
    font-weight: ${ifProp('selected', 'bold', 'normal')};
    margin: 8px 0;
  }
`;

export const StyledContent = styled.div`
  border-radius: 0 4px 4px 4px;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
`;
