import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Link from '../../atoms/Link';
import useUserForm from '../../../hooks/useUserForm';
import Flex from '../../atoms/Flex';
import FieldComponent from '../../molecules/FieldComponent';
import { validations } from '../../../utils/form';
import inputOptions from '../../../utils/form/inputOptions';
import ErrorAlert from '../../molecules/ErrorAlert';

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 45vw;
  min-width: 45vw;
  padding: 20px;
  max-height: 98vh;

  > form {
    height: calc(100% - 87px);
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledButton2 = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 5px;
  }
`;

const StyledField = styled(Field)`
  border-radius: ${size('borderRadius.small')};
  margin: 6px 0;
`;

const FieldFlex = styled(Flex)`
  align-items: center;
  @media (max-width: ${size('mobileBreakpoint')}){
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  padding: 17px 15px 22px 0px;
  width: 160px;
  text-align: right;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}){
    text-align: left;
    padding: 0px;
    width: auto;
  }
`;

const StyledButton = styled(Button)`
  min-width: 180px;
  margin: ${size('margin.default')} 0px ${size('margin.default')};
  border-radius: 0px;
  width: calc(100% - 20px);
`;

const CreditValueWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const CreditValueText = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
`;

const CreditValueField = styled(StyledField)`
  input {
    padding-right: 40px;
  }
`;

const TextNoteText = styled(StyledText)`
  padding-top: 12px;

  @media (max-width: ${size('mobileBreakpoint')}){
    padding-top: 0px;
  }
`;

const DescriptionStyledText = styled(TextNoteText)`
  display: block;
  @media (max-width: ${size('mobileBreakpoint')}){
    display: none;
  }
`;

const MobileDescriptionStyledText = styled(TextNoteText)`
  display: none;
  @media (max-width: ${size('mobileBreakpoint')}){
    display: block;
  }
`;

const ErrorAlertContainer = styled.div`
  > div {
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: 75vw;
      width: fit-content;
    }
  }
`;


const PopUpInventory = ({ open, user, selectedItem, type, sections, onClose }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const { onSubmit } = useUserForm({
    endpoint: type === 'create' ? 'inventoryItems/addItem' : `inventoryItems/editItem/${selectedItem.id}`,
    setErrorMessage,
    type: 'inventoryItem',
    action: type === 'create' ? 'create' : 'updateInventoryItem',
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  const initialValues = type === 'edit' ? {
    itemId: `INV-IT${selectedItem.id}`,
    section: selectedItem.inventory ? selectedItem.inventory.section : null,
    purchasedDate: selectedItem.purchasedDate,
    purchasedFrom: selectedItem.purchasedFrom,
    purchasedPrice: selectedItem.purchasedPrice,
    sellingPrice: selectedItem.sellingPrice,
    note: selectedItem.note,
    description: selectedItem.description,
  } : {
    section: selectedItem.section,
    quantity: 1,
    arrivalStatus: 'Arrived',
  };

  const isRental = selectedItem.section === 'Instruments for Rental' || (selectedItem.inventory && selectedItem.inventory.section === 'Instruments for Rental');

  return (
    <StyledPopup
      open={open}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          <PopUpButton className="close" onClick={() => { close(); onClose(); }}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper> {type === 'create' ? 'Add New Item' : 'Edit'} </PopUpTextWrapper>
          <Form
            name="InventoryItemForm"
            onSubmit={onSubmit}
            initialValues={initialValues}
            initialValuesEqual={() => true}
            render={({ handleSubmit, submitting, values }) => {
              if (type === 'create') values.inventoryId = selectedItem.inventoryId;
              else if (type === 'edit') values.inventory = selectedItem.inventory;

              return (
                <StyledForm onSubmit={handleSubmit}>
                  <div style={{ paddingRight: '20px', maxHeight: '100%', overflow: 'auto' }}>
                    <ErrorAlertContainer>
                      <ErrorAlert
                        errorMessage={errorMessage}
                        show={errorMessage !== null}
                      />
                    </ErrorAlertContainer>

                    {type === 'edit' ? (
                      <FieldFlex>
                        <StyledText>Item ID: </StyledText>
                        <StyledField
                          name="itemId"
                          type="text"
                          disabled
                          component={FieldComponent}
                        />
                      </FieldFlex>
                    ) : null}

                    <FieldFlex>
                      <StyledText>Section: </StyledText>
                      <StyledField
                        name="section"
                        type="select"
                        component={FieldComponent}
                        options={sections}
                        validate={validations.required}
                        disabled={type === 'create'}
                        menuPortalTarget={document.getElementById('popup-root')}
                        menuPlacement="auto"
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Purchased Date: </StyledText>
                      <StyledField
                        name="purchasedDate"
                        type="date"
                        component={FieldComponent}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Purchased From: </StyledText>
                      <StyledField
                        name="purchasedFrom"
                        type="text"
                        component={FieldComponent}
                      />
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>Purchased Price: </StyledText>
                      <CreditValueWrapper>
                        <CreditValueField
                          name="purchasedPrice"
                          type="number"
                          min={0}
                          step="any"
                          component={FieldComponent}
                        />
                        <CreditValueText> HKD </CreditValueText>
                      </CreditValueWrapper>
                    </FieldFlex>

                    <FieldFlex>
                      <StyledText>{isRental ? 'Rental Price (Monthly):' : 'Selling Price:'} </StyledText>
                      <CreditValueWrapper>
                        <CreditValueField
                          name="sellingPrice"
                          type="number"
                          min={0}
                          step="any"
                          component={FieldComponent}
                          validate={validations.required}
                        />
                        <CreditValueText> HKD </CreditValueText>
                      </CreditValueWrapper>
                    </FieldFlex>

                    {(type === 'create' && !isRental) ? (
                      <FieldFlex>
                        <StyledText>Status: </StyledText>
                        <StyledField
                          name="arrivalStatus"
                          type="select"
                          component={FieldComponent}
                          options={inputOptions.inventoryArrivalStatusOptions}
                          validate={validations.required}
                          menuPortalTarget={document.getElementById('popup-root')}
                          menuPlacement="auto"
                        />
                      </FieldFlex>
                    ) : null}

                    {type === 'create' ? (
                      <FieldFlex>
                        <StyledText>Quantity: </StyledText>
                        <StyledField
                          name="quantity"
                          type="number"
                          component={FieldComponent}
                          min={1}
                          step="1"
                          validate={validations.required}
                        />
                      </FieldFlex>
                    ) : null}

                    <FieldFlex style={{ alignItems: 'normal' }}>
                      <DescriptionStyledText> Description <div> (if any): </div> </DescriptionStyledText>
                      <MobileDescriptionStyledText> Description (if any): </MobileDescriptionStyledText>
                      <StyledField
                        name="description"
                        type="textarea"
                        component={FieldComponent}
                      />
                    </FieldFlex>

                    <FieldFlex style={{ alignItems: 'normal' }}>
                      <TextNoteText> Note (if any): </TextNoteText>
                      <StyledField
                        name="note"
                        type="textarea"
                        component={FieldComponent}
                      />
                    </FieldFlex>
                  </div>

                  <StyledButton type="submit" disabled={submitting}> Save </StyledButton>
                </StyledForm>
              )
            }}
          />
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpInventory.propTypes = {
  user: PropTypes.object.isRequired,
};

export default PopUpInventory;
