import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';

export default (options = {}) => {
  const { endpoint, user, id, type, setErrorMessage, setCompleteMessage, action } = options;
  const [formDisabled, setFormDisabled] = useState(true);

  const history = useHistory();

  const { api } = global;
  let onSubmit;

  if (user.role === 'parent') {
    onSubmit = async (values) => {
      values.eventId = id;
      values.type = type;
      return api
        .post(`/${endpoint}`, values)
        .then(() => setCompleteMessage(true))
        .then(() => new Promise((resolve) => setTimeout(resolve, 3000)))
        .then(() => { window.location.reload(false) })
        .catch((error) => setErrorMessage(error.message));
    };
  } else if (user.role === 'admin' || user.role === 'superAdmin') {
    if (action === 'create') {
      onSubmit = async (values) => {
        return api
          .post(`/${endpoint}`, values)
          .then(() => setCompleteMessage(true))
          .then(() => new Promise((resolve) => setTimeout(resolve, 3000)))
          .then(() => { history.push('/purchase-request') })
          .catch((error) => setErrorMessage(error.message));
      };
    } else if (action === 'edit') {
      onSubmit = async (values) => {
        return api
          .put(`/${endpoint}`, values)
          .then(() => { history.goBack() })
          .catch((error) => setErrorMessage(error.message));
      };
    } else {
      onSubmit = async () => {
        return api
          .delete(`/${endpoint}/${id}`)
          .then(() => { window.location.reload(false) })
          .catch((error) => setErrorMessage(error.message));
      };
    }
  } else if (user.role === 'instructor') {
    if (action === 'create') {
      onSubmit = async (values) => {
        return api
          .post(`/${endpoint}`, values)
          .then(() => setCompleteMessage(true))
          .then(() => new Promise((resolve) => setTimeout(resolve, 3000)))
          .then(() => { history.push('/purchase-request') })
          .catch((error) => setErrorMessage(error.message));
      };
    } else if (action === 'edit') {
      onSubmit = async (values) => {
        return api
          .put(`/${endpoint}`, values)
          .then(() => { history.goBack() })
          .catch((error) => setErrorMessage(error.message));
      };
    }
  }

  return {
    onSubmit,
    formDisabled,
  };
};
