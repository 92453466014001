import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { palette, font } from 'styled-theme';
import { ifProp, switchProp, prop } from 'styled-tools';
import Spinner from '../Spinner';

const backgroundColor = ({ transparent, disabled, primaryColor }) => {
  if (transparent) return palette('white', 0);
  if (disabled) return palette('grayscale', 4);
  if (primaryColor) return palette('primary', 0);
  return palette(0);
};

const foregroundColor = ({ transparent, disabled }) => {
  if (transparent) {
    return disabled ? palette('grayscale', 3) : palette(3);
  }
  return palette('blue', 0, true);
};

const hoverBackgroundColor = ({ disabled, transparent }) =>
  !disabled && (transparent ? palette('white', 1) : palette(0));
const hoverForegroundColor = ({ disabled, transparent }) => !disabled && transparent && palette(0);

const styles = css`
  display: block;
  font-family: ${font('secondary')};
  font-size: ${switchProp(prop('size'), {
    small: '12px', /* 0.75rem */
    medium: '14px', /* 14px */
    large: '16px', /* 1rem */
  })};
  font-weight: ${switchProp(prop('size'), {
    small: '400',
    medium: '500',
    large: '700',
  })};
  line-height: ${switchProp(prop('size'), {
    small: '22px', /* 1.375rem */
    medium: '30px', /* 1.875rem */
    large: '46px', /* 2.875rem */
  })};
  height: ${switchProp(prop('size'), {
    small: '28px', /* 1.75rem */
    medium: '36px', /* 2.25rem */
    large: '52px', /* 3.25rem */
  })};
  min-width: 40px; /* 2.5rem */
  border: 1px solid ${ifProp('transparent', 'currentcolor', 'transparent')};
  ${ifProp('borderColor', css`
    border-color: ${({ borderColor }) => borderColor };
  `)}
  padding: 2px 24px; /* 0.125rem 1.5rem */
  margin-bottom: 16px; /* 1rem */
  border-radius: 16px;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  text-decoration: none;
  cursor: ${ifProp('disabled', 'no-drop', 'pointer')};
  appearance: none;
  box-sizing: border-box;
  pointer-events: ${ifProp('disabled', 'none', 'auto')};
  transition: all 0.15s ease;
  background-color: ${backgroundColor};
  color: ${foregroundColor};
  /* font-weight: bold; */

  &:hover,
  &:focus,
  &:active {
    background-color: ${hoverBackgroundColor};
    color: ${hoverForegroundColor};
  }

  &:focus {
    outline: none;
  }
`;

const StyledLink = styled(
  // eslint-disable-next-line no-shadow
  ({ disabled, transparent, reverse, palette, theme, ...props }) => <Link {...props} />,
)`
  ${styles};

`;
const Anchor = styled.a`
  ${styles};

`;
const StyledButton = styled.button`
  ${styles};
`;

const Button = ({ type, isAsync, onClick, loaderStroke, children, ...props }) => {
  const [loading, setLoading] = useState(false);

  if (props.to) {
    return <StyledLink {...props}>{children}</StyledLink>;
  } else if (props.href) {
    return <Anchor {...props}>{children}</Anchor>;
  }
  const asyncOnClick = (...args) => {
    setLoading(true);
    if (loading) return;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 100);
    })
      .then(() => Promise.resolve(onClick(...args)))
      .then((result) => {
        setTimeout(() => {
          setLoading(false);
        }, 100);
        return result;
      })
      .catch((e) => {
        setLoading(false);
        throw e;
      });
  };

  const parsedOnClick = !isAsync ? onClick : asyncOnClick;

  return (
    <StyledButton
      {...props}
      onClick={parsedOnClick}
      type={type}
    >
      {loading ?
        <Spinner {...(loaderStroke ? { stroke: loaderStroke } : {})}/>
      : children}
    </StyledButton>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  palette: PropTypes.string,
  transparent: PropTypes.bool,
  reverse: PropTypes.bool,
  type: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Button.defaultProps = {
  palette: 'primary',
  type: 'button',
  size: 'medium',
};

export default Button;
