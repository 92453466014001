import React from 'react';
import styled from 'styled-components';
import { size, palette } from 'styled-theme';
import { connect } from 'react-redux';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';


import P from '../../../components/atoms/P';
import Divider from '../../../components/atoms/Divider';
import Calendar from '../../../components/organisms/Calendar';

const Wrapper = styled.div`
  padding-top: 25px;
  height: 100%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-top: 10px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const CalendarView = ({ authenticated, user }) => {
  return (
    <Wrapper>
      <Calendar user={user} />
    </Wrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarView);
