import React, { useState } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import moment from 'moment';
import { connect } from 'react-redux';
import { ConfigProvider, Empty } from 'antd';
import Popup from 'reactjs-popup';
import P from '../../../components/atoms/P';
import ModelPage from '../../../containers/ModelPage';
import ModelTable from '../../../components/organisms/ModelTable';
import AntDTable from '../../../components/organisms/AntDTable';
import useAdminDashboardData from '../../../hooks/useAdminDashboardData';
import Loader from '../../../components/atoms/Loading';
import OverView from '../../../components/organisms/OverView';
import lessonToday from '../../../asset/image/lessontoday.png';
import cancelToday from '../../../asset/image/canceltoday.png';
import Card from '../../../components/atoms/Card';
import Flex from '../../../components/atoms/Flex';
import Icon from '../../../components/atoms/Icon';
import PopUpDashboardRequest from '../../../components/organisms/PopUpDashboardRequest';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import SearchBar from '../../../components/molecules/SearchBar';
import Button from '../../../components/atoms/Button';
import Link from '../../../components/atoms/Link';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 100%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  height: calc(100% - 25px);
  max-height: calc(100% - 25px);
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

const RequestsWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 25px 25px;
  padding: 20px 10px 0px 10px;
  border-radius: 0px;
  box-shadow: none;
  height: calc(50% - 15px);
`;

const BlockTimeWrapper = styled(Card)`
  height: calc(50% - 10px);
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 25px;
  padding: 20px 10px 0px 10px;
  border-radius: 0px;
  box-shadow: none;

  > div {
    display: flex;
    flex-direction: column;
    padding: 0px !important;
    max-height: calc(100% - 30px);

    > div:first-child {
      margin-top: 0px;
      padding: 0px;
      > div {
        display: none;
      }
      > form {
        margin-left: 0;
        width: 100%;
        > div {
          > div: first-child {
            width: 100%;
          }
        }
      }
    }
  }
`;

const ListWrapper = styled.div`
  overflow-y: auto;
`;

const BlockListWrapper = styled.div`
  overflow-y: auto;
`;

const StyledFlex = styled(Flex)`
  padding: 15px 0px;
  border-bottom: 1px solid #E8E8E8;
  align-items: center;
`;

const StyledFlex2 = styled(Flex)`
  justify-content: space-between;
  margin-left: 10px;
  width: 100%;
`;

const StyledText = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: ${font('tertiary')};
`;

const StyledText2 = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  cursor: pointer;
  font-family: ${font('tertiary')};
`;

const StyledText3 = styled.div`
  font-weight: bold;
  font-family: ${font('tertiary')};
  margin-bottom: 5px;
`;

const StyledText4 = styled.div`
  font-size: 12px;
  color: #757575;
  width: 65px;
  text-align: left;
`;

const BlockTimeFlex = styled(Flex)`
  padding: 15px 0px;
  border-bottom: 1px solid #E8E8E8;
  flex-direction: column;
`;

const ModelContainer = styled.div`
  max-height: calc(100% - 153px);
  height: calc(100% - 153px);
  > div {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 20px !important;
    > div: first-child {
      display: none;
    }
    > div: last-child {
      height: 100%;
      width: 100%;
      > div: first-child {
        font-family: ${font('tertiary')};
      }
      > div: last-child {
        height: calc(100% - 40px);
        > div {
          height: 100%;
          > div {
            height: 100%;
            > div {
              height: 100%;
              > div {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 90vw;
  min-width: 90vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('primary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 20px;
  font-family: ${font('tertiary')};
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const PopUpTrigger = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }
`;

const StyledSpan = styled.span`
  display: inline-block;
`;

const Cell = styled.div`
  width: max-content;
`;

const modelName = 'event/dashboardTable';

const itemsPerPage = 0;

const ListView = (props) => {
  const [selectedRequest, setSelectedRequest] = useState(null);

  const { user } = props;

  const {
    loading, error, data, fetchDashboardData, setLoading, setError, setData, onSearch, searchBy,
  } = useAdminDashboardData({ query: {} });

  if (loading) return <Loader />;

  const handleCloseRequest = () => {
    setSelectedRequest(null);
  };

  const cellRenderers = [
    {
      title: 'Class',
      dataIndex: 'lessonInfo',
      key: 'lesson',
      width: 80,
      render: (lesson) => <Cell><Link to={`/lesson/show/${lesson.id}`}>{lesson.title}</Link></Cell>,
    },
    {
      title: 'Type',
      dataIndex: 'lessonType',
      key: 'type',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Student',
      dataIndex: 'studentNames',
      key: 'student',
      width: 90,
      render: (studentNames) => <Cell style={{ maxWidth: 250 }}>{studentNames.map((s, index) => <span>{(index ? ', ' : '')}<Link to={`/student/show/${s.id}`}><StyledSpan>{s.name}</StyledSpan></Link></span>)}</Cell>,
    },
    {
      title: 'Instructor',
      dataIndex: 'instructor',
      key: 'instructor',
      width: 100,
      render: (instructor) => <Cell>{(user.role === 'superAdmin' ? <Link to={`/staff/show/${instructor.id}`}>{instructor.name}</Link> : instructor.name)}</Cell>,
    },
  ];

  const cancelledColumns = [
    {
      title: 'Class',
      dataIndex: 'lessonInfo',
      key: 'lessonInfo',
      width: 90,
      render: (lesson) => <Cell><Link to={`lesson/show/${lesson.id}`}>{lesson.title}</Link></Cell>,
    },
    {
      title: 'Type',
      dataIndex: 'lessonType',
      key: 'lessonType',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Date',
      dataIndex: 'formattedDate',
      key: 'formattedDate',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Time',
      dataIndex: 'formattedTime',
      key: 'formattedTime',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Instructor',
      dataIndex: 'instructor',
      key: 'instructor',
      render: (content) => <Cell>{content}</Cell>,
      width: 100,
    },
    {
      title: 'Late Cancellation Fee',
      dataIndex: 'lateCancellationFee',
      key: 'lateCancellationFee',
      render: (content) => <Cell>{content}</Cell>,
      width: 180,
    },
  ];

  return (
    <MainWrapper>
      <Wrapper>
        {selectedRequest ? (
          <PopUpDashboardRequest
            open
            user={user}
            request={selectedRequest}
            students={data.students}
            instructors={data.allInstructor}
            allLessons={data.allLessons}
            onClose={handleCloseRequest}
          />
        ) : null}

        <LeftWrapper>
          <OverView
            style={{ marginBottom: 25, justifyContent: 'space-between', height: 128 }}
            data={[
              {
                image: lessonToday,
                imageWidth: 70,
                label: 'Today\'s Scheduled Classes',
                value: data.lessonsToday,
              },
              {
                image: cancelToday,
                imageWidth: 70,
                label: 'Today\'s Cancellation',
                value: data.cancelToday,
              },
            ]}
          />
          <ModelContainer>
            <ModelPage
              showHeader={false}
              modelName={modelName}
              allowSearch={false}
              itemsPerPage={itemsPerPage}
              {...props}
              render={(renderProps) => (
                <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Classes" />}>
                  <AntDTable
                    modelName={modelName}
                    cellRenderers={cellRenderers}
                    showTitle
                    title="Today's Classes"
                    scroll={{ x: 'max-content', y: 'calc(100% - 55px)' }}
                    rowKey="id"
                    {...renderProps}
                    loading={false}
                  />
                </ConfigProvider>
              )}
            />
          </ModelContainer>
        </LeftWrapper>
        <RightWrapper>
          <RequestsWrapper>
            <StyledText> Pending Requests ({data.requestCount})</StyledText>
            <ListWrapper>
              { data.requests.length !== 0 ? (
                data.requests.map((request) => {
                  const { id } = request;
                  let requestIcon = null;
                  let text = null;
                  if (request.type === 'cancel') {
                    requestIcon = 'cancel-request';
                    text = 'Class Cancellation Notification';
                  } else if (request.type === 'reschedule') {
                    requestIcon = 'reschedule-request';
                    text = 'Class Reschedule Request';
                  } else if (request.type === 'purchase') {
                    requestIcon = 'purchase-request';
                    text = 'Purchase Request';
                  }

                  const from = request.type === 'purchase' ? `${request.user.firstName} ${request.user.lastName}` : `${request.child.firstName} ${request.child.lastName}`;
                  const timeFrom = moment(request.createdAt).fromNow();

                  return (
                    <StyledFlex key={id}>
                      <Icon icon={requestIcon} height={40} style={{ minWidth: '40px' }} />
                      <StyledFlex2>
                        <Flex direction="column" style={{ marginRight: '5px' }}>
                          <StyledText2 onClick={() => setSelectedRequest(request)}> {text} </StyledText2>
                          <StyledText3 style={{ fontWeight: 'none', fontFamily: 'Avenir Book' }}> From {from} </StyledText3>
                        </Flex>
                        <StyledText4> {timeFrom} </StyledText4>
                      </StyledFlex2>
                    </StyledFlex>
                  )
                })
              ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Requests" style={{ padding: '70px 0px' }} />}
            </ListWrapper>
          </RequestsWrapper>
          <BlockTimeWrapper>
            <StyledText style={{ height: 20 }}> Extended Leave Overview </StyledText>
            <ModelPage
              showHeader={false}
              allowSearch
              modelName="event/extendedLeave"
              showCreateButton={false}
              {...props}
              render={(renderProps) => (
                <BlockListWrapper>
                  { renderProps.data.length !== 0 ? (
                    renderProps.data.map((block) => {
                      const { id } = block;
                      const { reason } = JSON.parse(block.note);

                      return (
                        <BlockTimeFlex key={id}>
                          <StyledText3> {block.fullName} </StyledText3>
                          <div style={{ marginBottom: 5 }}> {`Blocked time from ${block.startDate} to ${block.endDate}`} </div>
                          <div style={{ marginBottom: 5 }}> {`Reason: ${reason}`} </div>
                          <StyledPopup
                            trigger={<div><PopUpTrigger> {block.cancelledEvents.length} attendance cancelled </PopUpTrigger></div>}
                            modal
                            closeOnDocumentClick={false}
                            closeOnEscape={false}
                          >
                            {(close) => (
                              <PopUpWrapper>
                                <PopUpButton className="close" onClick={close}>
                                  &times;
                                </PopUpButton>
                                <PopUpTextWrapper>
                                  Cancelled Attendance
                                </PopUpTextWrapper>
                                <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Classes" />}>
                                  <AntDTable
                                    cellRenderers={cancelledColumns}
                                    data={block.cancelledEvents}
                                    rowKey="id"
                                    scroll={{ x: block.cancelledEvents.length > 0 ? 'max-content' : '100%', y: '70vh' }}
                                  />
                                </ConfigProvider>
                              </PopUpWrapper>
                            )}
                          </StyledPopup>
                        </BlockTimeFlex>
                      )
                    })
                  ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Blocks" style={{ padding: '70px 0px' }} />}
                </BlockListWrapper>
              )}
            />
          </BlockTimeWrapper>
        </RightWrapper>
      </Wrapper>
    </MainWrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
