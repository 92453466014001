import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { withState, compose } from 'recompose';
import { size, palette } from 'styled-theme';
import Input from '../../atoms/Input';

import Icon from '../../atoms/Icon';

const borderColor = ({ disabled, invalid }) => {
  if (disabled) return palette('grayscale', 4);
  if (invalid) return palette('red', 3);
  return palette('grayscale', 3);
};

const InputWithIcon = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)`
  border: 1px solid ${borderColor};
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 40px;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 6px;
  right: 12px;
  margin-right: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;

  svg {
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    path {
      color: #fff;
    }
  }
`;

const PasswordField = ({
  placeholder,
  inputType,
  setType,
  ...props
}) => {
  return (
    <InputWithIcon>
      <StyledInput
        placeholder={placeholder}
        {...props}
        type={inputType}
      />
      <StyledIcon
        icon={inputType === 'password' ? 'hide-eye' : 'preview-eye'}
        onClick={() => (inputType === 'password' ? setType('text') : setType('password'))}
      />
    </InputWithIcon>
  );
};

PasswordField.propTypes = {
  placeholder: propTypes.string,
  inputType: propTypes.string,
  setType: propTypes.func,
};

export default compose(
  withState('inputType', 'setType', 'password'),
)(PasswordField);
