import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import { ConfigProvider, Empty, Space } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import get from 'lodash/get';
import P from '../../../components/atoms/P';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Flex from '../../../components/atoms/Flex';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import Button from '../../../components/atoms/Button';
import inputOptions from '../../../utils/form/inputOptions';
import PopUpInvoice from '../../../components/organisms/PopUpInvoice';
import useGeneralData from '../../../hooks/useGeneralData';
import PopUpEditInvoice from '../../../components/organisms/PopUpEditInvoice';
import PopUpDelete from '../../../components/organisms/PopUpDelete';

const Wrapper = styled.div`
  padding-bottom: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
    height: 100%;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const StyledSpan = styled.span`
  display: inline-block;
`;

const ModelContainer = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
  > div {
    @media (max-width: ${size('mobileBreakpoint')}) {
      height: 100%;
    }
    > div: first-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        max-height: ${(props) => (props.divHeight ? 'none' : '56px')}
      }
    }
    > div: last-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        height: calc(100% - 56px);
        height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')}
      }
      > div {
        @media (max-width: ${size('mobileBreakpoint')}) {
          height: 100%;
        }
        > div {
          @media (max-width: ${size('mobileBreakpoint')}) {
            height: 100%;
          }
          > div: last-child {
            @media (max-width: ${size('mobileBreakpoint')}) {
              height: 100%;
            }
            > div {
              @media (max-width: ${size('mobileBreakpoint')}) {
                height: calc(100% - 40px);
              }
              > div {
                @media (max-width: ${size('mobileBreakpoint')}) {
                  height: 100%;
                }
                > div: last-child {
                  max-height: 100% !important;
                  @media (max-width: ${size('mobileBreakpoint')}) {
                    max-height: calc(100% - 55px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const modelName = 'package';

const itemsPerPage = 25;

const ListView = (props) => {
  const { user, location } = props;
  const [divHeight, setDivHeight] = useState(0);

  const { data, loading } = useGeneralData({
    endpoint: 'user/getBusinessProfile',
  });

  const cellRenderers = [
    {
      title: 'Package Code',
      dataIndex: 'packageInfo',
      key: 'packageInfo',
      width: 130,
      render: (packageInfo) => <Link to={{ pathname: `/package/show/${packageInfo.id}`, state: { prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` } }}>{`${packageInfo.prefix}${packageInfo.id}`}</Link>,
    },
    {
      title: 'Package Type',
      dataIndex: 'type',
      key: 'type',
      render: (content) => <Cell>{content}</Cell>,
      width: 130,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 110,
      render: (description) => <Cell style={{ maxWidth: 250 }}> {description || 'N/A'} </Cell>,
    },
    {
      title: 'Value Type',
      dataIndex: 'valueType',
      key: 'valueType',
      render: (content) => <Cell>{content}</Cell>,
      width: 110,
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (content) => <Cell>{content}</Cell>,
      width: 100,
    },
    {
      title: 'Total Classes',
      dataIndex: 'totalClasses',
      key: 'totalClasses',
      render: (content) => <Cell>{content}</Cell>,
      width: 130,
    },
    {
      title: 'Student(s)',
      dataIndex: 'studentNames',
      key: 'studentNames',
      width: 100,
      render: (studentNames) => <Cell style={{ maxWidth: 250 }}>{studentNames.map((s, index) => <span>{(index ? ', ' : '')}{(user.role === 'parent' && (Number(s.parentId) !== Number(user.id))) ? s.name : <Link to={`/student/show/${s.id}`}><StyledSpan>{s.name}</StyledSpan></Link>}</span>)}</Cell>,
    },
    {
      title: 'Sale To',
      dataIndex: 'parent',
      key: 'parent',
      render: (parent) => <Cell><Link to={`/user/show/${parent.id}`}>{`${parent.name}`}</Link></Cell>,
      width: 90,
    },
    {
      title: 'Total Package Value',
      dataIndex: 'totalPackageValue',
      key: 'totalPackageValue',
      render: (content) => <Cell>{content}</Cell>,
      width: 170,
    },
    {
      title: 'Remaining Amount',
      dataIndex: 'remainingAmount',
      key: 'remainingAmount',
      render: (content) => <Cell>{content}</Cell>,
      width: 160,
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      render: (date) => <Cell>{(date ? moment(date).format('DD/MM/YYYY') : 'N/A')}</Cell>,
      width: 120,
    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      render: (date) => <Cell>{(date ? moment(date).format('DD/MM/YYYY') : 'N/A')}</Cell>,
      width: 130,
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (method) => <Cell>{(method || 'N/A')}</Cell>,
      width: 150,
    },
    {
      title: 'Activation Date',
      dataIndex: 'activationDate',
      key: 'activationDate',
      render: (date) => <Cell>{(date ? moment(date).format('DD/MM/YYYY') : 'N/A')}</Cell>,
      width: 150,
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (date) => <Cell>{(date ? moment(date).format('DD/MM/YYYY') : 'N/A')}</Cell>,
      width: 140,
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice',
      key: 'invoice',
      render: (inv, record) => <Cell>{(inv.id ? <PopUpInvoice user={user} type="package" selectedRecord={record} businessProfile={data} /> : 'N/A')}</Cell>,
      width: 100,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        return (
          <Cell>
            <Flex>
              <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                <PopUpEditInvoice user={user} record={record} type="package" />
              </div>
              <div style={{ paddingLeft: 5 }}>
                <PopUpDelete user={user} disabled={(user.role !== 'superAdmin') ? true : false} canDelete={(parseInt(record.totalEvents[0].count) != 0 || record.sale.id != null) ? true : false} endpoint="package" id={record.id} type="Package" />
              </div>
            </Flex>
          </Cell>
        )
      },
      width: 90,
    },
  ];

  const expandableColumns = [
    {
      title: 'Free Trial',
      dataIndex: 'formattedFreeTrial',
      key: 'formattedFreeTrial',
    },
    {
      title: 'Discount',
      dataIndex: 'offsetDiscountText',
      key: 'offsetDiscountText',
    },
    {
      title: 'Commission to Instructor',
      dataIndex: 'commissionText',
      key: 'commissionText',
    },
    {
      title: 'Credit Card Charge',
      dataIndex: 'creditCardChargeText',
      key: 'creditCardChargeText',
    },
    {
      title: 'Discount %',
      dataIndex: 'discountPercent',
      key: 'discountPercent',
      render: (discount) => `${discount}%`,
    },
    {
      title: 'Net Amount',
      dataIndex: 'netAmount',
      key: 'netAmount',
    },
    {
      title: 'Activation Date',
      dataIndex: 'activationDate',
      key: 'activationDate',
      render: (date) => (date ? moment(date).format('DD/MM/YYYY') : 'N/A'),
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (date) => (date ? moment(date).format('DD/MM/YYYY') : 'N/A'),
    },
    {
      title: 'Created By',
      dataIndex: 'creatorName',
      key: 'creatorName',
      render: (creator) => (user.role === 'superAdmin' ? <Link to={`/staff/show/${creator.id}`}>{creator.name}</Link> : creator.name),
    },
  ];

  const filterConfig = [
    {
      type: 'radioGroup',
      name: 'type',
      label: 'Package Type',
      options: [{ label: 'All', value: 'All' }, ...inputOptions.packageTypeOptions],
    },
    {
      type: 'radioGroup',
      name: 'valueType',
      label: 'Value Type',
      options: [{ label: 'All', value: 'All' }, ...inputOptions.valueTypeOptions],
    },
    {
      type: 'spec',
      name: 'parentId',
      label: 'Sale To',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: { endpoint: 'user', query: { where: { role: 'parent', status: 'Current' }, order: { sortKey: ['firstName', 'lastName'], sortOrder: 'asc' } } },
      associationType: 'own',
    },
    {
      type: 'spec',
      name: 'children.id',
      label: 'Student',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: { endpoint: 'student', query: (user.role === 'parent') ? { where: { parentId: user.id }, order: { sortKey: ['child.firstName', 'child.lastName'], sortOrder: 'asc' } } : { order: { sortKey: ['child.firstName', 'child.lastName'], sortOrder: 'asc' } } },
      associationType: 'own',
    },
    {
      type: 'radioGroup',
      name: 'paymentStatus',
      label: 'Payment Status',
      options: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'Paid',
          value: 'Paid',
        },
        {
          label: 'Unpaid',
          value: 'Unpaid',
        },
      ],
    },
    {
      type: 'dateRange',
      name: 'activationDate',
      label: 'Activation Date',
      showTime: false,
    },
    {
      type: 'dateRange',
      name: 'priceEffectiveDate',
      label: 'Price Effective Date',
      showTime: false,
    },
    {
      type: 'dateRange',
      name: 'expiryDate',
      label: 'Expiry Date',
      showTime: false,
    },
    {
      type: 'dateRange',
      name: 'paymentDate',
      label: 'Payment Date',
      showTime: false,
    },
    {
      type: 'dateRange',
      name: 'invoiceDate',
      label: 'Invoice Date',
      showTime: false,
    },
  ];

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderText> Package Management </HeaderText>
      </Flex>
      <ModelContainer divHeight={divHeight} role={user.role}>
        <ModelPage
          showHeader={false}
          modelName={modelName}
          itemsPerPage={itemsPerPage}
          filterConfig={user.role === 'parent' ?
            filterConfig.filter((f) => (
              f.label !== 'Sale To'
              // && f.label !== 'Invoice Date'
              && f.label !== 'Payment Status'
              // && f.label !== 'Payment Date'
            )) : filterConfig}
          allowSearch
          showCreateButton={user.role === 'admin' || user.role === 'superAdmin'}
          showExportButton={user.role === 'admin' || user.role === 'superAdmin'}
          createState={{ prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` }}
          query={{
            where: {
              status: 'Active',
            },
          }}
          initialQuery={{
            currentPage: 1,
            where: {
              status: 'Active',
            },
          }}
          radioGroup={['All', 'Active', 'Inactive', 'Completed', 'Expired']}
          radioGroupKey="status"
          {...props}
          render={(renderProps) => {
            const { height } = renderProps;
            if (divHeight !== height) setDivHeight(height);
            return (
              <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Packages" />}>
                <AntDTable
                  modelName={modelName}
                  cellRenderers={user.role === 'parent' ?
                    cellRenderers.filter((c) => (
                      c.title !== 'Sale To'
                      && c.title !== 'Invoice'
                      // && c.title !== 'Invoice Date'
                      && c.title !== 'Payment Method'
                      // && c.title !== 'Payment Date'
                      && c.title !== 'Action'
                    )) : cellRenderers.filter((c) => (
                      c.title !== 'Expiry Date'
                      && c.title !== 'Activation Date'
                    ))}
                  scroll={{ x: 'max-content', y: '65vh' }}
                  expandable={user.role === 'parent' ? false : {
                    expandedRowRender: (record) => (
                      <AntDTable
                        modelName={modelName}
                        cellRenderers={expandableColumns}
                        pagination={false}
                        rowKey="id"
                        data={[record]}
                        isExpanded
                      />
                    ),
                  }}
                  pagination={{ pageSize: itemsPerPage, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                  rowKey="id"
                  {...renderProps}
                />
              </ConfigProvider>
            )
          }}
        />
      </ModelContainer>
    </Wrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
