import React from 'react';
import PropTypes from 'prop-types';
import { TwitterPicker } from 'react-color';
import get from 'lodash/get';
import { Creatable } from 'react-select';
import { compose, withState, withHandlers, lifecycle } from 'recompose';
import pick from 'lodash/pick';
import groupBy from 'lodash/groupBy';
import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';
import find from 'lodash/find';
import styled from 'styled-components';
import Select from '../../atoms/Select';
import MultiSelect from '../../atoms/MultiSelect';
// import categories from './categories.json';
import withLoadingState from '../../../containers/enhancers/withLoadingState';

const Flex = styled.div`
  display: flex;
  justifyContent: ${({ justifyContent = 'default' }) => justifyContent};
  flex-direction: ${({ direction = 'row' }) => direction};
`;


const ColorPicker = (props) => {
  const {
    disabled,
    meta,
    value,
    onChange,
  } = props;
  return (
    <Flex direction="column">
      <TwitterPicker triangle={'hide'} onChange={({ hex }) => onChange(hex)} color={value} {...{ disabled, ...meta }} />
    </Flex>
  );
};

ColorPicker.propTypes = {
  invalid: PropTypes.bool,
  isDirty: PropTypes.bool,
  meta: PropTypes.object,
  onChange: PropTypes.func,
};


export default ColorPicker;
