import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette } from 'styled-theme';
import { Radio } from 'antd';
import get from 'lodash/get';

const RadioGroup = ({
  options, value, onChange, radioGroupSize,
}) => {
  const onRadioChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <Radio.Group
      defaultValue={null}
      value={value}
      onChange={onRadioChange}
      size={radioGroupSize}
      style={{ marginRight: 'auto' }}
    >
      {options.map((r) => <Radio.Button key={r} value={r}>{r}</Radio.Button>)}
    </Radio.Group>
  )
};

RadioGroup.defaultProps = {
  options: [],
  radioGroupSize: 'large',
  value: null,
  onChange: (valueStr) => {
    console.log('[RadioGroup] onChange() ', valueStr);
  },
};

RadioGroup.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  radioGroupSize: PropTypes.string,
};

export default RadioGroup;
