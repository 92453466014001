import React from 'react';
import PropTypes from 'prop-types';
import getRange from 'lodash/range';
import styled, { css } from 'styled-components';
import { palette } from 'styled-theme';
import classNames from 'classnames';

const ButtonCss = css`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  padding: 10px 10px;
  margin: 0 4px;
  font-size: 16px;
  width: 36px;
  height: 36px;
  text-align: center;

  color: ${palette('primary', 0)};
  &:hover {
    color: ${palette('primary', 1)};
  }
  &.active {
    font-weight: bold;
  }
`;
const flexBoxCss = css`
  display: flex;
  align-items: center; 
`;
const PaginationWrapper = styled.div`
  ${flexBoxCss}
`;
const Control = styled.div`
  ${ButtonCss}
  width: auto;

   &.hidden {
    visibility: hidden;
  }
`;
const Paging = styled.div`
  ${flexBoxCss}
`;
const Page = styled.div`
  ${ButtonCss}
`;

const getPageRange = (currentPage, reach, totalItems, itemsPerPage) => {
  const firstPage = totalItems > 0 ? 1 : 1;
  const lastPage = Math.ceil(totalItems / itemsPerPage);

  let rangeStart;
  let rangeEnd;

  if (currentPage - Math.ceil(reach / 2) < firstPage) {
    rangeStart = firstPage;
    rangeEnd = Math.min((rangeStart + reach) - 1, lastPage);
  } else if (currentPage + Math.floor(reach / 2) > lastPage) {
    rangeEnd = lastPage;
    rangeStart = Math.max(firstPage, (lastPage - reach) + 1);
  } else {
    rangeStart = Math.max(firstPage, currentPage - Math.floor(reach / 2));
    rangeEnd = Math.min((rangeStart + reach) - 1, lastPage);
  }

  const range = getRange(rangeStart, rangeEnd + 1); // +1 as getRange() is exclusive for end index
  return { rangeStart, rangeEnd, range };
};

const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  reach,
  onPageChange,
  className,
  wrapperClassName,
  controlClassName,
  pageClassName,
  pagingClassName,
}) => {
  const firstPage = totalItems > 0 ? 1 : 1;
  const lastPage = Math.ceil(totalItems / itemsPerPage);
  const previousPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const { range, rangeStart, rangeEnd } = getPageRange(currentPage, reach, totalItems, itemsPerPage);
  const showedFirstPage = rangeStart !== firstPage;
  const showedLastPage = rangeEnd !== lastPage;
  const isFirstPage = currentPage <= firstPage;
  const isLastPage = currentPage >= lastPage;
  const onPageClick = pageNumber => () => onPageChange(pageNumber);

  return (
    <div className={className}>
      <PaginationWrapper className={wrapperClassName}>
        {!isFirstPage && (
          <Control
            onClick={onPageClick(previousPage)}
            className={controlClassName}
          >
            {'<< Previous'}
          </Control>
        )}
        <Paging className={pagingClassName}>
          {showedFirstPage && (
            <React.Fragment>
              <Page onClick={onPageClick(firstPage)} className={pageClassName}>{firstPage}</Page>
              <Page disabled>...</Page>
            </React.Fragment>
          )}
          {range.map((pageNumber, index) => (
            <Page
              key={`pagination-${index}`}
              className={classNames(
                pageClassName,
                {
                  active: currentPage === pageNumber,
                },
              )}
              onClick={onPageClick(pageNumber)}
            >
              {pageNumber}
            </Page>
          ))}
          {showedLastPage && (
            <React.Fragment>
              <Page disabled>...</Page>
              <Page onClick={onPageClick(lastPage)} className={pageClassName}>{lastPage}</Page>
            </React.Fragment>
          )}
        </Paging>
        {!isLastPage && (
          <Control
            onClick={onPageClick(nextPage)}
            className={controlClassName}
          >
            {'Next >>'}
          </Control>
        )}
      </PaginationWrapper>
    </div>
  );
};

Pagination.defaultProps = {
  onPageChange: () => {},
  className: classNames('pagination'),
  wrapperClassName: classNames('pagination-wrapper'),
  controlClassName: classNames('pagination-control'),
  pageClassName: classNames('pagination-page'),
  pagingClassName: classNames('pagination-paging'),
  reach: 7,
};

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  reach: PropTypes.number,
  onPageChange: PropTypes.func,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  controlClassName: PropTypes.string,
  pageClassName: PropTypes.string,
  pagingClassName: PropTypes.string,
};

export default Pagination;
