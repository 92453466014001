import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import { ifProp } from 'styled-tools';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Link from '../../atoms/Link';
import BlockTimeForm from '../BlockTimeForm';
import useCalendarForm from '../../../hooks/useCalendarForm';
import Icon from '../../atoms/Icon';
import ErrorAlert from '../../molecules/ErrorAlert';

const StyledButton = styled(Button)`
  margin: ${size('margin.default')} auto ${size('margin.default')};
  border-radius: 0px;
  margin-left: 10px;
`;

const StyledButton2 = styled(Button)`
  display: flex;
  flex-direction: row;
  border-radius: 0px;
  margin-bottom: 0px;
  width: 50%;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 30vw;
  min-width: 30vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const StyledText = styled.div`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  padding: 20px 0px 30px 0px;
  text-align: center;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledIcon = styled(Icon)`
  height: 14px;
  min-width: 14px;
  margin-right: 5px;

  > svg {
    stroke: ${ifProp('disabled', '#C1C1C1', 'none')};
  }
`;

const StyledButton3 = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorAlertContainer = styled.div`
  > div {
    max-width: 28vw;
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: none;
    }
  }
`;

const PopUpDeleteLesson = ({ user, selectedEvent, id, type, page }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { onSubmit } = useCalendarForm({
    endpoint: 'event/deleteClass',
    setErrorMessage,
    user,
    id,
    type,
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
        setIsSubmitting(false);
      }, 3000);
    }
  }, [errorMessage]);

  const { hasAttendanceConfirmed } = selectedEvent;

  const trigger = page === 'lesson' ? <StyledButton3 disabled={hasAttendanceConfirmed} style={{ color: hasAttendanceConfirmed && '#C1C1C1' }}> Delete </StyledButton3> : <StyledButton2 disabled={hasAttendanceConfirmed} style={{ color: hasAttendanceConfirmed && '#C1C1C1', marginLeft: 20 }}> <StyledIcon icon="cancel" disabled={hasAttendanceConfirmed} /> Delete </StyledButton2>

  return (
    <StyledPopup
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          <ErrorAlertContainer>
            <ErrorAlert
              errorMessage={errorMessage}
              show={errorMessage !== null}
            />
          </ErrorAlertContainer>
          <PopUpButton className="close" onClick={close}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper>
            Delete Class
          </PopUpTextWrapper>
          <StyledText>
            Are you sure you want to delete this class?
          </StyledText>
          <StyledButton2 style={{ width: '100%' }} disabled={isSubmitting} onClick={() => { setIsSubmitting(true); onSubmit(); }}> Confirm </StyledButton2>
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpDeleteLesson.propTypes = {
  user: PropTypes.object.isRequired,
  selectedEvent: PropTypes.object,
  id: PropTypes.number,
  type: PropTypes.string.isRequired,
};

export default PopUpDeleteLesson;
