import { compose, withProps, withState } from 'recompose';

import ForgetPasswordForm from '../../components/organisms/ForgotPasswordForm';

export default compose(
  withState('error', 'setError', null),
  withState('success', 'setSuccess', false),
  withProps(({ setError, setSuccess }) => {
    return {
      onSubmit: ({ email }) => {
        setError(null);
        setSuccess(false);
        return global.authApi.post('/reset-password', { email })
          .then(() => {
            setSuccess(true);
          })
          .catch(err => {
            setError(err.message);
          });
      },
    };
  }),
)(ForgetPasswordForm);
