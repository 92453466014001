import { connect } from 'react-redux';

import LoginForm from '../../../components/organisms/LoginForm';
import { signInRequest } from '../../../store/authentication/actions';

const mapDispatchToProps = dispatch => ({
  onSubmit: values => dispatch(signInRequest(values)),
});

export default connect(null, mapDispatchToProps)(LoginForm);
