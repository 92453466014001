import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import get from 'lodash/get';
import { Radio } from 'antd';
import moment from 'moment';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Link from '../../atoms/Link';
import BlockTimeForm from '../BlockTimeForm';
import useCalendarForm from '../../../hooks/useCalendarForm';
import Icon from '../../atoms/Icon';
import ErrorAlert from '../../molecules/ErrorAlert';

const StyledButton = styled(Button)`
  margin: ${size('margin.default')} auto ${size('margin.default')};
  border-radius: 0px;
  margin-left: 10px;
`;

const StyledButton2 = styled(Button)`
  display: flex;
  flex-direction: row;
  border-radius: 0px;
  margin-bottom: 0px;
  width: 50%;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 30vw;
  min-width: 30vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const StyledText = styled.div`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  padding: 20px 0px 30px 0px;
  text-align: center;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledIcon = styled(Icon)`
  height: 14px;
  min-width: 14px;
  margin-right: 5px;
`;

const ErrorAlertContainer = styled.div`
  > div {
    max-width: 28vw;
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: none;
    }
  }
`;

const RadioGroupContainer = styled.div`
  margin-bottom: 10px;
  > div {
    display: flex;
    flex-direction: column;

    label {
      margin: 10px 0px;
      > span: first-child {
        margin-right: 5px;
      }
    }
  }
`;

const PopUpBlockTime = ({ user, selectedEvent, id, type, triggerButton }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [radioGroup, setRadioGroup] = useState('this');

  const { onSubmit } = useCalendarForm({
    endpoint: 'event/deleteBlockTime',
    setErrorMessage,
    user,
    id,
    type: 'deleteEvent',
    selectedEvent,
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
        setIsSubmitting(false);
      }, 3000);
    }
  }, [errorMessage]);

  const trigger = triggerButton || <StyledButton2> <StyledIcon icon="cancel" /> Delete </StyledButton2>

  return (
    <StyledPopup
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          <ErrorAlertContainer>
            <ErrorAlert
              errorMessage={errorMessage}
              show={errorMessage !== null}
            />
          </ErrorAlertContainer>
          <PopUpButton className="close" onClick={close}>
            &times;
          </PopUpButton>
          <PopUpTextWrapper>
            {`Delete ${(get(selectedEvent, 'recurrenceType', null) === 'Does not repeat') ? 'Block Time' : 'Recurring Block Time'}`}
          </PopUpTextWrapper>
          {(get(selectedEvent, 'recurrenceType', null) === 'Does not repeat') ? (
            <div>
              <StyledText>
                Are you sure you want to delete this block time?
              </StyledText>
              <StyledButton2 style={{ width: '100%' }} disabled={isSubmitting} onClick={() => { setIsSubmitting(true); onSubmit(selectedEvent); }}> Confirm </StyledButton2>
            </div>
          ) : (
            <div>
              <RadioGroupContainer>
                <Radio.Group value={radioGroup} onChange={(v) => setRadioGroup(get(v, 'target.value', null))} options={[{ label: 'This block time', value: 'this' }, { label: 'This and following block times', value: 'thisFollowing' }]} />
              </RadioGroupContainer>
              <StyledButton2 style={{ width: '100%' }} disabled={isSubmitting} onClick={() => { setIsSubmitting(true); selectedEvent.radioGroup = radioGroup; onSubmit(selectedEvent); }}> Confirm </StyledButton2>
            </div>
          )}
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpBlockTime.propTypes = {
  user: PropTypes.object.isRequired,
  selectedEvent: PropTypes.object,
  id: PropTypes.number,
  type: PropTypes.string.isRequired,
};

export default PopUpBlockTime;
