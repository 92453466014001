import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, size, palette } from 'styled-theme';
import Popup from 'reactjs-popup';
import moment from 'moment';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Link from '../../atoms/Link';
import RequestForm from '../RequestForm';
import useRequestForm from '../../../hooks/useRequestForm';
import Icon from '../../atoms/Icon';

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  border-radius: 0px;
  margin-bottom: 0px;
  width: 50%;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 45vw;
  min-width: 45vw;
  padding: 20px;
  max-height: 95vh;

  > form {
    height: calc(100% - 87px);
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const StyledIcon = styled(Icon)`
  height: 14px;
  min-width: 14px;
  margin-right: 5px;
`;

const CompletedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

const StyledText = styled.div`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  padding: 10px 0px 20px 0px;
  text-align: center;
  margin-top: 20px;
`;

const StyledButton2 = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const PopUpRequest = ({ user, id, child, instructor, selectedEvent, eventInstructor, requests, type, allLessons, isCancelled, page }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [completeMessage, setCompleteMessage] = useState(null);

  const { onSubmit } = useRequestForm({
    endpoint: 'request',
    user,
    id,
    type,
    setErrorMessage,
    setCompleteMessage,
  });

  const currRequest = requests.filter((r) => r.eventId === selectedEvent.id);
  let sentRequest = false;

  if (currRequest.length > 0) sentRequest = true;

  let trigger = null;
  let completionText = null;

  if (type === 'reschedule') {
    trigger = page === 'lesson' ? <StyledButton2 style={{ color: sentRequest && '#C1C1C1' }} disabled={sentRequest}> Reschedule </StyledButton2> : <StyledButton style={{ marginRight: 10, width: isCancelled && '100%' }} disabled={sentRequest}> <StyledIcon icon="reschedule" /> Reschedule </StyledButton>
    completionText = 'Class reschedule request sent. The admin team will be in touch as soon as possible to fulfil your request.';
  } else {
    trigger = page === 'lesson' ? <StyledButton2 style={{ color: (sentRequest || isCancelled) && '#C1C1C1' }} disabled={sentRequest || isCancelled}> Cancel </StyledButton2> : <StyledButton disabled={sentRequest}> <StyledIcon icon="cancel" /> Cancel </StyledButton>
    completionText = 'Class cancellation sent.';
  }

  return (
    <StyledPopup
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {(close) => (
        <PopUpWrapper>
          { completeMessage == null ? (
            <PopUpButton className="close" onClick={close}>
              &times;
            </PopUpButton>
          ) : null}
          { type === 'reschedule'
            ? <PopUpTextWrapper> Class Reschedule Request </PopUpTextWrapper>
            : <PopUpTextWrapper> Class Cancellation Request </PopUpTextWrapper>}
          { completeMessage == null ? (
            <RequestForm
              onSubmit={onSubmit}
              child={child}
              instructor={instructor}
              selectedEvent={selectedEvent}
              eventInstructor={eventInstructor}
              type={type}
              user={user}
              selectedRequest={{}}
              allLessons={allLessons}
            />
          ) : (
            <CompletedWrapper style={{ paddingRight: '20px', maxHeight: '100%', overflow: 'auto' }}>
              <Icon icon="request-sent" height="60" />
              <StyledText>{completionText}</StyledText>
            </CompletedWrapper>
          )}
        </PopUpWrapper>
      )}
    </StyledPopup>
  );
}

PopUpRequest.propTypes = {
  user: PropTypes.object.isRequired,
  id: PropTypes.number,
  child: PropTypes.array.isRequired,
  instructor: PropTypes.array.isRequired,
  selectedEvent: PropTypes.object,
  eventInstructor: PropTypes.array,
  requests: PropTypes.array,
  type: PropTypes.string.isRequired,
  allLessons: PropTypes.array,
};

export default PopUpRequest;
