import React, { useState } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import moment from 'moment';
import { connect } from 'react-redux';
import { ConfigProvider, Empty } from 'antd';
import { FixedSizeList as List } from 'react-window';
import ReactSelect, { createFilter } from 'react-select';
import { PieChart, Pie, Legend, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import get from 'lodash/get';
import P from '../../../components/atoms/P';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import OverView from '../../../components/organisms/OverView';
import Card from '../../../components/atoms/Card';
import Flex from '../../../components/atoms/Flex';
import Icon from '../../../components/atoms/Icon';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import Button from '../../../components/atoms/Button';
import Link from '../../../components/atoms/Link';
import ModelPage from '../../../containers/ModelPage';
import customStyle from '../../../components/atoms/Select/styles';
import useQueryParams from '../../../hooks/useQueryParams';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 12px 12px 0px 12px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
`;

const LeftWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 15px 24px;
  margin-right: 50px;
`;

const RightWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 15px 24px;
  overflow: auto;
`;

const BottomWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: center;
`;

const MainHeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding: 10px 5px;
  font-family: ${font('tertiary')};
  padding-left: 0px;
`;

const OverViewText = styled.div`
  font-size: 14px;
`;

const ModelContainer = styled.div`
  > div {
    > div {
      > div: nth-child(3) {
        margin-left: auto;
      }
    }
  }
`;

const MenuContainer = styled.div`
  > div {
    > div {
      div {
        div {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = 35;
  const [value] = getValue();
  const listHeight = Math.min(maxHeight, height * children.length) || 200;
  let initialOffset = options.indexOf(value) * height;
  initialOffset = initialOffset > listHeight ? initialOffset : 0;
  return (
    <MenuContainer>
      { children.length ? (
        <List
          height={listHeight}
          itemCount={children.length}
          itemSize={height}
          initialScrollOffset={initialOffset}
        >
          {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
      ) : <div style={{ padding: '10px 8px' }}> No options available </div>}
    </MenuContainer>
  );
};

const StyledPieChart = styled(PieChart)`
  display: flex;
  align-items: center;
  li {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex !important;
    align-items: center;
  }
  > svg {
    min-width: 200px;
  }
  > div {
    min-width: 300px;
  }
`;

const getAllMonthYears = (startDate) => {
  const dateToday = moment().format('YYYY-MM-DD');
  let newDate = moment(startDate).format('YYYY-MM-DD');
  const monthYears = [];
  while (moment(newDate).isBefore(dateToday)) {
    const currentDay = moment(newDate).get('date');
    const monthStartDate = currentDay < 28 ? moment(newDate).set('date', 28).subtract(1, 'months').startOf('day') : moment(newDate).set('date', 28).startOf('day');
    const monthEndDate = currentDay < 28 ? moment(newDate).set('date', 27).endOf('day') : moment(newDate).set('date', 27).add(1, 'months').endOf('day');

    monthYears.push({ label: `${monthStartDate.format('DD MMM YYYY')} - ${monthEndDate.format('DD MMM YYYY')}`, value: JSON.stringify({ startDate: monthStartDate.format('YYYY-MM-DD'), endDate: monthEndDate.format('YYYY-MM-DD') }) });
    newDate = moment(newDate).add(1, 'months').format('YYYY-MM-DD');
  }
  return [...monthYears].reverse();
}

const ShowView = (props) => {
  const { user } = props;
  const monthYears = getAllMonthYears('2021-07-01');

  const { queryParams, setQueryParams } = useQueryParams({ initialQueryParams: { range: JSON.parse(monthYears[0].value) } });

  const currentRange = get(queryParams, 'range', JSON.parse(monthYears[0].value));

  const customSelect = (
    <div style={{ width: 250, minWidth: 250 }}>
      <ReactSelect
        className="select"
        autoBlur
        components={{ MenuList }}
        filterOption={createFilter({ ignoreAccents: false })}
        options={monthYears}
        onChange={(value) => setQueryParams((old) => ({
          ...old,
          range: JSON.parse(value.value),
        }), { replace: true })}
        closeMenuOnSelect
        isClearable={false}
        onBlurResetsInput={false}
        onCloseResetsInput={false}
        hideSelectedOptions={false}
        styles={customStyle({ invalid: false, disabled: false })}
        value={monthYears.filter((option) => option.value === JSON.stringify(currentRange))[0]}
        defaultValue={monthYears[0]}
        isDisabled={false}
      />
    </div>
  )

  return (
    <MainWrapper>
      <MainHeaderText> Financial Management </MainHeaderText>
      <ModelContainer>
        <ModelPage
          showHeader={false}
          apiRoute="performance/financial"
          allowSearch={false}
          query={{ where: { date: currentRange } }}
          showCreateButton={false}
          customSelect={customSelect}
          {...props}
          render={(renderProps) => {
            const { data, loading } = renderProps;
            const { revenue = {}, teachingOverview = [] } = data;
            const totalLessons = teachingOverview.reduce((total, current) => total + current.classes, 0);
            const totalHours = +(teachingOverview.reduce((total, current) => total + current['Teaching Hours'], 0)).toFixed(2);
            const monthlyRevenue = [
              { name: 'Lesson', value: revenue.lesson, color: '#5BA1F8' },
              { name: 'Inventory', value: revenue.inventory, color: '#FFAFAF' },
              { name: 'Others', value: revenue.others, color: '#F6D564' },
            ];
            if (loading) return <Loading />
            return (
              <Wrapper>
                <TopWrapper>
                  <LeftWrapper>
                    <HeaderText> Monthly Teaching Overview </HeaderText>
                    <Flex direction="column" style={{ paddingTop: 15, paddingBottom: 10 }}>
                      <HeaderText style={{ fontSize: 20 }}> {totalHours} </HeaderText>
                      <OverViewText> Monthly Teaching Hours</OverViewText>
                    </Flex>
                    <Flex direction="column" style={{ paddingTop: 15, paddingBottom: 10 }}>
                      <HeaderText style={{ fontSize: 20 }}> {totalLessons} </HeaderText>
                      <OverViewText> Monthly Classes </OverViewText>
                    </Flex>
                  </LeftWrapper>
                  <RightWrapper>
                    <HeaderText> Monthly Revenue </HeaderText>
                    <StyledPieChart width={200} height={200}>
                      <Pie data={monthlyRevenue} cx={80} cy={100} innerRadius={40} outerRadius={80}>
                        {monthlyRevenue.map((type) => (
                          <Cell fill={type.color} />
                        ))}
                      </Pie>
                      <Legend
                        layout="vertical"
                        iconType="circle"
                        formatter={(value, entry) => {
                          const sale = (entry.payload && entry.payload.value) ? entry.payload.value : 0;
                          return (
                            <span style={{ marginLeft: 5 }}>
                              <span style={{ color: '#000', minWidth: 80, display: 'inline-block' }}> {value} </span>
                              <span style={{ color: '#9F9F9F', minWidth: 80, display: 'inline-block' }}> {`| ${+(Number((sale / revenue.total) * 100).toFixed(2))}%`} </span>
                              <span style={{ color: '#000' }}> {`$${sale.toLocaleString()}`} </span>
                            </span>
                          )
                        }}
                        wrapperStyle={{ position: 'relative', left: 0, top: 4 }}
                      />
                    </StyledPieChart>
                  </RightWrapper>
                </TopWrapper>
                <BottomWrapper>
                  <HeaderText> {'Instructors\' Teaching Hours'} </HeaderText>
                  <ResponsiveContainer height={teachingOverview.length * 50}>
                    <BarChart data={teachingOverview} layout="vertical">
                      <CartesianGrid strokeDasharray="3" />
                      <XAxis dataKey="Teaching Hours" type="number" domain={[0, 150]} ticks={[0, 30, 60, 90, 120, 150]} label="Teaching Hours"/>
                      <YAxis dataKey="instructor.name" type="category" width={100} />
                      <Bar dataKey="Teaching Hours" fill="#84CDBD" />
                      <Tooltip cursor={{ fill: 'transparent' }} labelStyle={{ textAlign: 'center' }} itemStyle={{ textAlign: 'center' }} />
                    </BarChart>
                  </ResponsiveContainer>
                </BottomWrapper>
              </Wrapper>
            )
          }}
        />
      </ModelContainer>
    </MainWrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowView);
