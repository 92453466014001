import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { palette } from 'styled-theme';
import { ifProp } from 'styled-tools';

import { ReactComponent as ArrowUpIcon } from './icons/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from './icons/arrow-down.svg';
import { ReactComponent as CheckIcon } from './icons/check.svg';
import { ReactComponent as CloseIcon } from './icons/close.svg';
import { ReactComponent as CopyIcon } from './icons/copy.svg';
import { ReactComponent as DocsIcon } from './icons/docs.svg';
import { ReactComponent as FacebookFIcon } from './icons/facebook-f.svg';
import { ReactComponent as LoaderIcon } from './icons/loader.svg';
import { ReactComponent as FacebookIcon } from './icons/facebook.svg';
import { ReactComponent as GithubIcon } from './icons/github.svg';
import { ReactComponent as HeartIcon } from './icons/heart.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as LinkedinIcon } from './icons/linkedin.svg';
import { ReactComponent as StarIcon } from './icons/star.svg';
import { ReactComponent as TwitterIcon } from './icons/twitter.svg';
import { ReactComponent as ResponseIcon } from './icons/response.svg';
import { ReactComponent as ZoomInIcon } from './icons/zoom-in.svg';
import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as SortArrowsIcon } from './icons/sort-arrows.svg';
import { ReactComponent as SortArrowUpIcon } from './icons/sort-arrow-up.svg';
import { ReactComponent as SortArrowDownIcon } from './icons/sort-arrow-down.svg';
import { ReactComponent as CalendarLeftArrow } from './icons/mintleftarrow.svg';
import { ReactComponent as CalendarRightArrow } from './icons/mintrightarrow.svg';
import { ReactComponent as PersonIcon } from './icons/person.svg';
import { ReactComponent as RescheduleIcon } from './icons/reschedule.svg';
import { ReactComponent as CancelIcon } from './icons/cancel.svg';
import { ReactComponent as EventIcon } from './icons/event.svg';
import { ReactComponent as BlockTimeIcon } from './icons/blocktime.svg';
import { ReactComponent as ALevelsMusicIcon } from './icons/alevelsmusic.svg';
import { ReactComponent as AuralIcon } from './icons/aural.svg';
import { ReactComponent as CelloIcon } from './icons/cello.svg';
import { ReactComponent as ClarinetIcon } from './icons/clarinet.svg';
import { ReactComponent as CompositionIcon } from './icons/composition.svg';
import { ReactComponent as FluteIcon } from './icons/flute.svg';
import { ReactComponent as GcseMusicIcon } from './icons/gcsemusic.svg';
import { ReactComponent as GuitarIcon } from './icons/guitar.svg';
import { ReactComponent as LeavesIcon } from './icons/leaves.svg';
import { ReactComponent as MiniVocalMintsIcon } from './icons/minivocalmints.svg';
import { ReactComponent as OboeIcon } from './icons/oboe.svg';
import { ReactComponent as PianoIcon } from './icons/piano.svg';
import { ReactComponent as RecorderIcon } from './icons/recorder.svg';
import { ReactComponent as SaxophoneIcon } from './icons/saxophone.svg';
import { ReactComponent as ScalesIcon } from './icons/scales.svg';
import { ReactComponent as SeedlingsIcon } from './icons/seedlings.svg';
import { ReactComponent as SeedsIcon } from './icons/seeds.svg';
import { ReactComponent as SproutsIcon } from './icons/sprouts.svg';
import { ReactComponent as TheoryIcon } from './icons/theory.svg';
import { ReactComponent as TromboneIcon } from './icons/trombone.svg';
import { ReactComponent as TrumpetIcon } from './icons/trumpet.svg';
import { ReactComponent as UkuleleIcon } from './icons/ukulele.svg';
import { ReactComponent as ViolaIcon } from './icons/viola.svg';
import { ReactComponent as ViolinIcon } from './icons/violin.svg';
import { ReactComponent as VocalMintsIcon } from './icons/vocalmints.svg';
import { ReactComponent as VoiceIcon } from './icons/voice.svg';
import { ReactComponent as LessonCancelIcon } from './icons/lessoncancel.svg';
import { ReactComponent as LessonBackIcon } from './icons/lessonbackicon.svg';
import { ReactComponent as LessonRescheduleIcon } from './icons/lessonreschedule.svg';
import { ReactComponent as RequestSentIcon } from './icons/requestsent.svg';
import { ReactComponent as LessonCancelMoreIcon } from './icons/lessoncancelmore.svg';
import { ReactComponent as LessonRescheduleErrorIcon } from './icons/lessonrescheduleerror.svg';
import { ReactComponent as LessonIcon } from './icons/lesson.svg';
import { ReactComponent as EmailReminderIcon } from './icons/emailreminder.svg';
import { ReactComponent as RescheduleRequestIcon } from './icons/reschedulerequest.svg';
import { ReactComponent as CancelRequestIcon } from './icons/cancelrequest.svg';
import { ReactComponent as PurchaseRequestIcon } from './icons/purchaserequest.svg';
import { ReactComponent as BasicInfoIcon } from './icons/basicinfo.svg';
import { ReactComponent as ContactInfoIcon } from './icons/contactinfo.svg';
import { ReactComponent as AddContactIcon } from './icons/addcontact.svg';
import { ReactComponent as RemoveContactIcon } from './icons/removecontact.svg';
import { ReactComponent as HideEyeIcon } from './icons/hideeye.svg';
import { ReactComponent as ShowEyeIcon } from './icons/showeye.svg';
import { ReactComponent as StudyInfoIcon } from './icons/studyinfo.svg';
import { ReactComponent as OtherInfoIcon } from './icons/otherinfo.svg';
import { ReactComponent as EditIcon } from './icons/editicon.svg';
import { ReactComponent as ResetArrowDownIcon } from './icons/reset-arrow-down.svg';
import { ReactComponent as ResetArrowUpIcon } from './icons/reset-arrow-up.svg';
import { ReactComponent as PackageInfoIcon } from './icons/package-info.svg';
import { ReactComponent as PaymentInfoIcon } from './icons/payment-info.svg';
import { ReactComponent as StatusIcon } from './icons/status.svg';
import { ReactComponent as LessonPackageIcon } from './icons/lessonpackage.svg';
import { ReactComponent as EditLessonIcon } from './icons/editlesson.svg';
import { ReactComponent as RevertLessonIcon } from './icons/revertlesson.svg';
import { ReactComponent as LessonCancelOrangeIcon } from './icons/lessoncancelorange.svg';
import { ReactComponent as LessonNoteIcon } from './icons/lessonnote.svg';
import { ReactComponent as CalendarDeleteIcon } from './icons/calendardelete.svg';
import { ReactComponent as DisabledEditIcon } from './icons/disabled-edit.svg';
import { ReactComponent as SignatureIcon } from './icons/signature.svg';
import { ReactComponent as LibraryInfoIcon } from './icons/library-info.svg';
import { ReactComponent as SaleInfoIcon } from './icons/sale-info.svg';
import { ReactComponent as FilterDropDownIcon } from './icons/filter-dropdown.svg';
import { ReactComponent as FilterGoUpIcon } from './icons/filter-goup.svg';
import { ReactComponent as BurgerMenuIcon } from './icons/burger-menu.svg';
import { ReactComponent as BlackArrowUpIcon } from './icons/black-arrow-up.svg';
import { ReactComponent as BlackArrowDownIcon } from './icons/black-arrow-down.svg';
import { ReactComponent as BlackArrowRightIcon } from './icons/black-arrow-right.svg';
import { ReactComponent as CalendarPlusIcon } from './icons/calendar-plus.svg';

import { ReactComponent as LessonNoteBlackIcon } from './icons/lesson-note-black.svg';
import { ReactComponent as LessonNoteWhiteIcon } from './icons/lesson-note-white.svg';
import { ReactComponent as BroadcastPageIcon } from './icons/broadcast-page-icon.svg';
import { ReactComponent as BackButtonIcon } from './icons/back-button.svg';

import { ReactComponent as NotificationIcon } from './icons/notification.svg';
import { ReactComponent as NewNotificationIcon } from './icons/notification-new.svg';
import { ReactComponent as ClassAttendanceIcon } from './icons/attendance-icon.svg';
import { ReactComponent as BroadcastIcon } from './icons/broadcast-icon.svg';


const ReactIcons = {
  'arrow-up': ArrowUpIcon,
  'arrow-down': ArrowDownIcon,
  check: CheckIcon,
  close: CloseIcon,
  copy: CopyIcon,
  docs: DocsIcon,
  'facebook-f': FacebookFIcon,
  facebook: FacebookIcon,
  github: GithubIcon,
  heart: HeartIcon,
  info: InfoIcon,
  linkedin: LinkedinIcon,
  star: StarIcon,
  twitter: TwitterIcon,
  response: ResponseIcon,
  'zoom-in': ZoomInIcon,
  'search': SearchIcon,
  loader: LoaderIcon,
  'sort-arrows': SortArrowsIcon,
  'sort-arrow-up': SortArrowUpIcon,
  'sort-arrow-down': SortArrowDownIcon,
  calleftarrow: CalendarLeftArrow,
  calrightarrow: CalendarRightArrow,
  person: PersonIcon,
  reschedule: RescheduleIcon,
  cancel: CancelIcon,
  event: EventIcon,
  'block-time': BlockTimeIcon,
  'a-levels-music': ALevelsMusicIcon,
  aural: AuralIcon,
  cello: CelloIcon,
  clarinet: ClarinetIcon,
  composition: CompositionIcon,
  flute: FluteIcon,
  'gcse-music': GcseMusicIcon,
  guitar: GuitarIcon,
  leaves: LeavesIcon,
  'mini-vocal-mints': MiniVocalMintsIcon,
  oboe: OboeIcon,
  piano: PianoIcon,
  recorder: RecorderIcon,
  saxophone: SaxophoneIcon,
  scales: ScalesIcon,
  seedlings: SeedlingsIcon,
  seeds: SeedsIcon,
  sprouts: SproutsIcon,
  theory: TheoryIcon,
  trombone: TromboneIcon,
  trumpet: TrumpetIcon,
  ukulele: UkuleleIcon,
  viola: ViolaIcon,
  violin: ViolinIcon,
  'vocal-mints': VocalMintsIcon,
  voice: VoiceIcon,
  'lesson-cancel': LessonCancelIcon,
  'lesson-back': LessonBackIcon,
  'lesson-reschedule': LessonRescheduleIcon,
  'request-sent': RequestSentIcon,
  'lesson-cancel-more': LessonCancelMoreIcon,
  'lesson-reschedule-error': LessonRescheduleErrorIcon,
  lesson: LessonIcon,
  'email-reminder': EmailReminderIcon,
  'reschedule-request': RescheduleRequestIcon,
  'cancel-request': CancelRequestIcon,
  'purchase-request': PurchaseRequestIcon,
  'basic-info': BasicInfoIcon,
  'contact-info': ContactInfoIcon,
  'add-contact': AddContactIcon,
  'remove-contact': RemoveContactIcon,
  'preview-eye': HideEyeIcon,
  'hide-eye': ShowEyeIcon,
  'study-info': StudyInfoIcon,
  'other-info': OtherInfoIcon,
  'edit-icon': EditIcon,
  'reset-arrow-down': ResetArrowDownIcon,
  'reset-arrow-up': ResetArrowUpIcon,
  'package-info': PackageInfoIcon,
  'payment-info': PaymentInfoIcon,
  status: StatusIcon,
  'lesson-package': LessonPackageIcon,
  'edit-lesson': EditLessonIcon,
  'revert-lesson': RevertLessonIcon,
  'lesson-cancel-orange': LessonCancelOrangeIcon,
  'lesson-note': LessonNoteIcon,
  'calendar-delete': CalendarDeleteIcon,
  'edit-disabled': DisabledEditIcon,
  signature: SignatureIcon,
  'library-info': LibraryInfoIcon,
  'sale-info': SaleInfoIcon,
  'filter-dropdown': FilterDropDownIcon,
  'filter-goback': FilterGoUpIcon,
  'burger-menu': BurgerMenuIcon,
  'black-arrow-up': BlackArrowUpIcon,
  'black-arrow-down': BlackArrowDownIcon,
  'black-arrow-right': BlackArrowRightIcon,
  'calendar-plus': CalendarPlusIcon,

  'lesson-note-black': LessonNoteBlackIcon,
  'lesson-note-white': LessonNoteWhiteIcon,

  'broadcast-page': BroadcastPageIcon,
  'back-button': BackButtonIcon,
  notification: NotificationIcon,
  'new-notification': NewNotificationIcon,
  'class-attendance': ClassAttendanceIcon,
  broadcast: BroadcastIcon,
};

const fillStyle = css`
  .filled {
    fill: none;
    stroke: none;
  }
  ${ifProp(
    'filled',
    css`
      .filled {
        fill: currentcolor;
        stroke: currentcolor;
      }
      .unfilled {
        fill: none;
        stroke: none;
      }
    `,
  )};
`;

const fillHover = css`
  ${ifProp(
    'filled',
    ifProp(
      'unfillOnHover',
      null,
      css`
        .filled {
          fill: ${({ hoverPalette }) => palette(hoverPalette, hoverPalette === 'gray' ? 0 : 3)};
          stroke: ${({ hoverPalette }) => palette(hoverPalette, hoverPalette === 'gray' ? 0 : 3)};
        }
      `,
    ),
  )};
`;

const fillOnHover = css`
  ${ifProp(
    'fillOnHover',
    css`
      .filled {
        fill: ${({ hoverPalette }) => palette(hoverPalette, hoverPalette === 'gray' ? 0 : 3)};
        stroke: ${({ hoverPalette }) => palette(hoverPalette, hoverPalette === 'gray' ? 0 : 3)};
      }
      .unfilled {
        fill: none;
        stroke: none;
      }
    `,
  )};
`;

const unfillOnHover = css`
  ${ifProp(
    'unfillOnHover',
    css`
      .filled {
        fill: none;
        stroke: none;
      }
      .unfilled {
        fill: ${({ hoverPalette }) => palette(hoverPalette, hoverPalette === 'gray' ? 0 : 3)};
        stroke: ${({ hoverPalette }) => palette(hoverPalette, hoverPalette === 'gray' ? 0 : 3)};
      }
    `,
  )};
`;

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  color: ${ifProp(
    'palette',
    // ifProp({ palette: 'white' }, palette({ gray: 0 }, 0), palette({ gray: 0 }, 3)),
    'currentcolor',
    'white',
  )};
  height: ${({ height }) => `${height}px`};
  width: ${({ height }) => `${height}px`};

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* fill: currentcolor; */
    /* stroke: currentcolor; */
    fill: ${(props) => (props.fill ? props.fill : 'none')};
    stroke: ${(props) => (props.stroke ? props.stroke : 'none')};
    ${fillStyle};
  }

  ${ifProp(
    'hoverPalette',
    css`
      &:hover > svg {
        fill: ${({ hoverPalette }) => palette(hoverPalette, hoverPalette === 'gray' ? 0 : 3)};
        stroke: ${({ hoverPalette }) => palette(hoverPalette, hoverPalette === 'gray' ? 0 : 3)};
        ${unfillOnHover};
        ${fillOnHover};
        ${fillHover};
      }
    `,
  )};
`;

const Icon = ({ icon, ...props }) => {
  const ReactIcon = ReactIcons[icon];
  return <Wrapper {...props}>{ReactIcon && <ReactIcon />}</Wrapper>;
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  height: PropTypes.number,
  hoverPalette: PropTypes.string,
  palette: PropTypes.string,
  reverse: PropTypes.bool,
};

Icon.defaultProps = {
  height: 1.5,
};

export default Icon;
