import { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import has from 'lodash/has';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import omit from 'lodash/omit';

export default (options = {}) => {
  const { endpoint = undefined, id, data, pageUrl, viewOnSuccess = true } = options;

  const history = useHistory();
  const location = useLocation();
  const [formDisabled, setFormDisabled] = useState(false);
  const [updatedSpec, setUpdatedSpec] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { api } = global;
  let onSubmit;
  let handleDelete;

  const getRedirectUrl = (defaults) => {
    const dataId = get(defaults, 'id', id);
    if (pageUrl) {
      return `/${pageUrl}`;
    } else if (viewOnSuccess) {
      return `/${endpoint}/${dataId}`;
    } else if (has(location, 'state.from')) {
      return `/${location.state.from}`;
    }
  };
  if (data) {
    onSubmit = useCallback(values => {
      setFormDisabled(true);

      api
        .put(`/${endpoint}/${id}`, values)
        .then(() => {
          setFormDisabled(false);
          history.push(getRedirectUrl());
        })
        .catch(error => {
          setFormDisabled(false);
          setErrorMessage(error.message);
        });
    }, []);

    handleDelete = () => {
      setFormDisabled(true);

      api
        .delete(`/${endpoint}/${id}`)
        .then(() => {
          setFormDisabled(false);
          history.push(`/${pageUrl}`);
        })
        .catch(error => {
          setErrorMessage(error.message);
          setFormDisabled(false);
        });
    };
  } else {
    onSubmit = values => {
      api
        .post(`/${endpoint}`, values)
        .then((resp) => {
          history.push(getRedirectUrl({ id: resp.data.id }));
        })
        .catch(error => setErrorMessage(error.message));
    };
  }

  return {
    onSubmit,
    handleDelete,
    formDisabled,
    updatedSpec,
    errorMessage,
    setFormDisabled,
    setUpdatedSpec,
    setErrorMessage,
    initialValues: data,
  };
};
