import React from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FieldComponent from '../FieldComponent';

const DateTimeWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  > * {
    width: 23%;
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const DateRangerPicker = ({
  name,
  showTime,
  t,
  showYear,
  showDate = true,
}) => {
  const multiLanguageLabels = {
    Start_Date: 'Start_Date',
    End_Date: 'End_Date',
  };

  return (
    <DateTimeWrapper>
      { showDate ? (
        <Field
          name={`${name}.startDate`}
          component={FieldComponent}
          type="date"
          placeholder={multiLanguageLabels.Start_Date}
          renderError={false}
          showYearDropdown={showYear}
        />
      ) : null}
      {showTime
        ? (
          <Field
            name={`${name}.startTime`}
            component={FieldComponent}
            type="time"
            placeholder="Start Time"
            renderError={false}
            showYearDropdown={showYear}
          />
        )
        : null}
      to
      { showDate ? (
        <Field
          name={`${name}.endDate`}
          component={FieldComponent}
          type="date"
          placeholder={multiLanguageLabels.End_Date}
          renderError={false}
          showYearDropdown={showYear}
        />
      ) : null}
      {showTime
        ? (
          <Field
            name={`${name}.endTime`}
            component={FieldComponent}
            type="time"
            placeholder="End Time"
            renderError={false}
          />
        )
        : null}
    </DateTimeWrapper>
  );
};

DateRangerPicker.propTypes = {
  showTime: PropTypes.bool,
  t: PropTypes.func,
};

DateRangerPicker.defaultProps = {
  showTime: true,
};

export default (DateRangerPicker);
