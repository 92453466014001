import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import startCase from 'lodash/startCase';
import { Tabs, ConfigProvider, Empty, Tag } from 'antd';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import { selectUser } from '../../../store/authentication/selectors';
import Card from '../../../components/atoms/Card';
import Icon from '../../../components/atoms/Icon';
import Flex from '../../../components/atoms/Flex';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Button from '../../../components/atoms/Button';
import useGeneralData from '../../../hooks/useGeneralData';
import Loading from '../../../components/atoms/Loading';
import PopUpFile from '../../../components/organisms/PopUpFile';
import Image from '../../../components/atoms/Image';
import defaultProfile from '../../../asset/image/defaultprofile.png'
import useUserForm from '../../../hooks/useUserForm';
import PopUpTeachingNotes from '../../../components/organisms/PopUpTeachingNotes';
import useQueryParams from '../../../hooks/useQueryParams';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import PopUpDelete from '../../../components/organisms/PopUpDelete';

const Wrapper = styled.div`
  height: 100%;
`;

const HeaderTextWrapper = styled(Flex)`
  align-items: center;
  padding: 25px 5px 25px 0px;
  max-height: 66px;
  margin-left: -3px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: ${font('tertiary')};
  min-width: 200px;
  margin-top: 1px;
`;

const BackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 0px;
  height: 16px;
  max-height: 16px;
  background-color: #F5F5F5;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: #F5F5F5;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100% - 66px);
  padding-bottom: 10px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    flex-direction: column;
    min-height: calc(100% - 56px);
    padding-bottom: 0px;
  }
`;

const LeftWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 30%;
  margin-right: 15px;
  max-height: 100%;
  overflow: auto;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 12px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
    max-height: calc(50vh - 50px);
  }
`;

const RightWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  width: 70%;

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 0px;
    width: 100%;
    flex: 1;
  }
`;

const DetailWrapper = styled(Flex)`
  flex-direction: column;
`;

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  padding: 0px 0px 15px 0px;
  border-bottom: 1px dashed #E7E7E7;
`;

const LeftTopWrapper = styled(Flex)`
  justify-content: center;
  flex-direction: column;
`;

const ImageWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
  justify-content: center;
  margin-top: 0px;
`;

const StyledFlex = styled(Flex)`
  padding: 10px 0px;
  align-items: center;
`;

const LabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: ${font('tertiary')};
`;

const DetailLabelText = styled.div`
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  min-width: 100px;
  margin-right: 20px;
  font-family: ${font('tertiary')};
`;

const EditWrapper = styled(Flex)`
  justify-content: flex-end;
`;

const StyledLink = styled(Link)`
  color: #3E90F7;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    color: #3E90F7;
  }
`;

const ModelContainer = styled.div`
  > div {
    padding: 24px 0px;
    > div {
      display: flex;
      justify-content: flex-end;
      > div {
        .selector {
          width: 225px;
          min-width: 225px;
        }
      }
    }
  }

  .ant-table-body {
    max-height: 100% !important;
    @media (max-width: ${size('mobileBreakpoint')}) {
      max-height: ${(props) => (props.divHeight ? `calc(100vh - ${props.divHeight}px - 260px)` : 'calc(100vh - 56px - 260px)')} !important;
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const Note = styled.div`
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ShowView = (props) => {
  const { user, match, location } = props;
  const [uploadFile, setUploadFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [divHeight, setDivHeight] = useState(0);
  const [itemsDivHeight, setItemsDivHeight] = useState(0);
  const [filesDivHeight, setFilesDivHeight] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prevPath, setPrevPath] = useState(get(location, 'state.prevPath', null));

  const { data, loading } = useGeneralData({
    endpoint: 'student/studentData',
    query: { studentId: match.params.id },
  });

  useEffect(() => {
    if (errorMessage !== null) {
      setTimeout(() => {
        setErrorMessage(null);
        setIsSubmitting(false);
      }, 3000);
    }
  }, [errorMessage]);

  const history = useHistory();

  const { queryParams, setQueryParams } = useQueryParams()

  if (loading) return <Loading />

  if (!data || !data.formattedStudent) return <Redirect to="/student" />;

  const myLessons = (data.formattedStudent.lessons && data.formattedStudent.lessons.length > 0 && data.formattedStudent.lessons[0].id) ? data.formattedStudent.lessons.filter((v) => v.status !== 'Ex') : null;
  const myLessonNames = myLessons ? myLessons.map((l) => l.lessonName) : null;
  const currentActiveLessons = get(data, 'formattedStudent.currentActiveLessons', []);

  const lessonTabs = myLessonNames ? ['All', ...myLessonNames] : [];
  const { TabPane } = Tabs;
  const onTabChange = (tabValue) => {
    const radioGroupKeys = tabValue === 'Daily Teaching Notes' ? { name: 'All' } : { name: null };
    setQueryParams((old) => ({
      ...old,
      currentPage: 1,
      where: {
        ...old.where,
        tab: tabValue,
        ...radioGroupKeys,
      },
    }), { replace: true });
  }

  const teachingNotesColumns = [
    {
      title: 'Class',
      width: 90,
      render: (key, row) => <Cell><Link to={`/lesson/show/${row.recurrenceId}`}>{row.title}</Link></Cell>,
    },
    {
      title: 'Date',
      dataIndex: 'formattedDate',
      key: 'formattedDate',
      width: 75,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Time',
      dataIndex: 'formattedTime',
      key: 'formattedTime',
      width: 75,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Instructor',
      dataIndex: 'instructorLink',
      key: 'instructorLink',
      width: 100,
      render: (instructor) => <Cell>{(user.role === 'superAdmin' ? <Link to={`/staff/show/${instructor.id}`}>{instructor.name}</Link> : instructor.name)}</Cell>,
    },
    {
      title: 'Teaching Notes',
      dataIndex: 'teachingNotes',
      key: 'teachingNotes',
      width: 140,
      render: (note) => <Cell><Note> { note || 'N/A' } </Note></Cell>,
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (text, record) => (
        <Cell><PopUpTeachingNotes user={user} selectedRecord={record} type={(user.role === 'instructor' || (user.role === 'parent' && record.parentId === user.id)) ? 'edit' : 'view'} /></Cell>
      ),
    },
  ];

  const fileColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 80,
      render: (content) => <Cell style={{ maxWidth: 250 }}>{content}</Cell>,
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      width: 70,
      render: (url) => <Cell style={{ maxWidth: 'fit-content' }}><StyledLink to={{ pathname: url }} target="_blank" rel="noopener noreferrer"> {url} </StyledLink></Cell>,
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (text, record) => {
        const isDisabled = (user.role === 'instructor' || user.role === 'parent') && record.creatorId !== user.id;
        return <Cell><PopUpDelete user={user} endpoint="student/file" id={record.id} type="File" disabled={isDisabled} /></Cell>
      },
    },
  ];

  const purchasedItemsColumns = [
    {
      title: 'Name',
      key: 'product',
      width: 75,
      render: (text, record) => <Cell style={{ maxWidth: 300 }}>{((user.role === 'admin' || user.role === 'superAdmin') ? <Link to={`/inventory/show/${record.inventory.id}`}>{record.inventory.product}</Link> : record.inventory.product)}</Cell>,
    },
    {
      title: 'Type',
      dataIndex: ['inventory', 'type'],
      width: 75,
      render: (content) => <Cell>{content}</Cell>,
    },
    {
      title: 'Section',
      dataIndex: ['inventory', 'section'],
      width: 90,
      render: (section) => <Cell>{section || 'N/A'}</Cell>,
    },
    {
      title: 'Publisher / Maker',
      dataIndex: ['inventory', 'publisherOrMaker'],
      width: 150,
      render: (publisherOrMaker) => <Cell style={{ maxWidth: 300 }}>{publisherOrMaker || 'N/A'}</Cell>,
    },
    {
      title: 'Item ID',
      dataIndex: ['inventoryItem', 'id'],
      width: 100,
      render: (id) => <Cell>{`INV-IT${id}`}</Cell>,
    },
    ...(user.role === 'parent' ? [] : [{
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: 90,
      render: (price) => <Cell>{`$${Number(price).toLocaleString()}`}</Cell>,
    }]),
    {
      title: 'Payment Date',
      dataIndex: ['sale', 'paymentDate'],
      render: (date) => <Cell>{(date ? moment(date).format('DD/MM/YYYY') : 'N/A')}</Cell>,
      width: 140,
    },
  ];

  const profileImg = data.formattedStudent.profileImage === null ? defaultProfile : data.formattedStudent.profileImage;

  return (
    <Wrapper>
      {uploadFile ? (
        <PopUpFile
          open
          type="student"
          userId={match.params.id}
          onClose={() => setUploadFile(null)}
        />
      ) : null}
      <Flex>
        <HeaderTextWrapper>
          <BackButton onClick={() => history.goBack()}> <Icon icon="back-button" height={16} /> </BackButton>
          <HeaderText> Student Management </HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <ErrorAlert
            errorMessage={errorMessage}
            show={errorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw / 2)' }}
          />
        </Flex>
      </Flex>
      <BottomWrapper>
        <LeftWrapper>
          <LeftTopWrapper>
            <EditWrapper>
              { (user.role === 'admin' || user.role === 'superAdmin' || user.role === 'parent') ? (
                <StyledLink to={`/student/edit/${match.params.id}`}>
                  <Icon icon="edit-icon" height={21} style={{ minWidth: 21 }} />
                  <div>Edit</div>
                </StyledLink>
              ) : null}
            </EditWrapper>
            <ImageWrapper>
              <Image width={128} height={128} src={profileImg} alt="Image not found" style={{ minWidth: 128 }} />
              <LabelText style={{ fontSize: 16, paddingTop: 10 }}>{data.formattedStudent.name}</LabelText>
            </ImageWrapper>
          </LeftTopWrapper>
          <DetailWrapper>
            <SectionWrapper>
              <StyledFlex>
                <Icon icon="basic-info" height={20} style={{ marginRight: 5 }} />
                <LabelText style={{ marginRight: 0 }}> Basic Information </LabelText>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Student ID </DetailLabelText>
                <div>{data.formattedStudent.studentId}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Parent Name </DetailLabelText>
                <div>{data.formattedStudent.parentName}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Date of Birth </DetailLabelText>
                <div>{data.formattedStudent.formattedDob}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Gender </DetailLabelText>
                <div>{data.formattedStudent.gender || 'N/A'}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> School </DetailLabelText>
                <div>{data.formattedStudent.school || 'N/A'}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> School Year </DetailLabelText>
                <div>{data.formattedStudent.schoolYear || 'N/A'}</div>
              </StyledFlex>
              <StyledFlex>
                <DetailLabelText> Status </DetailLabelText>
                <div>{data.formattedStudent.status}</div>
              </StyledFlex>
            </SectionWrapper>
            <SectionWrapper style={{ paddingTop: 10, borderBottomStyle: 'dashed' }}>
              <StyledFlex>
                <Icon icon="study-info" height={20} style={{ marginRight: 5 }} />
                <LabelText style={{ marginRight: 0 }}> Study Information </LabelText>
              </StyledFlex>
              <StyledFlex style={{ alignItems: 'normal' }}>
                <DetailLabelText> Music Background </DetailLabelText>
                <div>{data.formattedStudent.musicBackground || 'N/A'}</div>
              </StyledFlex>
              <StyledFlex style={{ alignItems: 'normal' }}>
                <DetailLabelText> Lesson(s) </DetailLabelText>
                {currentActiveLessons ? (
                  <Flex direction="column">
                    {uniqBy(currentActiveLessons, 'lessonName')
                      .map((l) => (
                        <div style={{ paddingBottom: 5 }}>
                          {l.lessonName} [{l.status}]
                        </div>
                      ))
                    }
                  </Flex>
                ) : (
                  <div> N/A </div>
                )}
              </StyledFlex>
            </SectionWrapper>
          </DetailWrapper>
        </LeftWrapper>
        <RightWrapper>
          <Tabs activeKey={get(queryParams, ['where', 'tab'])} onChange={(tabValue) => onTabChange(tabValue)}>
            { (user.role === 'admin' || user.role === 'superAdmin' || user.role === 'instructor') && (
              <TabPane tab="Daily Teaching Notes" key="Daily Teaching Notes">
                <ModelContainer divHeight={divHeight}>
                  <ModelPage
                    showHeader={false}
                    apiRoute="student/teachingNotes"
                    itemsPerPage={25}
                    query={{ where: { id: match.params.id, tab: 'Daily Teaching Notes', name: 'All' } }}
                    initialQuery={{
                      currentPage: 1,
                      where: {
                        tab: 'Daily Teaching Notes',
                        name: 'All',
                      },
                    }}
                    radioGroup={lessonTabs}
                    radioGroupKey="name"
                    radioGroupSize="middle"
                    allowSearch={false}
                    {...props}
                    render={(renderProps) => {
                      const { height } = renderProps;
                      if (divHeight !== height) setDivHeight(height);
                      return (
                        <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Classes" />}>
                          <AntDTable
                            cellRenderers={user.role === 'instructor' ? teachingNotesColumns.filter((c) => c.title !== 'Instructor') : teachingNotesColumns}
                            scroll={{ x: 'max-content', y: '40vh' }}
                            pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                            rowKey="id"
                            {...renderProps}
                          />
                        </ConfigProvider>
                      )
                    }}
                  />
                </ModelContainer>
              </TabPane>
            )}
            <TabPane tab="Purchased Items" key="Purchased Items">
              <ModelContainer divHeight={itemsDivHeight}>
                <ModelPage
                  showHeader={false}
                  apiRoute="student/purchasedItems"
                  itemsPerPage={25}
                  query={{ where: { id: match.params.id, tab: 'Purchased Items', itemType: user.role === 'instructor' ? ['Accessory', 'Book', 'Instrument'] : null } }}
                  initialQuery={{
                    currentPage: 1,
                    where: {
                      tab: 'Purchased Items',
                    },
                  }}
                  allowSearch={false}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (itemsDivHeight !== height) setItemsDivHeight(height);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Items" />}>
                        <AntDTable
                          cellRenderers={user.role === 'instructor' ? purchasedItemsColumns.filter((c) => c.title !== 'Price') : purchasedItemsColumns}
                          scroll={{ x: 'max-content', y: '40vh' }}
                          pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
            <TabPane tab="Files" key="Files">
              <ModelContainer divHeight={filesDivHeight}>
                <ModelPage
                  showHeader={false}
                  apiRoute="student/files"
                  itemsPerPage={25}
                  query={{ where: { id: match.params.id, tab: 'Files' } }}
                  initialQuery={{
                    currentPage: 1,
                    where: {
                      tab: 'Files',
                    },
                  }}
                  actions={user.role === 'instructor' ? [] : [{
                    action: () => setUploadFile(true),
                    label: 'Add',
                  }]}
                  allowSearch={false}
                  {...props}
                  render={(renderProps) => {
                    const { height } = renderProps;
                    if (filesDivHeight !== height) setFilesDivHeight(height);
                    return (
                      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Files" />}>
                        <AntDTable
                          cellRenderers={user.role === 'instructor' ? fileColumns.filter((c) => c.title !== 'Action') : fileColumns}
                          scroll={{ x: 'max-content', y: '40vh' }}
                          pagination={{ pageSize: 25, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                          rowKey="id"
                          {...renderProps}
                        />
                      </ConfigProvider>
                    )
                  }}
                />
              </ModelContainer>
            </TabPane>
          </Tabs>
        </RightWrapper>
      </BottomWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state.authentication),
  }
}

export default connect(mapStateToProps)(ShowView);
