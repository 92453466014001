import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { size, palette } from 'styled-theme';

import ModelPage from '../../../containers/ModelPage';
import Flex from '../../atoms/Flex';
import Image from '../../atoms/Image';
import Icon from '../../atoms/Icon';
import Card from '../../atoms/Card';
import P from '../../atoms/P';
import ModelTable from '../ModelTable';

const StyledP = styled(P)`
  margin: 0;
`
const StyledCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  padding: 32px 20px;
`
const ImageContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  height: 50px;
`
const LabelP = styled(StyledP)`
  font-size: 16px;
  color: ${palette('black', 0)};
  word-break: normal;
`
const DataP = styled(StyledP)`
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  margin-top: 7px;
  color: ${palette('black', 0)};
  margin-bottom: 10px;
`
const OverView = ({
  data,
  ...props
}) => {
  // const showCreateButton = isRoleAuthorized(access.create, role) || true;
  // const showEditButton = isRoleAuthorized(access.update, role) || true;
  // const showViewButton = isRoleAuthorized(access.read, role) || true;
  return (
    <Flex {...props}>
      {data.map((d, index) => (
        <Card key={index} style={{ maxWidth: '50%', width: `calc(${100 / data.length}% - 12.5px)`, padding: '32px 20px', boxShadow: 'none' }}>
          <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Flex direction="column">
              <DataP style={{ fontWeight: 700 }}>{d.value}</DataP>
              <LabelP>{d.label}</LabelP>
            </Flex>
            <ImageContainer>
              <Image
                width={d.imageWidth}
                height={d.imageWidth}
                src={d.image}
                style={{ minWidth: d.imageWidth }}
              />
            </ImageContainer>
          </Flex>
        </Card>
      ))}
    </Flex>
  );
};

OverView.propTypes = {
  role: PropTypes.string,
};

export default OverView;
